import SvgAreas from "../../../../components/main/wrappers/svg-areas/SvgAreas";
import SvgCustoms from "../../../../components/main/wrappers/svg-customs/SvgCustoms";

import unitSizeJSON from "./unit_sizes.json";

import { ReactComponent as Block_1_93 } from "./blocks/1/land_plot_block_93.svg";
import { ReactComponent as Block_1_94 } from "./blocks/1/land_plot_block_94.svg";
import { ReactComponent as Block_1_95 } from "./blocks/1/land_plot_block_95.svg";
import { ReactComponent as Block_1_96 } from "./blocks/1/land_plot_block_96.svg";
import { ReactComponent as Block_1_97 } from "./blocks/1/land_plot_block_97.svg";
import { ReactComponent as Block_1_98 } from "./blocks/1/land_plot_block_98.svg";
import { ReactComponent as Block_1_99 } from "./blocks/1/land_plot_block_99.svg";
import { ReactComponent as Block_1_100 } from "./blocks/1/land_plot_block_100.svg";
import { ReactComponent as Block_1_101 } from "./blocks/1/land_plot_block_101.svg";
import { ReactComponent as Block_1_102 } from "./blocks/1/land_plot_block_102.svg";
import { ReactComponent as Block_1_103 } from "./blocks/1/land_plot_block_103.svg";
import { ReactComponent as Block_1_104 } from "./blocks/1/land_plot_block_104.svg";
import { ReactComponent as Block_1_105 } from "./blocks/1/land_plot_block_105.svg";
import { ReactComponent as Block_1_106 } from "./blocks/1/land_plot_block_106.svg";
import { ReactComponent as Block_1_107 } from "./blocks/1/land_plot_block_107.svg";
import { ReactComponent as Block_1_108 } from "./blocks/1/land_plot_block_108.svg";
import { ReactComponent as Block_1_109 } from "./blocks/1/land_plot_block_109.svg";
import { ReactComponent as Block_1_110 } from "./blocks/1/land_plot_block_110.svg";

import { ReactComponent as Block_31_93 } from "./blocks/31/land_plot_block_93.svg";
import { ReactComponent as Block_31_94 } from "./blocks/31/land_plot_block_94.svg";
import { ReactComponent as Block_31_95 } from "./blocks/31/land_plot_block_95.svg";
import { ReactComponent as Block_31_96 } from "./blocks/31/land_plot_block_96.svg";
import { ReactComponent as Block_31_97 } from "./blocks/31/land_plot_block_97.svg";
import { ReactComponent as Block_31_98 } from "./blocks/31/land_plot_block_98.svg";
import { ReactComponent as Block_31_99 } from "./blocks/31/land_plot_block_99.svg";
import { ReactComponent as Block_31_100 } from "./blocks/31/land_plot_block_100.svg";
import { ReactComponent as Block_31_101 } from "./blocks/31/land_plot_block_101.svg";
import { ReactComponent as Block_31_102 } from "./blocks/31/land_plot_block_102.svg";
import { ReactComponent as Block_31_103 } from "./blocks/31/land_plot_block_103.svg";
import { ReactComponent as Block_31_104 } from "./blocks/31/land_plot_block_104.svg";
import { ReactComponent as Block_31_105 } from "./blocks/31/land_plot_block_105.svg";
import { ReactComponent as Block_31_106 } from "./blocks/31/land_plot_block_106.svg";
import { ReactComponent as Block_31_107 } from "./blocks/31/land_plot_block_107.svg";
import { ReactComponent as Block_31_108 } from "./blocks/31/land_plot_block_108.svg";
import { ReactComponent as Block_31_109 } from "./blocks/31/land_plot_block_109.svg";
import { ReactComponent as Block_31_110 } from "./blocks/31/land_plot_block_110.svg";

import { ReactComponent as Block_61_93 } from "./blocks/61/land_plot_block_93.svg";
import { ReactComponent as Block_61_94 } from "./blocks/61/land_plot_block_94.svg";
import { ReactComponent as Block_61_95 } from "./blocks/61/land_plot_block_95.svg";
import { ReactComponent as Block_61_96 } from "./blocks/61/land_plot_block_96.svg";
import { ReactComponent as Block_61_97 } from "./blocks/61/land_plot_block_97.svg";
import { ReactComponent as Block_61_98 } from "./blocks/61/land_plot_block_98.svg";
import { ReactComponent as Block_61_99 } from "./blocks/61/land_plot_block_99.svg";
import { ReactComponent as Block_61_100 } from "./blocks/61/land_plot_block_100.svg";
import { ReactComponent as Block_61_101 } from "./blocks/61/land_plot_block_101.svg";
import { ReactComponent as Block_61_102 } from "./blocks/61/land_plot_block_102.svg";
import { ReactComponent as Block_61_103 } from "./blocks/61/land_plot_block_103.svg";
import { ReactComponent as Block_61_104 } from "./blocks/61/land_plot_block_104.svg";
import { ReactComponent as Block_61_105 } from "./blocks/61/land_plot_block_105.svg";
import { ReactComponent as Block_61_106 } from "./blocks/61/land_plot_block_106.svg";
import { ReactComponent as Block_61_107 } from "./blocks/61/land_plot_block_107.svg";
import { ReactComponent as Block_61_108 } from "./blocks/61/land_plot_block_108.svg";
import { ReactComponent as Block_61_109 } from "./blocks/61/land_plot_block_109.svg";
import { ReactComponent as Block_61_110 } from "./blocks/61/land_plot_block_110.svg";

import { ReactComponent as Block_90_93 } from "./blocks/90/land_plot_block_93.svg";
import { ReactComponent as Block_90_94 } from "./blocks/90/land_plot_block_94.svg";
import { ReactComponent as Block_90_95 } from "./blocks/90/land_plot_block_95.svg";
import { ReactComponent as Block_90_96 } from "./blocks/90/land_plot_block_96.svg";
import { ReactComponent as Block_90_97 } from "./blocks/90/land_plot_block_97.svg";
import { ReactComponent as Block_90_98 } from "./blocks/90/land_plot_block_98.svg";
import { ReactComponent as Block_90_99 } from "./blocks/90/land_plot_block_99.svg";
import { ReactComponent as Block_90_100 } from "./blocks/90/land_plot_block_100.svg";
import { ReactComponent as Block_90_101 } from "./blocks/90/land_plot_block_101.svg";
import { ReactComponent as Block_90_102 } from "./blocks/90/land_plot_block_102.svg";
import { ReactComponent as Block_90_103 } from "./blocks/90/land_plot_block_103.svg";
import { ReactComponent as Block_90_104 } from "./blocks/90/land_plot_block_104.svg";
import { ReactComponent as Block_90_105 } from "./blocks/90/land_plot_block_105.svg";
import { ReactComponent as Block_90_106 } from "./blocks/90/land_plot_block_106.svg";
import { ReactComponent as Block_90_107 } from "./blocks/90/land_plot_block_107.svg";
import { ReactComponent as Block_90_108 } from "./blocks/90/land_plot_block_108.svg";
import { ReactComponent as Block_90_109 } from "./blocks/90/land_plot_block_109.svg";
import { ReactComponent as Block_90_110 } from "./blocks/90/land_plot_block_110.svg";

import { ReactComponent as Church_2_1 } from "../../../../static/images/contents/zone-3/icons/2/church_1.svg";
import { ReactComponent as Church_2_2 } from "../../../../static/images/contents/zone-3/icons/2/church_2.svg";
import { ReactComponent as Church_2_3 } from "../../../../static/images/contents/zone-3/icons/2/church_3.svg";
import { ReactComponent as Church_2_4 } from "../../../../static/images/contents/zone-3/icons/2/church_4.svg";
import { ReactComponent as Church_2_5 } from "../../../../static/images/contents/zone-3/icons/2/church_5.svg";
import { ReactComponent as Church_2_6 } from "../../../../static/images/contents/zone-3/icons/2/church_6.svg";
import { ReactComponent as Church_2_7 } from "../../../../static/images/contents/zone-3/icons/2/church_7.svg";
import { ReactComponent as Church_2_8 } from "../../../../static/images/contents/zone-3/icons/2/church_8.svg";
import { ReactComponent as School_2_1 } from "../../../../static/images/contents/zone-3/icons/2/school_1.svg";
import { ReactComponent as School_2_2 } from "../../../../static/images/contents/zone-3/icons/2/school_2.svg";
import { ReactComponent as School_2_3 } from "../../../../static/images/contents/zone-3/icons/2/school_3.svg";
import { ReactComponent as School_2_4 } from "../../../../static/images/contents/zone-3/icons/2/school_4.svg";
import { ReactComponent as School_2_5 } from "../../../../static/images/contents/zone-3/icons/2/school_5.svg";
import { ReactComponent as School_2_6 } from "../../../../static/images/contents/zone-3/icons/2/school_6.svg";
import { ReactComponent as School_2_7 } from "../../../../static/images/contents/zone-3/icons/2/school_7.svg";
import { ReactComponent as Shopping_2_1 } from "../../../../static/images/contents/zone-3/icons/2/shopping_1.svg";
import { ReactComponent as Shopping_2_2 } from "../../../../static/images/contents/zone-3/icons/2/shopping_2.svg";
import { ReactComponent as Hospital_2_1 } from "../../../../static/images/contents/zone-3/icons/2/hospital_1.svg";

import { ReactComponent as Church_32_1 } from "../../../../static/images/contents/zone-3/icons/33/church_1.svg";
import { ReactComponent as Church_32_2 } from "../../../../static/images/contents/zone-3/icons/33/church_2.svg";
import { ReactComponent as Church_32_3 } from "../../../../static/images/contents/zone-3/icons/33/church_3.svg";
import { ReactComponent as Church_32_4 } from "../../../../static/images/contents/zone-3/icons/33/church_4.svg";
import { ReactComponent as Church_32_5 } from "../../../../static/images/contents/zone-3/icons/33/church_5.svg";
import { ReactComponent as Church_32_6 } from "../../../../static/images/contents/zone-3/icons/33/church_6.svg";
import { ReactComponent as School_32_1 } from "../../../../static/images/contents/zone-3/icons/33/school_1.svg";
import { ReactComponent as School_32_2 } from "../../../../static/images/contents/zone-3/icons/33/school_2.svg";
import { ReactComponent as School_32_3 } from "../../../../static/images/contents/zone-3/icons/33/school_3.svg";
import { ReactComponent as Shopping_32_1 } from "../../../../static/images/contents/zone-3/icons/33/shopping_1.svg";
import { ReactComponent as Shopping_32_2 } from "../../../../static/images/contents/zone-3/icons/33/shopping_2.svg";                      

import { ReactComponent as Church_62_1 } from "../../../../static/images/contents/zone-3/icons/61/church_1.svg";
import { ReactComponent as Church_62_2 } from "../../../../static/images/contents/zone-3/icons/61/church_2.svg";
import { ReactComponent as Church_62_3 } from "../../../../static/images/contents/zone-3/icons/61/church_3.svg";
import { ReactComponent as Church_62_4 } from "../../../../static/images/contents/zone-3/icons/61/church_4.svg";
import { ReactComponent as Church_62_5 } from "../../../../static/images/contents/zone-3/icons/61/church_5.svg";
import { ReactComponent as Church_62_6 } from "../../../../static/images/contents/zone-3/icons/61/church_6.svg";
import { ReactComponent as Church_62_7 } from "../../../../static/images/contents/zone-3/icons/61/church_7.svg";
import { ReactComponent as Church_62_8 } from "../../../../static/images/contents/zone-3/icons/61/church_8.svg";
import { ReactComponent as School_62_1 } from "../../../../static/images/contents/zone-3/icons/61/school_1.svg";
import { ReactComponent as School_62_2 } from "../../../../static/images/contents/zone-3/icons/61/school_2.svg";
import { ReactComponent as School_62_3 } from "../../../../static/images/contents/zone-3/icons/61/school_3.svg";
import { ReactComponent as School_62_4 } from "../../../../static/images/contents/zone-3/icons/61/school_4.svg";
import { ReactComponent as School_62_5 } from "../../../../static/images/contents/zone-3/icons/61/school_5.svg";
import { ReactComponent as School_62_6 } from "../../../../static/images/contents/zone-3/icons/61/school_6.svg";
import { ReactComponent as Shopping_62_1 } from "../../../../static/images/contents/zone-3/icons/61/shopping_1.svg";
import { ReactComponent as Shopping_62_2 } from "../../../../static/images/contents/zone-3/icons/61/shopping_2.svg";
import { ReactComponent as Hospital_62_1 } from "../../../../static/images/contents/zone-3/icons/2/hospital_1.svg";

import { ReactComponent as Church_91_1 } from "../../../../static/images/contents/zone-3/icons/91/church_1.svg";
import { ReactComponent as Church_91_2 } from "../../../../static/images/contents/zone-3/icons/91/church_2.svg";
import { ReactComponent as Church_91_3 } from "../../../../static/images/contents/zone-3/icons/91/church_3.svg";
import { ReactComponent as Church_91_4 } from "../../../../static/images/contents/zone-3/icons/91/church_4.svg";
import { ReactComponent as Church_91_5 } from "../../../../static/images/contents/zone-3/icons/91/church_5.svg";
import { ReactComponent as Church_91_6 } from "../../../../static/images/contents/zone-3/icons/91/church_6.svg";
import { ReactComponent as Church_91_7 } from "../../../../static/images/contents/zone-3/icons/91/church_7.svg";
import { ReactComponent as Church_91_8 } from "../../../../static/images/contents/zone-3/icons/91/church_8.svg";
import { ReactComponent as School_91_1 } from "../../../../static/images/contents/zone-3/icons/91/school_1.svg";
import { ReactComponent as School_91_2 } from "../../../../static/images/contents/zone-3/icons/91/school_2.svg";
import { ReactComponent as School_91_3 } from "../../../../static/images/contents/zone-3/icons/91/school_3.svg";
import { ReactComponent as School_91_4 } from "../../../../static/images/contents/zone-3/icons/91/school_4.svg";
import { ReactComponent as School_91_5 } from "../../../../static/images/contents/zone-3/icons/91/school_5.svg";
import { ReactComponent as School_91_6 } from "../../../../static/images/contents/zone-3/icons/91/school_6.svg";
import { ReactComponent as School_91_7 } from "../../../../static/images/contents/zone-3/icons/91/school_7.svg";
import { ReactComponent as Shopping_91_1 } from "../../../../static/images/contents/zone-3/icons/91/shopping_1.svg";
import { ReactComponent as Shopping_91_2 } from "../../../../static/images/contents/zone-3/icons/91/shopping_2.svg";
import { ReactComponent as Hospital_91_1 } from "../../../../static/images/contents/zone-3/icons/2/hospital_1.svg";

export const zone3_blocks = [
    {
        index: 2,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    unitSizeJSON={unitSizeJSON}
                    areas={[
                        {
                            Polygon: Block_1_93,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 93,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_94,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 94,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_95,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 95,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_96,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 96,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_97,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 97,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_98,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 98,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_99,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 99,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_100,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 100,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_101,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 101,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_102,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 102,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_103,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 103,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_104,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 104,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_105,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 105,
                                status: "sold",
                            },
                        },
                        {
                            Polygon: Block_1_106,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 106,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_107,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 107,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_108,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 108,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_109,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 109,
                                status: "sold",
                            },
                        },
                        {
                            Polygon: Block_1_110,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 110,
                                status: "available",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_7,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_8,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_7,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
    {
        index: 32,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    unitSizeJSON={unitSizeJSON}
                    areas={[
                        {
                            Polygon: Block_31_93,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 93,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_94,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 94,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_95,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 95,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_96,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 96,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_97,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 97,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_98,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 98,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_99,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 99,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_100,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 100,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_101,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 101,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_102,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 102,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_103,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 103,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_104,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 104,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_105,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 105,
                                status: "sold",
                            },
                        },
                        {
                            Polygon: Block_31_106,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 106,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_107,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 107,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_108,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 108,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_31_109,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 109,
                                status: "sold",
                            },
                        },
                        {
                            Polygon: Block_31_110,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 110,
                                status: "available",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_32_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_32_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_32_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_32_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_32_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_32_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_32_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_32_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_32_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_32_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_32_2,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
    {
        index: 62,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    unitSizeJSON={unitSizeJSON}
                    areas={[
                        {
                            Polygon: Block_61_93,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 93,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_94,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 94,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_95,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 95,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_96,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 96,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_97,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 97,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_98,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 98,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_99,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 99,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_100,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 100,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_101,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 101,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_102,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 102,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_103,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 103,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_104,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 104,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_105,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 105,
                                status: "sold",
                            },
                        },
                        {
                            Polygon: Block_61_106,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 106,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_107,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 107,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_108,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 108,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_61_109,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 109,
                                status: "sold",
                            },
                        },
                        {
                            Polygon: Block_61_110,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 110,
                                status: "available",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_62_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_62_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_62_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_62_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_62_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_62_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_62_7,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_62_8,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_62_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_62_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_62_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_62_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_62_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_62_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_62_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_62_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_62_1,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
    {
        index: 91,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    unitSizeJSON={unitSizeJSON}
                    areas={[
                        {
                            Polygon: Block_90_93,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 93,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_90_94,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 94,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_90_95,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 95,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_90_96,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 96,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_90_97,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 97,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_90_98,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 98,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_90_99,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 99,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_90_100,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 100,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_90_101,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 101,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_90_102,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 102,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_90_103,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 103,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_90_104,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 104,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_90_105,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 105,
                                status: "sold",
                            },
                        },
                        {
                            Polygon: Block_90_106,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 106,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_90_107,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 107,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_90_108,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 108,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_90_109,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 109,
                                status: "sold",
                            },
                        },
                        {
                            Polygon: Block_90_110,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 110,
                                status: "available",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_91_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_7,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_8,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_7,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_91_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_91_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_91_1,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
];

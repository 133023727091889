import AlZumurudaIcon from "../../static/images/contents/buraydah/en/al_zumuruda.svg";

import QassimUniversityIcon from "../../static/images/contents/buraydah/en/qassim_university.svg";
import QassimBoulvardIcon from "../../static/images/contents/buraydah/en/qassim_boulevard.svg";
import InternationalAirportIcon from "../../static/images/contents/buraydah/en/international_airport.svg";
import KingAbdullahSportsCityIcon from "../../static/images/contents/buraydah/en/king_abdullah_sports_city.svg";

import QassimUniversityIconActive from "../../static/images/contents/buraydah/en/qassim_university_active.svg";
import QassimBoulvardIconActive from "../../static/images/contents/buraydah/en/qassim_boulevard_active.svg";
import InternationalAirportIconActive from "../../static/images/contents/buraydah/en/international_airport_active.svg";
import KingAbdullahSportsCityIconActive from "../../static/images/contents/buraydah/en/king_abdullah_sports_city_active.svg";

import Mark5KM from "../../static/images/mark-5km.svg";
import Mark10KM from "../../static/images/mark-10km.svg";
import Mark15KM from "../../static/images/mark-15km.svg";
import Mark20KM from "../../static/images/mark-20km.svg";

export const translationsEn = {
    menu_title: "Menu",
    menu_buraydah: "Buraydah",
    menu_master_plan: "3D MASTER PLAN",
    menu_zones: "ZONES",
    menu_zone_1: "Zone 1",
    menu_zone_2: "Zone 2",
    menu_zone_3: "Zone 3",
    menu_zone_4: "Zone 4",
    menu_zone_5: "Zone 5",
    menu_language: "العربية",
    menu_services: "Services",
    map_show_distances: "Show Distances",
    map_show_circles: "Show Circles",
    map_km: "km",
    map_mins: "mins",
    map_5km: Mark5KM,
    map_10km: Mark10KM,
    map_15km: Mark15KM,
    map_20km: Mark20KM,
    percentage_loading_title: "Loading...",
    land_info_title: "Land Plot",

    land_info_status_available: "Available",
    land_info_status_booked: "Booked",
    land_info_status_sold: "Sold",
    land_info_electric_transformer: "Electric transformer built up",
    block_info_title: "Block",
    back_button: "Back",
    contact_form_send: "Send",
    contact_form_full_name: "Full name",
    contact_form_email: "Email",
    contact_form_phone_number: "Phone number",
    contact_form_sent_your_way: "A request has been sent your way;",
    contact_form_would_be_appreciated:
        "Your prompt attention will be greatly appreciated",
    buraydah_al_zumuruda_icon: AlZumurudaIcon,
    buraydah_qassim_university_icon: QassimUniversityIcon,
    buraydah_qassim_university_icon_active: QassimUniversityIconActive,
    buraydah_qassim_boulevard_icon: QassimBoulvardIcon,
    buraydah_qassim_boulevard_icon_active: QassimBoulvardIconActive,
    buraydah_international_airport_icon: InternationalAirportIcon,
    buraydah_international_airport_icon_active: InternationalAirportIconActive,
    buraydah_sports_city_icon: KingAbdullahSportsCityIcon,
    buraydah_sports_city_icon_active: KingAbdullahSportsCityIconActive,
    zone_5_switch_land_plot: "Land plots",
    zone_5_switch_blocks: "Blocks",
    font_inter: "Inter",
    font_salvager: "SALVAGER",
    electricity: "Electricity",
    water: "Water",
    roads: "Roads",
    stormwaterDrainage: "Stormwater Drainage",
    unit_size: "Unit Size",
    block: "Block",
    number_of_units: "Number of units",
    block_size: "Block Size",
    meters: "m",
    square_meters: "m²",
    contact_form_block: "Block",
    contact_form_north: "North",
    contact_form_east: "East",
    contact_form_south: "South",
    contact_form_west: "West",
    contact_form_front1: "Front 1",
    contact_form_front2: "Front 2",
    contact_form_front_northern: "NORTHERN",
    contact_form_front_southern: "SOUTHERN",
    contact_form_front_eastern: "EASTERN",
    contact_form_front_western: "WESTERN",
    contact_form_st_width_1: "St. Width 1",
    contact_form_st_width_2: "St. Width 2",
    contact_from_transformer_info: "Electric transformer built up",
    tip_title: "Navigate more efficiently",
    tip_desc: "Use filters to see blocks or land plots on the map.",
};

import SvgAreas from "../../../../components/main/wrappers/svg-areas/SvgAreas";
import SvgCustoms from "../../../../components/main/wrappers/svg-customs/SvgCustoms";

///////////////////////////////////////////////////////////////////////////////////stop frame 1
import { ReactComponent as LandPlot_1_55 } from "./land_plots/1/land_plot_55.svg";
import { ReactComponent as LandPlot_1_56 } from "./land_plots/1/land_plot_56.svg";
import { ReactComponent as LandPlot_1_57 } from "./land_plots/1/land_plot_57.svg";
import { ReactComponent as LandPlot_1_58 } from "./land_plots/1/land_plot_58.svg";
import { ReactComponent as LandPlot_1_59 } from "./land_plots/1/land_plot_59.svg";
import { ReactComponent as LandPlot_1_60 } from "./land_plots/1/land_plot_60.svg";
import { ReactComponent as LandPlot_1_61 } from "./land_plots/1/land_plot_61.svg";
import { ReactComponent as LandPlot_1_450 } from "./land_plots/1/land_plot_450.svg";
import { ReactComponent as LandPlot_1_451 } from "./land_plots/1/land_plot_451.svg";
import { ReactComponent as LandPlot_1_452 } from "./land_plots/1/land_plot_452.svg";
import { ReactComponent as LandPlot_1_453 } from "./land_plots/1/land_plot_453.svg";
import { ReactComponent as LandPlot_1_454 } from "./land_plots/1/land_plot_454.svg";
import { ReactComponent as LandPlot_1_455 } from "./land_plots/1/land_plot_455.svg";
import { ReactComponent as LandPlot_1_456 } from "./land_plots/1/land_plot_456.svg";
import { ReactComponent as LandPlot_1_457 } from "./land_plots/1/land_plot_457.svg";
import { ReactComponent as LandPlot_1_458 } from "./land_plots/1/land_plot_458.svg";
import { ReactComponent as LandPlot_1_459 } from "./land_plots/1/land_plot_459.svg";
import { ReactComponent as LandPlot_1_460 } from "./land_plots/1/land_plot_460.svg";
import { ReactComponent as LandPlot_1_461 } from "./land_plots/1/land_plot_461.svg";
import { ReactComponent as LandPlot_1_462 } from "./land_plots/1/land_plot_462.svg";
import { ReactComponent as LandPlot_1_463 } from "./land_plots/1/land_plot_463.svg";
import { ReactComponent as LandPlot_1_464 } from "./land_plots/1/land_plot_464.svg";
import { ReactComponent as LandPlot_1_465 } from "./land_plots/1/land_plot_465.svg";
import { ReactComponent as LandPlot_1_466 } from "./land_plots/1/land_plot_466.svg";
import { ReactComponent as LandPlot_1_467 } from "./land_plots/1/land_plot_467.svg";
import { ReactComponent as LandPlot_1_468 } from "./land_plots/1/land_plot_468.svg";
import { ReactComponent as LandPlot_1_469 } from "./land_plots/1/land_plot_469.svg";
import { ReactComponent as LandPlot_1_470 } from "./land_plots/1/land_plot_470.svg";
import { ReactComponent as LandPlot_1_471 } from "./land_plots/1/land_plot_471.svg";
import { ReactComponent as LandPlot_1_472 } from "./land_plots/1/land_plot_472.svg";
import { ReactComponent as LandPlot_1_473 } from "./land_plots/1/land_plot_473.svg";
import { ReactComponent as LandPlot_1_474 } from "./land_plots/1/land_plot_474.svg";
import { ReactComponent as LandPlot_1_475 } from "./land_plots/1/land_plot_475.svg";
import { ReactComponent as LandPlot_1_476 } from "./land_plots/1/land_plot_476.svg";
import { ReactComponent as LandPlot_1_477 } from "./land_plots/1/land_plot_477.svg";
import { ReactComponent as LandPlot_1_478 } from "./land_plots/1/land_plot_478.svg";
import { ReactComponent as LandPlot_1_479 } from "./land_plots/1/land_plot_479.svg";
import { ReactComponent as LandPlot_1_480 } from "./land_plots/1/land_plot_480.svg";
import { ReactComponent as LandPlot_1_481 } from "./land_plots/1/land_plot_481.svg";
import { ReactComponent as LandPlot_1_482 } from "./land_plots/1/land_plot_482.svg";
import { ReactComponent as LandPlot_1_483 } from "./land_plots/1/land_plot_483.svg";
import { ReactComponent as LandPlot_1_484 } from "./land_plots/1/land_plot_484.svg";
import { ReactComponent as LandPlot_1_485 } from "./land_plots/1/land_plot_485.svg";
import { ReactComponent as LandPlot_1_486 } from "./land_plots/1/land_plot_486.svg";
import { ReactComponent as LandPlot_1_487 } from "./land_plots/1/land_plot_487.svg";
import { ReactComponent as LandPlot_1_488 } from "./land_plots/1/land_plot_488.svg";
import { ReactComponent as LandPlot_1_489 } from "./land_plots/1/land_plot_489.svg";
import { ReactComponent as LandPlot_1_490 } from "./land_plots/1/land_plot_490.svg";
import { ReactComponent as LandPlot_1_491 } from "./land_plots/1/land_plot_491.svg";
import { ReactComponent as LandPlot_1_492 } from "./land_plots/1/land_plot_492.svg";
import { ReactComponent as LandPlot_1_493 } from "./land_plots/1/land_plot_493.svg";
import { ReactComponent as LandPlot_1_494 } from "./land_plots/1/land_plot_494.svg";
import { ReactComponent as LandPlot_1_495 } from "./land_plots/1/land_plot_495.svg";
import { ReactComponent as LandPlot_1_496 } from "./land_plots/1/land_plot_496.svg";
import { ReactComponent as LandPlot_1_497 } from "./land_plots/1/land_plot_497.svg";
import { ReactComponent as LandPlot_1_498 } from "./land_plots/1/land_plot_498.svg";
import { ReactComponent as LandPlot_1_499 } from "./land_plots/1/land_plot_499.svg";
import { ReactComponent as LandPlot_1_500 } from "./land_plots/1/land_plot_500.svg";
import { ReactComponent as LandPlot_1_501 } from "./land_plots/1/land_plot_501.svg";
import { ReactComponent as LandPlot_1_502 } from "./land_plots/1/land_plot_502.svg";
import { ReactComponent as LandPlot_1_503 } from "./land_plots/1/land_plot_503.svg";
import { ReactComponent as LandPlot_1_504 } from "./land_plots/1/land_plot_504.svg";
import { ReactComponent as LandPlot_1_505 } from "./land_plots/1/land_plot_505.svg";
import { ReactComponent as LandPlot_1_506 } from "./land_plots/1/land_plot_506.svg";
import { ReactComponent as LandPlot_1_507 } from "./land_plots/1/land_plot_507.svg";
import { ReactComponent as LandPlot_1_508 } from "./land_plots/1/land_plot_508.svg";
import { ReactComponent as LandPlot_1_509 } from "./land_plots/1/land_plot_509.svg";
import { ReactComponent as LandPlot_1_510 } from "./land_plots/1/land_plot_510.svg";
import { ReactComponent as LandPlot_1_511 } from "./land_plots/1/land_plot_511.svg";
import { ReactComponent as LandPlot_1_512 } from "./land_plots/1/land_plot_512.svg";
import { ReactComponent as LandPlot_1_513 } from "./land_plots/1/land_plot_513.svg";
import { ReactComponent as LandPlot_1_514 } from "./land_plots/1/land_plot_514.svg";
import { ReactComponent as LandPlot_1_515 } from "./land_plots/1/land_plot_515.svg";
import { ReactComponent as LandPlot_1_516 } from "./land_plots/1/land_plot_516.svg";
import { ReactComponent as LandPlot_1_517 } from "./land_plots/1/land_plot_517.svg";
import { ReactComponent as LandPlot_1_518 } from "./land_plots/1/land_plot_518.svg";
import { ReactComponent as LandPlot_1_519 } from "./land_plots/1/land_plot_519.svg";
import { ReactComponent as LandPlot_1_520 } from "./land_plots/1/land_plot_520.svg";
import { ReactComponent as LandPlot_1_521 } from "./land_plots/1/land_plot_521.svg";
import { ReactComponent as LandPlot_1_522 } from "./land_plots/1/land_plot_522.svg";
import { ReactComponent as LandPlot_1_523 } from "./land_plots/1/land_plot_523.svg";
import { ReactComponent as LandPlot_1_524 } from "./land_plots/1/land_plot_524.svg";
import { ReactComponent as LandPlot_1_525 } from "./land_plots/1/land_plot_525.svg";
import { ReactComponent as LandPlot_1_526 } from "./land_plots/1/land_plot_526.svg";
import { ReactComponent as LandPlot_1_527 } from "./land_plots/1/land_plot_527.svg";
import { ReactComponent as LandPlot_1_528 } from "./land_plots/1/land_plot_528.svg";
import { ReactComponent as LandPlot_1_529 } from "./land_plots/1/land_plot_529.svg";
import { ReactComponent as LandPlot_1_530 } from "./land_plots/1/land_plot_530.svg";
import { ReactComponent as LandPlot_1_531 } from "./land_plots/1/land_plot_531.svg";
import { ReactComponent as LandPlot_1_532 } from "./land_plots/1/land_plot_532.svg";
import { ReactComponent as LandPlot_1_533 } from "./land_plots/1/land_plot_533.svg";
import { ReactComponent as LandPlot_1_534 } from "./land_plots/1/land_plot_534.svg";
import { ReactComponent as LandPlot_1_535 } from "./land_plots/1/land_plot_535.svg";
import { ReactComponent as LandPlot_1_536 } from "./land_plots/1/land_plot_536.svg";
import { ReactComponent as LandPlot_1_537 } from "./land_plots/1/land_plot_537.svg";
import { ReactComponent as LandPlot_1_538 } from "./land_plots/1/land_plot_538.svg";
import { ReactComponent as LandPlot_1_539 } from "./land_plots/1/land_plot_539.svg";
import { ReactComponent as LandPlot_1_540 } from "./land_plots/1/land_plot_540.svg";
import { ReactComponent as LandPlot_1_541 } from "./land_plots/1/land_plot_541.svg";
import { ReactComponent as LandPlot_1_542 } from "./land_plots/1/land_plot_542.svg";
import { ReactComponent as LandPlot_1_543 } from "./land_plots/1/land_plot_543.svg";
import { ReactComponent as LandPlot_1_544 } from "./land_plots/1/land_plot_544.svg";
import { ReactComponent as LandPlot_1_545 } from "./land_plots/1/land_plot_545.svg";
import { ReactComponent as LandPlot_1_546 } from "./land_plots/1/land_plot_546.svg";
import { ReactComponent as LandPlot_1_547 } from "./land_plots/1/land_plot_547.svg";
import { ReactComponent as LandPlot_1_548 } from "./land_plots/1/land_plot_548.svg";
import { ReactComponent as LandPlot_1_549 } from "./land_plots/1/land_plot_549.svg";
import { ReactComponent as LandPlot_1_550 } from "./land_plots/1/land_plot_550.svg";
import { ReactComponent as LandPlot_1_551 } from "./land_plots/1/land_plot_551.svg";
import { ReactComponent as LandPlot_1_552 } from "./land_plots/1/land_plot_552.svg";
import { ReactComponent as LandPlot_1_553 } from "./land_plots/1/land_plot_553.svg";
import { ReactComponent as LandPlot_1_554 } from "./land_plots/1/land_plot_554.svg";
import { ReactComponent as LandPlot_1_555 } from "./land_plots/1/land_plot_555.svg";
import { ReactComponent as LandPlot_1_556 } from "./land_plots/1/land_plot_556.svg";
import { ReactComponent as LandPlot_1_557 } from "./land_plots/1/land_plot_557.svg";
import { ReactComponent as LandPlot_1_558 } from "./land_plots/1/land_plot_558.svg";
import { ReactComponent as LandPlot_1_559 } from "./land_plots/1/land_plot_559.svg";
import { ReactComponent as LandPlot_1_560 } from "./land_plots/1/land_plot_560.svg";
import { ReactComponent as LandPlot_1_561 } from "./land_plots/1/land_plot_561.svg";
import { ReactComponent as LandPlot_1_562 } from "./land_plots/1/land_plot_562.svg";
import { ReactComponent as LandPlot_1_563 } from "./land_plots/1/land_plot_563.svg";
import { ReactComponent as LandPlot_1_564 } from "./land_plots/1/land_plot_564.svg";
import { ReactComponent as LandPlot_1_565 } from "./land_plots/1/land_plot_565.svg";
import { ReactComponent as LandPlot_1_566 } from "./land_plots/1/land_plot_566.svg";
import { ReactComponent as LandPlot_1_567 } from "./land_plots/1/land_plot_567.svg";
import { ReactComponent as LandPlot_1_568 } from "./land_plots/1/land_plot_568.svg";
import { ReactComponent as LandPlot_1_569 } from "./land_plots/1/land_plot_569.svg";
import { ReactComponent as LandPlot_1_570 } from "./land_plots/1/land_plot_570.svg";
import { ReactComponent as LandPlot_1_571 } from "./land_plots/1/land_plot_571.svg";
import { ReactComponent as LandPlot_1_572 } from "./land_plots/1/land_plot_572.svg";
import { ReactComponent as LandPlot_1_573 } from "./land_plots/1/land_plot_573.svg";
import { ReactComponent as LandPlot_1_574 } from "./land_plots/1/land_plot_574.svg";
import { ReactComponent as LandPlot_1_575 } from "./land_plots/1/land_plot_575.svg";
import { ReactComponent as LandPlot_1_576 } from "./land_plots/1/land_plot_576.svg";
import { ReactComponent as LandPlot_1_577 } from "./land_plots/1/land_plot_577.svg";
import { ReactComponent as LandPlot_1_578 } from "./land_plots/1/land_plot_578.svg";
import { ReactComponent as LandPlot_1_579 } from "./land_plots/1/land_plot_579.svg";
import { ReactComponent as LandPlot_1_580 } from "./land_plots/1/land_plot_580.svg";
import { ReactComponent as LandPlot_1_581 } from "./land_plots/1/land_plot_581.svg";
import { ReactComponent as LandPlot_1_582 } from "./land_plots/1/land_plot_582.svg";
import { ReactComponent as LandPlot_1_583 } from "./land_plots/1/land_plot_583.svg";
import { ReactComponent as LandPlot_1_584 } from "./land_plots/1/land_plot_584.svg";
import { ReactComponent as LandPlot_1_585 } from "./land_plots/1/land_plot_585.svg";
import { ReactComponent as LandPlot_1_586 } from "./land_plots/1/land_plot_586.svg";
import { ReactComponent as LandPlot_1_587 } from "./land_plots/1/land_plot_587.svg";
import { ReactComponent as LandPlot_1_588 } from "./land_plots/1/land_plot_588.svg";
import { ReactComponent as LandPlot_1_589 } from "./land_plots/1/land_plot_589.svg";
import { ReactComponent as LandPlot_1_590 } from "./land_plots/1/land_plot_590.svg";
import { ReactComponent as LandPlot_1_591 } from "./land_plots/1/land_plot_591.svg";
import { ReactComponent as LandPlot_1_592 } from "./land_plots/1/land_plot_592.svg";
import { ReactComponent as LandPlot_1_593 } from "./land_plots/1/land_plot_593.svg";
import { ReactComponent as LandPlot_1_594 } from "./land_plots/1/land_plot_594.svg";
import { ReactComponent as LandPlot_1_595 } from "./land_plots/1/land_plot_595.svg";
import { ReactComponent as LandPlot_1_596 } from "./land_plots/1/land_plot_596.svg";
import { ReactComponent as LandPlot_1_597 } from "./land_plots/1/land_plot_597.svg";
import { ReactComponent as LandPlot_1_598 } from "./land_plots/1/land_plot_598.svg";
import { ReactComponent as LandPlot_1_599 } from "./land_plots/1/land_plot_599.svg";
import { ReactComponent as LandPlot_1_600 } from "./land_plots/1/land_plot_600.svg";
import { ReactComponent as LandPlot_1_601 } from "./land_plots/1/land_plot_601.svg";
import { ReactComponent as LandPlot_1_602 } from "./land_plots/1/land_plot_602.svg";
import { ReactComponent as LandPlot_1_603 } from "./land_plots/1/land_plot_603.svg";
import { ReactComponent as LandPlot_1_604 } from "./land_plots/1/land_plot_604.svg";
import { ReactComponent as LandPlot_1_605 } from "./land_plots/1/land_plot_605.svg";
import { ReactComponent as LandPlot_1_606 } from "./land_plots/1/land_plot_606.svg";
import { ReactComponent as LandPlot_1_607 } from "./land_plots/1/land_plot_607.svg";
import { ReactComponent as LandPlot_1_608 } from "./land_plots/1/land_plot_608.svg";
import { ReactComponent as LandPlot_1_609 } from "./land_plots/1/land_plot_609.svg";
import { ReactComponent as LandPlot_1_610 } from "./land_plots/1/land_plot_610.svg";
import { ReactComponent as LandPlot_1_611 } from "./land_plots/1/land_plot_611.svg";
import { ReactComponent as LandPlot_1_612 } from "./land_plots/1/land_plot_612.svg";
import { ReactComponent as LandPlot_1_613 } from "./land_plots/1/land_plot_613.svg";
import { ReactComponent as LandPlot_1_614 } from "./land_plots/1/land_plot_614.svg";
import { ReactComponent as LandPlot_1_615 } from "./land_plots/1/land_plot_615.svg";
import { ReactComponent as LandPlot_1_616 } from "./land_plots/1/land_plot_616.svg";
import { ReactComponent as LandPlot_1_617 } from "./land_plots/1/land_plot_617.svg";
import { ReactComponent as LandPlot_1_618 } from "./land_plots/1/land_plot_618.svg";
import { ReactComponent as LandPlot_1_619 } from "./land_plots/1/land_plot_619.svg";
import { ReactComponent as LandPlot_1_620 } from "./land_plots/1/land_plot_620.svg";
import { ReactComponent as LandPlot_1_621 } from "./land_plots/1/land_plot_621.svg";
import { ReactComponent as LandPlot_1_622 } from "./land_plots/1/land_plot_622.svg";
import { ReactComponent as LandPlot_1_623 } from "./land_plots/1/land_plot_623.svg";
import { ReactComponent as LandPlot_1_624 } from "./land_plots/1/land_plot_624.svg";
import { ReactComponent as LandPlot_1_625 } from "./land_plots/1/land_plot_625.svg";
import { ReactComponent as LandPlot_1_626 } from "./land_plots/1/land_plot_626.svg";
import { ReactComponent as LandPlot_1_627 } from "./land_plots/1/land_plot_627.svg";
import { ReactComponent as LandPlot_1_628 } from "./land_plots/1/land_plot_628.svg";
import { ReactComponent as LandPlot_1_629 } from "./land_plots/1/land_plot_629.svg";
import { ReactComponent as LandPlot_1_630 } from "./land_plots/1/land_plot_630.svg";
import { ReactComponent as LandPlot_1_631 } from "./land_plots/1/land_plot_631.svg";
import { ReactComponent as LandPlot_1_632 } from "./land_plots/1/land_plot_632.svg";
import { ReactComponent as LandPlot_1_633 } from "./land_plots/1/land_plot_633.svg";
import { ReactComponent as LandPlot_1_634 } from "./land_plots/1/land_plot_634.svg";
import { ReactComponent as LandPlot_1_635 } from "./land_plots/1/land_plot_635.svg";
import { ReactComponent as LandPlot_1_636 } from "./land_plots/1/land_plot_636.svg";
import { ReactComponent as LandPlot_1_637 } from "./land_plots/1/land_plot_637.svg";
import { ReactComponent as LandPlot_1_638 } from "./land_plots/1/land_plot_638.svg";
import { ReactComponent as LandPlot_1_639 } from "./land_plots/1/land_plot_639.svg";
import { ReactComponent as LandPlot_1_640 } from "./land_plots/1/land_plot_640.svg";
import { ReactComponent as LandPlot_1_641 } from "./land_plots/1/land_plot_641.svg";
import { ReactComponent as LandPlot_1_642 } from "./land_plots/1/land_plot_642.svg";
import { ReactComponent as LandPlot_1_643 } from "./land_plots/1/land_plot_643.svg";
import { ReactComponent as LandPlot_1_644 } from "./land_plots/1/land_plot_644.svg";
import { ReactComponent as LandPlot_1_645 } from "./land_plots/1/land_plot_645.svg";
import { ReactComponent as LandPlot_1_646 } from "./land_plots/1/land_plot_646.svg";
import { ReactComponent as LandPlot_1_647 } from "./land_plots/1/land_plot_647.svg";
import { ReactComponent as LandPlot_1_648 } from "./land_plots/1/land_plot_648.svg";
import { ReactComponent as LandPlot_1_649 } from "./land_plots/1/land_plot_649.svg";
import { ReactComponent as LandPlot_1_650 } from "./land_plots/1/land_plot_650.svg";
import { ReactComponent as LandPlot_1_651 } from "./land_plots/1/land_plot_651.svg";
import { ReactComponent as LandPlot_1_652 } from "./land_plots/1/land_plot_652.svg";
import { ReactComponent as LandPlot_1_653 } from "./land_plots/1/land_plot_653.svg";
import { ReactComponent as LandPlot_1_654 } from "./land_plots/1/land_plot_654.svg";
import { ReactComponent as LandPlot_1_655 } from "./land_plots/1/land_plot_655.svg";
import { ReactComponent as LandPlot_1_656 } from "./land_plots/1/land_plot_656.svg";
import { ReactComponent as LandPlot_1_657 } from "./land_plots/1/land_plot_657.svg";
import { ReactComponent as LandPlot_1_658 } from "./land_plots/1/land_plot_658.svg";
import { ReactComponent as LandPlot_1_659 } from "./land_plots/1/land_plot_659.svg";
import { ReactComponent as LandPlot_1_660 } from "./land_plots/1/land_plot_660.svg";
import { ReactComponent as LandPlot_1_661 } from "./land_plots/1/land_plot_661.svg";
import { ReactComponent as LandPlot_1_662 } from "./land_plots/1/land_plot_662.svg";
import { ReactComponent as LandPlot_1_663 } from "./land_plots/1/land_plot_663.svg";
import { ReactComponent as LandPlot_1_664 } from "./land_plots/1/land_plot_664.svg";
import { ReactComponent as LandPlot_1_665 } from "./land_plots/1/land_plot_665.svg";
import { ReactComponent as LandPlot_1_666 } from "./land_plots/1/land_plot_666.svg";
import { ReactComponent as LandPlot_1_667 } from "./land_plots/1/land_plot_667.svg";
import { ReactComponent as LandPlot_1_668 } from "./land_plots/1/land_plot_668.svg";
import { ReactComponent as LandPlot_1_669 } from "./land_plots/1/land_plot_669.svg";
import { ReactComponent as LandPlot_1_670 } from "./land_plots/1/land_plot_670.svg";
import { ReactComponent as LandPlot_1_671 } from "./land_plots/1/land_plot_671.svg";
import { ReactComponent as LandPlot_1_672 } from "./land_plots/1/land_plot_672.svg";
import { ReactComponent as LandPlot_1_673 } from "./land_plots/1/land_plot_673.svg";
import { ReactComponent as LandPlot_1_674 } from "./land_plots/1/land_plot_674.svg";
import { ReactComponent as LandPlot_1_675 } from "./land_plots/1/land_plot_675.svg";
import { ReactComponent as LandPlot_1_676 } from "./land_plots/1/land_plot_676.svg";
import { ReactComponent as LandPlot_1_677 } from "./land_plots/1/land_plot_677.svg";
import { ReactComponent as LandPlot_1_678 } from "./land_plots/1/land_plot_678.svg";
import { ReactComponent as LandPlot_1_679 } from "./land_plots/1/land_plot_679.svg";
import { ReactComponent as LandPlot_1_680 } from "./land_plots/1/land_plot_680.svg";

///////////////////////////////////////////////////////////////////////////////////stop frame 30
import { ReactComponent as LandPlot_30_55 } from "./land_plots/30/land_plot_55.svg";
import { ReactComponent as LandPlot_30_56 } from "./land_plots/30/land_plot_56.svg";
import { ReactComponent as LandPlot_30_57 } from "./land_plots/30/land_plot_57.svg";
import { ReactComponent as LandPlot_30_58 } from "./land_plots/30/land_plot_58.svg";
import { ReactComponent as LandPlot_30_59 } from "./land_plots/30/land_plot_59.svg";
import { ReactComponent as LandPlot_30_60 } from "./land_plots/30/land_plot_60.svg";
import { ReactComponent as LandPlot_30_61 } from "./land_plots/30/land_plot_61.svg";
import { ReactComponent as LandPlot_30_450 } from "./land_plots/30/land_plot_450.svg";
import { ReactComponent as LandPlot_30_451 } from "./land_plots/30/land_plot_451.svg";
import { ReactComponent as LandPlot_30_452 } from "./land_plots/30/land_plot_452.svg";
import { ReactComponent as LandPlot_30_453 } from "./land_plots/30/land_plot_453.svg";
import { ReactComponent as LandPlot_30_454 } from "./land_plots/30/land_plot_454.svg";
import { ReactComponent as LandPlot_30_455 } from "./land_plots/30/land_plot_455.svg";
import { ReactComponent as LandPlot_30_456 } from "./land_plots/30/land_plot_456.svg";
import { ReactComponent as LandPlot_30_457 } from "./land_plots/30/land_plot_457.svg";
import { ReactComponent as LandPlot_30_458 } from "./land_plots/30/land_plot_458.svg";
import { ReactComponent as LandPlot_30_459 } from "./land_plots/30/land_plot_459.svg";
import { ReactComponent as LandPlot_30_460 } from "./land_plots/30/land_plot_460.svg";
import { ReactComponent as LandPlot_30_461 } from "./land_plots/30/land_plot_461.svg";
import { ReactComponent as LandPlot_30_462 } from "./land_plots/30/land_plot_462.svg";
import { ReactComponent as LandPlot_30_463 } from "./land_plots/30/land_plot_463.svg";
import { ReactComponent as LandPlot_30_464 } from "./land_plots/30/land_plot_464.svg";
import { ReactComponent as LandPlot_30_465 } from "./land_plots/30/land_plot_465.svg";
import { ReactComponent as LandPlot_30_466 } from "./land_plots/30/land_plot_466.svg";
import { ReactComponent as LandPlot_30_467 } from "./land_plots/30/land_plot_467.svg";
import { ReactComponent as LandPlot_30_468 } from "./land_plots/30/land_plot_468.svg";
import { ReactComponent as LandPlot_30_469 } from "./land_plots/30/land_plot_469.svg";
import { ReactComponent as LandPlot_30_470 } from "./land_plots/30/land_plot_470.svg";
import { ReactComponent as LandPlot_30_471 } from "./land_plots/30/land_plot_471.svg";
import { ReactComponent as LandPlot_30_472 } from "./land_plots/30/land_plot_472.svg";
import { ReactComponent as LandPlot_30_473 } from "./land_plots/30/land_plot_473.svg";
import { ReactComponent as LandPlot_30_474 } from "./land_plots/30/land_plot_474.svg";
import { ReactComponent as LandPlot_30_475 } from "./land_plots/30/land_plot_475.svg";
import { ReactComponent as LandPlot_30_476 } from "./land_plots/30/land_plot_476.svg";
import { ReactComponent as LandPlot_30_477 } from "./land_plots/30/land_plot_477.svg";
import { ReactComponent as LandPlot_30_478 } from "./land_plots/30/land_plot_478.svg";
import { ReactComponent as LandPlot_30_479 } from "./land_plots/30/land_plot_479.svg";
import { ReactComponent as LandPlot_30_480 } from "./land_plots/30/land_plot_480.svg";
import { ReactComponent as LandPlot_30_481 } from "./land_plots/30/land_plot_481.svg";
import { ReactComponent as LandPlot_30_482 } from "./land_plots/30/land_plot_482.svg";
import { ReactComponent as LandPlot_30_483 } from "./land_plots/30/land_plot_483.svg";
import { ReactComponent as LandPlot_30_484 } from "./land_plots/30/land_plot_484.svg";
import { ReactComponent as LandPlot_30_485 } from "./land_plots/30/land_plot_485.svg";
import { ReactComponent as LandPlot_30_486 } from "./land_plots/30/land_plot_486.svg";
import { ReactComponent as LandPlot_30_487 } from "./land_plots/30/land_plot_487.svg";
import { ReactComponent as LandPlot_30_488 } from "./land_plots/30/land_plot_488.svg";
import { ReactComponent as LandPlot_30_489 } from "./land_plots/30/land_plot_489.svg";
import { ReactComponent as LandPlot_30_490 } from "./land_plots/30/land_plot_490.svg";
import { ReactComponent as LandPlot_30_491 } from "./land_plots/30/land_plot_491.svg";
import { ReactComponent as LandPlot_30_492 } from "./land_plots/30/land_plot_492.svg";
import { ReactComponent as LandPlot_30_493 } from "./land_plots/30/land_plot_493.svg";
import { ReactComponent as LandPlot_30_494 } from "./land_plots/30/land_plot_494.svg";
import { ReactComponent as LandPlot_30_495 } from "./land_plots/30/land_plot_495.svg";
import { ReactComponent as LandPlot_30_496 } from "./land_plots/30/land_plot_496.svg";
import { ReactComponent as LandPlot_30_497 } from "./land_plots/30/land_plot_497.svg";
import { ReactComponent as LandPlot_30_498 } from "./land_plots/30/land_plot_498.svg";
import { ReactComponent as LandPlot_30_499 } from "./land_plots/30/land_plot_499.svg";
import { ReactComponent as LandPlot_30_500 } from "./land_plots/30/land_plot_500.svg";
import { ReactComponent as LandPlot_30_501 } from "./land_plots/30/land_plot_501.svg";
import { ReactComponent as LandPlot_30_502 } from "./land_plots/30/land_plot_502.svg";
import { ReactComponent as LandPlot_30_503 } from "./land_plots/30/land_plot_503.svg";
import { ReactComponent as LandPlot_30_504 } from "./land_plots/30/land_plot_504.svg";
import { ReactComponent as LandPlot_30_505 } from "./land_plots/30/land_plot_505.svg";
import { ReactComponent as LandPlot_30_506 } from "./land_plots/30/land_plot_506.svg";
import { ReactComponent as LandPlot_30_507 } from "./land_plots/30/land_plot_507.svg";
import { ReactComponent as LandPlot_30_508 } from "./land_plots/30/land_plot_508.svg";
import { ReactComponent as LandPlot_30_509 } from "./land_plots/30/land_plot_509.svg";
import { ReactComponent as LandPlot_30_510 } from "./land_plots/30/land_plot_510.svg";
import { ReactComponent as LandPlot_30_511 } from "./land_plots/30/land_plot_511.svg";
import { ReactComponent as LandPlot_30_512 } from "./land_plots/30/land_plot_512.svg";
import { ReactComponent as LandPlot_30_513 } from "./land_plots/30/land_plot_513.svg";
import { ReactComponent as LandPlot_30_514 } from "./land_plots/30/land_plot_514.svg";
import { ReactComponent as LandPlot_30_515 } from "./land_plots/30/land_plot_515.svg";
import { ReactComponent as LandPlot_30_516 } from "./land_plots/30/land_plot_516.svg";
import { ReactComponent as LandPlot_30_517 } from "./land_plots/30/land_plot_517.svg";
import { ReactComponent as LandPlot_30_518 } from "./land_plots/30/land_plot_518.svg";
import { ReactComponent as LandPlot_30_519 } from "./land_plots/30/land_plot_519.svg";
import { ReactComponent as LandPlot_30_520 } from "./land_plots/30/land_plot_520.svg";
import { ReactComponent as LandPlot_30_521 } from "./land_plots/30/land_plot_521.svg";
import { ReactComponent as LandPlot_30_522 } from "./land_plots/30/land_plot_522.svg";
import { ReactComponent as LandPlot_30_523 } from "./land_plots/30/land_plot_523.svg";
import { ReactComponent as LandPlot_30_524 } from "./land_plots/30/land_plot_524.svg";
import { ReactComponent as LandPlot_30_525 } from "./land_plots/30/land_plot_525.svg";
import { ReactComponent as LandPlot_30_526 } from "./land_plots/30/land_plot_526.svg";
import { ReactComponent as LandPlot_30_527 } from "./land_plots/30/land_plot_527.svg";
import { ReactComponent as LandPlot_30_528 } from "./land_plots/30/land_plot_528.svg";
import { ReactComponent as LandPlot_30_529 } from "./land_plots/30/land_plot_529.svg";
import { ReactComponent as LandPlot_30_530 } from "./land_plots/30/land_plot_530.svg";
import { ReactComponent as LandPlot_30_531 } from "./land_plots/30/land_plot_531.svg";
import { ReactComponent as LandPlot_30_532 } from "./land_plots/30/land_plot_532.svg";
import { ReactComponent as LandPlot_30_533 } from "./land_plots/30/land_plot_533.svg";
import { ReactComponent as LandPlot_30_534 } from "./land_plots/30/land_plot_534.svg";
import { ReactComponent as LandPlot_30_535 } from "./land_plots/30/land_plot_535.svg";
import { ReactComponent as LandPlot_30_536 } from "./land_plots/30/land_plot_536.svg";
import { ReactComponent as LandPlot_30_537 } from "./land_plots/30/land_plot_537.svg";
import { ReactComponent as LandPlot_30_538 } from "./land_plots/30/land_plot_538.svg";
import { ReactComponent as LandPlot_30_539 } from "./land_plots/30/land_plot_539.svg";
import { ReactComponent as LandPlot_30_540 } from "./land_plots/30/land_plot_540.svg";
import { ReactComponent as LandPlot_30_541 } from "./land_plots/30/land_plot_541.svg";
import { ReactComponent as LandPlot_30_542 } from "./land_plots/30/land_plot_542.svg";
import { ReactComponent as LandPlot_30_543 } from "./land_plots/30/land_plot_543.svg";
import { ReactComponent as LandPlot_30_544 } from "./land_plots/30/land_plot_544.svg";
import { ReactComponent as LandPlot_30_545 } from "./land_plots/30/land_plot_545.svg";
import { ReactComponent as LandPlot_30_546 } from "./land_plots/30/land_plot_546.svg";
import { ReactComponent as LandPlot_30_547 } from "./land_plots/30/land_plot_547.svg";
import { ReactComponent as LandPlot_30_548 } from "./land_plots/30/land_plot_548.svg";
import { ReactComponent as LandPlot_30_549 } from "./land_plots/30/land_plot_549.svg";
import { ReactComponent as LandPlot_30_550 } from "./land_plots/30/land_plot_550.svg";
import { ReactComponent as LandPlot_30_551 } from "./land_plots/30/land_plot_551.svg";
import { ReactComponent as LandPlot_30_552 } from "./land_plots/30/land_plot_552.svg";
import { ReactComponent as LandPlot_30_553 } from "./land_plots/30/land_plot_553.svg";
import { ReactComponent as LandPlot_30_554 } from "./land_plots/30/land_plot_554.svg";
import { ReactComponent as LandPlot_30_555 } from "./land_plots/30/land_plot_555.svg";
import { ReactComponent as LandPlot_30_556 } from "./land_plots/30/land_plot_556.svg";
import { ReactComponent as LandPlot_30_557 } from "./land_plots/30/land_plot_557.svg";
import { ReactComponent as LandPlot_30_558 } from "./land_plots/30/land_plot_558.svg";
import { ReactComponent as LandPlot_30_559 } from "./land_plots/30/land_plot_559.svg";
import { ReactComponent as LandPlot_30_560 } from "./land_plots/30/land_plot_560.svg";
import { ReactComponent as LandPlot_30_561 } from "./land_plots/30/land_plot_561.svg";
import { ReactComponent as LandPlot_30_562 } from "./land_plots/30/land_plot_562.svg";
import { ReactComponent as LandPlot_30_563 } from "./land_plots/30/land_plot_563.svg";
import { ReactComponent as LandPlot_30_564 } from "./land_plots/30/land_plot_564.svg";
import { ReactComponent as LandPlot_30_565 } from "./land_plots/30/land_plot_565.svg";
import { ReactComponent as LandPlot_30_566 } from "./land_plots/30/land_plot_566.svg";
import { ReactComponent as LandPlot_30_567 } from "./land_plots/30/land_plot_567.svg";
import { ReactComponent as LandPlot_30_568 } from "./land_plots/30/land_plot_568.svg";
import { ReactComponent as LandPlot_30_569 } from "./land_plots/30/land_plot_569.svg";
import { ReactComponent as LandPlot_30_570 } from "./land_plots/30/land_plot_570.svg";
import { ReactComponent as LandPlot_30_571 } from "./land_plots/30/land_plot_571.svg";
import { ReactComponent as LandPlot_30_572 } from "./land_plots/30/land_plot_572.svg";
import { ReactComponent as LandPlot_30_573 } from "./land_plots/30/land_plot_573.svg";
import { ReactComponent as LandPlot_30_574 } from "./land_plots/30/land_plot_574.svg";
import { ReactComponent as LandPlot_30_575 } from "./land_plots/30/land_plot_575.svg";
import { ReactComponent as LandPlot_30_576 } from "./land_plots/30/land_plot_576.svg";
import { ReactComponent as LandPlot_30_577 } from "./land_plots/30/land_plot_577.svg";
import { ReactComponent as LandPlot_30_578 } from "./land_plots/30/land_plot_578.svg";
import { ReactComponent as LandPlot_30_579 } from "./land_plots/30/land_plot_579.svg";
import { ReactComponent as LandPlot_30_580 } from "./land_plots/30/land_plot_580.svg";
import { ReactComponent as LandPlot_30_581 } from "./land_plots/30/land_plot_581.svg";
import { ReactComponent as LandPlot_30_582 } from "./land_plots/30/land_plot_582.svg";
import { ReactComponent as LandPlot_30_583 } from "./land_plots/30/land_plot_583.svg";
import { ReactComponent as LandPlot_30_584 } from "./land_plots/30/land_plot_584.svg";
import { ReactComponent as LandPlot_30_585 } from "./land_plots/30/land_plot_585.svg";
import { ReactComponent as LandPlot_30_586 } from "./land_plots/30/land_plot_586.svg";
import { ReactComponent as LandPlot_30_587 } from "./land_plots/30/land_plot_587.svg";
import { ReactComponent as LandPlot_30_588 } from "./land_plots/30/land_plot_588.svg";
import { ReactComponent as LandPlot_30_589 } from "./land_plots/30/land_plot_589.svg";
import { ReactComponent as LandPlot_30_590 } from "./land_plots/30/land_plot_590.svg";
import { ReactComponent as LandPlot_30_591 } from "./land_plots/30/land_plot_591.svg";
import { ReactComponent as LandPlot_30_592 } from "./land_plots/30/land_plot_592.svg";
import { ReactComponent as LandPlot_30_593 } from "./land_plots/30/land_plot_593.svg";
import { ReactComponent as LandPlot_30_594 } from "./land_plots/30/land_plot_594.svg";
import { ReactComponent as LandPlot_30_595 } from "./land_plots/30/land_plot_595.svg";
import { ReactComponent as LandPlot_30_596 } from "./land_plots/30/land_plot_596.svg";
import { ReactComponent as LandPlot_30_597 } from "./land_plots/30/land_plot_597.svg";
import { ReactComponent as LandPlot_30_598 } from "./land_plots/30/land_plot_598.svg";
import { ReactComponent as LandPlot_30_599 } from "./land_plots/30/land_plot_599.svg";
import { ReactComponent as LandPlot_30_600 } from "./land_plots/30/land_plot_600.svg";
import { ReactComponent as LandPlot_30_601 } from "./land_plots/30/land_plot_601.svg";
import { ReactComponent as LandPlot_30_602 } from "./land_plots/30/land_plot_602.svg";
import { ReactComponent as LandPlot_30_603 } from "./land_plots/30/land_plot_603.svg";
import { ReactComponent as LandPlot_30_604 } from "./land_plots/30/land_plot_604.svg";
import { ReactComponent as LandPlot_30_605 } from "./land_plots/30/land_plot_605.svg";
import { ReactComponent as LandPlot_30_606 } from "./land_plots/30/land_plot_606.svg";
import { ReactComponent as LandPlot_30_607 } from "./land_plots/30/land_plot_607.svg";
import { ReactComponent as LandPlot_30_608 } from "./land_plots/30/land_plot_608.svg";
import { ReactComponent as LandPlot_30_609 } from "./land_plots/30/land_plot_609.svg";
import { ReactComponent as LandPlot_30_610 } from "./land_plots/30/land_plot_610.svg";
import { ReactComponent as LandPlot_30_611 } from "./land_plots/30/land_plot_611.svg";
import { ReactComponent as LandPlot_30_612 } from "./land_plots/30/land_plot_612.svg";
import { ReactComponent as LandPlot_30_613 } from "./land_plots/30/land_plot_613.svg";
import { ReactComponent as LandPlot_30_614 } from "./land_plots/30/land_plot_614.svg";
import { ReactComponent as LandPlot_30_615 } from "./land_plots/30/land_plot_615.svg";
import { ReactComponent as LandPlot_30_616 } from "./land_plots/30/land_plot_616.svg";
import { ReactComponent as LandPlot_30_617 } from "./land_plots/30/land_plot_617.svg";
import { ReactComponent as LandPlot_30_618 } from "./land_plots/30/land_plot_618.svg";
import { ReactComponent as LandPlot_30_619 } from "./land_plots/30/land_plot_619.svg";
import { ReactComponent as LandPlot_30_620 } from "./land_plots/30/land_plot_620.svg";
import { ReactComponent as LandPlot_30_621 } from "./land_plots/30/land_plot_621.svg";
import { ReactComponent as LandPlot_30_622 } from "./land_plots/30/land_plot_622.svg";
import { ReactComponent as LandPlot_30_623 } from "./land_plots/30/land_plot_623.svg";
import { ReactComponent as LandPlot_30_624 } from "./land_plots/30/land_plot_624.svg";
import { ReactComponent as LandPlot_30_625 } from "./land_plots/30/land_plot_625.svg";
import { ReactComponent as LandPlot_30_626 } from "./land_plots/30/land_plot_626.svg";
import { ReactComponent as LandPlot_30_627 } from "./land_plots/30/land_plot_627.svg";
import { ReactComponent as LandPlot_30_628 } from "./land_plots/30/land_plot_628.svg";
import { ReactComponent as LandPlot_30_629 } from "./land_plots/30/land_plot_629.svg";
import { ReactComponent as LandPlot_30_630 } from "./land_plots/30/land_plot_630.svg";
import { ReactComponent as LandPlot_30_631 } from "./land_plots/30/land_plot_631.svg";
import { ReactComponent as LandPlot_30_632 } from "./land_plots/30/land_plot_632.svg";
import { ReactComponent as LandPlot_30_633 } from "./land_plots/30/land_plot_633.svg";
import { ReactComponent as LandPlot_30_634 } from "./land_plots/30/land_plot_634.svg";
import { ReactComponent as LandPlot_30_635 } from "./land_plots/30/land_plot_635.svg";
import { ReactComponent as LandPlot_30_636 } from "./land_plots/30/land_plot_636.svg";
import { ReactComponent as LandPlot_30_637 } from "./land_plots/30/land_plot_637.svg";
import { ReactComponent as LandPlot_30_638 } from "./land_plots/30/land_plot_638.svg";
import { ReactComponent as LandPlot_30_639 } from "./land_plots/30/land_plot_639.svg";
import { ReactComponent as LandPlot_30_640 } from "./land_plots/30/land_plot_640.svg";
import { ReactComponent as LandPlot_30_641 } from "./land_plots/30/land_plot_641.svg";
import { ReactComponent as LandPlot_30_642 } from "./land_plots/30/land_plot_642.svg";
import { ReactComponent as LandPlot_30_643 } from "./land_plots/30/land_plot_643.svg";
import { ReactComponent as LandPlot_30_644 } from "./land_plots/30/land_plot_644.svg";
import { ReactComponent as LandPlot_30_645 } from "./land_plots/30/land_plot_645.svg";
import { ReactComponent as LandPlot_30_646 } from "./land_plots/30/land_plot_646.svg";
import { ReactComponent as LandPlot_30_647 } from "./land_plots/30/land_plot_647.svg";
import { ReactComponent as LandPlot_30_648 } from "./land_plots/30/land_plot_648.svg";
import { ReactComponent as LandPlot_30_649 } from "./land_plots/30/land_plot_649.svg";
import { ReactComponent as LandPlot_30_650 } from "./land_plots/30/land_plot_650.svg";
import { ReactComponent as LandPlot_30_651 } from "./land_plots/30/land_plot_651.svg";
import { ReactComponent as LandPlot_30_652 } from "./land_plots/30/land_plot_652.svg";
import { ReactComponent as LandPlot_30_653 } from "./land_plots/30/land_plot_653.svg";
import { ReactComponent as LandPlot_30_654 } from "./land_plots/30/land_plot_654.svg";
import { ReactComponent as LandPlot_30_655 } from "./land_plots/30/land_plot_655.svg";
import { ReactComponent as LandPlot_30_656 } from "./land_plots/30/land_plot_656.svg";
import { ReactComponent as LandPlot_30_657 } from "./land_plots/30/land_plot_657.svg";
import { ReactComponent as LandPlot_30_658 } from "./land_plots/30/land_plot_658.svg";
import { ReactComponent as LandPlot_30_659 } from "./land_plots/30/land_plot_659.svg";
import { ReactComponent as LandPlot_30_660 } from "./land_plots/30/land_plot_660.svg";
import { ReactComponent as LandPlot_30_661 } from "./land_plots/30/land_plot_661.svg";
import { ReactComponent as LandPlot_30_662 } from "./land_plots/30/land_plot_662.svg";
import { ReactComponent as LandPlot_30_663 } from "./land_plots/30/land_plot_663.svg";
import { ReactComponent as LandPlot_30_664 } from "./land_plots/30/land_plot_664.svg";
import { ReactComponent as LandPlot_30_665 } from "./land_plots/30/land_plot_665.svg";
import { ReactComponent as LandPlot_30_666 } from "./land_plots/30/land_plot_666.svg";
import { ReactComponent as LandPlot_30_667 } from "./land_plots/30/land_plot_667.svg";
import { ReactComponent as LandPlot_30_668 } from "./land_plots/30/land_plot_668.svg";
import { ReactComponent as LandPlot_30_669 } from "./land_plots/30/land_plot_669.svg";
import { ReactComponent as LandPlot_30_670 } from "./land_plots/30/land_plot_670.svg";
import { ReactComponent as LandPlot_30_671 } from "./land_plots/30/land_plot_671.svg";
import { ReactComponent as LandPlot_30_672 } from "./land_plots/30/land_plot_672.svg";
import { ReactComponent as LandPlot_30_673 } from "./land_plots/30/land_plot_673.svg";
import { ReactComponent as LandPlot_30_674 } from "./land_plots/30/land_plot_674.svg";
import { ReactComponent as LandPlot_30_675 } from "./land_plots/30/land_plot_675.svg";
import { ReactComponent as LandPlot_30_676 } from "./land_plots/30/land_plot_676.svg";
import { ReactComponent as LandPlot_30_677 } from "./land_plots/30/land_plot_677.svg";
import { ReactComponent as LandPlot_30_678 } from "./land_plots/30/land_plot_678.svg";
import { ReactComponent as LandPlot_30_679 } from "./land_plots/30/land_plot_679.svg";
import { ReactComponent as LandPlot_30_680 } from "./land_plots/30/land_plot_680.svg";

///////////////////////////////////////////////////////////////////////////////////stop frame 60
import { ReactComponent as LandPlot_60_55 } from "./land_plots/60/land_plot_55.svg";
import { ReactComponent as LandPlot_60_56 } from "./land_plots/60/land_plot_56.svg";
import { ReactComponent as LandPlot_60_57 } from "./land_plots/60/land_plot_57.svg";
import { ReactComponent as LandPlot_60_58 } from "./land_plots/60/land_plot_58.svg";
import { ReactComponent as LandPlot_60_59 } from "./land_plots/60/land_plot_59.svg";
import { ReactComponent as LandPlot_60_60 } from "./land_plots/60/land_plot_60.svg";
import { ReactComponent as LandPlot_60_61 } from "./land_plots/60/land_plot_61.svg";
import { ReactComponent as LandPlot_60_450 } from "./land_plots/60/land_plot_450.svg";
import { ReactComponent as LandPlot_60_451 } from "./land_plots/60/land_plot_451.svg";
import { ReactComponent as LandPlot_60_452 } from "./land_plots/60/land_plot_452.svg";
import { ReactComponent as LandPlot_60_453 } from "./land_plots/60/land_plot_453.svg";
import { ReactComponent as LandPlot_60_454 } from "./land_plots/60/land_plot_454.svg";
import { ReactComponent as LandPlot_60_455 } from "./land_plots/60/land_plot_455.svg";
import { ReactComponent as LandPlot_60_456 } from "./land_plots/60/land_plot_456.svg";
import { ReactComponent as LandPlot_60_457 } from "./land_plots/60/land_plot_457.svg";
import { ReactComponent as LandPlot_60_458 } from "./land_plots/60/land_plot_458.svg";
import { ReactComponent as LandPlot_60_459 } from "./land_plots/60/land_plot_459.svg";
import { ReactComponent as LandPlot_60_460 } from "./land_plots/60/land_plot_460.svg";
import { ReactComponent as LandPlot_60_461 } from "./land_plots/60/land_plot_461.svg";
import { ReactComponent as LandPlot_60_462 } from "./land_plots/60/land_plot_462.svg";
import { ReactComponent as LandPlot_60_463 } from "./land_plots/60/land_plot_463.svg";
import { ReactComponent as LandPlot_60_464 } from "./land_plots/60/land_plot_464.svg";
import { ReactComponent as LandPlot_60_465 } from "./land_plots/60/land_plot_465.svg";
import { ReactComponent as LandPlot_60_466 } from "./land_plots/60/land_plot_466.svg";
import { ReactComponent as LandPlot_60_467 } from "./land_plots/60/land_plot_467.svg";
import { ReactComponent as LandPlot_60_468 } from "./land_plots/60/land_plot_468.svg";
import { ReactComponent as LandPlot_60_469 } from "./land_plots/60/land_plot_469.svg";
import { ReactComponent as LandPlot_60_470 } from "./land_plots/60/land_plot_470.svg";
import { ReactComponent as LandPlot_60_471 } from "./land_plots/60/land_plot_471.svg";
import { ReactComponent as LandPlot_60_472 } from "./land_plots/60/land_plot_472.svg";
import { ReactComponent as LandPlot_60_473 } from "./land_plots/60/land_plot_473.svg";
import { ReactComponent as LandPlot_60_474 } from "./land_plots/60/land_plot_474.svg";
import { ReactComponent as LandPlot_60_475 } from "./land_plots/60/land_plot_475.svg";
import { ReactComponent as LandPlot_60_476 } from "./land_plots/60/land_plot_476.svg";
import { ReactComponent as LandPlot_60_477 } from "./land_plots/60/land_plot_477.svg";
import { ReactComponent as LandPlot_60_478 } from "./land_plots/60/land_plot_478.svg";
import { ReactComponent as LandPlot_60_479 } from "./land_plots/60/land_plot_479.svg";
import { ReactComponent as LandPlot_60_480 } from "./land_plots/60/land_plot_480.svg";
import { ReactComponent as LandPlot_60_481 } from "./land_plots/60/land_plot_481.svg";
import { ReactComponent as LandPlot_60_482 } from "./land_plots/60/land_plot_482.svg";
import { ReactComponent as LandPlot_60_483 } from "./land_plots/60/land_plot_483.svg";
import { ReactComponent as LandPlot_60_484 } from "./land_plots/60/land_plot_484.svg";
import { ReactComponent as LandPlot_60_485 } from "./land_plots/60/land_plot_485.svg";
import { ReactComponent as LandPlot_60_486 } from "./land_plots/60/land_plot_486.svg";
import { ReactComponent as LandPlot_60_487 } from "./land_plots/60/land_plot_487.svg";
import { ReactComponent as LandPlot_60_488 } from "./land_plots/60/land_plot_488.svg";
import { ReactComponent as LandPlot_60_489 } from "./land_plots/60/land_plot_489.svg";
import { ReactComponent as LandPlot_60_490 } from "./land_plots/60/land_plot_490.svg";
import { ReactComponent as LandPlot_60_491 } from "./land_plots/60/land_plot_491.svg";
import { ReactComponent as LandPlot_60_492 } from "./land_plots/60/land_plot_492.svg";
import { ReactComponent as LandPlot_60_493 } from "./land_plots/60/land_plot_493.svg";
import { ReactComponent as LandPlot_60_494 } from "./land_plots/60/land_plot_494.svg";
import { ReactComponent as LandPlot_60_495 } from "./land_plots/60/land_plot_495.svg";
import { ReactComponent as LandPlot_60_496 } from "./land_plots/60/land_plot_496.svg";
import { ReactComponent as LandPlot_60_497 } from "./land_plots/60/land_plot_497.svg";
import { ReactComponent as LandPlot_60_498 } from "./land_plots/60/land_plot_498.svg";
import { ReactComponent as LandPlot_60_499 } from "./land_plots/60/land_plot_499.svg";
import { ReactComponent as LandPlot_60_500 } from "./land_plots/60/land_plot_500.svg";
import { ReactComponent as LandPlot_60_501 } from "./land_plots/60/land_plot_501.svg";
import { ReactComponent as LandPlot_60_502 } from "./land_plots/60/land_plot_502.svg";
import { ReactComponent as LandPlot_60_503 } from "./land_plots/60/land_plot_503.svg";
import { ReactComponent as LandPlot_60_504 } from "./land_plots/60/land_plot_504.svg";
import { ReactComponent as LandPlot_60_505 } from "./land_plots/60/land_plot_505.svg";
import { ReactComponent as LandPlot_60_506 } from "./land_plots/60/land_plot_506.svg";
import { ReactComponent as LandPlot_60_507 } from "./land_plots/60/land_plot_507.svg";
import { ReactComponent as LandPlot_60_508 } from "./land_plots/60/land_plot_508.svg";
import { ReactComponent as LandPlot_60_509 } from "./land_plots/60/land_plot_509.svg";
import { ReactComponent as LandPlot_60_510 } from "./land_plots/60/land_plot_510.svg";
import { ReactComponent as LandPlot_60_511 } from "./land_plots/60/land_plot_511.svg";
import { ReactComponent as LandPlot_60_512 } from "./land_plots/60/land_plot_512.svg";
import { ReactComponent as LandPlot_60_513 } from "./land_plots/60/land_plot_513.svg";
import { ReactComponent as LandPlot_60_514 } from "./land_plots/60/land_plot_514.svg";
import { ReactComponent as LandPlot_60_515 } from "./land_plots/60/land_plot_515.svg";
import { ReactComponent as LandPlot_60_516 } from "./land_plots/60/land_plot_516.svg";
import { ReactComponent as LandPlot_60_517 } from "./land_plots/60/land_plot_517.svg";
import { ReactComponent as LandPlot_60_518 } from "./land_plots/60/land_plot_518.svg";
import { ReactComponent as LandPlot_60_519 } from "./land_plots/60/land_plot_519.svg";
import { ReactComponent as LandPlot_60_520 } from "./land_plots/60/land_plot_520.svg";
import { ReactComponent as LandPlot_60_521 } from "./land_plots/60/land_plot_521.svg";
import { ReactComponent as LandPlot_60_522 } from "./land_plots/60/land_plot_522.svg";
import { ReactComponent as LandPlot_60_523 } from "./land_plots/60/land_plot_523.svg";
import { ReactComponent as LandPlot_60_524 } from "./land_plots/60/land_plot_524.svg";
import { ReactComponent as LandPlot_60_525 } from "./land_plots/60/land_plot_525.svg";
import { ReactComponent as LandPlot_60_526 } from "./land_plots/60/land_plot_526.svg";
import { ReactComponent as LandPlot_60_527 } from "./land_plots/60/land_plot_527.svg";
import { ReactComponent as LandPlot_60_528 } from "./land_plots/60/land_plot_528.svg";
import { ReactComponent as LandPlot_60_529 } from "./land_plots/60/land_plot_529.svg";
import { ReactComponent as LandPlot_60_530 } from "./land_plots/60/land_plot_530.svg";
import { ReactComponent as LandPlot_60_531 } from "./land_plots/60/land_plot_531.svg";
import { ReactComponent as LandPlot_60_532 } from "./land_plots/60/land_plot_532.svg";
import { ReactComponent as LandPlot_60_533 } from "./land_plots/60/land_plot_533.svg";
import { ReactComponent as LandPlot_60_534 } from "./land_plots/60/land_plot_534.svg";
import { ReactComponent as LandPlot_60_535 } from "./land_plots/60/land_plot_535.svg";
import { ReactComponent as LandPlot_60_536 } from "./land_plots/60/land_plot_536.svg";
import { ReactComponent as LandPlot_60_537 } from "./land_plots/60/land_plot_537.svg";
import { ReactComponent as LandPlot_60_538 } from "./land_plots/60/land_plot_538.svg";
import { ReactComponent as LandPlot_60_539 } from "./land_plots/60/land_plot_539.svg";
import { ReactComponent as LandPlot_60_540 } from "./land_plots/60/land_plot_540.svg";
import { ReactComponent as LandPlot_60_541 } from "./land_plots/60/land_plot_541.svg";
import { ReactComponent as LandPlot_60_542 } from "./land_plots/60/land_plot_542.svg";
import { ReactComponent as LandPlot_60_543 } from "./land_plots/60/land_plot_543.svg";
import { ReactComponent as LandPlot_60_544 } from "./land_plots/60/land_plot_544.svg";
import { ReactComponent as LandPlot_60_545 } from "./land_plots/60/land_plot_545.svg";
import { ReactComponent as LandPlot_60_546 } from "./land_plots/60/land_plot_546.svg";
import { ReactComponent as LandPlot_60_547 } from "./land_plots/60/land_plot_547.svg";
import { ReactComponent as LandPlot_60_548 } from "./land_plots/60/land_plot_548.svg";
import { ReactComponent as LandPlot_60_549 } from "./land_plots/60/land_plot_549.svg";
import { ReactComponent as LandPlot_60_550 } from "./land_plots/60/land_plot_550.svg";
import { ReactComponent as LandPlot_60_551 } from "./land_plots/60/land_plot_551.svg";
import { ReactComponent as LandPlot_60_552 } from "./land_plots/60/land_plot_552.svg";
import { ReactComponent as LandPlot_60_553 } from "./land_plots/60/land_plot_553.svg";
import { ReactComponent as LandPlot_60_554 } from "./land_plots/60/land_plot_554.svg";
import { ReactComponent as LandPlot_60_555 } from "./land_plots/60/land_plot_555.svg";
import { ReactComponent as LandPlot_60_556 } from "./land_plots/60/land_plot_556.svg";
import { ReactComponent as LandPlot_60_557 } from "./land_plots/60/land_plot_557.svg";
import { ReactComponent as LandPlot_60_558 } from "./land_plots/60/land_plot_558.svg";
import { ReactComponent as LandPlot_60_559 } from "./land_plots/60/land_plot_559.svg";
import { ReactComponent as LandPlot_60_560 } from "./land_plots/60/land_plot_560.svg";
import { ReactComponent as LandPlot_60_561 } from "./land_plots/60/land_plot_561.svg";
import { ReactComponent as LandPlot_60_562 } from "./land_plots/60/land_plot_562.svg";
import { ReactComponent as LandPlot_60_563 } from "./land_plots/60/land_plot_563.svg";
import { ReactComponent as LandPlot_60_564 } from "./land_plots/60/land_plot_564.svg";
import { ReactComponent as LandPlot_60_565 } from "./land_plots/60/land_plot_565.svg";
import { ReactComponent as LandPlot_60_566 } from "./land_plots/60/land_plot_566.svg";
import { ReactComponent as LandPlot_60_567 } from "./land_plots/60/land_plot_567.svg";
import { ReactComponent as LandPlot_60_568 } from "./land_plots/60/land_plot_568.svg";
import { ReactComponent as LandPlot_60_569 } from "./land_plots/60/land_plot_569.svg";
import { ReactComponent as LandPlot_60_570 } from "./land_plots/60/land_plot_570.svg";
import { ReactComponent as LandPlot_60_571 } from "./land_plots/60/land_plot_571.svg";
import { ReactComponent as LandPlot_60_572 } from "./land_plots/60/land_plot_572.svg";
import { ReactComponent as LandPlot_60_573 } from "./land_plots/60/land_plot_573.svg";
import { ReactComponent as LandPlot_60_574 } from "./land_plots/60/land_plot_574.svg";
import { ReactComponent as LandPlot_60_575 } from "./land_plots/60/land_plot_575.svg";
import { ReactComponent as LandPlot_60_576 } from "./land_plots/60/land_plot_576.svg";
import { ReactComponent as LandPlot_60_577 } from "./land_plots/60/land_plot_577.svg";
import { ReactComponent as LandPlot_60_578 } from "./land_plots/60/land_plot_578.svg";
import { ReactComponent as LandPlot_60_579 } from "./land_plots/60/land_plot_579.svg";
import { ReactComponent as LandPlot_60_580 } from "./land_plots/60/land_plot_580.svg";
import { ReactComponent as LandPlot_60_581 } from "./land_plots/60/land_plot_581.svg";
import { ReactComponent as LandPlot_60_582 } from "./land_plots/60/land_plot_582.svg";
import { ReactComponent as LandPlot_60_583 } from "./land_plots/60/land_plot_583.svg";
import { ReactComponent as LandPlot_60_584 } from "./land_plots/60/land_plot_584.svg";
import { ReactComponent as LandPlot_60_585 } from "./land_plots/60/land_plot_585.svg";
import { ReactComponent as LandPlot_60_586 } from "./land_plots/60/land_plot_586.svg";
import { ReactComponent as LandPlot_60_587 } from "./land_plots/60/land_plot_587.svg";
import { ReactComponent as LandPlot_60_588 } from "./land_plots/60/land_plot_588.svg";
import { ReactComponent as LandPlot_60_589 } from "./land_plots/60/land_plot_589.svg";
import { ReactComponent as LandPlot_60_590 } from "./land_plots/60/land_plot_590.svg";
import { ReactComponent as LandPlot_60_591 } from "./land_plots/60/land_plot_591.svg";
import { ReactComponent as LandPlot_60_592 } from "./land_plots/60/land_plot_592.svg";
import { ReactComponent as LandPlot_60_593 } from "./land_plots/60/land_plot_593.svg";
import { ReactComponent as LandPlot_60_594 } from "./land_plots/60/land_plot_594.svg";
import { ReactComponent as LandPlot_60_595 } from "./land_plots/60/land_plot_595.svg";
import { ReactComponent as LandPlot_60_596 } from "./land_plots/60/land_plot_596.svg";
import { ReactComponent as LandPlot_60_597 } from "./land_plots/60/land_plot_597.svg";
import { ReactComponent as LandPlot_60_598 } from "./land_plots/60/land_plot_598.svg";
import { ReactComponent as LandPlot_60_599 } from "./land_plots/60/land_plot_599.svg";
import { ReactComponent as LandPlot_60_600 } from "./land_plots/60/land_plot_600.svg";
import { ReactComponent as LandPlot_60_601 } from "./land_plots/60/land_plot_601.svg";
import { ReactComponent as LandPlot_60_602 } from "./land_plots/60/land_plot_602.svg";
import { ReactComponent as LandPlot_60_603 } from "./land_plots/60/land_plot_603.svg";
import { ReactComponent as LandPlot_60_604 } from "./land_plots/60/land_plot_604.svg";
import { ReactComponent as LandPlot_60_605 } from "./land_plots/60/land_plot_605.svg";
import { ReactComponent as LandPlot_60_606 } from "./land_plots/60/land_plot_606.svg";
import { ReactComponent as LandPlot_60_607 } from "./land_plots/60/land_plot_607.svg";
import { ReactComponent as LandPlot_60_608 } from "./land_plots/60/land_plot_608.svg";
import { ReactComponent as LandPlot_60_609 } from "./land_plots/60/land_plot_609.svg";
import { ReactComponent as LandPlot_60_610 } from "./land_plots/60/land_plot_610.svg";
import { ReactComponent as LandPlot_60_611 } from "./land_plots/60/land_plot_611.svg";
import { ReactComponent as LandPlot_60_612 } from "./land_plots/60/land_plot_612.svg";
import { ReactComponent as LandPlot_60_613 } from "./land_plots/60/land_plot_613.svg";
import { ReactComponent as LandPlot_60_614 } from "./land_plots/60/land_plot_614.svg";
import { ReactComponent as LandPlot_60_615 } from "./land_plots/60/land_plot_615.svg";
import { ReactComponent as LandPlot_60_616 } from "./land_plots/60/land_plot_616.svg";
import { ReactComponent as LandPlot_60_617 } from "./land_plots/60/land_plot_617.svg";
import { ReactComponent as LandPlot_60_618 } from "./land_plots/60/land_plot_618.svg";
import { ReactComponent as LandPlot_60_619 } from "./land_plots/60/land_plot_619.svg";
import { ReactComponent as LandPlot_60_620 } from "./land_plots/60/land_plot_620.svg";
import { ReactComponent as LandPlot_60_621 } from "./land_plots/60/land_plot_621.svg";
import { ReactComponent as LandPlot_60_622 } from "./land_plots/60/land_plot_622.svg";
import { ReactComponent as LandPlot_60_623 } from "./land_plots/60/land_plot_623.svg";
import { ReactComponent as LandPlot_60_624 } from "./land_plots/60/land_plot_624.svg";
import { ReactComponent as LandPlot_60_625 } from "./land_plots/60/land_plot_625.svg";
import { ReactComponent as LandPlot_60_626 } from "./land_plots/60/land_plot_626.svg";
import { ReactComponent as LandPlot_60_627 } from "./land_plots/60/land_plot_627.svg";
import { ReactComponent as LandPlot_60_628 } from "./land_plots/60/land_plot_628.svg";
import { ReactComponent as LandPlot_60_629 } from "./land_plots/60/land_plot_629.svg";
import { ReactComponent as LandPlot_60_630 } from "./land_plots/60/land_plot_630.svg";
import { ReactComponent as LandPlot_60_631 } from "./land_plots/60/land_plot_631.svg";
import { ReactComponent as LandPlot_60_632 } from "./land_plots/60/land_plot_632.svg";
import { ReactComponent as LandPlot_60_633 } from "./land_plots/60/land_plot_633.svg";
import { ReactComponent as LandPlot_60_634 } from "./land_plots/60/land_plot_634.svg";
import { ReactComponent as LandPlot_60_635 } from "./land_plots/60/land_plot_635.svg";
import { ReactComponent as LandPlot_60_636 } from "./land_plots/60/land_plot_636.svg";
import { ReactComponent as LandPlot_60_637 } from "./land_plots/60/land_plot_637.svg";
import { ReactComponent as LandPlot_60_638 } from "./land_plots/60/land_plot_638.svg";
import { ReactComponent as LandPlot_60_639 } from "./land_plots/60/land_plot_639.svg";
import { ReactComponent as LandPlot_60_640 } from "./land_plots/60/land_plot_640.svg";
import { ReactComponent as LandPlot_60_641 } from "./land_plots/60/land_plot_641.svg";
import { ReactComponent as LandPlot_60_642 } from "./land_plots/60/land_plot_642.svg";
import { ReactComponent as LandPlot_60_643 } from "./land_plots/60/land_plot_643.svg";
import { ReactComponent as LandPlot_60_644 } from "./land_plots/60/land_plot_644.svg";
import { ReactComponent as LandPlot_60_645 } from "./land_plots/60/land_plot_645.svg";
import { ReactComponent as LandPlot_60_646 } from "./land_plots/60/land_plot_646.svg";
import { ReactComponent as LandPlot_60_647 } from "./land_plots/60/land_plot_647.svg";
import { ReactComponent as LandPlot_60_648 } from "./land_plots/60/land_plot_648.svg";
import { ReactComponent as LandPlot_60_649 } from "./land_plots/60/land_plot_649.svg";
import { ReactComponent as LandPlot_60_650 } from "./land_plots/60/land_plot_650.svg";
import { ReactComponent as LandPlot_60_651 } from "./land_plots/60/land_plot_651.svg";
import { ReactComponent as LandPlot_60_652 } from "./land_plots/60/land_plot_652.svg";
import { ReactComponent as LandPlot_60_653 } from "./land_plots/60/land_plot_653.svg";
import { ReactComponent as LandPlot_60_654 } from "./land_plots/60/land_plot_654.svg";
import { ReactComponent as LandPlot_60_655 } from "./land_plots/60/land_plot_655.svg";
import { ReactComponent as LandPlot_60_656 } from "./land_plots/60/land_plot_656.svg";
import { ReactComponent as LandPlot_60_657 } from "./land_plots/60/land_plot_657.svg";
import { ReactComponent as LandPlot_60_658 } from "./land_plots/60/land_plot_658.svg";
import { ReactComponent as LandPlot_60_659 } from "./land_plots/60/land_plot_659.svg";
import { ReactComponent as LandPlot_60_660 } from "./land_plots/60/land_plot_660.svg";
import { ReactComponent as LandPlot_60_661 } from "./land_plots/60/land_plot_661.svg";
import { ReactComponent as LandPlot_60_662 } from "./land_plots/60/land_plot_662.svg";
import { ReactComponent as LandPlot_60_663 } from "./land_plots/60/land_plot_663.svg";
import { ReactComponent as LandPlot_60_664 } from "./land_plots/60/land_plot_664.svg";
import { ReactComponent as LandPlot_60_665 } from "./land_plots/60/land_plot_665.svg";
import { ReactComponent as LandPlot_60_666 } from "./land_plots/60/land_plot_666.svg";
import { ReactComponent as LandPlot_60_667 } from "./land_plots/60/land_plot_667.svg";
import { ReactComponent as LandPlot_60_668 } from "./land_plots/60/land_plot_668.svg";
import { ReactComponent as LandPlot_60_669 } from "./land_plots/60/land_plot_669.svg";
import { ReactComponent as LandPlot_60_670 } from "./land_plots/60/land_plot_670.svg";
import { ReactComponent as LandPlot_60_671 } from "./land_plots/60/land_plot_671.svg";
import { ReactComponent as LandPlot_60_672 } from "./land_plots/60/land_plot_672.svg";
import { ReactComponent as LandPlot_60_673 } from "./land_plots/60/land_plot_673.svg";
import { ReactComponent as LandPlot_60_674 } from "./land_plots/60/land_plot_674.svg";
import { ReactComponent as LandPlot_60_675 } from "./land_plots/60/land_plot_675.svg";
import { ReactComponent as LandPlot_60_676 } from "./land_plots/60/land_plot_676.svg";
import { ReactComponent as LandPlot_60_677 } from "./land_plots/60/land_plot_677.svg";
import { ReactComponent as LandPlot_60_678 } from "./land_plots/60/land_plot_678.svg";
import { ReactComponent as LandPlot_60_679 } from "./land_plots/60/land_plot_679.svg";
import { ReactComponent as LandPlot_60_680 } from "./land_plots/60/land_plot_680.svg";

///////////////////////////////////////////////////////////////////////////////////stop frame 92
import { ReactComponent as LandPlot_92_55 } from "./land_plots/92/land_plot_55.svg";
import { ReactComponent as LandPlot_92_56 } from "./land_plots/92/land_plot_56.svg";
import { ReactComponent as LandPlot_92_57 } from "./land_plots/92/land_plot_57.svg";
import { ReactComponent as LandPlot_92_58 } from "./land_plots/92/land_plot_58.svg";
import { ReactComponent as LandPlot_92_59 } from "./land_plots/92/land_plot_59.svg";
import { ReactComponent as LandPlot_92_60 } from "./land_plots/92/land_plot_60.svg";
import { ReactComponent as LandPlot_92_61 } from "./land_plots/92/land_plot_61.svg";
import { ReactComponent as LandPlot_92_450 } from "./land_plots/92/land_plot_450.svg";
import { ReactComponent as LandPlot_92_451 } from "./land_plots/92/land_plot_451.svg";
import { ReactComponent as LandPlot_92_452 } from "./land_plots/92/land_plot_452.svg";
import { ReactComponent as LandPlot_92_453 } from "./land_plots/92/land_plot_453.svg";
import { ReactComponent as LandPlot_92_454 } from "./land_plots/92/land_plot_454.svg";
import { ReactComponent as LandPlot_92_455 } from "./land_plots/92/land_plot_455.svg";
import { ReactComponent as LandPlot_92_456 } from "./land_plots/92/land_plot_456.svg";
import { ReactComponent as LandPlot_92_457 } from "./land_plots/92/land_plot_457.svg";
import { ReactComponent as LandPlot_92_458 } from "./land_plots/92/land_plot_458.svg";
import { ReactComponent as LandPlot_92_459 } from "./land_plots/92/land_plot_459.svg";
import { ReactComponent as LandPlot_92_460 } from "./land_plots/92/land_plot_460.svg";
import { ReactComponent as LandPlot_92_461 } from "./land_plots/92/land_plot_461.svg";
import { ReactComponent as LandPlot_92_462 } from "./land_plots/92/land_plot_462.svg";
import { ReactComponent as LandPlot_92_463 } from "./land_plots/92/land_plot_463.svg";
import { ReactComponent as LandPlot_92_464 } from "./land_plots/92/land_plot_464.svg";
import { ReactComponent as LandPlot_92_465 } from "./land_plots/92/land_plot_465.svg";
import { ReactComponent as LandPlot_92_466 } from "./land_plots/92/land_plot_466.svg";
import { ReactComponent as LandPlot_92_467 } from "./land_plots/92/land_plot_467.svg";
import { ReactComponent as LandPlot_92_468 } from "./land_plots/92/land_plot_468.svg";
import { ReactComponent as LandPlot_92_469 } from "./land_plots/92/land_plot_469.svg";
import { ReactComponent as LandPlot_92_470 } from "./land_plots/92/land_plot_470.svg";
import { ReactComponent as LandPlot_92_471 } from "./land_plots/92/land_plot_471.svg";
import { ReactComponent as LandPlot_92_472 } from "./land_plots/92/land_plot_472.svg";
import { ReactComponent as LandPlot_92_473 } from "./land_plots/92/land_plot_473.svg";
import { ReactComponent as LandPlot_92_474 } from "./land_plots/92/land_plot_474.svg";
import { ReactComponent as LandPlot_92_475 } from "./land_plots/92/land_plot_475.svg";
import { ReactComponent as LandPlot_92_476 } from "./land_plots/92/land_plot_476.svg";
import { ReactComponent as LandPlot_92_477 } from "./land_plots/92/land_plot_477.svg";
import { ReactComponent as LandPlot_92_478 } from "./land_plots/92/land_plot_478.svg";
import { ReactComponent as LandPlot_92_479 } from "./land_plots/92/land_plot_479.svg";
import { ReactComponent as LandPlot_92_480 } from "./land_plots/92/land_plot_480.svg";
import { ReactComponent as LandPlot_92_481 } from "./land_plots/92/land_plot_481.svg";
import { ReactComponent as LandPlot_92_482 } from "./land_plots/92/land_plot_482.svg";
import { ReactComponent as LandPlot_92_483 } from "./land_plots/92/land_plot_483.svg";
import { ReactComponent as LandPlot_92_484 } from "./land_plots/92/land_plot_484.svg";
import { ReactComponent as LandPlot_92_485 } from "./land_plots/92/land_plot_485.svg";
import { ReactComponent as LandPlot_92_486 } from "./land_plots/92/land_plot_486.svg";
import { ReactComponent as LandPlot_92_487 } from "./land_plots/92/land_plot_487.svg";
import { ReactComponent as LandPlot_92_488 } from "./land_plots/92/land_plot_488.svg";
import { ReactComponent as LandPlot_92_489 } from "./land_plots/92/land_plot_489.svg";
import { ReactComponent as LandPlot_92_490 } from "./land_plots/92/land_plot_490.svg";
import { ReactComponent as LandPlot_92_491 } from "./land_plots/92/land_plot_491.svg";
import { ReactComponent as LandPlot_92_492 } from "./land_plots/92/land_plot_492.svg";
import { ReactComponent as LandPlot_92_493 } from "./land_plots/92/land_plot_493.svg";
import { ReactComponent as LandPlot_92_494 } from "./land_plots/92/land_plot_494.svg";
import { ReactComponent as LandPlot_92_495 } from "./land_plots/92/land_plot_495.svg";
import { ReactComponent as LandPlot_92_496 } from "./land_plots/92/land_plot_496.svg";
import { ReactComponent as LandPlot_92_497 } from "./land_plots/92/land_plot_497.svg";
import { ReactComponent as LandPlot_92_498 } from "./land_plots/92/land_plot_498.svg";
import { ReactComponent as LandPlot_92_499 } from "./land_plots/92/land_plot_499.svg";
import { ReactComponent as LandPlot_92_500 } from "./land_plots/92/land_plot_500.svg";
import { ReactComponent as LandPlot_92_501 } from "./land_plots/92/land_plot_501.svg";
import { ReactComponent as LandPlot_92_502 } from "./land_plots/92/land_plot_502.svg";
import { ReactComponent as LandPlot_92_503 } from "./land_plots/92/land_plot_503.svg";
import { ReactComponent as LandPlot_92_504 } from "./land_plots/92/land_plot_504.svg";
import { ReactComponent as LandPlot_92_505 } from "./land_plots/92/land_plot_505.svg";
import { ReactComponent as LandPlot_92_506 } from "./land_plots/92/land_plot_506.svg";
import { ReactComponent as LandPlot_92_507 } from "./land_plots/92/land_plot_507.svg";
import { ReactComponent as LandPlot_92_508 } from "./land_plots/92/land_plot_508.svg";
import { ReactComponent as LandPlot_92_509 } from "./land_plots/92/land_plot_509.svg";
import { ReactComponent as LandPlot_92_510 } from "./land_plots/92/land_plot_510.svg";
import { ReactComponent as LandPlot_92_511 } from "./land_plots/92/land_plot_511.svg";
import { ReactComponent as LandPlot_92_512 } from "./land_plots/92/land_plot_512.svg";
import { ReactComponent as LandPlot_92_513 } from "./land_plots/92/land_plot_513.svg";
import { ReactComponent as LandPlot_92_514 } from "./land_plots/92/land_plot_514.svg";
import { ReactComponent as LandPlot_92_515 } from "./land_plots/92/land_plot_515.svg";
import { ReactComponent as LandPlot_92_516 } from "./land_plots/92/land_plot_516.svg";
import { ReactComponent as LandPlot_92_517 } from "./land_plots/92/land_plot_517.svg";
import { ReactComponent as LandPlot_92_518 } from "./land_plots/92/land_plot_518.svg";
import { ReactComponent as LandPlot_92_519 } from "./land_plots/92/land_plot_519.svg";
import { ReactComponent as LandPlot_92_520 } from "./land_plots/92/land_plot_520.svg";
import { ReactComponent as LandPlot_92_521 } from "./land_plots/92/land_plot_521.svg";
import { ReactComponent as LandPlot_92_522 } from "./land_plots/92/land_plot_522.svg";
import { ReactComponent as LandPlot_92_523 } from "./land_plots/92/land_plot_523.svg";
import { ReactComponent as LandPlot_92_524 } from "./land_plots/92/land_plot_524.svg";
import { ReactComponent as LandPlot_92_525 } from "./land_plots/92/land_plot_525.svg";
import { ReactComponent as LandPlot_92_526 } from "./land_plots/92/land_plot_526.svg";
import { ReactComponent as LandPlot_92_527 } from "./land_plots/92/land_plot_527.svg";
import { ReactComponent as LandPlot_92_528 } from "./land_plots/92/land_plot_528.svg";
import { ReactComponent as LandPlot_92_529 } from "./land_plots/92/land_plot_529.svg";
import { ReactComponent as LandPlot_92_530 } from "./land_plots/92/land_plot_530.svg";
import { ReactComponent as LandPlot_92_531 } from "./land_plots/92/land_plot_531.svg";
import { ReactComponent as LandPlot_92_532 } from "./land_plots/92/land_plot_532.svg";
import { ReactComponent as LandPlot_92_533 } from "./land_plots/92/land_plot_533.svg";
import { ReactComponent as LandPlot_92_534 } from "./land_plots/92/land_plot_534.svg";
import { ReactComponent as LandPlot_92_535 } from "./land_plots/92/land_plot_535.svg";
import { ReactComponent as LandPlot_92_536 } from "./land_plots/92/land_plot_536.svg";
import { ReactComponent as LandPlot_92_537 } from "./land_plots/92/land_plot_537.svg";
import { ReactComponent as LandPlot_92_538 } from "./land_plots/92/land_plot_538.svg";
import { ReactComponent as LandPlot_92_539 } from "./land_plots/92/land_plot_539.svg";
import { ReactComponent as LandPlot_92_540 } from "./land_plots/92/land_plot_540.svg";
import { ReactComponent as LandPlot_92_541 } from "./land_plots/92/land_plot_541.svg";
import { ReactComponent as LandPlot_92_542 } from "./land_plots/92/land_plot_542.svg";
import { ReactComponent as LandPlot_92_543 } from "./land_plots/92/land_plot_543.svg";
import { ReactComponent as LandPlot_92_544 } from "./land_plots/92/land_plot_544.svg";
import { ReactComponent as LandPlot_92_545 } from "./land_plots/92/land_plot_545.svg";
import { ReactComponent as LandPlot_92_546 } from "./land_plots/92/land_plot_546.svg";
import { ReactComponent as LandPlot_92_547 } from "./land_plots/92/land_plot_547.svg";
import { ReactComponent as LandPlot_92_548 } from "./land_plots/92/land_plot_548.svg";
import { ReactComponent as LandPlot_92_549 } from "./land_plots/92/land_plot_549.svg";
import { ReactComponent as LandPlot_92_550 } from "./land_plots/92/land_plot_550.svg";
import { ReactComponent as LandPlot_92_551 } from "./land_plots/92/land_plot_551.svg";
import { ReactComponent as LandPlot_92_552 } from "./land_plots/92/land_plot_552.svg";
import { ReactComponent as LandPlot_92_553 } from "./land_plots/92/land_plot_553.svg";
import { ReactComponent as LandPlot_92_554 } from "./land_plots/92/land_plot_554.svg";
import { ReactComponent as LandPlot_92_555 } from "./land_plots/92/land_plot_555.svg";
import { ReactComponent as LandPlot_92_556 } from "./land_plots/92/land_plot_556.svg";
import { ReactComponent as LandPlot_92_557 } from "./land_plots/92/land_plot_557.svg";
import { ReactComponent as LandPlot_92_558 } from "./land_plots/92/land_plot_558.svg";
import { ReactComponent as LandPlot_92_559 } from "./land_plots/92/land_plot_559.svg";
import { ReactComponent as LandPlot_92_560 } from "./land_plots/92/land_plot_560.svg";
import { ReactComponent as LandPlot_92_561 } from "./land_plots/92/land_plot_561.svg";
import { ReactComponent as LandPlot_92_562 } from "./land_plots/92/land_plot_562.svg";
import { ReactComponent as LandPlot_92_563 } from "./land_plots/92/land_plot_563.svg";
import { ReactComponent as LandPlot_92_564 } from "./land_plots/92/land_plot_564.svg";
import { ReactComponent as LandPlot_92_565 } from "./land_plots/92/land_plot_565.svg";
import { ReactComponent as LandPlot_92_566 } from "./land_plots/92/land_plot_566.svg";
import { ReactComponent as LandPlot_92_567 } from "./land_plots/92/land_plot_567.svg";
import { ReactComponent as LandPlot_92_568 } from "./land_plots/92/land_plot_568.svg";
import { ReactComponent as LandPlot_92_569 } from "./land_plots/92/land_plot_569.svg";
import { ReactComponent as LandPlot_92_570 } from "./land_plots/92/land_plot_570.svg";
import { ReactComponent as LandPlot_92_571 } from "./land_plots/92/land_plot_571.svg";
import { ReactComponent as LandPlot_92_572 } from "./land_plots/92/land_plot_572.svg";
import { ReactComponent as LandPlot_92_573 } from "./land_plots/92/land_plot_573.svg";
import { ReactComponent as LandPlot_92_574 } from "./land_plots/92/land_plot_574.svg";
import { ReactComponent as LandPlot_92_575 } from "./land_plots/92/land_plot_575.svg";
import { ReactComponent as LandPlot_92_576 } from "./land_plots/92/land_plot_576.svg";
import { ReactComponent as LandPlot_92_577 } from "./land_plots/92/land_plot_577.svg";
import { ReactComponent as LandPlot_92_578 } from "./land_plots/92/land_plot_578.svg";
import { ReactComponent as LandPlot_92_579 } from "./land_plots/92/land_plot_579.svg";
import { ReactComponent as LandPlot_92_580 } from "./land_plots/92/land_plot_580.svg";
import { ReactComponent as LandPlot_92_581 } from "./land_plots/92/land_plot_581.svg";
import { ReactComponent as LandPlot_92_582 } from "./land_plots/92/land_plot_582.svg";
import { ReactComponent as LandPlot_92_583 } from "./land_plots/92/land_plot_583.svg";
import { ReactComponent as LandPlot_92_584 } from "./land_plots/92/land_plot_584.svg";
import { ReactComponent as LandPlot_92_585 } from "./land_plots/92/land_plot_585.svg";
import { ReactComponent as LandPlot_92_586 } from "./land_plots/92/land_plot_586.svg";
import { ReactComponent as LandPlot_92_587 } from "./land_plots/92/land_plot_587.svg";
import { ReactComponent as LandPlot_92_588 } from "./land_plots/92/land_plot_588.svg";
import { ReactComponent as LandPlot_92_589 } from "./land_plots/92/land_plot_589.svg";
import { ReactComponent as LandPlot_92_590 } from "./land_plots/92/land_plot_590.svg";
import { ReactComponent as LandPlot_92_591 } from "./land_plots/92/land_plot_591.svg";
import { ReactComponent as LandPlot_92_592 } from "./land_plots/92/land_plot_592.svg";
import { ReactComponent as LandPlot_92_593 } from "./land_plots/92/land_plot_593.svg";
import { ReactComponent as LandPlot_92_594 } from "./land_plots/92/land_plot_594.svg";
import { ReactComponent as LandPlot_92_595 } from "./land_plots/92/land_plot_595.svg";
import { ReactComponent as LandPlot_92_596 } from "./land_plots/92/land_plot_596.svg";
import { ReactComponent as LandPlot_92_597 } from "./land_plots/92/land_plot_597.svg";
import { ReactComponent as LandPlot_92_598 } from "./land_plots/92/land_plot_598.svg";
import { ReactComponent as LandPlot_92_599 } from "./land_plots/92/land_plot_599.svg";
import { ReactComponent as LandPlot_92_600 } from "./land_plots/92/land_plot_600.svg";
import { ReactComponent as LandPlot_92_601 } from "./land_plots/92/land_plot_601.svg";
import { ReactComponent as LandPlot_92_602 } from "./land_plots/92/land_plot_602.svg";
import { ReactComponent as LandPlot_92_603 } from "./land_plots/92/land_plot_603.svg";
import { ReactComponent as LandPlot_92_604 } from "./land_plots/92/land_plot_604.svg";
import { ReactComponent as LandPlot_92_605 } from "./land_plots/92/land_plot_605.svg";
import { ReactComponent as LandPlot_92_606 } from "./land_plots/92/land_plot_606.svg";
import { ReactComponent as LandPlot_92_607 } from "./land_plots/92/land_plot_607.svg";
import { ReactComponent as LandPlot_92_608 } from "./land_plots/92/land_plot_608.svg";
import { ReactComponent as LandPlot_92_609 } from "./land_plots/92/land_plot_609.svg";
import { ReactComponent as LandPlot_92_610 } from "./land_plots/92/land_plot_610.svg";
import { ReactComponent as LandPlot_92_611 } from "./land_plots/92/land_plot_611.svg";
import { ReactComponent as LandPlot_92_612 } from "./land_plots/92/land_plot_612.svg";
import { ReactComponent as LandPlot_92_613 } from "./land_plots/92/land_plot_613.svg";
import { ReactComponent as LandPlot_92_614 } from "./land_plots/92/land_plot_614.svg";
import { ReactComponent as LandPlot_92_615 } from "./land_plots/92/land_plot_615.svg";
import { ReactComponent as LandPlot_92_616 } from "./land_plots/92/land_plot_616.svg";
import { ReactComponent as LandPlot_92_617 } from "./land_plots/92/land_plot_617.svg";
import { ReactComponent as LandPlot_92_618 } from "./land_plots/92/land_plot_618.svg";
import { ReactComponent as LandPlot_92_619 } from "./land_plots/92/land_plot_619.svg";
import { ReactComponent as LandPlot_92_620 } from "./land_plots/92/land_plot_620.svg";
import { ReactComponent as LandPlot_92_621 } from "./land_plots/92/land_plot_621.svg";
import { ReactComponent as LandPlot_92_622 } from "./land_plots/92/land_plot_622.svg";
import { ReactComponent as LandPlot_92_623 } from "./land_plots/92/land_plot_623.svg";
import { ReactComponent as LandPlot_92_624 } from "./land_plots/92/land_plot_624.svg";
import { ReactComponent as LandPlot_92_625 } from "./land_plots/92/land_plot_625.svg";
import { ReactComponent as LandPlot_92_626 } from "./land_plots/92/land_plot_626.svg";
import { ReactComponent as LandPlot_92_627 } from "./land_plots/92/land_plot_627.svg";
import { ReactComponent as LandPlot_92_628 } from "./land_plots/92/land_plot_628.svg";
import { ReactComponent as LandPlot_92_629 } from "./land_plots/92/land_plot_629.svg";
import { ReactComponent as LandPlot_92_630 } from "./land_plots/92/land_plot_630.svg";
import { ReactComponent as LandPlot_92_631 } from "./land_plots/92/land_plot_631.svg";
import { ReactComponent as LandPlot_92_632 } from "./land_plots/92/land_plot_632.svg";
import { ReactComponent as LandPlot_92_633 } from "./land_plots/92/land_plot_633.svg";
import { ReactComponent as LandPlot_92_634 } from "./land_plots/92/land_plot_634.svg";
import { ReactComponent as LandPlot_92_635 } from "./land_plots/92/land_plot_635.svg";
import { ReactComponent as LandPlot_92_636 } from "./land_plots/92/land_plot_636.svg";
import { ReactComponent as LandPlot_92_637 } from "./land_plots/92/land_plot_637.svg";
import { ReactComponent as LandPlot_92_638 } from "./land_plots/92/land_plot_638.svg";
import { ReactComponent as LandPlot_92_639 } from "./land_plots/92/land_plot_639.svg";
import { ReactComponent as LandPlot_92_640 } from "./land_plots/92/land_plot_640.svg";
import { ReactComponent as LandPlot_92_641 } from "./land_plots/92/land_plot_641.svg";
import { ReactComponent as LandPlot_92_642 } from "./land_plots/92/land_plot_642.svg";
import { ReactComponent as LandPlot_92_643 } from "./land_plots/92/land_plot_643.svg";
import { ReactComponent as LandPlot_92_644 } from "./land_plots/92/land_plot_644.svg";
import { ReactComponent as LandPlot_92_645 } from "./land_plots/92/land_plot_645.svg";
import { ReactComponent as LandPlot_92_646 } from "./land_plots/92/land_plot_646.svg";
import { ReactComponent as LandPlot_92_647 } from "./land_plots/92/land_plot_647.svg";
import { ReactComponent as LandPlot_92_648 } from "./land_plots/92/land_plot_648.svg";
import { ReactComponent as LandPlot_92_649 } from "./land_plots/92/land_plot_649.svg";
import { ReactComponent as LandPlot_92_650 } from "./land_plots/92/land_plot_650.svg";
import { ReactComponent as LandPlot_92_651 } from "./land_plots/92/land_plot_651.svg";
import { ReactComponent as LandPlot_92_652 } from "./land_plots/92/land_plot_652.svg";
import { ReactComponent as LandPlot_92_653 } from "./land_plots/92/land_plot_653.svg";
import { ReactComponent as LandPlot_92_654 } from "./land_plots/92/land_plot_654.svg";
import { ReactComponent as LandPlot_92_655 } from "./land_plots/92/land_plot_655.svg";
import { ReactComponent as LandPlot_92_656 } from "./land_plots/92/land_plot_656.svg";
import { ReactComponent as LandPlot_92_657 } from "./land_plots/92/land_plot_657.svg";
import { ReactComponent as LandPlot_92_658 } from "./land_plots/92/land_plot_658.svg";
import { ReactComponent as LandPlot_92_659 } from "./land_plots/92/land_plot_659.svg";
import { ReactComponent as LandPlot_92_660 } from "./land_plots/92/land_plot_660.svg";
import { ReactComponent as LandPlot_92_661 } from "./land_plots/92/land_plot_661.svg";
import { ReactComponent as LandPlot_92_662 } from "./land_plots/92/land_plot_662.svg";
import { ReactComponent as LandPlot_92_663 } from "./land_plots/92/land_plot_663.svg";
import { ReactComponent as LandPlot_92_664 } from "./land_plots/92/land_plot_664.svg";
import { ReactComponent as LandPlot_92_665 } from "./land_plots/92/land_plot_665.svg";
import { ReactComponent as LandPlot_92_666 } from "./land_plots/92/land_plot_666.svg";
import { ReactComponent as LandPlot_92_667 } from "./land_plots/92/land_plot_667.svg";
import { ReactComponent as LandPlot_92_668 } from "./land_plots/92/land_plot_668.svg";
import { ReactComponent as LandPlot_92_669 } from "./land_plots/92/land_plot_669.svg";
import { ReactComponent as LandPlot_92_670 } from "./land_plots/92/land_plot_670.svg";
import { ReactComponent as LandPlot_92_671 } from "./land_plots/92/land_plot_671.svg";
import { ReactComponent as LandPlot_92_672 } from "./land_plots/92/land_plot_672.svg";
import { ReactComponent as LandPlot_92_673 } from "./land_plots/92/land_plot_673.svg";
import { ReactComponent as LandPlot_92_674 } from "./land_plots/92/land_plot_674.svg";
import { ReactComponent as LandPlot_92_675 } from "./land_plots/92/land_plot_675.svg";
import { ReactComponent as LandPlot_92_676 } from "./land_plots/92/land_plot_676.svg";
import { ReactComponent as LandPlot_92_677 } from "./land_plots/92/land_plot_677.svg";
import { ReactComponent as LandPlot_92_678 } from "./land_plots/92/land_plot_678.svg";
import { ReactComponent as LandPlot_92_679 } from "./land_plots/92/land_plot_679.svg";
import { ReactComponent as LandPlot_92_680 } from "./land_plots/92/land_plot_680.svg";

import { ReactComponent as Church_2_1 } from "../../../../static/images/contents/zone-4/icons/2/church_1.svg";
import { ReactComponent as Church_2_2 } from "../../../../static/images/contents/zone-4/icons/2/church_2.svg";
import { ReactComponent as Church_2_3 } from "../../../../static/images/contents/zone-4/icons/2/church_3.svg";
import { ReactComponent as Church_2_4 } from "../../../../static/images/contents/zone-4/icons/2/church_4.svg";
import { ReactComponent as Church_2_5 } from "../../../../static/images/contents/zone-4/icons/2/church_5.svg";
import { ReactComponent as Church_2_6 } from "../../../../static/images/contents/zone-4/icons/2/church_6.svg";
import { ReactComponent as Church_2_7 } from "../../../../static/images/contents/zone-4/icons/2/church_7.svg";
import { ReactComponent as School_2_1 } from "../../../../static/images/contents/zone-4/icons/2/school_1.svg";
import { ReactComponent as School_2_2 } from "../../../../static/images/contents/zone-4/icons/2/school_2.svg";
import { ReactComponent as School_2_3 } from "../../../../static/images/contents/zone-4/icons/2/school_3.svg";
import { ReactComponent as School_2_4 } from "../../../../static/images/contents/zone-4/icons/2/school_4.svg";
import { ReactComponent as Shopping_2_1 } from "../../../../static/images/contents/zone-4/icons/2/shopping_1.svg";
import { ReactComponent as Hospital_2_1 } from "../../../../static/images/contents/zone-4/icons/2/hospital_1.svg";

import { ReactComponent as Church_33_1 } from "../../../../static/images/contents/zone-4/icons/33/church_1.svg";
import { ReactComponent as Church_33_2 } from "../../../../static/images/contents/zone-4/icons/33/church_2.svg";
import { ReactComponent as Church_33_3 } from "../../../../static/images/contents/zone-4/icons/33/church_3.svg";
import { ReactComponent as Church_33_4 } from "../../../../static/images/contents/zone-4/icons/33/church_4.svg";
import { ReactComponent as School_33_1 } from "../../../../static/images/contents/zone-4/icons/33/school_1.svg";
import { ReactComponent as School_33_2 } from "../../../../static/images/contents/zone-4/icons/33/school_2.svg";
import { ReactComponent as School_33_3 } from "../../../../static/images/contents/zone-4/icons/33/school_3.svg";
import { ReactComponent as School_33_4 } from "../../../../static/images/contents/zone-4/icons/33/school_4.svg";
import { ReactComponent as Shopping_33_1 } from "../../../../static/images/contents/zone-4/icons/33/shopping_1.svg";
import { ReactComponent as Hospital_33_1 } from "../../../../static/images/contents/zone-4/icons/33/hospital_1.svg";                  

import { ReactComponent as Church_61_1 } from "../../../../static/images/contents/zone-4/icons/61/church_1.svg";
import { ReactComponent as Church_61_2 } from "../../../../static/images/contents/zone-4/icons/61/church_2.svg";
import { ReactComponent as Church_61_3 } from "../../../../static/images/contents/zone-4/icons/61/church_3.svg";
import { ReactComponent as Church_61_4 } from "../../../../static/images/contents/zone-4/icons/61/church_4.svg";
import { ReactComponent as Church_61_5 } from "../../../../static/images/contents/zone-4/icons/61/church_5.svg";
import { ReactComponent as Church_61_6 } from "../../../../static/images/contents/zone-4/icons/61/church_6.svg";
import { ReactComponent as School_61_1 } from "../../../../static/images/contents/zone-4/icons/61/school_1.svg";
import { ReactComponent as School_61_2 } from "../../../../static/images/contents/zone-4/icons/61/school_2.svg";
import { ReactComponent as School_61_3 } from "../../../../static/images/contents/zone-4/icons/61/school_3.svg";
import { ReactComponent as School_61_4 } from "../../../../static/images/contents/zone-4/icons/61/school_4.svg";
import { ReactComponent as Shopping_61_1 } from "../../../../static/images/contents/zone-4/icons/61/shopping_1.svg";
import { ReactComponent as Hospital_61_1 } from "../../../../static/images/contents/zone-4/icons/61/hospital_1.svg";

import { ReactComponent as Church_91_1 } from "../../../../static/images/contents/zone-4/icons/91/church_1.svg";
import { ReactComponent as Church_91_2 } from "../../../../static/images/contents/zone-4/icons/91/church_2.svg";
import { ReactComponent as Church_91_3 } from "../../../../static/images/contents/zone-4/icons/91/church_3.svg";
import { ReactComponent as School_91_1 } from "../../../../static/images/contents/zone-4/icons/91/school_1.svg";
import { ReactComponent as School_91_2 } from "../../../../static/images/contents/zone-4/icons/91/school_2.svg";
import { ReactComponent as Shopping_91_1 } from "../../../../static/images/contents/zone-4/icons/91/shopping_1.svg";


///////////////////////////////////////////////////////////////////////////////////////////////////
export const zone4_land_plots = [
////////////////////////////////////////////////////////////////////////////////////////index 1
    {
        index: 2,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={
                        [
                            {
                                Polygon: LandPlot_1_450,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 450,
                                    status: "available",
                                    block: 25,
                                    unitSize: 408.73,
                                    north: "15.14",
                                    east: "26.26",
                                    south: "15.0",
                                    west: "28.24",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "25",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_451,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 451,
                                    status: "available",
                                    block: 25,
                                    unitSize: 438.52,
                                    north: "15.14",
                                    east: "28.24",
                                    south: "15.0",
                                    west: "30.23",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_452,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 452,
                                    status: "available",
                                    block: 25,
                                    unitSize: 516.4,
                                    north: "30.29",
                                    east: "15.23",
                                    south: "30.0",
                                    west: "19.2",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_453,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 453,
                                    status: "available",
                                    block: 25,
                                    unitSize: 450.01,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_454,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 454,
                                    status: "available",
                                    block: 25,
                                    unitSize: 450.01,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_455,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 455,
                                    status: "available",
                                    block: 25,
                                    unitSize: 450.01,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_456,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 456,
                                    status: "available",
                                    block: 25,
                                    unitSize: 450.01,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_457,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 457,
                                    status: "available",
                                    block: 25,
                                    unitSize: 1350.03,
                                    north: "30",
                                    east: "45.0",
                                    south: "30.0",
                                    west: "45.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15+6",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_458,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 458,
                                    status: "available",
                                    block: 25,
                                    unitSize: 1062.43,
                                    north: "30",
                                    east: "33.58",
                                    south: "30.2",
                                    west: "37.25",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "25",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_459,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 459,
                                    status: "available",
                                    block: 25,
                                    unitSize: 1172.53,
                                    north: "30",
                                    east: "37.25",
                                    south: "30.2",
                                    west: "40.92",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_460,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 460,
                                    status: "available",
                                    block: 26,
                                    unitSize: 655.36,
                                    north: "24.47",
                                    east: "25.24",
                                    south: "27.96",
                                    west: "25.0",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_461,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 461,
                                    status: "available",
                                    block: 26,
                                    unitSize: 479.01,
                                    north: "24.96",
                                    east: "13.29",
                                    south: "30.52",
                                    west: "19.8",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_462,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 462,
                                    status: "available",
                                    block: 26,
                                    unitSize: 375.0,
                                    north: "15",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_463,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 463,
                                    status: "available",
                                    block: 26,
                                    unitSize: 322.13,
                                    north: "15",
                                    east: "19.8",
                                    south: "15.37",
                                    west: "23.15",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_464,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 464,
                                    status: "available",
                                    block: 26,
                                    unitSize: 375.0,
                                    north: "15",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_465,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 465,
                                    status: "available",
                                    block: 26,
                                    unitSize: 372.27,
                                    north: "15",
                                    east: "23.15",
                                    south: "15.37",
                                    west: "26.49",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_466,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 466,
                                    status: "available",
                                    block: 26,
                                    unitSize: 375.0,
                                    north: "15",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_467,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 467,
                                    status: "available",
                                    block: 26,
                                    unitSize: 422.41,
                                    north: "15",
                                    east: "26.49",
                                    south: "15.37",
                                    west: "29.83",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_468,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 468,
                                    status: "available",
                                    block: 26,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_469,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 469,
                                    status: "available",
                                    block: 26,
                                    unitSize: 397.54,
                                    north: "15",
                                    east: "24.83",
                                    south: "15.37",
                                    west: "28.17",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_470,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 470,
                                    status: "available",
                                    block: 26,
                                    unitSize: 443.75,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_471,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 471,
                                    status: "available",
                                    block: 26,
                                    unitSize: 447.68,
                                    north: "15",
                                    east: "28.17",
                                    south: "15.37",
                                    west: "31.52",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_472,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 472,
                                    status: "available",
                                    block: 26,
                                    unitSize: 503.75,
                                    north: "17",
                                    east: "30.0",
                                    south: "17.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_473,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 473,
                                    status: "available",
                                    block: 26,
                                    unitSize: 567.98,
                                    north: "17",
                                    east: "31.52",
                                    south: "17.42",
                                    west: "35.3",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_474,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 474,
                                    status: "available",
                                    block: 26,
                                    unitSize: 595.0,
                                    north: "17",
                                    east: "35.0",
                                    south: "17.0",
                                    west: "35.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_475,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 475,
                                    status: "available",
                                    block: 26,
                                    unitSize: 547.38,
                                    north: "17",
                                    east: "30.3",
                                    south: "17.42",
                                    west: "34.09",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_476,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 476,
                                    status: "available",
                                    block: 26,
                                    unitSize: 595.0,
                                    north: "17",
                                    east: "35.0",
                                    south: "17.0",
                                    west: "35.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_477,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 477,
                                    status: "available",
                                    block: 26,
                                    unitSize: 611.77,
                                    north: "17",
                                    east: "34.09",
                                    south: "17.42",
                                    west: "37.88",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_478,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 478,
                                    status: "available",
                                    block: 26,
                                    unitSize: 473.25,
                                    north: "31.55",
                                    east: "15.0",
                                    south: "31.55",
                                    west: "15.0",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_479,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 479,
                                    status: "available",
                                    block: 26,
                                    unitSize: 473.25,
                                    north: "31.55",
                                    east: "15.0",
                                    south: "31.55",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_480,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 480,
                                    status: "available",
                                    block: 26,
                                    unitSize: 473.25,
                                    north: "31.55",
                                    east: "15.0",
                                    south: "31.55",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_481,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 481,
                                    status: "available",
                                    block: 26,
                                    unitSize: 473.25,
                                    north: "31.55",
                                    east: "15.0",
                                    south: "31.55",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_482,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 482,
                                    status: "available",
                                    block: 26,
                                    unitSize: 505.1,
                                    north: "31.55",
                                    east: "12.88",
                                    south: "27.32",
                                    west: "14.91",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_483,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 483,
                                    status: "available",
                                    block: 27,
                                    unitSize: 692.22,
                                    north: "24.29",
                                    east: "30.1",
                                    south: "21.86",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_484,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 484,
                                    status: "available",
                                    block: 27,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_485,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 485,
                                    status: "available",
                                    block: 27,
                                    unitSize: 1423.06,
                                    north: "36.86",
                                    east: "39.96",
                                    south: "33.65",
                                    west: "40.98",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_486,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 486,
                                    status: "available",
                                    block: 27,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_487,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 487,
                                    status: "available",
                                    block: 27,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_488,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 488,
                                    status: "available",
                                    block: 27,
                                    unitSize: 1232.46,
                                    north: "30",
                                    east: "40.98",
                                    south: "30.02",
                                    west: "42.02",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_489,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 489,
                                    status: "available",
                                    block: 27,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_490,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 490,
                                    status: "available",
                                    block: 27,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_491,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 491,
                                    status: "available",
                                    block: 27,
                                    unitSize: 1275.94,
                                    north: "30",
                                    east: "42.02",
                                    south: "30.02",
                                    west: "43.05",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_492,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 492,
                                    status: "available",
                                    block: 27,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_493,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 493,
                                    status: "available",
                                    block: 27,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_494,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 494,
                                    status: "available",
                                    block: 27,
                                    unitSize: 1306.92,
                                    north: "30",
                                    east: "43.05",
                                    south: "30.02",
                                    west: "44.08",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_495,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 495,
                                    status: "available",
                                    block: 27,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_496,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 496,
                                    status: "available",
                                    block: 27,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_497,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 497,
                                    status: "available",
                                    block: 27,
                                    unitSize: 1337.9,
                                    north: "30",
                                    east: "44.08",
                                    south: "30.02",
                                    west: "45.11",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_498,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 498,
                                    status: "available",
                                    block: 27,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_499,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 499,
                                    status: "available",
                                    block: 27,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_500,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 500,
                                    status: "available",
                                    block: 27,
                                    unitSize: 1368.88,
                                    north: "30",
                                    east: "45.11",
                                    south: "30.02",
                                    west: "46.15",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_501,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 501,
                                    status: "available",
                                    block: 27,
                                    unitSize: 770.88,
                                    north: "23.02",
                                    east: "30.0",
                                    south: "26.5",
                                    west: "17.95",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_502,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 502,
                                    status: "available",
                                    block: 27,
                                    unitSize: 1196.68,
                                    north: "26.5",
                                    east: "46.15",
                                    south: "24.89",
                                    west: "47.03",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_503,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 503,
                                    status: "available",
                                    block: 28,
                                    unitSize: 687.5,
                                    north: "27.5",
                                    east: "25.0",
                                    south: "27.5",
                                    west: "25.0",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "6",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_504,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 504,
                                    status: "available",
                                    block: 28,
                                    unitSize: 687.5,
                                    north: "27.5",
                                    east: "25.0",
                                    south: "27.5",
                                    west: "25.0",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "6",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_505,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 505,
                                    status: "available",
                                    block: 28,
                                    unitSize: 1341.67,
                                    north: "27.5",
                                    east: "40.0",
                                    south: "27.5",
                                    west: "43.51",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15+6",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_506,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 506,
                                    status: "available",
                                    block: 28,
                                    unitSize: 687.5,
                                    north: "27.5",
                                    east: "25.0",
                                    south: "27.5",
                                    west: "25.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_507,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 507,
                                    status: "available",
                                    block: 28,
                                    unitSize: 847.03,
                                    north: "27.5",
                                    east: "18.51",
                                    south: "27.5",
                                    west: "22.01",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15+6",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_508,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 508,
                                    status: "available",
                                    block: 28,
                                    unitSize: 1512.5,
                                    north: "27.5",
                                    east: "55.0",
                                    south: "27.5",
                                    west: "55.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_509,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 509,
                                    status: "available",
                                    block: 28,
                                    unitSize: 1512.5,
                                    north: "27.5",
                                    east: "55.0",
                                    south: "27.5",
                                    west: "55.0",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_510,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 510,
                                    status: "available",
                                    block: 29,
                                    unitSize: 510.0,
                                    north: "17",
                                    east: "30.0",
                                    south: "17.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_511,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 511,
                                    status: "available",
                                    block: 29,
                                    unitSize: 425.0,
                                    north: "17",
                                    east: "25.0",
                                    south: "17.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_512,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 512,
                                    status: "available",
                                    block: 29,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_513,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 513,
                                    status: "available",
                                    block: 29,
                                    unitSize: 375.0,
                                    north: "15",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_514,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 514,
                                    status: "available",
                                    block: 29,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_515,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 515,
                                    status: "available",
                                    block: 29,
                                    unitSize: 375.0,
                                    north: "15",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_516,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 516,
                                    status: "available",
                                    block: 29,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_517,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 517,
                                    status: "available",
                                    block: 29,
                                    unitSize: 375.0,
                                    north: "15",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_518,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 518,
                                    status: "available",
                                    block: 29,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_519,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 519,
                                    status: "available",
                                    block: 29,
                                    unitSize: 375.0,
                                    north: "15",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_520,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 520,
                                    status: "available",
                                    block: 29,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_521,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 521,
                                    status: "available",
                                    block: 29,
                                    unitSize: 375.0,
                                    north: "15",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_522,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 522,
                                    status: "available",
                                    block: 29,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_523,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 523,
                                    status: "available",
                                    block: 29,
                                    unitSize: 375.0,
                                    north: "15",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_524,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 524,
                                    status: "available",
                                    block: 29,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_525,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 525,
                                    status: "available",
                                    block: 29,
                                    unitSize: 375.0,
                                    north: "15",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_526,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 526,
                                    status: "available",
                                    block: 29,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_527,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 527,
                                    status: "available",
                                    block: 29,
                                    unitSize: 375.0,
                                    north: "15",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_528,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 528,
                                    status: "available",
                                    block: 29,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_529,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 529,
                                    status: "available",
                                    block: 29,
                                    unitSize: 375.0,
                                    north: "15",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_530,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 530,
                                    status: "available",
                                    block: 29,
                                    unitSize: 588.0,
                                    north: "28",
                                    east: "21.0",
                                    south: "28.0",
                                    west: "21.0",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_531,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 531,
                                    status: "available",
                                    block: 29,
                                    unitSize: 476.0,
                                    north: "28",
                                    east: "17.0",
                                    south: "28.0",
                                    west: "17.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_532,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 532,
                                    status: "available",
                                    block: 29,
                                    unitSize: 476.0,
                                    north: "28",
                                    east: "17.0",
                                    south: "28.0",
                                    west: "17.0",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_533,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 533,
                                    status: "available",
                                    block: 30,
                                    unitSize: 657.11,
                                    north: "17",
                                    east: "38.19",
                                    south: "17.03",
                                    west: "39.22",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_534,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 534,
                                    status: "available",
                                    block: 30,
                                    unitSize: 592.91,
                                    north: "15",
                                    east: "39.22",
                                    south: "15.02",
                                    west: "39.94",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_535,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 535,
                                    status: "available",
                                    block: 30,
                                    unitSize: 605.19,
                                    north: "15",
                                    east: "39.94",
                                    south: "15.02",
                                    west: "40.76",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_536,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 536,
                                    status: "available",
                                    block: 30,
                                    unitSize: 617.47,
                                    north: "15",
                                    east: "40.76",
                                    south: "15.02",
                                    west: "41.57",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_537,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 537,
                                    status: "available",
                                    block: 30,
                                    unitSize: 629.75,
                                    north: "15",
                                    east: "41.57",
                                    south: "15.02",
                                    west: "42.39",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_538,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 538,
                                    status: "available",
                                    block: 30,
                                    unitSize: 440.0,
                                    north: "20",
                                    east: "22.0",
                                    south: "20.0",
                                    west: "22.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_539,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 539,
                                    status: "available",
                                    block: 30,
                                    unitSize: 418.78,
                                    north: "20",
                                    east: "20.39",
                                    south: "20.03",
                                    west: "22.58",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_540,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 540,
                                    status: "available",
                                    block: 30,
                                    unitSize: 440.0,
                                    north: "20",
                                    east: "22.0",
                                    south: "20.0",
                                    west: "22.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_541,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 541,
                                    status: "available",
                                    block: 30,
                                    unitSize: 440.61,
                                    north: "20",
                                    east: "21.48",
                                    south: "20.03",
                                    west: "22.58",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_542,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 542,
                                    status: "available",
                                    block: 30,
                                    unitSize: 396.0,
                                    north: "18",
                                    east: "22.0",
                                    south: "18.0",
                                    west: "22.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_543,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 543,
                                    status: "available",
                                    block: 30,
                                    unitSize: 415.22,
                                    north: "18",
                                    east: "22.58",
                                    south: "18.03",
                                    west: "23.56",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_544,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 544,
                                    status: "available",
                                    block: 30,
                                    unitSize: 396.0,
                                    north: "18",
                                    east: "22.0",
                                    south: "18.0",
                                    west: "22.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_545,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 545,
                                    status: "available",
                                    block: 30,
                                    unitSize: 432.9,
                                    north: "18",
                                    east: "23.56",
                                    south: "18.03",
                                    west: "24.54",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_546,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 546,
                                    status: "available",
                                    block: 30,
                                    unitSize: 459.0,
                                    north: "27",
                                    east: "17.0",
                                    south: "27.0",
                                    west: "17.0",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_547,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 547,
                                    status: "available",
                                    block: 30,
                                    unitSize: 405.0,
                                    north: "27",
                                    east: "15.0",
                                    south: "27.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_548,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 548,
                                    status: "available",
                                    block: 30,
                                    unitSize: 412.52,
                                    north: "27",
                                    east: "14.54",
                                    south: "27.04",
                                    west: "16.02",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_549,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 549,
                                    status: "available",
                                    block: 31,
                                    unitSize: 592.69,
                                    north: "16.73",
                                    east: "30.6",
                                    south: "22.78",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_550,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 550,
                                    status: "available",
                                    block: 31,
                                    unitSize: 510.0,
                                    north: "17",
                                    east: "30.0",
                                    south: "17.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_551,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 551,
                                    status: "available",
                                    block: 31,
                                    unitSize: 1841.62,
                                    north: "39.78",
                                    east: "38.53",
                                    south: "48.04",
                                    west: "45.12",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: true,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_552,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 552,
                                    status: "available",
                                    block: 31,
                                    unitSize: 510.0,
                                    north: "17",
                                    east: "30.0",
                                    south: "17.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_553,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 553,
                                    status: "available",
                                    block: 31,
                                    unitSize: 510.0,
                                    north: "17",
                                    east: "30.0",
                                    south: "17.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_554,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 554,
                                    status: "available",
                                    block: 31,
                                    unitSize: 1524.82,
                                    north: "34",
                                    east: "45.12",
                                    south: "34.02",
                                    west: "44.32",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_555,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 555,
                                    status: "available",
                                    block: 31,
                                    unitSize: 510.0,
                                    north: "17",
                                    east: "30.0",
                                    south: "17.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_556,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 556,
                                    status: "available",
                                    block: 31,
                                    unitSize: 510.0,
                                    north: "17",
                                    east: "30.0",
                                    south: "17.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_557,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 557,
                                    status: "available",
                                    block: 31,
                                    unitSize: 1488.16,
                                    north: "34",
                                    east: "44.32",
                                    south: "34.02",
                                    west: "43.22",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_558,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 558,
                                    status: "available",
                                    block: 31,
                                    unitSize: 510.0,
                                    north: "17",
                                    east: "30.0",
                                    south: "17.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_559,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 559,
                                    status: "available",
                                    block: 31,
                                    unitSize: 510.0,
                                    north: "17",
                                    east: "30.0",
                                    south: "17.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_560,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 560,
                                    status: "available",
                                    block: 31,
                                    unitSize: 1450.46,
                                    north: "34",
                                    east: "43.22",
                                    south: "34.02",
                                    west: "42.11",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_561,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 561,
                                    status: "available",
                                    block: 31,
                                    unitSize: 480.0,
                                    north: "16",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_562,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 562,
                                    status: "available",
                                    block: 31,
                                    unitSize: 480.0,
                                    north: "16",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_563,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 563,
                                    status: "available",
                                    block: 31,
                                    unitSize: 1318.2,
                                    north: "32",
                                    east: "42.11",
                                    south: "32.02",
                                    west: "41.06",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_564,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 564,
                                    status: "available",
                                    block: 31,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_565,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 565,
                                    status: "available",
                                    block: 31,
                                    unitSize: 483.41,
                                    north: "15.29",
                                    east: "30.0",
                                    south: "16.93",
                                    west: "30.04",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_566,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 566,
                                    status: "available",
                                    block: 31,
                                    unitSize: 1338.24,
                                    north: "31.93",
                                    east: "41.01",
                                    south: "34.13",
                                    west: "40.01",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_567,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 567,
                                    status: "available",
                                    block: 32,
                                    unitSize: 564.85,
                                    north: "20",
                                    east: "28.24",
                                    south: "20.0",
                                    west: "28.24",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_568,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 568,
                                    status: "available",
                                    block: 32,
                                    unitSize: 564.85,
                                    north: "20",
                                    east: "28.24",
                                    south: "20.0",
                                    west: "28.24",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_569,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 569,
                                    status: "available",
                                    block: 32,
                                    unitSize: 552.35,
                                    north: "20",
                                    east: "28.24",
                                    south: "20.0",
                                    west: "28.24",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: true,
                                    st_width_1: "25",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_570,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 570,
                                    status: "available",
                                    block: 32,
                                    unitSize: 450.0,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_571,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 571,
                                    status: "available",
                                    block: 32,
                                    unitSize: 450.0,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_572,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 572,
                                    status: "available",
                                    block: 32,
                                    unitSize: 450.0,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_573,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 573,
                                    status: "available",
                                    block: 32,
                                    unitSize: 450.0,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_574,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 574,
                                    status: "available",
                                    block: 32,
                                    unitSize: 450.0,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_575,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 575,
                                    status: "available",
                                    block: 32,
                                    unitSize: 450.0,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_576,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 576,
                                    status: "available",
                                    block: 32,
                                    unitSize: 450.0,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_577,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 577,
                                    status: "available",
                                    block: 32,
                                    unitSize: 450.0,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_578,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 578,
                                    status: "available",
                                    block: 32,
                                    unitSize: 450.0,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_579,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 579,
                                    status: "available",
                                    block: 32,
                                    unitSize: 450.0,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_580,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 580,
                                    status: "available",
                                    block: 32,
                                    unitSize: 450.0,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_581,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 581,
                                    status: "available",
                                    block: 32,
                                    unitSize: 450.0,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_582,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 582,
                                    status: "available",
                                    block: 32,
                                    unitSize: 508.31,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "18.89",
                                    front1: "eastern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_583,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 583,
                                    status: "available",
                                    block: 32,
                                    unitSize: 624.94,
                                    north: "30",
                                    east: "18.89",
                                    south: "30.25",
                                    west: "22.78",
                                    front1: "western",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_584,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 584,
                                    status: "available",
                                    block: 33,
                                    unitSize: 3659.06,
                                    north: "60.5",
                                    east: "64.42",
                                    south: "60.01",
                                    west: "57.96",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: true,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_585,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 585,
                                    status: "available",
                                    block: 34,
                                    unitSize: 600.0,
                                    north: "20",
                                    east: "30.0",
                                    south: "20.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_586,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 586,
                                    status: "available",
                                    block: 34,
                                    unitSize: 600.0,
                                    north: "20",
                                    east: "30.0",
                                    south: "20.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_587,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 587,
                                    status: "available",
                                    block: 34,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_588,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 588,
                                    status: "available",
                                    block: 34,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_589,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 589,
                                    status: "available",
                                    block: 34,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_590,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 590,
                                    status: "available",
                                    block: 34,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_591,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 591,
                                    status: "available",
                                    block: 34,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_592,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 592,
                                    status: "available",
                                    block: 34,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_593,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 593,
                                    status: "available",
                                    block: 34,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_594,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 594,
                                    status: "available",
                                    block: 34,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_595,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 595,
                                    status: "available",
                                    block: 34,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_596,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 596,
                                    status: "available",
                                    block: 34,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_597,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 597,
                                    status: "available",
                                    block: 34,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_598,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 598,
                                    status: "available",
                                    block: 34,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_599,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 599,
                                    status: "available",
                                    block: 34,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_600,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 600,
                                    status: "available",
                                    block: 34,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_601,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 601,
                                    status: "available",
                                    block: 34,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_602,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 602,
                                    status: "available",
                                    block: 34,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_603,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 603,
                                    status: "available",
                                    block: 34,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_604,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 604,
                                    status: "available",
                                    block: 34,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_605,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 605,
                                    status: "available",
                                    block: 34,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_606,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 606,
                                    status: "available",
                                    block: 34,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_607,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 607,
                                    status: "available",
                                    block: 34,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_608,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 608,
                                    status: "available",
                                    block: 34,
                                    unitSize: 450.0,
                                    north: "15",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_609,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 609,
                                    status: "available",
                                    block: 34,
                                    unitSize: 600.0,
                                    north: "20",
                                    east: "30.0",
                                    south: "20.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_610,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 610,
                                    status: "available",
                                    block: 34,
                                    unitSize: 600.0,
                                    north: "20",
                                    east: "30.0",
                                    south: "20.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_611,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 611,
                                    status: "available",
                                    block: 35,
                                    unitSize: 580.0,
                                    north: "20",
                                    east: "29.0",
                                    south: "20.0",
                                    west: "29.0",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_612,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 612,
                                    status: "available",
                                    block: 35,
                                    unitSize: 580.0,
                                    north: "20",
                                    east: "29.0",
                                    south: "20.0",
                                    west: "29.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_613,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 613,
                                    status: "available",
                                    block: 35,
                                    unitSize: 435.0,
                                    north: "15",
                                    east: "29.0",
                                    south: "15.0",
                                    west: "29.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_614,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 614,
                                    status: "available",
                                    block: 35,
                                    unitSize: 435.0,
                                    north: "15",
                                    east: "29.0",
                                    south: "15.0",
                                    west: "29.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_615,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 615,
                                    status: "available",
                                    block: 35,
                                    unitSize: 435.0,
                                    north: "15",
                                    east: "29.0",
                                    south: "15.0",
                                    west: "29.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_616,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 616,
                                    status: "available",
                                    block: 35,
                                    unitSize: 435.0,
                                    north: "15",
                                    east: "29.0",
                                    south: "15.0",
                                    west: "29.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_617,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 617,
                                    status: "available",
                                    block: 35,
                                    unitSize: 435.0,
                                    north: "15",
                                    east: "29.0",
                                    south: "15.0",
                                    west: "29.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_618,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 618,
                                    status: "available",
                                    block: 35,
                                    unitSize: 435.0,
                                    north: "15",
                                    east: "29.0",
                                    south: "15.0",
                                    west: "29.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_619,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 619,
                                    status: "available",
                                    block: 35,
                                    unitSize: 435.0,
                                    north: "15",
                                    east: "29.0",
                                    south: "15.0",
                                    west: "29.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_620,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 620,
                                    status: "available",
                                    block: 35,
                                    unitSize: 435.0,
                                    north: "15",
                                    east: "29.0",
                                    south: "15.0",
                                    west: "29.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_621,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 621,
                                    status: "available",
                                    block: 35,
                                    unitSize: 435.0,
                                    north: "15",
                                    east: "29.0",
                                    south: "15.0",
                                    west: "29.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_622,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 622,
                                    status: "available",
                                    block: 35,
                                    unitSize: 435.0,
                                    north: "15",
                                    east: "29.0",
                                    south: "15.0",
                                    west: "29.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_623,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 623,
                                    status: "available",
                                    block: 35,
                                    unitSize: 435.0,
                                    north: "15",
                                    east: "29.0",
                                    south: "15.0",
                                    west: "29.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_624,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 624,
                                    status: "available",
                                    block: 35,
                                    unitSize: 435.0,
                                    north: "15",
                                    east: "29.0",
                                    south: "15.0",
                                    west: "29.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_625,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 625,
                                    status: "available",
                                    block: 35,
                                    unitSize: 435.0,
                                    north: "15",
                                    east: "29.0",
                                    south: "15.0",
                                    west: "29.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_626,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 626,
                                    status: "available",
                                    block: 35,
                                    unitSize: 435.0,
                                    north: "15",
                                    east: "29.0",
                                    south: "15.0",
                                    west: "29.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_627,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 627,
                                    status: "available",
                                    block: 35,
                                    unitSize: 435.0,
                                    north: "15",
                                    east: "29.0",
                                    south: "15.0",
                                    west: "29.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_628,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 628,
                                    status: "available",
                                    block: 35,
                                    unitSize: 435.0,
                                    north: "15",
                                    east: "29.0",
                                    south: "15.0",
                                    west: "29.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_629,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 629,
                                    status: "available",
                                    block: 35,
                                    unitSize: 435.0,
                                    north: "15",
                                    east: "29.0",
                                    south: "15.0",
                                    west: "29.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_630,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 630,
                                    status: "available",
                                    block: 35,
                                    unitSize: 435.0,
                                    north: "15",
                                    east: "29.0",
                                    south: "15.0",
                                    west: "29.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_631,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 631,
                                    status: "available",
                                    block: 35,
                                    unitSize: 435.0,
                                    north: "15",
                                    east: "29.0",
                                    south: "15.0",
                                    west: "29.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_632,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 632,
                                    status: "available",
                                    block: 35,
                                    unitSize: 435.0,
                                    north: "15",
                                    east: "29.0",
                                    south: "15.0",
                                    west: "29.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_633,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 633,
                                    status: "available",
                                    block: 35,
                                    unitSize: 435.0,
                                    north: "15",
                                    east: "29.0",
                                    south: "15.0",
                                    west: "29.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_634,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 634,
                                    status: "available",
                                    block: 35,
                                    unitSize: 435.0,
                                    north: "15",
                                    east: "29.0",
                                    south: "15.0",
                                    west: "29.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_635,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 635,
                                    status: "available",
                                    block: 35,
                                    unitSize: 580.0,
                                    north: "20",
                                    east: "29.0",
                                    south: "20.0",
                                    west: "29.0",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_636,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 636,
                                    status: "available",
                                    block: 35,
                                    unitSize: 580.0,
                                    north: "20",
                                    east: "29.0",
                                    south: "20.0",
                                    west: "29.0",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_637,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 637,
                                    status: "available",
                                    block: 36,
                                    unitSize: 360.0,
                                    north: "15",
                                    east: "24.0",
                                    south: "15.0",
                                    west: "24.0",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_638,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 638,
                                    status: "available",
                                    block: 36,
                                    unitSize: 360.0,
                                    north: "15",
                                    east: "24.0",
                                    south: "15.0",
                                    west: "24.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_639,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 639,
                                    status: "available",
                                    block: 36,
                                    unitSize: 360.0,
                                    north: "15",
                                    east: "24.0",
                                    south: "15.0",
                                    west: "24.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_640,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 640,
                                    status: "available",
                                    block: 36,
                                    unitSize: 1514.65,
                                    north: "45",
                                    east: "33.16",
                                    south: "45.02",
                                    west: "34.16",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_641,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 641,
                                    status: "available",
                                    block: 36,
                                    unitSize: 360.0,
                                    north: "15",
                                    east: "24.0",
                                    south: "15.0",
                                    west: "24.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_642,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 642,
                                    status: "available",
                                    block: 36,
                                    unitSize: 360.0,
                                    north: "15",
                                    east: "24.0",
                                    south: "15.0",
                                    west: "24.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_643,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 643,
                                    status: "available",
                                    block: 36,
                                    unitSize: 1034.78,
                                    north: "30",
                                    east: "34.16",
                                    south: "30.01",
                                    west: "34.83",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_644,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 644,
                                    status: "available",
                                    block: 36,
                                    unitSize: 360.0,
                                    north: "15",
                                    east: "24.0",
                                    south: "15.0",
                                    west: "24.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_645,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 645,
                                    status: "available",
                                    block: 36,
                                    unitSize: 353.75,
                                    north: "15",
                                    east: "24.0",
                                    south: "15.0",
                                    west: "24.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_646,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 646,
                                    status: "available",
                                    block: 36,
                                    unitSize: 1054.81,
                                    north: "30",
                                    east: "34.83",
                                    south: "30.01",
                                    west: "35.49",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_647,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 647,
                                    status: "available",
                                    block: 36,
                                    unitSize: 353.75,
                                    north: "15",
                                    east: "24.0",
                                    south: "15.0",
                                    west: "24.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_648,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 648,
                                    status: "available",
                                    block: 36,
                                    unitSize: 360.0,
                                    north: "15",
                                    east: "24.0",
                                    south: "15.0",
                                    west: "24.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_649,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 649,
                                    status: "available",
                                    block: 36,
                                    unitSize: 1074.84,
                                    north: "30",
                                    east: "35.49",
                                    south: "30.01",
                                    west: "36.16",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_650,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 650,
                                    status: "available",
                                    block: 36,
                                    unitSize: 360.0,
                                    north: "15",
                                    east: "24.0",
                                    south: "15.0",
                                    west: "24.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_651,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 651,
                                    status: "available",
                                    block: 36,
                                    unitSize: 360.0,
                                    north: "15",
                                    east: "24.0",
                                    south: "15.0",
                                    west: "24.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_652,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 652,
                                    status: "available",
                                    block: 36,
                                    unitSize: 1094.88,
                                    north: "30",
                                    east: "36.16",
                                    south: "30.01",
                                    west: "36.83",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_653,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 653,
                                    status: "available",
                                    block: 36,
                                    unitSize: 360.0,
                                    north: "15",
                                    east: "24.0",
                                    south: "15.0",
                                    west: "24.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_654,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 654,
                                    status: "available",
                                    block: 36,
                                    unitSize: 360.0,
                                    north: "15",
                                    east: "24.0",
                                    south: "15.0",
                                    west: "24.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_655,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 655,
                                    status: "available",
                                    block: 36,
                                    unitSize: 1114.91,
                                    north: "30",
                                    east: "36.83",
                                    south: "30.01",
                                    west: "37.5",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_656,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 656,
                                    status: "available",
                                    block: 36,
                                    unitSize: 672.0,
                                    north: "28",
                                    east: "24.0",
                                    south: "28.0",
                                    west: "24.0",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_657,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 657,
                                    status: "available",
                                    block: 36,
                                    unitSize: 1058.66,
                                    north: "28",
                                    east: "37.5",
                                    south: "28.01",
                                    west: "38.12",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_658,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 658,
                                    status: "available",
                                    block: 37,
                                    unitSize: 540.0,
                                    north: "20",
                                    east: "27.0",
                                    south: "20.0",
                                    west: "27.0",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_659,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 659,
                                    status: "available",
                                    block: 37,
                                    unitSize: 540.0,
                                    north: "20",
                                    east: "27.0",
                                    south: "20.0",
                                    west: "27.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_660,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 660,
                                    status: "available",
                                    block: 37,
                                    unitSize: 675.0,
                                    north: "25",
                                    east: "27.0",
                                    south: "25.0",
                                    west: "27.0",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "25",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_661,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 661,
                                    status: "available",
                                    block: 37,
                                    unitSize: 450.0,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_662,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 662,
                                    status: "available",
                                    block: 37,
                                    unitSize: 525.0,
                                    north: "35",
                                    east: "15.0",
                                    south: "35.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_663,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 663,
                                    status: "available",
                                    block: 37,
                                    unitSize: 450.0,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_664,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 664,
                                    status: "available",
                                    block: 37,
                                    unitSize: 525.0,
                                    north: "35",
                                    east: "15.0",
                                    south: "35.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_665,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 665,
                                    status: "available",
                                    block: 37,
                                    unitSize: 450.0,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_666,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 666,
                                    status: "available",
                                    block: 37,
                                    unitSize: 525.0,
                                    north: "35",
                                    east: "15.0",
                                    south: "35.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_667,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 667,
                                    status: "available",
                                    block: 37,
                                    unitSize: 450.0,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_668,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 668,
                                    status: "available",
                                    block: 37,
                                    unitSize: 525.0,
                                    north: "35",
                                    east: "15.0",
                                    south: "35.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_669,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 669,
                                    status: "available",
                                    block: 37,
                                    unitSize: 450.0,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_670,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 670,
                                    status: "available",
                                    block: 37,
                                    unitSize: 525.0,
                                    north: "35",
                                    east: "15.0",
                                    south: "35.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_671,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 671,
                                    status: "available",
                                    block: 37,
                                    unitSize: 450.0,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_672,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 672,
                                    status: "available",
                                    block: 37,
                                    unitSize: 525.0,
                                    north: "35",
                                    east: "15.0",
                                    south: "35.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_673,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 673,
                                    status: "available",
                                    block: 37,
                                    unitSize: 450.0,
                                    north: "30",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_674,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 674,
                                    status: "available",
                                    block: 37,
                                    unitSize: 525.0,
                                    north: "35",
                                    east: "15.0",
                                    south: "35.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_675,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 675,
                                    status: "available",
                                    block: 37,
                                    unitSize: 1350.0,
                                    north: "30",
                                    east: "45.0",
                                    south: "30.0",
                                    west: "45.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15+6",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_676,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 676,
                                    status: "available",
                                    block: 37,
                                    unitSize: 525.0,
                                    north: "35",
                                    east: "15.0",
                                    south: "35.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_677,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 677,
                                    status: "available",
                                    block: 37,
                                    unitSize: 525.0,
                                    north: "35",
                                    east: "15.0",
                                    south: "35.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_678,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 678,
                                    status: "available",
                                    block: 37,
                                    unitSize: 525.0,
                                    north: "35",
                                    east: "15.0",
                                    south: "35.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_679,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 679,
                                    status: "available",
                                    block: 37,
                                    unitSize: 1007.65,
                                    north: "30",
                                    east: "33.25",
                                    south: "30.01",
                                    west: "33.92",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_680,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 680,
                                    status: "available",
                                    block: 37,
                                    unitSize: 1188.41,
                                    north: "35",
                                    east: "33.92",
                                    south: "35.01",
                                    west: "34.7",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: true,
                                    st_width_1: "60",
                                    st_width_2: "25",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_55,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 55,
                                    status: "available",
                                    block: 6,
                                    unitSize: 563.92,
                                    north: "34.43",
                                    east: "14.72",
                                    south: "32.51",
                                    west: "19.11",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_56,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 56,
                                    status: "available",
                                    block: 6,
                                    unitSize: 301.44,
                                    north: "15.13",
                                    east: "19.11",
                                    south: "15.0",
                                    west: "15.13",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_57,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 57,
                                    status: "available",
                                    block: 6,
                                    unitSize: 331.0,
                                    north: "15,13",
                                    east: "21.08",
                                    south: "15.0",
                                    west: "23.05",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_58,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 58,
                                    status: "available",
                                    block: 6,
                                    unitSize: 355.38,
                                    north: "14,74",
                                    east: "23.05",
                                    south: "14.97",
                                    west: "24.97",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "25",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_59,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 59,
                                    status: "available",
                                    block: 6,
                                    unitSize: 1010.53,
                                    north: "27.65",
                                    east: "39.68",
                                    south: "23.27",
                                    west: "40.1",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_60,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 60,
                                    status: "available",
                                    block: 6,
                                    unitSize: 1017.47,
                                    north: "25",
                                    east: "40.1",
                                    south: "25.03",
                                    west: "41.4",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_1_61,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 61,
                                    status: "available",
                                    block: 6,
                                    unitSize: 1063.3,
                                    north: "24.83",
                                    east: "41.4",
                                    south: "25.52",
                                    west: "43.34",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "25",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                        ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_7,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
////////////////////////////////////////////////////////////////////////////////////////index 30
    {
        index: 31,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: LandPlot_30_450,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 450,
                                status: "available",
                                block: 25,
                                unitSize: 408.73,
                                north: "15.14",
                                east: "26.26",
                                south: "15.0",
                                west: "28.24",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "25",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_451,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 451,
                                status: "available",
                                block: 25,
                                unitSize: 438.52,
                                north: "15.14",
                                east: "28.24",
                                south: "15.0",
                                west: "30.23",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_452,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 452,
                                status: "available",
                                block: 25,
                                unitSize: 516.4,
                                north: "30.29",
                                east: "15.23",
                                south: "30.0",
                                west: "19.2",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_453,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 453,
                                status: "available",
                                block: 25,
                                unitSize: 450.01,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_454,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 454,
                                status: "available",
                                block: 25,
                                unitSize: 450.01,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_455,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 455,
                                status: "available",
                                block: 25,
                                unitSize: 450.01,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_456,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 456,
                                status: "available",
                                block: 25,
                                unitSize: 450.01,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_457,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 457,
                                status: "available",
                                block: 25,
                                unitSize: 1350.03,
                                north: "30",
                                east: "45.0",
                                south: "30.0",
                                west: "45.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_458,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 458,
                                status: "available",
                                block: 25,
                                unitSize: 1062.43,
                                north: "30",
                                east: "33.58",
                                south: "30.2",
                                west: "37.25",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "25",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_459,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 459,
                                status: "available",
                                block: 25,
                                unitSize: 1172.53,
                                north: "30",
                                east: "37.25",
                                south: "30.2",
                                west: "40.92",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_460,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 460,
                                status: "available",
                                block: 26,
                                unitSize: 655.36,
                                north: "24.47",
                                east: "25.24",
                                south: "27.96",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_461,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 461,
                                status: "available",
                                block: 26,
                                unitSize: 479.01,
                                north: "24.96",
                                east: "13.29",
                                south: "30.52",
                                west: "19.8",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_462,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 462,
                                status: "available",
                                block: 26,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_463,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 463,
                                status: "available",
                                block: 26,
                                unitSize: 322.13,
                                north: "15",
                                east: "19.8",
                                south: "15.37",
                                west: "23.15",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_464,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 464,
                                status: "available",
                                block: 26,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_465,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 465,
                                status: "available",
                                block: 26,
                                unitSize: 372.27,
                                north: "15",
                                east: "23.15",
                                south: "15.37",
                                west: "26.49",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_466,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 466,
                                status: "available",
                                block: 26,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_467,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 467,
                                status: "available",
                                block: 26,
                                unitSize: 422.41,
                                north: "15",
                                east: "26.49",
                                south: "15.37",
                                west: "29.83",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_468,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 468,
                                status: "available",
                                block: 26,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_469,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 469,
                                status: "available",
                                block: 26,
                                unitSize: 397.54,
                                north: "15",
                                east: "24.83",
                                south: "15.37",
                                west: "28.17",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_470,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 470,
                                status: "available",
                                block: 26,
                                unitSize: 443.75,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_471,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 471,
                                status: "available",
                                block: 26,
                                unitSize: 447.68,
                                north: "15",
                                east: "28.17",
                                south: "15.37",
                                west: "31.52",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_472,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 472,
                                status: "available",
                                block: 26,
                                unitSize: 503.75,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_473,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 473,
                                status: "available",
                                block: 26,
                                unitSize: 567.98,
                                north: "17",
                                east: "31.52",
                                south: "17.42",
                                west: "35.3",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_474,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 474,
                                status: "available",
                                block: 26,
                                unitSize: 595.0,
                                north: "17",
                                east: "35.0",
                                south: "17.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_475,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 475,
                                status: "available",
                                block: 26,
                                unitSize: 547.38,
                                north: "17",
                                east: "30.3",
                                south: "17.42",
                                west: "34.09",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_476,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 476,
                                status: "available",
                                block: 26,
                                unitSize: 595.0,
                                north: "17",
                                east: "35.0",
                                south: "17.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_477,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 477,
                                status: "available",
                                block: 26,
                                unitSize: 611.77,
                                north: "17",
                                east: "34.09",
                                south: "17.42",
                                west: "37.88",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_478,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 478,
                                status: "available",
                                block: 26,
                                unitSize: 473.25,
                                north: "31.55",
                                east: "15.0",
                                south: "31.55",
                                west: "15.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_479,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 479,
                                status: "available",
                                block: 26,
                                unitSize: 473.25,
                                north: "31.55",
                                east: "15.0",
                                south: "31.55",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_480,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 480,
                                status: "available",
                                block: 26,
                                unitSize: 473.25,
                                north: "31.55",
                                east: "15.0",
                                south: "31.55",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_481,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 481,
                                status: "available",
                                block: 26,
                                unitSize: 473.25,
                                north: "31.55",
                                east: "15.0",
                                south: "31.55",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_482,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 482,
                                status: "available",
                                block: 26,
                                unitSize: 505.1,
                                north: "31.55",
                                east: "12.88",
                                south: "27.32",
                                west: "14.91",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_483,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 483,
                                status: "available",
                                block: 27,
                                unitSize: 692.22,
                                north: "24.29",
                                east: "30.1",
                                south: "21.86",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_484,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 484,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_485,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 485,
                                status: "available",
                                block: 27,
                                unitSize: 1423.06,
                                north: "36.86",
                                east: "39.96",
                                south: "33.65",
                                west: "40.98",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_486,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 486,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_487,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 487,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_488,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 488,
                                status: "available",
                                block: 27,
                                unitSize: 1232.46,
                                north: "30",
                                east: "40.98",
                                south: "30.02",
                                west: "42.02",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_489,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 489,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_490,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 490,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_491,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 491,
                                status: "available",
                                block: 27,
                                unitSize: 1275.94,
                                north: "30",
                                east: "42.02",
                                south: "30.02",
                                west: "43.05",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_492,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 492,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_493,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 493,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_494,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 494,
                                status: "available",
                                block: 27,
                                unitSize: 1306.92,
                                north: "30",
                                east: "43.05",
                                south: "30.02",
                                west: "44.08",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_495,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 495,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_496,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 496,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_497,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 497,
                                status: "available",
                                block: 27,
                                unitSize: 1337.9,
                                north: "30",
                                east: "44.08",
                                south: "30.02",
                                west: "45.11",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_498,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 498,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_499,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 499,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_500,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 500,
                                status: "available",
                                block: 27,
                                unitSize: 1368.88,
                                north: "30",
                                east: "45.11",
                                south: "30.02",
                                west: "46.15",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_501,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 501,
                                status: "available",
                                block: 27,
                                unitSize: 770.88,
                                north: "23.02",
                                east: "30.0",
                                south: "26.5",
                                west: "17.95",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_502,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 502,
                                status: "available",
                                block: 27,
                                unitSize: 1196.68,
                                north: "26.5",
                                east: "46.15",
                                south: "24.89",
                                west: "47.03",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_503,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 503,
                                status: "available",
                                block: 28,
                                unitSize: 687.5,
                                north: "27.5",
                                east: "25.0",
                                south: "27.5",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_504,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 504,
                                status: "available",
                                block: 28,
                                unitSize: 687.5,
                                north: "27.5",
                                east: "25.0",
                                south: "27.5",
                                west: "25.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_505,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 505,
                                status: "available",
                                block: 28,
                                unitSize: 1341.67,
                                north: "27.5",
                                east: "40.0",
                                south: "27.5",
                                west: "43.51",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_506,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 506,
                                status: "available",
                                block: 28,
                                unitSize: 687.5,
                                north: "27.5",
                                east: "25.0",
                                south: "27.5",
                                west: "25.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_507,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 507,
                                status: "available",
                                block: 28,
                                unitSize: 847.03,
                                north: "27.5",
                                east: "18.51",
                                south: "27.5",
                                west: "22.01",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_508,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 508,
                                status: "available",
                                block: 28,
                                unitSize: 1512.5,
                                north: "27.5",
                                east: "55.0",
                                south: "27.5",
                                west: "55.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_509,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 509,
                                status: "available",
                                block: 28,
                                unitSize: 1512.5,
                                north: "27.5",
                                east: "55.0",
                                south: "27.5",
                                west: "55.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_510,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 510,
                                status: "available",
                                block: 29,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_511,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 511,
                                status: "available",
                                block: 29,
                                unitSize: 425.0,
                                north: "17",
                                east: "25.0",
                                south: "17.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_512,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 512,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_513,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 513,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_514,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 514,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_515,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 515,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_516,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 516,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_517,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 517,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_518,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 518,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_519,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 519,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_520,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 520,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_521,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 521,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_522,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 522,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_523,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 523,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_524,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 524,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_525,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 525,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_526,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 526,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_527,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 527,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_528,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 528,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_529,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 529,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_530,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 530,
                                status: "available",
                                block: 29,
                                unitSize: 588.0,
                                north: "28",
                                east: "21.0",
                                south: "28.0",
                                west: "21.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_531,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 531,
                                status: "available",
                                block: 29,
                                unitSize: 476.0,
                                north: "28",
                                east: "17.0",
                                south: "28.0",
                                west: "17.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_532,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 532,
                                status: "available",
                                block: 29,
                                unitSize: 476.0,
                                north: "28",
                                east: "17.0",
                                south: "28.0",
                                west: "17.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_533,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 533,
                                status: "available",
                                block: 30,
                                unitSize: 657.11,
                                north: "17",
                                east: "38.19",
                                south: "17.03",
                                west: "39.22",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_534,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 534,
                                status: "available",
                                block: 30,
                                unitSize: 592.91,
                                north: "15",
                                east: "39.22",
                                south: "15.02",
                                west: "39.94",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_535,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 535,
                                status: "available",
                                block: 30,
                                unitSize: 605.19,
                                north: "15",
                                east: "39.94",
                                south: "15.02",
                                west: "40.76",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_536,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 536,
                                status: "available",
                                block: 30,
                                unitSize: 617.47,
                                north: "15",
                                east: "40.76",
                                south: "15.02",
                                west: "41.57",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_537,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 537,
                                status: "available",
                                block: 30,
                                unitSize: 629.75,
                                north: "15",
                                east: "41.57",
                                south: "15.02",
                                west: "42.39",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_538,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 538,
                                status: "available",
                                block: 30,
                                unitSize: 440.0,
                                north: "20",
                                east: "22.0",
                                south: "20.0",
                                west: "22.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_539,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 539,
                                status: "available",
                                block: 30,
                                unitSize: 418.78,
                                north: "20",
                                east: "20.39",
                                south: "20.03",
                                west: "22.58",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_540,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 540,
                                status: "available",
                                block: 30,
                                unitSize: 440.0,
                                north: "20",
                                east: "22.0",
                                south: "20.0",
                                west: "22.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_541,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 541,
                                status: "available",
                                block: 30,
                                unitSize: 440.61,
                                north: "20",
                                east: "21.48",
                                south: "20.03",
                                west: "22.58",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_542,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 542,
                                status: "available",
                                block: 30,
                                unitSize: 396.0,
                                north: "18",
                                east: "22.0",
                                south: "18.0",
                                west: "22.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_543,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 543,
                                status: "available",
                                block: 30,
                                unitSize: 415.22,
                                north: "18",
                                east: "22.58",
                                south: "18.03",
                                west: "23.56",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_544,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 544,
                                status: "available",
                                block: 30,
                                unitSize: 396.0,
                                north: "18",
                                east: "22.0",
                                south: "18.0",
                                west: "22.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_545,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 545,
                                status: "available",
                                block: 30,
                                unitSize: 432.9,
                                north: "18",
                                east: "23.56",
                                south: "18.03",
                                west: "24.54",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_546,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 546,
                                status: "available",
                                block: 30,
                                unitSize: 459.0,
                                north: "27",
                                east: "17.0",
                                south: "27.0",
                                west: "17.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_547,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 547,
                                status: "available",
                                block: 30,
                                unitSize: 405.0,
                                north: "27",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_548,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 548,
                                status: "available",
                                block: 30,
                                unitSize: 412.52,
                                north: "27",
                                east: "14.54",
                                south: "27.04",
                                west: "16.02",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_549,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 549,
                                status: "available",
                                block: 31,
                                unitSize: 592.69,
                                north: "16.73",
                                east: "30.6",
                                south: "22.78",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_550,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 550,
                                status: "available",
                                block: 31,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_551,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 551,
                                status: "available",
                                block: 31,
                                unitSize: 1841.62,
                                north: "39.78",
                                east: "38.53",
                                south: "48.04",
                                west: "45.12",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_552,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 552,
                                status: "available",
                                block: 31,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_553,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 553,
                                status: "available",
                                block: 31,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_554,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 554,
                                status: "available",
                                block: 31,
                                unitSize: 1524.82,
                                north: "34",
                                east: "45.12",
                                south: "34.02",
                                west: "44.32",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_555,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 555,
                                status: "available",
                                block: 31,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_556,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 556,
                                status: "available",
                                block: 31,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_557,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 557,
                                status: "available",
                                block: 31,
                                unitSize: 1488.16,
                                north: "34",
                                east: "44.32",
                                south: "34.02",
                                west: "43.22",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_558,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 558,
                                status: "available",
                                block: 31,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_559,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 559,
                                status: "available",
                                block: 31,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_560,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 560,
                                status: "available",
                                block: 31,
                                unitSize: 1450.46,
                                north: "34",
                                east: "43.22",
                                south: "34.02",
                                west: "42.11",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_561,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 561,
                                status: "available",
                                block: 31,
                                unitSize: 480.0,
                                north: "16",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_562,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 562,
                                status: "available",
                                block: 31,
                                unitSize: 480.0,
                                north: "16",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_563,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 563,
                                status: "available",
                                block: 31,
                                unitSize: 1318.2,
                                north: "32",
                                east: "42.11",
                                south: "32.02",
                                west: "41.06",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_564,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 564,
                                status: "available",
                                block: 31,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_565,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 565,
                                status: "available",
                                block: 31,
                                unitSize: 483.41,
                                north: "15.29",
                                east: "30.0",
                                south: "16.93",
                                west: "30.04",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_566,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 566,
                                status: "available",
                                block: 31,
                                unitSize: 1338.24,
                                north: "31.93",
                                east: "41.01",
                                south: "34.13",
                                west: "40.01",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_567,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 567,
                                status: "available",
                                block: 32,
                                unitSize: 564.85,
                                north: "20",
                                east: "28.24",
                                south: "20.0",
                                west: "28.24",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_568,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 568,
                                status: "available",
                                block: 32,
                                unitSize: 564.85,
                                north: "20",
                                east: "28.24",
                                south: "20.0",
                                west: "28.24",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_569,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 569,
                                status: "available",
                                block: 32,
                                unitSize: 552.35,
                                north: "20",
                                east: "28.24",
                                south: "20.0",
                                west: "28.24",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_570,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 570,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_571,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 571,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_572,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 572,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_573,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 573,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_574,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 574,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_575,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 575,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_576,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 576,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_577,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 577,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_578,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 578,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_579,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 579,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_580,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 580,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_581,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 581,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_582,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 582,
                                status: "available",
                                block: 32,
                                unitSize: 508.31,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "18.89",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_583,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 583,
                                status: "available",
                                block: 32,
                                unitSize: 624.94,
                                north: "30",
                                east: "18.89",
                                south: "30.25",
                                west: "22.78",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_584,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 584,
                                status: "available",
                                block: 33,
                                unitSize: 3659.06,
                                north: "60.5",
                                east: "64.42",
                                south: "60.01",
                                west: "57.96",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_585,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 585,
                                status: "available",
                                block: 34,
                                unitSize: 600.0,
                                north: "20",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_586,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 586,
                                status: "available",
                                block: 34,
                                unitSize: 600.0,
                                north: "20",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_587,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 587,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_588,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 588,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_589,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 589,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_590,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 590,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_591,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 591,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_592,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 592,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_593,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 593,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_594,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 594,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_595,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 595,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_596,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 596,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_597,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 597,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_598,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 598,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_599,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 599,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_600,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 600,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_601,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 601,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_602,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 602,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_603,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 603,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_604,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 604,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_605,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 605,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_606,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 606,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_607,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 607,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_608,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 608,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_609,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 609,
                                status: "available",
                                block: 34,
                                unitSize: 600.0,
                                north: "20",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_610,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 610,
                                status: "available",
                                block: 34,
                                unitSize: 600.0,
                                north: "20",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_611,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 611,
                                status: "available",
                                block: 35,
                                unitSize: 580.0,
                                north: "20",
                                east: "29.0",
                                south: "20.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_612,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 612,
                                status: "available",
                                block: 35,
                                unitSize: 580.0,
                                north: "20",
                                east: "29.0",
                                south: "20.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_613,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 613,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_614,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 614,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_615,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 615,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_616,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 616,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_617,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 617,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_618,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 618,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_619,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 619,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_620,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 620,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_621,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 621,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_622,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 622,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_623,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 623,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_624,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 624,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_625,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 625,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_626,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 626,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_627,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 627,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_628,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 628,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_629,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 629,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_630,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 630,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_631,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 631,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_632,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 632,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_633,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 633,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_634,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 634,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_635,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 635,
                                status: "available",
                                block: 35,
                                unitSize: 580.0,
                                north: "20",
                                east: "29.0",
                                south: "20.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_636,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 636,
                                status: "available",
                                block: 35,
                                unitSize: 580.0,
                                north: "20",
                                east: "29.0",
                                south: "20.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_637,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 637,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_638,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 638,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_639,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 639,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_640,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 640,
                                status: "available",
                                block: 36,
                                unitSize: 1514.65,
                                north: "45",
                                east: "33.16",
                                south: "45.02",
                                west: "34.16",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_641,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 641,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_642,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 642,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_643,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 643,
                                status: "available",
                                block: 36,
                                unitSize: 1034.78,
                                north: "30",
                                east: "34.16",
                                south: "30.01",
                                west: "34.83",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_644,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 644,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_645,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 645,
                                status: "available",
                                block: 36,
                                unitSize: 353.75,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_646,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 646,
                                status: "available",
                                block: 36,
                                unitSize: 1054.81,
                                north: "30",
                                east: "34.83",
                                south: "30.01",
                                west: "35.49",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_647,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 647,
                                status: "available",
                                block: 36,
                                unitSize: 353.75,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_648,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 648,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_649,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 649,
                                status: "available",
                                block: 36,
                                unitSize: 1074.84,
                                north: "30",
                                east: "35.49",
                                south: "30.01",
                                west: "36.16",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_650,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 650,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_651,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 651,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_652,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 652,
                                status: "available",
                                block: 36,
                                unitSize: 1094.88,
                                north: "30",
                                east: "36.16",
                                south: "30.01",
                                west: "36.83",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_653,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 653,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_654,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 654,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_655,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 655,
                                status: "available",
                                block: 36,
                                unitSize: 1114.91,
                                north: "30",
                                east: "36.83",
                                south: "30.01",
                                west: "37.5",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_656,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 656,
                                status: "available",
                                block: 36,
                                unitSize: 672.0,
                                north: "28",
                                east: "24.0",
                                south: "28.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_657,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 657,
                                status: "available",
                                block: 36,
                                unitSize: 1058.66,
                                north: "28",
                                east: "37.5",
                                south: "28.01",
                                west: "38.12",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_658,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 658,
                                status: "available",
                                block: 37,
                                unitSize: 540.0,
                                north: "20",
                                east: "27.0",
                                south: "20.0",
                                west: "27.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_659,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 659,
                                status: "available",
                                block: 37,
                                unitSize: 540.0,
                                north: "20",
                                east: "27.0",
                                south: "20.0",
                                west: "27.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_660,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 660,
                                status: "available",
                                block: 37,
                                unitSize: 675.0,
                                north: "25",
                                east: "27.0",
                                south: "25.0",
                                west: "27.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "25",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_661,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 661,
                                status: "available",
                                block: 37,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_662,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 662,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_663,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 663,
                                status: "available",
                                block: 37,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_664,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 664,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_665,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 665,
                                status: "available",
                                block: 37,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_666,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 666,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_667,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 667,
                                status: "available",
                                block: 37,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_668,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 668,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_669,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 669,
                                status: "available",
                                block: 37,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_670,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 670,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_671,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 671,
                                status: "available",
                                block: 37,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_672,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 672,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_673,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 673,
                                status: "available",
                                block: 37,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_674,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 674,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_675,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 675,
                                status: "available",
                                block: 37,
                                unitSize: 1350.0,
                                north: "30",
                                east: "45.0",
                                south: "30.0",
                                west: "45.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_676,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 676,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_677,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 677,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_678,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 678,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_679,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 679,
                                status: "available",
                                block: 37,
                                unitSize: 1007.65,
                                north: "30",
                                east: "33.25",
                                south: "30.01",
                                west: "33.92",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_680,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 680,
                                status: "available",
                                block: 37,
                                unitSize: 1188.41,
                                north: "35",
                                east: "33.92",
                                south: "35.01",
                                west: "34.7",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "25",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_55,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 55,
                                status: "available",
                                block: 6,
                                unitSize: 563.92,
                                north: "34.43",
                                east: "14.72",
                                south: "32.51",
                                west: "19.11",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_56,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 56,
                                status: "available",
                                block: 6,
                                unitSize: 301.44,
                                north: "15.13",
                                east: "19.11",
                                south: "15.0",
                                west: "15.13",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_57,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 57,
                                status: "available",
                                block: 6,
                                unitSize: 331.0,
                                north: "15,13",
                                east: "21.08",
                                south: "15.0",
                                west: "23.05",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_58,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 58,
                                status: "available",
                                block: 6,
                                unitSize: 355.38,
                                north: "14,74",
                                east: "23.05",
                                south: "14.97",
                                west: "24.97",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_59,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 59,
                                status: "available",
                                block: 6,
                                unitSize: 1010.53,
                                north: "27.65",
                                east: "39.68",
                                south: "23.27",
                                west: "40.1",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_60,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 60,
                                status: "available",
                                block: 6,
                                unitSize: 1017.47,
                                north: "25",
                                east: "40.1",
                                south: "25.03",
                                west: "41.4",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_30_61,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 61,
                                status: "available",
                                block: 6,
                                unitSize: 1063.3,
                                north: "24.83",
                                east: "41.4",
                                south: "25.52",
                                west: "43.34",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "25",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_33_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_33_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_33_1,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
////////////////////////////////////////////////////////////////////////////////////////index 60
    {
        index: 61,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: LandPlot_60_450,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 450,
                                status: "available",
                                block: 25,
                                unitSize: 408.73,
                                north: "15.14",
                                east: "26.26",
                                south: "15.0",
                                west: "28.24",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "25",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_451,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 451,
                                status: "available",
                                block: 25,
                                unitSize: 438.52,
                                north: "15.14",
                                east: "28.24",
                                south: "15.0",
                                west: "30.23",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_452,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 452,
                                status: "available",
                                block: 25,
                                unitSize: 516.4,
                                north: "30.29",
                                east: "15.23",
                                south: "30.0",
                                west: "19.2",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_453,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 453,
                                status: "available",
                                block: 25,
                                unitSize: 450.01,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_454,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 454,
                                status: "available",
                                block: 25,
                                unitSize: 450.01,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_455,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 455,
                                status: "available",
                                block: 25,
                                unitSize: 450.01,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_456,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 456,
                                status: "available",
                                block: 25,
                                unitSize: 450.01,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_457,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 457,
                                status: "available",
                                block: 25,
                                unitSize: 1350.03,
                                north: "30",
                                east: "45.0",
                                south: "30.0",
                                west: "45.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_458,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 458,
                                status: "available",
                                block: 25,
                                unitSize: 1062.43,
                                north: "30",
                                east: "33.58",
                                south: "30.2",
                                west: "37.25",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "25",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_459,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 459,
                                status: "available",
                                block: 25,
                                unitSize: 1172.53,
                                north: "30",
                                east: "37.25",
                                south: "30.2",
                                west: "40.92",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_460,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 460,
                                status: "available",
                                block: 26,
                                unitSize: 655.36,
                                north: "24.47",
                                east: "25.24",
                                south: "27.96",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_461,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 461,
                                status: "available",
                                block: 26,
                                unitSize: 479.01,
                                north: "24.96",
                                east: "13.29",
                                south: "30.52",
                                west: "19.8",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_462,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 462,
                                status: "available",
                                block: 26,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_463,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 463,
                                status: "available",
                                block: 26,
                                unitSize: 322.13,
                                north: "15",
                                east: "19.8",
                                south: "15.37",
                                west: "23.15",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_464,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 464,
                                status: "available",
                                block: 26,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_465,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 465,
                                status: "available",
                                block: 26,
                                unitSize: 372.27,
                                north: "15",
                                east: "23.15",
                                south: "15.37",
                                west: "26.49",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_466,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 466,
                                status: "available",
                                block: 26,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_467,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 467,
                                status: "available",
                                block: 26,
                                unitSize: 422.41,
                                north: "15",
                                east: "26.49",
                                south: "15.37",
                                west: "29.83",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_468,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 468,
                                status: "available",
                                block: 26,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_469,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 469,
                                status: "available",
                                block: 26,
                                unitSize: 397.54,
                                north: "15",
                                east: "24.83",
                                south: "15.37",
                                west: "28.17",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_470,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 470,
                                status: "available",
                                block: 26,
                                unitSize: 443.75,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_471,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 471,
                                status: "available",
                                block: 26,
                                unitSize: 447.68,
                                north: "15",
                                east: "28.17",
                                south: "15.37",
                                west: "31.52",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_472,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 472,
                                status: "available",
                                block: 26,
                                unitSize: 503.75,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_473,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 473,
                                status: "available",
                                block: 26,
                                unitSize: 567.98,
                                north: "17",
                                east: "31.52",
                                south: "17.42",
                                west: "35.3",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_474,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 474,
                                status: "available",
                                block: 26,
                                unitSize: 595.0,
                                north: "17",
                                east: "35.0",
                                south: "17.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_475,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 475,
                                status: "available",
                                block: 26,
                                unitSize: 547.38,
                                north: "17",
                                east: "30.3",
                                south: "17.42",
                                west: "34.09",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_476,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 476,
                                status: "available",
                                block: 26,
                                unitSize: 595.0,
                                north: "17",
                                east: "35.0",
                                south: "17.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_477,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 477,
                                status: "available",
                                block: 26,
                                unitSize: 611.77,
                                north: "17",
                                east: "34.09",
                                south: "17.42",
                                west: "37.88",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_478,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 478,
                                status: "available",
                                block: 26,
                                unitSize: 473.25,
                                north: "31.55",
                                east: "15.0",
                                south: "31.55",
                                west: "15.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_479,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 479,
                                status: "available",
                                block: 26,
                                unitSize: 473.25,
                                north: "31.55",
                                east: "15.0",
                                south: "31.55",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_480,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 480,
                                status: "available",
                                block: 26,
                                unitSize: 473.25,
                                north: "31.55",
                                east: "15.0",
                                south: "31.55",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_481,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 481,
                                status: "available",
                                block: 26,
                                unitSize: 473.25,
                                north: "31.55",
                                east: "15.0",
                                south: "31.55",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_482,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 482,
                                status: "available",
                                block: 26,
                                unitSize: 505.1,
                                north: "31.55",
                                east: "12.88",
                                south: "27.32",
                                west: "14.91",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_483,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 483,
                                status: "available",
                                block: 27,
                                unitSize: 692.22,
                                north: "24.29",
                                east: "30.1",
                                south: "21.86",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_484,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 484,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_485,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 485,
                                status: "available",
                                block: 27,
                                unitSize: 1423.06,
                                north: "36.86",
                                east: "39.96",
                                south: "33.65",
                                west: "40.98",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_486,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 486,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_487,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 487,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_488,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 488,
                                status: "available",
                                block: 27,
                                unitSize: 1232.46,
                                north: "30",
                                east: "40.98",
                                south: "30.02",
                                west: "42.02",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_489,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 489,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_490,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 490,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_491,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 491,
                                status: "available",
                                block: 27,
                                unitSize: 1275.94,
                                north: "30",
                                east: "42.02",
                                south: "30.02",
                                west: "43.05",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_492,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 492,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_493,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 493,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_494,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 494,
                                status: "available",
                                block: 27,
                                unitSize: 1306.92,
                                north: "30",
                                east: "43.05",
                                south: "30.02",
                                west: "44.08",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_495,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 495,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_496,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 496,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_497,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 497,
                                status: "available",
                                block: 27,
                                unitSize: 1337.9,
                                north: "30",
                                east: "44.08",
                                south: "30.02",
                                west: "45.11",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_498,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 498,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_499,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 499,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_500,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 500,
                                status: "available",
                                block: 27,
                                unitSize: 1368.88,
                                north: "30",
                                east: "45.11",
                                south: "30.02",
                                west: "46.15",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_501,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 501,
                                status: "available",
                                block: 27,
                                unitSize: 770.88,
                                north: "23.02",
                                east: "30.0",
                                south: "26.5",
                                west: "17.95",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_502,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 502,
                                status: "available",
                                block: 27,
                                unitSize: 1196.68,
                                north: "26.5",
                                east: "46.15",
                                south: "24.89",
                                west: "47.03",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_503,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 503,
                                status: "available",
                                block: 28,
                                unitSize: 687.5,
                                north: "27.5",
                                east: "25.0",
                                south: "27.5",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_504,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 504,
                                status: "available",
                                block: 28,
                                unitSize: 687.5,
                                north: "27.5",
                                east: "25.0",
                                south: "27.5",
                                west: "25.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_505,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 505,
                                status: "available",
                                block: 28,
                                unitSize: 1341.67,
                                north: "27.5",
                                east: "40.0",
                                south: "27.5",
                                west: "43.51",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_506,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 506,
                                status: "available",
                                block: 28,
                                unitSize: 687.5,
                                north: "27.5",
                                east: "25.0",
                                south: "27.5",
                                west: "25.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_507,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 507,
                                status: "available",
                                block: 28,
                                unitSize: 847.03,
                                north: "27.5",
                                east: "18.51",
                                south: "27.5",
                                west: "22.01",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_508,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 508,
                                status: "available",
                                block: 28,
                                unitSize: 1512.5,
                                north: "27.5",
                                east: "55.0",
                                south: "27.5",
                                west: "55.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_509,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 509,
                                status: "available",
                                block: 28,
                                unitSize: 1512.5,
                                north: "27.5",
                                east: "55.0",
                                south: "27.5",
                                west: "55.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_510,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 510,
                                status: "available",
                                block: 29,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_511,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 511,
                                status: "available",
                                block: 29,
                                unitSize: 425.0,
                                north: "17",
                                east: "25.0",
                                south: "17.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_512,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 512,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_513,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 513,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_514,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 514,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_515,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 515,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_516,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 516,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_517,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 517,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_518,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 518,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_519,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 519,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_520,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 520,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_521,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 521,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_522,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 522,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_523,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 523,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_524,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 524,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_525,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 525,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_526,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 526,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_527,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 527,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_528,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 528,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_529,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 529,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_530,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 530,
                                status: "available",
                                block: 29,
                                unitSize: 588.0,
                                north: "28",
                                east: "21.0",
                                south: "28.0",
                                west: "21.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_531,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 531,
                                status: "available",
                                block: 29,
                                unitSize: 476.0,
                                north: "28",
                                east: "17.0",
                                south: "28.0",
                                west: "17.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_532,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 532,
                                status: "available",
                                block: 29,
                                unitSize: 476.0,
                                north: "28",
                                east: "17.0",
                                south: "28.0",
                                west: "17.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_533,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 533,
                                status: "available",
                                block: 30,
                                unitSize: 657.11,
                                north: "17",
                                east: "38.19",
                                south: "17.03",
                                west: "39.22",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_534,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 534,
                                status: "available",
                                block: 30,
                                unitSize: 592.91,
                                north: "15",
                                east: "39.22",
                                south: "15.02",
                                west: "39.94",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_535,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 535,
                                status: "available",
                                block: 30,
                                unitSize: 605.19,
                                north: "15",
                                east: "39.94",
                                south: "15.02",
                                west: "40.76",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_536,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 536,
                                status: "available",
                                block: 30,
                                unitSize: 617.47,
                                north: "15",
                                east: "40.76",
                                south: "15.02",
                                west: "41.57",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_537,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 537,
                                status: "available",
                                block: 30,
                                unitSize: 629.75,
                                north: "15",
                                east: "41.57",
                                south: "15.02",
                                west: "42.39",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_538,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 538,
                                status: "available",
                                block: 30,
                                unitSize: 440.0,
                                north: "20",
                                east: "22.0",
                                south: "20.0",
                                west: "22.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_539,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 539,
                                status: "available",
                                block: 30,
                                unitSize: 418.78,
                                north: "20",
                                east: "20.39",
                                south: "20.03",
                                west: "22.58",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_540,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 540,
                                status: "available",
                                block: 30,
                                unitSize: 440.0,
                                north: "20",
                                east: "22.0",
                                south: "20.0",
                                west: "22.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_541,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 541,
                                status: "available",
                                block: 30,
                                unitSize: 440.61,
                                north: "20",
                                east: "21.48",
                                south: "20.03",
                                west: "22.58",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_542,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 542,
                                status: "available",
                                block: 30,
                                unitSize: 396.0,
                                north: "18",
                                east: "22.0",
                                south: "18.0",
                                west: "22.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_543,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 543,
                                status: "available",
                                block: 30,
                                unitSize: 415.22,
                                north: "18",
                                east: "22.58",
                                south: "18.03",
                                west: "23.56",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_544,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 544,
                                status: "available",
                                block: 30,
                                unitSize: 396.0,
                                north: "18",
                                east: "22.0",
                                south: "18.0",
                                west: "22.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_545,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 545,
                                status: "available",
                                block: 30,
                                unitSize: 432.9,
                                north: "18",
                                east: "23.56",
                                south: "18.03",
                                west: "24.54",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_546,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 546,
                                status: "available",
                                block: 30,
                                unitSize: 459.0,
                                north: "27",
                                east: "17.0",
                                south: "27.0",
                                west: "17.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_547,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 547,
                                status: "available",
                                block: 30,
                                unitSize: 405.0,
                                north: "27",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_548,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 548,
                                status: "available",
                                block: 30,
                                unitSize: 412.52,
                                north: "27",
                                east: "14.54",
                                south: "27.04",
                                west: "16.02",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_549,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 549,
                                status: "available",
                                block: 31,
                                unitSize: 592.69,
                                north: "16.73",
                                east: "30.6",
                                south: "22.78",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_550,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 550,
                                status: "available",
                                block: 31,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_551,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 551,
                                status: "available",
                                block: 31,
                                unitSize: 1841.62,
                                north: "39.78",
                                east: "38.53",
                                south: "48.04",
                                west: "45.12",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_552,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 552,
                                status: "available",
                                block: 31,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_553,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 553,
                                status: "available",
                                block: 31,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_554,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 554,
                                status: "available",
                                block: 31,
                                unitSize: 1524.82,
                                north: "34",
                                east: "45.12",
                                south: "34.02",
                                west: "44.32",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_555,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 555,
                                status: "available",
                                block: 31,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_556,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 556,
                                status: "available",
                                block: 31,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_557,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 557,
                                status: "available",
                                block: 31,
                                unitSize: 1488.16,
                                north: "34",
                                east: "44.32",
                                south: "34.02",
                                west: "43.22",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_558,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 558,
                                status: "available",
                                block: 31,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_559,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 559,
                                status: "available",
                                block: 31,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_560,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 560,
                                status: "available",
                                block: 31,
                                unitSize: 1450.46,
                                north: "34",
                                east: "43.22",
                                south: "34.02",
                                west: "42.11",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_561,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 561,
                                status: "available",
                                block: 31,
                                unitSize: 480.0,
                                north: "16",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_562,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 562,
                                status: "available",
                                block: 31,
                                unitSize: 480.0,
                                north: "16",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_563,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 563,
                                status: "available",
                                block: 31,
                                unitSize: 1318.2,
                                north: "32",
                                east: "42.11",
                                south: "32.02",
                                west: "41.06",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_564,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 564,
                                status: "available",
                                block: 31,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_565,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 565,
                                status: "available",
                                block: 31,
                                unitSize: 483.41,
                                north: "15.29",
                                east: "30.0",
                                south: "16.93",
                                west: "30.04",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_566,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 566,
                                status: "available",
                                block: 31,
                                unitSize: 1338.24,
                                north: "31.93",
                                east: "41.01",
                                south: "34.13",
                                west: "40.01",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_567,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 567,
                                status: "available",
                                block: 32,
                                unitSize: 564.85,
                                north: "20",
                                east: "28.24",
                                south: "20.0",
                                west: "28.24",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_568,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 568,
                                status: "available",
                                block: 32,
                                unitSize: 564.85,
                                north: "20",
                                east: "28.24",
                                south: "20.0",
                                west: "28.24",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_569,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 569,
                                status: "available",
                                block: 32,
                                unitSize: 552.35,
                                north: "20",
                                east: "28.24",
                                south: "20.0",
                                west: "28.24",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_570,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 570,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_571,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 571,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_572,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 572,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_573,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 573,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_574,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 574,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_575,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 575,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_576,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 576,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_577,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 577,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_578,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 578,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_579,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 579,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_580,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 580,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_581,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 581,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_582,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 582,
                                status: "available",
                                block: 32,
                                unitSize: 508.31,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "18.89",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_583,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 583,
                                status: "available",
                                block: 32,
                                unitSize: 624.94,
                                north: "30",
                                east: "18.89",
                                south: "30.25",
                                west: "22.78",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_584,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 584,
                                status: "available",
                                block: 33,
                                unitSize: 3659.06,
                                north: "60.5",
                                east: "64.42",
                                south: "60.01",
                                west: "57.96",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_585,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 585,
                                status: "available",
                                block: 34,
                                unitSize: 600.0,
                                north: "20",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_586,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 586,
                                status: "available",
                                block: 34,
                                unitSize: 600.0,
                                north: "20",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_587,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 587,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_588,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 588,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_589,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 589,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_590,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 590,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_591,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 591,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_592,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 592,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_593,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 593,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_594,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 594,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_595,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 595,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_596,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 596,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_597,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 597,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_598,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 598,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_599,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 599,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_600,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 600,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_601,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 601,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_602,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 602,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_603,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 603,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_604,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 604,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_605,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 605,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_606,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 606,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_607,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 607,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_608,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 608,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_609,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 609,
                                status: "available",
                                block: 34,
                                unitSize: 600.0,
                                north: "20",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_610,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 610,
                                status: "available",
                                block: 34,
                                unitSize: 600.0,
                                north: "20",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_611,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 611,
                                status: "available",
                                block: 35,
                                unitSize: 580.0,
                                north: "20",
                                east: "29.0",
                                south: "20.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_612,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 612,
                                status: "available",
                                block: 35,
                                unitSize: 580.0,
                                north: "20",
                                east: "29.0",
                                south: "20.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_613,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 613,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_614,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 614,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_615,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 615,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_616,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 616,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_617,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 617,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_618,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 618,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_619,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 619,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_620,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 620,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_621,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 621,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_622,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 622,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_623,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 623,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_624,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 624,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_625,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 625,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_626,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 626,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_627,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 627,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_628,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 628,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_629,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 629,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_630,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 630,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_631,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 631,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_632,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 632,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_633,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 633,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_634,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 634,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_635,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 635,
                                status: "available",
                                block: 35,
                                unitSize: 580.0,
                                north: "20",
                                east: "29.0",
                                south: "20.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_636,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 636,
                                status: "available",
                                block: 35,
                                unitSize: 580.0,
                                north: "20",
                                east: "29.0",
                                south: "20.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_637,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 637,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_638,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 638,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_639,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 639,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_640,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 640,
                                status: "available",
                                block: 36,
                                unitSize: 1514.65,
                                north: "45",
                                east: "33.16",
                                south: "45.02",
                                west: "34.16",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_641,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 641,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_642,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 642,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_643,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 643,
                                status: "available",
                                block: 36,
                                unitSize: 1034.78,
                                north: "30",
                                east: "34.16",
                                south: "30.01",
                                west: "34.83",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_644,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 644,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_645,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 645,
                                status: "available",
                                block: 36,
                                unitSize: 353.75,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_646,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 646,
                                status: "available",
                                block: 36,
                                unitSize: 1054.81,
                                north: "30",
                                east: "34.83",
                                south: "30.01",
                                west: "35.49",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_647,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 647,
                                status: "available",
                                block: 36,
                                unitSize: 353.75,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_648,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 648,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_649,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 649,
                                status: "available",
                                block: 36,
                                unitSize: 1074.84,
                                north: "30",
                                east: "35.49",
                                south: "30.01",
                                west: "36.16",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_650,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 650,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_651,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 651,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_652,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 652,
                                status: "available",
                                block: 36,
                                unitSize: 1094.88,
                                north: "30",
                                east: "36.16",
                                south: "30.01",
                                west: "36.83",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_653,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 653,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_654,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 654,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_655,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 655,
                                status: "available",
                                block: 36,
                                unitSize: 1114.91,
                                north: "30",
                                east: "36.83",
                                south: "30.01",
                                west: "37.5",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_656,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 656,
                                status: "available",
                                block: 36,
                                unitSize: 672.0,
                                north: "28",
                                east: "24.0",
                                south: "28.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_657,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 657,
                                status: "available",
                                block: 36,
                                unitSize: 1058.66,
                                north: "28",
                                east: "37.5",
                                south: "28.01",
                                west: "38.12",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_658,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 658,
                                status: "available",
                                block: 37,
                                unitSize: 540.0,
                                north: "20",
                                east: "27.0",
                                south: "20.0",
                                west: "27.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_659,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 659,
                                status: "available",
                                block: 37,
                                unitSize: 540.0,
                                north: "20",
                                east: "27.0",
                                south: "20.0",
                                west: "27.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_660,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 660,
                                status: "available",
                                block: 37,
                                unitSize: 675.0,
                                north: "25",
                                east: "27.0",
                                south: "25.0",
                                west: "27.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "25",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_661,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 661,
                                status: "available",
                                block: 37,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_662,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 662,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_663,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 663,
                                status: "available",
                                block: 37,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_664,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 664,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_665,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 665,
                                status: "available",
                                block: 37,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_666,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 666,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_667,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 667,
                                status: "available",
                                block: 37,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_668,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 668,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_669,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 669,
                                status: "available",
                                block: 37,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_670,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 670,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_671,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 671,
                                status: "available",
                                block: 37,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_672,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 672,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_673,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 673,
                                status: "available",
                                block: 37,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_674,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 674,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_675,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 675,
                                status: "available",
                                block: 37,
                                unitSize: 1350.0,
                                north: "30",
                                east: "45.0",
                                south: "30.0",
                                west: "45.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_676,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 676,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_677,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 677,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_678,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 678,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_679,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 679,
                                status: "available",
                                block: 37,
                                unitSize: 1007.65,
                                north: "30",
                                east: "33.25",
                                south: "30.01",
                                west: "33.92",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_680,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 680,
                                status: "available",
                                block: 37,
                                unitSize: 1188.41,
                                north: "35",
                                east: "33.92",
                                south: "35.01",
                                west: "34.7",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "25",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_55,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 55,
                                status: "available",
                                block: 6,
                                unitSize: 563.92,
                                north: "34.43",
                                east: "14.72",
                                south: "32.51",
                                west: "19.11",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_56,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 56,
                                status: "available",
                                block: 6,
                                unitSize: 301.44,
                                north: "15.13",
                                east: "19.11",
                                south: "15.0",
                                west: "15.13",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_57,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 57,
                                status: "available",
                                block: 6,
                                unitSize: 331.0,
                                north: "15,13",
                                east: "21.08",
                                south: "15.0",
                                west: "23.05",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_58,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 58,
                                status: "available",
                                block: 6,
                                unitSize: 355.38,
                                north: "14,74",
                                east: "23.05",
                                south: "14.97",
                                west: "24.97",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_59,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 59,
                                status: "available",
                                block: 6,
                                unitSize: 1010.53,
                                north: "27.65",
                                east: "39.68",
                                south: "23.27",
                                west: "40.1",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_60,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 60,
                                status: "available",
                                block: 6,
                                unitSize: 1017.47,
                                north: "25",
                                east: "40.1",
                                south: "25.03",
                                west: "41.4",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_60_61,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 61,
                                status: "available",
                                block: 6,
                                unitSize: 1063.3,
                                north: "24.83",
                                east: "41.4",
                                south: "25.52",
                                west: "43.34",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "25",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_61_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_61_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_61_1,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
////////////////////////////////////////////////////////////////////////////////////////index 92
    {
        index: 93,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: LandPlot_92_450,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 450,
                                status: "available",
                                block: 25,
                                unitSize: 408.73,
                                north: "15.14",
                                east: "26.26",
                                south: "15.0",
                                west: "28.24",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "25",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_451,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 451,
                                status: "available",
                                block: 25,
                                unitSize: 438.52,
                                north: "15.14",
                                east: "28.24",
                                south: "15.0",
                                west: "30.23",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_452,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 452,
                                status: "available",
                                block: 25,
                                unitSize: 516.4,
                                north: "30.29",
                                east: "15.23",
                                south: "30.0",
                                west: "19.2",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_453,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 453,
                                status: "available",
                                block: 25,
                                unitSize: 450.01,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_454,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 454,
                                status: "available",
                                block: 25,
                                unitSize: 450.01,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_455,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 455,
                                status: "available",
                                block: 25,
                                unitSize: 450.01,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_456,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 456,
                                status: "available",
                                block: 25,
                                unitSize: 450.01,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_457,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 457,
                                status: "available",
                                block: 25,
                                unitSize: 1350.03,
                                north: "30",
                                east: "45.0",
                                south: "30.0",
                                west: "45.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_458,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 458,
                                status: "available",
                                block: 25,
                                unitSize: 1062.43,
                                north: "30",
                                east: "33.58",
                                south: "30.2",
                                west: "37.25",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "25",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_459,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 459,
                                status: "available",
                                block: 25,
                                unitSize: 1172.53,
                                north: "30",
                                east: "37.25",
                                south: "30.2",
                                west: "40.92",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_460,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 460,
                                status: "available",
                                block: 26,
                                unitSize: 655.36,
                                north: "24.47",
                                east: "25.24",
                                south: "27.96",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_461,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 461,
                                status: "available",
                                block: 26,
                                unitSize: 479.01,
                                north: "24.96",
                                east: "13.29",
                                south: "30.52",
                                west: "19.8",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_462,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 462,
                                status: "available",
                                block: 26,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_463,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 463,
                                status: "available",
                                block: 26,
                                unitSize: 322.13,
                                north: "15",
                                east: "19.8",
                                south: "15.37",
                                west: "23.15",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_464,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 464,
                                status: "available",
                                block: 26,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_465,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 465,
                                status: "available",
                                block: 26,
                                unitSize: 372.27,
                                north: "15",
                                east: "23.15",
                                south: "15.37",
                                west: "26.49",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_466,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 466,
                                status: "available",
                                block: 26,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_467,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 467,
                                status: "available",
                                block: 26,
                                unitSize: 422.41,
                                north: "15",
                                east: "26.49",
                                south: "15.37",
                                west: "29.83",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_468,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 468,
                                status: "available",
                                block: 26,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_469,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 469,
                                status: "available",
                                block: 26,
                                unitSize: 397.54,
                                north: "15",
                                east: "24.83",
                                south: "15.37",
                                west: "28.17",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_470,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 470,
                                status: "available",
                                block: 26,
                                unitSize: 443.75,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_471,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 471,
                                status: "available",
                                block: 26,
                                unitSize: 447.68,
                                north: "15",
                                east: "28.17",
                                south: "15.37",
                                west: "31.52",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_472,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 472,
                                status: "available",
                                block: 26,
                                unitSize: 503.75,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_473,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 473,
                                status: "available",
                                block: 26,
                                unitSize: 567.98,
                                north: "17",
                                east: "31.52",
                                south: "17.42",
                                west: "35.3",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_474,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 474,
                                status: "available",
                                block: 26,
                                unitSize: 595.0,
                                north: "17",
                                east: "35.0",
                                south: "17.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_475,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 475,
                                status: "available",
                                block: 26,
                                unitSize: 547.38,
                                north: "17",
                                east: "30.3",
                                south: "17.42",
                                west: "34.09",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_476,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 476,
                                status: "available",
                                block: 26,
                                unitSize: 595.0,
                                north: "17",
                                east: "35.0",
                                south: "17.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_477,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 477,
                                status: "available",
                                block: 26,
                                unitSize: 611.77,
                                north: "17",
                                east: "34.09",
                                south: "17.42",
                                west: "37.88",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_478,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 478,
                                status: "available",
                                block: 26,
                                unitSize: 473.25,
                                north: "31.55",
                                east: "15.0",
                                south: "31.55",
                                west: "15.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_479,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 479,
                                status: "available",
                                block: 26,
                                unitSize: 473.25,
                                north: "31.55",
                                east: "15.0",
                                south: "31.55",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_480,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 480,
                                status: "available",
                                block: 26,
                                unitSize: 473.25,
                                north: "31.55",
                                east: "15.0",
                                south: "31.55",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_481,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 481,
                                status: "available",
                                block: 26,
                                unitSize: 473.25,
                                north: "31.55",
                                east: "15.0",
                                south: "31.55",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_482,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 482,
                                status: "available",
                                block: 26,
                                unitSize: 505.1,
                                north: "31.55",
                                east: "12.88",
                                south: "27.32",
                                west: "14.91",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_483,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 483,
                                status: "available",
                                block: 27,
                                unitSize: 692.22,
                                north: "24.29",
                                east: "30.1",
                                south: "21.86",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_484,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 484,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_485,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 485,
                                status: "available",
                                block: 27,
                                unitSize: 1423.06,
                                north: "36.86",
                                east: "39.96",
                                south: "33.65",
                                west: "40.98",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_486,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 486,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_487,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 487,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_488,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 488,
                                status: "available",
                                block: 27,
                                unitSize: 1232.46,
                                north: "30",
                                east: "40.98",
                                south: "30.02",
                                west: "42.02",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_489,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 489,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_490,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 490,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_491,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 491,
                                status: "available",
                                block: 27,
                                unitSize: 1275.94,
                                north: "30",
                                east: "42.02",
                                south: "30.02",
                                west: "43.05",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_492,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 492,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_493,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 493,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_494,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 494,
                                status: "available",
                                block: 27,
                                unitSize: 1306.92,
                                north: "30",
                                east: "43.05",
                                south: "30.02",
                                west: "44.08",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_495,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 495,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_496,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 496,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_497,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 497,
                                status: "available",
                                block: 27,
                                unitSize: 1337.9,
                                north: "30",
                                east: "44.08",
                                south: "30.02",
                                west: "45.11",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_498,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 498,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_499,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 499,
                                status: "available",
                                block: 27,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_500,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 500,
                                status: "available",
                                block: 27,
                                unitSize: 1368.88,
                                north: "30",
                                east: "45.11",
                                south: "30.02",
                                west: "46.15",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_501,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 501,
                                status: "available",
                                block: 27,
                                unitSize: 770.88,
                                north: "23.02",
                                east: "30.0",
                                south: "26.5",
                                west: "17.95",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_502,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 502,
                                status: "available",
                                block: 27,
                                unitSize: 1196.68,
                                north: "26.5",
                                east: "46.15",
                                south: "24.89",
                                west: "47.03",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_503,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 503,
                                status: "available",
                                block: 28,
                                unitSize: 687.5,
                                north: "27.5",
                                east: "25.0",
                                south: "27.5",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_504,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 504,
                                status: "available",
                                block: 28,
                                unitSize: 687.5,
                                north: "27.5",
                                east: "25.0",
                                south: "27.5",
                                west: "25.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_505,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 505,
                                status: "available",
                                block: 28,
                                unitSize: 1341.67,
                                north: "27.5",
                                east: "40.0",
                                south: "27.5",
                                west: "43.51",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_506,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 506,
                                status: "available",
                                block: 28,
                                unitSize: 687.5,
                                north: "27.5",
                                east: "25.0",
                                south: "27.5",
                                west: "25.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_507,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 507,
                                status: "available",
                                block: 28,
                                unitSize: 847.03,
                                north: "27.5",
                                east: "18.51",
                                south: "27.5",
                                west: "22.01",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_508,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 508,
                                status: "available",
                                block: 28,
                                unitSize: 1512.5,
                                north: "27.5",
                                east: "55.0",
                                south: "27.5",
                                west: "55.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_509,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 509,
                                status: "available",
                                block: 28,
                                unitSize: 1512.5,
                                north: "27.5",
                                east: "55.0",
                                south: "27.5",
                                west: "55.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_510,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 510,
                                status: "available",
                                block: 29,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_511,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 511,
                                status: "available",
                                block: 29,
                                unitSize: 425.0,
                                north: "17",
                                east: "25.0",
                                south: "17.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_512,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 512,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_513,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 513,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_514,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 514,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_515,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 515,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_516,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 516,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_517,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 517,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_518,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 518,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_519,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 519,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_520,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 520,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_521,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 521,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_522,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 522,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_523,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 523,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_524,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 524,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_525,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 525,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_526,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 526,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_527,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 527,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_528,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 528,
                                status: "available",
                                block: 29,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_529,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 529,
                                status: "available",
                                block: 29,
                                unitSize: 375.0,
                                north: "15",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_530,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 530,
                                status: "available",
                                block: 29,
                                unitSize: 588.0,
                                north: "28",
                                east: "21.0",
                                south: "28.0",
                                west: "21.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_531,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 531,
                                status: "available",
                                block: 29,
                                unitSize: 476.0,
                                north: "28",
                                east: "17.0",
                                south: "28.0",
                                west: "17.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_532,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 532,
                                status: "available",
                                block: 29,
                                unitSize: 476.0,
                                north: "28",
                                east: "17.0",
                                south: "28.0",
                                west: "17.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_533,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 533,
                                status: "available",
                                block: 30,
                                unitSize: 657.11,
                                north: "17",
                                east: "38.19",
                                south: "17.03",
                                west: "39.22",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_534,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 534,
                                status: "available",
                                block: 30,
                                unitSize: 592.91,
                                north: "15",
                                east: "39.22",
                                south: "15.02",
                                west: "39.94",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_535,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 535,
                                status: "available",
                                block: 30,
                                unitSize: 605.19,
                                north: "15",
                                east: "39.94",
                                south: "15.02",
                                west: "40.76",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_536,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 536,
                                status: "available",
                                block: 30,
                                unitSize: 617.47,
                                north: "15",
                                east: "40.76",
                                south: "15.02",
                                west: "41.57",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_537,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 537,
                                status: "available",
                                block: 30,
                                unitSize: 629.75,
                                north: "15",
                                east: "41.57",
                                south: "15.02",
                                west: "42.39",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_538,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 538,
                                status: "available",
                                block: 30,
                                unitSize: 440.0,
                                north: "20",
                                east: "22.0",
                                south: "20.0",
                                west: "22.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_539,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 539,
                                status: "available",
                                block: 30,
                                unitSize: 418.78,
                                north: "20",
                                east: "20.39",
                                south: "20.03",
                                west: "22.58",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_540,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 540,
                                status: "available",
                                block: 30,
                                unitSize: 440.0,
                                north: "20",
                                east: "22.0",
                                south: "20.0",
                                west: "22.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_541,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 541,
                                status: "available",
                                block: 30,
                                unitSize: 440.61,
                                north: "20",
                                east: "21.48",
                                south: "20.03",
                                west: "22.58",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_542,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 542,
                                status: "available",
                                block: 30,
                                unitSize: 396.0,
                                north: "18",
                                east: "22.0",
                                south: "18.0",
                                west: "22.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_543,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 543,
                                status: "available",
                                block: 30,
                                unitSize: 415.22,
                                north: "18",
                                east: "22.58",
                                south: "18.03",
                                west: "23.56",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_544,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 544,
                                status: "available",
                                block: 30,
                                unitSize: 396.0,
                                north: "18",
                                east: "22.0",
                                south: "18.0",
                                west: "22.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_545,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 545,
                                status: "available",
                                block: 30,
                                unitSize: 432.9,
                                north: "18",
                                east: "23.56",
                                south: "18.03",
                                west: "24.54",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_546,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 546,
                                status: "available",
                                block: 30,
                                unitSize: 459.0,
                                north: "27",
                                east: "17.0",
                                south: "27.0",
                                west: "17.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_547,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 547,
                                status: "available",
                                block: 30,
                                unitSize: 405.0,
                                north: "27",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_548,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 548,
                                status: "available",
                                block: 30,
                                unitSize: 412.52,
                                north: "27",
                                east: "14.54",
                                south: "27.04",
                                west: "16.02",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_549,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 549,
                                status: "available",
                                block: 31,
                                unitSize: 592.69,
                                north: "16.73",
                                east: "30.6",
                                south: "22.78",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_550,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 550,
                                status: "available",
                                block: 31,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_551,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 551,
                                status: "available",
                                block: 31,
                                unitSize: 1841.62,
                                north: "39.78",
                                east: "38.53",
                                south: "48.04",
                                west: "45.12",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_552,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 552,
                                status: "available",
                                block: 31,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_553,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 553,
                                status: "available",
                                block: 31,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_554,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 554,
                                status: "available",
                                block: 31,
                                unitSize: 1524.82,
                                north: "34",
                                east: "45.12",
                                south: "34.02",
                                west: "44.32",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_555,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 555,
                                status: "available",
                                block: 31,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_556,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 556,
                                status: "available",
                                block: 31,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_557,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 557,
                                status: "available",
                                block: 31,
                                unitSize: 1488.16,
                                north: "34",
                                east: "44.32",
                                south: "34.02",
                                west: "43.22",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_558,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 558,
                                status: "available",
                                block: 31,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_559,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 559,
                                status: "available",
                                block: 31,
                                unitSize: 510.0,
                                north: "17",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_560,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 560,
                                status: "available",
                                block: 31,
                                unitSize: 1450.46,
                                north: "34",
                                east: "43.22",
                                south: "34.02",
                                west: "42.11",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_561,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 561,
                                status: "available",
                                block: 31,
                                unitSize: 480.0,
                                north: "16",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_562,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 562,
                                status: "available",
                                block: 31,
                                unitSize: 480.0,
                                north: "16",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_563,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 563,
                                status: "available",
                                block: 31,
                                unitSize: 1318.2,
                                north: "32",
                                east: "42.11",
                                south: "32.02",
                                west: "41.06",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_564,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 564,
                                status: "available",
                                block: 31,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_565,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 565,
                                status: "available",
                                block: 31,
                                unitSize: 483.41,
                                north: "15.29",
                                east: "30.0",
                                south: "16.93",
                                west: "30.04",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_566,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 566,
                                status: "available",
                                block: 31,
                                unitSize: 1338.24,
                                north: "31.93",
                                east: "41.01",
                                south: "34.13",
                                west: "40.01",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_567,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 567,
                                status: "available",
                                block: 32,
                                unitSize: 564.85,
                                north: "20",
                                east: "28.24",
                                south: "20.0",
                                west: "28.24",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_568,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 568,
                                status: "available",
                                block: 32,
                                unitSize: 564.85,
                                north: "20",
                                east: "28.24",
                                south: "20.0",
                                west: "28.24",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_569,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 569,
                                status: "available",
                                block: 32,
                                unitSize: 552.35,
                                north: "20",
                                east: "28.24",
                                south: "20.0",
                                west: "28.24",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_570,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 570,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_571,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 571,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_572,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 572,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_573,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 573,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_574,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 574,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_575,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 575,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_576,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 576,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_577,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 577,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_578,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 578,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_579,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 579,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_580,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 580,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_581,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 581,
                                status: "available",
                                block: 32,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_582,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 582,
                                status: "available",
                                block: 32,
                                unitSize: 508.31,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "18.89",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_583,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 583,
                                status: "available",
                                block: 32,
                                unitSize: 624.94,
                                north: "30",
                                east: "18.89",
                                south: "30.25",
                                west: "22.78",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_584,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 584,
                                status: "available",
                                block: 33,
                                unitSize: 3659.06,
                                north: "60.5",
                                east: "64.42",
                                south: "60.01",
                                west: "57.96",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_585,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 585,
                                status: "available",
                                block: 34,
                                unitSize: 600.0,
                                north: "20",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_586,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 586,
                                status: "available",
                                block: 34,
                                unitSize: 600.0,
                                north: "20",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_587,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 587,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_588,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 588,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_589,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 589,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_590,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 590,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_591,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 591,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_592,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 592,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_593,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 593,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_594,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 594,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_595,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 595,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_596,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 596,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_597,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 597,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_598,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 598,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_599,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 599,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_600,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 600,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_601,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 601,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_602,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 602,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_603,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 603,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_604,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 604,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_605,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 605,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_606,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 606,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_607,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 607,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_608,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 608,
                                status: "available",
                                block: 34,
                                unitSize: 450.0,
                                north: "15",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_609,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 609,
                                status: "available",
                                block: 34,
                                unitSize: 600.0,
                                north: "20",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_610,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 610,
                                status: "available",
                                block: 34,
                                unitSize: 600.0,
                                north: "20",
                                east: "30.0",
                                south: "20.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_611,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 611,
                                status: "available",
                                block: 35,
                                unitSize: 580.0,
                                north: "20",
                                east: "29.0",
                                south: "20.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_612,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 612,
                                status: "available",
                                block: 35,
                                unitSize: 580.0,
                                north: "20",
                                east: "29.0",
                                south: "20.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_613,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 613,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_614,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 614,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_615,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 615,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_616,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 616,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_617,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 617,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_618,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 618,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_619,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 619,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_620,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 620,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_621,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 621,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_622,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 622,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_623,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 623,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_624,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 624,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_625,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 625,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_626,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 626,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_627,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 627,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_628,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 628,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_629,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 629,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_630,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 630,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_631,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 631,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_632,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 632,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_633,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 633,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_634,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 634,
                                status: "available",
                                block: 35,
                                unitSize: 435.0,
                                north: "15",
                                east: "29.0",
                                south: "15.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_635,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 635,
                                status: "available",
                                block: 35,
                                unitSize: 580.0,
                                north: "20",
                                east: "29.0",
                                south: "20.0",
                                west: "29.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_636,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 636,
                                status: "available",
                                block: 35,
                                unitSize: 580.0,
                                north: "20",
                                east: "29.0",
                                south: "20.0",
                                west: "29.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_637,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 637,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_638,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 638,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_639,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 639,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_640,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 640,
                                status: "available",
                                block: 36,
                                unitSize: 1514.65,
                                north: "45",
                                east: "33.16",
                                south: "45.02",
                                west: "34.16",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_641,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 641,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_642,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 642,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_643,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 643,
                                status: "available",
                                block: 36,
                                unitSize: 1034.78,
                                north: "30",
                                east: "34.16",
                                south: "30.01",
                                west: "34.83",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_644,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 644,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_645,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 645,
                                status: "available",
                                block: 36,
                                unitSize: 353.75,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_646,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 646,
                                status: "available",
                                block: 36,
                                unitSize: 1054.81,
                                north: "30",
                                east: "34.83",
                                south: "30.01",
                                west: "35.49",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_647,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 647,
                                status: "available",
                                block: 36,
                                unitSize: 353.75,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_648,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 648,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_649,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 649,
                                status: "available",
                                block: 36,
                                unitSize: 1074.84,
                                north: "30",
                                east: "35.49",
                                south: "30.01",
                                west: "36.16",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_650,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 650,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_651,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 651,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_652,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 652,
                                status: "available",
                                block: 36,
                                unitSize: 1094.88,
                                north: "30",
                                east: "36.16",
                                south: "30.01",
                                west: "36.83",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_653,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 653,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_654,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 654,
                                status: "available",
                                block: 36,
                                unitSize: 360.0,
                                north: "15",
                                east: "24.0",
                                south: "15.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_655,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 655,
                                status: "available",
                                block: 36,
                                unitSize: 1114.91,
                                north: "30",
                                east: "36.83",
                                south: "30.01",
                                west: "37.5",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_656,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 656,
                                status: "available",
                                block: 36,
                                unitSize: 672.0,
                                north: "28",
                                east: "24.0",
                                south: "28.0",
                                west: "24.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_657,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 657,
                                status: "available",
                                block: 36,
                                unitSize: 1058.66,
                                north: "28",
                                east: "37.5",
                                south: "28.01",
                                west: "38.12",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_658,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 658,
                                status: "available",
                                block: 37,
                                unitSize: 540.0,
                                north: "20",
                                east: "27.0",
                                south: "20.0",
                                west: "27.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_659,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 659,
                                status: "available",
                                block: 37,
                                unitSize: 540.0,
                                north: "20",
                                east: "27.0",
                                south: "20.0",
                                west: "27.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_660,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 660,
                                status: "available",
                                block: 37,
                                unitSize: 675.0,
                                north: "25",
                                east: "27.0",
                                south: "25.0",
                                west: "27.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "25",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_661,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 661,
                                status: "available",
                                block: 37,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_662,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 662,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_663,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 663,
                                status: "available",
                                block: 37,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_664,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 664,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_665,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 665,
                                status: "available",
                                block: 37,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_666,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 666,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_667,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 667,
                                status: "available",
                                block: 37,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_668,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 668,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_669,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 669,
                                status: "available",
                                block: 37,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_670,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 670,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_671,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 671,
                                status: "available",
                                block: 37,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_672,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 672,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_673,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 673,
                                status: "available",
                                block: 37,
                                unitSize: 450.0,
                                north: "30",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_674,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 674,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_675,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 675,
                                status: "available",
                                block: 37,
                                unitSize: 1350.0,
                                north: "30",
                                east: "45.0",
                                south: "30.0",
                                west: "45.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_676,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 676,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_677,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 677,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_678,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 678,
                                status: "available",
                                block: 37,
                                unitSize: 525.0,
                                north: "35",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_679,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 679,
                                status: "available",
                                block: 37,
                                unitSize: 1007.65,
                                north: "30",
                                east: "33.25",
                                south: "30.01",
                                west: "33.92",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_680,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 680,
                                status: "available",
                                block: 37,
                                unitSize: 1188.41,
                                north: "35",
                                east: "33.92",
                                south: "35.01",
                                west: "34.7",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "25",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_55,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 55,
                                status: "available",
                                block: 6,
                                unitSize: 563.92,
                                north: "34.43",
                                east: "14.72",
                                south: "32.51",
                                west: "19.11",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_56,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 56,
                                status: "available",
                                block: 6,
                                unitSize: 301.44,
                                north: "15.13",
                                east: "19.11",
                                south: "15.0",
                                west: "15.13",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_57,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 57,
                                status: "available",
                                block: 6,
                                unitSize: 331.0,
                                north: "15,13",
                                east: "21.08",
                                south: "15.0",
                                west: "23.05",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_58,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 58,
                                status: "available",
                                block: 6,
                                unitSize: 355.38,
                                north: "14,74",
                                east: "23.05",
                                south: "14.97",
                                west: "24.97",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "25",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_59,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 59,
                                status: "available",
                                block: 6,
                                unitSize: 1010.53,
                                north: "27.65",
                                east: "39.68",
                                south: "23.27",
                                west: "40.1",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_60,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 60,
                                status: "available",
                                block: 6,
                                unitSize: 1017.47,
                                north: "25",
                                east: "40.1",
                                south: "25.03",
                                west: "41.4",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_92_61,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 61,
                                status: "available",
                                block: 6,
                                unitSize: 1063.3,
                                north: "24.83",
                                east: "41.4",
                                south: "25.52",
                                west: "43.34",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "25",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_91_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_91_1,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
];

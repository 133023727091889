import SvgAreas from "../../../../components/main/wrappers/svg-areas/SvgAreas";
import SvgCustoms from "../../../../components/main/wrappers/svg-customs/SvgCustoms";

import unitSizeJSON from "./unit_sizes.json";

import { ReactComponent as Block_1_5 } from "./blocks/1/land_plot_block_5.svg";
import { ReactComponent as Block_1_25 } from "./blocks/1/land_plot_block_25.svg";
import { ReactComponent as Block_1_26 } from "./blocks/1/land_plot_block_26.svg";
import { ReactComponent as Block_1_27 } from "./blocks/1/land_plot_block_27.svg";
import { ReactComponent as Block_1_28 } from "./blocks/1/land_plot_block_28.svg";
import { ReactComponent as Block_1_29 } from "./blocks/1/land_plot_block_29.svg";
import { ReactComponent as Block_1_30 } from "./blocks/1/land_plot_block_30.svg";
import { ReactComponent as Block_1_31 } from "./blocks/1/land_plot_block_31.svg";
import { ReactComponent as Block_1_32 } from "./blocks/1/land_plot_block_32.svg";
import { ReactComponent as Block_1_33 } from "./blocks/1/land_plot_block_33.svg";
import { ReactComponent as Block_1_34 } from "./blocks/1/land_plot_block_34.svg";
import { ReactComponent as Block_1_35 } from "./blocks/1/land_plot_block_35.svg";
import { ReactComponent as Block_1_36 } from "./blocks/1/land_plot_block_36.svg";
import { ReactComponent as Block_1_37 } from "./blocks/1/land_plot_block_37.svg";

import { ReactComponent as Block_30_5 } from "./blocks/30/land_plot_block_5.svg";
import { ReactComponent as Block_30_25 } from "./blocks/30/land_plot_block_25.svg";
import { ReactComponent as Block_30_26 } from "./blocks/30/land_plot_block_26.svg";
import { ReactComponent as Block_30_27 } from "./blocks/30/land_plot_block_27.svg";
import { ReactComponent as Block_30_28 } from "./blocks/30/land_plot_block_28.svg";
import { ReactComponent as Block_30_29 } from "./blocks/30/land_plot_block_29.svg";
import { ReactComponent as Block_30_30 } from "./blocks/30/land_plot_block_30.svg";
import { ReactComponent as Block_30_31 } from "./blocks/30/land_plot_block_31.svg";
import { ReactComponent as Block_30_32 } from "./blocks/30/land_plot_block_32.svg";
import { ReactComponent as Block_30_33 } from "./blocks/30/land_plot_block_33.svg";
import { ReactComponent as Block_30_34 } from "./blocks/30/land_plot_block_34.svg";
import { ReactComponent as Block_30_35 } from "./blocks/30/land_plot_block_35.svg";
import { ReactComponent as Block_30_36 } from "./blocks/30/land_plot_block_36.svg";
import { ReactComponent as Block_30_37 } from "./blocks/30/land_plot_block_37.svg";

import { ReactComponent as Block_60_5 } from "./blocks/60/land_plot_block_5.svg";
import { ReactComponent as Block_60_25 } from "./blocks/60/land_plot_block_25.svg";
import { ReactComponent as Block_60_26 } from "./blocks/60/land_plot_block_26.svg";
import { ReactComponent as Block_60_27 } from "./blocks/60/land_plot_block_27.svg";
import { ReactComponent as Block_60_28 } from "./blocks/60/land_plot_block_28.svg";
import { ReactComponent as Block_60_29 } from "./blocks/60/land_plot_block_29.svg";
import { ReactComponent as Block_60_30 } from "./blocks/60/land_plot_block_30.svg";
import { ReactComponent as Block_60_31 } from "./blocks/60/land_plot_block_31.svg";
import { ReactComponent as Block_60_32 } from "./blocks/60/land_plot_block_32.svg";
import { ReactComponent as Block_60_33 } from "./blocks/60/land_plot_block_33.svg";
import { ReactComponent as Block_60_34 } from "./blocks/60/land_plot_block_34.svg";
import { ReactComponent as Block_60_35 } from "./blocks/60/land_plot_block_35.svg";
import { ReactComponent as Block_60_36 } from "./blocks/60/land_plot_block_36.svg";
import { ReactComponent as Block_60_37 } from "./blocks/60/land_plot_block_37.svg";

import { ReactComponent as Block_92_5 } from "./blocks/92/land_plot_block_5.svg";
import { ReactComponent as Block_92_25 } from "./blocks/92/land_plot_block_25.svg";
import { ReactComponent as Block_92_26 } from "./blocks/92/land_plot_block_26.svg";
import { ReactComponent as Block_92_27 } from "./blocks/92/land_plot_block_27.svg";
import { ReactComponent as Block_92_28 } from "./blocks/92/land_plot_block_28.svg";
import { ReactComponent as Block_92_29 } from "./blocks/92/land_plot_block_29.svg";
import { ReactComponent as Block_92_30 } from "./blocks/92/land_plot_block_30.svg";
import { ReactComponent as Block_92_31 } from "./blocks/92/land_plot_block_31.svg";
import { ReactComponent as Block_92_32 } from "./blocks/92/land_plot_block_32.svg";
import { ReactComponent as Block_92_33 } from "./blocks/92/land_plot_block_33.svg";
import { ReactComponent as Block_92_34 } from "./blocks/92/land_plot_block_34.svg";
import { ReactComponent as Block_92_35 } from "./blocks/92/land_plot_block_35.svg";
import { ReactComponent as Block_92_36 } from "./blocks/92/land_plot_block_36.svg";
import { ReactComponent as Block_92_37 } from "./blocks/92/land_plot_block_37.svg";

import { ReactComponent as Church_2_1 } from "../../../../static/images/contents/zone-4/icons/2/church_1.svg";
import { ReactComponent as Church_2_2 } from "../../../../static/images/contents/zone-4/icons/2/church_2.svg";
import { ReactComponent as Church_2_3 } from "../../../../static/images/contents/zone-4/icons/2/church_3.svg";
import { ReactComponent as Church_2_4 } from "../../../../static/images/contents/zone-4/icons/2/church_4.svg";
import { ReactComponent as Church_2_5 } from "../../../../static/images/contents/zone-4/icons/2/church_5.svg";
import { ReactComponent as Church_2_6 } from "../../../../static/images/contents/zone-4/icons/2/church_6.svg";
import { ReactComponent as Church_2_7 } from "../../../../static/images/contents/zone-4/icons/2/church_7.svg";
import { ReactComponent as School_2_1 } from "../../../../static/images/contents/zone-4/icons/2/school_1.svg";
import { ReactComponent as School_2_2 } from "../../../../static/images/contents/zone-4/icons/2/school_2.svg";
import { ReactComponent as School_2_3 } from "../../../../static/images/contents/zone-4/icons/2/school_3.svg";
import { ReactComponent as School_2_4 } from "../../../../static/images/contents/zone-4/icons/2/school_4.svg";
import { ReactComponent as Shopping_2_1 } from "../../../../static/images/contents/zone-4/icons/2/shopping_1.svg";
import { ReactComponent as Hospital_2_1 } from "../../../../static/images/contents/zone-4/icons/2/hospital_1.svg";

import { ReactComponent as Church_33_1 } from "../../../../static/images/contents/zone-4/icons/33/church_1.svg";
import { ReactComponent as Church_33_2 } from "../../../../static/images/contents/zone-4/icons/33/church_2.svg";
import { ReactComponent as Church_33_3 } from "../../../../static/images/contents/zone-4/icons/33/church_3.svg";
import { ReactComponent as Church_33_4 } from "../../../../static/images/contents/zone-4/icons/33/church_4.svg";
import { ReactComponent as School_33_1 } from "../../../../static/images/contents/zone-4/icons/33/school_1.svg";
import { ReactComponent as School_33_2 } from "../../../../static/images/contents/zone-4/icons/33/school_2.svg";
import { ReactComponent as School_33_3 } from "../../../../static/images/contents/zone-4/icons/33/school_3.svg";
import { ReactComponent as School_33_4 } from "../../../../static/images/contents/zone-4/icons/33/school_4.svg";
import { ReactComponent as Shopping_33_1 } from "../../../../static/images/contents/zone-4/icons/33/shopping_1.svg";
import { ReactComponent as Hospital_33_1 } from "../../../../static/images/contents/zone-4/icons/33/hospital_1.svg";                  

import { ReactComponent as Church_61_1 } from "../../../../static/images/contents/zone-4/icons/61/church_1.svg";
import { ReactComponent as Church_61_2 } from "../../../../static/images/contents/zone-4/icons/61/church_2.svg";
import { ReactComponent as Church_61_3 } from "../../../../static/images/contents/zone-4/icons/61/church_3.svg";
import { ReactComponent as Church_61_4 } from "../../../../static/images/contents/zone-4/icons/61/church_4.svg";
import { ReactComponent as Church_61_5 } from "../../../../static/images/contents/zone-4/icons/61/church_5.svg";
import { ReactComponent as Church_61_6 } from "../../../../static/images/contents/zone-4/icons/61/church_6.svg";
import { ReactComponent as School_61_1 } from "../../../../static/images/contents/zone-4/icons/61/school_1.svg";
import { ReactComponent as School_61_2 } from "../../../../static/images/contents/zone-4/icons/61/school_2.svg";
import { ReactComponent as School_61_3 } from "../../../../static/images/contents/zone-4/icons/61/school_3.svg";
import { ReactComponent as School_61_4 } from "../../../../static/images/contents/zone-4/icons/61/school_4.svg";
import { ReactComponent as Shopping_61_1 } from "../../../../static/images/contents/zone-4/icons/61/shopping_1.svg";
import { ReactComponent as Hospital_61_1 } from "../../../../static/images/contents/zone-4/icons/61/hospital_1.svg";

import { ReactComponent as Church_91_1 } from "../../../../static/images/contents/zone-4/icons/91/church_1.svg";
import { ReactComponent as Church_91_2 } from "../../../../static/images/contents/zone-4/icons/91/church_2.svg";
import { ReactComponent as Church_91_3 } from "../../../../static/images/contents/zone-4/icons/91/church_3.svg";
import { ReactComponent as School_91_1 } from "../../../../static/images/contents/zone-4/icons/91/school_1.svg";
import { ReactComponent as School_91_2 } from "../../../../static/images/contents/zone-4/icons/91/school_2.svg";
import { ReactComponent as Shopping_91_1 } from "../../../../static/images/contents/zone-4/icons/91/shopping_1.svg";

export const zone4_blocks = [
    {
        index: 2,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    unitSizeJSON={unitSizeJSON}
                    areas={[
                        {
                            Polygon: Block_1_5,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 5,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_25,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 25,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_26,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 26,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_27,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 27,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_28,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 28,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_29,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 29,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_30,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 30,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_31,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 31,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_32,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 32,
                                status: "sold",
                            },
                        },
                        {
                            Polygon: Block_1_33,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 33,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_34,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 34,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_35,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 35,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_36,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 36,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_1_37,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 37,
                                status: "available",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_7,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
    {
        index: 31,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    unitSizeJSON={unitSizeJSON}
                    areas={[
                        {
                            Polygon: Block_30_5,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 5,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_30_25,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 25,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_30_26,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 26,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_30_27,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 27,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_30_28,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 28,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_30_29,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 29,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_30_30,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 30,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_30_31,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 31,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_30_32,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 32,
                                status: "sold",
                            },
                        },
                        {
                            Polygon: Block_30_33,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 33,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_30_34,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 34,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_30_35,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 35,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_30_36,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 36,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_30_37,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 37,
                                status: "available",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_33_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_33_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_33_1,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
    {
        index: 61,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    unitSizeJSON={unitSizeJSON}
                    areas={[
                        {
                            Polygon: Block_60_5,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 5,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_60_25,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 25,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_60_26,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 26,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_60_27,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 27,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_60_28,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 28,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_60_29,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 29,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_60_30,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 30,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_60_31,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 31,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_60_32,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 32,
                                status: "sold",
                            },
                        },
                        {
                            Polygon: Block_60_33,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 33,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_60_34,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 34,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_60_35,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 35,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_60_36,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 36,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_60_37,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 37,
                                status: "available",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_61_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_61_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_61_1,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
    {
        index: 93,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    unitSizeJSON={unitSizeJSON}
                    areas={[
                        {
                            Polygon: Block_92_5,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 5,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_92_25,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 25,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_92_26,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 26,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_92_27,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 27,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_92_28,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 28,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_92_29,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 29,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_92_30,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 30,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_92_31,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 31,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_92_32,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 32,
                                status: "sold",
                            },
                        },
                        {
                            Polygon: Block_92_33,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 33,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_92_34,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 34,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_92_35,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 35,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_92_36,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 36,
                                status: "available",
                            },
                        },
                        {
                            Polygon: Block_92_37,
                            className: null,
                            infobox: {
                                type: "block_info",
                                index: 37,
                                status: "available",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_91_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_91_1,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
];

import SvgAreas from "../../../../components/main/wrappers/svg-areas/SvgAreas";
import SvgCustoms from "../../../../components/main/wrappers/svg-customs/SvgCustoms";

///////////////////////////////////////////////////////////////////////////////////stop frame 1
import { ReactComponent as LandPlot_1_1751 } from "./land_plots/1/land_plot_1751.svg";
import { ReactComponent as LandPlot_1_1752 } from "./land_plots/1/land_plot_1752.svg";
import { ReactComponent as LandPlot_1_1753 } from "./land_plots/1/land_plot_1753.svg";
import { ReactComponent as LandPlot_1_1754 } from "./land_plots/1/land_plot_1754.svg";
import { ReactComponent as LandPlot_1_1755 } from "./land_plots/1/land_plot_1755.svg";
import { ReactComponent as LandPlot_1_1756 } from "./land_plots/1/land_plot_1756.svg";
import { ReactComponent as LandPlot_1_1757 } from "./land_plots/1/land_plot_1757.svg";
import { ReactComponent as LandPlot_1_1758 } from "./land_plots/1/land_plot_1758.svg";
import { ReactComponent as LandPlot_1_1759 } from "./land_plots/1/land_plot_1759.svg";
import { ReactComponent as LandPlot_1_1760 } from "./land_plots/1/land_plot_1760.svg";
import { ReactComponent as LandPlot_1_1761 } from "./land_plots/1/land_plot_1761.svg";
import { ReactComponent as LandPlot_1_1762 } from "./land_plots/1/land_plot_1762.svg";
import { ReactComponent as LandPlot_1_1763 } from "./land_plots/1/land_plot_1763.svg";
import { ReactComponent as LandPlot_1_1764 } from "./land_plots/1/land_plot_1764.svg";
import { ReactComponent as LandPlot_1_1765 } from "./land_plots/1/land_plot_1765.svg";
import { ReactComponent as LandPlot_1_1766 } from "./land_plots/1/land_plot_1766.svg";
import { ReactComponent as LandPlot_1_1767 } from "./land_plots/1/land_plot_1767.svg";
import { ReactComponent as LandPlot_1_1768 } from "./land_plots/1/land_plot_1768.svg";
import { ReactComponent as LandPlot_1_1769 } from "./land_plots/1/land_plot_1769.svg";
import { ReactComponent as LandPlot_1_1770 } from "./land_plots/1/land_plot_1770.svg";
import { ReactComponent as LandPlot_1_1771 } from "./land_plots/1/land_plot_1771.svg";
import { ReactComponent as LandPlot_1_1772 } from "./land_plots/1/land_plot_1772.svg";
import { ReactComponent as LandPlot_1_1773 } from "./land_plots/1/land_plot_1773.svg";
import { ReactComponent as LandPlot_1_1774 } from "./land_plots/1/land_plot_1774.svg";
import { ReactComponent as LandPlot_1_1775 } from "./land_plots/1/land_plot_1775.svg";
import { ReactComponent as LandPlot_1_1776 } from "./land_plots/1/land_plot_1776.svg";
import { ReactComponent as LandPlot_1_1777 } from "./land_plots/1/land_plot_1777.svg";
import { ReactComponent as LandPlot_1_1778 } from "./land_plots/1/land_plot_1778.svg";
import { ReactComponent as LandPlot_1_1779 } from "./land_plots/1/land_plot_1779.svg";
import { ReactComponent as LandPlot_1_1780 } from "./land_plots/1/land_plot_1780.svg";
import { ReactComponent as LandPlot_1_1781 } from "./land_plots/1/land_plot_1781.svg";
import { ReactComponent as LandPlot_1_1782 } from "./land_plots/1/land_plot_1782.svg";
import { ReactComponent as LandPlot_1_1783 } from "./land_plots/1/land_plot_1783.svg";
import { ReactComponent as LandPlot_1_1784 } from "./land_plots/1/land_plot_1784.svg";
import { ReactComponent as LandPlot_1_1785 } from "./land_plots/1/land_plot_1785.svg";
import { ReactComponent as LandPlot_1_1786 } from "./land_plots/1/land_plot_1786.svg";
import { ReactComponent as LandPlot_1_1787 } from "./land_plots/1/land_plot_1787.svg";
import { ReactComponent as LandPlot_1_1788 } from "./land_plots/1/land_plot_1788.svg";
import { ReactComponent as LandPlot_1_1789 } from "./land_plots/1/land_plot_1789.svg";
import { ReactComponent as LandPlot_1_1790 } from "./land_plots/1/land_plot_1790.svg";
import { ReactComponent as LandPlot_1_1791 } from "./land_plots/1/land_plot_1791.svg";
import { ReactComponent as LandPlot_1_1792 } from "./land_plots/1/land_plot_1792.svg";
import { ReactComponent as LandPlot_1_1793 } from "./land_plots/1/land_plot_1793.svg";
import { ReactComponent as LandPlot_1_1794 } from "./land_plots/1/land_plot_1794.svg";
import { ReactComponent as LandPlot_1_1795 } from "./land_plots/1/land_plot_1795.svg";
import { ReactComponent as LandPlot_1_1796 } from "./land_plots/1/land_plot_1796.svg";
import { ReactComponent as LandPlot_1_1797 } from "./land_plots/1/land_plot_1797.svg";
import { ReactComponent as LandPlot_1_1798 } from "./land_plots/1/land_plot_1798.svg";
import { ReactComponent as LandPlot_1_1799 } from "./land_plots/1/land_plot_1799.svg";
import { ReactComponent as LandPlot_1_1800 } from "./land_plots/1/land_plot_1800.svg";
import { ReactComponent as LandPlot_1_1801 } from "./land_plots/1/land_plot_1801.svg";
import { ReactComponent as LandPlot_1_1802 } from "./land_plots/1/land_plot_1802.svg";
import { ReactComponent as LandPlot_1_1803 } from "./land_plots/1/land_plot_1803.svg";
import { ReactComponent as LandPlot_1_1804 } from "./land_plots/1/land_plot_1804.svg";
import { ReactComponent as LandPlot_1_1805 } from "./land_plots/1/land_plot_1805.svg";
import { ReactComponent as LandPlot_1_1806 } from "./land_plots/1/land_plot_1806.svg";
import { ReactComponent as LandPlot_1_1807 } from "./land_plots/1/land_plot_1807.svg";
import { ReactComponent as LandPlot_1_1808 } from "./land_plots/1/land_plot_1808.svg";
import { ReactComponent as LandPlot_1_1809 } from "./land_plots/1/land_plot_1809.svg";
import { ReactComponent as LandPlot_1_1810 } from "./land_plots/1/land_plot_1810.svg";
import { ReactComponent as LandPlot_1_1811 } from "./land_plots/1/land_plot_1811.svg";
import { ReactComponent as LandPlot_1_1812 } from "./land_plots/1/land_plot_1812.svg";
import { ReactComponent as LandPlot_1_1813 } from "./land_plots/1/land_plot_1813.svg";
import { ReactComponent as LandPlot_1_1814 } from "./land_plots/1/land_plot_1814.svg";
import { ReactComponent as LandPlot_1_1815 } from "./land_plots/1/land_plot_1815.svg";
import { ReactComponent as LandPlot_1_1816 } from "./land_plots/1/land_plot_1816.svg";
import { ReactComponent as LandPlot_1_1817 } from "./land_plots/1/land_plot_1817.svg";
import { ReactComponent as LandPlot_1_1818 } from "./land_plots/1/land_plot_1818.svg";
import { ReactComponent as LandPlot_1_1819 } from "./land_plots/1/land_plot_1819.svg";
import { ReactComponent as LandPlot_1_1820 } from "./land_plots/1/land_plot_1820.svg";
import { ReactComponent as LandPlot_1_1821 } from "./land_plots/1/land_plot_1821.svg";
import { ReactComponent as LandPlot_1_1822 } from "./land_plots/1/land_plot_1822.svg";
import { ReactComponent as LandPlot_1_1823 } from "./land_plots/1/land_plot_1823.svg";
import { ReactComponent as LandPlot_1_1824 } from "./land_plots/1/land_plot_1824.svg";
import { ReactComponent as LandPlot_1_1825 } from "./land_plots/1/land_plot_1825.svg";
import { ReactComponent as LandPlot_1_1826 } from "./land_plots/1/land_plot_1826.svg";
import { ReactComponent as LandPlot_1_1827 } from "./land_plots/1/land_plot_1827.svg";
import { ReactComponent as LandPlot_1_1828 } from "./land_plots/1/land_plot_1828.svg";
import { ReactComponent as LandPlot_1_1829 } from "./land_plots/1/land_plot_1829.svg";
import { ReactComponent as LandPlot_1_1830 } from "./land_plots/1/land_plot_1830.svg";
import { ReactComponent as LandPlot_1_1831 } from "./land_plots/1/land_plot_1831.svg";
import { ReactComponent as LandPlot_1_1832 } from "./land_plots/1/land_plot_1832.svg";
import { ReactComponent as LandPlot_1_1833 } from "./land_plots/1/land_plot_1833.svg";
import { ReactComponent as LandPlot_1_1834 } from "./land_plots/1/land_plot_1834.svg";
import { ReactComponent as LandPlot_1_1835 } from "./land_plots/1/land_plot_1835.svg";
import { ReactComponent as LandPlot_1_1836 } from "./land_plots/1/land_plot_1836.svg";
import { ReactComponent as LandPlot_1_1837 } from "./land_plots/1/land_plot_1837.svg";
import { ReactComponent as LandPlot_1_1838 } from "./land_plots/1/land_plot_1838.svg";
import { ReactComponent as LandPlot_1_1839 } from "./land_plots/1/land_plot_1839.svg";
import { ReactComponent as LandPlot_1_1840 } from "./land_plots/1/land_plot_1840.svg";
import { ReactComponent as LandPlot_1_1841 } from "./land_plots/1/land_plot_1841.svg";
import { ReactComponent as LandPlot_1_1842 } from "./land_plots/1/land_plot_1842.svg";
import { ReactComponent as LandPlot_1_1843 } from "./land_plots/1/land_plot_1843.svg";
import { ReactComponent as LandPlot_1_1844 } from "./land_plots/1/land_plot_1844.svg";
import { ReactComponent as LandPlot_1_1845 } from "./land_plots/1/land_plot_1845.svg";
import { ReactComponent as LandPlot_1_1846 } from "./land_plots/1/land_plot_1846.svg";
import { ReactComponent as LandPlot_1_1847 } from "./land_plots/1/land_plot_1847.svg";
import { ReactComponent as LandPlot_1_1848 } from "./land_plots/1/land_plot_1848.svg";
import { ReactComponent as LandPlot_1_1849 } from "./land_plots/1/land_plot_1849.svg";
import { ReactComponent as LandPlot_1_1850 } from "./land_plots/1/land_plot_1850.svg";
import { ReactComponent as LandPlot_1_1851 } from "./land_plots/1/land_plot_1851.svg";
import { ReactComponent as LandPlot_1_1852 } from "./land_plots/1/land_plot_1852.svg";
import { ReactComponent as LandPlot_1_1853 } from "./land_plots/1/land_plot_1853.svg";
import { ReactComponent as LandPlot_1_1854 } from "./land_plots/1/land_plot_1854.svg";
import { ReactComponent as LandPlot_1_1855 } from "./land_plots/1/land_plot_1855.svg";
import { ReactComponent as LandPlot_1_1856 } from "./land_plots/1/land_plot_1856.svg";
import { ReactComponent as LandPlot_1_1857 } from "./land_plots/1/land_plot_1857.svg";
import { ReactComponent as LandPlot_1_1858 } from "./land_plots/1/land_plot_1858.svg";
import { ReactComponent as LandPlot_1_1859 } from "./land_plots/1/land_plot_1859.svg";
import { ReactComponent as LandPlot_1_1860 } from "./land_plots/1/land_plot_1860.svg";
import { ReactComponent as LandPlot_1_1861 } from "./land_plots/1/land_plot_1861.svg";
import { ReactComponent as LandPlot_1_1862 } from "./land_plots/1/land_plot_1862.svg";
import { ReactComponent as LandPlot_1_1863 } from "./land_plots/1/land_plot_1863.svg";
import { ReactComponent as LandPlot_1_1864 } from "./land_plots/1/land_plot_1864.svg";
import { ReactComponent as LandPlot_1_1865 } from "./land_plots/1/land_plot_1865.svg";
import { ReactComponent as LandPlot_1_1866 } from "./land_plots/1/land_plot_1866.svg";
import { ReactComponent as LandPlot_1_1867 } from "./land_plots/1/land_plot_1867.svg";
import { ReactComponent as LandPlot_1_1868 } from "./land_plots/1/land_plot_1868.svg";
import { ReactComponent as LandPlot_1_1869 } from "./land_plots/1/land_plot_1869.svg";
import { ReactComponent as LandPlot_1_1870 } from "./land_plots/1/land_plot_1870.svg";
import { ReactComponent as LandPlot_1_1871 } from "./land_plots/1/land_plot_1871.svg";
import { ReactComponent as LandPlot_1_1872 } from "./land_plots/1/land_plot_1872.svg";
import { ReactComponent as LandPlot_1_1873 } from "./land_plots/1/land_plot_1873.svg";
import { ReactComponent as LandPlot_1_1874 } from "./land_plots/1/land_plot_1874.svg";
import { ReactComponent as LandPlot_1_1875 } from "./land_plots/1/land_plot_1875.svg";
import { ReactComponent as LandPlot_1_1876 } from "./land_plots/1/land_plot_1876.svg";
import { ReactComponent as LandPlot_1_1877 } from "./land_plots/1/land_plot_1877.svg";
import { ReactComponent as LandPlot_1_1878 } from "./land_plots/1/land_plot_1878.svg";
import { ReactComponent as LandPlot_1_1879 } from "./land_plots/1/land_plot_1879.svg";
import { ReactComponent as LandPlot_1_1880 } from "./land_plots/1/land_plot_1880.svg";
import { ReactComponent as LandPlot_1_1881 } from "./land_plots/1/land_plot_1881.svg";
import { ReactComponent as LandPlot_1_1882 } from "./land_plots/1/land_plot_1882.svg";
import { ReactComponent as LandPlot_1_1883 } from "./land_plots/1/land_plot_1883.svg";
import { ReactComponent as LandPlot_1_1884 } from "./land_plots/1/land_plot_1884.svg";
import { ReactComponent as LandPlot_1_1885 } from "./land_plots/1/land_plot_1885.svg";
import { ReactComponent as LandPlot_1_1886 } from "./land_plots/1/land_plot_1886.svg";
import { ReactComponent as LandPlot_1_1887 } from "./land_plots/1/land_plot_1887.svg";
import { ReactComponent as LandPlot_1_1888 } from "./land_plots/1/land_plot_1888.svg";
import { ReactComponent as LandPlot_1_1889 } from "./land_plots/1/land_plot_1889.svg";
import { ReactComponent as LandPlot_1_1890 } from "./land_plots/1/land_plot_1890.svg";
import { ReactComponent as LandPlot_1_1891 } from "./land_plots/1/land_plot_1891.svg";
import { ReactComponent as LandPlot_1_1892 } from "./land_plots/1/land_plot_1892.svg";
import { ReactComponent as LandPlot_1_1893 } from "./land_plots/1/land_plot_1893.svg";
import { ReactComponent as LandPlot_1_1894 } from "./land_plots/1/land_plot_1894.svg";
import { ReactComponent as LandPlot_1_1895 } from "./land_plots/1/land_plot_1895.svg";
import { ReactComponent as LandPlot_1_1896 } from "./land_plots/1/land_plot_1896.svg";
import { ReactComponent as LandPlot_1_1897 } from "./land_plots/1/land_plot_1897.svg";
import { ReactComponent as LandPlot_1_1898 } from "./land_plots/1/land_plot_1898.svg";
import { ReactComponent as LandPlot_1_1899 } from "./land_plots/1/land_plot_1899.svg";
import { ReactComponent as LandPlot_1_1900 } from "./land_plots/1/land_plot_1900.svg";
import { ReactComponent as LandPlot_1_1901 } from "./land_plots/1/land_plot_1901.svg";
import { ReactComponent as LandPlot_1_1902 } from "./land_plots/1/land_plot_1902.svg";
import { ReactComponent as LandPlot_1_1903 } from "./land_plots/1/land_plot_1903.svg";
import { ReactComponent as LandPlot_1_1904 } from "./land_plots/1/land_plot_1904.svg";
import { ReactComponent as LandPlot_1_1905 } from "./land_plots/1/land_plot_1905.svg";
import { ReactComponent as LandPlot_1_1906 } from "./land_plots/1/land_plot_1906.svg";
import { ReactComponent as LandPlot_1_1907 } from "./land_plots/1/land_plot_1907.svg";
import { ReactComponent as LandPlot_1_1908 } from "./land_plots/1/land_plot_1908.svg";
import { ReactComponent as LandPlot_1_1909 } from "./land_plots/1/land_plot_1909.svg";
import { ReactComponent as LandPlot_1_1910 } from "./land_plots/1/land_plot_1910.svg";
import { ReactComponent as LandPlot_1_1911 } from "./land_plots/1/land_plot_1911.svg";
import { ReactComponent as LandPlot_1_1912 } from "./land_plots/1/land_plot_1912.svg";
import { ReactComponent as LandPlot_1_1913 } from "./land_plots/1/land_plot_1913.svg";
import { ReactComponent as LandPlot_1_1914 } from "./land_plots/1/land_plot_1914.svg";
import { ReactComponent as LandPlot_1_1915 } from "./land_plots/1/land_plot_1915.svg";
import { ReactComponent as LandPlot_1_1916 } from "./land_plots/1/land_plot_1916.svg";
import { ReactComponent as LandPlot_1_1917 } from "./land_plots/1/land_plot_1917.svg";
import { ReactComponent as LandPlot_1_1918 } from "./land_plots/1/land_plot_1918.svg";
import { ReactComponent as LandPlot_1_1919 } from "./land_plots/1/land_plot_1919.svg";
import { ReactComponent as LandPlot_1_1920 } from "./land_plots/1/land_plot_1920.svg";
import { ReactComponent as LandPlot_1_1921 } from "./land_plots/1/land_plot_1921.svg";
import { ReactComponent as LandPlot_1_1922 } from "./land_plots/1/land_plot_1922.svg";
import { ReactComponent as LandPlot_1_1923 } from "./land_plots/1/land_plot_1923.svg";
import { ReactComponent as LandPlot_1_1924 } from "./land_plots/1/land_plot_1924.svg";
import { ReactComponent as LandPlot_1_1925 } from "./land_plots/1/land_plot_1925.svg";
import { ReactComponent as LandPlot_1_1926 } from "./land_plots/1/land_plot_1926.svg";
import { ReactComponent as LandPlot_1_1927 } from "./land_plots/1/land_plot_1927.svg";
import { ReactComponent as LandPlot_1_1928 } from "./land_plots/1/land_plot_1928.svg";
import { ReactComponent as LandPlot_1_1929 } from "./land_plots/1/land_plot_1929.svg";
import { ReactComponent as LandPlot_1_1930 } from "./land_plots/1/land_plot_1930.svg";
import { ReactComponent as LandPlot_1_1931 } from "./land_plots/1/land_plot_1931.svg";
import { ReactComponent as LandPlot_1_1932 } from "./land_plots/1/land_plot_1932.svg";
import { ReactComponent as LandPlot_1_1933 } from "./land_plots/1/land_plot_1933.svg";
import { ReactComponent as LandPlot_1_1934 } from "./land_plots/1/land_plot_1934.svg";
import { ReactComponent as LandPlot_1_1935 } from "./land_plots/1/land_plot_1935.svg";
import { ReactComponent as LandPlot_1_1936 } from "./land_plots/1/land_plot_1936.svg";
import { ReactComponent as LandPlot_1_1937 } from "./land_plots/1/land_plot_1937.svg";
import { ReactComponent as LandPlot_1_1938 } from "./land_plots/1/land_plot_1938.svg";
import { ReactComponent as LandPlot_1_1939 } from "./land_plots/1/land_plot_1939.svg";
import { ReactComponent as LandPlot_1_1940 } from "./land_plots/1/land_plot_1940.svg";
import { ReactComponent as LandPlot_1_1941 } from "./land_plots/1/land_plot_1941.svg";
import { ReactComponent as LandPlot_1_1942 } from "./land_plots/1/land_plot_1942.svg";
import { ReactComponent as LandPlot_1_1943 } from "./land_plots/1/land_plot_1943.svg";
import { ReactComponent as LandPlot_1_1944 } from "./land_plots/1/land_plot_1944.svg";
import { ReactComponent as LandPlot_1_1945 } from "./land_plots/1/land_plot_1945.svg";
import { ReactComponent as LandPlot_1_1946 } from "./land_plots/1/land_plot_1946.svg";
import { ReactComponent as LandPlot_1_1947 } from "./land_plots/1/land_plot_1947.svg";
import { ReactComponent as LandPlot_1_1948 } from "./land_plots/1/land_plot_1948.svg";
import { ReactComponent as LandPlot_1_1949 } from "./land_plots/1/land_plot_1949.svg";
import { ReactComponent as LandPlot_1_1950 } from "./land_plots/1/land_plot_1950.svg";
import { ReactComponent as LandPlot_1_1951 } from "./land_plots/1/land_plot_1951.svg";
import { ReactComponent as LandPlot_1_1952 } from "./land_plots/1/land_plot_1952.svg";
import { ReactComponent as LandPlot_1_1953 } from "./land_plots/1/land_plot_1953.svg";
import { ReactComponent as LandPlot_1_1954 } from "./land_plots/1/land_plot_1954.svg";
import { ReactComponent as LandPlot_1_1955 } from "./land_plots/1/land_plot_1955.svg";
import { ReactComponent as LandPlot_1_1956 } from "./land_plots/1/land_plot_1956.svg";
import { ReactComponent as LandPlot_1_1957 } from "./land_plots/1/land_plot_1957.svg";
import { ReactComponent as LandPlot_1_1958 } from "./land_plots/1/land_plot_1958.svg";
import { ReactComponent as LandPlot_1_1959 } from "./land_plots/1/land_plot_1959.svg";
import { ReactComponent as LandPlot_1_1960 } from "./land_plots/1/land_plot_1960.svg";
import { ReactComponent as LandPlot_1_1961 } from "./land_plots/1/land_plot_1961.svg";
import { ReactComponent as LandPlot_1_1962 } from "./land_plots/1/land_plot_1962.svg";
import { ReactComponent as LandPlot_1_1963 } from "./land_plots/1/land_plot_1963.svg";
import { ReactComponent as LandPlot_1_1964 } from "./land_plots/1/land_plot_1964.svg";
import { ReactComponent as LandPlot_1_1965 } from "./land_plots/1/land_plot_1965.svg";
import { ReactComponent as LandPlot_1_1966 } from "./land_plots/1/land_plot_1966.svg";
import { ReactComponent as LandPlot_1_1967 } from "./land_plots/1/land_plot_1967.svg";
import { ReactComponent as LandPlot_1_1968 } from "./land_plots/1/land_plot_1968.svg";
import { ReactComponent as LandPlot_1_1969 } from "./land_plots/1/land_plot_1969.svg";
import { ReactComponent as LandPlot_1_1970 } from "./land_plots/1/land_plot_1970.svg";
import { ReactComponent as LandPlot_1_1971 } from "./land_plots/1/land_plot_1971.svg";
import { ReactComponent as LandPlot_1_1972 } from "./land_plots/1/land_plot_1972.svg";
import { ReactComponent as LandPlot_1_1973 } from "./land_plots/1/land_plot_1973.svg";
import { ReactComponent as LandPlot_1_1974 } from "./land_plots/1/land_plot_1974.svg";
import { ReactComponent as LandPlot_1_1975 } from "./land_plots/1/land_plot_1975.svg";
import { ReactComponent as LandPlot_1_1976 } from "./land_plots/1/land_plot_1976.svg";
import { ReactComponent as LandPlot_1_1977 } from "./land_plots/1/land_plot_1977.svg";
import { ReactComponent as LandPlot_1_1978 } from "./land_plots/1/land_plot_1978.svg";
import { ReactComponent as LandPlot_1_1979 } from "./land_plots/1/land_plot_1979.svg";
import { ReactComponent as LandPlot_1_1980 } from "./land_plots/1/land_plot_1980.svg";
import { ReactComponent as LandPlot_1_1981 } from "./land_plots/1/land_plot_1981.svg";
import { ReactComponent as LandPlot_1_1982 } from "./land_plots/1/land_plot_1982.svg";
import { ReactComponent as LandPlot_1_1983 } from "./land_plots/1/land_plot_1983.svg";
import { ReactComponent as LandPlot_1_1984 } from "./land_plots/1/land_plot_1984.svg";
import { ReactComponent as LandPlot_1_1985 } from "./land_plots/1/land_plot_1985.svg";
import { ReactComponent as LandPlot_1_1986 } from "./land_plots/1/land_plot_1986.svg";
import { ReactComponent as LandPlot_1_1987 } from "./land_plots/1/land_plot_1987.svg";
import { ReactComponent as LandPlot_1_1988 } from "./land_plots/1/land_plot_1988.svg";
import { ReactComponent as LandPlot_1_1989 } from "./land_plots/1/land_plot_1989.svg";
import { ReactComponent as LandPlot_1_1990 } from "./land_plots/1/land_plot_1990.svg";
import { ReactComponent as LandPlot_1_1991 } from "./land_plots/1/land_plot_1991.svg";
import { ReactComponent as LandPlot_1_1992 } from "./land_plots/1/land_plot_1992.svg";
import { ReactComponent as LandPlot_1_1993 } from "./land_plots/1/land_plot_1993.svg";
import { ReactComponent as LandPlot_1_1994 } from "./land_plots/1/land_plot_1994.svg";
import { ReactComponent as LandPlot_1_1995 } from "./land_plots/1/land_plot_1995.svg";
import { ReactComponent as LandPlot_1_1996 } from "./land_plots/1/land_plot_1996.svg";
import { ReactComponent as LandPlot_1_1997 } from "./land_plots/1/land_plot_1997.svg";
import { ReactComponent as LandPlot_1_1998 } from "./land_plots/1/land_plot_1998.svg";
import { ReactComponent as LandPlot_1_1999 } from "./land_plots/1/land_plot_1999.svg";
import { ReactComponent as LandPlot_1_2000 } from "./land_plots/1/land_plot_2000.svg";
import { ReactComponent as LandPlot_1_2001 } from "./land_plots/1/land_plot_2001.svg";
import { ReactComponent as LandPlot_1_2002 } from "./land_plots/1/land_plot_2002.svg";
import { ReactComponent as LandPlot_1_2003 } from "./land_plots/1/land_plot_2003.svg";
import { ReactComponent as LandPlot_1_2004 } from "./land_plots/1/land_plot_2004.svg";
import { ReactComponent as LandPlot_1_2005 } from "./land_plots/1/land_plot_2005.svg";
import { ReactComponent as LandPlot_1_2006 } from "./land_plots/1/land_plot_2006.svg";
import { ReactComponent as LandPlot_1_2007 } from "./land_plots/1/land_plot_2007.svg";
import { ReactComponent as LandPlot_1_2008 } from "./land_plots/1/land_plot_2008.svg";
import { ReactComponent as LandPlot_1_2009 } from "./land_plots/1/land_plot_2009.svg";
import { ReactComponent as LandPlot_1_2010 } from "./land_plots/1/land_plot_2010.svg";
import { ReactComponent as LandPlot_1_2011 } from "./land_plots/1/land_plot_2011.svg";
import { ReactComponent as LandPlot_1_2012 } from "./land_plots/1/land_plot_2012.svg";
import { ReactComponent as LandPlot_1_2013 } from "./land_plots/1/land_plot_2013.svg";
import { ReactComponent as LandPlot_1_2014 } from "./land_plots/1/land_plot_2014.svg";
import { ReactComponent as LandPlot_1_2015 } from "./land_plots/1/land_plot_2015.svg";
import { ReactComponent as LandPlot_1_2016 } from "./land_plots/1/land_plot_2016.svg";
import { ReactComponent as LandPlot_1_2017 } from "./land_plots/1/land_plot_2017.svg";
import { ReactComponent as LandPlot_1_2018 } from "./land_plots/1/land_plot_2018.svg";
import { ReactComponent as LandPlot_1_2019 } from "./land_plots/1/land_plot_2019.svg";
import { ReactComponent as LandPlot_1_2020 } from "./land_plots/1/land_plot_2020.svg";
import { ReactComponent as LandPlot_1_2021 } from "./land_plots/1/land_plot_2021.svg";
import { ReactComponent as LandPlot_1_2022 } from "./land_plots/1/land_plot_2022.svg";
import { ReactComponent as LandPlot_1_2023 } from "./land_plots/1/land_plot_2023.svg";
import { ReactComponent as LandPlot_1_2024 } from "./land_plots/1/land_plot_2024.svg";
import { ReactComponent as LandPlot_1_2025 } from "./land_plots/1/land_plot_2025.svg";
import { ReactComponent as LandPlot_1_2026 } from "./land_plots/1/land_plot_2026.svg";
import { ReactComponent as LandPlot_1_2027 } from "./land_plots/1/land_plot_2027.svg";
import { ReactComponent as LandPlot_1_2028 } from "./land_plots/1/land_plot_2028.svg";
import { ReactComponent as LandPlot_1_2029 } from "./land_plots/1/land_plot_2029.svg";
import { ReactComponent as LandPlot_1_2030 } from "./land_plots/1/land_plot_2030.svg";
import { ReactComponent as LandPlot_1_2031 } from "./land_plots/1/land_plot_2031.svg";
import { ReactComponent as LandPlot_1_2032 } from "./land_plots/1/land_plot_2032.svg";
import { ReactComponent as LandPlot_1_2033 } from "./land_plots/1/land_plot_2033.svg";
import { ReactComponent as LandPlot_1_2034 } from "./land_plots/1/land_plot_2034.svg";
import { ReactComponent as LandPlot_1_2035 } from "./land_plots/1/land_plot_2035.svg";
import { ReactComponent as LandPlot_1_2036 } from "./land_plots/1/land_plot_2036.svg";
import { ReactComponent as LandPlot_1_2037 } from "./land_plots/1/land_plot_2037.svg";
import { ReactComponent as LandPlot_1_2038 } from "./land_plots/1/land_plot_2038.svg";
import { ReactComponent as LandPlot_1_2039 } from "./land_plots/1/land_plot_2039.svg";
import { ReactComponent as LandPlot_1_2040 } from "./land_plots/1/land_plot_2040.svg";
import { ReactComponent as LandPlot_1_2041 } from "./land_plots/1/land_plot_2041.svg";
import { ReactComponent as LandPlot_1_2042 } from "./land_plots/1/land_plot_2042.svg";
import { ReactComponent as LandPlot_1_2043 } from "./land_plots/1/land_plot_2043.svg";
import { ReactComponent as LandPlot_1_2044 } from "./land_plots/1/land_plot_2044.svg";
import { ReactComponent as LandPlot_1_2045 } from "./land_plots/1/land_plot_2045.svg";
import { ReactComponent as LandPlot_1_2046 } from "./land_plots/1/land_plot_2046.svg";
import { ReactComponent as LandPlot_1_2047 } from "./land_plots/1/land_plot_2047.svg";
import { ReactComponent as LandPlot_1_2048 } from "./land_plots/1/land_plot_2048.svg";
import { ReactComponent as LandPlot_1_2049 } from "./land_plots/1/land_plot_2049.svg";
import { ReactComponent as LandPlot_1_2050 } from "./land_plots/1/land_plot_2050.svg";
import { ReactComponent as LandPlot_1_2051 } from "./land_plots/1/land_plot_2051.svg";
import { ReactComponent as LandPlot_1_2052 } from "./land_plots/1/land_plot_2052.svg";

///////////////////////////////////////////////////////////////////////////////////stop frame 31
import { ReactComponent as LandPlot_31_1751 } from "./land_plots/31/land_plot_1751.svg";
import { ReactComponent as LandPlot_31_1752 } from "./land_plots/31/land_plot_1752.svg";
import { ReactComponent as LandPlot_31_1753 } from "./land_plots/31/land_plot_1753.svg";
import { ReactComponent as LandPlot_31_1754 } from "./land_plots/31/land_plot_1754.svg";
import { ReactComponent as LandPlot_31_1755 } from "./land_plots/31/land_plot_1755.svg";
import { ReactComponent as LandPlot_31_1756 } from "./land_plots/31/land_plot_1756.svg";
import { ReactComponent as LandPlot_31_1757 } from "./land_plots/31/land_plot_1757.svg";
import { ReactComponent as LandPlot_31_1758 } from "./land_plots/31/land_plot_1758.svg";
import { ReactComponent as LandPlot_31_1759 } from "./land_plots/31/land_plot_1759.svg";
import { ReactComponent as LandPlot_31_1760 } from "./land_plots/31/land_plot_1760.svg";
import { ReactComponent as LandPlot_31_1761 } from "./land_plots/31/land_plot_1761.svg";
import { ReactComponent as LandPlot_31_1762 } from "./land_plots/31/land_plot_1762.svg";
import { ReactComponent as LandPlot_31_1763 } from "./land_plots/31/land_plot_1763.svg";
import { ReactComponent as LandPlot_31_1764 } from "./land_plots/31/land_plot_1764.svg";
import { ReactComponent as LandPlot_31_1765 } from "./land_plots/31/land_plot_1765.svg";
import { ReactComponent as LandPlot_31_1766 } from "./land_plots/31/land_plot_1766.svg";
import { ReactComponent as LandPlot_31_1767 } from "./land_plots/31/land_plot_1767.svg";
import { ReactComponent as LandPlot_31_1768 } from "./land_plots/31/land_plot_1768.svg";
import { ReactComponent as LandPlot_31_1769 } from "./land_plots/31/land_plot_1769.svg";
import { ReactComponent as LandPlot_31_1770 } from "./land_plots/31/land_plot_1770.svg";
import { ReactComponent as LandPlot_31_1771 } from "./land_plots/31/land_plot_1771.svg";
import { ReactComponent as LandPlot_31_1772 } from "./land_plots/31/land_plot_1772.svg";
import { ReactComponent as LandPlot_31_1773 } from "./land_plots/31/land_plot_1773.svg";
import { ReactComponent as LandPlot_31_1774 } from "./land_plots/31/land_plot_1774.svg";
import { ReactComponent as LandPlot_31_1775 } from "./land_plots/31/land_plot_1775.svg";
import { ReactComponent as LandPlot_31_1776 } from "./land_plots/31/land_plot_1776.svg";
import { ReactComponent as LandPlot_31_1777 } from "./land_plots/31/land_plot_1777.svg";
import { ReactComponent as LandPlot_31_1778 } from "./land_plots/31/land_plot_1778.svg";
import { ReactComponent as LandPlot_31_1779 } from "./land_plots/31/land_plot_1779.svg";
import { ReactComponent as LandPlot_31_1780 } from "./land_plots/31/land_plot_1780.svg";
import { ReactComponent as LandPlot_31_1781 } from "./land_plots/31/land_plot_1781.svg";
import { ReactComponent as LandPlot_31_1782 } from "./land_plots/31/land_plot_1782.svg";
import { ReactComponent as LandPlot_31_1783 } from "./land_plots/31/land_plot_1783.svg";
import { ReactComponent as LandPlot_31_1784 } from "./land_plots/31/land_plot_1784.svg";
import { ReactComponent as LandPlot_31_1785 } from "./land_plots/31/land_plot_1785.svg";
import { ReactComponent as LandPlot_31_1786 } from "./land_plots/31/land_plot_1786.svg";
import { ReactComponent as LandPlot_31_1787 } from "./land_plots/31/land_plot_1787.svg";
import { ReactComponent as LandPlot_31_1788 } from "./land_plots/31/land_plot_1788.svg";
import { ReactComponent as LandPlot_31_1789 } from "./land_plots/31/land_plot_1789.svg";
import { ReactComponent as LandPlot_31_1790 } from "./land_plots/31/land_plot_1790.svg";
import { ReactComponent as LandPlot_31_1791 } from "./land_plots/31/land_plot_1791.svg";
import { ReactComponent as LandPlot_31_1792 } from "./land_plots/31/land_plot_1792.svg";
import { ReactComponent as LandPlot_31_1793 } from "./land_plots/31/land_plot_1793.svg";
import { ReactComponent as LandPlot_31_1794 } from "./land_plots/31/land_plot_1794.svg";
import { ReactComponent as LandPlot_31_1795 } from "./land_plots/31/land_plot_1795.svg";
import { ReactComponent as LandPlot_31_1796 } from "./land_plots/31/land_plot_1796.svg";
import { ReactComponent as LandPlot_31_1797 } from "./land_plots/31/land_plot_1797.svg";
import { ReactComponent as LandPlot_31_1798 } from "./land_plots/31/land_plot_1798.svg";
import { ReactComponent as LandPlot_31_1799 } from "./land_plots/31/land_plot_1799.svg";
import { ReactComponent as LandPlot_31_1800 } from "./land_plots/31/land_plot_1800.svg";
import { ReactComponent as LandPlot_31_1801 } from "./land_plots/31/land_plot_1801.svg";
import { ReactComponent as LandPlot_31_1802 } from "./land_plots/31/land_plot_1802.svg";
import { ReactComponent as LandPlot_31_1803 } from "./land_plots/31/land_plot_1803.svg";
import { ReactComponent as LandPlot_31_1804 } from "./land_plots/31/land_plot_1804.svg";
import { ReactComponent as LandPlot_31_1805 } from "./land_plots/31/land_plot_1805.svg";
import { ReactComponent as LandPlot_31_1806 } from "./land_plots/31/land_plot_1806.svg";
import { ReactComponent as LandPlot_31_1807 } from "./land_plots/31/land_plot_1807.svg";
import { ReactComponent as LandPlot_31_1808 } from "./land_plots/31/land_plot_1808.svg";
import { ReactComponent as LandPlot_31_1809 } from "./land_plots/31/land_plot_1809.svg";
import { ReactComponent as LandPlot_31_1810 } from "./land_plots/31/land_plot_1810.svg";
import { ReactComponent as LandPlot_31_1811 } from "./land_plots/31/land_plot_1811.svg";
import { ReactComponent as LandPlot_31_1812 } from "./land_plots/31/land_plot_1812.svg";
import { ReactComponent as LandPlot_31_1813 } from "./land_plots/31/land_plot_1813.svg";
import { ReactComponent as LandPlot_31_1814 } from "./land_plots/31/land_plot_1814.svg";
import { ReactComponent as LandPlot_31_1815 } from "./land_plots/31/land_plot_1815.svg";
import { ReactComponent as LandPlot_31_1816 } from "./land_plots/31/land_plot_1816.svg";
import { ReactComponent as LandPlot_31_1817 } from "./land_plots/31/land_plot_1817.svg";
import { ReactComponent as LandPlot_31_1818 } from "./land_plots/31/land_plot_1818.svg";
import { ReactComponent as LandPlot_31_1819 } from "./land_plots/31/land_plot_1819.svg";
import { ReactComponent as LandPlot_31_1820 } from "./land_plots/31/land_plot_1820.svg";
import { ReactComponent as LandPlot_31_1821 } from "./land_plots/31/land_plot_1821.svg";
import { ReactComponent as LandPlot_31_1822 } from "./land_plots/31/land_plot_1822.svg";
import { ReactComponent as LandPlot_31_1823 } from "./land_plots/31/land_plot_1823.svg";
import { ReactComponent as LandPlot_31_1824 } from "./land_plots/31/land_plot_1824.svg";
import { ReactComponent as LandPlot_31_1825 } from "./land_plots/31/land_plot_1825.svg";
import { ReactComponent as LandPlot_31_1826 } from "./land_plots/31/land_plot_1826.svg";
import { ReactComponent as LandPlot_31_1827 } from "./land_plots/31/land_plot_1827.svg";
import { ReactComponent as LandPlot_31_1828 } from "./land_plots/31/land_plot_1828.svg";
import { ReactComponent as LandPlot_31_1829 } from "./land_plots/31/land_plot_1829.svg";
import { ReactComponent as LandPlot_31_1830 } from "./land_plots/31/land_plot_1830.svg";
import { ReactComponent as LandPlot_31_1831 } from "./land_plots/31/land_plot_1831.svg";
import { ReactComponent as LandPlot_31_1832 } from "./land_plots/31/land_plot_1832.svg";
import { ReactComponent as LandPlot_31_1833 } from "./land_plots/31/land_plot_1833.svg";
import { ReactComponent as LandPlot_31_1834 } from "./land_plots/31/land_plot_1834.svg";
import { ReactComponent as LandPlot_31_1835 } from "./land_plots/31/land_plot_1835.svg";
import { ReactComponent as LandPlot_31_1836 } from "./land_plots/31/land_plot_1836.svg";
import { ReactComponent as LandPlot_31_1837 } from "./land_plots/31/land_plot_1837.svg";
import { ReactComponent as LandPlot_31_1838 } from "./land_plots/31/land_plot_1838.svg";
import { ReactComponent as LandPlot_31_1839 } from "./land_plots/31/land_plot_1839.svg";
import { ReactComponent as LandPlot_31_1840 } from "./land_plots/31/land_plot_1840.svg";
import { ReactComponent as LandPlot_31_1841 } from "./land_plots/31/land_plot_1841.svg";
import { ReactComponent as LandPlot_31_1842 } from "./land_plots/31/land_plot_1842.svg";
import { ReactComponent as LandPlot_31_1843 } from "./land_plots/31/land_plot_1843.svg";
import { ReactComponent as LandPlot_31_1844 } from "./land_plots/31/land_plot_1844.svg";
import { ReactComponent as LandPlot_31_1845 } from "./land_plots/31/land_plot_1845.svg";
import { ReactComponent as LandPlot_31_1846 } from "./land_plots/31/land_plot_1846.svg";
import { ReactComponent as LandPlot_31_1847 } from "./land_plots/31/land_plot_1847.svg";
import { ReactComponent as LandPlot_31_1848 } from "./land_plots/31/land_plot_1848.svg";
import { ReactComponent as LandPlot_31_1849 } from "./land_plots/31/land_plot_1849.svg";
import { ReactComponent as LandPlot_31_1850 } from "./land_plots/31/land_plot_1850.svg";
import { ReactComponent as LandPlot_31_1851 } from "./land_plots/31/land_plot_1851.svg";
import { ReactComponent as LandPlot_31_1852 } from "./land_plots/31/land_plot_1852.svg";
import { ReactComponent as LandPlot_31_1853 } from "./land_plots/31/land_plot_1853.svg";
import { ReactComponent as LandPlot_31_1854 } from "./land_plots/31/land_plot_1854.svg";
import { ReactComponent as LandPlot_31_1855 } from "./land_plots/31/land_plot_1855.svg";
import { ReactComponent as LandPlot_31_1856 } from "./land_plots/31/land_plot_1856.svg";
import { ReactComponent as LandPlot_31_1857 } from "./land_plots/31/land_plot_1857.svg";
import { ReactComponent as LandPlot_31_1858 } from "./land_plots/31/land_plot_1858.svg";
import { ReactComponent as LandPlot_31_1859 } from "./land_plots/31/land_plot_1859.svg";
import { ReactComponent as LandPlot_31_1860 } from "./land_plots/31/land_plot_1860.svg";
import { ReactComponent as LandPlot_31_1861 } from "./land_plots/31/land_plot_1861.svg";
import { ReactComponent as LandPlot_31_1862 } from "./land_plots/31/land_plot_1862.svg";
import { ReactComponent as LandPlot_31_1863 } from "./land_plots/31/land_plot_1863.svg";
import { ReactComponent as LandPlot_31_1864 } from "./land_plots/31/land_plot_1864.svg";
import { ReactComponent as LandPlot_31_1865 } from "./land_plots/31/land_plot_1865.svg";
import { ReactComponent as LandPlot_31_1866 } from "./land_plots/31/land_plot_1866.svg";
import { ReactComponent as LandPlot_31_1867 } from "./land_plots/31/land_plot_1867.svg";
import { ReactComponent as LandPlot_31_1868 } from "./land_plots/31/land_plot_1868.svg";
import { ReactComponent as LandPlot_31_1869 } from "./land_plots/31/land_plot_1869.svg";
import { ReactComponent as LandPlot_31_1870 } from "./land_plots/31/land_plot_1870.svg";
import { ReactComponent as LandPlot_31_1871 } from "./land_plots/31/land_plot_1871.svg";
import { ReactComponent as LandPlot_31_1872 } from "./land_plots/31/land_plot_1872.svg";
import { ReactComponent as LandPlot_31_1873 } from "./land_plots/31/land_plot_1873.svg";
import { ReactComponent as LandPlot_31_1874 } from "./land_plots/31/land_plot_1874.svg";
import { ReactComponent as LandPlot_31_1875 } from "./land_plots/31/land_plot_1875.svg";
import { ReactComponent as LandPlot_31_1876 } from "./land_plots/31/land_plot_1876.svg";
import { ReactComponent as LandPlot_31_1877 } from "./land_plots/31/land_plot_1877.svg";
import { ReactComponent as LandPlot_31_1878 } from "./land_plots/31/land_plot_1878.svg";
import { ReactComponent as LandPlot_31_1879 } from "./land_plots/31/land_plot_1879.svg";
import { ReactComponent as LandPlot_31_1880 } from "./land_plots/31/land_plot_1880.svg";
import { ReactComponent as LandPlot_31_1881 } from "./land_plots/31/land_plot_1881.svg";
import { ReactComponent as LandPlot_31_1882 } from "./land_plots/31/land_plot_1882.svg";
import { ReactComponent as LandPlot_31_1883 } from "./land_plots/31/land_plot_1883.svg";
import { ReactComponent as LandPlot_31_1884 } from "./land_plots/31/land_plot_1884.svg";
import { ReactComponent as LandPlot_31_1885 } from "./land_plots/31/land_plot_1885.svg";
import { ReactComponent as LandPlot_31_1886 } from "./land_plots/31/land_plot_1886.svg";
import { ReactComponent as LandPlot_31_1887 } from "./land_plots/31/land_plot_1887.svg";
import { ReactComponent as LandPlot_31_1888 } from "./land_plots/31/land_plot_1888.svg";
import { ReactComponent as LandPlot_31_1889 } from "./land_plots/31/land_plot_1889.svg";
import { ReactComponent as LandPlot_31_1890 } from "./land_plots/31/land_plot_1890.svg";
import { ReactComponent as LandPlot_31_1891 } from "./land_plots/31/land_plot_1891.svg";
import { ReactComponent as LandPlot_31_1892 } from "./land_plots/31/land_plot_1892.svg";
import { ReactComponent as LandPlot_31_1893 } from "./land_plots/31/land_plot_1893.svg";
import { ReactComponent as LandPlot_31_1894 } from "./land_plots/31/land_plot_1894.svg";
import { ReactComponent as LandPlot_31_1895 } from "./land_plots/31/land_plot_1895.svg";
import { ReactComponent as LandPlot_31_1896 } from "./land_plots/31/land_plot_1896.svg";
import { ReactComponent as LandPlot_31_1897 } from "./land_plots/31/land_plot_1897.svg";
import { ReactComponent as LandPlot_31_1898 } from "./land_plots/31/land_plot_1898.svg";
import { ReactComponent as LandPlot_31_1899 } from "./land_plots/31/land_plot_1899.svg";
import { ReactComponent as LandPlot_31_1900 } from "./land_plots/31/land_plot_1900.svg";
import { ReactComponent as LandPlot_31_1901 } from "./land_plots/31/land_plot_1901.svg";
import { ReactComponent as LandPlot_31_1902 } from "./land_plots/31/land_plot_1902.svg";
import { ReactComponent as LandPlot_31_1903 } from "./land_plots/31/land_plot_1903.svg";
import { ReactComponent as LandPlot_31_1904 } from "./land_plots/31/land_plot_1904.svg";
import { ReactComponent as LandPlot_31_1905 } from "./land_plots/31/land_plot_1905.svg";
import { ReactComponent as LandPlot_31_1906 } from "./land_plots/31/land_plot_1906.svg";
import { ReactComponent as LandPlot_31_1907 } from "./land_plots/31/land_plot_1907.svg";
import { ReactComponent as LandPlot_31_1908 } from "./land_plots/31/land_plot_1908.svg";
import { ReactComponent as LandPlot_31_1909 } from "./land_plots/31/land_plot_1909.svg";
import { ReactComponent as LandPlot_31_1910 } from "./land_plots/31/land_plot_1910.svg";
import { ReactComponent as LandPlot_31_1911 } from "./land_plots/31/land_plot_1911.svg";
import { ReactComponent as LandPlot_31_1912 } from "./land_plots/31/land_plot_1912.svg";
import { ReactComponent as LandPlot_31_1913 } from "./land_plots/31/land_plot_1913.svg";
import { ReactComponent as LandPlot_31_1914 } from "./land_plots/31/land_plot_1914.svg";
import { ReactComponent as LandPlot_31_1915 } from "./land_plots/31/land_plot_1915.svg";
import { ReactComponent as LandPlot_31_1916 } from "./land_plots/31/land_plot_1916.svg";
import { ReactComponent as LandPlot_31_1917 } from "./land_plots/31/land_plot_1917.svg";
import { ReactComponent as LandPlot_31_1918 } from "./land_plots/31/land_plot_1918.svg";
import { ReactComponent as LandPlot_31_1919 } from "./land_plots/31/land_plot_1919.svg";
import { ReactComponent as LandPlot_31_1920 } from "./land_plots/31/land_plot_1920.svg";
import { ReactComponent as LandPlot_31_1921 } from "./land_plots/31/land_plot_1921.svg";
import { ReactComponent as LandPlot_31_1922 } from "./land_plots/31/land_plot_1922.svg";
import { ReactComponent as LandPlot_31_1923 } from "./land_plots/31/land_plot_1923.svg";
import { ReactComponent as LandPlot_31_1924 } from "./land_plots/31/land_plot_1924.svg";
import { ReactComponent as LandPlot_31_1925 } from "./land_plots/31/land_plot_1925.svg";
import { ReactComponent as LandPlot_31_1926 } from "./land_plots/31/land_plot_1926.svg";
import { ReactComponent as LandPlot_31_1927 } from "./land_plots/31/land_plot_1927.svg";
import { ReactComponent as LandPlot_31_1928 } from "./land_plots/31/land_plot_1928.svg";
import { ReactComponent as LandPlot_31_1929 } from "./land_plots/31/land_plot_1929.svg";
import { ReactComponent as LandPlot_31_1930 } from "./land_plots/31/land_plot_1930.svg";
import { ReactComponent as LandPlot_31_1931 } from "./land_plots/31/land_plot_1931.svg";
import { ReactComponent as LandPlot_31_1932 } from "./land_plots/31/land_plot_1932.svg";
import { ReactComponent as LandPlot_31_1933 } from "./land_plots/31/land_plot_1933.svg";
import { ReactComponent as LandPlot_31_1934 } from "./land_plots/31/land_plot_1934.svg";
import { ReactComponent as LandPlot_31_1935 } from "./land_plots/31/land_plot_1935.svg";
import { ReactComponent as LandPlot_31_1936 } from "./land_plots/31/land_plot_1936.svg";
import { ReactComponent as LandPlot_31_1937 } from "./land_plots/31/land_plot_1937.svg";
import { ReactComponent as LandPlot_31_1938 } from "./land_plots/31/land_plot_1938.svg";
import { ReactComponent as LandPlot_31_1939 } from "./land_plots/31/land_plot_1939.svg";
import { ReactComponent as LandPlot_31_1940 } from "./land_plots/31/land_plot_1940.svg";
import { ReactComponent as LandPlot_31_1941 } from "./land_plots/31/land_plot_1941.svg";
import { ReactComponent as LandPlot_31_1942 } from "./land_plots/31/land_plot_1942.svg";
import { ReactComponent as LandPlot_31_1943 } from "./land_plots/31/land_plot_1943.svg";
import { ReactComponent as LandPlot_31_1944 } from "./land_plots/31/land_plot_1944.svg";
import { ReactComponent as LandPlot_31_1945 } from "./land_plots/31/land_plot_1945.svg";
import { ReactComponent as LandPlot_31_1946 } from "./land_plots/31/land_plot_1946.svg";
import { ReactComponent as LandPlot_31_1947 } from "./land_plots/31/land_plot_1947.svg";
import { ReactComponent as LandPlot_31_1948 } from "./land_plots/31/land_plot_1948.svg";
import { ReactComponent as LandPlot_31_1949 } from "./land_plots/31/land_plot_1949.svg";
import { ReactComponent as LandPlot_31_1950 } from "./land_plots/31/land_plot_1950.svg";
import { ReactComponent as LandPlot_31_1951 } from "./land_plots/31/land_plot_1951.svg";
import { ReactComponent as LandPlot_31_1952 } from "./land_plots/31/land_plot_1952.svg";
import { ReactComponent as LandPlot_31_1953 } from "./land_plots/31/land_plot_1953.svg";
import { ReactComponent as LandPlot_31_1954 } from "./land_plots/31/land_plot_1954.svg";
import { ReactComponent as LandPlot_31_1955 } from "./land_plots/31/land_plot_1955.svg";
import { ReactComponent as LandPlot_31_1956 } from "./land_plots/31/land_plot_1956.svg";
import { ReactComponent as LandPlot_31_1957 } from "./land_plots/31/land_plot_1957.svg";
import { ReactComponent as LandPlot_31_1958 } from "./land_plots/31/land_plot_1958.svg";
import { ReactComponent as LandPlot_31_1959 } from "./land_plots/31/land_plot_1959.svg";
import { ReactComponent as LandPlot_31_1960 } from "./land_plots/31/land_plot_1960.svg";
import { ReactComponent as LandPlot_31_1961 } from "./land_plots/31/land_plot_1961.svg";
import { ReactComponent as LandPlot_31_1962 } from "./land_plots/31/land_plot_1962.svg";
import { ReactComponent as LandPlot_31_1963 } from "./land_plots/31/land_plot_1963.svg";
import { ReactComponent as LandPlot_31_1964 } from "./land_plots/31/land_plot_1964.svg";
import { ReactComponent as LandPlot_31_1965 } from "./land_plots/31/land_plot_1965.svg";
import { ReactComponent as LandPlot_31_1966 } from "./land_plots/31/land_plot_1966.svg";
import { ReactComponent as LandPlot_31_1967 } from "./land_plots/31/land_plot_1967.svg";
import { ReactComponent as LandPlot_31_1968 } from "./land_plots/31/land_plot_1968.svg";
import { ReactComponent as LandPlot_31_1969 } from "./land_plots/31/land_plot_1969.svg";
import { ReactComponent as LandPlot_31_1970 } from "./land_plots/31/land_plot_1970.svg";
import { ReactComponent as LandPlot_31_1971 } from "./land_plots/31/land_plot_1971.svg";
import { ReactComponent as LandPlot_31_1972 } from "./land_plots/31/land_plot_1972.svg";
import { ReactComponent as LandPlot_31_1973 } from "./land_plots/31/land_plot_1973.svg";
import { ReactComponent as LandPlot_31_1974 } from "./land_plots/31/land_plot_1974.svg";
import { ReactComponent as LandPlot_31_1975 } from "./land_plots/31/land_plot_1975.svg";
import { ReactComponent as LandPlot_31_1976 } from "./land_plots/31/land_plot_1976.svg";
import { ReactComponent as LandPlot_31_1977 } from "./land_plots/31/land_plot_1977.svg";
import { ReactComponent as LandPlot_31_1978 } from "./land_plots/31/land_plot_1978.svg";
import { ReactComponent as LandPlot_31_1979 } from "./land_plots/31/land_plot_1979.svg";
import { ReactComponent as LandPlot_31_1980 } from "./land_plots/31/land_plot_1980.svg";
import { ReactComponent as LandPlot_31_1981 } from "./land_plots/31/land_plot_1981.svg";
import { ReactComponent as LandPlot_31_1982 } from "./land_plots/31/land_plot_1982.svg";
import { ReactComponent as LandPlot_31_1983 } from "./land_plots/31/land_plot_1983.svg";
import { ReactComponent as LandPlot_31_1984 } from "./land_plots/31/land_plot_1984.svg";
import { ReactComponent as LandPlot_31_1985 } from "./land_plots/31/land_plot_1985.svg";
import { ReactComponent as LandPlot_31_1986 } from "./land_plots/31/land_plot_1986.svg";
import { ReactComponent as LandPlot_31_1987 } from "./land_plots/31/land_plot_1987.svg";
import { ReactComponent as LandPlot_31_1988 } from "./land_plots/31/land_plot_1988.svg";
import { ReactComponent as LandPlot_31_1989 } from "./land_plots/31/land_plot_1989.svg";
import { ReactComponent as LandPlot_31_1990 } from "./land_plots/31/land_plot_1990.svg";
import { ReactComponent as LandPlot_31_1991 } from "./land_plots/31/land_plot_1991.svg";
import { ReactComponent as LandPlot_31_1992 } from "./land_plots/31/land_plot_1992.svg";
import { ReactComponent as LandPlot_31_1993 } from "./land_plots/31/land_plot_1993.svg";
import { ReactComponent as LandPlot_31_1994 } from "./land_plots/31/land_plot_1994.svg";
import { ReactComponent as LandPlot_31_1995 } from "./land_plots/31/land_plot_1995.svg";
import { ReactComponent as LandPlot_31_1996 } from "./land_plots/31/land_plot_1996.svg";
import { ReactComponent as LandPlot_31_1997 } from "./land_plots/31/land_plot_1997.svg";
import { ReactComponent as LandPlot_31_1998 } from "./land_plots/31/land_plot_1998.svg";
import { ReactComponent as LandPlot_31_1999 } from "./land_plots/31/land_plot_1999.svg";
import { ReactComponent as LandPlot_31_2000 } from "./land_plots/31/land_plot_2000.svg";
import { ReactComponent as LandPlot_31_2001 } from "./land_plots/31/land_plot_2001.svg";
import { ReactComponent as LandPlot_31_2002 } from "./land_plots/31/land_plot_2002.svg";
import { ReactComponent as LandPlot_31_2003 } from "./land_plots/31/land_plot_2003.svg";
import { ReactComponent as LandPlot_31_2004 } from "./land_plots/31/land_plot_2004.svg";
import { ReactComponent as LandPlot_31_2005 } from "./land_plots/31/land_plot_2005.svg";
import { ReactComponent as LandPlot_31_2006 } from "./land_plots/31/land_plot_2006.svg";
import { ReactComponent as LandPlot_31_2007 } from "./land_plots/31/land_plot_2007.svg";
import { ReactComponent as LandPlot_31_2008 } from "./land_plots/31/land_plot_2008.svg";
import { ReactComponent as LandPlot_31_2009 } from "./land_plots/31/land_plot_2009.svg";
import { ReactComponent as LandPlot_31_2010 } from "./land_plots/31/land_plot_2010.svg";
import { ReactComponent as LandPlot_31_2011 } from "./land_plots/31/land_plot_2011.svg";
import { ReactComponent as LandPlot_31_2012 } from "./land_plots/31/land_plot_2012.svg";
import { ReactComponent as LandPlot_31_2013 } from "./land_plots/31/land_plot_2013.svg";
import { ReactComponent as LandPlot_31_2014 } from "./land_plots/31/land_plot_2014.svg";
import { ReactComponent as LandPlot_31_2015 } from "./land_plots/31/land_plot_2015.svg";
import { ReactComponent as LandPlot_31_2016 } from "./land_plots/31/land_plot_2016.svg";
import { ReactComponent as LandPlot_31_2017 } from "./land_plots/31/land_plot_2017.svg";
import { ReactComponent as LandPlot_31_2018 } from "./land_plots/31/land_plot_2018.svg";
import { ReactComponent as LandPlot_31_2019 } from "./land_plots/31/land_plot_2019.svg";
import { ReactComponent as LandPlot_31_2020 } from "./land_plots/31/land_plot_2020.svg";
import { ReactComponent as LandPlot_31_2021 } from "./land_plots/31/land_plot_2021.svg";
import { ReactComponent as LandPlot_31_2022 } from "./land_plots/31/land_plot_2022.svg";
import { ReactComponent as LandPlot_31_2023 } from "./land_plots/31/land_plot_2023.svg";
import { ReactComponent as LandPlot_31_2024 } from "./land_plots/31/land_plot_2024.svg";
import { ReactComponent as LandPlot_31_2025 } from "./land_plots/31/land_plot_2025.svg";
import { ReactComponent as LandPlot_31_2026 } from "./land_plots/31/land_plot_2026.svg";
import { ReactComponent as LandPlot_31_2027 } from "./land_plots/31/land_plot_2027.svg";
import { ReactComponent as LandPlot_31_2028 } from "./land_plots/31/land_plot_2028.svg";
import { ReactComponent as LandPlot_31_2029 } from "./land_plots/31/land_plot_2029.svg";
import { ReactComponent as LandPlot_31_2030 } from "./land_plots/31/land_plot_2030.svg";
import { ReactComponent as LandPlot_31_2031 } from "./land_plots/31/land_plot_2031.svg";
import { ReactComponent as LandPlot_31_2032 } from "./land_plots/31/land_plot_2032.svg";
import { ReactComponent as LandPlot_31_2033 } from "./land_plots/31/land_plot_2033.svg";
import { ReactComponent as LandPlot_31_2034 } from "./land_plots/31/land_plot_2034.svg";
import { ReactComponent as LandPlot_31_2035 } from "./land_plots/31/land_plot_2035.svg";
import { ReactComponent as LandPlot_31_2036 } from "./land_plots/31/land_plot_2036.svg";
import { ReactComponent as LandPlot_31_2037 } from "./land_plots/31/land_plot_2037.svg";
import { ReactComponent as LandPlot_31_2038 } from "./land_plots/31/land_plot_2038.svg";
import { ReactComponent as LandPlot_31_2039 } from "./land_plots/31/land_plot_2039.svg";
import { ReactComponent as LandPlot_31_2040 } from "./land_plots/31/land_plot_2040.svg";
import { ReactComponent as LandPlot_31_2041 } from "./land_plots/31/land_plot_2041.svg";
import { ReactComponent as LandPlot_31_2042 } from "./land_plots/31/land_plot_2042.svg";
import { ReactComponent as LandPlot_31_2043 } from "./land_plots/31/land_plot_2043.svg";
import { ReactComponent as LandPlot_31_2044 } from "./land_plots/31/land_plot_2044.svg";
import { ReactComponent as LandPlot_31_2045 } from "./land_plots/31/land_plot_2045.svg";
import { ReactComponent as LandPlot_31_2046 } from "./land_plots/31/land_plot_2046.svg";
import { ReactComponent as LandPlot_31_2047 } from "./land_plots/31/land_plot_2047.svg";
import { ReactComponent as LandPlot_31_2048 } from "./land_plots/31/land_plot_2048.svg";
import { ReactComponent as LandPlot_31_2049 } from "./land_plots/31/land_plot_2049.svg";
import { ReactComponent as LandPlot_31_2050 } from "./land_plots/31/land_plot_2050.svg";
import { ReactComponent as LandPlot_31_2051 } from "./land_plots/31/land_plot_2051.svg";
import { ReactComponent as LandPlot_31_2052 } from "./land_plots/31/land_plot_2052.svg";

///////////////////////////////////////////////////////////////////////////////////stop frame 61
import { ReactComponent as LandPlot_61_1751 } from "./land_plots/61/land_plot_1751.svg";
import { ReactComponent as LandPlot_61_1752 } from "./land_plots/61/land_plot_1752.svg";
import { ReactComponent as LandPlot_61_1753 } from "./land_plots/61/land_plot_1753.svg";
import { ReactComponent as LandPlot_61_1754 } from "./land_plots/61/land_plot_1754.svg";
import { ReactComponent as LandPlot_61_1755 } from "./land_plots/61/land_plot_1755.svg";
import { ReactComponent as LandPlot_61_1756 } from "./land_plots/61/land_plot_1756.svg";
import { ReactComponent as LandPlot_61_1757 } from "./land_plots/61/land_plot_1757.svg";
import { ReactComponent as LandPlot_61_1758 } from "./land_plots/61/land_plot_1758.svg";
import { ReactComponent as LandPlot_61_1759 } from "./land_plots/61/land_plot_1759.svg";
import { ReactComponent as LandPlot_61_1760 } from "./land_plots/61/land_plot_1760.svg";
import { ReactComponent as LandPlot_61_1761 } from "./land_plots/61/land_plot_1761.svg";
import { ReactComponent as LandPlot_61_1762 } from "./land_plots/61/land_plot_1762.svg";
import { ReactComponent as LandPlot_61_1763 } from "./land_plots/61/land_plot_1763.svg";
import { ReactComponent as LandPlot_61_1764 } from "./land_plots/61/land_plot_1764.svg";
import { ReactComponent as LandPlot_61_1765 } from "./land_plots/61/land_plot_1765.svg";
import { ReactComponent as LandPlot_61_1766 } from "./land_plots/61/land_plot_1766.svg";
import { ReactComponent as LandPlot_61_1767 } from "./land_plots/61/land_plot_1767.svg";
import { ReactComponent as LandPlot_61_1768 } from "./land_plots/61/land_plot_1768.svg";
import { ReactComponent as LandPlot_61_1769 } from "./land_plots/61/land_plot_1769.svg";
import { ReactComponent as LandPlot_61_1770 } from "./land_plots/61/land_plot_1770.svg";
import { ReactComponent as LandPlot_61_1771 } from "./land_plots/61/land_plot_1771.svg";
import { ReactComponent as LandPlot_61_1772 } from "./land_plots/61/land_plot_1772.svg";
import { ReactComponent as LandPlot_61_1773 } from "./land_plots/61/land_plot_1773.svg";
import { ReactComponent as LandPlot_61_1774 } from "./land_plots/61/land_plot_1774.svg";
import { ReactComponent as LandPlot_61_1775 } from "./land_plots/61/land_plot_1775.svg";
import { ReactComponent as LandPlot_61_1776 } from "./land_plots/61/land_plot_1776.svg";
import { ReactComponent as LandPlot_61_1777 } from "./land_plots/61/land_plot_1777.svg";
import { ReactComponent as LandPlot_61_1778 } from "./land_plots/61/land_plot_1778.svg";
import { ReactComponent as LandPlot_61_1779 } from "./land_plots/61/land_plot_1779.svg";
import { ReactComponent as LandPlot_61_1780 } from "./land_plots/61/land_plot_1780.svg";
import { ReactComponent as LandPlot_61_1781 } from "./land_plots/61/land_plot_1781.svg";
import { ReactComponent as LandPlot_61_1782 } from "./land_plots/61/land_plot_1782.svg";
import { ReactComponent as LandPlot_61_1783 } from "./land_plots/61/land_plot_1783.svg";
import { ReactComponent as LandPlot_61_1784 } from "./land_plots/61/land_plot_1784.svg";
import { ReactComponent as LandPlot_61_1785 } from "./land_plots/61/land_plot_1785.svg";
import { ReactComponent as LandPlot_61_1786 } from "./land_plots/61/land_plot_1786.svg";
import { ReactComponent as LandPlot_61_1787 } from "./land_plots/61/land_plot_1787.svg";
import { ReactComponent as LandPlot_61_1788 } from "./land_plots/61/land_plot_1788.svg";
import { ReactComponent as LandPlot_61_1789 } from "./land_plots/61/land_plot_1789.svg";
import { ReactComponent as LandPlot_61_1790 } from "./land_plots/61/land_plot_1790.svg";
import { ReactComponent as LandPlot_61_1791 } from "./land_plots/61/land_plot_1791.svg";
import { ReactComponent as LandPlot_61_1792 } from "./land_plots/61/land_plot_1792.svg";
import { ReactComponent as LandPlot_61_1793 } from "./land_plots/61/land_plot_1793.svg";
import { ReactComponent as LandPlot_61_1794 } from "./land_plots/61/land_plot_1794.svg";
import { ReactComponent as LandPlot_61_1795 } from "./land_plots/61/land_plot_1795.svg";
import { ReactComponent as LandPlot_61_1796 } from "./land_plots/61/land_plot_1796.svg";
import { ReactComponent as LandPlot_61_1797 } from "./land_plots/61/land_plot_1797.svg";
import { ReactComponent as LandPlot_61_1798 } from "./land_plots/61/land_plot_1798.svg";
import { ReactComponent as LandPlot_61_1799 } from "./land_plots/61/land_plot_1799.svg";
import { ReactComponent as LandPlot_61_1800 } from "./land_plots/61/land_plot_1800.svg";
import { ReactComponent as LandPlot_61_1801 } from "./land_plots/61/land_plot_1801.svg";
import { ReactComponent as LandPlot_61_1802 } from "./land_plots/61/land_plot_1802.svg";
import { ReactComponent as LandPlot_61_1803 } from "./land_plots/61/land_plot_1803.svg";
import { ReactComponent as LandPlot_61_1804 } from "./land_plots/61/land_plot_1804.svg";
import { ReactComponent as LandPlot_61_1805 } from "./land_plots/61/land_plot_1805.svg";
import { ReactComponent as LandPlot_61_1806 } from "./land_plots/61/land_plot_1806.svg";
import { ReactComponent as LandPlot_61_1807 } from "./land_plots/61/land_plot_1807.svg";
import { ReactComponent as LandPlot_61_1808 } from "./land_plots/61/land_plot_1808.svg";
import { ReactComponent as LandPlot_61_1809 } from "./land_plots/61/land_plot_1809.svg";
import { ReactComponent as LandPlot_61_1810 } from "./land_plots/61/land_plot_1810.svg";
import { ReactComponent as LandPlot_61_1811 } from "./land_plots/61/land_plot_1811.svg";
import { ReactComponent as LandPlot_61_1812 } from "./land_plots/61/land_plot_1812.svg";
import { ReactComponent as LandPlot_61_1813 } from "./land_plots/61/land_plot_1813.svg";
import { ReactComponent as LandPlot_61_1814 } from "./land_plots/61/land_plot_1814.svg";
import { ReactComponent as LandPlot_61_1815 } from "./land_plots/61/land_plot_1815.svg";
import { ReactComponent as LandPlot_61_1816 } from "./land_plots/61/land_plot_1816.svg";
import { ReactComponent as LandPlot_61_1817 } from "./land_plots/61/land_plot_1817.svg";
import { ReactComponent as LandPlot_61_1818 } from "./land_plots/61/land_plot_1818.svg";
import { ReactComponent as LandPlot_61_1819 } from "./land_plots/61/land_plot_1819.svg";
import { ReactComponent as LandPlot_61_1820 } from "./land_plots/61/land_plot_1820.svg";
import { ReactComponent as LandPlot_61_1821 } from "./land_plots/61/land_plot_1821.svg";
import { ReactComponent as LandPlot_61_1822 } from "./land_plots/61/land_plot_1822.svg";
import { ReactComponent as LandPlot_61_1823 } from "./land_plots/61/land_plot_1823.svg";
import { ReactComponent as LandPlot_61_1824 } from "./land_plots/61/land_plot_1824.svg";
import { ReactComponent as LandPlot_61_1825 } from "./land_plots/61/land_plot_1825.svg";
import { ReactComponent as LandPlot_61_1826 } from "./land_plots/61/land_plot_1826.svg";
import { ReactComponent as LandPlot_61_1827 } from "./land_plots/61/land_plot_1827.svg";
import { ReactComponent as LandPlot_61_1828 } from "./land_plots/61/land_plot_1828.svg";
import { ReactComponent as LandPlot_61_1829 } from "./land_plots/61/land_plot_1829.svg";
import { ReactComponent as LandPlot_61_1830 } from "./land_plots/61/land_plot_1830.svg";
import { ReactComponent as LandPlot_61_1831 } from "./land_plots/61/land_plot_1831.svg";
import { ReactComponent as LandPlot_61_1832 } from "./land_plots/61/land_plot_1832.svg";
import { ReactComponent as LandPlot_61_1833 } from "./land_plots/61/land_plot_1833.svg";
import { ReactComponent as LandPlot_61_1834 } from "./land_plots/61/land_plot_1834.svg";
import { ReactComponent as LandPlot_61_1835 } from "./land_plots/61/land_plot_1835.svg";
import { ReactComponent as LandPlot_61_1836 } from "./land_plots/61/land_plot_1836.svg";
import { ReactComponent as LandPlot_61_1837 } from "./land_plots/61/land_plot_1837.svg";
import { ReactComponent as LandPlot_61_1838 } from "./land_plots/61/land_plot_1838.svg";
import { ReactComponent as LandPlot_61_1839 } from "./land_plots/61/land_plot_1839.svg";
import { ReactComponent as LandPlot_61_1840 } from "./land_plots/61/land_plot_1840.svg";
import { ReactComponent as LandPlot_61_1841 } from "./land_plots/61/land_plot_1841.svg";
import { ReactComponent as LandPlot_61_1842 } from "./land_plots/61/land_plot_1842.svg";
import { ReactComponent as LandPlot_61_1843 } from "./land_plots/61/land_plot_1843.svg";
import { ReactComponent as LandPlot_61_1844 } from "./land_plots/61/land_plot_1844.svg";
import { ReactComponent as LandPlot_61_1845 } from "./land_plots/61/land_plot_1845.svg";
import { ReactComponent as LandPlot_61_1846 } from "./land_plots/61/land_plot_1846.svg";
import { ReactComponent as LandPlot_61_1847 } from "./land_plots/61/land_plot_1847.svg";
import { ReactComponent as LandPlot_61_1848 } from "./land_plots/61/land_plot_1848.svg";
import { ReactComponent as LandPlot_61_1849 } from "./land_plots/61/land_plot_1849.svg";
import { ReactComponent as LandPlot_61_1850 } from "./land_plots/61/land_plot_1850.svg";
import { ReactComponent as LandPlot_61_1851 } from "./land_plots/61/land_plot_1851.svg";
import { ReactComponent as LandPlot_61_1852 } from "./land_plots/61/land_plot_1852.svg";
import { ReactComponent as LandPlot_61_1853 } from "./land_plots/61/land_plot_1853.svg";
import { ReactComponent as LandPlot_61_1854 } from "./land_plots/61/land_plot_1854.svg";
import { ReactComponent as LandPlot_61_1855 } from "./land_plots/61/land_plot_1855.svg";
import { ReactComponent as LandPlot_61_1856 } from "./land_plots/61/land_plot_1856.svg";
import { ReactComponent as LandPlot_61_1857 } from "./land_plots/61/land_plot_1857.svg";
import { ReactComponent as LandPlot_61_1858 } from "./land_plots/61/land_plot_1858.svg";
import { ReactComponent as LandPlot_61_1859 } from "./land_plots/61/land_plot_1859.svg";
import { ReactComponent as LandPlot_61_1860 } from "./land_plots/61/land_plot_1860.svg";
import { ReactComponent as LandPlot_61_1861 } from "./land_plots/61/land_plot_1861.svg";
import { ReactComponent as LandPlot_61_1862 } from "./land_plots/61/land_plot_1862.svg";
import { ReactComponent as LandPlot_61_1863 } from "./land_plots/61/land_plot_1863.svg";
import { ReactComponent as LandPlot_61_1864 } from "./land_plots/61/land_plot_1864.svg";
import { ReactComponent as LandPlot_61_1865 } from "./land_plots/61/land_plot_1865.svg";
import { ReactComponent as LandPlot_61_1866 } from "./land_plots/61/land_plot_1866.svg";
import { ReactComponent as LandPlot_61_1867 } from "./land_plots/61/land_plot_1867.svg";
import { ReactComponent as LandPlot_61_1868 } from "./land_plots/61/land_plot_1868.svg";
import { ReactComponent as LandPlot_61_1869 } from "./land_plots/61/land_plot_1869.svg";
import { ReactComponent as LandPlot_61_1870 } from "./land_plots/61/land_plot_1870.svg";
import { ReactComponent as LandPlot_61_1871 } from "./land_plots/61/land_plot_1871.svg";
import { ReactComponent as LandPlot_61_1872 } from "./land_plots/61/land_plot_1872.svg";
import { ReactComponent as LandPlot_61_1873 } from "./land_plots/61/land_plot_1873.svg";
import { ReactComponent as LandPlot_61_1874 } from "./land_plots/61/land_plot_1874.svg";
import { ReactComponent as LandPlot_61_1875 } from "./land_plots/61/land_plot_1875.svg";
import { ReactComponent as LandPlot_61_1876 } from "./land_plots/61/land_plot_1876.svg";
import { ReactComponent as LandPlot_61_1877 } from "./land_plots/61/land_plot_1877.svg";
import { ReactComponent as LandPlot_61_1878 } from "./land_plots/61/land_plot_1878.svg";
import { ReactComponent as LandPlot_61_1879 } from "./land_plots/61/land_plot_1879.svg";
import { ReactComponent as LandPlot_61_1880 } from "./land_plots/61/land_plot_1880.svg";
import { ReactComponent as LandPlot_61_1881 } from "./land_plots/61/land_plot_1881.svg";
import { ReactComponent as LandPlot_61_1882 } from "./land_plots/61/land_plot_1882.svg";
import { ReactComponent as LandPlot_61_1883 } from "./land_plots/61/land_plot_1883.svg";
import { ReactComponent as LandPlot_61_1884 } from "./land_plots/61/land_plot_1884.svg";
import { ReactComponent as LandPlot_61_1885 } from "./land_plots/61/land_plot_1885.svg";
import { ReactComponent as LandPlot_61_1886 } from "./land_plots/61/land_plot_1886.svg";
import { ReactComponent as LandPlot_61_1887 } from "./land_plots/61/land_plot_1887.svg";
import { ReactComponent as LandPlot_61_1888 } from "./land_plots/61/land_plot_1888.svg";
import { ReactComponent as LandPlot_61_1889 } from "./land_plots/61/land_plot_1889.svg";
import { ReactComponent as LandPlot_61_1890 } from "./land_plots/61/land_plot_1890.svg";
import { ReactComponent as LandPlot_61_1891 } from "./land_plots/61/land_plot_1891.svg";
import { ReactComponent as LandPlot_61_1892 } from "./land_plots/61/land_plot_1892.svg";
import { ReactComponent as LandPlot_61_1893 } from "./land_plots/61/land_plot_1893.svg";
import { ReactComponent as LandPlot_61_1894 } from "./land_plots/61/land_plot_1894.svg";
import { ReactComponent as LandPlot_61_1895 } from "./land_plots/61/land_plot_1895.svg";
import { ReactComponent as LandPlot_61_1896 } from "./land_plots/61/land_plot_1896.svg";
import { ReactComponent as LandPlot_61_1897 } from "./land_plots/61/land_plot_1897.svg";
import { ReactComponent as LandPlot_61_1898 } from "./land_plots/61/land_plot_1898.svg";
import { ReactComponent as LandPlot_61_1899 } from "./land_plots/61/land_plot_1899.svg";
import { ReactComponent as LandPlot_61_1900 } from "./land_plots/61/land_plot_1900.svg";
import { ReactComponent as LandPlot_61_1901 } from "./land_plots/61/land_plot_1901.svg";
import { ReactComponent as LandPlot_61_1902 } from "./land_plots/61/land_plot_1902.svg";
import { ReactComponent as LandPlot_61_1903 } from "./land_plots/61/land_plot_1903.svg";
import { ReactComponent as LandPlot_61_1904 } from "./land_plots/61/land_plot_1904.svg";
import { ReactComponent as LandPlot_61_1905 } from "./land_plots/61/land_plot_1905.svg";
import { ReactComponent as LandPlot_61_1906 } from "./land_plots/61/land_plot_1906.svg";
import { ReactComponent as LandPlot_61_1907 } from "./land_plots/61/land_plot_1907.svg";
import { ReactComponent as LandPlot_61_1908 } from "./land_plots/61/land_plot_1908.svg";
import { ReactComponent as LandPlot_61_1909 } from "./land_plots/61/land_plot_1909.svg";
import { ReactComponent as LandPlot_61_1910 } from "./land_plots/61/land_plot_1910.svg";
import { ReactComponent as LandPlot_61_1911 } from "./land_plots/61/land_plot_1911.svg";
import { ReactComponent as LandPlot_61_1912 } from "./land_plots/61/land_plot_1912.svg";
import { ReactComponent as LandPlot_61_1913 } from "./land_plots/61/land_plot_1913.svg";
import { ReactComponent as LandPlot_61_1914 } from "./land_plots/61/land_plot_1914.svg";
import { ReactComponent as LandPlot_61_1915 } from "./land_plots/61/land_plot_1915.svg";
import { ReactComponent as LandPlot_61_1916 } from "./land_plots/61/land_plot_1916.svg";
import { ReactComponent as LandPlot_61_1917 } from "./land_plots/61/land_plot_1917.svg";
import { ReactComponent as LandPlot_61_1918 } from "./land_plots/61/land_plot_1918.svg";
import { ReactComponent as LandPlot_61_1919 } from "./land_plots/61/land_plot_1919.svg";
import { ReactComponent as LandPlot_61_1920 } from "./land_plots/61/land_plot_1920.svg";
import { ReactComponent as LandPlot_61_1921 } from "./land_plots/61/land_plot_1921.svg";
import { ReactComponent as LandPlot_61_1922 } from "./land_plots/61/land_plot_1922.svg";
import { ReactComponent as LandPlot_61_1923 } from "./land_plots/61/land_plot_1923.svg";
import { ReactComponent as LandPlot_61_1924 } from "./land_plots/61/land_plot_1924.svg";
import { ReactComponent as LandPlot_61_1925 } from "./land_plots/61/land_plot_1925.svg";
import { ReactComponent as LandPlot_61_1926 } from "./land_plots/61/land_plot_1926.svg";
import { ReactComponent as LandPlot_61_1927 } from "./land_plots/61/land_plot_1927.svg";
import { ReactComponent as LandPlot_61_1928 } from "./land_plots/61/land_plot_1928.svg";
import { ReactComponent as LandPlot_61_1929 } from "./land_plots/61/land_plot_1929.svg";
import { ReactComponent as LandPlot_61_1930 } from "./land_plots/61/land_plot_1930.svg";
import { ReactComponent as LandPlot_61_1931 } from "./land_plots/61/land_plot_1931.svg";
import { ReactComponent as LandPlot_61_1932 } from "./land_plots/61/land_plot_1932.svg";
import { ReactComponent as LandPlot_61_1933 } from "./land_plots/61/land_plot_1933.svg";
import { ReactComponent as LandPlot_61_1934 } from "./land_plots/61/land_plot_1934.svg";
import { ReactComponent as LandPlot_61_1935 } from "./land_plots/61/land_plot_1935.svg";
import { ReactComponent as LandPlot_61_1936 } from "./land_plots/61/land_plot_1936.svg";
import { ReactComponent as LandPlot_61_1937 } from "./land_plots/61/land_plot_1937.svg";
import { ReactComponent as LandPlot_61_1938 } from "./land_plots/61/land_plot_1938.svg";
import { ReactComponent as LandPlot_61_1939 } from "./land_plots/61/land_plot_1939.svg";
import { ReactComponent as LandPlot_61_1940 } from "./land_plots/61/land_plot_1940.svg";
import { ReactComponent as LandPlot_61_1941 } from "./land_plots/61/land_plot_1941.svg";
import { ReactComponent as LandPlot_61_1942 } from "./land_plots/61/land_plot_1942.svg";
import { ReactComponent as LandPlot_61_1943 } from "./land_plots/61/land_plot_1943.svg";
import { ReactComponent as LandPlot_61_1944 } from "./land_plots/61/land_plot_1944.svg";
import { ReactComponent as LandPlot_61_1945 } from "./land_plots/61/land_plot_1945.svg";
import { ReactComponent as LandPlot_61_1946 } from "./land_plots/61/land_plot_1946.svg";
import { ReactComponent as LandPlot_61_1947 } from "./land_plots/61/land_plot_1947.svg";
import { ReactComponent as LandPlot_61_1948 } from "./land_plots/61/land_plot_1948.svg";
import { ReactComponent as LandPlot_61_1949 } from "./land_plots/61/land_plot_1949.svg";
import { ReactComponent as LandPlot_61_1950 } from "./land_plots/61/land_plot_1950.svg";
import { ReactComponent as LandPlot_61_1951 } from "./land_plots/61/land_plot_1951.svg";
import { ReactComponent as LandPlot_61_1952 } from "./land_plots/61/land_plot_1952.svg";
import { ReactComponent as LandPlot_61_1953 } from "./land_plots/61/land_plot_1953.svg";
import { ReactComponent as LandPlot_61_1954 } from "./land_plots/61/land_plot_1954.svg";
import { ReactComponent as LandPlot_61_1955 } from "./land_plots/61/land_plot_1955.svg";
import { ReactComponent as LandPlot_61_1956 } from "./land_plots/61/land_plot_1956.svg";
import { ReactComponent as LandPlot_61_1957 } from "./land_plots/61/land_plot_1957.svg";
import { ReactComponent as LandPlot_61_1958 } from "./land_plots/61/land_plot_1958.svg";
import { ReactComponent as LandPlot_61_1959 } from "./land_plots/61/land_plot_1959.svg";
import { ReactComponent as LandPlot_61_1960 } from "./land_plots/61/land_plot_1960.svg";
import { ReactComponent as LandPlot_61_1961 } from "./land_plots/61/land_plot_1961.svg";
import { ReactComponent as LandPlot_61_1962 } from "./land_plots/61/land_plot_1962.svg";
import { ReactComponent as LandPlot_61_1963 } from "./land_plots/61/land_plot_1963.svg";
import { ReactComponent as LandPlot_61_1964 } from "./land_plots/61/land_plot_1964.svg";
import { ReactComponent as LandPlot_61_1965 } from "./land_plots/61/land_plot_1965.svg";
import { ReactComponent as LandPlot_61_1966 } from "./land_plots/61/land_plot_1966.svg";
import { ReactComponent as LandPlot_61_1967 } from "./land_plots/61/land_plot_1967.svg";
import { ReactComponent as LandPlot_61_1968 } from "./land_plots/61/land_plot_1968.svg";
import { ReactComponent as LandPlot_61_1969 } from "./land_plots/61/land_plot_1969.svg";
import { ReactComponent as LandPlot_61_1970 } from "./land_plots/61/land_plot_1970.svg";
import { ReactComponent as LandPlot_61_1971 } from "./land_plots/61/land_plot_1971.svg";
import { ReactComponent as LandPlot_61_1972 } from "./land_plots/61/land_plot_1972.svg";
import { ReactComponent as LandPlot_61_1973 } from "./land_plots/61/land_plot_1973.svg";
import { ReactComponent as LandPlot_61_1974 } from "./land_plots/61/land_plot_1974.svg";
import { ReactComponent as LandPlot_61_1975 } from "./land_plots/61/land_plot_1975.svg";
import { ReactComponent as LandPlot_61_1976 } from "./land_plots/61/land_plot_1976.svg";
import { ReactComponent as LandPlot_61_1977 } from "./land_plots/61/land_plot_1977.svg";
import { ReactComponent as LandPlot_61_1978 } from "./land_plots/61/land_plot_1978.svg";
import { ReactComponent as LandPlot_61_1979 } from "./land_plots/61/land_plot_1979.svg";
import { ReactComponent as LandPlot_61_1980 } from "./land_plots/61/land_plot_1980.svg";
import { ReactComponent as LandPlot_61_1981 } from "./land_plots/61/land_plot_1981.svg";
import { ReactComponent as LandPlot_61_1982 } from "./land_plots/61/land_plot_1982.svg";
import { ReactComponent as LandPlot_61_1983 } from "./land_plots/61/land_plot_1983.svg";
import { ReactComponent as LandPlot_61_1984 } from "./land_plots/61/land_plot_1984.svg";
import { ReactComponent as LandPlot_61_1985 } from "./land_plots/61/land_plot_1985.svg";
import { ReactComponent as LandPlot_61_1986 } from "./land_plots/61/land_plot_1986.svg";
import { ReactComponent as LandPlot_61_1987 } from "./land_plots/61/land_plot_1987.svg";
import { ReactComponent as LandPlot_61_1988 } from "./land_plots/61/land_plot_1988.svg";
import { ReactComponent as LandPlot_61_1989 } from "./land_plots/61/land_plot_1989.svg";
import { ReactComponent as LandPlot_61_1990 } from "./land_plots/61/land_plot_1990.svg";
import { ReactComponent as LandPlot_61_1991 } from "./land_plots/61/land_plot_1991.svg";
import { ReactComponent as LandPlot_61_1992 } from "./land_plots/61/land_plot_1992.svg";
import { ReactComponent as LandPlot_61_1993 } from "./land_plots/61/land_plot_1993.svg";
import { ReactComponent as LandPlot_61_1994 } from "./land_plots/61/land_plot_1994.svg";
import { ReactComponent as LandPlot_61_1995 } from "./land_plots/61/land_plot_1995.svg";
import { ReactComponent as LandPlot_61_1996 } from "./land_plots/61/land_plot_1996.svg";
import { ReactComponent as LandPlot_61_1997 } from "./land_plots/61/land_plot_1997.svg";
import { ReactComponent as LandPlot_61_1998 } from "./land_plots/61/land_plot_1998.svg";
import { ReactComponent as LandPlot_61_1999 } from "./land_plots/61/land_plot_1999.svg";
import { ReactComponent as LandPlot_61_2000 } from "./land_plots/61/land_plot_2000.svg";
import { ReactComponent as LandPlot_61_2001 } from "./land_plots/61/land_plot_2001.svg";
import { ReactComponent as LandPlot_61_2002 } from "./land_plots/61/land_plot_2002.svg";
import { ReactComponent as LandPlot_61_2003 } from "./land_plots/61/land_plot_2003.svg";
import { ReactComponent as LandPlot_61_2004 } from "./land_plots/61/land_plot_2004.svg";
import { ReactComponent as LandPlot_61_2005 } from "./land_plots/61/land_plot_2005.svg";
import { ReactComponent as LandPlot_61_2006 } from "./land_plots/61/land_plot_2006.svg";
import { ReactComponent as LandPlot_61_2007 } from "./land_plots/61/land_plot_2007.svg";
import { ReactComponent as LandPlot_61_2008 } from "./land_plots/61/land_plot_2008.svg";
import { ReactComponent as LandPlot_61_2009 } from "./land_plots/61/land_plot_2009.svg";
import { ReactComponent as LandPlot_61_2010 } from "./land_plots/61/land_plot_2010.svg";
import { ReactComponent as LandPlot_61_2011 } from "./land_plots/61/land_plot_2011.svg";
import { ReactComponent as LandPlot_61_2012 } from "./land_plots/61/land_plot_2012.svg";
import { ReactComponent as LandPlot_61_2013 } from "./land_plots/61/land_plot_2013.svg";
import { ReactComponent as LandPlot_61_2014 } from "./land_plots/61/land_plot_2014.svg";
import { ReactComponent as LandPlot_61_2015 } from "./land_plots/61/land_plot_2015.svg";
import { ReactComponent as LandPlot_61_2016 } from "./land_plots/61/land_plot_2016.svg";
import { ReactComponent as LandPlot_61_2017 } from "./land_plots/61/land_plot_2017.svg";
import { ReactComponent as LandPlot_61_2018 } from "./land_plots/61/land_plot_2018.svg";
import { ReactComponent as LandPlot_61_2019 } from "./land_plots/61/land_plot_2019.svg";
import { ReactComponent as LandPlot_61_2020 } from "./land_plots/61/land_plot_2020.svg";
import { ReactComponent as LandPlot_61_2021 } from "./land_plots/61/land_plot_2021.svg";
import { ReactComponent as LandPlot_61_2022 } from "./land_plots/61/land_plot_2022.svg";
import { ReactComponent as LandPlot_61_2023 } from "./land_plots/61/land_plot_2023.svg";
import { ReactComponent as LandPlot_61_2024 } from "./land_plots/61/land_plot_2024.svg";
import { ReactComponent as LandPlot_61_2025 } from "./land_plots/61/land_plot_2025.svg";
import { ReactComponent as LandPlot_61_2026 } from "./land_plots/61/land_plot_2026.svg";
import { ReactComponent as LandPlot_61_2027 } from "./land_plots/61/land_plot_2027.svg";
import { ReactComponent as LandPlot_61_2028 } from "./land_plots/61/land_plot_2028.svg";
import { ReactComponent as LandPlot_61_2029 } from "./land_plots/61/land_plot_2029.svg";
import { ReactComponent as LandPlot_61_2030 } from "./land_plots/61/land_plot_2030.svg";
import { ReactComponent as LandPlot_61_2031 } from "./land_plots/61/land_plot_2031.svg";
import { ReactComponent as LandPlot_61_2032 } from "./land_plots/61/land_plot_2032.svg";
import { ReactComponent as LandPlot_61_2033 } from "./land_plots/61/land_plot_2033.svg";
import { ReactComponent as LandPlot_61_2034 } from "./land_plots/61/land_plot_2034.svg";
import { ReactComponent as LandPlot_61_2035 } from "./land_plots/61/land_plot_2035.svg";
import { ReactComponent as LandPlot_61_2036 } from "./land_plots/61/land_plot_2036.svg";
import { ReactComponent as LandPlot_61_2037 } from "./land_plots/61/land_plot_2037.svg";
import { ReactComponent as LandPlot_61_2038 } from "./land_plots/61/land_plot_2038.svg";
import { ReactComponent as LandPlot_61_2039 } from "./land_plots/61/land_plot_2039.svg";
import { ReactComponent as LandPlot_61_2040 } from "./land_plots/61/land_plot_2040.svg";
import { ReactComponent as LandPlot_61_2041 } from "./land_plots/61/land_plot_2041.svg";
import { ReactComponent as LandPlot_61_2042 } from "./land_plots/61/land_plot_2042.svg";
import { ReactComponent as LandPlot_61_2043 } from "./land_plots/61/land_plot_2043.svg";
import { ReactComponent as LandPlot_61_2044 } from "./land_plots/61/land_plot_2044.svg";
import { ReactComponent as LandPlot_61_2045 } from "./land_plots/61/land_plot_2045.svg";
import { ReactComponent as LandPlot_61_2046 } from "./land_plots/61/land_plot_2046.svg";
import { ReactComponent as LandPlot_61_2047 } from "./land_plots/61/land_plot_2047.svg";
import { ReactComponent as LandPlot_61_2048 } from "./land_plots/61/land_plot_2048.svg";
import { ReactComponent as LandPlot_61_2049 } from "./land_plots/61/land_plot_2049.svg";
import { ReactComponent as LandPlot_61_2050 } from "./land_plots/61/land_plot_2050.svg";
import { ReactComponent as LandPlot_61_2051 } from "./land_plots/61/land_plot_2051.svg";
import { ReactComponent as LandPlot_61_2052 } from "./land_plots/61/land_plot_2052.svg";

///////////////////////////////////////////////////////////////////////////////////stop frame 90
import { ReactComponent as LandPlot_90_1751 } from "./land_plots/90/land_plot_1751.svg";
import { ReactComponent as LandPlot_90_1752 } from "./land_plots/90/land_plot_1752.svg";
import { ReactComponent as LandPlot_90_1753 } from "./land_plots/90/land_plot_1753.svg";
import { ReactComponent as LandPlot_90_1754 } from "./land_plots/90/land_plot_1754.svg";
import { ReactComponent as LandPlot_90_1755 } from "./land_plots/90/land_plot_1755.svg";
import { ReactComponent as LandPlot_90_1756 } from "./land_plots/90/land_plot_1756.svg";
import { ReactComponent as LandPlot_90_1757 } from "./land_plots/90/land_plot_1757.svg";
import { ReactComponent as LandPlot_90_1758 } from "./land_plots/90/land_plot_1758.svg";
import { ReactComponent as LandPlot_90_1759 } from "./land_plots/90/land_plot_1759.svg";
import { ReactComponent as LandPlot_90_1760 } from "./land_plots/90/land_plot_1760.svg";
import { ReactComponent as LandPlot_90_1761 } from "./land_plots/90/land_plot_1761.svg";
import { ReactComponent as LandPlot_90_1762 } from "./land_plots/90/land_plot_1762.svg";
import { ReactComponent as LandPlot_90_1763 } from "./land_plots/90/land_plot_1763.svg";
import { ReactComponent as LandPlot_90_1764 } from "./land_plots/90/land_plot_1764.svg";
import { ReactComponent as LandPlot_90_1765 } from "./land_plots/90/land_plot_1765.svg";
import { ReactComponent as LandPlot_90_1766 } from "./land_plots/90/land_plot_1766.svg";
import { ReactComponent as LandPlot_90_1767 } from "./land_plots/90/land_plot_1767.svg";
import { ReactComponent as LandPlot_90_1768 } from "./land_plots/90/land_plot_1768.svg";
import { ReactComponent as LandPlot_90_1769 } from "./land_plots/90/land_plot_1769.svg";
import { ReactComponent as LandPlot_90_1770 } from "./land_plots/90/land_plot_1770.svg";
import { ReactComponent as LandPlot_90_1771 } from "./land_plots/90/land_plot_1771.svg";
import { ReactComponent as LandPlot_90_1772 } from "./land_plots/90/land_plot_1772.svg";
import { ReactComponent as LandPlot_90_1773 } from "./land_plots/90/land_plot_1773.svg";
import { ReactComponent as LandPlot_90_1774 } from "./land_plots/90/land_plot_1774.svg";
import { ReactComponent as LandPlot_90_1775 } from "./land_plots/90/land_plot_1775.svg";
import { ReactComponent as LandPlot_90_1776 } from "./land_plots/90/land_plot_1776.svg";
import { ReactComponent as LandPlot_90_1777 } from "./land_plots/90/land_plot_1777.svg";
import { ReactComponent as LandPlot_90_1778 } from "./land_plots/90/land_plot_1778.svg";
import { ReactComponent as LandPlot_90_1779 } from "./land_plots/90/land_plot_1779.svg";
import { ReactComponent as LandPlot_90_1780 } from "./land_plots/90/land_plot_1780.svg";
import { ReactComponent as LandPlot_90_1781 } from "./land_plots/90/land_plot_1781.svg";
import { ReactComponent as LandPlot_90_1782 } from "./land_plots/90/land_plot_1782.svg";
import { ReactComponent as LandPlot_90_1783 } from "./land_plots/90/land_plot_1783.svg";
import { ReactComponent as LandPlot_90_1784 } from "./land_plots/90/land_plot_1784.svg";
import { ReactComponent as LandPlot_90_1785 } from "./land_plots/90/land_plot_1785.svg";
import { ReactComponent as LandPlot_90_1786 } from "./land_plots/90/land_plot_1786.svg";
import { ReactComponent as LandPlot_90_1787 } from "./land_plots/90/land_plot_1787.svg";
import { ReactComponent as LandPlot_90_1788 } from "./land_plots/90/land_plot_1788.svg";
import { ReactComponent as LandPlot_90_1789 } from "./land_plots/90/land_plot_1789.svg";
import { ReactComponent as LandPlot_90_1790 } from "./land_plots/90/land_plot_1790.svg";
import { ReactComponent as LandPlot_90_1791 } from "./land_plots/90/land_plot_1791.svg";
import { ReactComponent as LandPlot_90_1792 } from "./land_plots/90/land_plot_1792.svg";
import { ReactComponent as LandPlot_90_1793 } from "./land_plots/90/land_plot_1793.svg";
import { ReactComponent as LandPlot_90_1794 } from "./land_plots/90/land_plot_1794.svg";
import { ReactComponent as LandPlot_90_1795 } from "./land_plots/90/land_plot_1795.svg";
import { ReactComponent as LandPlot_90_1796 } from "./land_plots/90/land_plot_1796.svg";
import { ReactComponent as LandPlot_90_1797 } from "./land_plots/90/land_plot_1797.svg";
import { ReactComponent as LandPlot_90_1798 } from "./land_plots/90/land_plot_1798.svg";
import { ReactComponent as LandPlot_90_1799 } from "./land_plots/90/land_plot_1799.svg";
import { ReactComponent as LandPlot_90_1800 } from "./land_plots/90/land_plot_1800.svg";
import { ReactComponent as LandPlot_90_1801 } from "./land_plots/90/land_plot_1801.svg";
import { ReactComponent as LandPlot_90_1802 } from "./land_plots/90/land_plot_1802.svg";
import { ReactComponent as LandPlot_90_1803 } from "./land_plots/90/land_plot_1803.svg";
import { ReactComponent as LandPlot_90_1804 } from "./land_plots/90/land_plot_1804.svg";
import { ReactComponent as LandPlot_90_1805 } from "./land_plots/90/land_plot_1805.svg";
import { ReactComponent as LandPlot_90_1806 } from "./land_plots/90/land_plot_1806.svg";
import { ReactComponent as LandPlot_90_1807 } from "./land_plots/90/land_plot_1807.svg";
import { ReactComponent as LandPlot_90_1808 } from "./land_plots/90/land_plot_1808.svg";
import { ReactComponent as LandPlot_90_1809 } from "./land_plots/90/land_plot_1809.svg";
import { ReactComponent as LandPlot_90_1810 } from "./land_plots/90/land_plot_1810.svg";
import { ReactComponent as LandPlot_90_1811 } from "./land_plots/90/land_plot_1811.svg";
import { ReactComponent as LandPlot_90_1812 } from "./land_plots/90/land_plot_1812.svg";
import { ReactComponent as LandPlot_90_1813 } from "./land_plots/90/land_plot_1813.svg";
import { ReactComponent as LandPlot_90_1814 } from "./land_plots/90/land_plot_1814.svg";
import { ReactComponent as LandPlot_90_1815 } from "./land_plots/90/land_plot_1815.svg";
import { ReactComponent as LandPlot_90_1816 } from "./land_plots/90/land_plot_1816.svg";
import { ReactComponent as LandPlot_90_1817 } from "./land_plots/90/land_plot_1817.svg";
import { ReactComponent as LandPlot_90_1818 } from "./land_plots/90/land_plot_1818.svg";
import { ReactComponent as LandPlot_90_1819 } from "./land_plots/90/land_plot_1819.svg";
import { ReactComponent as LandPlot_90_1820 } from "./land_plots/90/land_plot_1820.svg";
import { ReactComponent as LandPlot_90_1821 } from "./land_plots/90/land_plot_1821.svg";
import { ReactComponent as LandPlot_90_1822 } from "./land_plots/90/land_plot_1822.svg";
import { ReactComponent as LandPlot_90_1823 } from "./land_plots/90/land_plot_1823.svg";
import { ReactComponent as LandPlot_90_1824 } from "./land_plots/90/land_plot_1824.svg";
import { ReactComponent as LandPlot_90_1825 } from "./land_plots/90/land_plot_1825.svg";
import { ReactComponent as LandPlot_90_1826 } from "./land_plots/90/land_plot_1826.svg";
import { ReactComponent as LandPlot_90_1827 } from "./land_plots/90/land_plot_1827.svg";
import { ReactComponent as LandPlot_90_1828 } from "./land_plots/90/land_plot_1828.svg";
import { ReactComponent as LandPlot_90_1829 } from "./land_plots/90/land_plot_1829.svg";
import { ReactComponent as LandPlot_90_1830 } from "./land_plots/90/land_plot_1830.svg";
import { ReactComponent as LandPlot_90_1831 } from "./land_plots/90/land_plot_1831.svg";
import { ReactComponent as LandPlot_90_1832 } from "./land_plots/90/land_plot_1832.svg";
import { ReactComponent as LandPlot_90_1833 } from "./land_plots/90/land_plot_1833.svg";
import { ReactComponent as LandPlot_90_1834 } from "./land_plots/90/land_plot_1834.svg";
import { ReactComponent as LandPlot_90_1835 } from "./land_plots/90/land_plot_1835.svg";
import { ReactComponent as LandPlot_90_1836 } from "./land_plots/90/land_plot_1836.svg";
import { ReactComponent as LandPlot_90_1837 } from "./land_plots/90/land_plot_1837.svg";
import { ReactComponent as LandPlot_90_1838 } from "./land_plots/90/land_plot_1838.svg";
import { ReactComponent as LandPlot_90_1839 } from "./land_plots/90/land_plot_1839.svg";
import { ReactComponent as LandPlot_90_1840 } from "./land_plots/90/land_plot_1840.svg";
import { ReactComponent as LandPlot_90_1841 } from "./land_plots/90/land_plot_1841.svg";
import { ReactComponent as LandPlot_90_1842 } from "./land_plots/90/land_plot_1842.svg";
import { ReactComponent as LandPlot_90_1843 } from "./land_plots/90/land_plot_1843.svg";
import { ReactComponent as LandPlot_90_1844 } from "./land_plots/90/land_plot_1844.svg";
import { ReactComponent as LandPlot_90_1845 } from "./land_plots/90/land_plot_1845.svg";
import { ReactComponent as LandPlot_90_1846 } from "./land_plots/90/land_plot_1846.svg";
import { ReactComponent as LandPlot_90_1847 } from "./land_plots/90/land_plot_1847.svg";
import { ReactComponent as LandPlot_90_1848 } from "./land_plots/90/land_plot_1848.svg";
import { ReactComponent as LandPlot_90_1849 } from "./land_plots/90/land_plot_1849.svg";
import { ReactComponent as LandPlot_90_1850 } from "./land_plots/90/land_plot_1850.svg";
import { ReactComponent as LandPlot_90_1851 } from "./land_plots/90/land_plot_1851.svg";
import { ReactComponent as LandPlot_90_1852 } from "./land_plots/90/land_plot_1852.svg";
import { ReactComponent as LandPlot_90_1853 } from "./land_plots/90/land_plot_1853.svg";
import { ReactComponent as LandPlot_90_1854 } from "./land_plots/90/land_plot_1854.svg";
import { ReactComponent as LandPlot_90_1855 } from "./land_plots/90/land_plot_1855.svg";
import { ReactComponent as LandPlot_90_1856 } from "./land_plots/90/land_plot_1856.svg";
import { ReactComponent as LandPlot_90_1857 } from "./land_plots/90/land_plot_1857.svg";
import { ReactComponent as LandPlot_90_1858 } from "./land_plots/90/land_plot_1858.svg";
import { ReactComponent as LandPlot_90_1859 } from "./land_plots/90/land_plot_1859.svg";
import { ReactComponent as LandPlot_90_1860 } from "./land_plots/90/land_plot_1860.svg";
import { ReactComponent as LandPlot_90_1861 } from "./land_plots/90/land_plot_1861.svg";
import { ReactComponent as LandPlot_90_1862 } from "./land_plots/90/land_plot_1862.svg";
import { ReactComponent as LandPlot_90_1863 } from "./land_plots/90/land_plot_1863.svg";
import { ReactComponent as LandPlot_90_1864 } from "./land_plots/90/land_plot_1864.svg";
import { ReactComponent as LandPlot_90_1865 } from "./land_plots/90/land_plot_1865.svg";
import { ReactComponent as LandPlot_90_1866 } from "./land_plots/90/land_plot_1866.svg";
import { ReactComponent as LandPlot_90_1867 } from "./land_plots/90/land_plot_1867.svg";
import { ReactComponent as LandPlot_90_1868 } from "./land_plots/90/land_plot_1868.svg";
import { ReactComponent as LandPlot_90_1869 } from "./land_plots/90/land_plot_1869.svg";
import { ReactComponent as LandPlot_90_1870 } from "./land_plots/90/land_plot_1870.svg";
import { ReactComponent as LandPlot_90_1871 } from "./land_plots/90/land_plot_1871.svg";
import { ReactComponent as LandPlot_90_1872 } from "./land_plots/90/land_plot_1872.svg";
import { ReactComponent as LandPlot_90_1873 } from "./land_plots/90/land_plot_1873.svg";
import { ReactComponent as LandPlot_90_1874 } from "./land_plots/90/land_plot_1874.svg";
import { ReactComponent as LandPlot_90_1875 } from "./land_plots/90/land_plot_1875.svg";
import { ReactComponent as LandPlot_90_1876 } from "./land_plots/90/land_plot_1876.svg";
import { ReactComponent as LandPlot_90_1877 } from "./land_plots/90/land_plot_1877.svg";
import { ReactComponent as LandPlot_90_1878 } from "./land_plots/90/land_plot_1878.svg";
import { ReactComponent as LandPlot_90_1879 } from "./land_plots/90/land_plot_1879.svg";
import { ReactComponent as LandPlot_90_1880 } from "./land_plots/90/land_plot_1880.svg";
import { ReactComponent as LandPlot_90_1881 } from "./land_plots/90/land_plot_1881.svg";
import { ReactComponent as LandPlot_90_1882 } from "./land_plots/90/land_plot_1882.svg";
import { ReactComponent as LandPlot_90_1883 } from "./land_plots/90/land_plot_1883.svg";
import { ReactComponent as LandPlot_90_1884 } from "./land_plots/90/land_plot_1884.svg";
import { ReactComponent as LandPlot_90_1885 } from "./land_plots/90/land_plot_1885.svg";
import { ReactComponent as LandPlot_90_1886 } from "./land_plots/90/land_plot_1886.svg";
import { ReactComponent as LandPlot_90_1887 } from "./land_plots/90/land_plot_1887.svg";
import { ReactComponent as LandPlot_90_1888 } from "./land_plots/90/land_plot_1888.svg";
import { ReactComponent as LandPlot_90_1889 } from "./land_plots/90/land_plot_1889.svg";
import { ReactComponent as LandPlot_90_1890 } from "./land_plots/90/land_plot_1890.svg";
import { ReactComponent as LandPlot_90_1891 } from "./land_plots/90/land_plot_1891.svg";
import { ReactComponent as LandPlot_90_1892 } from "./land_plots/90/land_plot_1892.svg";
import { ReactComponent as LandPlot_90_1893 } from "./land_plots/90/land_plot_1893.svg";
import { ReactComponent as LandPlot_90_1894 } from "./land_plots/90/land_plot_1894.svg";
import { ReactComponent as LandPlot_90_1895 } from "./land_plots/90/land_plot_1895.svg";
import { ReactComponent as LandPlot_90_1896 } from "./land_plots/90/land_plot_1896.svg";
import { ReactComponent as LandPlot_90_1897 } from "./land_plots/90/land_plot_1897.svg";
import { ReactComponent as LandPlot_90_1898 } from "./land_plots/90/land_plot_1898.svg";
import { ReactComponent as LandPlot_90_1899 } from "./land_plots/90/land_plot_1899.svg";
import { ReactComponent as LandPlot_90_1900 } from "./land_plots/90/land_plot_1900.svg";
import { ReactComponent as LandPlot_90_1901 } from "./land_plots/90/land_plot_1901.svg";
import { ReactComponent as LandPlot_90_1902 } from "./land_plots/90/land_plot_1902.svg";
import { ReactComponent as LandPlot_90_1903 } from "./land_plots/90/land_plot_1903.svg";
import { ReactComponent as LandPlot_90_1904 } from "./land_plots/90/land_plot_1904.svg";
import { ReactComponent as LandPlot_90_1905 } from "./land_plots/90/land_plot_1905.svg";
import { ReactComponent as LandPlot_90_1906 } from "./land_plots/90/land_plot_1906.svg";
import { ReactComponent as LandPlot_90_1907 } from "./land_plots/90/land_plot_1907.svg";
import { ReactComponent as LandPlot_90_1908 } from "./land_plots/90/land_plot_1908.svg";
import { ReactComponent as LandPlot_90_1909 } from "./land_plots/90/land_plot_1909.svg";
import { ReactComponent as LandPlot_90_1910 } from "./land_plots/90/land_plot_1910.svg";
import { ReactComponent as LandPlot_90_1911 } from "./land_plots/90/land_plot_1911.svg";
import { ReactComponent as LandPlot_90_1912 } from "./land_plots/90/land_plot_1912.svg";
import { ReactComponent as LandPlot_90_1913 } from "./land_plots/90/land_plot_1913.svg";
import { ReactComponent as LandPlot_90_1914 } from "./land_plots/90/land_plot_1914.svg";
import { ReactComponent as LandPlot_90_1915 } from "./land_plots/90/land_plot_1915.svg";
import { ReactComponent as LandPlot_90_1916 } from "./land_plots/90/land_plot_1916.svg";
import { ReactComponent as LandPlot_90_1917 } from "./land_plots/90/land_plot_1917.svg";
import { ReactComponent as LandPlot_90_1918 } from "./land_plots/90/land_plot_1918.svg";
import { ReactComponent as LandPlot_90_1919 } from "./land_plots/90/land_plot_1919.svg";
import { ReactComponent as LandPlot_90_1920 } from "./land_plots/90/land_plot_1920.svg";
import { ReactComponent as LandPlot_90_1921 } from "./land_plots/90/land_plot_1921.svg";
import { ReactComponent as LandPlot_90_1922 } from "./land_plots/90/land_plot_1922.svg";
import { ReactComponent as LandPlot_90_1923 } from "./land_plots/90/land_plot_1923.svg";
import { ReactComponent as LandPlot_90_1924 } from "./land_plots/90/land_plot_1924.svg";
import { ReactComponent as LandPlot_90_1925 } from "./land_plots/90/land_plot_1925.svg";
import { ReactComponent as LandPlot_90_1926 } from "./land_plots/90/land_plot_1926.svg";
import { ReactComponent as LandPlot_90_1927 } from "./land_plots/90/land_plot_1927.svg";
import { ReactComponent as LandPlot_90_1928 } from "./land_plots/90/land_plot_1928.svg";
import { ReactComponent as LandPlot_90_1929 } from "./land_plots/90/land_plot_1929.svg";
import { ReactComponent as LandPlot_90_1930 } from "./land_plots/90/land_plot_1930.svg";
import { ReactComponent as LandPlot_90_1931 } from "./land_plots/90/land_plot_1931.svg";
import { ReactComponent as LandPlot_90_1932 } from "./land_plots/90/land_plot_1932.svg";
import { ReactComponent as LandPlot_90_1933 } from "./land_plots/90/land_plot_1933.svg";
import { ReactComponent as LandPlot_90_1934 } from "./land_plots/90/land_plot_1934.svg";
import { ReactComponent as LandPlot_90_1935 } from "./land_plots/90/land_plot_1935.svg";
import { ReactComponent as LandPlot_90_1936 } from "./land_plots/90/land_plot_1936.svg";
import { ReactComponent as LandPlot_90_1937 } from "./land_plots/90/land_plot_1937.svg";
import { ReactComponent as LandPlot_90_1938 } from "./land_plots/90/land_plot_1938.svg";
import { ReactComponent as LandPlot_90_1939 } from "./land_plots/90/land_plot_1939.svg";
import { ReactComponent as LandPlot_90_1940 } from "./land_plots/90/land_plot_1940.svg";
import { ReactComponent as LandPlot_90_1941 } from "./land_plots/90/land_plot_1941.svg";
import { ReactComponent as LandPlot_90_1942 } from "./land_plots/90/land_plot_1942.svg";
import { ReactComponent as LandPlot_90_1943 } from "./land_plots/90/land_plot_1943.svg";
import { ReactComponent as LandPlot_90_1944 } from "./land_plots/90/land_plot_1944.svg";
import { ReactComponent as LandPlot_90_1945 } from "./land_plots/90/land_plot_1945.svg";
import { ReactComponent as LandPlot_90_1946 } from "./land_plots/90/land_plot_1946.svg";
import { ReactComponent as LandPlot_90_1947 } from "./land_plots/90/land_plot_1947.svg";
import { ReactComponent as LandPlot_90_1948 } from "./land_plots/90/land_plot_1948.svg";
import { ReactComponent as LandPlot_90_1949 } from "./land_plots/90/land_plot_1949.svg";
import { ReactComponent as LandPlot_90_1950 } from "./land_plots/90/land_plot_1950.svg";
import { ReactComponent as LandPlot_90_1951 } from "./land_plots/90/land_plot_1951.svg";
import { ReactComponent as LandPlot_90_1952 } from "./land_plots/90/land_plot_1952.svg";
import { ReactComponent as LandPlot_90_1953 } from "./land_plots/90/land_plot_1953.svg";
import { ReactComponent as LandPlot_90_1954 } from "./land_plots/90/land_plot_1954.svg";
import { ReactComponent as LandPlot_90_1955 } from "./land_plots/90/land_plot_1955.svg";
import { ReactComponent as LandPlot_90_1956 } from "./land_plots/90/land_plot_1956.svg";
import { ReactComponent as LandPlot_90_1957 } from "./land_plots/90/land_plot_1957.svg";
import { ReactComponent as LandPlot_90_1958 } from "./land_plots/90/land_plot_1958.svg";
import { ReactComponent as LandPlot_90_1959 } from "./land_plots/90/land_plot_1959.svg";
import { ReactComponent as LandPlot_90_1960 } from "./land_plots/90/land_plot_1960.svg";
import { ReactComponent as LandPlot_90_1961 } from "./land_plots/90/land_plot_1961.svg";
import { ReactComponent as LandPlot_90_1962 } from "./land_plots/90/land_plot_1962.svg";
import { ReactComponent as LandPlot_90_1963 } from "./land_plots/90/land_plot_1963.svg";
import { ReactComponent as LandPlot_90_1964 } from "./land_plots/90/land_plot_1964.svg";
import { ReactComponent as LandPlot_90_1965 } from "./land_plots/90/land_plot_1965.svg";
import { ReactComponent as LandPlot_90_1966 } from "./land_plots/90/land_plot_1966.svg";
import { ReactComponent as LandPlot_90_1967 } from "./land_plots/90/land_plot_1967.svg";
import { ReactComponent as LandPlot_90_1968 } from "./land_plots/90/land_plot_1968.svg";
import { ReactComponent as LandPlot_90_1969 } from "./land_plots/90/land_plot_1969.svg";
import { ReactComponent as LandPlot_90_1970 } from "./land_plots/90/land_plot_1970.svg";
import { ReactComponent as LandPlot_90_1971 } from "./land_plots/90/land_plot_1971.svg";
import { ReactComponent as LandPlot_90_1972 } from "./land_plots/90/land_plot_1972.svg";
import { ReactComponent as LandPlot_90_1973 } from "./land_plots/90/land_plot_1973.svg";
import { ReactComponent as LandPlot_90_1974 } from "./land_plots/90/land_plot_1974.svg";
import { ReactComponent as LandPlot_90_1975 } from "./land_plots/90/land_plot_1975.svg";
import { ReactComponent as LandPlot_90_1976 } from "./land_plots/90/land_plot_1976.svg";
import { ReactComponent as LandPlot_90_1977 } from "./land_plots/90/land_plot_1977.svg";
import { ReactComponent as LandPlot_90_1978 } from "./land_plots/90/land_plot_1978.svg";
import { ReactComponent as LandPlot_90_1979 } from "./land_plots/90/land_plot_1979.svg";
import { ReactComponent as LandPlot_90_1980 } from "./land_plots/90/land_plot_1980.svg";
import { ReactComponent as LandPlot_90_1981 } from "./land_plots/90/land_plot_1981.svg";
import { ReactComponent as LandPlot_90_1982 } from "./land_plots/90/land_plot_1982.svg";
import { ReactComponent as LandPlot_90_1983 } from "./land_plots/90/land_plot_1983.svg";
import { ReactComponent as LandPlot_90_1984 } from "./land_plots/90/land_plot_1984.svg";
import { ReactComponent as LandPlot_90_1985 } from "./land_plots/90/land_plot_1985.svg";
import { ReactComponent as LandPlot_90_1986 } from "./land_plots/90/land_plot_1986.svg";
import { ReactComponent as LandPlot_90_1987 } from "./land_plots/90/land_plot_1987.svg";
import { ReactComponent as LandPlot_90_1988 } from "./land_plots/90/land_plot_1988.svg";
import { ReactComponent as LandPlot_90_1989 } from "./land_plots/90/land_plot_1989.svg";
import { ReactComponent as LandPlot_90_1990 } from "./land_plots/90/land_plot_1990.svg";
import { ReactComponent as LandPlot_90_1991 } from "./land_plots/90/land_plot_1991.svg";
import { ReactComponent as LandPlot_90_1992 } from "./land_plots/90/land_plot_1992.svg";
import { ReactComponent as LandPlot_90_1993 } from "./land_plots/90/land_plot_1993.svg";
import { ReactComponent as LandPlot_90_1994 } from "./land_plots/90/land_plot_1994.svg";
import { ReactComponent as LandPlot_90_1995 } from "./land_plots/90/land_plot_1995.svg";
import { ReactComponent as LandPlot_90_1996 } from "./land_plots/90/land_plot_1996.svg";
import { ReactComponent as LandPlot_90_1997 } from "./land_plots/90/land_plot_1997.svg";
import { ReactComponent as LandPlot_90_1998 } from "./land_plots/90/land_plot_1998.svg";
import { ReactComponent as LandPlot_90_1999 } from "./land_plots/90/land_plot_1999.svg";
import { ReactComponent as LandPlot_90_2000 } from "./land_plots/90/land_plot_2000.svg";
import { ReactComponent as LandPlot_90_2001 } from "./land_plots/90/land_plot_2001.svg";
import { ReactComponent as LandPlot_90_2002 } from "./land_plots/90/land_plot_2002.svg";
import { ReactComponent as LandPlot_90_2003 } from "./land_plots/90/land_plot_2003.svg";
import { ReactComponent as LandPlot_90_2004 } from "./land_plots/90/land_plot_2004.svg";
import { ReactComponent as LandPlot_90_2005 } from "./land_plots/90/land_plot_2005.svg";
import { ReactComponent as LandPlot_90_2006 } from "./land_plots/90/land_plot_2006.svg";
import { ReactComponent as LandPlot_90_2007 } from "./land_plots/90/land_plot_2007.svg";
import { ReactComponent as LandPlot_90_2008 } from "./land_plots/90/land_plot_2008.svg";
import { ReactComponent as LandPlot_90_2009 } from "./land_plots/90/land_plot_2009.svg";
import { ReactComponent as LandPlot_90_2010 } from "./land_plots/90/land_plot_2010.svg";
import { ReactComponent as LandPlot_90_2011 } from "./land_plots/90/land_plot_2011.svg";
import { ReactComponent as LandPlot_90_2012 } from "./land_plots/90/land_plot_2012.svg";
import { ReactComponent as LandPlot_90_2013 } from "./land_plots/90/land_plot_2013.svg";
import { ReactComponent as LandPlot_90_2014 } from "./land_plots/90/land_plot_2014.svg";
import { ReactComponent as LandPlot_90_2015 } from "./land_plots/90/land_plot_2015.svg";
import { ReactComponent as LandPlot_90_2016 } from "./land_plots/90/land_plot_2016.svg";
import { ReactComponent as LandPlot_90_2017 } from "./land_plots/90/land_plot_2017.svg";
import { ReactComponent as LandPlot_90_2018 } from "./land_plots/90/land_plot_2018.svg";
import { ReactComponent as LandPlot_90_2019 } from "./land_plots/90/land_plot_2019.svg";
import { ReactComponent as LandPlot_90_2020 } from "./land_plots/90/land_plot_2020.svg";
import { ReactComponent as LandPlot_90_2021 } from "./land_plots/90/land_plot_2021.svg";
import { ReactComponent as LandPlot_90_2022 } from "./land_plots/90/land_plot_2022.svg";
import { ReactComponent as LandPlot_90_2023 } from "./land_plots/90/land_plot_2023.svg";
import { ReactComponent as LandPlot_90_2024 } from "./land_plots/90/land_plot_2024.svg";
import { ReactComponent as LandPlot_90_2025 } from "./land_plots/90/land_plot_2025.svg";
import { ReactComponent as LandPlot_90_2026 } from "./land_plots/90/land_plot_2026.svg";
import { ReactComponent as LandPlot_90_2027 } from "./land_plots/90/land_plot_2027.svg";
import { ReactComponent as LandPlot_90_2028 } from "./land_plots/90/land_plot_2028.svg";
import { ReactComponent as LandPlot_90_2029 } from "./land_plots/90/land_plot_2029.svg";
import { ReactComponent as LandPlot_90_2030 } from "./land_plots/90/land_plot_2030.svg";
import { ReactComponent as LandPlot_90_2031 } from "./land_plots/90/land_plot_2031.svg";
import { ReactComponent as LandPlot_90_2032 } from "./land_plots/90/land_plot_2032.svg";
import { ReactComponent as LandPlot_90_2033 } from "./land_plots/90/land_plot_2033.svg";
import { ReactComponent as LandPlot_90_2034 } from "./land_plots/90/land_plot_2034.svg";
import { ReactComponent as LandPlot_90_2035 } from "./land_plots/90/land_plot_2035.svg";
import { ReactComponent as LandPlot_90_2036 } from "./land_plots/90/land_plot_2036.svg";
import { ReactComponent as LandPlot_90_2037 } from "./land_plots/90/land_plot_2037.svg";
import { ReactComponent as LandPlot_90_2038 } from "./land_plots/90/land_plot_2038.svg";
import { ReactComponent as LandPlot_90_2039 } from "./land_plots/90/land_plot_2039.svg";
import { ReactComponent as LandPlot_90_2040 } from "./land_plots/90/land_plot_2040.svg";
import { ReactComponent as LandPlot_90_2041 } from "./land_plots/90/land_plot_2041.svg";
import { ReactComponent as LandPlot_90_2042 } from "./land_plots/90/land_plot_2042.svg";
import { ReactComponent as LandPlot_90_2043 } from "./land_plots/90/land_plot_2043.svg";
import { ReactComponent as LandPlot_90_2044 } from "./land_plots/90/land_plot_2044.svg";
import { ReactComponent as LandPlot_90_2045 } from "./land_plots/90/land_plot_2045.svg";
import { ReactComponent as LandPlot_90_2046 } from "./land_plots/90/land_plot_2046.svg";
import { ReactComponent as LandPlot_90_2047 } from "./land_plots/90/land_plot_2047.svg";
import { ReactComponent as LandPlot_90_2048 } from "./land_plots/90/land_plot_2048.svg";
import { ReactComponent as LandPlot_90_2049 } from "./land_plots/90/land_plot_2049.svg";
import { ReactComponent as LandPlot_90_2050 } from "./land_plots/90/land_plot_2050.svg";
import { ReactComponent as LandPlot_90_2051 } from "./land_plots/90/land_plot_2051.svg";
import { ReactComponent as LandPlot_90_2052 } from "./land_plots/90/land_plot_2052.svg";

import { ReactComponent as Church_2_1 } from "../../../../static/images/contents/zone-3/icons/2/church_1.svg";
import { ReactComponent as Church_2_2 } from "../../../../static/images/contents/zone-3/icons/2/church_2.svg";
import { ReactComponent as Church_2_3 } from "../../../../static/images/contents/zone-3/icons/2/church_3.svg";
import { ReactComponent as Church_2_4 } from "../../../../static/images/contents/zone-3/icons/2/church_4.svg";
import { ReactComponent as Church_2_5 } from "../../../../static/images/contents/zone-3/icons/2/church_5.svg";
import { ReactComponent as Church_2_6 } from "../../../../static/images/contents/zone-3/icons/2/church_6.svg";
import { ReactComponent as Church_2_7 } from "../../../../static/images/contents/zone-3/icons/2/church_7.svg";
import { ReactComponent as Church_2_8 } from "../../../../static/images/contents/zone-3/icons/2/church_8.svg";
import { ReactComponent as School_2_1 } from "../../../../static/images/contents/zone-3/icons/2/school_1.svg";
import { ReactComponent as School_2_2 } from "../../../../static/images/contents/zone-3/icons/2/school_2.svg";
import { ReactComponent as School_2_3 } from "../../../../static/images/contents/zone-3/icons/2/school_3.svg";
import { ReactComponent as School_2_4 } from "../../../../static/images/contents/zone-3/icons/2/school_4.svg";
import { ReactComponent as School_2_5 } from "../../../../static/images/contents/zone-3/icons/2/school_5.svg";
import { ReactComponent as School_2_6 } from "../../../../static/images/contents/zone-3/icons/2/school_6.svg";
import { ReactComponent as School_2_7 } from "../../../../static/images/contents/zone-3/icons/2/school_7.svg";
import { ReactComponent as Shopping_2_1 } from "../../../../static/images/contents/zone-3/icons/2/shopping_1.svg";
import { ReactComponent as Shopping_2_2 } from "../../../../static/images/contents/zone-3/icons/2/shopping_2.svg";
import { ReactComponent as Hospital_2_1 } from "../../../../static/images/contents/zone-3/icons/2/hospital_1.svg";

import { ReactComponent as Church_32_1 } from "../../../../static/images/contents/zone-3/icons/33/church_1.svg";
import { ReactComponent as Church_32_2 } from "../../../../static/images/contents/zone-3/icons/33/church_2.svg";
import { ReactComponent as Church_32_3 } from "../../../../static/images/contents/zone-3/icons/33/church_3.svg";
import { ReactComponent as Church_32_4 } from "../../../../static/images/contents/zone-3/icons/33/church_4.svg";
import { ReactComponent as Church_32_5 } from "../../../../static/images/contents/zone-3/icons/33/church_5.svg";
import { ReactComponent as Church_32_6 } from "../../../../static/images/contents/zone-3/icons/33/church_6.svg";
import { ReactComponent as School_32_1 } from "../../../../static/images/contents/zone-3/icons/33/school_1.svg";
import { ReactComponent as School_32_2 } from "../../../../static/images/contents/zone-3/icons/33/school_2.svg";
import { ReactComponent as School_32_3 } from "../../../../static/images/contents/zone-3/icons/33/school_3.svg";
import { ReactComponent as Shopping_32_1 } from "../../../../static/images/contents/zone-3/icons/33/shopping_1.svg";
import { ReactComponent as Shopping_32_2 } from "../../../../static/images/contents/zone-3/icons/33/shopping_2.svg";                      

import { ReactComponent as Church_62_1 } from "../../../../static/images/contents/zone-3/icons/61/church_1.svg";
import { ReactComponent as Church_62_2 } from "../../../../static/images/contents/zone-3/icons/61/church_2.svg";
import { ReactComponent as Church_62_3 } from "../../../../static/images/contents/zone-3/icons/61/church_3.svg";
import { ReactComponent as Church_62_4 } from "../../../../static/images/contents/zone-3/icons/61/church_4.svg";
import { ReactComponent as Church_62_5 } from "../../../../static/images/contents/zone-3/icons/61/church_5.svg";
import { ReactComponent as Church_62_6 } from "../../../../static/images/contents/zone-3/icons/61/church_6.svg";
import { ReactComponent as Church_62_7 } from "../../../../static/images/contents/zone-3/icons/61/church_7.svg";
import { ReactComponent as Church_62_8 } from "../../../../static/images/contents/zone-3/icons/61/church_8.svg";
import { ReactComponent as School_62_1 } from "../../../../static/images/contents/zone-3/icons/61/school_1.svg";
import { ReactComponent as School_62_2 } from "../../../../static/images/contents/zone-3/icons/61/school_2.svg";
import { ReactComponent as School_62_3 } from "../../../../static/images/contents/zone-3/icons/61/school_3.svg";
import { ReactComponent as School_62_4 } from "../../../../static/images/contents/zone-3/icons/61/school_4.svg";
import { ReactComponent as School_62_5 } from "../../../../static/images/contents/zone-3/icons/61/school_5.svg";
import { ReactComponent as School_62_6 } from "../../../../static/images/contents/zone-3/icons/61/school_6.svg";
import { ReactComponent as Shopping_62_1 } from "../../../../static/images/contents/zone-3/icons/61/shopping_1.svg";
import { ReactComponent as Shopping_62_2 } from "../../../../static/images/contents/zone-3/icons/61/shopping_2.svg";
import { ReactComponent as Hospital_62_1 } from "../../../../static/images/contents/zone-3/icons/2/hospital_1.svg";

import { ReactComponent as Church_91_1 } from "../../../../static/images/contents/zone-3/icons/91/church_1.svg";
import { ReactComponent as Church_91_2 } from "../../../../static/images/contents/zone-3/icons/91/church_2.svg";
import { ReactComponent as Church_91_3 } from "../../../../static/images/contents/zone-3/icons/91/church_3.svg";
import { ReactComponent as Church_91_4 } from "../../../../static/images/contents/zone-3/icons/91/church_4.svg";
import { ReactComponent as Church_91_5 } from "../../../../static/images/contents/zone-3/icons/91/church_5.svg";
import { ReactComponent as Church_91_6 } from "../../../../static/images/contents/zone-3/icons/91/church_6.svg";
import { ReactComponent as Church_91_7 } from "../../../../static/images/contents/zone-3/icons/91/church_7.svg";
import { ReactComponent as Church_91_8 } from "../../../../static/images/contents/zone-3/icons/91/church_8.svg";
import { ReactComponent as School_91_1 } from "../../../../static/images/contents/zone-3/icons/91/school_1.svg";
import { ReactComponent as School_91_2 } from "../../../../static/images/contents/zone-3/icons/91/school_2.svg";
import { ReactComponent as School_91_3 } from "../../../../static/images/contents/zone-3/icons/91/school_3.svg";
import { ReactComponent as School_91_4 } from "../../../../static/images/contents/zone-3/icons/91/school_4.svg";
import { ReactComponent as School_91_5 } from "../../../../static/images/contents/zone-3/icons/91/school_5.svg";
import { ReactComponent as School_91_6 } from "../../../../static/images/contents/zone-3/icons/91/school_6.svg";
import { ReactComponent as School_91_7 } from "../../../../static/images/contents/zone-3/icons/91/school_7.svg";
import { ReactComponent as Shopping_91_1 } from "../../../../static/images/contents/zone-3/icons/91/shopping_1.svg";
import { ReactComponent as Shopping_91_2 } from "../../../../static/images/contents/zone-3/icons/91/shopping_2.svg";
import { ReactComponent as Hospital_91_1 } from "../../../../static/images/contents/zone-3/icons/2/hospital_1.svg";


///////////////////////////////////////////////////////////////////////////////////////////////////
export const zone3_land_plots = [
    ////////////////////////////////////////////////////////////////////////////////////////index 1
    {
        index: 2,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: LandPlot_1_1751,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1751,
                                status: "available",
                                block: 93,
                                unitSize: 1151.74,
                                north: "25.81",
                                east: "49.77",
                                south: "25.0",
                                west: "43.37",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1752,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1752,
                                status: "available",
                                block: 93,
                                unitSize: 1004.31,
                                north: "25.81",
                                east: "43.37",
                                south: "25.0",
                                west: "36.97",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1753,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1753,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1754,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1754,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1755,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1755,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1756,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1756,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1757,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1757,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1758,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1758,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1759,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1759,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1760,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1760,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1761,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1761,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1762,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1762,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1763,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1763,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1764,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1764,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1765,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1765,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1766,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1766,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1767,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1767,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1768,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1768,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1769,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1769,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1770,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1770,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1771,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1771,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1772,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1772,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1773,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1773,
                                status: "available",
                                block: 93,
                                unitSize: 374.97,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1774,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1774,
                                status: "available",
                                block: 93,
                                unitSize: 375.03,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1775,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1775,
                                status: "available",
                                block: 94,
                                unitSize: 1448.27,
                                north: "42.45",
                                east: "39.73",
                                south: "33.46",
                                west: "37.74",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1776,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1776,
                                status: "available",
                                block: 94,
                                unitSize: 1122.19,
                                north: "30.01",
                                east: "37.74",
                                south: "30.0",
                                west: "37.07",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1777,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1777,
                                status: "available",
                                block: 94,
                                unitSize: 1101.93,
                                north: "30.01",
                                east: "37.07",
                                south: "30.0",
                                west: "36.39",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1778,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1778,
                                status: "available",
                                block: 94,
                                unitSize: 1081.67,
                                north: "30.01",
                                east: "36.39",
                                south: "30.0",
                                west: "35.72",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1779,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1779,
                                status: "available",
                                block: 94,
                                unitSize: 1061.41,
                                north: "30.01",
                                east: "35.72",
                                south: "30.0",
                                west: "35.04",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1780,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1780,
                                status: "available",
                                block: 94,
                                unitSize: 1042.89,
                                north: "30.01",
                                east: "35.04",
                                south: "30.0",
                                west: "34.75",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1781,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1781,
                                status: "available",
                                block: 94,
                                unitSize: 1306.42,
                                north: "31.87",
                                east: "34.75",
                                south: "40.49",
                                west: "38.88",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1782,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1782,
                                status: "available",
                                block: 95,
                                unitSize: 811.69,
                                north: "15.68",
                                east: "38.22",
                                south: "20.15",
                                west: "38.56",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1783,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1783,
                                status: "available",
                                block: 95,
                                unitSize: 503.69,
                                north: "20.15",
                                east: "25.0",
                                south: "20.15",
                                west: "25.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1784,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1784,
                                status: "available",
                                block: 95,
                                unitSize: 552.23,
                                north: "15.4",
                                east: "38.56",
                                south: "15.0",
                                west: "35.08",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1785,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1785,
                                status: "available",
                                block: 95,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1786,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1786,
                                status: "available",
                                block: 95,
                                unitSize: 500.04,
                                north: "15.4",
                                east: "35.08",
                                south: "15.0",
                                west: "31.6",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1787,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1787,
                                status: "available",
                                block: 95,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1788,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1788,
                                status: "available",
                                block: 95,
                                unitSize: 447.86,
                                north: "15.4",
                                east: "31.6",
                                south: "15.0",
                                west: "28.12",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1789,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1789,
                                status: "available",
                                block: 95,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1790,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1790,
                                status: "available",
                                block: 95,
                                unitSize: 395.67,
                                north: "15.4",
                                east: "28.12",
                                south: "15.0",
                                west: "24.64",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1791,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1791,
                                status: "available",
                                block: 95,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1792,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1792,
                                status: "available",
                                block: 95,
                                unitSize: 343.48,
                                north: "15.4",
                                east: "24.64",
                                south: "15.0",
                                west: "21.16",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1793,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1793,
                                status: "available",
                                block: 95,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1794,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1794,
                                status: "available",
                                block: 95,
                                unitSize: 530.41,
                                north: "30.8",
                                east: "21.16",
                                south: "30.0",
                                west: "14.2",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1795,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1795,
                                status: "available",
                                block: 95,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1796,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1796,
                                status: "available",
                                block: 95,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1797,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1797,
                                status: "available",
                                block: 95,
                                unitSize: 668.05,
                                north: "18.48",
                                east: "39.2",
                                south: "18.0",
                                west: "35.03",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1798,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1798,
                                status: "available",
                                block: 95,
                                unitSize: 592.9,
                                north: "18.48",
                                east: "35.03",
                                south: "18.0",
                                west: "30.85",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1799,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1799,
                                status: "available",
                                block: 95,
                                unitSize: 517.75,
                                north: "18.48",
                                east: "30.85",
                                south: "18.0",
                                west: "26.68",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1800,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1800,
                                status: "available",
                                block: 95,
                                unitSize: 509.06,
                                north: "21.56",
                                east: "26.68",
                                south: "21.0",
                                west: "21.81",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1801,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1801,
                                status: "available",
                                block: 96,
                                unitSize: 694.43,
                                north: "23.15",
                                east: "30.0",
                                south: "23.15",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1802,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1802,
                                status: "available",
                                block: 96,
                                unitSize: 694.43,
                                north: "23.15",
                                east: "30.0",
                                south: "23.15",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1803,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1803,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1804,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1804,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1805,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1805,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1806,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1806,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1807,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1807,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1808,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1808,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1809,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1809,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1810,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1810,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1811,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1811,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1812,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1812,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1813,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1813,
                                status: "available",
                                block: 96,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1814,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1814,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1815,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1815,
                                status: "available",
                                block: 96,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1816,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1816,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1817,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1817,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1818,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1818,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1819,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1819,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1820,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1820,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1821,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1821,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1822,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1822,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1823,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1823,
                                status: "available",
                                block: 96,
                                unitSize: 405.0,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1824,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1824,
                                status: "available",
                                block: 96,
                                unitSize: 405.0,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1825,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1825,
                                status: "available",
                                block: 96,
                                unitSize: 405.0,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1826,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1826,
                                status: "available",
                                block: 96,
                                unitSize: 405.0,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "30",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1827,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1827,
                                status: "available",
                                block: 97,
                                unitSize: 750.0,
                                north: "25.0",
                                east: "30.0",
                                south: "25.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1828,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1828,
                                status: "available",
                                block: 97,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1829,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1829,
                                status: "available",
                                block: 97,
                                unitSize: 1125.0,
                                north: "25.0",
                                east: "45.0",
                                south: "25.0",
                                west: "45.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "15+6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1830,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1830,
                                status: "available",
                                block: 97,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1831,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1831,
                                status: "available",
                                block: 97,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1832,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1832,
                                status: "available",
                                block: 97,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1833,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1833,
                                status: "available",
                                block: 97,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1834,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1834,
                                status: "available",
                                block: 97,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1835,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1835,
                                status: "available",
                                block: 97,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1836,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1836,
                                status: "available",
                                block: 97,
                                unitSize: 374.91,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "30",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1837,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1837,
                                status: "available",
                                block: 97,
                                unitSize: 374.97,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "30",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1838,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1838,
                                status: "available",
                                block: 98,
                                unitSize: 1269.26,
                                north: "30.01",
                                east: "42.58",
                                south: "30.0",
                                west: "42.02",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1839,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1839,
                                status: "available",
                                block: 98,
                                unitSize: 450.04,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1840,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1840,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1841,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1841,
                                status: "available",
                                block: 98,
                                unitSize: 1252.23,
                                north: "30.01",
                                east: "42.02",
                                south: "30.0",
                                west: "41.46",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1842,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1842,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1843,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1843,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1844,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1844,
                                status: "available",
                                block: 98,
                                unitSize: 1235.41,
                                north: "30.01",
                                east: "41.46",
                                south: "30.0",
                                west: "40.9",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1845,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1845,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1846,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1846,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1847,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1847,
                                status: "available",
                                block: 98,
                                unitSize: 1218.59,
                                north: "30.01",
                                east: "40.9",
                                south: "30.0",
                                west: "40.34",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1848,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1848,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1849,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1849,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1850,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1850,
                                status: "available",
                                block: 98,
                                unitSize: 1201.77,
                                north: "30.01",
                                east: "40.34",
                                south: "30.0",
                                west: "39.78",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1851,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1851,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1852,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1852,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1853,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1853,
                                status: "available",
                                block: 98,
                                unitSize: 1184.95,
                                north: "30.01",
                                east: "39.78",
                                south: "30.0",
                                west: "39.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1854,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1854,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1855,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1855,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1856,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1856,
                                status: "available",
                                block: 98,
                                unitSize: 1168.09,
                                north: "30.01",
                                east: "39.22",
                                south: "30.0",
                                west: "38.65",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1857,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1857,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1858,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1858,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1859,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1859,
                                status: "available",
                                block: 98,
                                unitSize: 1227.88,
                                north: "32.03",
                                east: "38.65",
                                south: "32.01",
                                west: "38.04",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1860,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1860,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1861,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1861,
                                status: "available",
                                block: 98,
                                unitSize: 510.24,
                                north: "17.01",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1862,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1862,
                                status: "available",
                                block: 99,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1863,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1863,
                                status: "available",
                                block: 99,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1864,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1864,
                                status: "available",
                                block: 99,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1865,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1865,
                                status: "available",
                                block: 99,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "30",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1866,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1866,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1867,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1867,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1868,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1868,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1869,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1869,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1870,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1870,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1871,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1871,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1872,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1872,
                                status: "available",
                                block: 99,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1873,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1873,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1874,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1874,
                                status: "available",
                                block: 99,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1875,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1875,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1876,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1876,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1877,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1877,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1878,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1878,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1879,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1879,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1880,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1880,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1881,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1881,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1882,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1882,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1883,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1883,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1884,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1884,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1885,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1885,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1886,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1886,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1887,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1887,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1888,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1888,
                                status: "available",
                                block: 99,
                                unitSize: 405.0,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1889,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1889,
                                status: "available",
                                block: 99,
                                unitSize: 405.0,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1890,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1890,
                                status: "available",
                                block: 99,
                                unitSize: 405.0,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1891,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1891,
                                status: "available",
                                block: 99,
                                unitSize: 405.0,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "30",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1892,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1892,
                                status: "available",
                                block: 100,
                                unitSize: 1385.24,
                                north: "32.51",
                                east: "42.93",
                                south: "32.5",
                                west: "42.32",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1893,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1893,
                                status: "available",
                                block: 100,
                                unitSize: 1352.78,
                                north: "32.51",
                                east: "42.32",
                                south: "32.5",
                                west: "41.7",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "25",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1894,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1894,
                                status: "available",
                                block: 100,
                                unitSize: 1462.51,
                                north: "32.5",
                                east: "45.0",
                                south: "32.5",
                                west: "45.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1895,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1895,
                                status: "available",
                                block: 100,
                                unitSize: 487.5,
                                north: "32.5",
                                east: "15.0",
                                south: "32.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1896,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1896,
                                status: "available",
                                block: 100,
                                unitSize: 487.5,
                                north: "32.5",
                                east: "15.0",
                                south: "32.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1897,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1897,
                                status: "available",
                                block: 100,
                                unitSize: 487.5,
                                north: "32.5",
                                east: "15.0",
                                south: "32.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1898,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1898,
                                status: "available",
                                block: 100,
                                unitSize: 487.5,
                                north: "32.5",
                                east: "15.0",
                                south: "32.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1899,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1899,
                                status: "available",
                                block: 100,
                                unitSize: 487.5,
                                north: "32.5",
                                east: "15.0",
                                south: "32.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1900,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1900,
                                status: "available",
                                block: 100,
                                unitSize: 487.5,
                                north: "32.5",
                                east: "15.0",
                                south: "32.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1901,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1901,
                                status: "available",
                                block: 100,
                                unitSize: 487.5,
                                north: "32.5",
                                east: "15.0",
                                south: "32.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1902,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1902,
                                status: "available",
                                block: 100,
                                unitSize: 812.43,
                                north: "32.5",
                                east: "25.0",
                                south: "32.5",
                                west: "25.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "30",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1903,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1903,
                                status: "available",
                                block: 100,
                                unitSize: 812.3,
                                north: "32.5",
                                east: "25.0",
                                south: "32.5",
                                west: "25.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "30",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1904,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1904,
                                status: "available",
                                block: 101,
                                unitSize: 1431.47,
                                north: "35.01",
                                east: "41.23",
                                south: "35.0",
                                west: "40.57",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "25",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1905,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1905,
                                status: "available",
                                block: 101,
                                unitSize: 1208.55,
                                north: "30.01",
                                east: "40.57",
                                south: "30.0",
                                west: "40.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1906,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1906,
                                status: "available",
                                block: 101,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1907,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1907,
                                status: "available",
                                block: 101,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1908,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1908,
                                status: "available",
                                block: 101,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1909,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1909,
                                status: "available",
                                block: 101,
                                unitSize: 1349.99,
                                north: "30.0",
                                east: "45.0",
                                south: "30.0",
                                west: "45.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1910,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1910,
                                status: "available",
                                block: 101,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1911,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1911,
                                status: "available",
                                block: 101,
                                unitSize: 449.99,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1912,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1912,
                                status: "available",
                                block: 101,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1913,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1913,
                                status: "available",
                                block: 101,
                                unitSize: 499.99,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1914,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1914,
                                status: "available",
                                block: 101,
                                unitSize: 874.54,
                                north: "35.0",
                                east: "24.99",
                                south: "35.0",
                                west: "24.98",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "30",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1915,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1915,
                                status: "available",
                                block: 101,
                                unitSize: 749.45,
                                north: "30.0",
                                east: "24.98",
                                south: "30.0",
                                west: "24.98",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "30",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1916,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1916,
                                status: "available",
                                block: 102,
                                unitSize: 1226.77,
                                north: "31.01",
                                east: "39.87",
                                south: "31.0",
                                west: "39.28",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1917,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1917,
                                status: "available",
                                block: 102,
                                unitSize: 400.0,
                                north: "16.0",
                                east: "25.0",
                                south: "16.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1918,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1918,
                                status: "available",
                                block: 102,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1919,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1919,
                                status: "available",
                                block: 102,
                                unitSize: 1169.8,
                                north: "31.01",
                                east: "39.28",
                                south: "30.0",
                                west: "38.71",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1920,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1920,
                                status: "available",
                                block: 102,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1921,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1921,
                                status: "available",
                                block: 102,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1922,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1922,
                                status: "available",
                                block: 102,
                                unitSize: 1140.31,
                                north: "30.01",
                                east: "38.71",
                                south: "30.0",
                                west: "38.15",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1923,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1923,
                                status: "available",
                                block: 102,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1924,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1924,
                                status: "available",
                                block: 102,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1925,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1925,
                                status: "available",
                                block: 102,
                                unitSize: 1135.93,
                                north: "30.01",
                                east: "38.15",
                                south: "30.0",
                                west: "37.58",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1926,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1926,
                                status: "available",
                                block: 102,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1927,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1927,
                                status: "available",
                                block: 102,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1928,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1928,
                                status: "available",
                                block: 102,
                                unitSize: 1156.08,
                                north: "31.01",
                                east: "37.58",
                                south: "31.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1929,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1929,
                                status: "available",
                                block: 102,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1930,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1930,
                                status: "available",
                                block: 102,
                                unitSize: 400.01,
                                north: "16.0",
                                east: "25.0",
                                south: "16.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1931,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1931,
                                status: "available",
                                block: 103,
                                unitSize: 1140.38,
                                north: "31.23",
                                east: "36.89",
                                south: "31.09",
                                west: "36.31",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1932,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1932,
                                status: "available",
                                block: 103,
                                unitSize: 401.15,
                                north: "16.09",
                                east: "25.0",
                                south: "16.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1933,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1933,
                                status: "available",
                                block: 103,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1934,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1934,
                                status: "available",
                                block: 103,
                                unitSize: 1080.76,
                                north: "30.01",
                                east: "36.31",
                                south: "30.0",
                                west: "35.74",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1935,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1935,
                                status: "available",
                                block: 103,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1936,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1936,
                                status: "available",
                                block: 103,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1937,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1937,
                                status: "available",
                                block: 103,
                                unitSize: 1063.87,
                                north: "30.01",
                                east: "35.74",
                                south: "30.0",
                                west: "35.18",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1938,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1938,
                                status: "available",
                                block: 103,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1939,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1939,
                                status: "available",
                                block: 103,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1940,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1940,
                                status: "available",
                                block: 103,
                                unitSize: 1046.98,
                                north: "30.01",
                                east: "35.18",
                                south: "30.0",
                                west: "34.62",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1941,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1941,
                                status: "available",
                                block: 103,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1942,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1942,
                                status: "available",
                                block: 103,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1943,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1943,
                                status: "available",
                                block: 103,
                                unitSize: 1063.48,
                                north: "30.98",
                                east: "34.62",
                                south: "30.99",
                                west: "34.04",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1944,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1944,
                                status: "available",
                                block: 103,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1945,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1945,
                                status: "available",
                                block: 103,
                                unitSize: 399.89,
                                north: "15.99",
                                east: "25.0",
                                south: "16.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1946,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1946,
                                status: "available",
                                block: 104,
                                unitSize: 540.0,
                                north: "18.0",
                                east: "30.0",
                                south: "18.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1947,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1947,
                                status: "available",
                                block: 104,
                                unitSize: 540.0,
                                north: "18.0",
                                east: "30.0",
                                south: "18.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1948,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1948,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1949,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1949,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1950,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1950,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1951,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1951,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1952,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1952,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1953,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1953,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1954,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1954,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1955,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1955,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1956,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1956,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1957,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1957,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1958,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1958,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1959,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1959,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1960,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1960,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1961,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1961,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1962,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1962,
                                status: "available",
                                block: 104,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1963,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1963,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1964,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1964,
                                status: "available",
                                block: 104,
                                unitSize: 503.75,
                                north: "17.0",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1965,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1965,
                                status: "available",
                                block: 104,
                                unitSize: 510.0,
                                north: "17.0",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1966,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1966,
                                status: "sold",
                                block: 105,
                                unitSize: 510.0,
                                north: "17.0",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1967,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1967,
                                status: "sold",
                                block: 105,
                                unitSize: 510.0,
                                north: "17.0",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1968,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1968,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1969,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1969,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1970,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1970,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1971,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1971,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1972,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1972,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1973,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1973,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1974,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1974,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1975,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1975,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1976,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1976,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1977,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1977,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1978,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1978,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1979,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1979,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1980,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1980,
                                status: "sold",
                                block: 105,
                                unitSize: 540.0,
                                north: "18.0",
                                east: "30.0",
                                south: "18.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1981,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1981,
                                status: "sold",
                                block: 105,
                                unitSize: 540.0,
                                north: "18.0",
                                east: "30.0",
                                south: "18.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1982,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1982,
                                status: "available",
                                block: 106,
                                unitSize: 2910.25,
                                north: "50.01",
                                east: "58.92",
                                south: "50.0",
                                west: "57.99",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1983,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1983,
                                status: "available",
                                block: 107,
                                unitSize: 377.64,
                                north: "25.0",
                                east: "15.11",
                                south: "25.0",
                                west: "15.1",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1984,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1984,
                                status: "available",
                                block: 107,
                                unitSize: 377.58,
                                north: "25.0",
                                east: "15.11",
                                south: "25.0",
                                west: "15.1",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1985,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1985,
                                status: "available",
                                block: 107,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1986,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1986,
                                status: "available",
                                block: 107,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1987,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1987,
                                status: "available",
                                block: 107,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1988,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1988,
                                status: "available",
                                block: 107,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1989,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1989,
                                status: "available",
                                block: 107,
                                unitSize: 406.2,
                                north: "17.0",
                                east: "23.89",
                                south: "17.0",
                                west: "23.89",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1990,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1990,
                                status: "available",
                                block: 107,
                                unitSize: 382.33,
                                north: "16.0",
                                east: "23.89",
                                south: "16.0",
                                west: "23.9",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1991,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1991,
                                status: "available",
                                block: 107,
                                unitSize: 406.25,
                                north: "17.0",
                                east: "23.9",
                                south: "17.0",
                                west: "23.9",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1992,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1992,
                                status: "available",
                                block: 108,
                                unitSize: 1175.64,
                                north: "27.16",
                                east: "34.87",
                                south: "34.01",
                                west: "34.24",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1993,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1993,
                                status: "available",
                                block: 108,
                                unitSize: 437.14,
                                north: "19.01",
                                east: "23.0",
                                south: "19.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1994,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1994,
                                status: "available",
                                block: 108,
                                unitSize: 345.0,
                                north: "15.0",
                                east: "23.0",
                                south: "15.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1995,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1995,
                                status: "available",
                                block: 108,
                                unitSize: 1018.73,
                                north: "30.0",
                                east: "34.24",
                                south: "30.0",
                                west: "33.68",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1996,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1996,
                                status: "available",
                                block: 108,
                                unitSize: 345.0,
                                north: "15.0",
                                east: "23.0",
                                south: "15.0",
                                west: "23.0",
                                front1: "nan",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1997,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1997,
                                status: "available",
                                block: 108,
                                unitSize: 345.0,
                                north: "15.0",
                                east: "23.0",
                                south: "15.0",
                                west: "23.0",
                                front1: "nan",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1998,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1998,
                                status: "available",
                                block: 108,
                                unitSize: 1055.64,
                                north: "32.02",
                                east: "33.68",
                                south: "32.0",
                                west: "33.08",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_1999,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1999,
                                status: "available",
                                block: 108,
                                unitSize: 368.0,
                                north: "16.0",
                                east: "23.0",
                                south: "16.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2000,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2000,
                                status: "available",
                                block: 108,
                                unitSize: 368.0,
                                north: "16.0",
                                east: "23.0",
                                south: "16.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2001,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2001,
                                status: "available",
                                block: 108,
                                unitSize: 1049.03,
                                north: "32.01",
                                east: "33.08",
                                south: "32.0",
                                west: "32.48",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2002,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2002,
                                status: "available",
                                block: 108,
                                unitSize: 368.0,
                                north: "16.0",
                                east: "23.0",
                                south: "16.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2003,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2003,
                                status: "available",
                                block: 108,
                                unitSize: 368.0,
                                north: "16.0",
                                east: "23.0",
                                south: "16.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2004,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2004,
                                status: "available",
                                block: 108,
                                unitSize: 1029.92,
                                north: "32.01",
                                east: "32.48",
                                south: "32.0",
                                west: "31.89",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2005,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2005,
                                status: "available",
                                block: 108,
                                unitSize: 368.0,
                                north: "16.0",
                                east: "23.0",
                                south: "16.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2006,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2006,
                                status: "available",
                                block: 108,
                                unitSize: 368.0,
                                north: "16.0",
                                east: "23.0",
                                south: "16.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2007,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2007,
                                status: "available",
                                block: 108,
                                unitSize: 1073.36,
                                north: "34.01",
                                east: "31.89",
                                south: "34.0",
                                west: "31.25",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2008,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2008,
                                status: "available",
                                block: 108,
                                unitSize: 391.0,
                                north: "17.0",
                                east: "23.0",
                                south: "17.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2009,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2009,
                                status: "available",
                                block: 108,
                                unitSize: 391.0,
                                north: "17.0",
                                east: "23.0",
                                south: "17.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2010,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2010,
                                status: "available",
                                block: 108,
                                unitSize: 1051.78,
                                north: "34.01",
                                east: "31.25",
                                south: "34.0",
                                west: "30.62",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2011,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2011,
                                status: "available",
                                block: 108,
                                unitSize: 391.0,
                                north: "17.0",
                                east: "23.0",
                                south: "17.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2012,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2012,
                                status: "available",
                                block: 108,
                                unitSize: 391.0,
                                north: "17.0",
                                east: "23.0",
                                south: "17.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2013,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2013,
                                status: "available",
                                block: 108,
                                unitSize: 1077.02,
                                north: "35.98",
                                east: "30.62",
                                south: "35.99",
                                west: "29.95",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2014,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2014,
                                status: "available",
                                block: 108,
                                unitSize: 391.0,
                                north: "17.0",
                                east: "23.0",
                                south: "17.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2015,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2015,
                                status: "available",
                                block: 108,
                                unitSize: 436.86,
                                north: "18.99",
                                east: "23.0",
                                south: "19.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2016,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2016,
                                status: "available",
                                block: 109,
                                unitSize: 540.0,
                                north: "18.0",
                                east: "30.0",
                                south: "18.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2017,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2017,
                                status: "sold",
                                block: 109,
                                unitSize: 540.0,
                                north: "18.0",
                                east: "30.0",
                                south: "18.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_1_2018,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2018,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2019,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2019,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2020,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2020,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2021,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2021,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2022,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2022,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2023,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2023,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2024,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2024,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2025,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2025,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2026,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2026,
                                status: "available",
                                block: 109,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2027,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2027,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2028,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2028,
                                status: "available",
                                block: 109,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2029,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2029,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2030,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2030,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2031,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2031,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2032,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2032,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2033,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2033,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2034,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2034,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2035,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2035,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2036,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2036,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2037,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2037,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        // {
                        //     Polygon: LandPlot_1_2038,
                        //     className: null,
                        //     infobox: {
                        //         type: "land_info",
                        //         index: 2038,
                        //         status: "available",
                        //         block: 109,
                        //         unitSize: 0.0,
                        //         north: "15.0",
                        //         east: "30.0",
                        //         south: "15.0",
                        //         west: "30.0",
                        //         front1: "northern",
                        //         front2: "nan",
                        //         electricTransformer: false,
                        //         st_width_1: "15",
                        //         st_width_2: "0",
                        //     },
                        //     onClick: {
                        //         "action": "open_contact_form"
                        //     }
                        // },
                        // {
                        //     Polygon: LandPlot_1_2039,
                        //     className: null,
                        //     infobox: {
                        //         type: "land_info",
                        //         index: 2039,
                        //         status: "available",
                        //         block: 109,
                        //         unitSize: 0.0,
                        //         north: "15.0",
                        //         east: "30.0",
                        //         south: "15.0",
                        //         west: "30.0",
                        //         front1: "southern",
                        //         front2: "nan",
                        //         electricTransformer: false,
                        //         st_width_1: "30",
                        //         st_width_2: "0",
                        //     },
                        //     onClick: {
                        //         "action": "open_contact_form"
                        //     }
                        // },
                        // {
                        //     Polygon: LandPlot_1_2040,
                        //     className: null,
                        //     infobox: {
                        //         type: "land_info",
                        //         index: 2040,
                        //         status: "available",
                        //         block: 109,
                        //         unitSize: 0.0,
                        //         north: "15.0",
                        //         east: "30.0",
                        //         south: "15.0",
                        //         west: "30.0",
                        //         front1: "northern",
                        //         front2: "nan",
                        //         electricTransformer: false,
                        //         st_width_1: "15",
                        //         st_width_2: "0",
                        //     },
                        //     onClick: {
                        //         "action": "open_contact_form"
                        //     }
                        // },
                        // {
                        //     Polygon: LandPlot_1_2041,
                        //     className: null,
                        //     infobox: {
                        //         type: "land_info",
                        //         index: 2041,
                        //         status: "available",
                        //         block: 109,
                        //         unitSize: 0.0,
                        //         north: "15.0",
                        //         east: "30.0",
                        //         south: "15.0",
                        //         west: "30.0",
                        //         front1: "southern",
                        //         front2: "nan",
                        //         electricTransformer: false,
                        //         st_width_1: "30",
                        //         st_width_2: "0",
                        //     },
                        //     onClick: {
                        //         "action": "open_contact_form"
                        //     }
                        // },
                        // {
                        //     Polygon: LandPlot_1_2042,
                        //     className: null,
                        //     infobox: {
                        //         type: "land_info",
                        //         index: 2042,
                        //         status: "available",
                        //         block: 109,
                        //         unitSize: 0.0,
                        //         north: "15.0",
                        //         east: "30.0",
                        //         south: "15.0",
                        //         west: "30.0",
                        //         front1: "northern",
                        //         front2: "nan",
                        //         electricTransformer: false,
                        //         st_width_1: "15",
                        //         st_width_2: "0",
                        //     },
                        //     onClick: {
                        //         "action": "open_contact_form"
                        //     }
                        // },
                        // {
                        //     Polygon: LandPlot_1_2043,
                        //     className: null,
                        //     infobox: {
                        //         type: "land_info",
                        //         index: 2043,
                        //         status: "available",
                        //         block: 109,
                        //         unitSize: 0.0,
                        //         north: "15.0",
                        //         east: "30.0",
                        //         south: "15.0",
                        //         west: "30.0",
                        //         front1: "southern",
                        //         front2: "nan",
                        //         electricTransformer: false,
                        //         st_width_1: "30",
                        //         st_width_2: "0",
                        //     },
                        //     onClick: {
                        //         "action": "open_contact_form"
                        //     }
                        // },
                        // {
                        //     Polygon: LandPlot_1_2044,
                        //     className: null,
                        //     infobox: {
                        //         type: "land_info",
                        //         index: 2044,
                        //         status: "sold",
                        //         block: 109,
                        //         unitSize: 0.0,
                        //         north: "15.0",
                        //         east: "30.0",
                        //         south: "15.0",
                        //         west: "30.0",
                        //         front1: "northern",
                        //         front2: "nan",
                        //         electricTransformer: false,
                        //         st_width_1: "15",
                        //         st_width_2: "0",
                        //     },
                        // },
                        // {
                        //     Polygon: LandPlot_1_2045,
                        //     className: null,
                        //     infobox: {
                        //         type: "land_info",
                        //         index: 2045,
                        //         status: "sold",
                        //         block: 109,
                        //         unitSize: 0.0,
                        //         north: "15.0",
                        //         east: "30.0",
                        //         south: "15.0",
                        //         west: "30.0",
                        //         front1: "southern",
                        //         front2: "nan",
                        //         electricTransformer: false,
                        //         st_width_1: "30",
                        //         st_width_2: "0",
                        //     },
                        // },
                        // {
                        //     Polygon: LandPlot_1_2046,
                        //     className: null,
                        //     infobox: {
                        //         type: "land_info",
                        //         index: 2046,
                        //         status: "sold",
                        //         block: 109,
                        //         unitSize: 0.0,
                        //         north: "18.0",
                        //         east: "30.0",
                        //         south: "18.0",
                        //         west: "30.0",
                        //         front1: "northern",
                        //         front2: "western",
                        //         electricTransformer: false,
                        //         st_width_1: "15",
                        //         st_width_2: "15",
                        //     },
                        // },
                        // {
                        //     Polygon: LandPlot_1_2047,
                        //     className: null,
                        //     infobox: {
                        //         type: "land_info",
                        //         index: 2047,
                        //         status: "sold",
                        //         block: 109,
                        //         unitSize: 0.0,
                        //         north: "18.0",
                        //         east: "30.0",
                        //         south: "18.0",
                        //         west: "30.0",
                        //         front1: "southern",
                        //         front2: "western",
                        //         electricTransformer: false,
                        //         st_width_1: "30",
                        //         st_width_2: "15",
                        //     },
                        // },
                        {
                            Polygon: LandPlot_1_2048,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2048,
                                status: "available",
                                block: 110,
                                unitSize: 1859.27,
                                north: "42.43",
                                east: "47.83",
                                south: "35.91",
                                west: "47.49",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2049,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2049,
                                status: "available",
                                block: 110,
                                unitSize: 690.6,
                                north: "35.91",
                                east: "20.0",
                                south: "33.15",
                                west: "20.19",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2050,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2050,
                                status: "available",
                                block: 110,
                                unitSize: 635.27,
                                north: "33.15",
                                east: "20.0",
                                south: "30.38",
                                west: "20.19",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2051,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2051,
                                status: "available",
                                block: 110,
                                unitSize: 579.93,
                                north: "30.38",
                                east: "20.0",
                                south: "27.61",
                                west: "20.19",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_1_2052,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2052,
                                status: "available",
                                block: 110,
                                unitSize: 524.62,
                                north: "27.61",
                                east: "20.0",
                                south: "24.85",
                                west: "20.19",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "30",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_7,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_8,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_7,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Hospital_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
    ////////////////////////////////////////////////////////////////////////////////////////index 31
    {
        index: 32,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: LandPlot_31_1751,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1751,
                                status: "available",
                                block: 93,
                                unitSize: 1151.74,
                                north: "25.81",
                                east: "49.77",
                                south: "25.0",
                                west: "43.37",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1752,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1752,
                                status: "available",
                                block: 93,
                                unitSize: 1004.31,
                                north: "25.81",
                                east: "43.37",
                                south: "25.0",
                                west: "36.97",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1753,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1753,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1754,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1754,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1755,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1755,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1756,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1756,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1757,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1757,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1758,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1758,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1759,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1759,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1760,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1760,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1761,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1761,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1762,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1762,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1763,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1763,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1764,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1764,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1765,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1765,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1766,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1766,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1767,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1767,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1768,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1768,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1769,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1769,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1770,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1770,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1771,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1771,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1772,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1772,
                                status: "available",
                                block: 93,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1773,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1773,
                                status: "available",
                                block: 93,
                                unitSize: 374.97,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1774,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1774,
                                status: "available",
                                block: 93,
                                unitSize: 375.03,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1775,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1775,
                                status: "available",
                                block: 94,
                                unitSize: 1448.27,
                                north: "42.45",
                                east: "39.73",
                                south: "33.46",
                                west: "37.74",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1776,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1776,
                                status: "available",
                                block: 94,
                                unitSize: 1122.19,
                                north: "30.01",
                                east: "37.74",
                                south: "30.0",
                                west: "37.07",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1777,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1777,
                                status: "available",
                                block: 94,
                                unitSize: 1101.93,
                                north: "30.01",
                                east: "37.07",
                                south: "30.0",
                                west: "36.39",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1778,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1778,
                                status: "available",
                                block: 94,
                                unitSize: 1081.67,
                                north: "30.01",
                                east: "36.39",
                                south: "30.0",
                                west: "35.72",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1779,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1779,
                                status: "available",
                                block: 94,
                                unitSize: 1061.41,
                                north: "30.01",
                                east: "35.72",
                                south: "30.0",
                                west: "35.04",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1780,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1780,
                                status: "available",
                                block: 94,
                                unitSize: 1042.89,
                                north: "30.01",
                                east: "35.04",
                                south: "30.0",
                                west: "34.75",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1781,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1781,
                                status: "available",
                                block: 94,
                                unitSize: 1306.42,
                                north: "31.87",
                                east: "34.75",
                                south: "40.49",
                                west: "38.88",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1782,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1782,
                                status: "available",
                                block: 95,
                                unitSize: 811.69,
                                north: "15.68",
                                east: "38.22",
                                south: "20.15",
                                west: "38.56",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1783,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1783,
                                status: "available",
                                block: 95,
                                unitSize: 503.69,
                                north: "20.15",
                                east: "25.0",
                                south: "20.15",
                                west: "25.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1784,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1784,
                                status: "available",
                                block: 95,
                                unitSize: 552.23,
                                north: "15.4",
                                east: "38.56",
                                south: "15.0",
                                west: "35.08",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1785,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1785,
                                status: "available",
                                block: 95,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1786,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1786,
                                status: "available",
                                block: 95,
                                unitSize: 500.04,
                                north: "15.4",
                                east: "35.08",
                                south: "15.0",
                                west: "31.6",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1787,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1787,
                                status: "available",
                                block: 95,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1788,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1788,
                                status: "available",
                                block: 95,
                                unitSize: 447.86,
                                north: "15.4",
                                east: "31.6",
                                south: "15.0",
                                west: "28.12",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1789,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1789,
                                status: "available",
                                block: 95,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1790,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1790,
                                status: "available",
                                block: 95,
                                unitSize: 395.67,
                                north: "15.4",
                                east: "28.12",
                                south: "15.0",
                                west: "24.64",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1791,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1791,
                                status: "available",
                                block: 95,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1792,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1792,
                                status: "available",
                                block: 95,
                                unitSize: 343.48,
                                north: "15.4",
                                east: "24.64",
                                south: "15.0",
                                west: "21.16",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1793,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1793,
                                status: "available",
                                block: 95,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1794,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1794,
                                status: "available",
                                block: 95,
                                unitSize: 530.41,
                                north: "30.8",
                                east: "21.16",
                                south: "30.0",
                                west: "14.2",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1795,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1795,
                                status: "available",
                                block: 95,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1796,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1796,
                                status: "available",
                                block: 95,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1797,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1797,
                                status: "available",
                                block: 95,
                                unitSize: 668.05,
                                north: "18.48",
                                east: "39.2",
                                south: "18.0",
                                west: "35.03",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1798,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1798,
                                status: "available",
                                block: 95,
                                unitSize: 592.9,
                                north: "18.48",
                                east: "35.03",
                                south: "18.0",
                                west: "30.85",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1799,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1799,
                                status: "available",
                                block: 95,
                                unitSize: 517.75,
                                north: "18.48",
                                east: "30.85",
                                south: "18.0",
                                west: "26.68",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1800,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1800,
                                status: "available",
                                block: 95,
                                unitSize: 509.06,
                                north: "21.56",
                                east: "26.68",
                                south: "21.0",
                                west: "21.81",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1801,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1801,
                                status: "available",
                                block: 96,
                                unitSize: 694.43,
                                north: "23.15",
                                east: "30.0",
                                south: "23.15",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1802,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1802,
                                status: "available",
                                block: 96,
                                unitSize: 694.43,
                                north: "23.15",
                                east: "30.0",
                                south: "23.15",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1803,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1803,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1804,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1804,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1805,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1805,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1806,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1806,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1807,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1807,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1808,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1808,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1809,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1809,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1810,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1810,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1811,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1811,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1812,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1812,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1813,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1813,
                                status: "available",
                                block: 96,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1814,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1814,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1815,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1815,
                                status: "available",
                                block: 96,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1816,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1816,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1817,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1817,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1818,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1818,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1819,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1819,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1820,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1820,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1821,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1821,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1822,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1822,
                                status: "available",
                                block: 96,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1823,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1823,
                                status: "available",
                                block: 96,
                                unitSize: 405.0,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1824,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1824,
                                status: "available",
                                block: 96,
                                unitSize: 405.0,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1825,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1825,
                                status: "available",
                                block: 96,
                                unitSize: 405.0,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1826,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1826,
                                status: "available",
                                block: 96,
                                unitSize: 405.0,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "30",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1827,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1827,
                                status: "available",
                                block: 97,
                                unitSize: 750.0,
                                north: "25.0",
                                east: "30.0",
                                south: "25.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1828,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1828,
                                status: "available",
                                block: 97,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1829,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1829,
                                status: "available",
                                block: 97,
                                unitSize: 1125.0,
                                north: "25.0",
                                east: "45.0",
                                south: "25.0",
                                west: "45.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "15+6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1830,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1830,
                                status: "available",
                                block: 97,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1831,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1831,
                                status: "available",
                                block: 97,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1832,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1832,
                                status: "available",
                                block: 97,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1833,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1833,
                                status: "available",
                                block: 97,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1834,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1834,
                                status: "available",
                                block: 97,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1835,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1835,
                                status: "available",
                                block: 97,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1836,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1836,
                                status: "available",
                                block: 97,
                                unitSize: 374.91,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "30",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1837,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1837,
                                status: "available",
                                block: 97,
                                unitSize: 374.97,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "30",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1838,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1838,
                                status: "available",
                                block: 98,
                                unitSize: 1269.26,
                                north: "30.01",
                                east: "42.58",
                                south: "30.0",
                                west: "42.02",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1839,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1839,
                                status: "available",
                                block: 98,
                                unitSize: 450.04,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1840,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1840,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1841,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1841,
                                status: "available",
                                block: 98,
                                unitSize: 1252.23,
                                north: "30.01",
                                east: "42.02",
                                south: "30.0",
                                west: "41.46",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1842,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1842,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1843,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1843,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1844,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1844,
                                status: "available",
                                block: 98,
                                unitSize: 1235.41,
                                north: "30.01",
                                east: "41.46",
                                south: "30.0",
                                west: "40.9",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1845,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1845,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1846,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1846,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1847,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1847,
                                status: "available",
                                block: 98,
                                unitSize: 1218.59,
                                north: "30.01",
                                east: "40.9",
                                south: "30.0",
                                west: "40.34",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1848,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1848,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1849,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1849,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1850,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1850,
                                status: "available",
                                block: 98,
                                unitSize: 1201.77,
                                north: "30.01",
                                east: "40.34",
                                south: "30.0",
                                west: "39.78",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1851,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1851,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1852,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1852,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1853,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1853,
                                status: "available",
                                block: 98,
                                unitSize: 1184.95,
                                north: "30.01",
                                east: "39.78",
                                south: "30.0",
                                west: "39.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1854,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1854,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1855,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1855,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1856,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1856,
                                status: "available",
                                block: 98,
                                unitSize: 1168.09,
                                north: "30.01",
                                east: "39.22",
                                south: "30.0",
                                west: "38.65",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1857,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1857,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1858,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1858,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1859,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1859,
                                status: "available",
                                block: 98,
                                unitSize: 1227.88,
                                north: "32.03",
                                east: "38.65",
                                south: "32.01",
                                west: "38.04",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1860,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1860,
                                status: "available",
                                block: 98,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1861,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1861,
                                status: "available",
                                block: 98,
                                unitSize: 510.24,
                                north: "17.01",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1862,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1862,
                                status: "available",
                                block: 99,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1863,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1863,
                                status: "available",
                                block: 99,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1864,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1864,
                                status: "available",
                                block: 99,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1865,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1865,
                                status: "available",
                                block: 99,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "30",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1866,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1866,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1867,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1867,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1868,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1868,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1869,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1869,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1870,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1870,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1871,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1871,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1872,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1872,
                                status: "available",
                                block: 99,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1873,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1873,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1874,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1874,
                                status: "available",
                                block: 99,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1875,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1875,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1876,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1876,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1877,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1877,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1878,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1878,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1879,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1879,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1880,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1880,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1881,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1881,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1882,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1882,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1883,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1883,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1884,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1884,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1885,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1885,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1886,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1886,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1887,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1887,
                                status: "available",
                                block: 99,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1888,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1888,
                                status: "available",
                                block: 99,
                                unitSize: 405.0,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1889,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1889,
                                status: "available",
                                block: 99,
                                unitSize: 405.0,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1890,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1890,
                                status: "available",
                                block: 99,
                                unitSize: 405.0,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1891,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1891,
                                status: "available",
                                block: 99,
                                unitSize: 405.0,
                                north: "27.0",
                                east: "15.0",
                                south: "27.0",
                                west: "15.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "30",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1892,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1892,
                                status: "available",
                                block: 100,
                                unitSize: 1385.24,
                                north: "32.51",
                                east: "42.93",
                                south: "32.5",
                                west: "42.32",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1893,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1893,
                                status: "available",
                                block: 100,
                                unitSize: 1352.78,
                                north: "32.51",
                                east: "42.32",
                                south: "32.5",
                                west: "41.7",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "25",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1894,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1894,
                                status: "available",
                                block: 100,
                                unitSize: 1462.51,
                                north: "32.5",
                                east: "45.0",
                                south: "32.5",
                                west: "45.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1895,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1895,
                                status: "available",
                                block: 100,
                                unitSize: 487.5,
                                north: "32.5",
                                east: "15.0",
                                south: "32.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1896,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1896,
                                status: "available",
                                block: 100,
                                unitSize: 487.5,
                                north: "32.5",
                                east: "15.0",
                                south: "32.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1897,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1897,
                                status: "available",
                                block: 100,
                                unitSize: 487.5,
                                north: "32.5",
                                east: "15.0",
                                south: "32.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1898,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1898,
                                status: "available",
                                block: 100,
                                unitSize: 487.5,
                                north: "32.5",
                                east: "15.0",
                                south: "32.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1899,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1899,
                                status: "available",
                                block: 100,
                                unitSize: 487.5,
                                north: "32.5",
                                east: "15.0",
                                south: "32.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1900,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1900,
                                status: "available",
                                block: 100,
                                unitSize: 487.5,
                                north: "32.5",
                                east: "15.0",
                                south: "32.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1901,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1901,
                                status: "available",
                                block: 100,
                                unitSize: 487.5,
                                north: "32.5",
                                east: "15.0",
                                south: "32.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1902,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1902,
                                status: "available",
                                block: 100,
                                unitSize: 812.43,
                                north: "32.5",
                                east: "25.0",
                                south: "32.5",
                                west: "25.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "30",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1903,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1903,
                                status: "available",
                                block: 100,
                                unitSize: 812.3,
                                north: "32.5",
                                east: "25.0",
                                south: "32.5",
                                west: "25.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "30",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1904,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1904,
                                status: "available",
                                block: 101,
                                unitSize: 1431.47,
                                north: "35.01",
                                east: "41.23",
                                south: "35.0",
                                west: "40.57",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "25",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1905,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1905,
                                status: "available",
                                block: 101,
                                unitSize: 1208.55,
                                north: "30.01",
                                east: "40.57",
                                south: "30.0",
                                west: "40.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1906,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1906,
                                status: "available",
                                block: 101,
                                unitSize: 525.0,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1907,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1907,
                                status: "available",
                                block: 101,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1908,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1908,
                                status: "available",
                                block: 101,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1909,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1909,
                                status: "available",
                                block: 101,
                                unitSize: 1349.99,
                                north: "30.0",
                                east: "45.0",
                                south: "30.0",
                                west: "45.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1910,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1910,
                                status: "available",
                                block: 101,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1911,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1911,
                                status: "available",
                                block: 101,
                                unitSize: 449.99,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1912,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1912,
                                status: "available",
                                block: 101,
                                unitSize: 525.01,
                                north: "35.0",
                                east: "15.0",
                                south: "35.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1913,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1913,
                                status: "available",
                                block: 101,
                                unitSize: 499.99,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1914,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1914,
                                status: "available",
                                block: 101,
                                unitSize: 874.54,
                                north: "35.0",
                                east: "24.99",
                                south: "35.0",
                                west: "24.98",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "30",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1915,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1915,
                                status: "available",
                                block: 101,
                                unitSize: 749.45,
                                north: "30.0",
                                east: "24.98",
                                south: "30.0",
                                west: "24.98",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "30",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1916,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1916,
                                status: "available",
                                block: 102,
                                unitSize: 1226.77,
                                north: "31.01",
                                east: "39.87",
                                south: "31.0",
                                west: "39.28",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1917,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1917,
                                status: "available",
                                block: 102,
                                unitSize: 400.0,
                                north: "16.0",
                                east: "25.0",
                                south: "16.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1918,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1918,
                                status: "available",
                                block: 102,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1919,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1919,
                                status: "available",
                                block: 102,
                                unitSize: 1169.8,
                                north: "31.01",
                                east: "39.28",
                                south: "30.0",
                                west: "38.71",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1920,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1920,
                                status: "available",
                                block: 102,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1921,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1921,
                                status: "available",
                                block: 102,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1922,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1922,
                                status: "available",
                                block: 102,
                                unitSize: 1140.31,
                                north: "30.01",
                                east: "38.71",
                                south: "30.0",
                                west: "38.15",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1923,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1923,
                                status: "available",
                                block: 102,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1924,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1924,
                                status: "available",
                                block: 102,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1925,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1925,
                                status: "available",
                                block: 102,
                                unitSize: 1135.93,
                                north: "30.01",
                                east: "38.15",
                                south: "30.0",
                                west: "37.58",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1926,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1926,
                                status: "available",
                                block: 102,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1927,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1927,
                                status: "available",
                                block: 102,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1928,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1928,
                                status: "available",
                                block: 102,
                                unitSize: 1156.08,
                                north: "31.01",
                                east: "37.58",
                                south: "31.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1929,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1929,
                                status: "available",
                                block: 102,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1930,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1930,
                                status: "available",
                                block: 102,
                                unitSize: 400.01,
                                north: "16.0",
                                east: "25.0",
                                south: "16.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1931,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1931,
                                status: "available",
                                block: 103,
                                unitSize: 1140.38,
                                north: "31.23",
                                east: "36.89",
                                south: "31.09",
                                west: "36.31",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1932,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1932,
                                status: "available",
                                block: 103,
                                unitSize: 401.15,
                                north: "16.09",
                                east: "25.0",
                                south: "16.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1933,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1933,
                                status: "available",
                                block: 103,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1934,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1934,
                                status: "available",
                                block: 103,
                                unitSize: 1080.76,
                                north: "30.01",
                                east: "36.31",
                                south: "30.0",
                                west: "35.74",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1935,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1935,
                                status: "available",
                                block: 103,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1936,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1936,
                                status: "available",
                                block: 103,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1937,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1937,
                                status: "available",
                                block: 103,
                                unitSize: 1063.87,
                                north: "30.01",
                                east: "35.74",
                                south: "30.0",
                                west: "35.18",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1938,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1938,
                                status: "available",
                                block: 103,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1939,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1939,
                                status: "available",
                                block: 103,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1940,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1940,
                                status: "available",
                                block: 103,
                                unitSize: 1046.98,
                                north: "30.01",
                                east: "35.18",
                                south: "30.0",
                                west: "34.62",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1941,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1941,
                                status: "available",
                                block: 103,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1942,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1942,
                                status: "available",
                                block: 103,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1943,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1943,
                                status: "available",
                                block: 103,
                                unitSize: 1063.48,
                                north: "30.98",
                                east: "34.62",
                                south: "30.99",
                                west: "34.04",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1944,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1944,
                                status: "available",
                                block: 103,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1945,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1945,
                                status: "available",
                                block: 103,
                                unitSize: 399.89,
                                north: "15.99",
                                east: "25.0",
                                south: "16.0",
                                west: "25.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1946,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1946,
                                status: "available",
                                block: 104,
                                unitSize: 540.0,
                                north: "18.0",
                                east: "30.0",
                                south: "18.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1947,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1947,
                                status: "available",
                                block: 104,
                                unitSize: 540.0,
                                north: "18.0",
                                east: "30.0",
                                south: "18.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1948,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1948,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1949,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1949,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1950,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1950,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1951,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1951,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1952,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1952,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1953,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1953,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1954,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1954,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1955,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1955,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1956,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1956,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1957,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1957,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1958,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1958,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1959,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1959,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1960,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1960,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1961,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1961,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1962,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1962,
                                status: "available",
                                block: 104,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1963,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1963,
                                status: "available",
                                block: 104,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1964,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1964,
                                status: "available",
                                block: 104,
                                unitSize: 503.75,
                                north: "17.0",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1965,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1965,
                                status: "available",
                                block: 104,
                                unitSize: 510.0,
                                north: "17.0",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1966,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1966,
                                status: "sold",
                                block: 105,
                                unitSize: 510.0,
                                north: "17.0",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1967,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1967,
                                status: "sold",
                                block: 105,
                                unitSize: 510.0,
                                north: "17.0",
                                east: "30.0",
                                south: "17.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1968,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1968,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1969,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1969,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1970,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1970,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1971,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1971,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1972,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1972,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1973,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1973,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1974,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1974,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1975,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1975,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1976,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1976,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1977,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1977,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1978,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1978,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1979,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1979,
                                status: "sold",
                                block: 105,
                                unitSize: 480.0,
                                north: "16.0",
                                east: "30.0",
                                south: "16.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1980,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1980,
                                status: "sold",
                                block: 105,
                                unitSize: 540.0,
                                north: "18.0",
                                east: "30.0",
                                south: "18.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1981,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1981,
                                status: "sold",
                                block: 105,
                                unitSize: 540.0,
                                north: "18.0",
                                east: "30.0",
                                south: "18.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1982,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1982,
                                status: "available",
                                block: 106,
                                unitSize: 2910.25,
                                north: "50.01",
                                east: "58.92",
                                south: "50.0",
                                west: "57.99",
                                front1: "northern",
                                front2: "southern",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1983,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1983,
                                status: "available",
                                block: 107,
                                unitSize: 377.64,
                                north: "25.0",
                                east: "15.11",
                                south: "25.0",
                                west: "15.1",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1984,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1984,
                                status: "available",
                                block: 107,
                                unitSize: 377.58,
                                north: "25.0",
                                east: "15.11",
                                south: "25.0",
                                west: "15.1",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1985,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1985,
                                status: "available",
                                block: 107,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1986,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1986,
                                status: "available",
                                block: 107,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1987,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1987,
                                status: "available",
                                block: 107,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1988,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1988,
                                status: "available",
                                block: 107,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1989,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1989,
                                status: "available",
                                block: 107,
                                unitSize: 406.2,
                                north: "17.0",
                                east: "23.89",
                                south: "17.0",
                                west: "23.89",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1990,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1990,
                                status: "available",
                                block: 107,
                                unitSize: 382.33,
                                north: "16.0",
                                east: "23.89",
                                south: "16.0",
                                west: "23.9",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1991,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1991,
                                status: "available",
                                block: 107,
                                unitSize: 406.25,
                                north: "17.0",
                                east: "23.9",
                                south: "17.0",
                                west: "23.9",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1992,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1992,
                                status: "available",
                                block: 108,
                                unitSize: 1175.64,
                                north: "27.16",
                                east: "34.87",
                                south: "34.01",
                                west: "34.24",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1993,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1993,
                                status: "available",
                                block: 108,
                                unitSize: 437.14,
                                north: "19.01",
                                east: "23.0",
                                south: "19.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1994,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1994,
                                status: "available",
                                block: 108,
                                unitSize: 345.0,
                                north: "15.0",
                                east: "23.0",
                                south: "15.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1995,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1995,
                                status: "available",
                                block: 108,
                                unitSize: 1018.73,
                                north: "30.0",
                                east: "34.24",
                                south: "30.0",
                                west: "33.68",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1996,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1996,
                                status: "available",
                                block: 108,
                                unitSize: 345.0,
                                north: "15.0",
                                east: "23.0",
                                south: "15.0",
                                west: "23.0",
                                front1: "nan",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1997,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1997,
                                status: "available",
                                block: 108,
                                unitSize: 345.0,
                                north: "15.0",
                                east: "23.0",
                                south: "15.0",
                                west: "23.0",
                                front1: "nan",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1998,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1998,
                                status: "available",
                                block: 108,
                                unitSize: 1055.64,
                                north: "32.02",
                                east: "33.68",
                                south: "32.0",
                                west: "33.08",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_1999,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1999,
                                status: "available",
                                block: 108,
                                unitSize: 368.0,
                                north: "16.0",
                                east: "23.0",
                                south: "16.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2000,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2000,
                                status: "available",
                                block: 108,
                                unitSize: 368.0,
                                north: "16.0",
                                east: "23.0",
                                south: "16.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2001,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2001,
                                status: "available",
                                block: 108,
                                unitSize: 1049.03,
                                north: "32.01",
                                east: "33.08",
                                south: "32.0",
                                west: "32.48",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2002,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2002,
                                status: "available",
                                block: 108,
                                unitSize: 368.0,
                                north: "16.0",
                                east: "23.0",
                                south: "16.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2003,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2003,
                                status: "available",
                                block: 108,
                                unitSize: 368.0,
                                north: "16.0",
                                east: "23.0",
                                south: "16.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2004,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2004,
                                status: "available",
                                block: 108,
                                unitSize: 1029.92,
                                north: "32.01",
                                east: "32.48",
                                south: "32.0",
                                west: "31.89",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2005,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2005,
                                status: "available",
                                block: 108,
                                unitSize: 368.0,
                                north: "16.0",
                                east: "23.0",
                                south: "16.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2006,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2006,
                                status: "available",
                                block: 108,
                                unitSize: 368.0,
                                north: "16.0",
                                east: "23.0",
                                south: "16.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2007,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2007,
                                status: "available",
                                block: 108,
                                unitSize: 1073.36,
                                north: "34.01",
                                east: "31.89",
                                south: "34.0",
                                west: "31.25",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2008,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2008,
                                status: "available",
                                block: 108,
                                unitSize: 391.0,
                                north: "17.0",
                                east: "23.0",
                                south: "17.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2009,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2009,
                                status: "available",
                                block: 108,
                                unitSize: 391.0,
                                north: "17.0",
                                east: "23.0",
                                south: "17.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2010,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2010,
                                status: "available",
                                block: 108,
                                unitSize: 1051.78,
                                north: "34.01",
                                east: "31.25",
                                south: "34.0",
                                west: "30.62",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2011,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2011,
                                status: "available",
                                block: 108,
                                unitSize: 391.0,
                                north: "17.0",
                                east: "23.0",
                                south: "17.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2012,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2012,
                                status: "available",
                                block: 108,
                                unitSize: 391.0,
                                north: "17.0",
                                east: "23.0",
                                south: "17.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2013,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2013,
                                status: "available",
                                block: 108,
                                unitSize: 1077.02,
                                north: "35.98",
                                east: "30.62",
                                south: "35.99",
                                west: "29.95",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2014,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2014,
                                status: "available",
                                block: 108,
                                unitSize: 391.0,
                                north: "17.0",
                                east: "23.0",
                                south: "17.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2015,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2015,
                                status: "available",
                                block: 108,
                                unitSize: 436.86,
                                north: "18.99",
                                east: "23.0",
                                south: "19.0",
                                west: "23.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2016,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2016,
                                status: "available",
                                block: 109,
                                unitSize: 540.0,
                                north: "18.0",
                                east: "30.0",
                                south: "18.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2017,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2017,
                                status: "sold",
                                block: 109,
                                unitSize: 540.0,
                                north: "18.0",
                                east: "30.0",
                                south: "18.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_31_2018,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2018,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2019,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2019,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2020,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2020,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2021,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2021,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2022,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2022,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2023,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2023,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2024,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2024,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2025,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2025,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2026,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2026,
                                status: "available",
                                block: 109,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2027,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2027,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2028,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2028,
                                status: "available",
                                block: 109,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2029,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2029,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2030,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2030,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2031,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2031,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2032,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2032,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2033,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2033,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2034,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2034,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2035,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2035,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2036,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2036,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2037,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2037,
                                status: "available",
                                block: 109,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        // {
                        //     Polygon: LandPlot_31_2038,
                        //     className: null,
                        //     infobox: {
                        //         type: "land_info",
                        //         index: 2038,
                        //         status: "available",
                        //         block: 109,
                        //         unitSize: 0.0,
                        //         north: "15.0",
                        //         east: "30.0",
                        //         south: "15.0",
                        //         west: "30.0",
                        //         front1: "northern",
                        //         front2: "nan",
                        //         electricTransformer: false,
                        //         st_width_1: "15",
                        //         st_width_2: "0",
                        //     },
                        //     onClick: {
                        //         "action": "open_contact_form"
                        //     }
                        // },
                        // {
                        //     Polygon: LandPlot_31_2039,
                        //     className: null,
                        //     infobox: {
                        //         type: "land_info",
                        //         index: 2039,
                        //         status: "available",
                        //         block: 109,
                        //         unitSize: 0.0,
                        //         north: "15.0",
                        //         east: "30.0",
                        //         south: "15.0",
                        //         west: "30.0",
                        //         front1: "southern",
                        //         front2: "nan",
                        //         electricTransformer: false,
                        //         st_width_1: "30",
                        //         st_width_2: "0",
                        //     },
                        //     onClick: {
                        //         "action": "open_contact_form"
                        //     }
                        // },
                        // {
                        //     Polygon: LandPlot_31_2040,
                        //     className: null,
                        //     infobox: {
                        //         type: "land_info",
                        //         index: 2040,
                        //         status: "available",
                        //         block: 109,
                        //         unitSize: 0.0,
                        //         north: "15.0",
                        //         east: "30.0",
                        //         south: "15.0",
                        //         west: "30.0",
                        //         front1: "northern",
                        //         front2: "nan",
                        //         electricTransformer: false,
                        //         st_width_1: "15",
                        //         st_width_2: "0",
                        //     },
                        //     onClick: {
                        //         "action": "open_contact_form"
                        //     }
                        // },
                        // {
                        //     Polygon: LandPlot_31_2041,
                        //     className: null,
                        //     infobox: {
                        //         type: "land_info",
                        //         index: 2041,
                        //         status: "available",
                        //         block: 109,
                        //         unitSize: 0.0,
                        //         north: "15.0",
                        //         east: "30.0",
                        //         south: "15.0",
                        //         west: "30.0",
                        //         front1: "southern",
                        //         front2: "nan",
                        //         electricTransformer: false,
                        //         st_width_1: "30",
                        //         st_width_2: "0",
                        //     },
                        //     onClick: {
                        //         "action": "open_contact_form"
                        //     }
                        // },
                        // {
                        //     Polygon: LandPlot_31_2042,
                        //     className: null,
                        //     infobox: {
                        //         type: "land_info",
                        //         index: 2042,
                        //         status: "available",
                        //         block: 109,
                        //         unitSize: 0.0,
                        //         north: "15.0",
                        //         east: "30.0",
                        //         south: "15.0",
                        //         west: "30.0",
                        //         front1: "northern",
                        //         front2: "nan",
                        //         electricTransformer: false,
                        //         st_width_1: "15",
                        //         st_width_2: "0",
                        //     },
                        //     onClick: {
                        //         "action": "open_contact_form"
                        //     }
                        // },
                        // {
                        //     Polygon: LandPlot_31_2043,
                        //     className: null,
                        //     infobox: {
                        //         type: "land_info",
                        //         index: 2043,
                        //         status: "available",
                        //         block: 109,
                        //         unitSize: 0.0,
                        //         north: "15.0",
                        //         east: "30.0",
                        //         south: "15.0",
                        //         west: "30.0",
                        //         front1: "southern",
                        //         front2: "nan",
                        //         electricTransformer: false,
                        //         st_width_1: "30",
                        //         st_width_2: "0",
                        //     },
                        //     onClick: {
                        //         "action": "open_contact_form"
                        //     }
                        // },
                        // {
                        //     Polygon: LandPlot_31_2044,
                        //     className: null,
                        //     infobox: {
                        //         type: "land_info",
                        //         index: 2044,
                        //         status: "sold",
                        //         block: 109,
                        //         unitSize: 0.0,
                        //         north: "15.0",
                        //         east: "30.0",
                        //         south: "15.0",
                        //         west: "30.0",
                        //         front1: "northern",
                        //         front2: "nan",
                        //         electricTransformer: false,
                        //         st_width_1: "15",
                        //         st_width_2: "0",
                        //     },
                        // },
                        // {
                        //     Polygon: LandPlot_31_2045,
                        //     className: null,
                        //     infobox: {
                        //         type: "land_info",
                        //         index: 2045,
                        //         status: "sold",
                        //         block: 109,
                        //         unitSize: 0.0,
                        //         north: "15.0",
                        //         east: "30.0",
                        //         south: "15.0",
                        //         west: "30.0",
                        //         front1: "southern",
                        //         front2: "nan",
                        //         electricTransformer: false,
                        //         st_width_1: "30",
                        //         st_width_2: "0",
                        //     },
                        // },
                        // {
                        //     Polygon: LandPlot_31_2046,
                        //     className: null,
                        //     infobox: {
                        //         type: "land_info",
                        //         index: 2046,
                        //         status: "sold",
                        //         block: 109,
                        //         unitSize: 0.0,
                        //         north: "18.0",
                        //         east: "30.0",
                        //         south: "18.0",
                        //         west: "30.0",
                        //         front1: "northern",
                        //         front2: "western",
                        //         electricTransformer: false,
                        //         st_width_1: "15",
                        //         st_width_2: "15",
                        //     },
                        // },
                        // {
                        //     Polygon: LandPlot_31_2047,
                        //     className: null,
                        //     infobox: {
                        //         type: "land_info",
                        //         index: 2047,
                        //         status: "sold",
                        //         block: 109,
                        //         unitSize: 0.0,
                        //         north: "18.0",
                        //         east: "30.0",
                        //         south: "18.0",
                        //         west: "30.0",
                        //         front1: "southern",
                        //         front2: "western",
                        //         electricTransformer: false,
                        //         st_width_1: "30",
                        //         st_width_2: "15",
                        //     },
                        // },
                        {
                            Polygon: LandPlot_31_2048,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2048,
                                status: "available",
                                block: 110,
                                unitSize: 1859.27,
                                north: "42.43",
                                east: "47.83",
                                south: "35.91",
                                west: "47.49",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2049,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2049,
                                status: "available",
                                block: 110,
                                unitSize: 690.6,
                                north: "35.91",
                                east: "20.0",
                                south: "33.15",
                                west: "20.19",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2050,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2050,
                                status: "available",
                                block: 110,
                                unitSize: 635.27,
                                north: "33.15",
                                east: "20.0",
                                south: "30.38",
                                west: "20.19",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2051,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2051,
                                status: "available",
                                block: 110,
                                unitSize: 579.93,
                                north: "30.38",
                                east: "20.0",
                                south: "27.61",
                                west: "20.19",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                        {
                            Polygon: LandPlot_31_2052,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 2052,
                                status: "available",
                                block: 110,
                                unitSize: 524.62,
                                north: "27.61",
                                east: "20.0",
                                south: "24.85",
                                west: "20.19",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "30",
                            },
                            onClick: {
                                "action": "open_contact_form"
                            }
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_32_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_32_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_32_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_32_4,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_32_5,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_32_6,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_32_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_32_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_32_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_32_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_32_2,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
    ////////////////////////////////////////////////////////////////////////////////////////index 61
    {
            index: 62,
            default: true,
            wrapper: (
                <>
                    <SvgAreas
                        areas={[
                            {
                                Polygon: LandPlot_61_1751,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1751,
                                    status: "available",
                                    block: 93,
                                    unitSize: 1151.74,
                                    north: "25.81",
                                    east: "49.77",
                                    south: "25.0",
                                    west: "43.37",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: true,
                                    st_width_1: "60",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1752,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1752,
                                    status: "available",
                                    block: 93,
                                    unitSize: 1004.31,
                                    north: "25.81",
                                    east: "43.37",
                                    south: "25.0",
                                    west: "36.97",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1753,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1753,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1754,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1754,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1755,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1755,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1756,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1756,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1757,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1757,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1758,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1758,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1759,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1759,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1760,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1760,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1761,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1761,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1762,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1762,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1763,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1763,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1764,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1764,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1765,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1765,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1766,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1766,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1767,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1767,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1768,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1768,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1769,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1769,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1770,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1770,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1771,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1771,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1772,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1772,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1773,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1773,
                                    status: "available",
                                    block: 93,
                                    unitSize: 374.97,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1774,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1774,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.03,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1775,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1775,
                                    status: "available",
                                    block: 94,
                                    unitSize: 1448.27,
                                    north: "42.45",
                                    east: "39.73",
                                    south: "33.46",
                                    west: "37.74",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1776,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1776,
                                    status: "available",
                                    block: 94,
                                    unitSize: 1122.19,
                                    north: "30.01",
                                    east: "37.74",
                                    south: "30.0",
                                    west: "37.07",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1777,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1777,
                                    status: "available",
                                    block: 94,
                                    unitSize: 1101.93,
                                    north: "30.01",
                                    east: "37.07",
                                    south: "30.0",
                                    west: "36.39",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1778,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1778,
                                    status: "available",
                                    block: 94,
                                    unitSize: 1081.67,
                                    north: "30.01",
                                    east: "36.39",
                                    south: "30.0",
                                    west: "35.72",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1779,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1779,
                                    status: "available",
                                    block: 94,
                                    unitSize: 1061.41,
                                    north: "30.01",
                                    east: "35.72",
                                    south: "30.0",
                                    west: "35.04",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1780,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1780,
                                    status: "available",
                                    block: 94,
                                    unitSize: 1042.89,
                                    north: "30.01",
                                    east: "35.04",
                                    south: "30.0",
                                    west: "34.75",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1781,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1781,
                                    status: "available",
                                    block: 94,
                                    unitSize: 1306.42,
                                    north: "31.87",
                                    east: "34.75",
                                    south: "40.49",
                                    west: "38.88",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1782,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1782,
                                    status: "available",
                                    block: 95,
                                    unitSize: 811.69,
                                    north: "15.68",
                                    east: "38.22",
                                    south: "20.15",
                                    west: "38.56",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1783,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1783,
                                    status: "available",
                                    block: 95,
                                    unitSize: 503.69,
                                    north: "20.15",
                                    east: "25.0",
                                    south: "20.15",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1784,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1784,
                                    status: "available",
                                    block: 95,
                                    unitSize: 552.23,
                                    north: "15.4",
                                    east: "38.56",
                                    south: "15.0",
                                    west: "35.08",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1785,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1785,
                                    status: "available",
                                    block: 95,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1786,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1786,
                                    status: "available",
                                    block: 95,
                                    unitSize: 500.04,
                                    north: "15.4",
                                    east: "35.08",
                                    south: "15.0",
                                    west: "31.6",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1787,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1787,
                                    status: "available",
                                    block: 95,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1788,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1788,
                                    status: "available",
                                    block: 95,
                                    unitSize: 447.86,
                                    north: "15.4",
                                    east: "31.6",
                                    south: "15.0",
                                    west: "28.12",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1789,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1789,
                                    status: "available",
                                    block: 95,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1790,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1790,
                                    status: "available",
                                    block: 95,
                                    unitSize: 395.67,
                                    north: "15.4",
                                    east: "28.12",
                                    south: "15.0",
                                    west: "24.64",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1791,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1791,
                                    status: "available",
                                    block: 95,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1792,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1792,
                                    status: "available",
                                    block: 95,
                                    unitSize: 343.48,
                                    north: "15.4",
                                    east: "24.64",
                                    south: "15.0",
                                    west: "21.16",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1793,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1793,
                                    status: "available",
                                    block: 95,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1794,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1794,
                                    status: "available",
                                    block: 95,
                                    unitSize: 530.41,
                                    north: "30.8",
                                    east: "21.16",
                                    south: "30.0",
                                    west: "14.2",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1795,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1795,
                                    status: "available",
                                    block: 95,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1796,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1796,
                                    status: "available",
                                    block: 95,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1797,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1797,
                                    status: "available",
                                    block: 95,
                                    unitSize: 668.05,
                                    north: "18.48",
                                    east: "39.2",
                                    south: "18.0",
                                    west: "35.03",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1798,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1798,
                                    status: "available",
                                    block: 95,
                                    unitSize: 592.9,
                                    north: "18.48",
                                    east: "35.03",
                                    south: "18.0",
                                    west: "30.85",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1799,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1799,
                                    status: "available",
                                    block: 95,
                                    unitSize: 517.75,
                                    north: "18.48",
                                    east: "30.85",
                                    south: "18.0",
                                    west: "26.68",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1800,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1800,
                                    status: "available",
                                    block: 95,
                                    unitSize: 509.06,
                                    north: "21.56",
                                    east: "26.68",
                                    south: "21.0",
                                    west: "21.81",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1801,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1801,
                                    status: "available",
                                    block: 96,
                                    unitSize: 694.43,
                                    north: "23.15",
                                    east: "30.0",
                                    south: "23.15",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1802,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1802,
                                    status: "available",
                                    block: 96,
                                    unitSize: 694.43,
                                    north: "23.15",
                                    east: "30.0",
                                    south: "23.15",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1803,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1803,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1804,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1804,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1805,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1805,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1806,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1806,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1807,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1807,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1808,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1808,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1809,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1809,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1810,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1810,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1811,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1811,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1812,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1812,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1813,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1813,
                                    status: "available",
                                    block: 96,
                                    unitSize: 443.75,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1814,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1814,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1815,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1815,
                                    status: "available",
                                    block: 96,
                                    unitSize: 443.75,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1816,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1816,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1817,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1817,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1818,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1818,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1819,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1819,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1820,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1820,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1821,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1821,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1822,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1822,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1823,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1823,
                                    status: "available",
                                    block: 96,
                                    unitSize: 405.0,
                                    north: "27.0",
                                    east: "15.0",
                                    south: "27.0",
                                    west: "15.0",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1824,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1824,
                                    status: "available",
                                    block: 96,
                                    unitSize: 405.0,
                                    north: "27.0",
                                    east: "15.0",
                                    south: "27.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1825,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1825,
                                    status: "available",
                                    block: 96,
                                    unitSize: 405.0,
                                    north: "27.0",
                                    east: "15.0",
                                    south: "27.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1826,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1826,
                                    status: "available",
                                    block: 96,
                                    unitSize: 405.0,
                                    north: "27.0",
                                    east: "15.0",
                                    south: "27.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "30",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1827,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1827,
                                    status: "available",
                                    block: 97,
                                    unitSize: 750.0,
                                    north: "25.0",
                                    east: "30.0",
                                    south: "25.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "6",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1828,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1828,
                                    status: "available",
                                    block: 97,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1829,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1829,
                                    status: "available",
                                    block: 97,
                                    unitSize: 1125.0,
                                    north: "25.0",
                                    east: "45.0",
                                    south: "25.0",
                                    west: "45.0",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "6",
                                    st_width_2: "15+6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1830,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1830,
                                    status: "available",
                                    block: 97,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1831,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1831,
                                    status: "available",
                                    block: 97,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1832,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1832,
                                    status: "available",
                                    block: 97,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1833,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1833,
                                    status: "available",
                                    block: 97,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1834,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1834,
                                    status: "available",
                                    block: 97,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1835,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1835,
                                    status: "available",
                                    block: 97,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1836,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1836,
                                    status: "available",
                                    block: 97,
                                    unitSize: 374.91,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "30",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1837,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1837,
                                    status: "available",
                                    block: 97,
                                    unitSize: 374.97,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "30",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1838,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1838,
                                    status: "available",
                                    block: 98,
                                    unitSize: 1269.26,
                                    north: "30.01",
                                    east: "42.58",
                                    south: "30.0",
                                    west: "42.02",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1839,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1839,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.04,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1840,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1840,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1841,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1841,
                                    status: "available",
                                    block: 98,
                                    unitSize: 1252.23,
                                    north: "30.01",
                                    east: "42.02",
                                    south: "30.0",
                                    west: "41.46",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1842,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1842,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1843,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1843,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1844,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1844,
                                    status: "available",
                                    block: 98,
                                    unitSize: 1235.41,
                                    north: "30.01",
                                    east: "41.46",
                                    south: "30.0",
                                    west: "40.9",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1845,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1845,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1846,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1846,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1847,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1847,
                                    status: "available",
                                    block: 98,
                                    unitSize: 1218.59,
                                    north: "30.01",
                                    east: "40.9",
                                    south: "30.0",
                                    west: "40.34",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1848,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1848,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1849,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1849,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1850,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1850,
                                    status: "available",
                                    block: 98,
                                    unitSize: 1201.77,
                                    north: "30.01",
                                    east: "40.34",
                                    south: "30.0",
                                    west: "39.78",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1851,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1851,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1852,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1852,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1853,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1853,
                                    status: "available",
                                    block: 98,
                                    unitSize: 1184.95,
                                    north: "30.01",
                                    east: "39.78",
                                    south: "30.0",
                                    west: "39.22",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1854,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1854,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1855,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1855,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1856,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1856,
                                    status: "available",
                                    block: 98,
                                    unitSize: 1168.09,
                                    north: "30.01",
                                    east: "39.22",
                                    south: "30.0",
                                    west: "38.65",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1857,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1857,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1858,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1858,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1859,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1859,
                                    status: "available",
                                    block: 98,
                                    unitSize: 1227.88,
                                    north: "32.03",
                                    east: "38.65",
                                    south: "32.01",
                                    west: "38.04",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1860,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1860,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1861,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1861,
                                    status: "available",
                                    block: 98,
                                    unitSize: 510.24,
                                    north: "17.01",
                                    east: "30.0",
                                    south: "17.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1862,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1862,
                                    status: "available",
                                    block: 99,
                                    unitSize: 480.0,
                                    north: "32.0",
                                    east: "15.0",
                                    south: "32.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "northern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1863,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1863,
                                    status: "available",
                                    block: 99,
                                    unitSize: 480.0,
                                    north: "32.0",
                                    east: "15.0",
                                    south: "32.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1864,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1864,
                                    status: "available",
                                    block: 99,
                                    unitSize: 480.0,
                                    north: "32.0",
                                    east: "15.0",
                                    south: "32.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1865,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1865,
                                    status: "available",
                                    block: 99,
                                    unitSize: 480.0,
                                    north: "32.0",
                                    east: "15.0",
                                    south: "32.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "30",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1866,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1866,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1867,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1867,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1868,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1868,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1869,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1869,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1870,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1870,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1871,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1871,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1872,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1872,
                                    status: "available",
                                    block: 99,
                                    unitSize: 443.75,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1873,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1873,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1874,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1874,
                                    status: "available",
                                    block: 99,
                                    unitSize: 443.75,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1875,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1875,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1876,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1876,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1877,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1877,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1878,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1878,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1879,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1879,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1880,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1880,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1881,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1881,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1882,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1882,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1883,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1883,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1884,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1884,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1885,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1885,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1886,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1886,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1887,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1887,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1888,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1888,
                                    status: "available",
                                    block: 99,
                                    unitSize: 405.0,
                                    north: "27.0",
                                    east: "15.0",
                                    south: "27.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "northern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1889,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1889,
                                    status: "available",
                                    block: 99,
                                    unitSize: 405.0,
                                    north: "27.0",
                                    east: "15.0",
                                    south: "27.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1890,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1890,
                                    status: "available",
                                    block: 99,
                                    unitSize: 405.0,
                                    north: "27.0",
                                    east: "15.0",
                                    south: "27.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1891,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1891,
                                    status: "available",
                                    block: 99,
                                    unitSize: 405.0,
                                    north: "27.0",
                                    east: "15.0",
                                    south: "27.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "30",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1892,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1892,
                                    status: "available",
                                    block: 100,
                                    unitSize: 1385.24,
                                    north: "32.51",
                                    east: "42.93",
                                    south: "32.5",
                                    west: "42.32",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1893,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1893,
                                    status: "available",
                                    block: 100,
                                    unitSize: 1352.78,
                                    north: "32.51",
                                    east: "42.32",
                                    south: "32.5",
                                    west: "41.7",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: true,
                                    st_width_1: "60",
                                    st_width_2: "25",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1894,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1894,
                                    status: "available",
                                    block: 100,
                                    unitSize: 1462.51,
                                    north: "32.5",
                                    east: "45.0",
                                    south: "32.5",
                                    west: "45.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15+6",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1895,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1895,
                                    status: "available",
                                    block: 100,
                                    unitSize: 487.5,
                                    north: "32.5",
                                    east: "15.0",
                                    south: "32.5",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1896,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1896,
                                    status: "available",
                                    block: 100,
                                    unitSize: 487.5,
                                    north: "32.5",
                                    east: "15.0",
                                    south: "32.5",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1897,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1897,
                                    status: "available",
                                    block: 100,
                                    unitSize: 487.5,
                                    north: "32.5",
                                    east: "15.0",
                                    south: "32.5",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1898,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1898,
                                    status: "available",
                                    block: 100,
                                    unitSize: 487.5,
                                    north: "32.5",
                                    east: "15.0",
                                    south: "32.5",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1899,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1899,
                                    status: "available",
                                    block: 100,
                                    unitSize: 487.5,
                                    north: "32.5",
                                    east: "15.0",
                                    south: "32.5",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1900,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1900,
                                    status: "available",
                                    block: 100,
                                    unitSize: 487.5,
                                    north: "32.5",
                                    east: "15.0",
                                    south: "32.5",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1901,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1901,
                                    status: "available",
                                    block: 100,
                                    unitSize: 487.5,
                                    north: "32.5",
                                    east: "15.0",
                                    south: "32.5",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1902,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1902,
                                    status: "available",
                                    block: 100,
                                    unitSize: 812.43,
                                    north: "32.5",
                                    east: "25.0",
                                    south: "32.5",
                                    west: "25.0",
                                    front1: "eastern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "30",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1903,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1903,
                                    status: "available",
                                    block: 100,
                                    unitSize: 812.3,
                                    north: "32.5",
                                    east: "25.0",
                                    south: "32.5",
                                    west: "25.0",
                                    front1: "western",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "30",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1904,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1904,
                                    status: "available",
                                    block: 101,
                                    unitSize: 1431.47,
                                    north: "35.01",
                                    east: "41.23",
                                    south: "35.0",
                                    west: "40.57",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "25",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1905,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1905,
                                    status: "available",
                                    block: 101,
                                    unitSize: 1208.55,
                                    north: "30.01",
                                    east: "40.57",
                                    south: "30.0",
                                    west: "40.0",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1906,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1906,
                                    status: "available",
                                    block: 101,
                                    unitSize: 525.0,
                                    north: "35.0",
                                    east: "15.0",
                                    south: "35.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1907,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1907,
                                    status: "available",
                                    block: 101,
                                    unitSize: 525.01,
                                    north: "35.0",
                                    east: "15.0",
                                    south: "35.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1908,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1908,
                                    status: "available",
                                    block: 101,
                                    unitSize: 525.01,
                                    north: "35.0",
                                    east: "15.0",
                                    south: "35.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1909,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1909,
                                    status: "available",
                                    block: 101,
                                    unitSize: 1349.99,
                                    north: "30.0",
                                    east: "45.0",
                                    south: "30.0",
                                    west: "45.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15+6",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1910,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1910,
                                    status: "available",
                                    block: 101,
                                    unitSize: 525.01,
                                    north: "35.0",
                                    east: "15.0",
                                    south: "35.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1911,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1911,
                                    status: "available",
                                    block: 101,
                                    unitSize: 449.99,
                                    north: "30.0",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1912,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1912,
                                    status: "available",
                                    block: 101,
                                    unitSize: 525.01,
                                    north: "35.0",
                                    east: "15.0",
                                    south: "35.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1913,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1913,
                                    status: "available",
                                    block: 101,
                                    unitSize: 499.99,
                                    north: "30.0",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1914,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1914,
                                    status: "available",
                                    block: 101,
                                    unitSize: 874.54,
                                    north: "35.0",
                                    east: "24.99",
                                    south: "35.0",
                                    west: "24.98",
                                    front1: "eastern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "30",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1915,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1915,
                                    status: "available",
                                    block: 101,
                                    unitSize: 749.45,
                                    north: "30.0",
                                    east: "24.98",
                                    south: "30.0",
                                    west: "24.98",
                                    front1: "western",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "30",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1916,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1916,
                                    status: "available",
                                    block: 102,
                                    unitSize: 1226.77,
                                    north: "31.01",
                                    east: "39.87",
                                    south: "31.0",
                                    west: "39.28",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1917,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1917,
                                    status: "available",
                                    block: 102,
                                    unitSize: 400.0,
                                    north: "16.0",
                                    east: "25.0",
                                    south: "16.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1918,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1918,
                                    status: "available",
                                    block: 102,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1919,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1919,
                                    status: "available",
                                    block: 102,
                                    unitSize: 1169.8,
                                    north: "31.01",
                                    east: "39.28",
                                    south: "30.0",
                                    west: "38.71",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1920,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1920,
                                    status: "available",
                                    block: 102,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1921,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1921,
                                    status: "available",
                                    block: 102,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1922,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1922,
                                    status: "available",
                                    block: 102,
                                    unitSize: 1140.31,
                                    north: "30.01",
                                    east: "38.71",
                                    south: "30.0",
                                    west: "38.15",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1923,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1923,
                                    status: "available",
                                    block: 102,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1924,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1924,
                                    status: "available",
                                    block: 102,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1925,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1925,
                                    status: "available",
                                    block: 102,
                                    unitSize: 1135.93,
                                    north: "30.01",
                                    east: "38.15",
                                    south: "30.0",
                                    west: "37.58",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1926,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1926,
                                    status: "available",
                                    block: 102,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1927,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1927,
                                    status: "available",
                                    block: 102,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1928,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1928,
                                    status: "available",
                                    block: 102,
                                    unitSize: 1156.08,
                                    north: "31.01",
                                    east: "37.58",
                                    south: "31.0",
                                    west: "37.0",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1929,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1929,
                                    status: "available",
                                    block: 102,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1930,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1930,
                                    status: "available",
                                    block: 102,
                                    unitSize: 400.01,
                                    north: "16.0",
                                    east: "25.0",
                                    south: "16.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1931,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1931,
                                    status: "available",
                                    block: 103,
                                    unitSize: 1140.38,
                                    north: "31.23",
                                    east: "36.89",
                                    south: "31.09",
                                    west: "36.31",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1932,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1932,
                                    status: "available",
                                    block: 103,
                                    unitSize: 401.15,
                                    north: "16.09",
                                    east: "25.0",
                                    south: "16.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1933,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1933,
                                    status: "available",
                                    block: 103,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1934,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1934,
                                    status: "available",
                                    block: 103,
                                    unitSize: 1080.76,
                                    north: "30.01",
                                    east: "36.31",
                                    south: "30.0",
                                    west: "35.74",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1935,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1935,
                                    status: "available",
                                    block: 103,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1936,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1936,
                                    status: "available",
                                    block: 103,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1937,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1937,
                                    status: "available",
                                    block: 103,
                                    unitSize: 1063.87,
                                    north: "30.01",
                                    east: "35.74",
                                    south: "30.0",
                                    west: "35.18",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1938,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1938,
                                    status: "available",
                                    block: 103,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1939,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1939,
                                    status: "available",
                                    block: 103,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1940,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1940,
                                    status: "available",
                                    block: 103,
                                    unitSize: 1046.98,
                                    north: "30.01",
                                    east: "35.18",
                                    south: "30.0",
                                    west: "34.62",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1941,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1941,
                                    status: "available",
                                    block: 103,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1942,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1942,
                                    status: "available",
                                    block: 103,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1943,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1943,
                                    status: "available",
                                    block: 103,
                                    unitSize: 1063.48,
                                    north: "30.98",
                                    east: "34.62",
                                    south: "30.99",
                                    west: "34.04",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1944,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1944,
                                    status: "available",
                                    block: 103,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1945,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1945,
                                    status: "available",
                                    block: 103,
                                    unitSize: 399.89,
                                    north: "15.99",
                                    east: "25.0",
                                    south: "16.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1946,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1946,
                                    status: "available",
                                    block: 104,
                                    unitSize: 540.0,
                                    north: "18.0",
                                    east: "30.0",
                                    south: "18.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1947,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1947,
                                    status: "available",
                                    block: 104,
                                    unitSize: 540.0,
                                    north: "18.0",
                                    east: "30.0",
                                    south: "18.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1948,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1948,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1949,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1949,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1950,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1950,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1951,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1951,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1952,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1952,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1953,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1953,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1954,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1954,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1955,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1955,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1956,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1956,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1957,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1957,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1958,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1958,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1959,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1959,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1960,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1960,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1961,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1961,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1962,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1962,
                                    status: "available",
                                    block: 104,
                                    unitSize: 443.75,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1963,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1963,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1964,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1964,
                                    status: "available",
                                    block: 104,
                                    unitSize: 503.75,
                                    north: "17.0",
                                    east: "30.0",
                                    south: "17.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: true,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1965,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1965,
                                    status: "available",
                                    block: 104,
                                    unitSize: 510.0,
                                    north: "17.0",
                                    east: "30.0",
                                    south: "17.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1966,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1966,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 510.0,
                                    north: "17.0",
                                    east: "30.0",
                                    south: "17.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                            },
                            {
                                Polygon: LandPlot_61_1967,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1967,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 510.0,
                                    north: "17.0",
                                    east: "30.0",
                                    south: "17.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "6",
                                },
                            },
                            {
                                Polygon: LandPlot_61_1968,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1968,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_61_1969,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1969,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_61_1970,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1970,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_61_1971,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1971,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_61_1972,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1972,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_61_1973,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1973,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_61_1974,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1974,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_61_1975,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1975,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_61_1976,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1976,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_61_1977,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1977,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_61_1978,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1978,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_61_1979,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1979,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_61_1980,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1980,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 540.0,
                                    north: "18.0",
                                    east: "30.0",
                                    south: "18.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                            },
                            {
                                Polygon: LandPlot_61_1981,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1981,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 540.0,
                                    north: "18.0",
                                    east: "30.0",
                                    south: "18.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "15",
                                },
                            },
                            {
                                Polygon: LandPlot_61_1982,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1982,
                                    status: "available",
                                    block: 106,
                                    unitSize: 2910.25,
                                    north: "50.01",
                                    east: "58.92",
                                    south: "50.0",
                                    west: "57.99",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: true,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1983,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1983,
                                    status: "available",
                                    block: 107,
                                    unitSize: 377.64,
                                    north: "25.0",
                                    east: "15.11",
                                    south: "25.0",
                                    west: "15.1",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "6",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1984,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1984,
                                    status: "available",
                                    block: 107,
                                    unitSize: 377.58,
                                    north: "25.0",
                                    east: "15.11",
                                    south: "25.0",
                                    west: "15.1",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "6",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1985,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1985,
                                    status: "available",
                                    block: 107,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1986,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1986,
                                    status: "available",
                                    block: 107,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1987,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1987,
                                    status: "available",
                                    block: 107,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1988,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1988,
                                    status: "available",
                                    block: 107,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1989,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1989,
                                    status: "available",
                                    block: 107,
                                    unitSize: 406.2,
                                    north: "17.0",
                                    east: "23.89",
                                    south: "17.0",
                                    west: "23.89",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1990,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1990,
                                    status: "available",
                                    block: 107,
                                    unitSize: 382.33,
                                    north: "16.0",
                                    east: "23.89",
                                    south: "16.0",
                                    west: "23.9",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1991,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1991,
                                    status: "available",
                                    block: 107,
                                    unitSize: 406.25,
                                    north: "17.0",
                                    east: "23.9",
                                    south: "17.0",
                                    west: "23.9",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1992,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1992,
                                    status: "available",
                                    block: 108,
                                    unitSize: 1175.64,
                                    north: "27.16",
                                    east: "34.87",
                                    south: "34.01",
                                    west: "34.24",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1993,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1993,
                                    status: "available",
                                    block: 108,
                                    unitSize: 437.14,
                                    north: "19.01",
                                    east: "23.0",
                                    south: "19.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1994,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1994,
                                    status: "available",
                                    block: 108,
                                    unitSize: 345.0,
                                    north: "15.0",
                                    east: "23.0",
                                    south: "15.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1995,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1995,
                                    status: "available",
                                    block: 108,
                                    unitSize: 1018.73,
                                    north: "30.0",
                                    east: "34.24",
                                    south: "30.0",
                                    west: "33.68",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1996,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1996,
                                    status: "available",
                                    block: 108,
                                    unitSize: 345.0,
                                    north: "15.0",
                                    east: "23.0",
                                    south: "15.0",
                                    west: "23.0",
                                    front1: "nan",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1997,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1997,
                                    status: "available",
                                    block: 108,
                                    unitSize: 345.0,
                                    north: "15.0",
                                    east: "23.0",
                                    south: "15.0",
                                    west: "23.0",
                                    front1: "nan",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1998,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1998,
                                    status: "available",
                                    block: 108,
                                    unitSize: 1055.64,
                                    north: "32.02",
                                    east: "33.68",
                                    south: "32.0",
                                    west: "33.08",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_1999,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1999,
                                    status: "available",
                                    block: 108,
                                    unitSize: 368.0,
                                    north: "16.0",
                                    east: "23.0",
                                    south: "16.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2000,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2000,
                                    status: "available",
                                    block: 108,
                                    unitSize: 368.0,
                                    north: "16.0",
                                    east: "23.0",
                                    south: "16.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2001,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2001,
                                    status: "available",
                                    block: 108,
                                    unitSize: 1049.03,
                                    north: "32.01",
                                    east: "33.08",
                                    south: "32.0",
                                    west: "32.48",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2002,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2002,
                                    status: "available",
                                    block: 108,
                                    unitSize: 368.0,
                                    north: "16.0",
                                    east: "23.0",
                                    south: "16.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2003,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2003,
                                    status: "available",
                                    block: 108,
                                    unitSize: 368.0,
                                    north: "16.0",
                                    east: "23.0",
                                    south: "16.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2004,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2004,
                                    status: "available",
                                    block: 108,
                                    unitSize: 1029.92,
                                    north: "32.01",
                                    east: "32.48",
                                    south: "32.0",
                                    west: "31.89",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2005,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2005,
                                    status: "available",
                                    block: 108,
                                    unitSize: 368.0,
                                    north: "16.0",
                                    east: "23.0",
                                    south: "16.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2006,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2006,
                                    status: "available",
                                    block: 108,
                                    unitSize: 368.0,
                                    north: "16.0",
                                    east: "23.0",
                                    south: "16.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2007,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2007,
                                    status: "available",
                                    block: 108,
                                    unitSize: 1073.36,
                                    north: "34.01",
                                    east: "31.89",
                                    south: "34.0",
                                    west: "31.25",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2008,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2008,
                                    status: "available",
                                    block: 108,
                                    unitSize: 391.0,
                                    north: "17.0",
                                    east: "23.0",
                                    south: "17.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2009,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2009,
                                    status: "available",
                                    block: 108,
                                    unitSize: 391.0,
                                    north: "17.0",
                                    east: "23.0",
                                    south: "17.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2010,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2010,
                                    status: "available",
                                    block: 108,
                                    unitSize: 1051.78,
                                    north: "34.01",
                                    east: "31.25",
                                    south: "34.0",
                                    west: "30.62",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2011,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2011,
                                    status: "available",
                                    block: 108,
                                    unitSize: 391.0,
                                    north: "17.0",
                                    east: "23.0",
                                    south: "17.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2012,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2012,
                                    status: "available",
                                    block: 108,
                                    unitSize: 391.0,
                                    north: "17.0",
                                    east: "23.0",
                                    south: "17.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2013,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2013,
                                    status: "available",
                                    block: 108,
                                    unitSize: 1077.02,
                                    north: "35.98",
                                    east: "30.62",
                                    south: "35.99",
                                    west: "29.95",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: true,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2014,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2014,
                                    status: "available",
                                    block: 108,
                                    unitSize: 391.0,
                                    north: "17.0",
                                    east: "23.0",
                                    south: "17.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2015,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2015,
                                    status: "available",
                                    block: 108,
                                    unitSize: 436.86,
                                    north: "18.99",
                                    east: "23.0",
                                    south: "19.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2016,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2016,
                                    status: "available",
                                    block: 109,
                                    unitSize: 540.0,
                                    north: "18.0",
                                    east: "30.0",
                                    south: "18.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2017,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2017,
                                    status: "sold",
                                    block: 109,
                                    unitSize: 540.0,
                                    north: "18.0",
                                    east: "30.0",
                                    south: "18.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "15",
                                },
                            },
                            {
                                Polygon: LandPlot_61_2018,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2018,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2019,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2019,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2020,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2020,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2021,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2021,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2022,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2022,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2023,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2023,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2024,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2024,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2025,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2025,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2026,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2026,
                                    status: "available",
                                    block: 109,
                                    unitSize: 443.75,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2027,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2027,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2028,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2028,
                                    status: "available",
                                    block: 109,
                                    unitSize: 443.75,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2029,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2029,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2030,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2030,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2031,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2031,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2032,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2032,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2033,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2033,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2034,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2034,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2035,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2035,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2036,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2036,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2037,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2037,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            // {
                            //     Polygon: LandPlot_61_2038,
                            //     className: null,
                            //     infobox: {
                            //         type: "land_info",
                            //         index: 2038,
                            //         status: "available",
                            //         block: 109,
                            //         unitSize: 0.0,
                            //         north: "15.0",
                            //         east: "30.0",
                            //         south: "15.0",
                            //         west: "30.0",
                            //         front1: "northern",
                            //         front2: "nan",
                            //         electricTransformer: false,
                            //         st_width_1: "15",
                            //         st_width_2: "0",
                            //     },
                            //     onClick: {
                            //         "action": "open_contact_form"
                            //     }
                            // },
                            // {
                            //     Polygon: LandPlot_61_2039,
                            //     className: null,
                            //     infobox: {
                            //         type: "land_info",
                            //         index: 2039,
                            //         status: "available",
                            //         block: 109,
                            //         unitSize: 0.0,
                            //         north: "15.0",
                            //         east: "30.0",
                            //         south: "15.0",
                            //         west: "30.0",
                            //         front1: "southern",
                            //         front2: "nan",
                            //         electricTransformer: false,
                            //         st_width_1: "30",
                            //         st_width_2: "0",
                            //     },
                            //     onClick: {
                            //         "action": "open_contact_form"
                            //     }
                            // },
                            // {
                            //     Polygon: LandPlot_61_2040,
                            //     className: null,
                            //     infobox: {
                            //         type: "land_info",
                            //         index: 2040,
                            //         status: "available",
                            //         block: 109,
                            //         unitSize: 0.0,
                            //         north: "15.0",
                            //         east: "30.0",
                            //         south: "15.0",
                            //         west: "30.0",
                            //         front1: "northern",
                            //         front2: "nan",
                            //         electricTransformer: false,
                            //         st_width_1: "15",
                            //         st_width_2: "0",
                            //     },
                            //     onClick: {
                            //         "action": "open_contact_form"
                            //     }
                            // },
                            // {
                            //     Polygon: LandPlot_61_2041,
                            //     className: null,
                            //     infobox: {
                            //         type: "land_info",
                            //         index: 2041,
                            //         status: "available",
                            //         block: 109,
                            //         unitSize: 0.0,
                            //         north: "15.0",
                            //         east: "30.0",
                            //         south: "15.0",
                            //         west: "30.0",
                            //         front1: "southern",
                            //         front2: "nan",
                            //         electricTransformer: false,
                            //         st_width_1: "30",
                            //         st_width_2: "0",
                            //     },
                            //     onClick: {
                            //         "action": "open_contact_form"
                            //     }
                            // },
                            // {
                            //     Polygon: LandPlot_61_2042,
                            //     className: null,
                            //     infobox: {
                            //         type: "land_info",
                            //         index: 2042,
                            //         status: "available",
                            //         block: 109,
                            //         unitSize: 0.0,
                            //         north: "15.0",
                            //         east: "30.0",
                            //         south: "15.0",
                            //         west: "30.0",
                            //         front1: "northern",
                            //         front2: "nan",
                            //         electricTransformer: false,
                            //         st_width_1: "15",
                            //         st_width_2: "0",
                            //     },
                            //     onClick: {
                            //         "action": "open_contact_form"
                            //     }
                            // },
                            // {
                            //     Polygon: LandPlot_61_2043,
                            //     className: null,
                            //     infobox: {
                            //         type: "land_info",
                            //         index: 2043,
                            //         status: "available",
                            //         block: 109,
                            //         unitSize: 0.0,
                            //         north: "15.0",
                            //         east: "30.0",
                            //         south: "15.0",
                            //         west: "30.0",
                            //         front1: "southern",
                            //         front2: "nan",
                            //         electricTransformer: false,
                            //         st_width_1: "30",
                            //         st_width_2: "0",
                            //     },
                            //     onClick: {
                            //         "action": "open_contact_form"
                            //     }
                            // },
                            // {
                            //     Polygon: LandPlot_61_2044,
                            //     className: null,
                            //     infobox: {
                            //         type: "land_info",
                            //         index: 2044,
                            //         status: "sold",
                            //         block: 109,
                            //         unitSize: 0.0,
                            //         north: "15.0",
                            //         east: "30.0",
                            //         south: "15.0",
                            //         west: "30.0",
                            //         front1: "northern",
                            //         front2: "nan",
                            //         electricTransformer: false,
                            //         st_width_1: "15",
                            //         st_width_2: "0",
                            //     },
                            // },
                            // {
                            //     Polygon: LandPlot_61_2045,
                            //     className: null,
                            //     infobox: {
                            //         type: "land_info",
                            //         index: 2045,
                            //         status: "sold",
                            //         block: 109,
                            //         unitSize: 0.0,
                            //         north: "15.0",
                            //         east: "30.0",
                            //         south: "15.0",
                            //         west: "30.0",
                            //         front1: "southern",
                            //         front2: "nan",
                            //         electricTransformer: false,
                            //         st_width_1: "30",
                            //         st_width_2: "0",
                            //     },
                            // },
                            // {
                            //     Polygon: LandPlot_61_2046,
                            //     className: null,
                            //     infobox: {
                            //         type: "land_info",
                            //         index: 2046,
                            //         status: "sold",
                            //         block: 109,
                            //         unitSize: 0.0,
                            //         north: "18.0",
                            //         east: "30.0",
                            //         south: "18.0",
                            //         west: "30.0",
                            //         front1: "northern",
                            //         front2: "western",
                            //         electricTransformer: false,
                            //         st_width_1: "15",
                            //         st_width_2: "15",
                            //     },
                            // },
                            // {
                            //     Polygon: LandPlot_61_2047,
                            //     className: null,
                            //     infobox: {
                            //         type: "land_info",
                            //         index: 2047,
                            //         status: "sold",
                            //         block: 109,
                            //         unitSize: 0.0,
                            //         north: "18.0",
                            //         east: "30.0",
                            //         south: "18.0",
                            //         west: "30.0",
                            //         front1: "southern",
                            //         front2: "western",
                            //         electricTransformer: false,
                            //         st_width_1: "30",
                            //         st_width_2: "15",
                            //     },
                            // },
                            {
                                Polygon: LandPlot_61_2048,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2048,
                                    status: "available",
                                    block: 110,
                                    unitSize: 1859.27,
                                    north: "42.43",
                                    east: "47.83",
                                    south: "35.91",
                                    west: "47.49",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2049,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2049,
                                    status: "available",
                                    block: 110,
                                    unitSize: 690.6,
                                    north: "35.91",
                                    east: "20.0",
                                    south: "33.15",
                                    west: "20.19",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2050,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2050,
                                    status: "available",
                                    block: 110,
                                    unitSize: 635.27,
                                    north: "33.15",
                                    east: "20.0",
                                    south: "30.38",
                                    west: "20.19",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2051,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2051,
                                    status: "available",
                                    block: 110,
                                    unitSize: 579.93,
                                    north: "30.38",
                                    east: "20.0",
                                    south: "27.61",
                                    west: "20.19",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_61_2052,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2052,
                                    status: "available",
                                    block: 110,
                                    unitSize: 524.62,
                                    north: "27.61",
                                    east: "20.0",
                                    south: "24.85",
                                    west: "20.19",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "30",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                        ]}
                    />
                    <SvgCustoms
                        customs={[
                            {
                                Element: Church_62_1,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: Church_62_2,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: Church_62_3,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: Church_62_4,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: Church_62_5,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: Church_62_6,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: Church_62_7,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: Church_62_8,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: School_62_1,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: School_62_2,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: School_62_3,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: School_62_4,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: School_62_5,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: School_62_6,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: Shopping_62_1,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: Shopping_62_2,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: Hospital_62_1,
                                className: "landmark",
                                onClick: null,
                            },
                        ]}
                    />
                </>
            ),
    },
    ////////////////////////////////////////////////////////////////////////////////////////index 90
    {
            index: 91,
            default: true,
            wrapper: (
                <>
                    <SvgAreas
                        areas={[
                            {
                                Polygon: LandPlot_90_1751,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1751,
                                    status: "available",
                                    block: 93,
                                    unitSize: 1151.74,
                                    north: "25.81",
                                    east: "49.77",
                                    south: "25.0",
                                    west: "43.37",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: true,
                                    st_width_1: "60",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1752,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1752,
                                    status: "available",
                                    block: 93,
                                    unitSize: 1004.31,
                                    north: "25.81",
                                    east: "43.37",
                                    south: "25.0",
                                    west: "36.97",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1753,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1753,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1754,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1754,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1755,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1755,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1756,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1756,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1757,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1757,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1758,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1758,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1759,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1759,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1760,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1760,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1761,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1761,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1762,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1762,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1763,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1763,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1764,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1764,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1765,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1765,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1766,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1766,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1767,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1767,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1768,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1768,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1769,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1769,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1770,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1770,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1771,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1771,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1772,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1772,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1773,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1773,
                                    status: "available",
                                    block: 93,
                                    unitSize: 374.97,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1774,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1774,
                                    status: "available",
                                    block: 93,
                                    unitSize: 375.03,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1775,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1775,
                                    status: "available",
                                    block: 94,
                                    unitSize: 1448.27,
                                    north: "42.45",
                                    east: "39.73",
                                    south: "33.46",
                                    west: "37.74",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1776,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1776,
                                    status: "available",
                                    block: 94,
                                    unitSize: 1122.19,
                                    north: "30.01",
                                    east: "37.74",
                                    south: "30.0",
                                    west: "37.07",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1777,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1777,
                                    status: "available",
                                    block: 94,
                                    unitSize: 1101.93,
                                    north: "30.01",
                                    east: "37.07",
                                    south: "30.0",
                                    west: "36.39",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1778,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1778,
                                    status: "available",
                                    block: 94,
                                    unitSize: 1081.67,
                                    north: "30.01",
                                    east: "36.39",
                                    south: "30.0",
                                    west: "35.72",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1779,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1779,
                                    status: "available",
                                    block: 94,
                                    unitSize: 1061.41,
                                    north: "30.01",
                                    east: "35.72",
                                    south: "30.0",
                                    west: "35.04",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1780,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1780,
                                    status: "available",
                                    block: 94,
                                    unitSize: 1042.89,
                                    north: "30.01",
                                    east: "35.04",
                                    south: "30.0",
                                    west: "34.75",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1781,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1781,
                                    status: "available",
                                    block: 94,
                                    unitSize: 1306.42,
                                    north: "31.87",
                                    east: "34.75",
                                    south: "40.49",
                                    west: "38.88",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1782,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1782,
                                    status: "available",
                                    block: 95,
                                    unitSize: 811.69,
                                    north: "15.68",
                                    east: "38.22",
                                    south: "20.15",
                                    west: "38.56",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1783,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1783,
                                    status: "available",
                                    block: 95,
                                    unitSize: 503.69,
                                    north: "20.15",
                                    east: "25.0",
                                    south: "20.15",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1784,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1784,
                                    status: "available",
                                    block: 95,
                                    unitSize: 552.23,
                                    north: "15.4",
                                    east: "38.56",
                                    south: "15.0",
                                    west: "35.08",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1785,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1785,
                                    status: "available",
                                    block: 95,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1786,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1786,
                                    status: "available",
                                    block: 95,
                                    unitSize: 500.04,
                                    north: "15.4",
                                    east: "35.08",
                                    south: "15.0",
                                    west: "31.6",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1787,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1787,
                                    status: "available",
                                    block: 95,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1788,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1788,
                                    status: "available",
                                    block: 95,
                                    unitSize: 447.86,
                                    north: "15.4",
                                    east: "31.6",
                                    south: "15.0",
                                    west: "28.12",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1789,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1789,
                                    status: "available",
                                    block: 95,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1790,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1790,
                                    status: "available",
                                    block: 95,
                                    unitSize: 395.67,
                                    north: "15.4",
                                    east: "28.12",
                                    south: "15.0",
                                    west: "24.64",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1791,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1791,
                                    status: "available",
                                    block: 95,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1792,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1792,
                                    status: "available",
                                    block: 95,
                                    unitSize: 343.48,
                                    north: "15.4",
                                    east: "24.64",
                                    south: "15.0",
                                    west: "21.16",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1793,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1793,
                                    status: "available",
                                    block: 95,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1794,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1794,
                                    status: "available",
                                    block: 95,
                                    unitSize: 530.41,
                                    north: "30.8",
                                    east: "21.16",
                                    south: "30.0",
                                    west: "14.2",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1795,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1795,
                                    status: "available",
                                    block: 95,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1796,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1796,
                                    status: "available",
                                    block: 95,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1797,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1797,
                                    status: "available",
                                    block: 95,
                                    unitSize: 668.05,
                                    north: "18.48",
                                    east: "39.2",
                                    south: "18.0",
                                    west: "35.03",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1798,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1798,
                                    status: "available",
                                    block: 95,
                                    unitSize: 592.9,
                                    north: "18.48",
                                    east: "35.03",
                                    south: "18.0",
                                    west: "30.85",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1799,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1799,
                                    status: "available",
                                    block: 95,
                                    unitSize: 517.75,
                                    north: "18.48",
                                    east: "30.85",
                                    south: "18.0",
                                    west: "26.68",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1800,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1800,
                                    status: "available",
                                    block: 95,
                                    unitSize: 509.06,
                                    north: "21.56",
                                    east: "26.68",
                                    south: "21.0",
                                    west: "21.81",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1801,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1801,
                                    status: "available",
                                    block: 96,
                                    unitSize: 694.43,
                                    north: "23.15",
                                    east: "30.0",
                                    south: "23.15",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1802,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1802,
                                    status: "available",
                                    block: 96,
                                    unitSize: 694.43,
                                    north: "23.15",
                                    east: "30.0",
                                    south: "23.15",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1803,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1803,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1804,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1804,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1805,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1805,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1806,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1806,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1807,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1807,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1808,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1808,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1809,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1809,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1810,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1810,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1811,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1811,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1812,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1812,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1813,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1813,
                                    status: "available",
                                    block: 96,
                                    unitSize: 443.75,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1814,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1814,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1815,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1815,
                                    status: "available",
                                    block: 96,
                                    unitSize: 443.75,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1816,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1816,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1817,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1817,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1818,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1818,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1819,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1819,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1820,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1820,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1821,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1821,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1822,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1822,
                                    status: "available",
                                    block: 96,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1823,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1823,
                                    status: "available",
                                    block: 96,
                                    unitSize: 405.0,
                                    north: "27.0",
                                    east: "15.0",
                                    south: "27.0",
                                    west: "15.0",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1824,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1824,
                                    status: "available",
                                    block: 96,
                                    unitSize: 405.0,
                                    north: "27.0",
                                    east: "15.0",
                                    south: "27.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1825,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1825,
                                    status: "available",
                                    block: 96,
                                    unitSize: 405.0,
                                    north: "27.0",
                                    east: "15.0",
                                    south: "27.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1826,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1826,
                                    status: "available",
                                    block: 96,
                                    unitSize: 405.0,
                                    north: "27.0",
                                    east: "15.0",
                                    south: "27.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "30",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1827,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1827,
                                    status: "available",
                                    block: 97,
                                    unitSize: 750.0,
                                    north: "25.0",
                                    east: "30.0",
                                    south: "25.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "6",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1828,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1828,
                                    status: "available",
                                    block: 97,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1829,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1829,
                                    status: "available",
                                    block: 97,
                                    unitSize: 1125.0,
                                    north: "25.0",
                                    east: "45.0",
                                    south: "25.0",
                                    west: "45.0",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "6",
                                    st_width_2: "15+6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1830,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1830,
                                    status: "available",
                                    block: 97,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1831,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1831,
                                    status: "available",
                                    block: 97,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1832,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1832,
                                    status: "available",
                                    block: 97,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1833,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1833,
                                    status: "available",
                                    block: 97,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1834,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1834,
                                    status: "available",
                                    block: 97,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1835,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1835,
                                    status: "available",
                                    block: 97,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1836,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1836,
                                    status: "available",
                                    block: 97,
                                    unitSize: 374.91,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "30",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1837,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1837,
                                    status: "available",
                                    block: 97,
                                    unitSize: 374.97,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "30",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1838,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1838,
                                    status: "available",
                                    block: 98,
                                    unitSize: 1269.26,
                                    north: "30.01",
                                    east: "42.58",
                                    south: "30.0",
                                    west: "42.02",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1839,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1839,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.04,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1840,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1840,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1841,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1841,
                                    status: "available",
                                    block: 98,
                                    unitSize: 1252.23,
                                    north: "30.01",
                                    east: "42.02",
                                    south: "30.0",
                                    west: "41.46",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1842,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1842,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1843,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1843,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1844,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1844,
                                    status: "available",
                                    block: 98,
                                    unitSize: 1235.41,
                                    north: "30.01",
                                    east: "41.46",
                                    south: "30.0",
                                    west: "40.9",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1845,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1845,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1846,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1846,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1847,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1847,
                                    status: "available",
                                    block: 98,
                                    unitSize: 1218.59,
                                    north: "30.01",
                                    east: "40.9",
                                    south: "30.0",
                                    west: "40.34",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1848,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1848,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1849,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1849,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1850,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1850,
                                    status: "available",
                                    block: 98,
                                    unitSize: 1201.77,
                                    north: "30.01",
                                    east: "40.34",
                                    south: "30.0",
                                    west: "39.78",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1851,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1851,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1852,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1852,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1853,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1853,
                                    status: "available",
                                    block: 98,
                                    unitSize: 1184.95,
                                    north: "30.01",
                                    east: "39.78",
                                    south: "30.0",
                                    west: "39.22",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1854,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1854,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1855,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1855,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1856,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1856,
                                    status: "available",
                                    block: 98,
                                    unitSize: 1168.09,
                                    north: "30.01",
                                    east: "39.22",
                                    south: "30.0",
                                    west: "38.65",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1857,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1857,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1858,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1858,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1859,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1859,
                                    status: "available",
                                    block: 98,
                                    unitSize: 1227.88,
                                    north: "32.03",
                                    east: "38.65",
                                    south: "32.01",
                                    west: "38.04",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1860,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1860,
                                    status: "available",
                                    block: 98,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1861,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1861,
                                    status: "available",
                                    block: 98,
                                    unitSize: 510.24,
                                    north: "17.01",
                                    east: "30.0",
                                    south: "17.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1862,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1862,
                                    status: "available",
                                    block: 99,
                                    unitSize: 480.0,
                                    north: "32.0",
                                    east: "15.0",
                                    south: "32.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "northern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1863,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1863,
                                    status: "available",
                                    block: 99,
                                    unitSize: 480.0,
                                    north: "32.0",
                                    east: "15.0",
                                    south: "32.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1864,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1864,
                                    status: "available",
                                    block: 99,
                                    unitSize: 480.0,
                                    north: "32.0",
                                    east: "15.0",
                                    south: "32.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1865,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1865,
                                    status: "available",
                                    block: 99,
                                    unitSize: 480.0,
                                    north: "32.0",
                                    east: "15.0",
                                    south: "32.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "30",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1866,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1866,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1867,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1867,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1868,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1868,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1869,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1869,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1870,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1870,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1871,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1871,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1872,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1872,
                                    status: "available",
                                    block: 99,
                                    unitSize: 443.75,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1873,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1873,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1874,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1874,
                                    status: "available",
                                    block: 99,
                                    unitSize: 443.75,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1875,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1875,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1876,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1876,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1877,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1877,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1878,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1878,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1879,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1879,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1880,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1880,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1881,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1881,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1882,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1882,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1883,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1883,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1884,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1884,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1885,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1885,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1886,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1886,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1887,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1887,
                                    status: "available",
                                    block: 99,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1888,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1888,
                                    status: "available",
                                    block: 99,
                                    unitSize: 405.0,
                                    north: "27.0",
                                    east: "15.0",
                                    south: "27.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "northern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1889,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1889,
                                    status: "available",
                                    block: 99,
                                    unitSize: 405.0,
                                    north: "27.0",
                                    east: "15.0",
                                    south: "27.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1890,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1890,
                                    status: "available",
                                    block: 99,
                                    unitSize: 405.0,
                                    north: "27.0",
                                    east: "15.0",
                                    south: "27.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1891,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1891,
                                    status: "available",
                                    block: 99,
                                    unitSize: 405.0,
                                    north: "27.0",
                                    east: "15.0",
                                    south: "27.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "30",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1892,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1892,
                                    status: "available",
                                    block: 100,
                                    unitSize: 1385.24,
                                    north: "32.51",
                                    east: "42.93",
                                    south: "32.5",
                                    west: "42.32",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1893,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1893,
                                    status: "available",
                                    block: 100,
                                    unitSize: 1352.78,
                                    north: "32.51",
                                    east: "42.32",
                                    south: "32.5",
                                    west: "41.7",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: true,
                                    st_width_1: "60",
                                    st_width_2: "25",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1894,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1894,
                                    status: "available",
                                    block: 100,
                                    unitSize: 1462.51,
                                    north: "32.5",
                                    east: "45.0",
                                    south: "32.5",
                                    west: "45.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15+6",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1895,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1895,
                                    status: "available",
                                    block: 100,
                                    unitSize: 487.5,
                                    north: "32.5",
                                    east: "15.0",
                                    south: "32.5",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1896,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1896,
                                    status: "available",
                                    block: 100,
                                    unitSize: 487.5,
                                    north: "32.5",
                                    east: "15.0",
                                    south: "32.5",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1897,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1897,
                                    status: "available",
                                    block: 100,
                                    unitSize: 487.5,
                                    north: "32.5",
                                    east: "15.0",
                                    south: "32.5",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1898,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1898,
                                    status: "available",
                                    block: 100,
                                    unitSize: 487.5,
                                    north: "32.5",
                                    east: "15.0",
                                    south: "32.5",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1899,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1899,
                                    status: "available",
                                    block: 100,
                                    unitSize: 487.5,
                                    north: "32.5",
                                    east: "15.0",
                                    south: "32.5",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1900,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1900,
                                    status: "available",
                                    block: 100,
                                    unitSize: 487.5,
                                    north: "32.5",
                                    east: "15.0",
                                    south: "32.5",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1901,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1901,
                                    status: "available",
                                    block: 100,
                                    unitSize: 487.5,
                                    north: "32.5",
                                    east: "15.0",
                                    south: "32.5",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1902,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1902,
                                    status: "available",
                                    block: 100,
                                    unitSize: 812.43,
                                    north: "32.5",
                                    east: "25.0",
                                    south: "32.5",
                                    west: "25.0",
                                    front1: "eastern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "30",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1903,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1903,
                                    status: "available",
                                    block: 100,
                                    unitSize: 812.3,
                                    north: "32.5",
                                    east: "25.0",
                                    south: "32.5",
                                    west: "25.0",
                                    front1: "western",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "30",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1904,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1904,
                                    status: "available",
                                    block: 101,
                                    unitSize: 1431.47,
                                    north: "35.01",
                                    east: "41.23",
                                    south: "35.0",
                                    west: "40.57",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "25",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1905,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1905,
                                    status: "available",
                                    block: 101,
                                    unitSize: 1208.55,
                                    north: "30.01",
                                    east: "40.57",
                                    south: "30.0",
                                    west: "40.0",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1906,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1906,
                                    status: "available",
                                    block: 101,
                                    unitSize: 525.0,
                                    north: "35.0",
                                    east: "15.0",
                                    south: "35.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1907,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1907,
                                    status: "available",
                                    block: 101,
                                    unitSize: 525.01,
                                    north: "35.0",
                                    east: "15.0",
                                    south: "35.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1908,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1908,
                                    status: "available",
                                    block: 101,
                                    unitSize: 525.01,
                                    north: "35.0",
                                    east: "15.0",
                                    south: "35.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1909,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1909,
                                    status: "available",
                                    block: 101,
                                    unitSize: 1349.99,
                                    north: "30.0",
                                    east: "45.0",
                                    south: "30.0",
                                    west: "45.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15+6",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1910,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1910,
                                    status: "available",
                                    block: 101,
                                    unitSize: 525.01,
                                    north: "35.0",
                                    east: "15.0",
                                    south: "35.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1911,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1911,
                                    status: "available",
                                    block: 101,
                                    unitSize: 449.99,
                                    north: "30.0",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1912,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1912,
                                    status: "available",
                                    block: 101,
                                    unitSize: 525.01,
                                    north: "35.0",
                                    east: "15.0",
                                    south: "35.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1913,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1913,
                                    status: "available",
                                    block: 101,
                                    unitSize: 499.99,
                                    north: "30.0",
                                    east: "15.0",
                                    south: "30.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1914,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1914,
                                    status: "available",
                                    block: 101,
                                    unitSize: 874.54,
                                    north: "35.0",
                                    east: "24.99",
                                    south: "35.0",
                                    west: "24.98",
                                    front1: "eastern",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "25",
                                    st_width_2: "30",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1915,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1915,
                                    status: "available",
                                    block: 101,
                                    unitSize: 749.45,
                                    north: "30.0",
                                    east: "24.98",
                                    south: "30.0",
                                    west: "24.98",
                                    front1: "western",
                                    front2: "southern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "30",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1916,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1916,
                                    status: "available",
                                    block: 102,
                                    unitSize: 1226.77,
                                    north: "31.01",
                                    east: "39.87",
                                    south: "31.0",
                                    west: "39.28",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1917,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1917,
                                    status: "available",
                                    block: 102,
                                    unitSize: 400.0,
                                    north: "16.0",
                                    east: "25.0",
                                    south: "16.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1918,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1918,
                                    status: "available",
                                    block: 102,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1919,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1919,
                                    status: "available",
                                    block: 102,
                                    unitSize: 1169.8,
                                    north: "31.01",
                                    east: "39.28",
                                    south: "30.0",
                                    west: "38.71",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1920,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1920,
                                    status: "available",
                                    block: 102,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1921,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1921,
                                    status: "available",
                                    block: 102,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1922,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1922,
                                    status: "available",
                                    block: 102,
                                    unitSize: 1140.31,
                                    north: "30.01",
                                    east: "38.71",
                                    south: "30.0",
                                    west: "38.15",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1923,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1923,
                                    status: "available",
                                    block: 102,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1924,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1924,
                                    status: "available",
                                    block: 102,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1925,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1925,
                                    status: "available",
                                    block: 102,
                                    unitSize: 1135.93,
                                    north: "30.01",
                                    east: "38.15",
                                    south: "30.0",
                                    west: "37.58",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1926,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1926,
                                    status: "available",
                                    block: 102,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1927,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1927,
                                    status: "available",
                                    block: 102,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1928,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1928,
                                    status: "available",
                                    block: 102,
                                    unitSize: 1156.08,
                                    north: "31.01",
                                    east: "37.58",
                                    south: "31.0",
                                    west: "37.0",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1929,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1929,
                                    status: "available",
                                    block: 102,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1930,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1930,
                                    status: "available",
                                    block: 102,
                                    unitSize: 400.01,
                                    north: "16.0",
                                    east: "25.0",
                                    south: "16.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1931,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1931,
                                    status: "available",
                                    block: 103,
                                    unitSize: 1140.38,
                                    north: "31.23",
                                    east: "36.89",
                                    south: "31.09",
                                    west: "36.31",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1932,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1932,
                                    status: "available",
                                    block: 103,
                                    unitSize: 401.15,
                                    north: "16.09",
                                    east: "25.0",
                                    south: "16.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1933,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1933,
                                    status: "available",
                                    block: 103,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1934,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1934,
                                    status: "available",
                                    block: 103,
                                    unitSize: 1080.76,
                                    north: "30.01",
                                    east: "36.31",
                                    south: "30.0",
                                    west: "35.74",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1935,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1935,
                                    status: "available",
                                    block: 103,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1936,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1936,
                                    status: "available",
                                    block: 103,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1937,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1937,
                                    status: "available",
                                    block: 103,
                                    unitSize: 1063.87,
                                    north: "30.01",
                                    east: "35.74",
                                    south: "30.0",
                                    west: "35.18",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1938,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1938,
                                    status: "available",
                                    block: 103,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1939,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1939,
                                    status: "available",
                                    block: 103,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1940,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1940,
                                    status: "available",
                                    block: 103,
                                    unitSize: 1046.98,
                                    north: "30.01",
                                    east: "35.18",
                                    south: "30.0",
                                    west: "34.62",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1941,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1941,
                                    status: "available",
                                    block: 103,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1942,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1942,
                                    status: "available",
                                    block: 103,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1943,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1943,
                                    status: "available",
                                    block: 103,
                                    unitSize: 1063.48,
                                    north: "30.98",
                                    east: "34.62",
                                    south: "30.99",
                                    west: "34.04",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1944,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1944,
                                    status: "available",
                                    block: 103,
                                    unitSize: 375.0,
                                    north: "15.0",
                                    east: "25.0",
                                    south: "15.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1945,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1945,
                                    status: "available",
                                    block: 103,
                                    unitSize: 399.89,
                                    north: "15.99",
                                    east: "25.0",
                                    south: "16.0",
                                    west: "25.0",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1946,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1946,
                                    status: "available",
                                    block: 104,
                                    unitSize: 540.0,
                                    north: "18.0",
                                    east: "30.0",
                                    south: "18.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1947,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1947,
                                    status: "available",
                                    block: 104,
                                    unitSize: 540.0,
                                    north: "18.0",
                                    east: "30.0",
                                    south: "18.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1948,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1948,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1949,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1949,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1950,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1950,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1951,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1951,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1952,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1952,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1953,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1953,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1954,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1954,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1955,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1955,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1956,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1956,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1957,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1957,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1958,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1958,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1959,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1959,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1960,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1960,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1961,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1961,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1962,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1962,
                                    status: "available",
                                    block: 104,
                                    unitSize: 443.75,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1963,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1963,
                                    status: "available",
                                    block: 104,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1964,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1964,
                                    status: "available",
                                    block: 104,
                                    unitSize: 503.75,
                                    north: "17.0",
                                    east: "30.0",
                                    south: "17.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: true,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1965,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1965,
                                    status: "available",
                                    block: 104,
                                    unitSize: 510.0,
                                    north: "17.0",
                                    east: "30.0",
                                    south: "17.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1966,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1966,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 510.0,
                                    north: "17.0",
                                    east: "30.0",
                                    south: "17.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                            },
                            {
                                Polygon: LandPlot_90_1967,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1967,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 510.0,
                                    north: "17.0",
                                    east: "30.0",
                                    south: "17.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "6",
                                },
                            },
                            {
                                Polygon: LandPlot_90_1968,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1968,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_90_1969,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1969,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_90_1970,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1970,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_90_1971,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1971,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_90_1972,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1972,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_90_1973,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1973,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_90_1974,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1974,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_90_1975,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1975,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_90_1976,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1976,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_90_1977,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1977,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_90_1978,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1978,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_90_1979,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1979,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 480.0,
                                    north: "16.0",
                                    east: "30.0",
                                    south: "16.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                            },
                            {
                                Polygon: LandPlot_90_1980,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1980,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 540.0,
                                    north: "18.0",
                                    east: "30.0",
                                    south: "18.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                            },
                            {
                                Polygon: LandPlot_90_1981,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1981,
                                    status: "sold",
                                    block: 105,
                                    unitSize: 540.0,
                                    north: "18.0",
                                    east: "30.0",
                                    south: "18.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "15",
                                },
                            },
                            {
                                Polygon: LandPlot_90_1982,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1982,
                                    status: "available",
                                    block: 106,
                                    unitSize: 2910.25,
                                    north: "50.01",
                                    east: "58.92",
                                    south: "50.0",
                                    west: "57.99",
                                    front1: "northern",
                                    front2: "southern",
                                    electricTransformer: true,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1983,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1983,
                                    status: "available",
                                    block: 107,
                                    unitSize: 377.64,
                                    north: "25.0",
                                    east: "15.11",
                                    south: "25.0",
                                    west: "15.1",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "6",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1984,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1984,
                                    status: "available",
                                    block: 107,
                                    unitSize: 377.58,
                                    north: "25.0",
                                    east: "15.11",
                                    south: "25.0",
                                    west: "15.1",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "6",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1985,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1985,
                                    status: "available",
                                    block: 107,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1986,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1986,
                                    status: "available",
                                    block: 107,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1987,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1987,
                                    status: "available",
                                    block: 107,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1988,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1988,
                                    status: "available",
                                    block: 107,
                                    unitSize: 375.0,
                                    north: "25.0",
                                    east: "15.0",
                                    south: "25.0",
                                    west: "15.0",
                                    front1: "western",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1989,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1989,
                                    status: "available",
                                    block: 107,
                                    unitSize: 406.2,
                                    north: "17.0",
                                    east: "23.89",
                                    south: "17.0",
                                    west: "23.89",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1990,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1990,
                                    status: "available",
                                    block: 107,
                                    unitSize: 382.33,
                                    north: "16.0",
                                    east: "23.89",
                                    south: "16.0",
                                    west: "23.9",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1991,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1991,
                                    status: "available",
                                    block: 107,
                                    unitSize: 406.25,
                                    north: "17.0",
                                    east: "23.9",
                                    south: "17.0",
                                    west: "23.9",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1992,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1992,
                                    status: "available",
                                    block: 108,
                                    unitSize: 1175.64,
                                    north: "27.16",
                                    east: "34.87",
                                    south: "34.01",
                                    west: "34.24",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1993,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1993,
                                    status: "available",
                                    block: 108,
                                    unitSize: 437.14,
                                    north: "19.01",
                                    east: "23.0",
                                    south: "19.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1994,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1994,
                                    status: "available",
                                    block: 108,
                                    unitSize: 345.0,
                                    north: "15.0",
                                    east: "23.0",
                                    south: "15.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1995,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1995,
                                    status: "available",
                                    block: 108,
                                    unitSize: 1018.73,
                                    north: "30.0",
                                    east: "34.24",
                                    south: "30.0",
                                    west: "33.68",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1996,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1996,
                                    status: "available",
                                    block: 108,
                                    unitSize: 345.0,
                                    north: "15.0",
                                    east: "23.0",
                                    south: "15.0",
                                    west: "23.0",
                                    front1: "nan",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1997,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1997,
                                    status: "available",
                                    block: 108,
                                    unitSize: 345.0,
                                    north: "15.0",
                                    east: "23.0",
                                    south: "15.0",
                                    west: "23.0",
                                    front1: "nan",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1998,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1998,
                                    status: "available",
                                    block: 108,
                                    unitSize: 1055.64,
                                    north: "32.02",
                                    east: "33.68",
                                    south: "32.0",
                                    west: "33.08",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_1999,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 1999,
                                    status: "available",
                                    block: 108,
                                    unitSize: 368.0,
                                    north: "16.0",
                                    east: "23.0",
                                    south: "16.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2000,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2000,
                                    status: "available",
                                    block: 108,
                                    unitSize: 368.0,
                                    north: "16.0",
                                    east: "23.0",
                                    south: "16.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2001,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2001,
                                    status: "available",
                                    block: 108,
                                    unitSize: 1049.03,
                                    north: "32.01",
                                    east: "33.08",
                                    south: "32.0",
                                    west: "32.48",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2002,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2002,
                                    status: "available",
                                    block: 108,
                                    unitSize: 368.0,
                                    north: "16.0",
                                    east: "23.0",
                                    south: "16.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2003,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2003,
                                    status: "available",
                                    block: 108,
                                    unitSize: 368.0,
                                    north: "16.0",
                                    east: "23.0",
                                    south: "16.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2004,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2004,
                                    status: "available",
                                    block: 108,
                                    unitSize: 1029.92,
                                    north: "32.01",
                                    east: "32.48",
                                    south: "32.0",
                                    west: "31.89",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2005,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2005,
                                    status: "available",
                                    block: 108,
                                    unitSize: 368.0,
                                    north: "16.0",
                                    east: "23.0",
                                    south: "16.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2006,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2006,
                                    status: "available",
                                    block: 108,
                                    unitSize: 368.0,
                                    north: "16.0",
                                    east: "23.0",
                                    south: "16.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2007,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2007,
                                    status: "available",
                                    block: 108,
                                    unitSize: 1073.36,
                                    north: "34.01",
                                    east: "31.89",
                                    south: "34.0",
                                    west: "31.25",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2008,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2008,
                                    status: "available",
                                    block: 108,
                                    unitSize: 391.0,
                                    north: "17.0",
                                    east: "23.0",
                                    south: "17.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2009,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2009,
                                    status: "available",
                                    block: 108,
                                    unitSize: 391.0,
                                    north: "17.0",
                                    east: "23.0",
                                    south: "17.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2010,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2010,
                                    status: "available",
                                    block: 108,
                                    unitSize: 1051.78,
                                    north: "34.01",
                                    east: "31.25",
                                    south: "34.0",
                                    west: "30.62",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2011,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2011,
                                    status: "available",
                                    block: 108,
                                    unitSize: 391.0,
                                    north: "17.0",
                                    east: "23.0",
                                    south: "17.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2012,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2012,
                                    status: "available",
                                    block: 108,
                                    unitSize: 391.0,
                                    north: "17.0",
                                    east: "23.0",
                                    south: "17.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2013,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2013,
                                    status: "available",
                                    block: 108,
                                    unitSize: 1077.02,
                                    north: "35.98",
                                    east: "30.62",
                                    south: "35.99",
                                    west: "29.95",
                                    front1: "northern",
                                    front2: "western",
                                    electricTransformer: true,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2014,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2014,
                                    status: "available",
                                    block: 108,
                                    unitSize: 391.0,
                                    north: "17.0",
                                    east: "23.0",
                                    south: "17.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2015,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2015,
                                    status: "available",
                                    block: 108,
                                    unitSize: 436.86,
                                    north: "18.99",
                                    east: "23.0",
                                    south: "19.0",
                                    west: "23.0",
                                    front1: "southern",
                                    front2: "western",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2016,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2016,
                                    status: "available",
                                    block: 109,
                                    unitSize: 540.0,
                                    north: "18.0",
                                    east: "30.0",
                                    south: "18.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "15",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2017,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2017,
                                    status: "sold",
                                    block: 109,
                                    unitSize: 540.0,
                                    north: "18.0",
                                    east: "30.0",
                                    south: "18.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "15",
                                },
                            },
                            {
                                Polygon: LandPlot_90_2018,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2018,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2019,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2019,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2020,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2020,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2021,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2021,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2022,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2022,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2023,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2023,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2024,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2024,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2025,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2025,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2026,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2026,
                                    status: "available",
                                    block: 109,
                                    unitSize: 443.75,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2027,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2027,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2028,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2028,
                                    status: "available",
                                    block: 109,
                                    unitSize: 443.75,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: true,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2029,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2029,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2030,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2030,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2031,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2031,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2032,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2032,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2033,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2033,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2034,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2034,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2035,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2035,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2036,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2036,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "northern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2037,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2037,
                                    status: "available",
                                    block: 109,
                                    unitSize: 450.0,
                                    north: "15.0",
                                    east: "30.0",
                                    south: "15.0",
                                    west: "30.0",
                                    front1: "southern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "30",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            // {
                            //     Polygon: LandPlot_90_2038,
                            //     className: null,
                            //     infobox: {
                            //         type: "land_info",
                            //         index: 2038,
                            //         status: "available",
                            //         block: 109,
                            //         unitSize: 0.0,
                            //         north: "15.0",
                            //         east: "30.0",
                            //         south: "15.0",
                            //         west: "30.0",
                            //         front1: "northern",
                            //         front2: "nan",
                            //         electricTransformer: false,
                            //         st_width_1: "15",
                            //         st_width_2: "0",
                            //     },
                            //     onClick: {
                            //         "action": "open_contact_form"
                            //     }
                            // },
                            // {
                            //     Polygon: LandPlot_90_2039,
                            //     className: null,
                            //     infobox: {
                            //         type: "land_info",
                            //         index: 2039,
                            //         status: "available",
                            //         block: 109,
                            //         unitSize: 0.0,
                            //         north: "15.0",
                            //         east: "30.0",
                            //         south: "15.0",
                            //         west: "30.0",
                            //         front1: "southern",
                            //         front2: "nan",
                            //         electricTransformer: false,
                            //         st_width_1: "30",
                            //         st_width_2: "0",
                            //     },
                            //     onClick: {
                            //         "action": "open_contact_form"
                            //     }
                            // },
                            // {
                            //     Polygon: LandPlot_90_2040,
                            //     className: null,
                            //     infobox: {
                            //         type: "land_info",
                            //         index: 2040,
                            //         status: "available",
                            //         block: 109,
                            //         unitSize: 0.0,
                            //         north: "15.0",
                            //         east: "30.0",
                            //         south: "15.0",
                            //         west: "30.0",
                            //         front1: "northern",
                            //         front2: "nan",
                            //         electricTransformer: false,
                            //         st_width_1: "15",
                            //         st_width_2: "0",
                            //     },
                            //     onClick: {
                            //         "action": "open_contact_form"
                            //     }
                            // },
                            // {
                            //     Polygon: LandPlot_90_2041,
                            //     className: null,
                            //     infobox: {
                            //         type: "land_info",
                            //         index: 2041,
                            //         status: "available",
                            //         block: 109,
                            //         unitSize: 0.0,
                            //         north: "15.0",
                            //         east: "30.0",
                            //         south: "15.0",
                            //         west: "30.0",
                            //         front1: "southern",
                            //         front2: "nan",
                            //         electricTransformer: false,
                            //         st_width_1: "30",
                            //         st_width_2: "0",
                            //     },
                            //     onClick: {
                            //         "action": "open_contact_form"
                            //     }
                            // },
                            // {
                            //     Polygon: LandPlot_90_2042,
                            //     className: null,
                            //     infobox: {
                            //         type: "land_info",
                            //         index: 2042,
                            //         status: "available",
                            //         block: 109,
                            //         unitSize: 0.0,
                            //         north: "15.0",
                            //         east: "30.0",
                            //         south: "15.0",
                            //         west: "30.0",
                            //         front1: "northern",
                            //         front2: "nan",
                            //         electricTransformer: false,
                            //         st_width_1: "15",
                            //         st_width_2: "0",
                            //     },
                            //     onClick: {
                            //         "action": "open_contact_form"
                            //     }
                            // },
                            // {
                            //     Polygon: LandPlot_90_2043,
                            //     className: null,
                            //     infobox: {
                            //         type: "land_info",
                            //         index: 2043,
                            //         status: "available",
                            //         block: 109,
                            //         unitSize: 0.0,
                            //         north: "15.0",
                            //         east: "30.0",
                            //         south: "15.0",
                            //         west: "30.0",
                            //         front1: "southern",
                            //         front2: "nan",
                            //         electricTransformer: false,
                            //         st_width_1: "30",
                            //         st_width_2: "0",
                            //     },
                            //     onClick: {
                            //         "action": "open_contact_form"
                            //     }
                            // },
                            // {
                            //     Polygon: LandPlot_90_2044,
                            //     className: null,
                            //     infobox: {
                            //         type: "land_info",
                            //         index: 2044,
                            //         status: "sold",
                            //         block: 109,
                            //         unitSize: 0.0,
                            //         north: "15.0",
                            //         east: "30.0",
                            //         south: "15.0",
                            //         west: "30.0",
                            //         front1: "northern",
                            //         front2: "nan",
                            //         electricTransformer: false,
                            //         st_width_1: "15",
                            //         st_width_2: "0",
                            //     },
                            // },
                            // {
                            //     Polygon: LandPlot_90_2045,
                            //     className: null,
                            //     infobox: {
                            //         type: "land_info",
                            //         index: 2045,
                            //         status: "sold",
                            //         block: 109,
                            //         unitSize: 0.0,
                            //         north: "15.0",
                            //         east: "30.0",
                            //         south: "15.0",
                            //         west: "30.0",
                            //         front1: "southern",
                            //         front2: "nan",
                            //         electricTransformer: false,
                            //         st_width_1: "30",
                            //         st_width_2: "0",
                            //     },
                            // },
                            // {
                            //     Polygon: LandPlot_90_2046,
                            //     className: null,
                            //     infobox: {
                            //         type: "land_info",
                            //         index: 2046,
                            //         status: "sold",
                            //         block: 109,
                            //         unitSize: 0.0,
                            //         north: "18.0",
                            //         east: "30.0",
                            //         south: "18.0",
                            //         west: "30.0",
                            //         front1: "northern",
                            //         front2: "western",
                            //         electricTransformer: false,
                            //         st_width_1: "15",
                            //         st_width_2: "15",
                            //     },
                            // },
                            // {
                            //     Polygon: LandPlot_90_2047,
                            //     className: null,
                            //     infobox: {
                            //         type: "land_info",
                            //         index: 2047,
                            //         status: "sold",
                            //         block: 109,
                            //         unitSize: 0.0,
                            //         north: "18.0",
                            //         east: "30.0",
                            //         south: "18.0",
                            //         west: "30.0",
                            //         front1: "southern",
                            //         front2: "western",
                            //         electricTransformer: false,
                            //         st_width_1: "30",
                            //         st_width_2: "15",
                            //     },
                            // },
                            {
                                Polygon: LandPlot_90_2048,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2048,
                                    status: "available",
                                    block: 110,
                                    unitSize: 1859.27,
                                    north: "42.43",
                                    east: "47.83",
                                    south: "35.91",
                                    west: "47.49",
                                    front1: "northern",
                                    front2: "eastern",
                                    electricTransformer: false,
                                    st_width_1: "60",
                                    st_width_2: "6",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2049,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2049,
                                    status: "available",
                                    block: 110,
                                    unitSize: 690.6,
                                    north: "35.91",
                                    east: "20.0",
                                    south: "33.15",
                                    west: "20.19",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2050,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2050,
                                    status: "available",
                                    block: 110,
                                    unitSize: 635.27,
                                    north: "33.15",
                                    east: "20.0",
                                    south: "30.38",
                                    west: "20.19",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2051,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2051,
                                    status: "available",
                                    block: 110,
                                    unitSize: 579.93,
                                    north: "30.38",
                                    east: "20.0",
                                    south: "27.61",
                                    west: "20.19",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "0",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                            {
                                Polygon: LandPlot_90_2052,
                                className: null,
                                infobox: {
                                    type: "land_info",
                                    index: 2052,
                                    status: "available",
                                    block: 110,
                                    unitSize: 524.62,
                                    north: "27.61",
                                    east: "20.0",
                                    south: "24.85",
                                    west: "20.19",
                                    front1: "eastern",
                                    front2: "nan",
                                    electricTransformer: false,
                                    st_width_1: "15",
                                    st_width_2: "30",
                                },
                                onClick: {
                                    "action": "open_contact_form"
                                }
                            },
                        ]}
                    />
                    <SvgCustoms
                        customs={[
                            {
                                Element: Church_91_1,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: Church_91_2,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: Church_91_3,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: Church_91_4,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: Church_91_5,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: Church_91_6,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: Church_91_7,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: Church_91_8,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: School_91_1,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: School_91_2,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: School_91_3,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: School_91_4,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: School_91_5,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: School_91_6,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: School_91_7,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: Shopping_91_1,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: Shopping_91_2,
                                className: "landmark",
                                onClick: null,
                            },
                            {
                                Element: Hospital_91_1,
                                className: "landmark",
                                onClick: null,
                            },
                        ]}
                    />
                </>
            ),
    },
];

import AlZumurudaIcon from "../../static/images/contents/buraydah/ar/al_zumuruda.svg";

import QassimUniversityIcon from "../../static/images/contents/buraydah/ar/qassim_university.svg";
import QassimBoulvardIcon from "../../static/images/contents/buraydah/ar/qassim_boulevard.svg";
import InternationalAirportIcon from "../../static/images/contents/buraydah/ar/international_airport.svg";
import KingAbdullahSportsCityIcon from "../../static/images/contents/buraydah/ar/king_abdullah_sports_city.svg";

import QassimUniversityIconActive from "../../static/images/contents/buraydah/ar/qassim_university_active.svg";
import QassimBoulvardIconActive from "../../static/images/contents/buraydah/ar/qassim_boulevard_active.svg";
import InternationalAirportIconActive from "../../static/images/contents/buraydah/ar/international_airport_active.svg";
import KingAbdullahSportsCityIconActive from "../../static/images/contents/buraydah/ar/king_abdullah_sports_city_active.svg";

import Mark5KM from "../../static/images/mark-5km-ar.svg";
import Mark10KM from "../../static/images/mark-10km-ar.svg";
import Mark15KM from "../../static/images/mark-15km-ar.svg";
import Mark20KM from "../../static/images/mark-20km-ar.svg";

export const translationsAr = {
    menu_title: "القائمة",
    menu_buraydah: "بريدة",
    menu_master_plan: "المخطط",
    menu_zones: "المنطقة",
    menu_zone_1: "منطقة 1",
    menu_zone_2: "منطقة 2",
    menu_zone_3: "منطقة 3",
    menu_zone_4: "منطقة 4",
    menu_zone_5: "منطقة 5",
    menu_language: "English",
    menu_services: "الخدمات",
    map_show_distances: "عرض المسافة",
    map_show_circles: "عرض الدوائر",
    map_km: "كلم (كيلومترات) ", //كلم
    map_mins: "دقيقة (دقائق)", //دقيقة
    map_5km: Mark5KM,
    map_10km: Mark10KM,
    map_15km: Mark15KM,
    map_20km: Mark20KM,
    percentage_loading_title: "تحميل",
    land_info_title: "قطعة أرض",
    land_info_status_available: "متاح",
    land_info_status_booked: "محجوز",
    land_info_status_sold: "مُباع",
    land_info_electric_transformer: "محول كهربائي تم بناؤه",
    block_info_title: "بلوك",
    back_button: "خلف",
    contact_form_send: "يرسل",
    contact_form_full_name: "الاسم الكامل",
    contact_form_email: "بريد إلكتروني",
    contact_form_phone_number: "رقم التليفون",
    contact_form_sent_your_way: "!شكراً لتسجيل اهتمامك",
    contact_form_would_be_appreciated: ".سنتواصل معك في أقرب وقت",
    buraydah_al_zumuruda_icon: AlZumurudaIcon,
    buraydah_qassim_university_icon: QassimUniversityIcon,
    buraydah_qassim_university_icon_active: QassimUniversityIconActive,
    buraydah_qassim_boulevard_icon: QassimBoulvardIcon,
    buraydah_qassim_boulevard_icon_active: QassimBoulvardIconActive,
    buraydah_international_airport_icon: InternationalAirportIcon,
    buraydah_international_airport_icon_active: InternationalAirportIconActive,
    buraydah_sports_city_icon: KingAbdullahSportsCityIcon,
    buraydah_sports_city_icon_active: KingAbdullahSportsCityIconActive,
    zone_5_switch_land_plot: "أراضي",
    zone_5_switch_blocks: "بلوكات",
    font_inter: "PNU",
    font_salvager: "Etab",
    electricity: "الكهرباء",
    water: "الماء",
    roads: "الطرق",
    stormwaterDrainage: "تصريف مياه الأمطار",
    unit_size: "مساحة القطعة",
    block: "حجب",
    number_of_units: "عدد الوحدات",
    block_size: "مساحة البلوك",
    meters: "م",
    square_meters: "م²",
    contact_form_block: "بلوك",
    contact_form_north: "شمال",
    contact_form_east: "شرق",
    contact_form_south: "جنوب",
    contact_form_west: "الغرب",
    contact_form_front1: "الواجهة 1",
    contact_form_front2: "الجبهة 2",
    contact_form_front_northern: "شمالية",
    contact_form_front_southern: "جنوبية",
    contact_form_front_eastern: "شرقية",
    contact_form_front_western: "غربية",
    contact_form_st_width_1: "عرض الشارع 1",
    contact_form_st_width_2: "عرض الشارع 2",
    contact_from_transformer_info: "تم بناء المحول الكهربائي",
    tip_title: "تنقّل بشكل أفضل",
    tip_desc: "استخدم الفلتر لعرض البلوكات أو الأراضيعلى الخريطة",
};

import SvgAreas from "../../../../components/main/wrappers/svg-areas/SvgAreas";
import SvgCustoms from "../../../../components/main/wrappers/svg-customs/SvgCustoms";

///////////////////////////////////////////////////////////////////////////////////stop frame 1
import { ReactComponent as LandPlot_1_1164 } from "./land_plots/1/land_plot_block_1164.svg";
import { ReactComponent as LandPlot_1_1165 } from "./land_plots/1/land_plot_block_1165.svg";
import { ReactComponent as LandPlot_1_1166 } from "./land_plots/1/land_plot_block_1166.svg";
import { ReactComponent as LandPlot_1_1167 } from "./land_plots/1/land_plot_block_1167.svg";
import { ReactComponent as LandPlot_1_1168 } from "./land_plots/1/land_plot_block_1168.svg";
import { ReactComponent as LandPlot_1_1169 } from "./land_plots/1/land_plot_block_1169.svg";
import { ReactComponent as LandPlot_1_1170 } from "./land_plots/1/land_plot_block_1170.svg";
import { ReactComponent as LandPlot_1_1171 } from "./land_plots/1/land_plot_block_1171.svg";
import { ReactComponent as LandPlot_1_1172 } from "./land_plots/1/land_plot_block_1172.svg";
import { ReactComponent as LandPlot_1_1173 } from "./land_plots/1/land_plot_block_1173.svg";
import { ReactComponent as LandPlot_1_1174 } from "./land_plots/1/land_plot_block_1174.svg";
import { ReactComponent as LandPlot_1_1175 } from "./land_plots/1/land_plot_block_1175.svg";
import { ReactComponent as LandPlot_1_1176 } from "./land_plots/1/land_plot_block_1176.svg";
import { ReactComponent as LandPlot_1_1177 } from "./land_plots/1/land_plot_block_1177.svg";
import { ReactComponent as LandPlot_1_1178 } from "./land_plots/1/land_plot_block_1178.svg";
import { ReactComponent as LandPlot_1_1179 } from "./land_plots/1/land_plot_block_1179.svg";
import { ReactComponent as LandPlot_1_1180 } from "./land_plots/1/land_plot_block_1180.svg";
import { ReactComponent as LandPlot_1_1181 } from "./land_plots/1/land_plot_block_1181.svg";
import { ReactComponent as LandPlot_1_1182 } from "./land_plots/1/land_plot_block_1182.svg";
import { ReactComponent as LandPlot_1_1183 } from "./land_plots/1/land_plot_block_1183.svg";
import { ReactComponent as LandPlot_1_1184 } from "./land_plots/1/land_plot_block_1184.svg";
import { ReactComponent as LandPlot_1_1185 } from "./land_plots/1/land_plot_block_1185.svg";
import { ReactComponent as LandPlot_1_1186 } from "./land_plots/1/land_plot_block_1186.svg";
import { ReactComponent as LandPlot_1_1187 } from "./land_plots/1/land_plot_block_1187.svg";
import { ReactComponent as LandPlot_1_1188 } from "./land_plots/1/land_plot_block_1188.svg";
import { ReactComponent as LandPlot_1_1189 } from "./land_plots/1/land_plot_block_1189.svg";
import { ReactComponent as LandPlot_1_1190 } from "./land_plots/1/land_plot_block_1190.svg";
import { ReactComponent as LandPlot_1_1191 } from "./land_plots/1/land_plot_block_1191.svg";
import { ReactComponent as LandPlot_1_1192 } from "./land_plots/1/land_plot_block_1192.svg";
import { ReactComponent as LandPlot_1_1193 } from "./land_plots/1/land_plot_block_1193.svg";
import { ReactComponent as LandPlot_1_1194 } from "./land_plots/1/land_plot_block_1194.svg";
import { ReactComponent as LandPlot_1_1195 } from "./land_plots/1/land_plot_block_1195.svg";
import { ReactComponent as LandPlot_1_1196 } from "./land_plots/1/land_plot_block_1196.svg";
import { ReactComponent as LandPlot_1_1197 } from "./land_plots/1/land_plot_block_1197.svg";
import { ReactComponent as LandPlot_1_1198 } from "./land_plots/1/land_plot_block_1198.svg";
import { ReactComponent as LandPlot_1_1199 } from "./land_plots/1/land_plot_block_1199.svg";
import { ReactComponent as LandPlot_1_1200 } from "./land_plots/1/land_plot_block_1200.svg";
import { ReactComponent as LandPlot_1_1201 } from "./land_plots/1/land_plot_block_1201.svg";
import { ReactComponent as LandPlot_1_1202 } from "./land_plots/1/land_plot_block_1202.svg";
import { ReactComponent as LandPlot_1_1203 } from "./land_plots/1/land_plot_block_1203.svg";
import { ReactComponent as LandPlot_1_1204 } from "./land_plots/1/land_plot_block_1204.svg";
import { ReactComponent as LandPlot_1_1205 } from "./land_plots/1/land_plot_block_1205.svg";
import { ReactComponent as LandPlot_1_1206 } from "./land_plots/1/land_plot_block_1206.svg";
import { ReactComponent as LandPlot_1_1207 } from "./land_plots/1/land_plot_block_1207.svg";
import { ReactComponent as LandPlot_1_1208 } from "./land_plots/1/land_plot_block_1208.svg";
import { ReactComponent as LandPlot_1_1209 } from "./land_plots/1/land_plot_block_1209.svg";
import { ReactComponent as LandPlot_1_1210 } from "./land_plots/1/land_plot_block_1210.svg";
import { ReactComponent as LandPlot_1_1211 } from "./land_plots/1/land_plot_block_1211.svg";
import { ReactComponent as LandPlot_1_1212 } from "./land_plots/1/land_plot_block_1212.svg";
import { ReactComponent as LandPlot_1_1213 } from "./land_plots/1/land_plot_block_1213.svg";
import { ReactComponent as LandPlot_1_1214 } from "./land_plots/1/land_plot_block_1214.svg";
import { ReactComponent as LandPlot_1_1215 } from "./land_plots/1/land_plot_block_1215.svg";
import { ReactComponent as LandPlot_1_1216 } from "./land_plots/1/land_plot_block_1216.svg";
import { ReactComponent as LandPlot_1_1217 } from "./land_plots/1/land_plot_block_1217.svg";
import { ReactComponent as LandPlot_1_1218 } from "./land_plots/1/land_plot_block_1218.svg";
import { ReactComponent as LandPlot_1_1219 } from "./land_plots/1/land_plot_block_1219.svg";
import { ReactComponent as LandPlot_1_1220 } from "./land_plots/1/land_plot_block_1220.svg";
import { ReactComponent as LandPlot_1_1221 } from "./land_plots/1/land_plot_block_1221.svg";
import { ReactComponent as LandPlot_1_1222 } from "./land_plots/1/land_plot_block_1222.svg";
import { ReactComponent as LandPlot_1_1223 } from "./land_plots/1/land_plot_block_1223.svg";
import { ReactComponent as LandPlot_1_1224 } from "./land_plots/1/land_plot_block_1224.svg";
import { ReactComponent as LandPlot_1_1225 } from "./land_plots/1/land_plot_block_1225.svg";
import { ReactComponent as LandPlot_1_1226 } from "./land_plots/1/land_plot_block_1226.svg";
import { ReactComponent as LandPlot_1_1227 } from "./land_plots/1/land_plot_block_1227.svg";
import { ReactComponent as LandPlot_1_1228 } from "./land_plots/1/land_plot_block_1228.svg";
import { ReactComponent as LandPlot_1_1229 } from "./land_plots/1/land_plot_block_1229.svg";
import { ReactComponent as LandPlot_1_1230 } from "./land_plots/1/land_plot_block_1230.svg";
import { ReactComponent as LandPlot_1_1231 } from "./land_plots/1/land_plot_block_1231.svg";
import { ReactComponent as LandPlot_1_1232 } from "./land_plots/1/land_plot_block_1232.svg";
import { ReactComponent as LandPlot_1_1233 } from "./land_plots/1/land_plot_block_1233.svg";
import { ReactComponent as LandPlot_1_1234 } from "./land_plots/1/land_plot_block_1234.svg";
import { ReactComponent as LandPlot_1_1235 } from "./land_plots/1/land_plot_block_1235.svg";
import { ReactComponent as LandPlot_1_1236 } from "./land_plots/1/land_plot_block_1236.svg";
import { ReactComponent as LandPlot_1_1237 } from "./land_plots/1/land_plot_block_1237.svg";
import { ReactComponent as LandPlot_1_1238 } from "./land_plots/1/land_plot_block_1238.svg";
import { ReactComponent as LandPlot_1_1239 } from "./land_plots/1/land_plot_block_1239.svg";
import { ReactComponent as LandPlot_1_1240 } from "./land_plots/1/land_plot_block_1240.svg";
import { ReactComponent as LandPlot_1_1241 } from "./land_plots/1/land_plot_block_1241.svg";
import { ReactComponent as LandPlot_1_1242 } from "./land_plots/1/land_plot_block_1242.svg";
import { ReactComponent as LandPlot_1_1243 } from "./land_plots/1/land_plot_block_1243.svg";
import { ReactComponent as LandPlot_1_1244 } from "./land_plots/1/land_plot_block_1244.svg";
import { ReactComponent as LandPlot_1_1245 } from "./land_plots/1/land_plot_block_1245.svg";
import { ReactComponent as LandPlot_1_1246 } from "./land_plots/1/land_plot_block_1246.svg";
import { ReactComponent as LandPlot_1_1247 } from "./land_plots/1/land_plot_block_1247.svg";
import { ReactComponent as LandPlot_1_1248 } from "./land_plots/1/land_plot_block_1248.svg";
import { ReactComponent as LandPlot_1_1249 } from "./land_plots/1/land_plot_block_1249.svg";
import { ReactComponent as LandPlot_1_1250 } from "./land_plots/1/land_plot_block_1250.svg";
import { ReactComponent as LandPlot_1_1251 } from "./land_plots/1/land_plot_block_1251.svg";
import { ReactComponent as LandPlot_1_1252 } from "./land_plots/1/land_plot_block_1252.svg";
import { ReactComponent as LandPlot_1_1253 } from "./land_plots/1/land_plot_block_1253.svg";
import { ReactComponent as LandPlot_1_1254 } from "./land_plots/1/land_plot_block_1254.svg";
import { ReactComponent as LandPlot_1_1255 } from "./land_plots/1/land_plot_block_1255.svg";
import { ReactComponent as LandPlot_1_1256 } from "./land_plots/1/land_plot_block_1256.svg";
import { ReactComponent as LandPlot_1_1257 } from "./land_plots/1/land_plot_block_1257.svg";
import { ReactComponent as LandPlot_1_1258 } from "./land_plots/1/land_plot_block_1258.svg";
import { ReactComponent as LandPlot_1_1259 } from "./land_plots/1/land_plot_block_1259.svg";
import { ReactComponent as LandPlot_1_1260 } from "./land_plots/1/land_plot_block_1260.svg";
import { ReactComponent as LandPlot_1_1261 } from "./land_plots/1/land_plot_block_1261.svg";
import { ReactComponent as LandPlot_1_1262 } from "./land_plots/1/land_plot_block_1262.svg";
import { ReactComponent as LandPlot_1_1263 } from "./land_plots/1/land_plot_block_1263.svg";
import { ReactComponent as LandPlot_1_1264 } from "./land_plots/1/land_plot_block_1264.svg";
import { ReactComponent as LandPlot_1_1265 } from "./land_plots/1/land_plot_block_1265.svg";
import { ReactComponent as LandPlot_1_1266 } from "./land_plots/1/land_plot_block_1266.svg";
import { ReactComponent as LandPlot_1_1267 } from "./land_plots/1/land_plot_block_1267.svg";
import { ReactComponent as LandPlot_1_1268 } from "./land_plots/1/land_plot_block_1268.svg";
import { ReactComponent as LandPlot_1_1269 } from "./land_plots/1/land_plot_block_1269.svg";
import { ReactComponent as LandPlot_1_1270 } from "./land_plots/1/land_plot_block_1270.svg";
import { ReactComponent as LandPlot_1_1271 } from "./land_plots/1/land_plot_block_1271.svg";
import { ReactComponent as LandPlot_1_1272 } from "./land_plots/1/land_plot_block_1272.svg";
import { ReactComponent as LandPlot_1_1273 } from "./land_plots/1/land_plot_block_1273.svg";
import { ReactComponent as LandPlot_1_1274 } from "./land_plots/1/land_plot_block_1274.svg";
import { ReactComponent as LandPlot_1_1275 } from "./land_plots/1/land_plot_block_1275.svg";
import { ReactComponent as LandPlot_1_1276 } from "./land_plots/1/land_plot_block_1276.svg";
import { ReactComponent as LandPlot_1_1277 } from "./land_plots/1/land_plot_block_1277.svg";
import { ReactComponent as LandPlot_1_1278 } from "./land_plots/1/land_plot_block_1278.svg";
import { ReactComponent as LandPlot_1_1279 } from "./land_plots/1/land_plot_block_1279.svg";
import { ReactComponent as LandPlot_1_1280 } from "./land_plots/1/land_plot_block_1280.svg";
import { ReactComponent as LandPlot_1_1281 } from "./land_plots/1/land_plot_block_1281.svg";
import { ReactComponent as LandPlot_1_1282 } from "./land_plots/1/land_plot_block_1282.svg";
import { ReactComponent as LandPlot_1_1283 } from "./land_plots/1/land_plot_block_1283.svg";
import { ReactComponent as LandPlot_1_1284 } from "./land_plots/1/land_plot_block_1284.svg";
import { ReactComponent as LandPlot_1_1285 } from "./land_plots/1/land_plot_block_1285.svg";
import { ReactComponent as LandPlot_1_1286 } from "./land_plots/1/land_plot_block_1286.svg";
import { ReactComponent as LandPlot_1_1287 } from "./land_plots/1/land_plot_block_1287.svg";
import { ReactComponent as LandPlot_1_1288 } from "./land_plots/1/land_plot_block_1288.svg";
import { ReactComponent as LandPlot_1_1289 } from "./land_plots/1/land_plot_block_1289.svg";
import { ReactComponent as LandPlot_1_1290 } from "./land_plots/1/land_plot_block_1290.svg";
import { ReactComponent as LandPlot_1_1291 } from "./land_plots/1/land_plot_block_1291.svg";
import { ReactComponent as LandPlot_1_1292 } from "./land_plots/1/land_plot_block_1292.svg";
import { ReactComponent as LandPlot_1_1293 } from "./land_plots/1/land_plot_block_1293.svg";
import { ReactComponent as LandPlot_1_1294 } from "./land_plots/1/land_plot_block_1294.svg";
import { ReactComponent as LandPlot_1_1295 } from "./land_plots/1/land_plot_block_1295.svg";
import { ReactComponent as LandPlot_1_1296 } from "./land_plots/1/land_plot_block_1296.svg";
import { ReactComponent as LandPlot_1_1297 } from "./land_plots/1/land_plot_block_1297.svg";
import { ReactComponent as LandPlot_1_1298 } from "./land_plots/1/land_plot_block_1298.svg";
import { ReactComponent as LandPlot_1_1299 } from "./land_plots/1/land_plot_block_1299.svg";
import { ReactComponent as LandPlot_1_1300 } from "./land_plots/1/land_plot_block_1300.svg";
import { ReactComponent as LandPlot_1_1301 } from "./land_plots/1/land_plot_block_1301.svg";
import { ReactComponent as LandPlot_1_1302 } from "./land_plots/1/land_plot_block_1302.svg";
import { ReactComponent as LandPlot_1_1303 } from "./land_plots/1/land_plot_block_1303.svg";
import { ReactComponent as LandPlot_1_1304 } from "./land_plots/1/land_plot_block_1304.svg";
import { ReactComponent as LandPlot_1_1305 } from "./land_plots/1/land_plot_block_1305.svg";
import { ReactComponent as LandPlot_1_1306 } from "./land_plots/1/land_plot_block_1306.svg";
import { ReactComponent as LandPlot_1_1307 } from "./land_plots/1/land_plot_block_1307.svg";
import { ReactComponent as LandPlot_1_1308 } from "./land_plots/1/land_plot_block_1308.svg";
import { ReactComponent as LandPlot_1_1309 } from "./land_plots/1/land_plot_block_1309.svg";
import { ReactComponent as LandPlot_1_1310 } from "./land_plots/1/land_plot_block_1310.svg";
import { ReactComponent as LandPlot_1_1311 } from "./land_plots/1/land_plot_block_1311.svg";
import { ReactComponent as LandPlot_1_1312 } from "./land_plots/1/land_plot_block_1312.svg";
import { ReactComponent as LandPlot_1_1313 } from "./land_plots/1/land_plot_block_1313.svg";
import { ReactComponent as LandPlot_1_1314 } from "./land_plots/1/land_plot_block_1314.svg";
import { ReactComponent as LandPlot_1_1315 } from "./land_plots/1/land_plot_block_1315.svg";
import { ReactComponent as LandPlot_1_1316 } from "./land_plots/1/land_plot_block_1316.svg";
import { ReactComponent as LandPlot_1_1317 } from "./land_plots/1/land_plot_block_1317.svg";
import { ReactComponent as LandPlot_1_1318 } from "./land_plots/1/land_plot_block_1318.svg";
import { ReactComponent as LandPlot_1_1319 } from "./land_plots/1/land_plot_block_1319.svg";
import { ReactComponent as LandPlot_1_1320 } from "./land_plots/1/land_plot_block_1320.svg";
import { ReactComponent as LandPlot_1_1321 } from "./land_plots/1/land_plot_block_1321.svg";
import { ReactComponent as LandPlot_1_1322 } from "./land_plots/1/land_plot_block_1322.svg";
import { ReactComponent as LandPlot_1_1323 } from "./land_plots/1/land_plot_block_1323.svg";
import { ReactComponent as LandPlot_1_1324 } from "./land_plots/1/land_plot_block_1324.svg";
import { ReactComponent as LandPlot_1_1325 } from "./land_plots/1/land_plot_block_1325.svg";
import { ReactComponent as LandPlot_1_1326 } from "./land_plots/1/land_plot_block_1326.svg";
import { ReactComponent as LandPlot_1_1327 } from "./land_plots/1/land_plot_block_1327.svg";
import { ReactComponent as LandPlot_1_1328 } from "./land_plots/1/land_plot_block_1328.svg";
import { ReactComponent as LandPlot_1_1329 } from "./land_plots/1/land_plot_block_1329.svg";
import { ReactComponent as LandPlot_1_1330 } from "./land_plots/1/land_plot_block_1330.svg";
import { ReactComponent as LandPlot_1_1331 } from "./land_plots/1/land_plot_block_1331.svg";
import { ReactComponent as LandPlot_1_1332 } from "./land_plots/1/land_plot_block_1332.svg";
import { ReactComponent as LandPlot_1_1333 } from "./land_plots/1/land_plot_block_1333.svg";
import { ReactComponent as LandPlot_1_1334 } from "./land_plots/1/land_plot_block_1334.svg";
import { ReactComponent as LandPlot_1_1335 } from "./land_plots/1/land_plot_block_1335.svg";
import { ReactComponent as LandPlot_1_1336 } from "./land_plots/1/land_plot_block_1336.svg";
import { ReactComponent as LandPlot_1_1337 } from "./land_plots/1/land_plot_block_1337.svg";
import { ReactComponent as LandPlot_1_1338 } from "./land_plots/1/land_plot_block_1338.svg";
import { ReactComponent as LandPlot_1_1339 } from "./land_plots/1/land_plot_block_1339.svg";
import { ReactComponent as LandPlot_1_1340 } from "./land_plots/1/land_plot_block_1340.svg";
import { ReactComponent as LandPlot_1_1341 } from "./land_plots/1/land_plot_block_1341.svg";
import { ReactComponent as LandPlot_1_1342 } from "./land_plots/1/land_plot_block_1342.svg";
import { ReactComponent as LandPlot_1_1343 } from "./land_plots/1/land_plot_block_1343.svg";
import { ReactComponent as LandPlot_1_1344 } from "./land_plots/1/land_plot_block_1344.svg";
import { ReactComponent as LandPlot_1_1345 } from "./land_plots/1/land_plot_block_1345.svg";
import { ReactComponent as LandPlot_1_1346 } from "./land_plots/1/land_plot_block_1346.svg";
import { ReactComponent as LandPlot_1_1347 } from "./land_plots/1/land_plot_block_1347.svg";
import { ReactComponent as LandPlot_1_1348 } from "./land_plots/1/land_plot_block_1348.svg";
import { ReactComponent as LandPlot_1_1349 } from "./land_plots/1/land_plot_block_1349.svg";
import { ReactComponent as LandPlot_1_1350 } from "./land_plots/1/land_plot_block_1350.svg";
import { ReactComponent as LandPlot_1_1351 } from "./land_plots/1/land_plot_block_1351.svg";
import { ReactComponent as LandPlot_1_1352 } from "./land_plots/1/land_plot_block_1352.svg";
import { ReactComponent as LandPlot_1_1353 } from "./land_plots/1/land_plot_block_1353.svg";
import { ReactComponent as LandPlot_1_1354 } from "./land_plots/1/land_plot_block_1354.svg";
import { ReactComponent as LandPlot_1_1355 } from "./land_plots/1/land_plot_block_1355.svg";
import { ReactComponent as LandPlot_1_1356 } from "./land_plots/1/land_plot_block_1356.svg";
import { ReactComponent as LandPlot_1_1357 } from "./land_plots/1/land_plot_block_1357.svg";
import { ReactComponent as LandPlot_1_1358 } from "./land_plots/1/land_plot_block_1358.svg";
import { ReactComponent as LandPlot_1_1359 } from "./land_plots/1/land_plot_block_1359.svg";
import { ReactComponent as LandPlot_1_1360 } from "./land_plots/1/land_plot_block_1360.svg";
import { ReactComponent as LandPlot_1_1361 } from "./land_plots/1/land_plot_block_1361.svg";
import { ReactComponent as LandPlot_1_1362 } from "./land_plots/1/land_plot_block_1362.svg";
import { ReactComponent as LandPlot_1_1363 } from "./land_plots/1/land_plot_block_1363.svg";
import { ReactComponent as LandPlot_1_1364 } from "./land_plots/1/land_plot_block_1364.svg";
import { ReactComponent as LandPlot_1_1365 } from "./land_plots/1/land_plot_block_1365.svg";
import { ReactComponent as LandPlot_1_1366 } from "./land_plots/1/land_plot_block_1366.svg";
import { ReactComponent as LandPlot_1_1367 } from "./land_plots/1/land_plot_block_1367.svg";
import { ReactComponent as LandPlot_1_1368 } from "./land_plots/1/land_plot_block_1368.svg";
import { ReactComponent as LandPlot_1_1369 } from "./land_plots/1/land_plot_block_1369.svg";
import { ReactComponent as LandPlot_1_1370 } from "./land_plots/1/land_plot_block_1370.svg";
import { ReactComponent as LandPlot_1_1371 } from "./land_plots/1/land_plot_block_1371.svg";
import { ReactComponent as LandPlot_1_1372 } from "./land_plots/1/land_plot_block_1372.svg";
import { ReactComponent as LandPlot_1_1373 } from "./land_plots/1/land_plot_block_1373.svg";
import { ReactComponent as LandPlot_1_1374 } from "./land_plots/1/land_plot_block_1374.svg";
import { ReactComponent as LandPlot_1_1375 } from "./land_plots/1/land_plot_block_1375.svg";
import { ReactComponent as LandPlot_1_1376 } from "./land_plots/1/land_plot_block_1376.svg";
import { ReactComponent as LandPlot_1_1377 } from "./land_plots/1/land_plot_block_1377.svg";
import { ReactComponent as LandPlot_1_1378 } from "./land_plots/1/land_plot_block_1378.svg";
import { ReactComponent as LandPlot_1_1379 } from "./land_plots/1/land_plot_block_1379.svg";
import { ReactComponent as LandPlot_1_1380 } from "./land_plots/1/land_plot_block_1380.svg";
import { ReactComponent as LandPlot_1_1381 } from "./land_plots/1/land_plot_block_1381.svg";
import { ReactComponent as LandPlot_1_1382 } from "./land_plots/1/land_plot_block_1382.svg";
import { ReactComponent as LandPlot_1_1383 } from "./land_plots/1/land_plot_block_1383.svg";
import { ReactComponent as LandPlot_1_1384 } from "./land_plots/1/land_plot_block_1384.svg";
import { ReactComponent as LandPlot_1_1385 } from "./land_plots/1/land_plot_block_1385.svg";
import { ReactComponent as LandPlot_1_1386 } from "./land_plots/1/land_plot_block_1386.svg";
import { ReactComponent as LandPlot_1_1387 } from "./land_plots/1/land_plot_block_1387.svg";
import { ReactComponent as LandPlot_1_1388 } from "./land_plots/1/land_plot_block_1388.svg";
import { ReactComponent as LandPlot_1_1389 } from "./land_plots/1/land_plot_block_1389.svg";
import { ReactComponent as LandPlot_1_1390 } from "./land_plots/1/land_plot_block_1390.svg";
import { ReactComponent as LandPlot_1_1391 } from "./land_plots/1/land_plot_block_1391.svg";
import { ReactComponent as LandPlot_1_1392 } from "./land_plots/1/land_plot_block_1392.svg";
import { ReactComponent as LandPlot_1_1393 } from "./land_plots/1/land_plot_block_1393.svg";
import { ReactComponent as LandPlot_1_1394 } from "./land_plots/1/land_plot_block_1394.svg";
import { ReactComponent as LandPlot_1_1395 } from "./land_plots/1/land_plot_block_1395.svg";
import { ReactComponent as LandPlot_1_1396 } from "./land_plots/1/land_plot_block_1396.svg";
import { ReactComponent as LandPlot_1_1397 } from "./land_plots/1/land_plot_block_1397.svg";
import { ReactComponent as LandPlot_1_1398 } from "./land_plots/1/land_plot_block_1398.svg";
import { ReactComponent as LandPlot_1_1399 } from "./land_plots/1/land_plot_block_1399.svg";
import { ReactComponent as LandPlot_1_1400 } from "./land_plots/1/land_plot_block_1400.svg";
import { ReactComponent as LandPlot_1_1401 } from "./land_plots/1/land_plot_block_1401.svg";
import { ReactComponent as LandPlot_1_1402 } from "./land_plots/1/land_plot_block_1402.svg";
import { ReactComponent as LandPlot_1_1403 } from "./land_plots/1/land_plot_block_1403.svg";
import { ReactComponent as LandPlot_1_1404 } from "./land_plots/1/land_plot_block_1404.svg";
import { ReactComponent as LandPlot_1_1405 } from "./land_plots/1/land_plot_block_1405.svg";
import { ReactComponent as LandPlot_1_1406 } from "./land_plots/1/land_plot_block_1406.svg";
import { ReactComponent as LandPlot_1_1407 } from "./land_plots/1/land_plot_block_1407.svg";
import { ReactComponent as LandPlot_1_1408 } from "./land_plots/1/land_plot_block_1408.svg";
import { ReactComponent as LandPlot_1_1409 } from "./land_plots/1/land_plot_block_1409.svg";
import { ReactComponent as LandPlot_1_1410 } from "./land_plots/1/land_plot_block_1410.svg";
import { ReactComponent as LandPlot_1_1411 } from "./land_plots/1/land_plot_block_1411.svg";
import { ReactComponent as LandPlot_1_1412 } from "./land_plots/1/land_plot_block_1412.svg";
import { ReactComponent as LandPlot_1_1413 } from "./land_plots/1/land_plot_block_1413.svg";
import { ReactComponent as LandPlot_1_1414 } from "./land_plots/1/land_plot_block_1414.svg";
import { ReactComponent as LandPlot_1_1415 } from "./land_plots/1/land_plot_block_1415.svg";
import { ReactComponent as LandPlot_1_1416 } from "./land_plots/1/land_plot_block_1416.svg";
import { ReactComponent as LandPlot_1_1417 } from "./land_plots/1/land_plot_block_1417.svg";
import { ReactComponent as LandPlot_1_1418 } from "./land_plots/1/land_plot_block_1418.svg";
import { ReactComponent as LandPlot_1_1419 } from "./land_plots/1/land_plot_block_1419.svg";
import { ReactComponent as LandPlot_1_1420 } from "./land_plots/1/land_plot_block_1420.svg";
import { ReactComponent as LandPlot_1_1421 } from "./land_plots/1/land_plot_block_1421.svg";
import { ReactComponent as LandPlot_1_1422 } from "./land_plots/1/land_plot_block_1422.svg";
import { ReactComponent as LandPlot_1_1423 } from "./land_plots/1/land_plot_block_1423.svg";
import { ReactComponent as LandPlot_1_1424 } from "./land_plots/1/land_plot_block_1424.svg";
import { ReactComponent as LandPlot_1_1425 } from "./land_plots/1/land_plot_block_1425.svg";
import { ReactComponent as LandPlot_1_1426 } from "./land_plots/1/land_plot_block_1426.svg";
import { ReactComponent as LandPlot_1_1427 } from "./land_plots/1/land_plot_block_1427.svg";
import { ReactComponent as LandPlot_1_1428 } from "./land_plots/1/land_plot_block_1428.svg";
import { ReactComponent as LandPlot_1_1429 } from "./land_plots/1/land_plot_block_1429.svg";
import { ReactComponent as LandPlot_1_1430 } from "./land_plots/1/land_plot_block_1430.svg";
import { ReactComponent as LandPlot_1_1431 } from "./land_plots/1/land_plot_block_1431.svg";
import { ReactComponent as LandPlot_1_1432 } from "./land_plots/1/land_plot_block_1432.svg";
import { ReactComponent as LandPlot_1_1433 } from "./land_plots/1/land_plot_block_1433.svg";
import { ReactComponent as LandPlot_1_1434 } from "./land_plots/1/land_plot_block_1434.svg";
import { ReactComponent as LandPlot_1_1435 } from "./land_plots/1/land_plot_block_1435.svg";
import { ReactComponent as LandPlot_1_1436 } from "./land_plots/1/land_plot_block_1436.svg";
import { ReactComponent as LandPlot_1_1437 } from "./land_plots/1/land_plot_block_1437.svg";
import { ReactComponent as LandPlot_1_1438 } from "./land_plots/1/land_plot_block_1438.svg";
import { ReactComponent as LandPlot_1_1439 } from "./land_plots/1/land_plot_block_1439.svg";
import { ReactComponent as LandPlot_1_1440 } from "./land_plots/1/land_plot_block_1440.svg";
import { ReactComponent as LandPlot_1_1441 } from "./land_plots/1/land_plot_block_1441.svg";
import { ReactComponent as LandPlot_1_1442 } from "./land_plots/1/land_plot_block_1442.svg";
import { ReactComponent as LandPlot_1_1443 } from "./land_plots/1/land_plot_block_1443.svg";
import { ReactComponent as LandPlot_1_1444 } from "./land_plots/1/land_plot_block_1444.svg";
import { ReactComponent as LandPlot_1_1445 } from "./land_plots/1/land_plot_block_1445.svg";
import { ReactComponent as LandPlot_1_1446 } from "./land_plots/1/land_plot_block_1446.svg";
import { ReactComponent as LandPlot_1_1447 } from "./land_plots/1/land_plot_block_1447.svg";
import { ReactComponent as LandPlot_1_1448 } from "./land_plots/1/land_plot_block_1448.svg";
import { ReactComponent as LandPlot_1_1449 } from "./land_plots/1/land_plot_block_1449.svg";
import { ReactComponent as LandPlot_1_1450 } from "./land_plots/1/land_plot_block_1450.svg";
import { ReactComponent as LandPlot_1_1451 } from "./land_plots/1/land_plot_block_1451.svg";
import { ReactComponent as LandPlot_1_1452 } from "./land_plots/1/land_plot_block_1452.svg";
import { ReactComponent as LandPlot_1_1453 } from "./land_plots/1/land_plot_block_1453.svg";
import { ReactComponent as LandPlot_1_1454 } from "./land_plots/1/land_plot_block_1454.svg";
import { ReactComponent as LandPlot_1_1455 } from "./land_plots/1/land_plot_block_1455.svg";
import { ReactComponent as LandPlot_1_1456 } from "./land_plots/1/land_plot_block_1456.svg";
import { ReactComponent as LandPlot_1_1457 } from "./land_plots/1/land_plot_block_1457.svg";
import { ReactComponent as LandPlot_1_1458 } from "./land_plots/1/land_plot_block_1458.svg";
import { ReactComponent as LandPlot_1_1459 } from "./land_plots/1/land_plot_block_1459.svg";
import { ReactComponent as LandPlot_1_1460 } from "./land_plots/1/land_plot_block_1460.svg";
import { ReactComponent as LandPlot_1_1461 } from "./land_plots/1/land_plot_block_1461.svg";
import { ReactComponent as LandPlot_1_1462 } from "./land_plots/1/land_plot_block_1462.svg";
import { ReactComponent as LandPlot_1_1463 } from "./land_plots/1/land_plot_block_1463.svg";
import { ReactComponent as LandPlot_1_1464 } from "./land_plots/1/land_plot_block_1464.svg";
import { ReactComponent as LandPlot_1_1465 } from "./land_plots/1/land_plot_block_1465.svg";
import { ReactComponent as LandPlot_1_1466 } from "./land_plots/1/land_plot_block_1466.svg";
import { ReactComponent as LandPlot_1_1467 } from "./land_plots/1/land_plot_block_1467.svg";
import { ReactComponent as LandPlot_1_1468 } from "./land_plots/1/land_plot_block_1468.svg";
import { ReactComponent as LandPlot_1_1469 } from "./land_plots/1/land_plot_block_1469.svg";
import { ReactComponent as LandPlot_1_1470 } from "./land_plots/1/land_plot_block_1470.svg";
import { ReactComponent as LandPlot_1_1471 } from "./land_plots/1/land_plot_block_1471.svg";
import { ReactComponent as LandPlot_1_1472 } from "./land_plots/1/land_plot_block_1472.svg";
import { ReactComponent as LandPlot_1_1473 } from "./land_plots/1/land_plot_block_1473.svg";
import { ReactComponent as LandPlot_1_1474 } from "./land_plots/1/land_plot_block_1474.svg";
import { ReactComponent as LandPlot_1_1475 } from "./land_plots/1/land_plot_block_1475.svg";
import { ReactComponent as LandPlot_1_1476 } from "./land_plots/1/land_plot_block_1476.svg";
import { ReactComponent as LandPlot_1_1477 } from "./land_plots/1/land_plot_block_1477.svg";
import { ReactComponent as LandPlot_1_1478 } from "./land_plots/1/land_plot_block_1478.svg";
import { ReactComponent as LandPlot_1_1479 } from "./land_plots/1/land_plot_block_1479.svg";
import { ReactComponent as LandPlot_1_1480 } from "./land_plots/1/land_plot_block_1480.svg";
import { ReactComponent as LandPlot_1_1481 } from "./land_plots/1/land_plot_block_1481.svg";
import { ReactComponent as LandPlot_1_1482 } from "./land_plots/1/land_plot_block_1482.svg";
import { ReactComponent as LandPlot_1_1483 } from "./land_plots/1/land_plot_block_1483.svg";
import { ReactComponent as LandPlot_1_1484 } from "./land_plots/1/land_plot_block_1484.svg";
import { ReactComponent as LandPlot_1_1485 } from "./land_plots/1/land_plot_block_1485.svg";
import { ReactComponent as LandPlot_1_1486 } from "./land_plots/1/land_plot_block_1486.svg";
import { ReactComponent as LandPlot_1_1487 } from "./land_plots/1/land_plot_block_1487.svg";
import { ReactComponent as LandPlot_1_1488 } from "./land_plots/1/land_plot_block_1488.svg";
import { ReactComponent as LandPlot_1_1489 } from "./land_plots/1/land_plot_block_1489.svg";
import { ReactComponent as LandPlot_1_1490 } from "./land_plots/1/land_plot_block_1490.svg";
import { ReactComponent as LandPlot_1_1491 } from "./land_plots/1/land_plot_block_1491.svg";
import { ReactComponent as LandPlot_1_1492 } from "./land_plots/1/land_plot_block_1492.svg";
import { ReactComponent as LandPlot_1_1493 } from "./land_plots/1/land_plot_block_1493.svg";
import { ReactComponent as LandPlot_1_1494 } from "./land_plots/1/land_plot_block_1494.svg";
import { ReactComponent as LandPlot_1_1495 } from "./land_plots/1/land_plot_block_1495.svg";
import { ReactComponent as LandPlot_1_1496 } from "./land_plots/1/land_plot_block_1496.svg";
import { ReactComponent as LandPlot_1_1497 } from "./land_plots/1/land_plot_block_1497.svg";
import { ReactComponent as LandPlot_1_1498 } from "./land_plots/1/land_plot_block_1498.svg";
import { ReactComponent as LandPlot_1_1499 } from "./land_plots/1/land_plot_block_1499.svg";
import { ReactComponent as LandPlot_1_1500 } from "./land_plots/1/land_plot_block_1500.svg";
import { ReactComponent as LandPlot_1_1501 } from "./land_plots/1/land_plot_block_1501.svg";
import { ReactComponent as LandPlot_1_1502 } from "./land_plots/1/land_plot_block_1502.svg";
import { ReactComponent as LandPlot_1_1503 } from "./land_plots/1/land_plot_block_1503.svg";
import { ReactComponent as LandPlot_1_1504 } from "./land_plots/1/land_plot_block_1504.svg";
import { ReactComponent as LandPlot_1_1505 } from "./land_plots/1/land_plot_block_1505.svg";
import { ReactComponent as LandPlot_1_1506 } from "./land_plots/1/land_plot_block_1506.svg";
import { ReactComponent as LandPlot_1_1507 } from "./land_plots/1/land_plot_block_1507.svg";
import { ReactComponent as LandPlot_1_1508 } from "./land_plots/1/land_plot_block_1508.svg";
import { ReactComponent as LandPlot_1_1509 } from "./land_plots/1/land_plot_block_1509.svg";
import { ReactComponent as LandPlot_1_1510 } from "./land_plots/1/land_plot_block_1510.svg";
import { ReactComponent as LandPlot_1_1511 } from "./land_plots/1/land_plot_block_1511.svg";
import { ReactComponent as LandPlot_1_1512 } from "./land_plots/1/land_plot_block_1512.svg";
import { ReactComponent as LandPlot_1_1513 } from "./land_plots/1/land_plot_block_1513.svg";
import { ReactComponent as LandPlot_1_1514 } from "./land_plots/1/land_plot_block_1514.svg";
import { ReactComponent as LandPlot_1_1515 } from "./land_plots/1/land_plot_block_1515.svg";
import { ReactComponent as LandPlot_1_1516 } from "./land_plots/1/land_plot_block_1516.svg";
import { ReactComponent as LandPlot_1_1517 } from "./land_plots/1/land_plot_block_1517.svg";
import { ReactComponent as LandPlot_1_1518 } from "./land_plots/1/land_plot_block_1518.svg";
import { ReactComponent as LandPlot_1_1519 } from "./land_plots/1/land_plot_block_1519.svg";
import { ReactComponent as LandPlot_1_1520 } from "./land_plots/1/land_plot_block_1520.svg";
import { ReactComponent as LandPlot_1_1521 } from "./land_plots/1/land_plot_block_1521.svg";
import { ReactComponent as LandPlot_1_1522 } from "./land_plots/1/land_plot_block_1522.svg";
import { ReactComponent as LandPlot_1_1523 } from "./land_plots/1/land_plot_block_1523.svg";
import { ReactComponent as LandPlot_1_1524 } from "./land_plots/1/land_plot_block_1524.svg";
import { ReactComponent as LandPlot_1_1525 } from "./land_plots/1/land_plot_block_1525.svg";
import { ReactComponent as LandPlot_1_1526 } from "./land_plots/1/land_plot_block_1526.svg";
import { ReactComponent as LandPlot_1_1527 } from "./land_plots/1/land_plot_block_1527.svg";
import { ReactComponent as LandPlot_1_1528 } from "./land_plots/1/land_plot_block_1528.svg";
import { ReactComponent as LandPlot_1_1529 } from "./land_plots/1/land_plot_block_1529.svg";
import { ReactComponent as LandPlot_1_1530 } from "./land_plots/1/land_plot_block_1530.svg";
import { ReactComponent as LandPlot_1_1531 } from "./land_plots/1/land_plot_block_1531.svg";
import { ReactComponent as LandPlot_1_1532 } from "./land_plots/1/land_plot_block_1532.svg";
import { ReactComponent as LandPlot_1_1533 } from "./land_plots/1/land_plot_block_1533.svg";
import { ReactComponent as LandPlot_1_1534 } from "./land_plots/1/land_plot_block_1534.svg";
import { ReactComponent as LandPlot_1_1535 } from "./land_plots/1/land_plot_block_1535.svg";
import { ReactComponent as LandPlot_1_1536 } from "./land_plots/1/land_plot_block_1536.svg";
import { ReactComponent as LandPlot_1_1537 } from "./land_plots/1/land_plot_block_1537.svg";
import { ReactComponent as LandPlot_1_1538 } from "./land_plots/1/land_plot_block_1538.svg";
import { ReactComponent as LandPlot_1_1539 } from "./land_plots/1/land_plot_block_1539.svg";
import { ReactComponent as LandPlot_1_1540 } from "./land_plots/1/land_plot_block_1540.svg";
import { ReactComponent as LandPlot_1_1541 } from "./land_plots/1/land_plot_block_1541.svg";
import { ReactComponent as LandPlot_1_1542 } from "./land_plots/1/land_plot_block_1542.svg";
import { ReactComponent as LandPlot_1_1543 } from "./land_plots/1/land_plot_block_1543.svg";
import { ReactComponent as LandPlot_1_1544 } from "./land_plots/1/land_plot_block_1544.svg";
import { ReactComponent as LandPlot_1_1545 } from "./land_plots/1/land_plot_block_1545.svg";
import { ReactComponent as LandPlot_1_1546 } from "./land_plots/1/land_plot_block_1546.svg";
import { ReactComponent as LandPlot_1_1547 } from "./land_plots/1/land_plot_block_1547.svg";
import { ReactComponent as LandPlot_1_1548 } from "./land_plots/1/land_plot_block_1548.svg";
import { ReactComponent as LandPlot_1_1549 } from "./land_plots/1/land_plot_block_1549.svg";
import { ReactComponent as LandPlot_1_1550 } from "./land_plots/1/land_plot_block_1550.svg";
import { ReactComponent as LandPlot_1_1551 } from "./land_plots/1/land_plot_block_1551.svg";
import { ReactComponent as LandPlot_1_1552 } from "./land_plots/1/land_plot_block_1552.svg";
import { ReactComponent as LandPlot_1_1553 } from "./land_plots/1/land_plot_block_1553.svg";
import { ReactComponent as LandPlot_1_1554 } from "./land_plots/1/land_plot_block_1554.svg";
import { ReactComponent as LandPlot_1_1555 } from "./land_plots/1/land_plot_block_1555.svg";
import { ReactComponent as LandPlot_1_1556 } from "./land_plots/1/land_plot_block_1556.svg";
import { ReactComponent as LandPlot_1_1557 } from "./land_plots/1/land_plot_block_1557.svg";
import { ReactComponent as LandPlot_1_1558 } from "./land_plots/1/land_plot_block_1558.svg";
import { ReactComponent as LandPlot_1_1559 } from "./land_plots/1/land_plot_block_1559.svg";
import { ReactComponent as LandPlot_1_1560 } from "./land_plots/1/land_plot_block_1560.svg";
import { ReactComponent as LandPlot_1_1561 } from "./land_plots/1/land_plot_block_1561.svg";
import { ReactComponent as LandPlot_1_1562 } from "./land_plots/1/land_plot_block_1562.svg";
import { ReactComponent as LandPlot_1_1563 } from "./land_plots/1/land_plot_block_1563.svg";
import { ReactComponent as LandPlot_1_1564 } from "./land_plots/1/land_plot_block_1564.svg";
import { ReactComponent as LandPlot_1_1565 } from "./land_plots/1/land_plot_block_1565.svg";
import { ReactComponent as LandPlot_1_1566 } from "./land_plots/1/land_plot_block_1566.svg";
import { ReactComponent as LandPlot_1_1567 } from "./land_plots/1/land_plot_block_1567.svg";
import { ReactComponent as LandPlot_1_1568 } from "./land_plots/1/land_plot_block_1568.svg";
import { ReactComponent as LandPlot_1_1569 } from "./land_plots/1/land_plot_block_1569.svg";
import { ReactComponent as LandPlot_1_1570 } from "./land_plots/1/land_plot_block_1570.svg";
import { ReactComponent as LandPlot_1_1571 } from "./land_plots/1/land_plot_block_1571.svg";
import { ReactComponent as LandPlot_1_1572 } from "./land_plots/1/land_plot_block_1572.svg";
import { ReactComponent as LandPlot_1_1573 } from "./land_plots/1/land_plot_block_1573.svg";
import { ReactComponent as LandPlot_1_1574 } from "./land_plots/1/land_plot_block_1574.svg";
import { ReactComponent as LandPlot_1_1575 } from "./land_plots/1/land_plot_block_1575.svg";
import { ReactComponent as LandPlot_1_1576 } from "./land_plots/1/land_plot_block_1576.svg";
import { ReactComponent as LandPlot_1_1577 } from "./land_plots/1/land_plot_block_1577.svg";
import { ReactComponent as LandPlot_1_1578 } from "./land_plots/1/land_plot_block_1578.svg";
import { ReactComponent as LandPlot_1_1579 } from "./land_plots/1/land_plot_block_1579.svg";
import { ReactComponent as LandPlot_1_1580 } from "./land_plots/1/land_plot_block_1580.svg";
import { ReactComponent as LandPlot_1_1581 } from "./land_plots/1/land_plot_block_1581.svg";
import { ReactComponent as LandPlot_1_1582 } from "./land_plots/1/land_plot_block_1582.svg";
import { ReactComponent as LandPlot_1_1583 } from "./land_plots/1/land_plot_block_1583.svg";
import { ReactComponent as LandPlot_1_1584 } from "./land_plots/1/land_plot_block_1584.svg";
import { ReactComponent as LandPlot_1_1585 } from "./land_plots/1/land_plot_block_1585.svg";
import { ReactComponent as LandPlot_1_1586 } from "./land_plots/1/land_plot_block_1586.svg";
import { ReactComponent as LandPlot_1_1587 } from "./land_plots/1/land_plot_block_1587.svg";
import { ReactComponent as LandPlot_1_1588 } from "./land_plots/1/land_plot_block_1588.svg";
import { ReactComponent as LandPlot_1_1589 } from "./land_plots/1/land_plot_block_1589.svg";
import { ReactComponent as LandPlot_1_1590 } from "./land_plots/1/land_plot_block_1590.svg";
import { ReactComponent as LandPlot_1_1591 } from "./land_plots/1/land_plot_block_1591.svg";
import { ReactComponent as LandPlot_1_1592 } from "./land_plots/1/land_plot_block_1592.svg";
import { ReactComponent as LandPlot_1_1593 } from "./land_plots/1/land_plot_block_1593.svg";
import { ReactComponent as LandPlot_1_1594 } from "./land_plots/1/land_plot_block_1594.svg";
import { ReactComponent as LandPlot_1_1595 } from "./land_plots/1/land_plot_block_1595.svg";
import { ReactComponent as LandPlot_1_1596 } from "./land_plots/1/land_plot_block_1596.svg";
import { ReactComponent as LandPlot_1_1597 } from "./land_plots/1/land_plot_block_1597.svg";
import { ReactComponent as LandPlot_1_1598 } from "./land_plots/1/land_plot_block_1598.svg";
import { ReactComponent as LandPlot_1_1599 } from "./land_plots/1/land_plot_block_1599.svg";
import { ReactComponent as LandPlot_1_1600 } from "./land_plots/1/land_plot_block_1600.svg";
import { ReactComponent as LandPlot_1_1601 } from "./land_plots/1/land_plot_block_1601.svg";
import { ReactComponent as LandPlot_1_1602 } from "./land_plots/1/land_plot_block_1602.svg";
import { ReactComponent as LandPlot_1_1603 } from "./land_plots/1/land_plot_block_1603.svg";
import { ReactComponent as LandPlot_1_1604 } from "./land_plots/1/land_plot_block_1604.svg";
import { ReactComponent as LandPlot_1_1605 } from "./land_plots/1/land_plot_block_1605.svg";
import { ReactComponent as LandPlot_1_1606 } from "./land_plots/1/land_plot_block_1606.svg";
import { ReactComponent as LandPlot_1_1607 } from "./land_plots/1/land_plot_block_1607.svg";
import { ReactComponent as LandPlot_1_1608 } from "./land_plots/1/land_plot_block_1608.svg";
import { ReactComponent as LandPlot_1_1609 } from "./land_plots/1/land_plot_block_1609.svg";
import { ReactComponent as LandPlot_1_1610 } from "./land_plots/1/land_plot_block_1610.svg";
import { ReactComponent as LandPlot_1_1611 } from "./land_plots/1/land_plot_block_1611.svg";
import { ReactComponent as LandPlot_1_1612 } from "./land_plots/1/land_plot_block_1612.svg";
import { ReactComponent as LandPlot_1_1613 } from "./land_plots/1/land_plot_block_1613.svg";
import { ReactComponent as LandPlot_1_1614 } from "./land_plots/1/land_plot_block_1614.svg";
import { ReactComponent as LandPlot_1_1615 } from "./land_plots/1/land_plot_block_1615.svg";
import { ReactComponent as LandPlot_1_1616 } from "./land_plots/1/land_plot_block_1616.svg";
import { ReactComponent as LandPlot_1_1617 } from "./land_plots/1/land_plot_block_1617.svg";
import { ReactComponent as LandPlot_1_1618 } from "./land_plots/1/land_plot_block_1618.svg";
import { ReactComponent as LandPlot_1_1619 } from "./land_plots/1/land_plot_block_1619.svg";
import { ReactComponent as LandPlot_1_1620 } from "./land_plots/1/land_plot_block_1620.svg";
import { ReactComponent as LandPlot_1_1621 } from "./land_plots/1/land_plot_block_1621.svg";
import { ReactComponent as LandPlot_1_1622 } from "./land_plots/1/land_plot_block_1622.svg";
import { ReactComponent as LandPlot_1_1623 } from "./land_plots/1/land_plot_block_1623.svg";
import { ReactComponent as LandPlot_1_1624 } from "./land_plots/1/land_plot_block_1624.svg";
import { ReactComponent as LandPlot_1_1625 } from "./land_plots/1/land_plot_block_1625.svg";
import { ReactComponent as LandPlot_1_1626 } from "./land_plots/1/land_plot_block_1626.svg";
import { ReactComponent as LandPlot_1_1627 } from "./land_plots/1/land_plot_block_1627.svg";

//////////////////////////////////////////////////////////////////////////////////stop frame 31
import { ReactComponent as LandPlot_31_1164 } from "./land_plots/31/land_plot_block_1164.svg";
import { ReactComponent as LandPlot_31_1165 } from "./land_plots/31/land_plot_block_1165.svg";
import { ReactComponent as LandPlot_31_1166 } from "./land_plots/31/land_plot_block_1166.svg";
import { ReactComponent as LandPlot_31_1167 } from "./land_plots/31/land_plot_block_1167.svg";
import { ReactComponent as LandPlot_31_1168 } from "./land_plots/31/land_plot_block_1168.svg";
import { ReactComponent as LandPlot_31_1169 } from "./land_plots/31/land_plot_block_1169.svg";
import { ReactComponent as LandPlot_31_1170 } from "./land_plots/31/land_plot_block_1170.svg";
import { ReactComponent as LandPlot_31_1171 } from "./land_plots/31/land_plot_block_1171.svg";
import { ReactComponent as LandPlot_31_1172 } from "./land_plots/31/land_plot_block_1172.svg";
import { ReactComponent as LandPlot_31_1173 } from "./land_plots/31/land_plot_block_1173.svg";
import { ReactComponent as LandPlot_31_1174 } from "./land_plots/31/land_plot_block_1174.svg";
import { ReactComponent as LandPlot_31_1175 } from "./land_plots/31/land_plot_block_1175.svg";
import { ReactComponent as LandPlot_31_1176 } from "./land_plots/31/land_plot_block_1176.svg";
import { ReactComponent as LandPlot_31_1177 } from "./land_plots/31/land_plot_block_1177.svg";
import { ReactComponent as LandPlot_31_1178 } from "./land_plots/31/land_plot_block_1178.svg";
import { ReactComponent as LandPlot_31_1179 } from "./land_plots/31/land_plot_block_1179.svg";
import { ReactComponent as LandPlot_31_1180 } from "./land_plots/31/land_plot_block_1180.svg";
import { ReactComponent as LandPlot_31_1181 } from "./land_plots/31/land_plot_block_1181.svg";
import { ReactComponent as LandPlot_31_1182 } from "./land_plots/31/land_plot_block_1182.svg";
import { ReactComponent as LandPlot_31_1183 } from "./land_plots/31/land_plot_block_1183.svg";
import { ReactComponent as LandPlot_31_1184 } from "./land_plots/31/land_plot_block_1184.svg";
import { ReactComponent as LandPlot_31_1185 } from "./land_plots/31/land_plot_block_1185.svg";
import { ReactComponent as LandPlot_31_1186 } from "./land_plots/31/land_plot_block_1186.svg";
import { ReactComponent as LandPlot_31_1187 } from "./land_plots/31/land_plot_block_1187.svg";
import { ReactComponent as LandPlot_31_1188 } from "./land_plots/31/land_plot_block_1188.svg";
import { ReactComponent as LandPlot_31_1189 } from "./land_plots/31/land_plot_block_1189.svg";
import { ReactComponent as LandPlot_31_1190 } from "./land_plots/31/land_plot_block_1190.svg";
import { ReactComponent as LandPlot_31_1191 } from "./land_plots/31/land_plot_block_1191.svg";
import { ReactComponent as LandPlot_31_1192 } from "./land_plots/31/land_plot_block_1192.svg";
import { ReactComponent as LandPlot_31_1193 } from "./land_plots/31/land_plot_block_1193.svg";
import { ReactComponent as LandPlot_31_1194 } from "./land_plots/31/land_plot_block_1194.svg";
import { ReactComponent as LandPlot_31_1195 } from "./land_plots/31/land_plot_block_1195.svg";
import { ReactComponent as LandPlot_31_1196 } from "./land_plots/31/land_plot_block_1196.svg";
import { ReactComponent as LandPlot_31_1197 } from "./land_plots/31/land_plot_block_1197.svg";
import { ReactComponent as LandPlot_31_1198 } from "./land_plots/31/land_plot_block_1198.svg";
import { ReactComponent as LandPlot_31_1199 } from "./land_plots/31/land_plot_block_1199.svg";
import { ReactComponent as LandPlot_31_1200 } from "./land_plots/31/land_plot_block_1200.svg";
import { ReactComponent as LandPlot_31_1201 } from "./land_plots/31/land_plot_block_1201.svg";
import { ReactComponent as LandPlot_31_1202 } from "./land_plots/31/land_plot_block_1202.svg";
import { ReactComponent as LandPlot_31_1203 } from "./land_plots/31/land_plot_block_1203.svg";
import { ReactComponent as LandPlot_31_1204 } from "./land_plots/31/land_plot_block_1204.svg";
import { ReactComponent as LandPlot_31_1205 } from "./land_plots/31/land_plot_block_1205.svg";
import { ReactComponent as LandPlot_31_1206 } from "./land_plots/31/land_plot_block_1206.svg";
import { ReactComponent as LandPlot_31_1207 } from "./land_plots/31/land_plot_block_1207.svg";
import { ReactComponent as LandPlot_31_1208 } from "./land_plots/31/land_plot_block_1208.svg";
import { ReactComponent as LandPlot_31_1209 } from "./land_plots/31/land_plot_block_1209.svg";
import { ReactComponent as LandPlot_31_1210 } from "./land_plots/31/land_plot_block_1210.svg";
import { ReactComponent as LandPlot_31_1211 } from "./land_plots/31/land_plot_block_1211.svg";
import { ReactComponent as LandPlot_31_1212 } from "./land_plots/31/land_plot_block_1212.svg";
import { ReactComponent as LandPlot_31_1213 } from "./land_plots/31/land_plot_block_1213.svg";
import { ReactComponent as LandPlot_31_1214 } from "./land_plots/31/land_plot_block_1214.svg";
import { ReactComponent as LandPlot_31_1215 } from "./land_plots/31/land_plot_block_1215.svg";
import { ReactComponent as LandPlot_31_1216 } from "./land_plots/31/land_plot_block_1216.svg";
import { ReactComponent as LandPlot_31_1217 } from "./land_plots/31/land_plot_block_1217.svg";
import { ReactComponent as LandPlot_31_1218 } from "./land_plots/31/land_plot_block_1218.svg";
import { ReactComponent as LandPlot_31_1219 } from "./land_plots/31/land_plot_block_1219.svg";
import { ReactComponent as LandPlot_31_1220 } from "./land_plots/31/land_plot_block_1220.svg";
import { ReactComponent as LandPlot_31_1221 } from "./land_plots/31/land_plot_block_1221.svg";
import { ReactComponent as LandPlot_31_1222 } from "./land_plots/31/land_plot_block_1222.svg";
import { ReactComponent as LandPlot_31_1223 } from "./land_plots/31/land_plot_block_1223.svg";
import { ReactComponent as LandPlot_31_1224 } from "./land_plots/31/land_plot_block_1224.svg";
import { ReactComponent as LandPlot_31_1225 } from "./land_plots/31/land_plot_block_1225.svg";
import { ReactComponent as LandPlot_31_1226 } from "./land_plots/31/land_plot_block_1226.svg";
import { ReactComponent as LandPlot_31_1227 } from "./land_plots/31/land_plot_block_1227.svg";
import { ReactComponent as LandPlot_31_1228 } from "./land_plots/31/land_plot_block_1228.svg";
import { ReactComponent as LandPlot_31_1229 } from "./land_plots/31/land_plot_block_1229.svg";
import { ReactComponent as LandPlot_31_1230 } from "./land_plots/31/land_plot_block_1230.svg";
import { ReactComponent as LandPlot_31_1231 } from "./land_plots/31/land_plot_block_1231.svg";
import { ReactComponent as LandPlot_31_1232 } from "./land_plots/31/land_plot_block_1232.svg";
import { ReactComponent as LandPlot_31_1233 } from "./land_plots/31/land_plot_block_1233.svg";
import { ReactComponent as LandPlot_31_1234 } from "./land_plots/31/land_plot_block_1234.svg";
import { ReactComponent as LandPlot_31_1235 } from "./land_plots/31/land_plot_block_1235.svg";
import { ReactComponent as LandPlot_31_1236 } from "./land_plots/31/land_plot_block_1236.svg";
import { ReactComponent as LandPlot_31_1237 } from "./land_plots/31/land_plot_block_1237.svg";
import { ReactComponent as LandPlot_31_1238 } from "./land_plots/31/land_plot_block_1238.svg";
import { ReactComponent as LandPlot_31_1239 } from "./land_plots/31/land_plot_block_1239.svg";
import { ReactComponent as LandPlot_31_1240 } from "./land_plots/31/land_plot_block_1240.svg";
import { ReactComponent as LandPlot_31_1241 } from "./land_plots/31/land_plot_block_1241.svg";
import { ReactComponent as LandPlot_31_1242 } from "./land_plots/31/land_plot_block_1242.svg";
import { ReactComponent as LandPlot_31_1243 } from "./land_plots/31/land_plot_block_1243.svg";
import { ReactComponent as LandPlot_31_1244 } from "./land_plots/31/land_plot_block_1244.svg";
import { ReactComponent as LandPlot_31_1245 } from "./land_plots/31/land_plot_block_1245.svg";
import { ReactComponent as LandPlot_31_1246 } from "./land_plots/31/land_plot_block_1246.svg";
import { ReactComponent as LandPlot_31_1247 } from "./land_plots/31/land_plot_block_1247.svg";
import { ReactComponent as LandPlot_31_1248 } from "./land_plots/31/land_plot_block_1248.svg";
import { ReactComponent as LandPlot_31_1249 } from "./land_plots/31/land_plot_block_1249.svg";
import { ReactComponent as LandPlot_31_1250 } from "./land_plots/31/land_plot_block_1250.svg";
import { ReactComponent as LandPlot_31_1251 } from "./land_plots/31/land_plot_block_1251.svg";
import { ReactComponent as LandPlot_31_1252 } from "./land_plots/31/land_plot_block_1252.svg";
import { ReactComponent as LandPlot_31_1253 } from "./land_plots/31/land_plot_block_1253.svg";
import { ReactComponent as LandPlot_31_1254 } from "./land_plots/31/land_plot_block_1254.svg";
import { ReactComponent as LandPlot_31_1255 } from "./land_plots/31/land_plot_block_1255.svg";
import { ReactComponent as LandPlot_31_1256 } from "./land_plots/31/land_plot_block_1256.svg";
import { ReactComponent as LandPlot_31_1257 } from "./land_plots/31/land_plot_block_1257.svg";
import { ReactComponent as LandPlot_31_1258 } from "./land_plots/31/land_plot_block_1258.svg";
import { ReactComponent as LandPlot_31_1259 } from "./land_plots/31/land_plot_block_1259.svg";
import { ReactComponent as LandPlot_31_1260 } from "./land_plots/31/land_plot_block_1260.svg";
import { ReactComponent as LandPlot_31_1261 } from "./land_plots/31/land_plot_block_1261.svg";
import { ReactComponent as LandPlot_31_1262 } from "./land_plots/31/land_plot_block_1262.svg";
import { ReactComponent as LandPlot_31_1263 } from "./land_plots/31/land_plot_block_1263.svg";
import { ReactComponent as LandPlot_31_1264 } from "./land_plots/31/land_plot_block_1264.svg";
import { ReactComponent as LandPlot_31_1265 } from "./land_plots/31/land_plot_block_1265.svg";
import { ReactComponent as LandPlot_31_1266 } from "./land_plots/31/land_plot_block_1266.svg";
import { ReactComponent as LandPlot_31_1267 } from "./land_plots/31/land_plot_block_1267.svg";
import { ReactComponent as LandPlot_31_1268 } from "./land_plots/31/land_plot_block_1268.svg";
import { ReactComponent as LandPlot_31_1269 } from "./land_plots/31/land_plot_block_1269.svg";
import { ReactComponent as LandPlot_31_1270 } from "./land_plots/31/land_plot_block_1270.svg";
import { ReactComponent as LandPlot_31_1271 } from "./land_plots/31/land_plot_block_1271.svg";
import { ReactComponent as LandPlot_31_1272 } from "./land_plots/31/land_plot_block_1272.svg";
import { ReactComponent as LandPlot_31_1273 } from "./land_plots/31/land_plot_block_1273.svg";
import { ReactComponent as LandPlot_31_1274 } from "./land_plots/31/land_plot_block_1274.svg";
import { ReactComponent as LandPlot_31_1275 } from "./land_plots/31/land_plot_block_1275.svg";
import { ReactComponent as LandPlot_31_1276 } from "./land_plots/31/land_plot_block_1276.svg";
import { ReactComponent as LandPlot_31_1277 } from "./land_plots/31/land_plot_block_1277.svg";
import { ReactComponent as LandPlot_31_1278 } from "./land_plots/31/land_plot_block_1278.svg";
import { ReactComponent as LandPlot_31_1279 } from "./land_plots/31/land_plot_block_1279.svg";
import { ReactComponent as LandPlot_31_1280 } from "./land_plots/31/land_plot_block_1280.svg";
import { ReactComponent as LandPlot_31_1281 } from "./land_plots/31/land_plot_block_1281.svg";
import { ReactComponent as LandPlot_31_1282 } from "./land_plots/31/land_plot_block_1282.svg";
import { ReactComponent as LandPlot_31_1283 } from "./land_plots/31/land_plot_block_1283.svg";
import { ReactComponent as LandPlot_31_1284 } from "./land_plots/31/land_plot_block_1284.svg";
import { ReactComponent as LandPlot_31_1285 } from "./land_plots/31/land_plot_block_1285.svg";
import { ReactComponent as LandPlot_31_1286 } from "./land_plots/31/land_plot_block_1286.svg";
import { ReactComponent as LandPlot_31_1287 } from "./land_plots/31/land_plot_block_1287.svg";
import { ReactComponent as LandPlot_31_1288 } from "./land_plots/31/land_plot_block_1288.svg";
import { ReactComponent as LandPlot_31_1289 } from "./land_plots/31/land_plot_block_1289.svg";
import { ReactComponent as LandPlot_31_1290 } from "./land_plots/31/land_plot_block_1290.svg";
import { ReactComponent as LandPlot_31_1291 } from "./land_plots/31/land_plot_block_1291.svg";
import { ReactComponent as LandPlot_31_1292 } from "./land_plots/31/land_plot_block_1292.svg";
import { ReactComponent as LandPlot_31_1293 } from "./land_plots/31/land_plot_block_1293.svg";
import { ReactComponent as LandPlot_31_1294 } from "./land_plots/31/land_plot_block_1294.svg";
import { ReactComponent as LandPlot_31_1295 } from "./land_plots/31/land_plot_block_1295.svg";
import { ReactComponent as LandPlot_31_1296 } from "./land_plots/31/land_plot_block_1296.svg";
import { ReactComponent as LandPlot_31_1297 } from "./land_plots/31/land_plot_block_1297.svg";
import { ReactComponent as LandPlot_31_1298 } from "./land_plots/31/land_plot_block_1298.svg";
import { ReactComponent as LandPlot_31_1299 } from "./land_plots/31/land_plot_block_1299.svg";
import { ReactComponent as LandPlot_31_1300 } from "./land_plots/31/land_plot_block_1300.svg";
import { ReactComponent as LandPlot_31_1301 } from "./land_plots/31/land_plot_block_1301.svg";
import { ReactComponent as LandPlot_31_1302 } from "./land_plots/31/land_plot_block_1302.svg";
import { ReactComponent as LandPlot_31_1303 } from "./land_plots/31/land_plot_block_1303.svg";
import { ReactComponent as LandPlot_31_1304 } from "./land_plots/31/land_plot_block_1304.svg";
import { ReactComponent as LandPlot_31_1305 } from "./land_plots/31/land_plot_block_1305.svg";
import { ReactComponent as LandPlot_31_1306 } from "./land_plots/31/land_plot_block_1306.svg";
import { ReactComponent as LandPlot_31_1307 } from "./land_plots/31/land_plot_block_1307.svg";
import { ReactComponent as LandPlot_31_1308 } from "./land_plots/31/land_plot_block_1308.svg";
import { ReactComponent as LandPlot_31_1309 } from "./land_plots/31/land_plot_block_1309.svg";
import { ReactComponent as LandPlot_31_1310 } from "./land_plots/31/land_plot_block_1310.svg";
import { ReactComponent as LandPlot_31_1311 } from "./land_plots/31/land_plot_block_1311.svg";
import { ReactComponent as LandPlot_31_1312 } from "./land_plots/31/land_plot_block_1312.svg";
import { ReactComponent as LandPlot_31_1313 } from "./land_plots/31/land_plot_block_1313.svg";
import { ReactComponent as LandPlot_31_1314 } from "./land_plots/31/land_plot_block_1314.svg";
import { ReactComponent as LandPlot_31_1315 } from "./land_plots/31/land_plot_block_1315.svg";
import { ReactComponent as LandPlot_31_1316 } from "./land_plots/31/land_plot_block_1316.svg";
import { ReactComponent as LandPlot_31_1317 } from "./land_plots/31/land_plot_block_1317.svg";
import { ReactComponent as LandPlot_31_1318 } from "./land_plots/31/land_plot_block_1318.svg";
import { ReactComponent as LandPlot_31_1319 } from "./land_plots/31/land_plot_block_1319.svg";
import { ReactComponent as LandPlot_31_1320 } from "./land_plots/31/land_plot_block_1320.svg";
import { ReactComponent as LandPlot_31_1321 } from "./land_plots/31/land_plot_block_1321.svg";
import { ReactComponent as LandPlot_31_1322 } from "./land_plots/31/land_plot_block_1322.svg";
import { ReactComponent as LandPlot_31_1323 } from "./land_plots/31/land_plot_block_1323.svg";
import { ReactComponent as LandPlot_31_1324 } from "./land_plots/31/land_plot_block_1324.svg";
import { ReactComponent as LandPlot_31_1325 } from "./land_plots/31/land_plot_block_1325.svg";
import { ReactComponent as LandPlot_31_1326 } from "./land_plots/31/land_plot_block_1326.svg";
import { ReactComponent as LandPlot_31_1327 } from "./land_plots/31/land_plot_block_1327.svg";
import { ReactComponent as LandPlot_31_1328 } from "./land_plots/31/land_plot_block_1328.svg";
import { ReactComponent as LandPlot_31_1329 } from "./land_plots/31/land_plot_block_1329.svg";
import { ReactComponent as LandPlot_31_1330 } from "./land_plots/31/land_plot_block_1330.svg";
import { ReactComponent as LandPlot_31_1331 } from "./land_plots/31/land_plot_block_1331.svg";
import { ReactComponent as LandPlot_31_1332 } from "./land_plots/31/land_plot_block_1332.svg";
import { ReactComponent as LandPlot_31_1333 } from "./land_plots/31/land_plot_block_1333.svg";
import { ReactComponent as LandPlot_31_1334 } from "./land_plots/31/land_plot_block_1334.svg";
import { ReactComponent as LandPlot_31_1335 } from "./land_plots/31/land_plot_block_1335.svg";
import { ReactComponent as LandPlot_31_1336 } from "./land_plots/31/land_plot_block_1336.svg";
import { ReactComponent as LandPlot_31_1337 } from "./land_plots/31/land_plot_block_1337.svg";
import { ReactComponent as LandPlot_31_1338 } from "./land_plots/31/land_plot_block_1338.svg";
import { ReactComponent as LandPlot_31_1339 } from "./land_plots/31/land_plot_block_1339.svg";
import { ReactComponent as LandPlot_31_1340 } from "./land_plots/31/land_plot_block_1340.svg";
import { ReactComponent as LandPlot_31_1341 } from "./land_plots/31/land_plot_block_1341.svg";
import { ReactComponent as LandPlot_31_1342 } from "./land_plots/31/land_plot_block_1342.svg";
import { ReactComponent as LandPlot_31_1343 } from "./land_plots/31/land_plot_block_1343.svg";
import { ReactComponent as LandPlot_31_1344 } from "./land_plots/31/land_plot_block_1344.svg";
import { ReactComponent as LandPlot_31_1345 } from "./land_plots/31/land_plot_block_1345.svg";
import { ReactComponent as LandPlot_31_1346 } from "./land_plots/31/land_plot_block_1346.svg";
import { ReactComponent as LandPlot_31_1347 } from "./land_plots/31/land_plot_block_1347.svg";
import { ReactComponent as LandPlot_31_1348 } from "./land_plots/31/land_plot_block_1348.svg";
import { ReactComponent as LandPlot_31_1349 } from "./land_plots/31/land_plot_block_1349.svg";
import { ReactComponent as LandPlot_31_1350 } from "./land_plots/31/land_plot_block_1350.svg";
import { ReactComponent as LandPlot_31_1351 } from "./land_plots/31/land_plot_block_1351.svg";
import { ReactComponent as LandPlot_31_1352 } from "./land_plots/31/land_plot_block_1352.svg";
import { ReactComponent as LandPlot_31_1353 } from "./land_plots/31/land_plot_block_1353.svg";
import { ReactComponent as LandPlot_31_1354 } from "./land_plots/31/land_plot_block_1354.svg";
import { ReactComponent as LandPlot_31_1355 } from "./land_plots/31/land_plot_block_1355.svg";
import { ReactComponent as LandPlot_31_1356 } from "./land_plots/31/land_plot_block_1356.svg";
import { ReactComponent as LandPlot_31_1357 } from "./land_plots/31/land_plot_block_1357.svg";
import { ReactComponent as LandPlot_31_1358 } from "./land_plots/31/land_plot_block_1358.svg";
import { ReactComponent as LandPlot_31_1359 } from "./land_plots/31/land_plot_block_1359.svg";
import { ReactComponent as LandPlot_31_1360 } from "./land_plots/31/land_plot_block_1360.svg";
import { ReactComponent as LandPlot_31_1361 } from "./land_plots/31/land_plot_block_1361.svg";
import { ReactComponent as LandPlot_31_1362 } from "./land_plots/31/land_plot_block_1362.svg";
import { ReactComponent as LandPlot_31_1363 } from "./land_plots/31/land_plot_block_1363.svg";
import { ReactComponent as LandPlot_31_1364 } from "./land_plots/31/land_plot_block_1364.svg";
import { ReactComponent as LandPlot_31_1365 } from "./land_plots/31/land_plot_block_1365.svg";
import { ReactComponent as LandPlot_31_1366 } from "./land_plots/31/land_plot_block_1366.svg";
import { ReactComponent as LandPlot_31_1367 } from "./land_plots/31/land_plot_block_1367.svg";
import { ReactComponent as LandPlot_31_1368 } from "./land_plots/31/land_plot_block_1368.svg";
import { ReactComponent as LandPlot_31_1369 } from "./land_plots/31/land_plot_block_1369.svg";
import { ReactComponent as LandPlot_31_1370 } from "./land_plots/31/land_plot_block_1370.svg";
import { ReactComponent as LandPlot_31_1371 } from "./land_plots/31/land_plot_block_1371.svg";
import { ReactComponent as LandPlot_31_1372 } from "./land_plots/31/land_plot_block_1372.svg";
import { ReactComponent as LandPlot_31_1373 } from "./land_plots/31/land_plot_block_1373.svg";
import { ReactComponent as LandPlot_31_1374 } from "./land_plots/31/land_plot_block_1374.svg";
import { ReactComponent as LandPlot_31_1375 } from "./land_plots/31/land_plot_block_1375.svg";
import { ReactComponent as LandPlot_31_1376 } from "./land_plots/31/land_plot_block_1376.svg";
import { ReactComponent as LandPlot_31_1377 } from "./land_plots/31/land_plot_block_1377.svg";
import { ReactComponent as LandPlot_31_1378 } from "./land_plots/31/land_plot_block_1378.svg";
import { ReactComponent as LandPlot_31_1379 } from "./land_plots/31/land_plot_block_1379.svg";
import { ReactComponent as LandPlot_31_1380 } from "./land_plots/31/land_plot_block_1380.svg";
import { ReactComponent as LandPlot_31_1381 } from "./land_plots/31/land_plot_block_1381.svg";
import { ReactComponent as LandPlot_31_1382 } from "./land_plots/31/land_plot_block_1382.svg";
import { ReactComponent as LandPlot_31_1383 } from "./land_plots/31/land_plot_block_1383.svg";
import { ReactComponent as LandPlot_31_1384 } from "./land_plots/31/land_plot_block_1384.svg";
import { ReactComponent as LandPlot_31_1385 } from "./land_plots/31/land_plot_block_1385.svg";
import { ReactComponent as LandPlot_31_1386 } from "./land_plots/31/land_plot_block_1386.svg";
import { ReactComponent as LandPlot_31_1387 } from "./land_plots/31/land_plot_block_1387.svg";
import { ReactComponent as LandPlot_31_1388 } from "./land_plots/31/land_plot_block_1388.svg";
import { ReactComponent as LandPlot_31_1389 } from "./land_plots/31/land_plot_block_1389.svg";
import { ReactComponent as LandPlot_31_1390 } from "./land_plots/31/land_plot_block_1390.svg";
import { ReactComponent as LandPlot_31_1391 } from "./land_plots/31/land_plot_block_1391.svg";
import { ReactComponent as LandPlot_31_1392 } from "./land_plots/31/land_plot_block_1392.svg";
import { ReactComponent as LandPlot_31_1393 } from "./land_plots/31/land_plot_block_1393.svg";
import { ReactComponent as LandPlot_31_1394 } from "./land_plots/31/land_plot_block_1394.svg";
import { ReactComponent as LandPlot_31_1395 } from "./land_plots/31/land_plot_block_1395.svg";
import { ReactComponent as LandPlot_31_1396 } from "./land_plots/31/land_plot_block_1396.svg";
import { ReactComponent as LandPlot_31_1397 } from "./land_plots/31/land_plot_block_1397.svg";
import { ReactComponent as LandPlot_31_1398 } from "./land_plots/31/land_plot_block_1398.svg";
import { ReactComponent as LandPlot_31_1399 } from "./land_plots/31/land_plot_block_1399.svg";
import { ReactComponent as LandPlot_31_1400 } from "./land_plots/31/land_plot_block_1400.svg";
import { ReactComponent as LandPlot_31_1401 } from "./land_plots/31/land_plot_block_1401.svg";
import { ReactComponent as LandPlot_31_1402 } from "./land_plots/31/land_plot_block_1402.svg";
import { ReactComponent as LandPlot_31_1403 } from "./land_plots/31/land_plot_block_1403.svg";
import { ReactComponent as LandPlot_31_1404 } from "./land_plots/31/land_plot_block_1404.svg";
import { ReactComponent as LandPlot_31_1405 } from "./land_plots/31/land_plot_block_1405.svg";
import { ReactComponent as LandPlot_31_1406 } from "./land_plots/31/land_plot_block_1406.svg";
import { ReactComponent as LandPlot_31_1407 } from "./land_plots/31/land_plot_block_1407.svg";
import { ReactComponent as LandPlot_31_1408 } from "./land_plots/31/land_plot_block_1408.svg";
import { ReactComponent as LandPlot_31_1409 } from "./land_plots/31/land_plot_block_1409.svg";
import { ReactComponent as LandPlot_31_1410 } from "./land_plots/31/land_plot_block_1410.svg";
import { ReactComponent as LandPlot_31_1411 } from "./land_plots/31/land_plot_block_1411.svg";
import { ReactComponent as LandPlot_31_1412 } from "./land_plots/31/land_plot_block_1412.svg";
import { ReactComponent as LandPlot_31_1413 } from "./land_plots/31/land_plot_block_1413.svg";
import { ReactComponent as LandPlot_31_1414 } from "./land_plots/31/land_plot_block_1414.svg";
import { ReactComponent as LandPlot_31_1415 } from "./land_plots/31/land_plot_block_1415.svg";
import { ReactComponent as LandPlot_31_1416 } from "./land_plots/31/land_plot_block_1416.svg";
import { ReactComponent as LandPlot_31_1417 } from "./land_plots/31/land_plot_block_1417.svg";
import { ReactComponent as LandPlot_31_1418 } from "./land_plots/31/land_plot_block_1418.svg";
import { ReactComponent as LandPlot_31_1419 } from "./land_plots/31/land_plot_block_1419.svg";
import { ReactComponent as LandPlot_31_1420 } from "./land_plots/31/land_plot_block_1420.svg";
import { ReactComponent as LandPlot_31_1421 } from "./land_plots/31/land_plot_block_1421.svg";
import { ReactComponent as LandPlot_31_1422 } from "./land_plots/31/land_plot_block_1422.svg";
import { ReactComponent as LandPlot_31_1423 } from "./land_plots/31/land_plot_block_1423.svg";
import { ReactComponent as LandPlot_31_1424 } from "./land_plots/31/land_plot_block_1424.svg";
import { ReactComponent as LandPlot_31_1425 } from "./land_plots/31/land_plot_block_1425.svg";
import { ReactComponent as LandPlot_31_1426 } from "./land_plots/31/land_plot_block_1426.svg";
import { ReactComponent as LandPlot_31_1427 } from "./land_plots/31/land_plot_block_1427.svg";
import { ReactComponent as LandPlot_31_1428 } from "./land_plots/31/land_plot_block_1428.svg";
import { ReactComponent as LandPlot_31_1429 } from "./land_plots/31/land_plot_block_1429.svg";
import { ReactComponent as LandPlot_31_1430 } from "./land_plots/31/land_plot_block_1430.svg";
import { ReactComponent as LandPlot_31_1431 } from "./land_plots/31/land_plot_block_1431.svg";
import { ReactComponent as LandPlot_31_1432 } from "./land_plots/31/land_plot_block_1432.svg";
import { ReactComponent as LandPlot_31_1433 } from "./land_plots/31/land_plot_block_1433.svg";
import { ReactComponent as LandPlot_31_1434 } from "./land_plots/31/land_plot_block_1434.svg";
import { ReactComponent as LandPlot_31_1435 } from "./land_plots/31/land_plot_block_1435.svg";
import { ReactComponent as LandPlot_31_1436 } from "./land_plots/31/land_plot_block_1436.svg";
import { ReactComponent as LandPlot_31_1437 } from "./land_plots/31/land_plot_block_1437.svg";
import { ReactComponent as LandPlot_31_1438 } from "./land_plots/31/land_plot_block_1438.svg";
import { ReactComponent as LandPlot_31_1439 } from "./land_plots/31/land_plot_block_1439.svg";
import { ReactComponent as LandPlot_31_1440 } from "./land_plots/31/land_plot_block_1440.svg";
import { ReactComponent as LandPlot_31_1441 } from "./land_plots/31/land_plot_block_1441.svg";
import { ReactComponent as LandPlot_31_1442 } from "./land_plots/31/land_plot_block_1442.svg";
import { ReactComponent as LandPlot_31_1443 } from "./land_plots/31/land_plot_block_1443.svg";
import { ReactComponent as LandPlot_31_1444 } from "./land_plots/31/land_plot_block_1444.svg";
import { ReactComponent as LandPlot_31_1445 } from "./land_plots/31/land_plot_block_1445.svg";
import { ReactComponent as LandPlot_31_1446 } from "./land_plots/31/land_plot_block_1446.svg";
import { ReactComponent as LandPlot_31_1447 } from "./land_plots/31/land_plot_block_1447.svg";
import { ReactComponent as LandPlot_31_1448 } from "./land_plots/31/land_plot_block_1448.svg";
import { ReactComponent as LandPlot_31_1449 } from "./land_plots/31/land_plot_block_1449.svg";
import { ReactComponent as LandPlot_31_1450 } from "./land_plots/31/land_plot_block_1450.svg";
import { ReactComponent as LandPlot_31_1451 } from "./land_plots/31/land_plot_block_1451.svg";
import { ReactComponent as LandPlot_31_1452 } from "./land_plots/31/land_plot_block_1452.svg";
import { ReactComponent as LandPlot_31_1453 } from "./land_plots/31/land_plot_block_1453.svg";
import { ReactComponent as LandPlot_31_1454 } from "./land_plots/31/land_plot_block_1454.svg";
import { ReactComponent as LandPlot_31_1455 } from "./land_plots/31/land_plot_block_1455.svg";
import { ReactComponent as LandPlot_31_1456 } from "./land_plots/31/land_plot_block_1456.svg";
import { ReactComponent as LandPlot_31_1457 } from "./land_plots/31/land_plot_block_1457.svg";
import { ReactComponent as LandPlot_31_1458 } from "./land_plots/31/land_plot_block_1458.svg";
import { ReactComponent as LandPlot_31_1459 } from "./land_plots/31/land_plot_block_1459.svg";
import { ReactComponent as LandPlot_31_1460 } from "./land_plots/31/land_plot_block_1460.svg";
import { ReactComponent as LandPlot_31_1461 } from "./land_plots/31/land_plot_block_1461.svg";
import { ReactComponent as LandPlot_31_1462 } from "./land_plots/31/land_plot_block_1462.svg";
import { ReactComponent as LandPlot_31_1463 } from "./land_plots/31/land_plot_block_1463.svg";
import { ReactComponent as LandPlot_31_1464 } from "./land_plots/31/land_plot_block_1464.svg";
import { ReactComponent as LandPlot_31_1465 } from "./land_plots/31/land_plot_block_1465.svg";
import { ReactComponent as LandPlot_31_1466 } from "./land_plots/31/land_plot_block_1466.svg";
import { ReactComponent as LandPlot_31_1467 } from "./land_plots/31/land_plot_block_1467.svg";
import { ReactComponent as LandPlot_31_1468 } from "./land_plots/31/land_plot_block_1468.svg";
import { ReactComponent as LandPlot_31_1469 } from "./land_plots/31/land_plot_block_1469.svg";
import { ReactComponent as LandPlot_31_1470 } from "./land_plots/31/land_plot_block_1470.svg";
import { ReactComponent as LandPlot_31_1471 } from "./land_plots/31/land_plot_block_1471.svg";
import { ReactComponent as LandPlot_31_1472 } from "./land_plots/31/land_plot_block_1472.svg";
import { ReactComponent as LandPlot_31_1473 } from "./land_plots/31/land_plot_block_1473.svg";
import { ReactComponent as LandPlot_31_1474 } from "./land_plots/31/land_plot_block_1474.svg";
import { ReactComponent as LandPlot_31_1475 } from "./land_plots/31/land_plot_block_1475.svg";
import { ReactComponent as LandPlot_31_1476 } from "./land_plots/31/land_plot_block_1476.svg";
import { ReactComponent as LandPlot_31_1477 } from "./land_plots/31/land_plot_block_1477.svg";
import { ReactComponent as LandPlot_31_1478 } from "./land_plots/31/land_plot_block_1478.svg";
import { ReactComponent as LandPlot_31_1479 } from "./land_plots/31/land_plot_block_1479.svg";
import { ReactComponent as LandPlot_31_1480 } from "./land_plots/31/land_plot_block_1480.svg";
import { ReactComponent as LandPlot_31_1481 } from "./land_plots/31/land_plot_block_1481.svg";
import { ReactComponent as LandPlot_31_1482 } from "./land_plots/31/land_plot_block_1482.svg";
import { ReactComponent as LandPlot_31_1483 } from "./land_plots/31/land_plot_block_1483.svg";
import { ReactComponent as LandPlot_31_1484 } from "./land_plots/31/land_plot_block_1484.svg";
import { ReactComponent as LandPlot_31_1485 } from "./land_plots/31/land_plot_block_1485.svg";
import { ReactComponent as LandPlot_31_1486 } from "./land_plots/31/land_plot_block_1486.svg";
import { ReactComponent as LandPlot_31_1487 } from "./land_plots/31/land_plot_block_1487.svg";
import { ReactComponent as LandPlot_31_1488 } from "./land_plots/31/land_plot_block_1488.svg";
import { ReactComponent as LandPlot_31_1489 } from "./land_plots/31/land_plot_block_1489.svg";
import { ReactComponent as LandPlot_31_1490 } from "./land_plots/31/land_plot_block_1490.svg";
import { ReactComponent as LandPlot_31_1491 } from "./land_plots/31/land_plot_block_1491.svg";
import { ReactComponent as LandPlot_31_1492 } from "./land_plots/31/land_plot_block_1492.svg";
import { ReactComponent as LandPlot_31_1493 } from "./land_plots/31/land_plot_block_1493.svg";
import { ReactComponent as LandPlot_31_1494 } from "./land_plots/31/land_plot_block_1494.svg";
import { ReactComponent as LandPlot_31_1495 } from "./land_plots/31/land_plot_block_1495.svg";
import { ReactComponent as LandPlot_31_1496 } from "./land_plots/31/land_plot_block_1496.svg";
import { ReactComponent as LandPlot_31_1497 } from "./land_plots/31/land_plot_block_1497.svg";
import { ReactComponent as LandPlot_31_1498 } from "./land_plots/31/land_plot_block_1498.svg";
import { ReactComponent as LandPlot_31_1499 } from "./land_plots/31/land_plot_block_1499.svg";
import { ReactComponent as LandPlot_31_1500 } from "./land_plots/31/land_plot_block_1500.svg";
import { ReactComponent as LandPlot_31_1501 } from "./land_plots/31/land_plot_block_1501.svg";
import { ReactComponent as LandPlot_31_1502 } from "./land_plots/31/land_plot_block_1502.svg";
import { ReactComponent as LandPlot_31_1503 } from "./land_plots/31/land_plot_block_1503.svg";
import { ReactComponent as LandPlot_31_1504 } from "./land_plots/31/land_plot_block_1504.svg";
import { ReactComponent as LandPlot_31_1505 } from "./land_plots/31/land_plot_block_1505.svg";
import { ReactComponent as LandPlot_31_1506 } from "./land_plots/31/land_plot_block_1506.svg";
import { ReactComponent as LandPlot_31_1507 } from "./land_plots/31/land_plot_block_1507.svg";
import { ReactComponent as LandPlot_31_1508 } from "./land_plots/31/land_plot_block_1508.svg";
import { ReactComponent as LandPlot_31_1509 } from "./land_plots/31/land_plot_block_1509.svg";
import { ReactComponent as LandPlot_31_1510 } from "./land_plots/31/land_plot_block_1510.svg";
import { ReactComponent as LandPlot_31_1511 } from "./land_plots/31/land_plot_block_1511.svg";
import { ReactComponent as LandPlot_31_1512 } from "./land_plots/31/land_plot_block_1512.svg";
import { ReactComponent as LandPlot_31_1513 } from "./land_plots/31/land_plot_block_1513.svg";
import { ReactComponent as LandPlot_31_1514 } from "./land_plots/31/land_plot_block_1514.svg";
import { ReactComponent as LandPlot_31_1515 } from "./land_plots/31/land_plot_block_1515.svg";
import { ReactComponent as LandPlot_31_1516 } from "./land_plots/31/land_plot_block_1516.svg";
import { ReactComponent as LandPlot_31_1517 } from "./land_plots/31/land_plot_block_1517.svg";
import { ReactComponent as LandPlot_31_1518 } from "./land_plots/31/land_plot_block_1518.svg";
import { ReactComponent as LandPlot_31_1519 } from "./land_plots/31/land_plot_block_1519.svg";
import { ReactComponent as LandPlot_31_1520 } from "./land_plots/31/land_plot_block_1520.svg";
import { ReactComponent as LandPlot_31_1521 } from "./land_plots/31/land_plot_block_1521.svg";
import { ReactComponent as LandPlot_31_1522 } from "./land_plots/31/land_plot_block_1522.svg";
import { ReactComponent as LandPlot_31_1523 } from "./land_plots/31/land_plot_block_1523.svg";
import { ReactComponent as LandPlot_31_1524 } from "./land_plots/31/land_plot_block_1524.svg";
import { ReactComponent as LandPlot_31_1525 } from "./land_plots/31/land_plot_block_1525.svg";
import { ReactComponent as LandPlot_31_1526 } from "./land_plots/31/land_plot_block_1526.svg";
import { ReactComponent as LandPlot_31_1527 } from "./land_plots/31/land_plot_block_1527.svg";
import { ReactComponent as LandPlot_31_1528 } from "./land_plots/31/land_plot_block_1528.svg";
import { ReactComponent as LandPlot_31_1529 } from "./land_plots/31/land_plot_block_1529.svg";
import { ReactComponent as LandPlot_31_1530 } from "./land_plots/31/land_plot_block_1530.svg";
import { ReactComponent as LandPlot_31_1531 } from "./land_plots/31/land_plot_block_1531.svg";
import { ReactComponent as LandPlot_31_1532 } from "./land_plots/31/land_plot_block_1532.svg";
import { ReactComponent as LandPlot_31_1533 } from "./land_plots/31/land_plot_block_1533.svg";
import { ReactComponent as LandPlot_31_1534 } from "./land_plots/31/land_plot_block_1534.svg";
import { ReactComponent as LandPlot_31_1535 } from "./land_plots/31/land_plot_block_1535.svg";
import { ReactComponent as LandPlot_31_1536 } from "./land_plots/31/land_plot_block_1536.svg";
import { ReactComponent as LandPlot_31_1537 } from "./land_plots/31/land_plot_block_1537.svg";
import { ReactComponent as LandPlot_31_1538 } from "./land_plots/31/land_plot_block_1538.svg";
import { ReactComponent as LandPlot_31_1539 } from "./land_plots/31/land_plot_block_1539.svg";
import { ReactComponent as LandPlot_31_1540 } from "./land_plots/31/land_plot_block_1540.svg";
import { ReactComponent as LandPlot_31_1541 } from "./land_plots/31/land_plot_block_1541.svg";
import { ReactComponent as LandPlot_31_1542 } from "./land_plots/31/land_plot_block_1542.svg";
import { ReactComponent as LandPlot_31_1543 } from "./land_plots/31/land_plot_block_1543.svg";
import { ReactComponent as LandPlot_31_1544 } from "./land_plots/31/land_plot_block_1544.svg";
import { ReactComponent as LandPlot_31_1545 } from "./land_plots/31/land_plot_block_1545.svg";
import { ReactComponent as LandPlot_31_1546 } from "./land_plots/31/land_plot_block_1546.svg";
import { ReactComponent as LandPlot_31_1547 } from "./land_plots/31/land_plot_block_1547.svg";
import { ReactComponent as LandPlot_31_1548 } from "./land_plots/31/land_plot_block_1548.svg";
import { ReactComponent as LandPlot_31_1549 } from "./land_plots/31/land_plot_block_1549.svg";
import { ReactComponent as LandPlot_31_1550 } from "./land_plots/31/land_plot_block_1550.svg";
import { ReactComponent as LandPlot_31_1551 } from "./land_plots/31/land_plot_block_1551.svg";
import { ReactComponent as LandPlot_31_1552 } from "./land_plots/31/land_plot_block_1552.svg";
import { ReactComponent as LandPlot_31_1553 } from "./land_plots/31/land_plot_block_1553.svg";
import { ReactComponent as LandPlot_31_1554 } from "./land_plots/31/land_plot_block_1554.svg";
import { ReactComponent as LandPlot_31_1555 } from "./land_plots/31/land_plot_block_1555.svg";
import { ReactComponent as LandPlot_31_1556 } from "./land_plots/31/land_plot_block_1556.svg";
import { ReactComponent as LandPlot_31_1557 } from "./land_plots/31/land_plot_block_1557.svg";
import { ReactComponent as LandPlot_31_1558 } from "./land_plots/31/land_plot_block_1558.svg";
import { ReactComponent as LandPlot_31_1559 } from "./land_plots/31/land_plot_block_1559.svg";
import { ReactComponent as LandPlot_31_1560 } from "./land_plots/31/land_plot_block_1560.svg";
import { ReactComponent as LandPlot_31_1561 } from "./land_plots/31/land_plot_block_1561.svg";
import { ReactComponent as LandPlot_31_1562 } from "./land_plots/31/land_plot_block_1562.svg";
import { ReactComponent as LandPlot_31_1563 } from "./land_plots/31/land_plot_block_1563.svg";
import { ReactComponent as LandPlot_31_1564 } from "./land_plots/31/land_plot_block_1564.svg";
import { ReactComponent as LandPlot_31_1565 } from "./land_plots/31/land_plot_block_1565.svg";
import { ReactComponent as LandPlot_31_1566 } from "./land_plots/31/land_plot_block_1566.svg";
import { ReactComponent as LandPlot_31_1567 } from "./land_plots/31/land_plot_block_1567.svg";
import { ReactComponent as LandPlot_31_1568 } from "./land_plots/31/land_plot_block_1568.svg";
import { ReactComponent as LandPlot_31_1569 } from "./land_plots/31/land_plot_block_1569.svg";
import { ReactComponent as LandPlot_31_1570 } from "./land_plots/31/land_plot_block_1570.svg";
import { ReactComponent as LandPlot_31_1571 } from "./land_plots/31/land_plot_block_1571.svg";
import { ReactComponent as LandPlot_31_1572 } from "./land_plots/31/land_plot_block_1572.svg";
import { ReactComponent as LandPlot_31_1573 } from "./land_plots/31/land_plot_block_1573.svg";
import { ReactComponent as LandPlot_31_1574 } from "./land_plots/31/land_plot_block_1574.svg";
import { ReactComponent as LandPlot_31_1575 } from "./land_plots/31/land_plot_block_1575.svg";
import { ReactComponent as LandPlot_31_1576 } from "./land_plots/31/land_plot_block_1576.svg";
import { ReactComponent as LandPlot_31_1577 } from "./land_plots/31/land_plot_block_1577.svg";
import { ReactComponent as LandPlot_31_1578 } from "./land_plots/31/land_plot_block_1578.svg";
import { ReactComponent as LandPlot_31_1579 } from "./land_plots/31/land_plot_block_1579.svg";
import { ReactComponent as LandPlot_31_1580 } from "./land_plots/31/land_plot_block_1580.svg";
import { ReactComponent as LandPlot_31_1581 } from "./land_plots/31/land_plot_block_1581.svg";
import { ReactComponent as LandPlot_31_1582 } from "./land_plots/31/land_plot_block_1582.svg";
import { ReactComponent as LandPlot_31_1583 } from "./land_plots/31/land_plot_block_1583.svg";
import { ReactComponent as LandPlot_31_1584 } from "./land_plots/31/land_plot_block_1584.svg";
import { ReactComponent as LandPlot_31_1585 } from "./land_plots/31/land_plot_block_1585.svg";
import { ReactComponent as LandPlot_31_1586 } from "./land_plots/31/land_plot_block_1586.svg";
import { ReactComponent as LandPlot_31_1587 } from "./land_plots/31/land_plot_block_1587.svg";
import { ReactComponent as LandPlot_31_1588 } from "./land_plots/31/land_plot_block_1588.svg";
import { ReactComponent as LandPlot_31_1589 } from "./land_plots/31/land_plot_block_1589.svg";
import { ReactComponent as LandPlot_31_1590 } from "./land_plots/31/land_plot_block_1590.svg";
import { ReactComponent as LandPlot_31_1591 } from "./land_plots/31/land_plot_block_1591.svg";
import { ReactComponent as LandPlot_31_1592 } from "./land_plots/31/land_plot_block_1592.svg";
import { ReactComponent as LandPlot_31_1593 } from "./land_plots/31/land_plot_block_1593.svg";
import { ReactComponent as LandPlot_31_1594 } from "./land_plots/31/land_plot_block_1594.svg";
import { ReactComponent as LandPlot_31_1595 } from "./land_plots/31/land_plot_block_1595.svg";
import { ReactComponent as LandPlot_31_1596 } from "./land_plots/31/land_plot_block_1596.svg";
import { ReactComponent as LandPlot_31_1597 } from "./land_plots/31/land_plot_block_1597.svg";
import { ReactComponent as LandPlot_31_1598 } from "./land_plots/31/land_plot_block_1598.svg";
import { ReactComponent as LandPlot_31_1599 } from "./land_plots/31/land_plot_block_1599.svg";
import { ReactComponent as LandPlot_31_1600 } from "./land_plots/31/land_plot_block_1600.svg";
import { ReactComponent as LandPlot_31_1601 } from "./land_plots/31/land_plot_block_1601.svg";
import { ReactComponent as LandPlot_31_1602 } from "./land_plots/31/land_plot_block_1602.svg";
import { ReactComponent as LandPlot_31_1603 } from "./land_plots/31/land_plot_block_1603.svg";
import { ReactComponent as LandPlot_31_1604 } from "./land_plots/31/land_plot_block_1604.svg";
import { ReactComponent as LandPlot_31_1605 } from "./land_plots/31/land_plot_block_1605.svg";
import { ReactComponent as LandPlot_31_1606 } from "./land_plots/31/land_plot_block_1606.svg";
import { ReactComponent as LandPlot_31_1607 } from "./land_plots/31/land_plot_block_1607.svg";
import { ReactComponent as LandPlot_31_1608 } from "./land_plots/31/land_plot_block_1608.svg";
import { ReactComponent as LandPlot_31_1609 } from "./land_plots/31/land_plot_block_1609.svg";
import { ReactComponent as LandPlot_31_1610 } from "./land_plots/31/land_plot_block_1610.svg";
import { ReactComponent as LandPlot_31_1611 } from "./land_plots/31/land_plot_block_1611.svg";
import { ReactComponent as LandPlot_31_1612 } from "./land_plots/31/land_plot_block_1612.svg";
import { ReactComponent as LandPlot_31_1613 } from "./land_plots/31/land_plot_block_1613.svg";
import { ReactComponent as LandPlot_31_1614 } from "./land_plots/31/land_plot_block_1614.svg";
import { ReactComponent as LandPlot_31_1615 } from "./land_plots/31/land_plot_block_1615.svg";
import { ReactComponent as LandPlot_31_1616 } from "./land_plots/31/land_plot_block_1616.svg";
import { ReactComponent as LandPlot_31_1617 } from "./land_plots/31/land_plot_block_1617.svg";
import { ReactComponent as LandPlot_31_1618 } from "./land_plots/31/land_plot_block_1618.svg";
import { ReactComponent as LandPlot_31_1619 } from "./land_plots/31/land_plot_block_1619.svg";
import { ReactComponent as LandPlot_31_1620 } from "./land_plots/31/land_plot_block_1620.svg";
import { ReactComponent as LandPlot_31_1621 } from "./land_plots/31/land_plot_block_1621.svg";
import { ReactComponent as LandPlot_31_1622 } from "./land_plots/31/land_plot_block_1622.svg";
import { ReactComponent as LandPlot_31_1623 } from "./land_plots/31/land_plot_block_1623.svg";
import { ReactComponent as LandPlot_31_1624 } from "./land_plots/31/land_plot_block_1624.svg";
import { ReactComponent as LandPlot_31_1625 } from "./land_plots/31/land_plot_block_1625.svg";
import { ReactComponent as LandPlot_31_1626 } from "./land_plots/31/land_plot_block_1626.svg";
import { ReactComponent as LandPlot_31_1627 } from "./land_plots/31/land_plot_block_1627.svg";

//////////////////////////////////////////////////////////////////////////////////stop frame 61
import { ReactComponent as LandPlot_61_1164 } from "./land_plots/61/land_plot_block_1164.svg";
import { ReactComponent as LandPlot_61_1165 } from "./land_plots/61/land_plot_block_1165.svg";
import { ReactComponent as LandPlot_61_1166 } from "./land_plots/61/land_plot_block_1166.svg";
import { ReactComponent as LandPlot_61_1167 } from "./land_plots/61/land_plot_block_1167.svg";
import { ReactComponent as LandPlot_61_1168 } from "./land_plots/61/land_plot_block_1168.svg";
import { ReactComponent as LandPlot_61_1169 } from "./land_plots/61/land_plot_block_1169.svg";
import { ReactComponent as LandPlot_61_1170 } from "./land_plots/61/land_plot_block_1170.svg";
import { ReactComponent as LandPlot_61_1171 } from "./land_plots/61/land_plot_block_1171.svg";
import { ReactComponent as LandPlot_61_1172 } from "./land_plots/61/land_plot_block_1172.svg";
import { ReactComponent as LandPlot_61_1173 } from "./land_plots/61/land_plot_block_1173.svg";
import { ReactComponent as LandPlot_61_1174 } from "./land_plots/61/land_plot_block_1174.svg";
import { ReactComponent as LandPlot_61_1175 } from "./land_plots/61/land_plot_block_1175.svg";
import { ReactComponent as LandPlot_61_1176 } from "./land_plots/61/land_plot_block_1176.svg";
import { ReactComponent as LandPlot_61_1177 } from "./land_plots/61/land_plot_block_1177.svg";
import { ReactComponent as LandPlot_61_1178 } from "./land_plots/61/land_plot_block_1178.svg";
import { ReactComponent as LandPlot_61_1179 } from "./land_plots/61/land_plot_block_1179.svg";
import { ReactComponent as LandPlot_61_1180 } from "./land_plots/61/land_plot_block_1180.svg";
import { ReactComponent as LandPlot_61_1181 } from "./land_plots/61/land_plot_block_1181.svg";
import { ReactComponent as LandPlot_61_1182 } from "./land_plots/61/land_plot_block_1182.svg";
import { ReactComponent as LandPlot_61_1183 } from "./land_plots/61/land_plot_block_1183.svg";
import { ReactComponent as LandPlot_61_1184 } from "./land_plots/61/land_plot_block_1184.svg";
import { ReactComponent as LandPlot_61_1185 } from "./land_plots/61/land_plot_block_1185.svg";
import { ReactComponent as LandPlot_61_1186 } from "./land_plots/61/land_plot_block_1186.svg";
import { ReactComponent as LandPlot_61_1187 } from "./land_plots/61/land_plot_block_1187.svg";
import { ReactComponent as LandPlot_61_1188 } from "./land_plots/61/land_plot_block_1188.svg";
import { ReactComponent as LandPlot_61_1189 } from "./land_plots/61/land_plot_block_1189.svg";
import { ReactComponent as LandPlot_61_1190 } from "./land_plots/61/land_plot_block_1190.svg";
import { ReactComponent as LandPlot_61_1191 } from "./land_plots/61/land_plot_block_1191.svg";
import { ReactComponent as LandPlot_61_1192 } from "./land_plots/61/land_plot_block_1192.svg";
import { ReactComponent as LandPlot_61_1193 } from "./land_plots/61/land_plot_block_1193.svg";
import { ReactComponent as LandPlot_61_1194 } from "./land_plots/61/land_plot_block_1194.svg";
import { ReactComponent as LandPlot_61_1195 } from "./land_plots/61/land_plot_block_1195.svg";
import { ReactComponent as LandPlot_61_1196 } from "./land_plots/61/land_plot_block_1196.svg";
import { ReactComponent as LandPlot_61_1197 } from "./land_plots/61/land_plot_block_1197.svg";
import { ReactComponent as LandPlot_61_1198 } from "./land_plots/61/land_plot_block_1198.svg";
import { ReactComponent as LandPlot_61_1199 } from "./land_plots/61/land_plot_block_1199.svg";
import { ReactComponent as LandPlot_61_1200 } from "./land_plots/61/land_plot_block_1200.svg";
import { ReactComponent as LandPlot_61_1201 } from "./land_plots/61/land_plot_block_1201.svg";
import { ReactComponent as LandPlot_61_1202 } from "./land_plots/61/land_plot_block_1202.svg";
import { ReactComponent as LandPlot_61_1203 } from "./land_plots/61/land_plot_block_1203.svg";
import { ReactComponent as LandPlot_61_1204 } from "./land_plots/61/land_plot_block_1204.svg";
import { ReactComponent as LandPlot_61_1205 } from "./land_plots/61/land_plot_block_1205.svg";
import { ReactComponent as LandPlot_61_1206 } from "./land_plots/61/land_plot_block_1206.svg";
import { ReactComponent as LandPlot_61_1207 } from "./land_plots/61/land_plot_block_1207.svg";
import { ReactComponent as LandPlot_61_1208 } from "./land_plots/61/land_plot_block_1208.svg";
import { ReactComponent as LandPlot_61_1209 } from "./land_plots/61/land_plot_block_1209.svg";
import { ReactComponent as LandPlot_61_1210 } from "./land_plots/61/land_plot_block_1210.svg";
import { ReactComponent as LandPlot_61_1211 } from "./land_plots/61/land_plot_block_1211.svg";
import { ReactComponent as LandPlot_61_1212 } from "./land_plots/61/land_plot_block_1212.svg";
import { ReactComponent as LandPlot_61_1213 } from "./land_plots/61/land_plot_block_1213.svg";
import { ReactComponent as LandPlot_61_1214 } from "./land_plots/61/land_plot_block_1214.svg";
import { ReactComponent as LandPlot_61_1215 } from "./land_plots/61/land_plot_block_1215.svg";
import { ReactComponent as LandPlot_61_1216 } from "./land_plots/61/land_plot_block_1216.svg";
import { ReactComponent as LandPlot_61_1217 } from "./land_plots/61/land_plot_block_1217.svg";
import { ReactComponent as LandPlot_61_1218 } from "./land_plots/61/land_plot_block_1218.svg";
import { ReactComponent as LandPlot_61_1219 } from "./land_plots/61/land_plot_block_1219.svg";
import { ReactComponent as LandPlot_61_1220 } from "./land_plots/61/land_plot_block_1220.svg";
import { ReactComponent as LandPlot_61_1221 } from "./land_plots/61/land_plot_block_1221.svg";
import { ReactComponent as LandPlot_61_1222 } from "./land_plots/61/land_plot_block_1222.svg";
import { ReactComponent as LandPlot_61_1223 } from "./land_plots/61/land_plot_block_1223.svg";
import { ReactComponent as LandPlot_61_1224 } from "./land_plots/61/land_plot_block_1224.svg";
import { ReactComponent as LandPlot_61_1225 } from "./land_plots/61/land_plot_block_1225.svg";
import { ReactComponent as LandPlot_61_1226 } from "./land_plots/61/land_plot_block_1226.svg";
import { ReactComponent as LandPlot_61_1227 } from "./land_plots/61/land_plot_block_1227.svg";
import { ReactComponent as LandPlot_61_1228 } from "./land_plots/61/land_plot_block_1228.svg";
import { ReactComponent as LandPlot_61_1229 } from "./land_plots/61/land_plot_block_1229.svg";
import { ReactComponent as LandPlot_61_1230 } from "./land_plots/61/land_plot_block_1230.svg";
import { ReactComponent as LandPlot_61_1231 } from "./land_plots/61/land_plot_block_1231.svg";
import { ReactComponent as LandPlot_61_1232 } from "./land_plots/61/land_plot_block_1232.svg";
import { ReactComponent as LandPlot_61_1233 } from "./land_plots/61/land_plot_block_1233.svg";
import { ReactComponent as LandPlot_61_1234 } from "./land_plots/61/land_plot_block_1234.svg";
import { ReactComponent as LandPlot_61_1235 } from "./land_plots/61/land_plot_block_1235.svg";
import { ReactComponent as LandPlot_61_1236 } from "./land_plots/61/land_plot_block_1236.svg";
import { ReactComponent as LandPlot_61_1237 } from "./land_plots/61/land_plot_block_1237.svg";
import { ReactComponent as LandPlot_61_1238 } from "./land_plots/61/land_plot_block_1238.svg";
import { ReactComponent as LandPlot_61_1239 } from "./land_plots/61/land_plot_block_1239.svg";
import { ReactComponent as LandPlot_61_1240 } from "./land_plots/61/land_plot_block_1240.svg";
import { ReactComponent as LandPlot_61_1241 } from "./land_plots/61/land_plot_block_1241.svg";
import { ReactComponent as LandPlot_61_1242 } from "./land_plots/61/land_plot_block_1242.svg";
import { ReactComponent as LandPlot_61_1243 } from "./land_plots/61/land_plot_block_1243.svg";
import { ReactComponent as LandPlot_61_1244 } from "./land_plots/61/land_plot_block_1244.svg";
import { ReactComponent as LandPlot_61_1245 } from "./land_plots/61/land_plot_block_1245.svg";
import { ReactComponent as LandPlot_61_1246 } from "./land_plots/61/land_plot_block_1246.svg";
import { ReactComponent as LandPlot_61_1247 } from "./land_plots/61/land_plot_block_1247.svg";
import { ReactComponent as LandPlot_61_1248 } from "./land_plots/61/land_plot_block_1248.svg";
import { ReactComponent as LandPlot_61_1249 } from "./land_plots/61/land_plot_block_1249.svg";
import { ReactComponent as LandPlot_61_1250 } from "./land_plots/61/land_plot_block_1250.svg";
import { ReactComponent as LandPlot_61_1251 } from "./land_plots/61/land_plot_block_1251.svg";
import { ReactComponent as LandPlot_61_1252 } from "./land_plots/61/land_plot_block_1252.svg";
import { ReactComponent as LandPlot_61_1253 } from "./land_plots/61/land_plot_block_1253.svg";
import { ReactComponent as LandPlot_61_1254 } from "./land_plots/61/land_plot_block_1254.svg";
import { ReactComponent as LandPlot_61_1255 } from "./land_plots/61/land_plot_block_1255.svg";
import { ReactComponent as LandPlot_61_1256 } from "./land_plots/61/land_plot_block_1256.svg";
import { ReactComponent as LandPlot_61_1257 } from "./land_plots/61/land_plot_block_1257.svg";
import { ReactComponent as LandPlot_61_1258 } from "./land_plots/61/land_plot_block_1258.svg";
import { ReactComponent as LandPlot_61_1259 } from "./land_plots/61/land_plot_block_1259.svg";
import { ReactComponent as LandPlot_61_1260 } from "./land_plots/61/land_plot_block_1260.svg";
import { ReactComponent as LandPlot_61_1261 } from "./land_plots/61/land_plot_block_1261.svg";
import { ReactComponent as LandPlot_61_1262 } from "./land_plots/61/land_plot_block_1262.svg";
import { ReactComponent as LandPlot_61_1263 } from "./land_plots/61/land_plot_block_1263.svg";
import { ReactComponent as LandPlot_61_1264 } from "./land_plots/61/land_plot_block_1264.svg";
import { ReactComponent as LandPlot_61_1265 } from "./land_plots/61/land_plot_block_1265.svg";
import { ReactComponent as LandPlot_61_1266 } from "./land_plots/61/land_plot_block_1266.svg";
import { ReactComponent as LandPlot_61_1267 } from "./land_plots/61/land_plot_block_1267.svg";
import { ReactComponent as LandPlot_61_1268 } from "./land_plots/61/land_plot_block_1268.svg";
import { ReactComponent as LandPlot_61_1269 } from "./land_plots/61/land_plot_block_1269.svg";
import { ReactComponent as LandPlot_61_1270 } from "./land_plots/61/land_plot_block_1270.svg";
import { ReactComponent as LandPlot_61_1271 } from "./land_plots/61/land_plot_block_1271.svg";
import { ReactComponent as LandPlot_61_1272 } from "./land_plots/61/land_plot_block_1272.svg";
import { ReactComponent as LandPlot_61_1273 } from "./land_plots/61/land_plot_block_1273.svg";
import { ReactComponent as LandPlot_61_1274 } from "./land_plots/61/land_plot_block_1274.svg";
import { ReactComponent as LandPlot_61_1275 } from "./land_plots/61/land_plot_block_1275.svg";
import { ReactComponent as LandPlot_61_1276 } from "./land_plots/61/land_plot_block_1276.svg";
import { ReactComponent as LandPlot_61_1277 } from "./land_plots/61/land_plot_block_1277.svg";
import { ReactComponent as LandPlot_61_1278 } from "./land_plots/61/land_plot_block_1278.svg";
import { ReactComponent as LandPlot_61_1279 } from "./land_plots/61/land_plot_block_1279.svg";
import { ReactComponent as LandPlot_61_1280 } from "./land_plots/61/land_plot_block_1280.svg";
import { ReactComponent as LandPlot_61_1281 } from "./land_plots/61/land_plot_block_1281.svg";
import { ReactComponent as LandPlot_61_1282 } from "./land_plots/61/land_plot_block_1282.svg";
import { ReactComponent as LandPlot_61_1283 } from "./land_plots/61/land_plot_block_1283.svg";
import { ReactComponent as LandPlot_61_1284 } from "./land_plots/61/land_plot_block_1284.svg";
import { ReactComponent as LandPlot_61_1285 } from "./land_plots/61/land_plot_block_1285.svg";
import { ReactComponent as LandPlot_61_1286 } from "./land_plots/61/land_plot_block_1286.svg";
import { ReactComponent as LandPlot_61_1287 } from "./land_plots/61/land_plot_block_1287.svg";
import { ReactComponent as LandPlot_61_1288 } from "./land_plots/61/land_plot_block_1288.svg";
import { ReactComponent as LandPlot_61_1289 } from "./land_plots/61/land_plot_block_1289.svg";
import { ReactComponent as LandPlot_61_1290 } from "./land_plots/61/land_plot_block_1290.svg";
import { ReactComponent as LandPlot_61_1291 } from "./land_plots/61/land_plot_block_1291.svg";
import { ReactComponent as LandPlot_61_1292 } from "./land_plots/61/land_plot_block_1292.svg";
import { ReactComponent as LandPlot_61_1293 } from "./land_plots/61/land_plot_block_1293.svg";
import { ReactComponent as LandPlot_61_1294 } from "./land_plots/61/land_plot_block_1294.svg";
import { ReactComponent as LandPlot_61_1295 } from "./land_plots/61/land_plot_block_1295.svg";
import { ReactComponent as LandPlot_61_1296 } from "./land_plots/61/land_plot_block_1296.svg";
import { ReactComponent as LandPlot_61_1297 } from "./land_plots/61/land_plot_block_1297.svg";
import { ReactComponent as LandPlot_61_1298 } from "./land_plots/61/land_plot_block_1298.svg";
import { ReactComponent as LandPlot_61_1299 } from "./land_plots/61/land_plot_block_1299.svg";
import { ReactComponent as LandPlot_61_1300 } from "./land_plots/61/land_plot_block_1300.svg";
import { ReactComponent as LandPlot_61_1301 } from "./land_plots/61/land_plot_block_1301.svg";
import { ReactComponent as LandPlot_61_1302 } from "./land_plots/61/land_plot_block_1302.svg";
import { ReactComponent as LandPlot_61_1303 } from "./land_plots/61/land_plot_block_1303.svg";
import { ReactComponent as LandPlot_61_1304 } from "./land_plots/61/land_plot_block_1304.svg";
import { ReactComponent as LandPlot_61_1305 } from "./land_plots/61/land_plot_block_1305.svg";
import { ReactComponent as LandPlot_61_1306 } from "./land_plots/61/land_plot_block_1306.svg";
import { ReactComponent as LandPlot_61_1307 } from "./land_plots/61/land_plot_block_1307.svg";
import { ReactComponent as LandPlot_61_1308 } from "./land_plots/61/land_plot_block_1308.svg";
import { ReactComponent as LandPlot_61_1309 } from "./land_plots/61/land_plot_block_1309.svg";
import { ReactComponent as LandPlot_61_1310 } from "./land_plots/61/land_plot_block_1310.svg";
import { ReactComponent as LandPlot_61_1311 } from "./land_plots/61/land_plot_block_1311.svg";
import { ReactComponent as LandPlot_61_1312 } from "./land_plots/61/land_plot_block_1312.svg";
import { ReactComponent as LandPlot_61_1313 } from "./land_plots/61/land_plot_block_1313.svg";
import { ReactComponent as LandPlot_61_1314 } from "./land_plots/61/land_plot_block_1314.svg";
import { ReactComponent as LandPlot_61_1315 } from "./land_plots/61/land_plot_block_1315.svg";
import { ReactComponent as LandPlot_61_1316 } from "./land_plots/61/land_plot_block_1316.svg";
import { ReactComponent as LandPlot_61_1317 } from "./land_plots/61/land_plot_block_1317.svg";
import { ReactComponent as LandPlot_61_1318 } from "./land_plots/61/land_plot_block_1318.svg";
import { ReactComponent as LandPlot_61_1319 } from "./land_plots/61/land_plot_block_1319.svg";
import { ReactComponent as LandPlot_61_1320 } from "./land_plots/61/land_plot_block_1320.svg";
import { ReactComponent as LandPlot_61_1321 } from "./land_plots/61/land_plot_block_1321.svg";
import { ReactComponent as LandPlot_61_1322 } from "./land_plots/61/land_plot_block_1322.svg";
import { ReactComponent as LandPlot_61_1323 } from "./land_plots/61/land_plot_block_1323.svg";
import { ReactComponent as LandPlot_61_1324 } from "./land_plots/61/land_plot_block_1324.svg";
import { ReactComponent as LandPlot_61_1325 } from "./land_plots/61/land_plot_block_1325.svg";
import { ReactComponent as LandPlot_61_1326 } from "./land_plots/61/land_plot_block_1326.svg";
import { ReactComponent as LandPlot_61_1327 } from "./land_plots/61/land_plot_block_1327.svg";
import { ReactComponent as LandPlot_61_1328 } from "./land_plots/61/land_plot_block_1328.svg";
import { ReactComponent as LandPlot_61_1329 } from "./land_plots/61/land_plot_block_1329.svg";
import { ReactComponent as LandPlot_61_1330 } from "./land_plots/61/land_plot_block_1330.svg";
import { ReactComponent as LandPlot_61_1331 } from "./land_plots/61/land_plot_block_1331.svg";
import { ReactComponent as LandPlot_61_1332 } from "./land_plots/61/land_plot_block_1332.svg";
import { ReactComponent as LandPlot_61_1333 } from "./land_plots/61/land_plot_block_1333.svg";
import { ReactComponent as LandPlot_61_1334 } from "./land_plots/61/land_plot_block_1334.svg";
import { ReactComponent as LandPlot_61_1335 } from "./land_plots/61/land_plot_block_1335.svg";
import { ReactComponent as LandPlot_61_1336 } from "./land_plots/61/land_plot_block_1336.svg";
import { ReactComponent as LandPlot_61_1337 } from "./land_plots/61/land_plot_block_1337.svg";
import { ReactComponent as LandPlot_61_1338 } from "./land_plots/61/land_plot_block_1338.svg";
import { ReactComponent as LandPlot_61_1339 } from "./land_plots/61/land_plot_block_1339.svg";
import { ReactComponent as LandPlot_61_1340 } from "./land_plots/61/land_plot_block_1340.svg";
import { ReactComponent as LandPlot_61_1341 } from "./land_plots/61/land_plot_block_1341.svg";
import { ReactComponent as LandPlot_61_1342 } from "./land_plots/61/land_plot_block_1342.svg";
import { ReactComponent as LandPlot_61_1343 } from "./land_plots/61/land_plot_block_1343.svg";
import { ReactComponent as LandPlot_61_1344 } from "./land_plots/61/land_plot_block_1344.svg";
import { ReactComponent as LandPlot_61_1345 } from "./land_plots/61/land_plot_block_1345.svg";
import { ReactComponent as LandPlot_61_1346 } from "./land_plots/61/land_plot_block_1346.svg";
import { ReactComponent as LandPlot_61_1347 } from "./land_plots/61/land_plot_block_1347.svg";
import { ReactComponent as LandPlot_61_1348 } from "./land_plots/61/land_plot_block_1348.svg";
import { ReactComponent as LandPlot_61_1349 } from "./land_plots/61/land_plot_block_1349.svg";
import { ReactComponent as LandPlot_61_1350 } from "./land_plots/61/land_plot_block_1350.svg";
import { ReactComponent as LandPlot_61_1351 } from "./land_plots/61/land_plot_block_1351.svg";
import { ReactComponent as LandPlot_61_1352 } from "./land_plots/61/land_plot_block_1352.svg";
import { ReactComponent as LandPlot_61_1353 } from "./land_plots/61/land_plot_block_1353.svg";
import { ReactComponent as LandPlot_61_1354 } from "./land_plots/61/land_plot_block_1354.svg";
import { ReactComponent as LandPlot_61_1355 } from "./land_plots/61/land_plot_block_1355.svg";
import { ReactComponent as LandPlot_61_1356 } from "./land_plots/61/land_plot_block_1356.svg";
import { ReactComponent as LandPlot_61_1357 } from "./land_plots/61/land_plot_block_1357.svg";
import { ReactComponent as LandPlot_61_1358 } from "./land_plots/61/land_plot_block_1358.svg";
import { ReactComponent as LandPlot_61_1359 } from "./land_plots/61/land_plot_block_1359.svg";
import { ReactComponent as LandPlot_61_1360 } from "./land_plots/61/land_plot_block_1360.svg";
import { ReactComponent as LandPlot_61_1361 } from "./land_plots/61/land_plot_block_1361.svg";
import { ReactComponent as LandPlot_61_1362 } from "./land_plots/61/land_plot_block_1362.svg";
import { ReactComponent as LandPlot_61_1363 } from "./land_plots/61/land_plot_block_1363.svg";
import { ReactComponent as LandPlot_61_1364 } from "./land_plots/61/land_plot_block_1364.svg";
import { ReactComponent as LandPlot_61_1365 } from "./land_plots/61/land_plot_block_1365.svg";
import { ReactComponent as LandPlot_61_1366 } from "./land_plots/61/land_plot_block_1366.svg";
import { ReactComponent as LandPlot_61_1367 } from "./land_plots/61/land_plot_block_1367.svg";
import { ReactComponent as LandPlot_61_1368 } from "./land_plots/61/land_plot_block_1368.svg";
import { ReactComponent as LandPlot_61_1369 } from "./land_plots/61/land_plot_block_1369.svg";
import { ReactComponent as LandPlot_61_1370 } from "./land_plots/61/land_plot_block_1370.svg";
import { ReactComponent as LandPlot_61_1371 } from "./land_plots/61/land_plot_block_1371.svg";
import { ReactComponent as LandPlot_61_1372 } from "./land_plots/61/land_plot_block_1372.svg";
import { ReactComponent as LandPlot_61_1373 } from "./land_plots/61/land_plot_block_1373.svg";
import { ReactComponent as LandPlot_61_1374 } from "./land_plots/61/land_plot_block_1374.svg";
import { ReactComponent as LandPlot_61_1375 } from "./land_plots/61/land_plot_block_1375.svg";
import { ReactComponent as LandPlot_61_1376 } from "./land_plots/61/land_plot_block_1376.svg";
import { ReactComponent as LandPlot_61_1377 } from "./land_plots/61/land_plot_block_1377.svg";
import { ReactComponent as LandPlot_61_1378 } from "./land_plots/61/land_plot_block_1378.svg";
import { ReactComponent as LandPlot_61_1379 } from "./land_plots/61/land_plot_block_1379.svg";
import { ReactComponent as LandPlot_61_1380 } from "./land_plots/61/land_plot_block_1380.svg";
import { ReactComponent as LandPlot_61_1381 } from "./land_plots/61/land_plot_block_1381.svg";
import { ReactComponent as LandPlot_61_1382 } from "./land_plots/61/land_plot_block_1382.svg";
import { ReactComponent as LandPlot_61_1383 } from "./land_plots/61/land_plot_block_1383.svg";
import { ReactComponent as LandPlot_61_1384 } from "./land_plots/61/land_plot_block_1384.svg";
import { ReactComponent as LandPlot_61_1385 } from "./land_plots/61/land_plot_block_1385.svg";
import { ReactComponent as LandPlot_61_1386 } from "./land_plots/61/land_plot_block_1386.svg";
import { ReactComponent as LandPlot_61_1387 } from "./land_plots/61/land_plot_block_1387.svg";
import { ReactComponent as LandPlot_61_1388 } from "./land_plots/61/land_plot_block_1388.svg";
import { ReactComponent as LandPlot_61_1389 } from "./land_plots/61/land_plot_block_1389.svg";
import { ReactComponent as LandPlot_61_1390 } from "./land_plots/61/land_plot_block_1390.svg";
import { ReactComponent as LandPlot_61_1391 } from "./land_plots/61/land_plot_block_1391.svg";
import { ReactComponent as LandPlot_61_1392 } from "./land_plots/61/land_plot_block_1392.svg";
import { ReactComponent as LandPlot_61_1393 } from "./land_plots/61/land_plot_block_1393.svg";
import { ReactComponent as LandPlot_61_1394 } from "./land_plots/61/land_plot_block_1394.svg";
import { ReactComponent as LandPlot_61_1395 } from "./land_plots/61/land_plot_block_1395.svg";
import { ReactComponent as LandPlot_61_1396 } from "./land_plots/61/land_plot_block_1396.svg";
import { ReactComponent as LandPlot_61_1397 } from "./land_plots/61/land_plot_block_1397.svg";
import { ReactComponent as LandPlot_61_1398 } from "./land_plots/61/land_plot_block_1398.svg";
import { ReactComponent as LandPlot_61_1399 } from "./land_plots/61/land_plot_block_1399.svg";
import { ReactComponent as LandPlot_61_1400 } from "./land_plots/61/land_plot_block_1400.svg";
import { ReactComponent as LandPlot_61_1401 } from "./land_plots/61/land_plot_block_1401.svg";
import { ReactComponent as LandPlot_61_1402 } from "./land_plots/61/land_plot_block_1402.svg";
import { ReactComponent as LandPlot_61_1403 } from "./land_plots/61/land_plot_block_1403.svg";
import { ReactComponent as LandPlot_61_1404 } from "./land_plots/61/land_plot_block_1404.svg";
import { ReactComponent as LandPlot_61_1405 } from "./land_plots/61/land_plot_block_1405.svg";
import { ReactComponent as LandPlot_61_1406 } from "./land_plots/61/land_plot_block_1406.svg";
import { ReactComponent as LandPlot_61_1407 } from "./land_plots/61/land_plot_block_1407.svg";
import { ReactComponent as LandPlot_61_1408 } from "./land_plots/61/land_plot_block_1408.svg";
import { ReactComponent as LandPlot_61_1409 } from "./land_plots/61/land_plot_block_1409.svg";
import { ReactComponent as LandPlot_61_1410 } from "./land_plots/61/land_plot_block_1410.svg";
import { ReactComponent as LandPlot_61_1411 } from "./land_plots/61/land_plot_block_1411.svg";
import { ReactComponent as LandPlot_61_1412 } from "./land_plots/61/land_plot_block_1412.svg";
import { ReactComponent as LandPlot_61_1413 } from "./land_plots/61/land_plot_block_1413.svg";
import { ReactComponent as LandPlot_61_1414 } from "./land_plots/61/land_plot_block_1414.svg";
import { ReactComponent as LandPlot_61_1415 } from "./land_plots/61/land_plot_block_1415.svg";
import { ReactComponent as LandPlot_61_1416 } from "./land_plots/61/land_plot_block_1416.svg";
import { ReactComponent as LandPlot_61_1417 } from "./land_plots/61/land_plot_block_1417.svg";
import { ReactComponent as LandPlot_61_1418 } from "./land_plots/61/land_plot_block_1418.svg";
import { ReactComponent as LandPlot_61_1419 } from "./land_plots/61/land_plot_block_1419.svg";
import { ReactComponent as LandPlot_61_1420 } from "./land_plots/61/land_plot_block_1420.svg";
import { ReactComponent as LandPlot_61_1421 } from "./land_plots/61/land_plot_block_1421.svg";
import { ReactComponent as LandPlot_61_1422 } from "./land_plots/61/land_plot_block_1422.svg";
import { ReactComponent as LandPlot_61_1423 } from "./land_plots/61/land_plot_block_1423.svg";
import { ReactComponent as LandPlot_61_1424 } from "./land_plots/61/land_plot_block_1424.svg";
import { ReactComponent as LandPlot_61_1425 } from "./land_plots/61/land_plot_block_1425.svg";
import { ReactComponent as LandPlot_61_1426 } from "./land_plots/61/land_plot_block_1426.svg";
import { ReactComponent as LandPlot_61_1427 } from "./land_plots/61/land_plot_block_1427.svg";
import { ReactComponent as LandPlot_61_1428 } from "./land_plots/61/land_plot_block_1428.svg";
import { ReactComponent as LandPlot_61_1429 } from "./land_plots/61/land_plot_block_1429.svg";
import { ReactComponent as LandPlot_61_1430 } from "./land_plots/61/land_plot_block_1430.svg";
import { ReactComponent as LandPlot_61_1431 } from "./land_plots/61/land_plot_block_1431.svg";
import { ReactComponent as LandPlot_61_1432 } from "./land_plots/61/land_plot_block_1432.svg";
import { ReactComponent as LandPlot_61_1433 } from "./land_plots/61/land_plot_block_1433.svg";
import { ReactComponent as LandPlot_61_1434 } from "./land_plots/61/land_plot_block_1434.svg";
import { ReactComponent as LandPlot_61_1435 } from "./land_plots/61/land_plot_block_1435.svg";
import { ReactComponent as LandPlot_61_1436 } from "./land_plots/61/land_plot_block_1436.svg";
import { ReactComponent as LandPlot_61_1437 } from "./land_plots/61/land_plot_block_1437.svg";
import { ReactComponent as LandPlot_61_1438 } from "./land_plots/61/land_plot_block_1438.svg";
import { ReactComponent as LandPlot_61_1439 } from "./land_plots/61/land_plot_block_1439.svg";
import { ReactComponent as LandPlot_61_1440 } from "./land_plots/61/land_plot_block_1440.svg";
import { ReactComponent as LandPlot_61_1441 } from "./land_plots/61/land_plot_block_1441.svg";
import { ReactComponent as LandPlot_61_1442 } from "./land_plots/61/land_plot_block_1442.svg";
import { ReactComponent as LandPlot_61_1443 } from "./land_plots/61/land_plot_block_1443.svg";
import { ReactComponent as LandPlot_61_1444 } from "./land_plots/61/land_plot_block_1444.svg";
import { ReactComponent as LandPlot_61_1445 } from "./land_plots/61/land_plot_block_1445.svg";
import { ReactComponent as LandPlot_61_1446 } from "./land_plots/61/land_plot_block_1446.svg";
import { ReactComponent as LandPlot_61_1447 } from "./land_plots/61/land_plot_block_1447.svg";
import { ReactComponent as LandPlot_61_1448 } from "./land_plots/61/land_plot_block_1448.svg";
import { ReactComponent as LandPlot_61_1449 } from "./land_plots/61/land_plot_block_1449.svg";
import { ReactComponent as LandPlot_61_1450 } from "./land_plots/61/land_plot_block_1450.svg";
import { ReactComponent as LandPlot_61_1451 } from "./land_plots/61/land_plot_block_1451.svg";
import { ReactComponent as LandPlot_61_1452 } from "./land_plots/61/land_plot_block_1452.svg";
import { ReactComponent as LandPlot_61_1453 } from "./land_plots/61/land_plot_block_1453.svg";
import { ReactComponent as LandPlot_61_1454 } from "./land_plots/61/land_plot_block_1454.svg";
import { ReactComponent as LandPlot_61_1455 } from "./land_plots/61/land_plot_block_1455.svg";
import { ReactComponent as LandPlot_61_1456 } from "./land_plots/61/land_plot_block_1456.svg";
import { ReactComponent as LandPlot_61_1457 } from "./land_plots/61/land_plot_block_1457.svg";
import { ReactComponent as LandPlot_61_1458 } from "./land_plots/61/land_plot_block_1458.svg";
import { ReactComponent as LandPlot_61_1459 } from "./land_plots/61/land_plot_block_1459.svg";
import { ReactComponent as LandPlot_61_1460 } from "./land_plots/61/land_plot_block_1460.svg";
import { ReactComponent as LandPlot_61_1461 } from "./land_plots/61/land_plot_block_1461.svg";
import { ReactComponent as LandPlot_61_1462 } from "./land_plots/61/land_plot_block_1462.svg";
import { ReactComponent as LandPlot_61_1463 } from "./land_plots/61/land_plot_block_1463.svg";
import { ReactComponent as LandPlot_61_1464 } from "./land_plots/61/land_plot_block_1464.svg";
import { ReactComponent as LandPlot_61_1465 } from "./land_plots/61/land_plot_block_1465.svg";
import { ReactComponent as LandPlot_61_1466 } from "./land_plots/61/land_plot_block_1466.svg";
import { ReactComponent as LandPlot_61_1467 } from "./land_plots/61/land_plot_block_1467.svg";
import { ReactComponent as LandPlot_61_1468 } from "./land_plots/61/land_plot_block_1468.svg";
import { ReactComponent as LandPlot_61_1469 } from "./land_plots/61/land_plot_block_1469.svg";
import { ReactComponent as LandPlot_61_1470 } from "./land_plots/61/land_plot_block_1470.svg";
import { ReactComponent as LandPlot_61_1471 } from "./land_plots/61/land_plot_block_1471.svg";
import { ReactComponent as LandPlot_61_1472 } from "./land_plots/61/land_plot_block_1472.svg";
import { ReactComponent as LandPlot_61_1473 } from "./land_plots/61/land_plot_block_1473.svg";
import { ReactComponent as LandPlot_61_1474 } from "./land_plots/61/land_plot_block_1474.svg";
import { ReactComponent as LandPlot_61_1475 } from "./land_plots/61/land_plot_block_1475.svg";
import { ReactComponent as LandPlot_61_1476 } from "./land_plots/61/land_plot_block_1476.svg";
import { ReactComponent as LandPlot_61_1477 } from "./land_plots/61/land_plot_block_1477.svg";
import { ReactComponent as LandPlot_61_1478 } from "./land_plots/61/land_plot_block_1478.svg";
import { ReactComponent as LandPlot_61_1479 } from "./land_plots/61/land_plot_block_1479.svg";
import { ReactComponent as LandPlot_61_1480 } from "./land_plots/61/land_plot_block_1480.svg";
import { ReactComponent as LandPlot_61_1481 } from "./land_plots/61/land_plot_block_1481.svg";
import { ReactComponent as LandPlot_61_1482 } from "./land_plots/61/land_plot_block_1482.svg";
import { ReactComponent as LandPlot_61_1483 } from "./land_plots/61/land_plot_block_1483.svg";
import { ReactComponent as LandPlot_61_1484 } from "./land_plots/61/land_plot_block_1484.svg";
import { ReactComponent as LandPlot_61_1485 } from "./land_plots/61/land_plot_block_1485.svg";
import { ReactComponent as LandPlot_61_1486 } from "./land_plots/61/land_plot_block_1486.svg";
import { ReactComponent as LandPlot_61_1487 } from "./land_plots/61/land_plot_block_1487.svg";
import { ReactComponent as LandPlot_61_1488 } from "./land_plots/61/land_plot_block_1488.svg";
import { ReactComponent as LandPlot_61_1489 } from "./land_plots/61/land_plot_block_1489.svg";
import { ReactComponent as LandPlot_61_1490 } from "./land_plots/61/land_plot_block_1490.svg";
import { ReactComponent as LandPlot_61_1491 } from "./land_plots/61/land_plot_block_1491.svg";
import { ReactComponent as LandPlot_61_1492 } from "./land_plots/61/land_plot_block_1492.svg";
import { ReactComponent as LandPlot_61_1493 } from "./land_plots/61/land_plot_block_1493.svg";
import { ReactComponent as LandPlot_61_1494 } from "./land_plots/61/land_plot_block_1494.svg";
import { ReactComponent as LandPlot_61_1495 } from "./land_plots/61/land_plot_block_1495.svg";
import { ReactComponent as LandPlot_61_1496 } from "./land_plots/61/land_plot_block_1496.svg";
import { ReactComponent as LandPlot_61_1497 } from "./land_plots/61/land_plot_block_1497.svg";
import { ReactComponent as LandPlot_61_1498 } from "./land_plots/61/land_plot_block_1498.svg";
import { ReactComponent as LandPlot_61_1499 } from "./land_plots/61/land_plot_block_1499.svg";
import { ReactComponent as LandPlot_61_1500 } from "./land_plots/61/land_plot_block_1500.svg";
import { ReactComponent as LandPlot_61_1501 } from "./land_plots/61/land_plot_block_1501.svg";
import { ReactComponent as LandPlot_61_1502 } from "./land_plots/61/land_plot_block_1502.svg";
import { ReactComponent as LandPlot_61_1503 } from "./land_plots/61/land_plot_block_1503.svg";
import { ReactComponent as LandPlot_61_1504 } from "./land_plots/61/land_plot_block_1504.svg";
import { ReactComponent as LandPlot_61_1505 } from "./land_plots/61/land_plot_block_1505.svg";
import { ReactComponent as LandPlot_61_1506 } from "./land_plots/61/land_plot_block_1506.svg";
import { ReactComponent as LandPlot_61_1507 } from "./land_plots/61/land_plot_block_1507.svg";
import { ReactComponent as LandPlot_61_1508 } from "./land_plots/61/land_plot_block_1508.svg";
import { ReactComponent as LandPlot_61_1509 } from "./land_plots/61/land_plot_block_1509.svg";
import { ReactComponent as LandPlot_61_1510 } from "./land_plots/61/land_plot_block_1510.svg";
import { ReactComponent as LandPlot_61_1511 } from "./land_plots/61/land_plot_block_1511.svg";
import { ReactComponent as LandPlot_61_1512 } from "./land_plots/61/land_plot_block_1512.svg";
import { ReactComponent as LandPlot_61_1513 } from "./land_plots/61/land_plot_block_1513.svg";
import { ReactComponent as LandPlot_61_1514 } from "./land_plots/61/land_plot_block_1514.svg";
import { ReactComponent as LandPlot_61_1515 } from "./land_plots/61/land_plot_block_1515.svg";
import { ReactComponent as LandPlot_61_1516 } from "./land_plots/61/land_plot_block_1516.svg";
import { ReactComponent as LandPlot_61_1517 } from "./land_plots/61/land_plot_block_1517.svg";
import { ReactComponent as LandPlot_61_1518 } from "./land_plots/61/land_plot_block_1518.svg";
import { ReactComponent as LandPlot_61_1519 } from "./land_plots/61/land_plot_block_1519.svg";
import { ReactComponent as LandPlot_61_1520 } from "./land_plots/61/land_plot_block_1520.svg";
import { ReactComponent as LandPlot_61_1521 } from "./land_plots/61/land_plot_block_1521.svg";
import { ReactComponent as LandPlot_61_1522 } from "./land_plots/61/land_plot_block_1522.svg";
import { ReactComponent as LandPlot_61_1523 } from "./land_plots/61/land_plot_block_1523.svg";
import { ReactComponent as LandPlot_61_1524 } from "./land_plots/61/land_plot_block_1524.svg";
import { ReactComponent as LandPlot_61_1525 } from "./land_plots/61/land_plot_block_1525.svg";
import { ReactComponent as LandPlot_61_1526 } from "./land_plots/61/land_plot_block_1526.svg";
import { ReactComponent as LandPlot_61_1527 } from "./land_plots/61/land_plot_block_1527.svg";
import { ReactComponent as LandPlot_61_1528 } from "./land_plots/61/land_plot_block_1528.svg";
import { ReactComponent as LandPlot_61_1529 } from "./land_plots/61/land_plot_block_1529.svg";
import { ReactComponent as LandPlot_61_1530 } from "./land_plots/61/land_plot_block_1530.svg";
import { ReactComponent as LandPlot_61_1531 } from "./land_plots/61/land_plot_block_1531.svg";
import { ReactComponent as LandPlot_61_1532 } from "./land_plots/61/land_plot_block_1532.svg";
import { ReactComponent as LandPlot_61_1533 } from "./land_plots/61/land_plot_block_1533.svg";
import { ReactComponent as LandPlot_61_1534 } from "./land_plots/61/land_plot_block_1534.svg";
import { ReactComponent as LandPlot_61_1535 } from "./land_plots/61/land_plot_block_1535.svg";
import { ReactComponent as LandPlot_61_1536 } from "./land_plots/61/land_plot_block_1536.svg";
import { ReactComponent as LandPlot_61_1537 } from "./land_plots/61/land_plot_block_1537.svg";
import { ReactComponent as LandPlot_61_1538 } from "./land_plots/61/land_plot_block_1538.svg";
import { ReactComponent as LandPlot_61_1539 } from "./land_plots/61/land_plot_block_1539.svg";
import { ReactComponent as LandPlot_61_1540 } from "./land_plots/61/land_plot_block_1540.svg";
import { ReactComponent as LandPlot_61_1541 } from "./land_plots/61/land_plot_block_1541.svg";
import { ReactComponent as LandPlot_61_1542 } from "./land_plots/61/land_plot_block_1542.svg";
import { ReactComponent as LandPlot_61_1543 } from "./land_plots/61/land_plot_block_1543.svg";
import { ReactComponent as LandPlot_61_1544 } from "./land_plots/61/land_plot_block_1544.svg";
import { ReactComponent as LandPlot_61_1545 } from "./land_plots/61/land_plot_block_1545.svg";
import { ReactComponent as LandPlot_61_1546 } from "./land_plots/61/land_plot_block_1546.svg";
import { ReactComponent as LandPlot_61_1547 } from "./land_plots/61/land_plot_block_1547.svg";
import { ReactComponent as LandPlot_61_1548 } from "./land_plots/61/land_plot_block_1548.svg";
import { ReactComponent as LandPlot_61_1549 } from "./land_plots/61/land_plot_block_1549.svg";
import { ReactComponent as LandPlot_61_1550 } from "./land_plots/61/land_plot_block_1550.svg";
import { ReactComponent as LandPlot_61_1551 } from "./land_plots/61/land_plot_block_1551.svg";
import { ReactComponent as LandPlot_61_1552 } from "./land_plots/61/land_plot_block_1552.svg";
import { ReactComponent as LandPlot_61_1553 } from "./land_plots/61/land_plot_block_1553.svg";
import { ReactComponent as LandPlot_61_1554 } from "./land_plots/61/land_plot_block_1554.svg";
import { ReactComponent as LandPlot_61_1555 } from "./land_plots/61/land_plot_block_1555.svg";
import { ReactComponent as LandPlot_61_1556 } from "./land_plots/61/land_plot_block_1556.svg";
import { ReactComponent as LandPlot_61_1557 } from "./land_plots/61/land_plot_block_1557.svg";
import { ReactComponent as LandPlot_61_1558 } from "./land_plots/61/land_plot_block_1558.svg";
import { ReactComponent as LandPlot_61_1559 } from "./land_plots/61/land_plot_block_1559.svg";
import { ReactComponent as LandPlot_61_1560 } from "./land_plots/61/land_plot_block_1560.svg";
import { ReactComponent as LandPlot_61_1561 } from "./land_plots/61/land_plot_block_1561.svg";
import { ReactComponent as LandPlot_61_1562 } from "./land_plots/61/land_plot_block_1562.svg";
import { ReactComponent as LandPlot_61_1563 } from "./land_plots/61/land_plot_block_1563.svg";
import { ReactComponent as LandPlot_61_1564 } from "./land_plots/61/land_plot_block_1564.svg";
import { ReactComponent as LandPlot_61_1565 } from "./land_plots/61/land_plot_block_1565.svg";
import { ReactComponent as LandPlot_61_1566 } from "./land_plots/61/land_plot_block_1566.svg";
import { ReactComponent as LandPlot_61_1567 } from "./land_plots/61/land_plot_block_1567.svg";
import { ReactComponent as LandPlot_61_1568 } from "./land_plots/61/land_plot_block_1568.svg";
import { ReactComponent as LandPlot_61_1569 } from "./land_plots/61/land_plot_block_1569.svg";
import { ReactComponent as LandPlot_61_1570 } from "./land_plots/61/land_plot_block_1570.svg";
import { ReactComponent as LandPlot_61_1571 } from "./land_plots/61/land_plot_block_1571.svg";
import { ReactComponent as LandPlot_61_1572 } from "./land_plots/61/land_plot_block_1572.svg";
import { ReactComponent as LandPlot_61_1573 } from "./land_plots/61/land_plot_block_1573.svg";
import { ReactComponent as LandPlot_61_1574 } from "./land_plots/61/land_plot_block_1574.svg";
import { ReactComponent as LandPlot_61_1575 } from "./land_plots/61/land_plot_block_1575.svg";
import { ReactComponent as LandPlot_61_1576 } from "./land_plots/61/land_plot_block_1576.svg";
import { ReactComponent as LandPlot_61_1577 } from "./land_plots/61/land_plot_block_1577.svg";
import { ReactComponent as LandPlot_61_1578 } from "./land_plots/61/land_plot_block_1578.svg";
import { ReactComponent as LandPlot_61_1579 } from "./land_plots/61/land_plot_block_1579.svg";
import { ReactComponent as LandPlot_61_1580 } from "./land_plots/61/land_plot_block_1580.svg";
import { ReactComponent as LandPlot_61_1581 } from "./land_plots/61/land_plot_block_1581.svg";
import { ReactComponent as LandPlot_61_1582 } from "./land_plots/61/land_plot_block_1582.svg";
import { ReactComponent as LandPlot_61_1583 } from "./land_plots/61/land_plot_block_1583.svg";
import { ReactComponent as LandPlot_61_1584 } from "./land_plots/61/land_plot_block_1584.svg";
import { ReactComponent as LandPlot_61_1585 } from "./land_plots/61/land_plot_block_1585.svg";
import { ReactComponent as LandPlot_61_1586 } from "./land_plots/61/land_plot_block_1586.svg";
import { ReactComponent as LandPlot_61_1587 } from "./land_plots/61/land_plot_block_1587.svg";
import { ReactComponent as LandPlot_61_1588 } from "./land_plots/61/land_plot_block_1588.svg";
import { ReactComponent as LandPlot_61_1589 } from "./land_plots/61/land_plot_block_1589.svg";
import { ReactComponent as LandPlot_61_1590 } from "./land_plots/61/land_plot_block_1590.svg";
import { ReactComponent as LandPlot_61_1591 } from "./land_plots/61/land_plot_block_1591.svg";
import { ReactComponent as LandPlot_61_1592 } from "./land_plots/61/land_plot_block_1592.svg";
import { ReactComponent as LandPlot_61_1593 } from "./land_plots/61/land_plot_block_1593.svg";
import { ReactComponent as LandPlot_61_1594 } from "./land_plots/61/land_plot_block_1594.svg";
import { ReactComponent as LandPlot_61_1595 } from "./land_plots/61/land_plot_block_1595.svg";
import { ReactComponent as LandPlot_61_1596 } from "./land_plots/61/land_plot_block_1596.svg";
import { ReactComponent as LandPlot_61_1597 } from "./land_plots/61/land_plot_block_1597.svg";
import { ReactComponent as LandPlot_61_1598 } from "./land_plots/61/land_plot_block_1598.svg";
import { ReactComponent as LandPlot_61_1599 } from "./land_plots/61/land_plot_block_1599.svg";
import { ReactComponent as LandPlot_61_1600 } from "./land_plots/61/land_plot_block_1600.svg";
import { ReactComponent as LandPlot_61_1601 } from "./land_plots/61/land_plot_block_1601.svg";
import { ReactComponent as LandPlot_61_1602 } from "./land_plots/61/land_plot_block_1602.svg";
import { ReactComponent as LandPlot_61_1603 } from "./land_plots/61/land_plot_block_1603.svg";
import { ReactComponent as LandPlot_61_1604 } from "./land_plots/61/land_plot_block_1604.svg";
import { ReactComponent as LandPlot_61_1605 } from "./land_plots/61/land_plot_block_1605.svg";
import { ReactComponent as LandPlot_61_1606 } from "./land_plots/61/land_plot_block_1606.svg";
import { ReactComponent as LandPlot_61_1607 } from "./land_plots/61/land_plot_block_1607.svg";
import { ReactComponent as LandPlot_61_1608 } from "./land_plots/61/land_plot_block_1608.svg";
import { ReactComponent as LandPlot_61_1609 } from "./land_plots/61/land_plot_block_1609.svg";
import { ReactComponent as LandPlot_61_1610 } from "./land_plots/61/land_plot_block_1610.svg";
import { ReactComponent as LandPlot_61_1611 } from "./land_plots/61/land_plot_block_1611.svg";
import { ReactComponent as LandPlot_61_1612 } from "./land_plots/61/land_plot_block_1612.svg";
import { ReactComponent as LandPlot_61_1613 } from "./land_plots/61/land_plot_block_1613.svg";
import { ReactComponent as LandPlot_61_1614 } from "./land_plots/61/land_plot_block_1614.svg";
import { ReactComponent as LandPlot_61_1615 } from "./land_plots/61/land_plot_block_1615.svg";
import { ReactComponent as LandPlot_61_1616 } from "./land_plots/61/land_plot_block_1616.svg";
import { ReactComponent as LandPlot_61_1617 } from "./land_plots/61/land_plot_block_1617.svg";
import { ReactComponent as LandPlot_61_1618 } from "./land_plots/61/land_plot_block_1618.svg";
import { ReactComponent as LandPlot_61_1619 } from "./land_plots/61/land_plot_block_1619.svg";
import { ReactComponent as LandPlot_61_1620 } from "./land_plots/61/land_plot_block_1620.svg";
import { ReactComponent as LandPlot_61_1621 } from "./land_plots/61/land_plot_block_1621.svg";
import { ReactComponent as LandPlot_61_1622 } from "./land_plots/61/land_plot_block_1622.svg";
import { ReactComponent as LandPlot_61_1623 } from "./land_plots/61/land_plot_block_1623.svg";
import { ReactComponent as LandPlot_61_1624 } from "./land_plots/61/land_plot_block_1624.svg";
import { ReactComponent as LandPlot_61_1625 } from "./land_plots/61/land_plot_block_1625.svg";
import { ReactComponent as LandPlot_61_1626 } from "./land_plots/61/land_plot_block_1626.svg";
import { ReactComponent as LandPlot_61_1627 } from "./land_plots/61/land_plot_block_1627.svg";

//////////////////////////////////////////////////////////////////////////////////stop frame 91
import { ReactComponent as LandPlot_91_1164 } from "./land_plots/91/land_plot_block_1164.svg";
import { ReactComponent as LandPlot_91_1165 } from "./land_plots/91/land_plot_block_1165.svg";
import { ReactComponent as LandPlot_91_1166 } from "./land_plots/91/land_plot_block_1166.svg";
import { ReactComponent as LandPlot_91_1167 } from "./land_plots/91/land_plot_block_1167.svg";
import { ReactComponent as LandPlot_91_1168 } from "./land_plots/91/land_plot_block_1168.svg";
import { ReactComponent as LandPlot_91_1169 } from "./land_plots/91/land_plot_block_1169.svg";
import { ReactComponent as LandPlot_91_1170 } from "./land_plots/91/land_plot_block_1170.svg";
import { ReactComponent as LandPlot_91_1171 } from "./land_plots/91/land_plot_block_1171.svg";
import { ReactComponent as LandPlot_91_1172 } from "./land_plots/91/land_plot_block_1172.svg";
import { ReactComponent as LandPlot_91_1173 } from "./land_plots/91/land_plot_block_1173.svg";
import { ReactComponent as LandPlot_91_1174 } from "./land_plots/91/land_plot_block_1174.svg";
import { ReactComponent as LandPlot_91_1175 } from "./land_plots/91/land_plot_block_1175.svg";
import { ReactComponent as LandPlot_91_1176 } from "./land_plots/91/land_plot_block_1176.svg";
import { ReactComponent as LandPlot_91_1177 } from "./land_plots/91/land_plot_block_1177.svg";
import { ReactComponent as LandPlot_91_1178 } from "./land_plots/91/land_plot_block_1178.svg";
import { ReactComponent as LandPlot_91_1179 } from "./land_plots/91/land_plot_block_1179.svg";
import { ReactComponent as LandPlot_91_1180 } from "./land_plots/91/land_plot_block_1180.svg";
import { ReactComponent as LandPlot_91_1181 } from "./land_plots/91/land_plot_block_1181.svg";
import { ReactComponent as LandPlot_91_1182 } from "./land_plots/91/land_plot_block_1182.svg";
import { ReactComponent as LandPlot_91_1183 } from "./land_plots/91/land_plot_block_1183.svg";
import { ReactComponent as LandPlot_91_1184 } from "./land_plots/91/land_plot_block_1184.svg";
import { ReactComponent as LandPlot_91_1185 } from "./land_plots/91/land_plot_block_1185.svg";
import { ReactComponent as LandPlot_91_1186 } from "./land_plots/91/land_plot_block_1186.svg";
import { ReactComponent as LandPlot_91_1187 } from "./land_plots/91/land_plot_block_1187.svg";
import { ReactComponent as LandPlot_91_1188 } from "./land_plots/91/land_plot_block_1188.svg";
import { ReactComponent as LandPlot_91_1189 } from "./land_plots/91/land_plot_block_1189.svg";
import { ReactComponent as LandPlot_91_1190 } from "./land_plots/91/land_plot_block_1190.svg";
import { ReactComponent as LandPlot_91_1191 } from "./land_plots/91/land_plot_block_1191.svg";
import { ReactComponent as LandPlot_91_1192 } from "./land_plots/91/land_plot_block_1192.svg";
import { ReactComponent as LandPlot_91_1193 } from "./land_plots/91/land_plot_block_1193.svg";
import { ReactComponent as LandPlot_91_1194 } from "./land_plots/91/land_plot_block_1194.svg";
import { ReactComponent as LandPlot_91_1195 } from "./land_plots/91/land_plot_block_1195.svg";
import { ReactComponent as LandPlot_91_1196 } from "./land_plots/91/land_plot_block_1196.svg";
import { ReactComponent as LandPlot_91_1197 } from "./land_plots/91/land_plot_block_1197.svg";
import { ReactComponent as LandPlot_91_1198 } from "./land_plots/91/land_plot_block_1198.svg";
import { ReactComponent as LandPlot_91_1199 } from "./land_plots/91/land_plot_block_1199.svg";
import { ReactComponent as LandPlot_91_1200 } from "./land_plots/91/land_plot_block_1200.svg";
import { ReactComponent as LandPlot_91_1201 } from "./land_plots/91/land_plot_block_1201.svg";
import { ReactComponent as LandPlot_91_1202 } from "./land_plots/91/land_plot_block_1202.svg";
import { ReactComponent as LandPlot_91_1203 } from "./land_plots/91/land_plot_block_1203.svg";
import { ReactComponent as LandPlot_91_1204 } from "./land_plots/91/land_plot_block_1204.svg";
import { ReactComponent as LandPlot_91_1205 } from "./land_plots/91/land_plot_block_1205.svg";
import { ReactComponent as LandPlot_91_1206 } from "./land_plots/91/land_plot_block_1206.svg";
import { ReactComponent as LandPlot_91_1207 } from "./land_plots/91/land_plot_block_1207.svg";
import { ReactComponent as LandPlot_91_1208 } from "./land_plots/91/land_plot_block_1208.svg";
import { ReactComponent as LandPlot_91_1209 } from "./land_plots/91/land_plot_block_1209.svg";
import { ReactComponent as LandPlot_91_1210 } from "./land_plots/91/land_plot_block_1210.svg";
import { ReactComponent as LandPlot_91_1211 } from "./land_plots/91/land_plot_block_1211.svg";
import { ReactComponent as LandPlot_91_1212 } from "./land_plots/91/land_plot_block_1212.svg";
import { ReactComponent as LandPlot_91_1213 } from "./land_plots/91/land_plot_block_1213.svg";
import { ReactComponent as LandPlot_91_1214 } from "./land_plots/91/land_plot_block_1214.svg";
import { ReactComponent as LandPlot_91_1215 } from "./land_plots/91/land_plot_block_1215.svg";
import { ReactComponent as LandPlot_91_1216 } from "./land_plots/91/land_plot_block_1216.svg";
import { ReactComponent as LandPlot_91_1217 } from "./land_plots/91/land_plot_block_1217.svg";
import { ReactComponent as LandPlot_91_1218 } from "./land_plots/91/land_plot_block_1218.svg";
import { ReactComponent as LandPlot_91_1219 } from "./land_plots/91/land_plot_block_1219.svg";
import { ReactComponent as LandPlot_91_1220 } from "./land_plots/91/land_plot_block_1220.svg";
import { ReactComponent as LandPlot_91_1221 } from "./land_plots/91/land_plot_block_1221.svg";
import { ReactComponent as LandPlot_91_1222 } from "./land_plots/91/land_plot_block_1222.svg";
import { ReactComponent as LandPlot_91_1223 } from "./land_plots/91/land_plot_block_1223.svg";
import { ReactComponent as LandPlot_91_1224 } from "./land_plots/91/land_plot_block_1224.svg";
import { ReactComponent as LandPlot_91_1225 } from "./land_plots/91/land_plot_block_1225.svg";
import { ReactComponent as LandPlot_91_1226 } from "./land_plots/91/land_plot_block_1226.svg";
import { ReactComponent as LandPlot_91_1227 } from "./land_plots/91/land_plot_block_1227.svg";
import { ReactComponent as LandPlot_91_1228 } from "./land_plots/91/land_plot_block_1228.svg";
import { ReactComponent as LandPlot_91_1229 } from "./land_plots/91/land_plot_block_1229.svg";
import { ReactComponent as LandPlot_91_1230 } from "./land_plots/91/land_plot_block_1230.svg";
import { ReactComponent as LandPlot_91_1231 } from "./land_plots/91/land_plot_block_1231.svg";
import { ReactComponent as LandPlot_91_1232 } from "./land_plots/91/land_plot_block_1232.svg";
import { ReactComponent as LandPlot_91_1233 } from "./land_plots/91/land_plot_block_1233.svg";
import { ReactComponent as LandPlot_91_1234 } from "./land_plots/91/land_plot_block_1234.svg";
import { ReactComponent as LandPlot_91_1235 } from "./land_plots/91/land_plot_block_1235.svg";
import { ReactComponent as LandPlot_91_1236 } from "./land_plots/91/land_plot_block_1236.svg";
import { ReactComponent as LandPlot_91_1237 } from "./land_plots/91/land_plot_block_1237.svg";
import { ReactComponent as LandPlot_91_1238 } from "./land_plots/91/land_plot_block_1238.svg";
import { ReactComponent as LandPlot_91_1239 } from "./land_plots/91/land_plot_block_1239.svg";
import { ReactComponent as LandPlot_91_1240 } from "./land_plots/91/land_plot_block_1240.svg";
import { ReactComponent as LandPlot_91_1241 } from "./land_plots/91/land_plot_block_1241.svg";
import { ReactComponent as LandPlot_91_1242 } from "./land_plots/91/land_plot_block_1242.svg";
import { ReactComponent as LandPlot_91_1243 } from "./land_plots/91/land_plot_block_1243.svg";
import { ReactComponent as LandPlot_91_1244 } from "./land_plots/91/land_plot_block_1244.svg";
import { ReactComponent as LandPlot_91_1245 } from "./land_plots/91/land_plot_block_1245.svg";
import { ReactComponent as LandPlot_91_1246 } from "./land_plots/91/land_plot_block_1246.svg";
import { ReactComponent as LandPlot_91_1247 } from "./land_plots/91/land_plot_block_1247.svg";
import { ReactComponent as LandPlot_91_1248 } from "./land_plots/91/land_plot_block_1248.svg";
import { ReactComponent as LandPlot_91_1249 } from "./land_plots/91/land_plot_block_1249.svg";
import { ReactComponent as LandPlot_91_1250 } from "./land_plots/91/land_plot_block_1250.svg";
import { ReactComponent as LandPlot_91_1251 } from "./land_plots/91/land_plot_block_1251.svg";
import { ReactComponent as LandPlot_91_1252 } from "./land_plots/91/land_plot_block_1252.svg";
import { ReactComponent as LandPlot_91_1253 } from "./land_plots/91/land_plot_block_1253.svg";
import { ReactComponent as LandPlot_91_1254 } from "./land_plots/91/land_plot_block_1254.svg";
import { ReactComponent as LandPlot_91_1255 } from "./land_plots/91/land_plot_block_1255.svg";
import { ReactComponent as LandPlot_91_1256 } from "./land_plots/91/land_plot_block_1256.svg";
import { ReactComponent as LandPlot_91_1257 } from "./land_plots/91/land_plot_block_1257.svg";
import { ReactComponent as LandPlot_91_1258 } from "./land_plots/91/land_plot_block_1258.svg";
import { ReactComponent as LandPlot_91_1259 } from "./land_plots/91/land_plot_block_1259.svg";
import { ReactComponent as LandPlot_91_1260 } from "./land_plots/91/land_plot_block_1260.svg";
import { ReactComponent as LandPlot_91_1261 } from "./land_plots/91/land_plot_block_1261.svg";
import { ReactComponent as LandPlot_91_1262 } from "./land_plots/91/land_plot_block_1262.svg";
import { ReactComponent as LandPlot_91_1263 } from "./land_plots/91/land_plot_block_1263.svg";
import { ReactComponent as LandPlot_91_1264 } from "./land_plots/91/land_plot_block_1264.svg";
import { ReactComponent as LandPlot_91_1265 } from "./land_plots/91/land_plot_block_1265.svg";
import { ReactComponent as LandPlot_91_1266 } from "./land_plots/91/land_plot_block_1266.svg";
import { ReactComponent as LandPlot_91_1267 } from "./land_plots/91/land_plot_block_1267.svg";
import { ReactComponent as LandPlot_91_1268 } from "./land_plots/91/land_plot_block_1268.svg";
import { ReactComponent as LandPlot_91_1269 } from "./land_plots/91/land_plot_block_1269.svg";
import { ReactComponent as LandPlot_91_1270 } from "./land_plots/91/land_plot_block_1270.svg";
import { ReactComponent as LandPlot_91_1271 } from "./land_plots/91/land_plot_block_1271.svg";
import { ReactComponent as LandPlot_91_1272 } from "./land_plots/91/land_plot_block_1272.svg";
import { ReactComponent as LandPlot_91_1273 } from "./land_plots/91/land_plot_block_1273.svg";
import { ReactComponent as LandPlot_91_1274 } from "./land_plots/91/land_plot_block_1274.svg";
import { ReactComponent as LandPlot_91_1275 } from "./land_plots/91/land_plot_block_1275.svg";
import { ReactComponent as LandPlot_91_1276 } from "./land_plots/91/land_plot_block_1276.svg";
import { ReactComponent as LandPlot_91_1277 } from "./land_plots/91/land_plot_block_1277.svg";
import { ReactComponent as LandPlot_91_1278 } from "./land_plots/91/land_plot_block_1278.svg";
import { ReactComponent as LandPlot_91_1279 } from "./land_plots/91/land_plot_block_1279.svg";
import { ReactComponent as LandPlot_91_1280 } from "./land_plots/91/land_plot_block_1280.svg";
import { ReactComponent as LandPlot_91_1281 } from "./land_plots/91/land_plot_block_1281.svg";
import { ReactComponent as LandPlot_91_1282 } from "./land_plots/91/land_plot_block_1282.svg";
import { ReactComponent as LandPlot_91_1283 } from "./land_plots/91/land_plot_block_1283.svg";
import { ReactComponent as LandPlot_91_1284 } from "./land_plots/91/land_plot_block_1284.svg";
import { ReactComponent as LandPlot_91_1285 } from "./land_plots/91/land_plot_block_1285.svg";
import { ReactComponent as LandPlot_91_1286 } from "./land_plots/91/land_plot_block_1286.svg";
import { ReactComponent as LandPlot_91_1287 } from "./land_plots/91/land_plot_block_1287.svg";
import { ReactComponent as LandPlot_91_1288 } from "./land_plots/91/land_plot_block_1288.svg";
import { ReactComponent as LandPlot_91_1289 } from "./land_plots/91/land_plot_block_1289.svg";
import { ReactComponent as LandPlot_91_1290 } from "./land_plots/91/land_plot_block_1290.svg";
import { ReactComponent as LandPlot_91_1291 } from "./land_plots/91/land_plot_block_1291.svg";
import { ReactComponent as LandPlot_91_1292 } from "./land_plots/91/land_plot_block_1292.svg";
import { ReactComponent as LandPlot_91_1293 } from "./land_plots/91/land_plot_block_1293.svg";
import { ReactComponent as LandPlot_91_1294 } from "./land_plots/91/land_plot_block_1294.svg";
import { ReactComponent as LandPlot_91_1295 } from "./land_plots/91/land_plot_block_1295.svg";
import { ReactComponent as LandPlot_91_1296 } from "./land_plots/91/land_plot_block_1296.svg";
import { ReactComponent as LandPlot_91_1297 } from "./land_plots/91/land_plot_block_1297.svg";
import { ReactComponent as LandPlot_91_1298 } from "./land_plots/91/land_plot_block_1298.svg";
import { ReactComponent as LandPlot_91_1299 } from "./land_plots/91/land_plot_block_1299.svg";
import { ReactComponent as LandPlot_91_1300 } from "./land_plots/91/land_plot_block_1300.svg";
import { ReactComponent as LandPlot_91_1301 } from "./land_plots/91/land_plot_block_1301.svg";
import { ReactComponent as LandPlot_91_1302 } from "./land_plots/91/land_plot_block_1302.svg";
import { ReactComponent as LandPlot_91_1303 } from "./land_plots/91/land_plot_block_1303.svg";
import { ReactComponent as LandPlot_91_1304 } from "./land_plots/91/land_plot_block_1304.svg";
import { ReactComponent as LandPlot_91_1305 } from "./land_plots/91/land_plot_block_1305.svg";
import { ReactComponent as LandPlot_91_1306 } from "./land_plots/91/land_plot_block_1306.svg";
import { ReactComponent as LandPlot_91_1307 } from "./land_plots/91/land_plot_block_1307.svg";
import { ReactComponent as LandPlot_91_1308 } from "./land_plots/91/land_plot_block_1308.svg";
import { ReactComponent as LandPlot_91_1309 } from "./land_plots/91/land_plot_block_1309.svg";
import { ReactComponent as LandPlot_91_1310 } from "./land_plots/91/land_plot_block_1310.svg";
import { ReactComponent as LandPlot_91_1311 } from "./land_plots/91/land_plot_block_1311.svg";
import { ReactComponent as LandPlot_91_1312 } from "./land_plots/91/land_plot_block_1312.svg";
import { ReactComponent as LandPlot_91_1313 } from "./land_plots/91/land_plot_block_1313.svg";
import { ReactComponent as LandPlot_91_1314 } from "./land_plots/91/land_plot_block_1314.svg";
import { ReactComponent as LandPlot_91_1315 } from "./land_plots/91/land_plot_block_1315.svg";
import { ReactComponent as LandPlot_91_1316 } from "./land_plots/91/land_plot_block_1316.svg";
import { ReactComponent as LandPlot_91_1317 } from "./land_plots/91/land_plot_block_1317.svg";
import { ReactComponent as LandPlot_91_1318 } from "./land_plots/91/land_plot_block_1318.svg";
import { ReactComponent as LandPlot_91_1319 } from "./land_plots/91/land_plot_block_1319.svg";
import { ReactComponent as LandPlot_91_1320 } from "./land_plots/91/land_plot_block_1320.svg";
import { ReactComponent as LandPlot_91_1321 } from "./land_plots/91/land_plot_block_1321.svg";
import { ReactComponent as LandPlot_91_1322 } from "./land_plots/91/land_plot_block_1322.svg";
import { ReactComponent as LandPlot_91_1323 } from "./land_plots/91/land_plot_block_1323.svg";
import { ReactComponent as LandPlot_91_1324 } from "./land_plots/91/land_plot_block_1324.svg";
import { ReactComponent as LandPlot_91_1325 } from "./land_plots/91/land_plot_block_1325.svg";
import { ReactComponent as LandPlot_91_1326 } from "./land_plots/91/land_plot_block_1326.svg";
import { ReactComponent as LandPlot_91_1327 } from "./land_plots/91/land_plot_block_1327.svg";
import { ReactComponent as LandPlot_91_1328 } from "./land_plots/91/land_plot_block_1328.svg";
import { ReactComponent as LandPlot_91_1329 } from "./land_plots/91/land_plot_block_1329.svg";
import { ReactComponent as LandPlot_91_1330 } from "./land_plots/91/land_plot_block_1330.svg";
import { ReactComponent as LandPlot_91_1331 } from "./land_plots/91/land_plot_block_1331.svg";
import { ReactComponent as LandPlot_91_1332 } from "./land_plots/91/land_plot_block_1332.svg";
import { ReactComponent as LandPlot_91_1333 } from "./land_plots/91/land_plot_block_1333.svg";
import { ReactComponent as LandPlot_91_1334 } from "./land_plots/91/land_plot_block_1334.svg";
import { ReactComponent as LandPlot_91_1335 } from "./land_plots/91/land_plot_block_1335.svg";
import { ReactComponent as LandPlot_91_1336 } from "./land_plots/91/land_plot_block_1336.svg";
import { ReactComponent as LandPlot_91_1337 } from "./land_plots/91/land_plot_block_1337.svg";
import { ReactComponent as LandPlot_91_1338 } from "./land_plots/91/land_plot_block_1338.svg";
import { ReactComponent as LandPlot_91_1339 } from "./land_plots/91/land_plot_block_1339.svg";
import { ReactComponent as LandPlot_91_1340 } from "./land_plots/91/land_plot_block_1340.svg";
import { ReactComponent as LandPlot_91_1341 } from "./land_plots/91/land_plot_block_1341.svg";
import { ReactComponent as LandPlot_91_1342 } from "./land_plots/91/land_plot_block_1342.svg";
import { ReactComponent as LandPlot_91_1343 } from "./land_plots/91/land_plot_block_1343.svg";
import { ReactComponent as LandPlot_91_1344 } from "./land_plots/91/land_plot_block_1344.svg";
import { ReactComponent as LandPlot_91_1345 } from "./land_plots/91/land_plot_block_1345.svg";
import { ReactComponent as LandPlot_91_1346 } from "./land_plots/91/land_plot_block_1346.svg";
import { ReactComponent as LandPlot_91_1347 } from "./land_plots/91/land_plot_block_1347.svg";
import { ReactComponent as LandPlot_91_1348 } from "./land_plots/91/land_plot_block_1348.svg";
import { ReactComponent as LandPlot_91_1349 } from "./land_plots/91/land_plot_block_1349.svg";
import { ReactComponent as LandPlot_91_1350 } from "./land_plots/91/land_plot_block_1350.svg";
import { ReactComponent as LandPlot_91_1351 } from "./land_plots/91/land_plot_block_1351.svg";
import { ReactComponent as LandPlot_91_1352 } from "./land_plots/91/land_plot_block_1352.svg";
import { ReactComponent as LandPlot_91_1353 } from "./land_plots/91/land_plot_block_1353.svg";
import { ReactComponent as LandPlot_91_1354 } from "./land_plots/91/land_plot_block_1354.svg";
import { ReactComponent as LandPlot_91_1355 } from "./land_plots/91/land_plot_block_1355.svg";
import { ReactComponent as LandPlot_91_1356 } from "./land_plots/91/land_plot_block_1356.svg";
import { ReactComponent as LandPlot_91_1357 } from "./land_plots/91/land_plot_block_1357.svg";
import { ReactComponent as LandPlot_91_1358 } from "./land_plots/91/land_plot_block_1358.svg";
import { ReactComponent as LandPlot_91_1359 } from "./land_plots/91/land_plot_block_1359.svg";
import { ReactComponent as LandPlot_91_1360 } from "./land_plots/91/land_plot_block_1360.svg";
import { ReactComponent as LandPlot_91_1361 } from "./land_plots/91/land_plot_block_1361.svg";
import { ReactComponent as LandPlot_91_1362 } from "./land_plots/91/land_plot_block_1362.svg";
import { ReactComponent as LandPlot_91_1363 } from "./land_plots/91/land_plot_block_1363.svg";
import { ReactComponent as LandPlot_91_1364 } from "./land_plots/91/land_plot_block_1364.svg";
import { ReactComponent as LandPlot_91_1365 } from "./land_plots/91/land_plot_block_1365.svg";
import { ReactComponent as LandPlot_91_1366 } from "./land_plots/91/land_plot_block_1366.svg";
import { ReactComponent as LandPlot_91_1367 } from "./land_plots/91/land_plot_block_1367.svg";
import { ReactComponent as LandPlot_91_1368 } from "./land_plots/91/land_plot_block_1368.svg";
import { ReactComponent as LandPlot_91_1369 } from "./land_plots/91/land_plot_block_1369.svg";
import { ReactComponent as LandPlot_91_1370 } from "./land_plots/91/land_plot_block_1370.svg";
import { ReactComponent as LandPlot_91_1371 } from "./land_plots/91/land_plot_block_1371.svg";
import { ReactComponent as LandPlot_91_1372 } from "./land_plots/91/land_plot_block_1372.svg";
import { ReactComponent as LandPlot_91_1373 } from "./land_plots/91/land_plot_block_1373.svg";
import { ReactComponent as LandPlot_91_1374 } from "./land_plots/91/land_plot_block_1374.svg";
import { ReactComponent as LandPlot_91_1375 } from "./land_plots/91/land_plot_block_1375.svg";
import { ReactComponent as LandPlot_91_1376 } from "./land_plots/91/land_plot_block_1376.svg";
import { ReactComponent as LandPlot_91_1377 } from "./land_plots/91/land_plot_block_1377.svg";
import { ReactComponent as LandPlot_91_1378 } from "./land_plots/91/land_plot_block_1378.svg";
import { ReactComponent as LandPlot_91_1379 } from "./land_plots/91/land_plot_block_1379.svg";
import { ReactComponent as LandPlot_91_1380 } from "./land_plots/91/land_plot_block_1380.svg";
import { ReactComponent as LandPlot_91_1381 } from "./land_plots/91/land_plot_block_1381.svg";
import { ReactComponent as LandPlot_91_1382 } from "./land_plots/91/land_plot_block_1382.svg";
import { ReactComponent as LandPlot_91_1383 } from "./land_plots/91/land_plot_block_1383.svg";
import { ReactComponent as LandPlot_91_1384 } from "./land_plots/91/land_plot_block_1384.svg";
import { ReactComponent as LandPlot_91_1385 } from "./land_plots/91/land_plot_block_1385.svg";
import { ReactComponent as LandPlot_91_1386 } from "./land_plots/91/land_plot_block_1386.svg";
import { ReactComponent as LandPlot_91_1387 } from "./land_plots/91/land_plot_block_1387.svg";
import { ReactComponent as LandPlot_91_1388 } from "./land_plots/91/land_plot_block_1388.svg";
import { ReactComponent as LandPlot_91_1389 } from "./land_plots/91/land_plot_block_1389.svg";
import { ReactComponent as LandPlot_91_1390 } from "./land_plots/91/land_plot_block_1390.svg";
import { ReactComponent as LandPlot_91_1391 } from "./land_plots/91/land_plot_block_1391.svg";
import { ReactComponent as LandPlot_91_1392 } from "./land_plots/91/land_plot_block_1392.svg";
import { ReactComponent as LandPlot_91_1393 } from "./land_plots/91/land_plot_block_1393.svg";
import { ReactComponent as LandPlot_91_1394 } from "./land_plots/91/land_plot_block_1394.svg";
import { ReactComponent as LandPlot_91_1395 } from "./land_plots/91/land_plot_block_1395.svg";
import { ReactComponent as LandPlot_91_1396 } from "./land_plots/91/land_plot_block_1396.svg";
import { ReactComponent as LandPlot_91_1397 } from "./land_plots/91/land_plot_block_1397.svg";
import { ReactComponent as LandPlot_91_1398 } from "./land_plots/91/land_plot_block_1398.svg";
import { ReactComponent as LandPlot_91_1399 } from "./land_plots/91/land_plot_block_1399.svg";
import { ReactComponent as LandPlot_91_1400 } from "./land_plots/91/land_plot_block_1400.svg";
import { ReactComponent as LandPlot_91_1401 } from "./land_plots/91/land_plot_block_1401.svg";
import { ReactComponent as LandPlot_91_1402 } from "./land_plots/91/land_plot_block_1402.svg";
import { ReactComponent as LandPlot_91_1403 } from "./land_plots/91/land_plot_block_1403.svg";
import { ReactComponent as LandPlot_91_1404 } from "./land_plots/91/land_plot_block_1404.svg";
import { ReactComponent as LandPlot_91_1405 } from "./land_plots/91/land_plot_block_1405.svg";
import { ReactComponent as LandPlot_91_1406 } from "./land_plots/91/land_plot_block_1406.svg";
import { ReactComponent as LandPlot_91_1407 } from "./land_plots/91/land_plot_block_1407.svg";
import { ReactComponent as LandPlot_91_1408 } from "./land_plots/91/land_plot_block_1408.svg";
import { ReactComponent as LandPlot_91_1409 } from "./land_plots/91/land_plot_block_1409.svg";
import { ReactComponent as LandPlot_91_1410 } from "./land_plots/91/land_plot_block_1410.svg";
import { ReactComponent as LandPlot_91_1411 } from "./land_plots/91/land_plot_block_1411.svg";
import { ReactComponent as LandPlot_91_1412 } from "./land_plots/91/land_plot_block_1412.svg";
import { ReactComponent as LandPlot_91_1413 } from "./land_plots/91/land_plot_block_1413.svg";
import { ReactComponent as LandPlot_91_1414 } from "./land_plots/91/land_plot_block_1414.svg";
import { ReactComponent as LandPlot_91_1415 } from "./land_plots/91/land_plot_block_1415.svg";
import { ReactComponent as LandPlot_91_1416 } from "./land_plots/91/land_plot_block_1416.svg";
import { ReactComponent as LandPlot_91_1417 } from "./land_plots/91/land_plot_block_1417.svg";
import { ReactComponent as LandPlot_91_1418 } from "./land_plots/91/land_plot_block_1418.svg";
import { ReactComponent as LandPlot_91_1419 } from "./land_plots/91/land_plot_block_1419.svg";
import { ReactComponent as LandPlot_91_1420 } from "./land_plots/91/land_plot_block_1420.svg";
import { ReactComponent as LandPlot_91_1421 } from "./land_plots/91/land_plot_block_1421.svg";
import { ReactComponent as LandPlot_91_1422 } from "./land_plots/91/land_plot_block_1422.svg";
import { ReactComponent as LandPlot_91_1423 } from "./land_plots/91/land_plot_block_1423.svg";
import { ReactComponent as LandPlot_91_1424 } from "./land_plots/91/land_plot_block_1424.svg";
import { ReactComponent as LandPlot_91_1425 } from "./land_plots/91/land_plot_block_1425.svg";
import { ReactComponent as LandPlot_91_1426 } from "./land_plots/91/land_plot_block_1426.svg";
import { ReactComponent as LandPlot_91_1427 } from "./land_plots/91/land_plot_block_1427.svg";
import { ReactComponent as LandPlot_91_1428 } from "./land_plots/91/land_plot_block_1428.svg";
import { ReactComponent as LandPlot_91_1429 } from "./land_plots/91/land_plot_block_1429.svg";
import { ReactComponent as LandPlot_91_1430 } from "./land_plots/91/land_plot_block_1430.svg";
import { ReactComponent as LandPlot_91_1431 } from "./land_plots/91/land_plot_block_1431.svg";
import { ReactComponent as LandPlot_91_1432 } from "./land_plots/91/land_plot_block_1432.svg";
import { ReactComponent as LandPlot_91_1433 } from "./land_plots/91/land_plot_block_1433.svg";
import { ReactComponent as LandPlot_91_1434 } from "./land_plots/91/land_plot_block_1434.svg";
import { ReactComponent as LandPlot_91_1435 } from "./land_plots/91/land_plot_block_1435.svg";
import { ReactComponent as LandPlot_91_1436 } from "./land_plots/91/land_plot_block_1436.svg";
import { ReactComponent as LandPlot_91_1437 } from "./land_plots/91/land_plot_block_1437.svg";
import { ReactComponent as LandPlot_91_1438 } from "./land_plots/91/land_plot_block_1438.svg";
import { ReactComponent as LandPlot_91_1439 } from "./land_plots/91/land_plot_block_1439.svg";
import { ReactComponent as LandPlot_91_1440 } from "./land_plots/91/land_plot_block_1440.svg";
import { ReactComponent as LandPlot_91_1441 } from "./land_plots/91/land_plot_block_1441.svg";
import { ReactComponent as LandPlot_91_1442 } from "./land_plots/91/land_plot_block_1442.svg";
import { ReactComponent as LandPlot_91_1443 } from "./land_plots/91/land_plot_block_1443.svg";
import { ReactComponent as LandPlot_91_1444 } from "./land_plots/91/land_plot_block_1444.svg";
import { ReactComponent as LandPlot_91_1445 } from "./land_plots/91/land_plot_block_1445.svg";
import { ReactComponent as LandPlot_91_1446 } from "./land_plots/91/land_plot_block_1446.svg";
import { ReactComponent as LandPlot_91_1447 } from "./land_plots/91/land_plot_block_1447.svg";
import { ReactComponent as LandPlot_91_1448 } from "./land_plots/91/land_plot_block_1448.svg";
import { ReactComponent as LandPlot_91_1449 } from "./land_plots/91/land_plot_block_1449.svg";
import { ReactComponent as LandPlot_91_1450 } from "./land_plots/91/land_plot_block_1450.svg";
import { ReactComponent as LandPlot_91_1451 } from "./land_plots/91/land_plot_block_1451.svg";
import { ReactComponent as LandPlot_91_1452 } from "./land_plots/91/land_plot_block_1452.svg";
import { ReactComponent as LandPlot_91_1453 } from "./land_plots/91/land_plot_block_1453.svg";
import { ReactComponent as LandPlot_91_1454 } from "./land_plots/91/land_plot_block_1454.svg";
import { ReactComponent as LandPlot_91_1455 } from "./land_plots/91/land_plot_block_1455.svg";
import { ReactComponent as LandPlot_91_1456 } from "./land_plots/91/land_plot_block_1456.svg";
import { ReactComponent as LandPlot_91_1457 } from "./land_plots/91/land_plot_block_1457.svg";
import { ReactComponent as LandPlot_91_1458 } from "./land_plots/91/land_plot_block_1458.svg";
import { ReactComponent as LandPlot_91_1459 } from "./land_plots/91/land_plot_block_1459.svg";
import { ReactComponent as LandPlot_91_1460 } from "./land_plots/91/land_plot_block_1460.svg";
import { ReactComponent as LandPlot_91_1461 } from "./land_plots/91/land_plot_block_1461.svg";
import { ReactComponent as LandPlot_91_1462 } from "./land_plots/91/land_plot_block_1462.svg";
import { ReactComponent as LandPlot_91_1463 } from "./land_plots/91/land_plot_block_1463.svg";
import { ReactComponent as LandPlot_91_1464 } from "./land_plots/91/land_plot_block_1464.svg";
import { ReactComponent as LandPlot_91_1465 } from "./land_plots/91/land_plot_block_1465.svg";
import { ReactComponent as LandPlot_91_1466 } from "./land_plots/91/land_plot_block_1466.svg";
import { ReactComponent as LandPlot_91_1467 } from "./land_plots/91/land_plot_block_1467.svg";
import { ReactComponent as LandPlot_91_1468 } from "./land_plots/91/land_plot_block_1468.svg";
import { ReactComponent as LandPlot_91_1469 } from "./land_plots/91/land_plot_block_1469.svg";
import { ReactComponent as LandPlot_91_1470 } from "./land_plots/91/land_plot_block_1470.svg";
import { ReactComponent as LandPlot_91_1471 } from "./land_plots/91/land_plot_block_1471.svg";
import { ReactComponent as LandPlot_91_1472 } from "./land_plots/91/land_plot_block_1472.svg";
import { ReactComponent as LandPlot_91_1473 } from "./land_plots/91/land_plot_block_1473.svg";
import { ReactComponent as LandPlot_91_1474 } from "./land_plots/91/land_plot_block_1474.svg";
import { ReactComponent as LandPlot_91_1475 } from "./land_plots/91/land_plot_block_1475.svg";
import { ReactComponent as LandPlot_91_1476 } from "./land_plots/91/land_plot_block_1476.svg";
import { ReactComponent as LandPlot_91_1477 } from "./land_plots/91/land_plot_block_1477.svg";
import { ReactComponent as LandPlot_91_1478 } from "./land_plots/91/land_plot_block_1478.svg";
import { ReactComponent as LandPlot_91_1479 } from "./land_plots/91/land_plot_block_1479.svg";
import { ReactComponent as LandPlot_91_1480 } from "./land_plots/91/land_plot_block_1480.svg";
import { ReactComponent as LandPlot_91_1481 } from "./land_plots/91/land_plot_block_1481.svg";
import { ReactComponent as LandPlot_91_1482 } from "./land_plots/91/land_plot_block_1482.svg";
import { ReactComponent as LandPlot_91_1483 } from "./land_plots/91/land_plot_block_1483.svg";
import { ReactComponent as LandPlot_91_1484 } from "./land_plots/91/land_plot_block_1484.svg";
import { ReactComponent as LandPlot_91_1485 } from "./land_plots/91/land_plot_block_1485.svg";
import { ReactComponent as LandPlot_91_1486 } from "./land_plots/91/land_plot_block_1486.svg";
import { ReactComponent as LandPlot_91_1487 } from "./land_plots/91/land_plot_block_1487.svg";
import { ReactComponent as LandPlot_91_1488 } from "./land_plots/91/land_plot_block_1488.svg";
import { ReactComponent as LandPlot_91_1489 } from "./land_plots/91/land_plot_block_1489.svg";
import { ReactComponent as LandPlot_91_1490 } from "./land_plots/91/land_plot_block_1490.svg";
import { ReactComponent as LandPlot_91_1491 } from "./land_plots/91/land_plot_block_1491.svg";
import { ReactComponent as LandPlot_91_1492 } from "./land_plots/91/land_plot_block_1492.svg";
import { ReactComponent as LandPlot_91_1493 } from "./land_plots/91/land_plot_block_1493.svg";
import { ReactComponent as LandPlot_91_1494 } from "./land_plots/91/land_plot_block_1494.svg";
import { ReactComponent as LandPlot_91_1495 } from "./land_plots/91/land_plot_block_1495.svg";
import { ReactComponent as LandPlot_91_1496 } from "./land_plots/91/land_plot_block_1496.svg";
import { ReactComponent as LandPlot_91_1497 } from "./land_plots/91/land_plot_block_1497.svg";
import { ReactComponent as LandPlot_91_1498 } from "./land_plots/91/land_plot_block_1498.svg";
import { ReactComponent as LandPlot_91_1499 } from "./land_plots/91/land_plot_block_1499.svg";
import { ReactComponent as LandPlot_91_1500 } from "./land_plots/91/land_plot_block_1500.svg";
import { ReactComponent as LandPlot_91_1501 } from "./land_plots/91/land_plot_block_1501.svg";
import { ReactComponent as LandPlot_91_1502 } from "./land_plots/91/land_plot_block_1502.svg";
import { ReactComponent as LandPlot_91_1503 } from "./land_plots/91/land_plot_block_1503.svg";
import { ReactComponent as LandPlot_91_1504 } from "./land_plots/91/land_plot_block_1504.svg";
import { ReactComponent as LandPlot_91_1505 } from "./land_plots/91/land_plot_block_1505.svg";
import { ReactComponent as LandPlot_91_1506 } from "./land_plots/91/land_plot_block_1506.svg";
import { ReactComponent as LandPlot_91_1507 } from "./land_plots/91/land_plot_block_1507.svg";
import { ReactComponent as LandPlot_91_1508 } from "./land_plots/91/land_plot_block_1508.svg";
import { ReactComponent as LandPlot_91_1509 } from "./land_plots/91/land_plot_block_1509.svg";
import { ReactComponent as LandPlot_91_1510 } from "./land_plots/91/land_plot_block_1510.svg";
import { ReactComponent as LandPlot_91_1511 } from "./land_plots/91/land_plot_block_1511.svg";
import { ReactComponent as LandPlot_91_1512 } from "./land_plots/91/land_plot_block_1512.svg";
import { ReactComponent as LandPlot_91_1513 } from "./land_plots/91/land_plot_block_1513.svg";
import { ReactComponent as LandPlot_91_1514 } from "./land_plots/91/land_plot_block_1514.svg";
import { ReactComponent as LandPlot_91_1515 } from "./land_plots/91/land_plot_block_1515.svg";
import { ReactComponent as LandPlot_91_1516 } from "./land_plots/91/land_plot_block_1516.svg";
import { ReactComponent as LandPlot_91_1517 } from "./land_plots/91/land_plot_block_1517.svg";
import { ReactComponent as LandPlot_91_1518 } from "./land_plots/91/land_plot_block_1518.svg";
import { ReactComponent as LandPlot_91_1519 } from "./land_plots/91/land_plot_block_1519.svg";
import { ReactComponent as LandPlot_91_1520 } from "./land_plots/91/land_plot_block_1520.svg";
import { ReactComponent as LandPlot_91_1521 } from "./land_plots/91/land_plot_block_1521.svg";
import { ReactComponent as LandPlot_91_1522 } from "./land_plots/91/land_plot_block_1522.svg";
import { ReactComponent as LandPlot_91_1523 } from "./land_plots/91/land_plot_block_1523.svg";
import { ReactComponent as LandPlot_91_1524 } from "./land_plots/91/land_plot_block_1524.svg";
import { ReactComponent as LandPlot_91_1525 } from "./land_plots/91/land_plot_block_1525.svg";
import { ReactComponent as LandPlot_91_1526 } from "./land_plots/91/land_plot_block_1526.svg";
import { ReactComponent as LandPlot_91_1527 } from "./land_plots/91/land_plot_block_1527.svg";
import { ReactComponent as LandPlot_91_1528 } from "./land_plots/91/land_plot_block_1528.svg";
import { ReactComponent as LandPlot_91_1529 } from "./land_plots/91/land_plot_block_1529.svg";
import { ReactComponent as LandPlot_91_1530 } from "./land_plots/91/land_plot_block_1530.svg";
import { ReactComponent as LandPlot_91_1531 } from "./land_plots/91/land_plot_block_1531.svg";
import { ReactComponent as LandPlot_91_1532 } from "./land_plots/91/land_plot_block_1532.svg";
import { ReactComponent as LandPlot_91_1533 } from "./land_plots/91/land_plot_block_1533.svg";
import { ReactComponent as LandPlot_91_1534 } from "./land_plots/91/land_plot_block_1534.svg";
import { ReactComponent as LandPlot_91_1535 } from "./land_plots/91/land_plot_block_1535.svg";
import { ReactComponent as LandPlot_91_1536 } from "./land_plots/91/land_plot_block_1536.svg";
import { ReactComponent as LandPlot_91_1537 } from "./land_plots/91/land_plot_block_1537.svg";
import { ReactComponent as LandPlot_91_1538 } from "./land_plots/91/land_plot_block_1538.svg";
import { ReactComponent as LandPlot_91_1539 } from "./land_plots/91/land_plot_block_1539.svg";
import { ReactComponent as LandPlot_91_1540 } from "./land_plots/91/land_plot_block_1540.svg";
import { ReactComponent as LandPlot_91_1541 } from "./land_plots/91/land_plot_block_1541.svg";
import { ReactComponent as LandPlot_91_1542 } from "./land_plots/91/land_plot_block_1542.svg";
import { ReactComponent as LandPlot_91_1543 } from "./land_plots/91/land_plot_block_1543.svg";
import { ReactComponent as LandPlot_91_1544 } from "./land_plots/91/land_plot_block_1544.svg";
import { ReactComponent as LandPlot_91_1545 } from "./land_plots/91/land_plot_block_1545.svg";
import { ReactComponent as LandPlot_91_1546 } from "./land_plots/91/land_plot_block_1546.svg";
import { ReactComponent as LandPlot_91_1547 } from "./land_plots/91/land_plot_block_1547.svg";
import { ReactComponent as LandPlot_91_1548 } from "./land_plots/91/land_plot_block_1548.svg";
import { ReactComponent as LandPlot_91_1549 } from "./land_plots/91/land_plot_block_1549.svg";
import { ReactComponent as LandPlot_91_1550 } from "./land_plots/91/land_plot_block_1550.svg";
import { ReactComponent as LandPlot_91_1551 } from "./land_plots/91/land_plot_block_1551.svg";
import { ReactComponent as LandPlot_91_1552 } from "./land_plots/91/land_plot_block_1552.svg";
import { ReactComponent as LandPlot_91_1553 } from "./land_plots/91/land_plot_block_1553.svg";
import { ReactComponent as LandPlot_91_1554 } from "./land_plots/91/land_plot_block_1554.svg";
import { ReactComponent as LandPlot_91_1555 } from "./land_plots/91/land_plot_block_1555.svg";
import { ReactComponent as LandPlot_91_1556 } from "./land_plots/91/land_plot_block_1556.svg";
import { ReactComponent as LandPlot_91_1557 } from "./land_plots/91/land_plot_block_1557.svg";
import { ReactComponent as LandPlot_91_1558 } from "./land_plots/91/land_plot_block_1558.svg";
import { ReactComponent as LandPlot_91_1559 } from "./land_plots/91/land_plot_block_1559.svg";
import { ReactComponent as LandPlot_91_1560 } from "./land_plots/91/land_plot_block_1560.svg";
import { ReactComponent as LandPlot_91_1561 } from "./land_plots/91/land_plot_block_1561.svg";
import { ReactComponent as LandPlot_91_1562 } from "./land_plots/91/land_plot_block_1562.svg";
import { ReactComponent as LandPlot_91_1563 } from "./land_plots/91/land_plot_block_1563.svg";
import { ReactComponent as LandPlot_91_1564 } from "./land_plots/91/land_plot_block_1564.svg";
import { ReactComponent as LandPlot_91_1565 } from "./land_plots/91/land_plot_block_1565.svg";
import { ReactComponent as LandPlot_91_1566 } from "./land_plots/91/land_plot_block_1566.svg";
import { ReactComponent as LandPlot_91_1567 } from "./land_plots/91/land_plot_block_1567.svg";
import { ReactComponent as LandPlot_91_1568 } from "./land_plots/91/land_plot_block_1568.svg";
import { ReactComponent as LandPlot_91_1569 } from "./land_plots/91/land_plot_block_1569.svg";
import { ReactComponent as LandPlot_91_1570 } from "./land_plots/91/land_plot_block_1570.svg";
import { ReactComponent as LandPlot_91_1571 } from "./land_plots/91/land_plot_block_1571.svg";
import { ReactComponent as LandPlot_91_1572 } from "./land_plots/91/land_plot_block_1572.svg";
import { ReactComponent as LandPlot_91_1573 } from "./land_plots/91/land_plot_block_1573.svg";
import { ReactComponent as LandPlot_91_1574 } from "./land_plots/91/land_plot_block_1574.svg";
import { ReactComponent as LandPlot_91_1575 } from "./land_plots/91/land_plot_block_1575.svg";
import { ReactComponent as LandPlot_91_1576 } from "./land_plots/91/land_plot_block_1576.svg";
import { ReactComponent as LandPlot_91_1577 } from "./land_plots/91/land_plot_block_1577.svg";
import { ReactComponent as LandPlot_91_1578 } from "./land_plots/91/land_plot_block_1578.svg";
import { ReactComponent as LandPlot_91_1579 } from "./land_plots/91/land_plot_block_1579.svg";
import { ReactComponent as LandPlot_91_1580 } from "./land_plots/91/land_plot_block_1580.svg";
import { ReactComponent as LandPlot_91_1581 } from "./land_plots/91/land_plot_block_1581.svg";
import { ReactComponent as LandPlot_91_1582 } from "./land_plots/91/land_plot_block_1582.svg";
import { ReactComponent as LandPlot_91_1583 } from "./land_plots/91/land_plot_block_1583.svg";
import { ReactComponent as LandPlot_91_1584 } from "./land_plots/91/land_plot_block_1584.svg";
import { ReactComponent as LandPlot_91_1585 } from "./land_plots/91/land_plot_block_1585.svg";
import { ReactComponent as LandPlot_91_1586 } from "./land_plots/91/land_plot_block_1586.svg";
import { ReactComponent as LandPlot_91_1587 } from "./land_plots/91/land_plot_block_1587.svg";
import { ReactComponent as LandPlot_91_1588 } from "./land_plots/91/land_plot_block_1588.svg";
import { ReactComponent as LandPlot_91_1589 } from "./land_plots/91/land_plot_block_1589.svg";
import { ReactComponent as LandPlot_91_1590 } from "./land_plots/91/land_plot_block_1590.svg";
import { ReactComponent as LandPlot_91_1591 } from "./land_plots/91/land_plot_block_1591.svg";
import { ReactComponent as LandPlot_91_1592 } from "./land_plots/91/land_plot_block_1592.svg";
import { ReactComponent as LandPlot_91_1593 } from "./land_plots/91/land_plot_block_1593.svg";
import { ReactComponent as LandPlot_91_1594 } from "./land_plots/91/land_plot_block_1594.svg";
import { ReactComponent as LandPlot_91_1595 } from "./land_plots/91/land_plot_block_1595.svg";
import { ReactComponent as LandPlot_91_1596 } from "./land_plots/91/land_plot_block_1596.svg";
import { ReactComponent as LandPlot_91_1597 } from "./land_plots/91/land_plot_block_1597.svg";
import { ReactComponent as LandPlot_91_1598 } from "./land_plots/91/land_plot_block_1598.svg";
import { ReactComponent as LandPlot_91_1599 } from "./land_plots/91/land_plot_block_1599.svg";
import { ReactComponent as LandPlot_91_1600 } from "./land_plots/91/land_plot_block_1600.svg";
import { ReactComponent as LandPlot_91_1601 } from "./land_plots/91/land_plot_block_1601.svg";
import { ReactComponent as LandPlot_91_1602 } from "./land_plots/91/land_plot_block_1602.svg";
import { ReactComponent as LandPlot_91_1603 } from "./land_plots/91/land_plot_block_1603.svg";
import { ReactComponent as LandPlot_91_1604 } from "./land_plots/91/land_plot_block_1604.svg";
import { ReactComponent as LandPlot_91_1605 } from "./land_plots/91/land_plot_block_1605.svg";
import { ReactComponent as LandPlot_91_1606 } from "./land_plots/91/land_plot_block_1606.svg";
import { ReactComponent as LandPlot_91_1607 } from "./land_plots/91/land_plot_block_1607.svg";
import { ReactComponent as LandPlot_91_1608 } from "./land_plots/91/land_plot_block_1608.svg";
import { ReactComponent as LandPlot_91_1609 } from "./land_plots/91/land_plot_block_1609.svg";
import { ReactComponent as LandPlot_91_1610 } from "./land_plots/91/land_plot_block_1610.svg";
import { ReactComponent as LandPlot_91_1611 } from "./land_plots/91/land_plot_block_1611.svg";
import { ReactComponent as LandPlot_91_1612 } from "./land_plots/91/land_plot_block_1612.svg";
import { ReactComponent as LandPlot_91_1613 } from "./land_plots/91/land_plot_block_1613.svg";
import { ReactComponent as LandPlot_91_1614 } from "./land_plots/91/land_plot_block_1614.svg";
import { ReactComponent as LandPlot_91_1615 } from "./land_plots/91/land_plot_block_1615.svg";
import { ReactComponent as LandPlot_91_1616 } from "./land_plots/91/land_plot_block_1616.svg";
import { ReactComponent as LandPlot_91_1617 } from "./land_plots/91/land_plot_block_1617.svg";
import { ReactComponent as LandPlot_91_1618 } from "./land_plots/91/land_plot_block_1618.svg";
import { ReactComponent as LandPlot_91_1619 } from "./land_plots/91/land_plot_block_1619.svg";
import { ReactComponent as LandPlot_91_1620 } from "./land_plots/91/land_plot_block_1620.svg";
import { ReactComponent as LandPlot_91_1621 } from "./land_plots/91/land_plot_block_1621.svg";
import { ReactComponent as LandPlot_91_1622 } from "./land_plots/91/land_plot_block_1622.svg";
import { ReactComponent as LandPlot_91_1623 } from "./land_plots/91/land_plot_block_1623.svg";
import { ReactComponent as LandPlot_91_1624 } from "./land_plots/91/land_plot_block_1624.svg";
import { ReactComponent as LandPlot_91_1625 } from "./land_plots/91/land_plot_block_1625.svg";
import { ReactComponent as LandPlot_91_1626 } from "./land_plots/91/land_plot_block_1626.svg";
import { ReactComponent as LandPlot_91_1627 } from "./land_plots/91/land_plot_block_1627.svg";

import { ReactComponent as Church_2_1 } from "../../../../static/images/contents/zone-2/icons/2/church_1.svg";
import { ReactComponent as Church_2_2 } from "../../../../static/images/contents/zone-2/icons/2/church_2.svg";
import { ReactComponent as Church_2_3 } from "../../../../static/images/contents/zone-2/icons/2/church_3.svg";
import { ReactComponent as School_2_1 } from "../../../../static/images/contents/zone-2/icons/2/school_1.svg";
import { ReactComponent as School_2_2 } from "../../../../static/images/contents/zone-2/icons/2/school_2.svg";
import { ReactComponent as School_2_3 } from "../../../../static/images/contents/zone-2/icons/2/school_3.svg";
import { ReactComponent as Shopping_2_1 } from "../../../../static/images/contents/zone-2/icons/2/shopping_1.svg";

import { ReactComponent as Church_33_1 } from "../../../../static/images/contents/zone-2/icons/33/church_1.svg";
import { ReactComponent as Church_33_2 } from "../../../../static/images/contents/zone-2/icons/33/church_2.svg";
import { ReactComponent as Church_33_3 } from "../../../../static/images/contents/zone-2/icons/33/church_3.svg";
import { ReactComponent as School_33_1 } from "../../../../static/images/contents/zone-2/icons/33/school_1.svg";
import { ReactComponent as School_33_2 } from "../../../../static/images/contents/zone-2/icons/33/school_2.svg";
import { ReactComponent as School_33_3 } from "../../../../static/images/contents/zone-2/icons/33/school_3.svg";
import { ReactComponent as Shopping_33_1 } from "../../../../static/images/contents/zone-2/icons/33/shopping_1.svg";

import { ReactComponent as Church_61_1 } from "../../../../static/images/contents/zone-2/icons/61/church_1.svg";
import { ReactComponent as Church_61_2 } from "../../../../static/images/contents/zone-2/icons/61/church_2.svg";
import { ReactComponent as Church_61_3 } from "../../../../static/images/contents/zone-2/icons/61/church_3.svg";
import { ReactComponent as School_61_1 } from "../../../../static/images/contents/zone-2/icons/61/school_1.svg";
import { ReactComponent as School_61_2 } from "../../../../static/images/contents/zone-2/icons/61/school_2.svg";
import { ReactComponent as School_61_3 } from "../../../../static/images/contents/zone-2/icons/61/school_3.svg";
import { ReactComponent as Shopping_61_1 } from "../../../../static/images/contents/zone-2/icons/61/shopping_1.svg";

import { ReactComponent as Church_91_1 } from "../../../../static/images/contents/zone-2/icons/91/church_1.svg";
import { ReactComponent as Church_91_2 } from "../../../../static/images/contents/zone-2/icons/91/church_2.svg";
import { ReactComponent as Church_91_3 } from "../../../../static/images/contents/zone-2/icons/91/church_3.svg";
import { ReactComponent as School_91_1 } from "../../../../static/images/contents/zone-2/icons/91/school_1.svg";
import { ReactComponent as School_91_2 } from "../../../../static/images/contents/zone-2/icons/91/school_2.svg";
import { ReactComponent as School_91_3 } from "../../../../static/images/contents/zone-2/icons/91/school_3.svg";
import { ReactComponent as Shopping_91_1 } from "../../../../static/images/contents/zone-2/icons/91/shopping_1.svg";

///////////////////////////////////////////////////////////////////////////////////////////////////
export const zone2_land_plots = [
    //index 1
    {
        index: 2,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: LandPlot_1_1164,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1164,
                                status: "available",
                                block: 64,
                                unitSize: 725.0,
                                north: "29.0",
                                east: "25.0",
                                south: "29.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1165,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1165,
                                status: "available",
                                block: 64,
                                unitSize: 580.0,
                                north: "29.0",
                                east: "20.0",
                                south: "29.0",
                                west: "20.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1166,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1166,
                                status: "available",
                                block: 64,
                                unitSize: 580.0,
                                north: "29.0",
                                east: "20.0",
                                south: "29.0",
                                west: "20.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1167,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1167,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1168,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1168,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1169,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1169,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1170,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1170,
                                status: "available",
                                block: 64,
                                unitSize: 1350.0,
                                north: "45.0",
                                east: "30.0",
                                south: "45.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1171,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1171,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1172,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1172,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1173,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1173,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1174,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1174,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1175,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1175,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1176,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1176,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1177,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1177,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1178,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1178,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1179,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1179,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1180,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1180,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1181,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1181,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1182,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1182,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1183,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1183,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1184,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1184,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1185,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1185,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1186,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1186,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1187,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1187,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1188,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1188,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1189,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1189,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1190,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1190,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1191,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1191,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1192,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1192,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1193,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1193,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1194,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1194,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1195,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1195,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1196,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1196,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1197,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1197,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1198,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1198,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1199,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1199,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1200,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1200,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1201,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1201,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1202,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1202,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1203,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1203,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1204,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1204,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1205,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1205,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1206,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1206,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1207,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1207,
                                status: "booked",
                                block: 65,
                                unitSize: 440.9,
                                north: "15.0",
                                east: "29.39",
                                south: "15.0",
                                west: "29.39",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1208,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1208,
                                status: "booked",
                                block: 65,
                                unitSize: 440.9,
                                north: "15.0",
                                east: "29.39",
                                south: "15.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1209,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1209,
                                status: "booked",
                                block: 65,
                                unitSize: 440.9,
                                north: "15.0",
                                east: "29.39",
                                south: "15.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1210,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1210,
                                status: "booked",
                                block: 65,
                                unitSize: 440.9,
                                north: "15.0",
                                east: "29.39",
                                south: "15.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1211,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1211,
                                status: "available",
                                block: 66,
                                unitSize: 527.0,
                                north: "17.0",
                                east: "31.0",
                                south: "17.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1212,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1212,
                                status: "available",
                                block: 66,
                                unitSize: 527.0,
                                north: "17.0",
                                east: "31.0",
                                south: "17.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1213,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1213,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1214,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1214,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1215,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1215,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1216,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1216,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1217,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1217,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1218,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1218,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1219,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1219,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1220,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1220,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1221,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1221,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1222,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1222,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1223,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1223,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1224,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1224,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1225,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1225,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1226,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1226,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1227,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1227,
                                status: "available",
                                block: 66,
                                unitSize: 558.0,
                                north: "18.0",
                                east: "31.0",
                                south: "18.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1228,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1228,
                                status: "available",
                                block: 66,
                                unitSize: 558.0,
                                north: "18.0",
                                east: "31.0",
                                south: "18.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1229,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1229,
                                status: "available",
                                block: 67,
                                unitSize: 527.0,
                                north: "17.0",
                                east: "31.0",
                                south: "17.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1230,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1230,
                                status: "available",
                                block: 67,
                                unitSize: 550.68,
                                north: "17.0",
                                east: "32.39",
                                south: "17.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1231,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1231,
                                status: "available",
                                block: 67,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1232,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1232,
                                status: "available",
                                block: 67,
                                unitSize: 485.9,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1233,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1233,
                                status: "available",
                                block: 67,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1234,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1234,
                                status: "available",
                                block: 67,
                                unitSize: 485.9,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1235,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1235,
                                status: "available",
                                block: 67,
                                unitSize: 458.75,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1236,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1236,
                                status: "available",
                                block: 67,
                                unitSize: 485.09,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1237,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1237,
                                status: "available",
                                block: 67,
                                unitSize: 458.75,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1238,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1238,
                                status: "available",
                                block: 67,
                                unitSize: 485.09,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1239,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1239,
                                status: "available",
                                block: 67,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1240,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1240,
                                status: "available",
                                block: 67,
                                unitSize: 485.09,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1241,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1241,
                                status: "available",
                                block: 67,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1242,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1242,
                                status: "available",
                                block: 67,
                                unitSize: 485.09,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1243,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1243,
                                status: "available",
                                block: 67,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1244,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1244,
                                status: "available",
                                block: 67,
                                unitSize: 485.09,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1245,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1245,
                                status: "available",
                                block: 67,
                                unitSize: 558.0,
                                north: "18.0",
                                east: "31.0",
                                south: "18.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1246,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1246,
                                status: "available",
                                block: 67,
                                unitSize: 583.08,
                                north: "18.0",
                                east: "32.39",
                                south: "18.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1247,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1247,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1248,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1248,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1249,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1249,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1250,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1250,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1251,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1251,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1252,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1252,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1253,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1253,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1254,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1254,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1255,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1255,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1256,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1256,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1257,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1257,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1258,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1258,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1259,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1259,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1260,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1260,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1261,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1261,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1262,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1262,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1263,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1263,
                                status: "available",
                                block: 68,
                                unitSize: 499.68,
                                north: "17.0",
                                east: "29.39",
                                south: "17.0",
                                west: "29.39",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1264,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1264,
                                status: "available",
                                block: 68,
                                unitSize: 470.29,
                                north: "16.0",
                                east: "29.39",
                                south: "16.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1265,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1265,
                                status: "available",
                                block: 68,
                                unitSize: 499.68,
                                north: "17.0",
                                east: "29.39",
                                south: "17.0",
                                west: "29.39",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1266,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1266,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1267,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1267,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1268,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1268,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1269,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1269,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1270,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1270,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1271,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1271,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1272,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1272,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1273,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1273,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1274,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1274,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1275,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1275,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1276,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1276,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1277,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1277,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1278,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1278,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1279,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1279,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1280,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1280,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1281,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1281,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1282,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1282,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1283,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1283,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1284,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1284,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1285,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1285,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1286,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1286,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1287,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1287,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1288,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1288,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1289,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1289,
                                status: "available",
                                block: 69,
                                unitSize: 1350.0,
                                north: "45.0",
                                east: "30.0",
                                south: "45.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1290,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1290,
                                status: "available",
                                block: 69,
                                unitSize: 680.0,
                                north: "34.0",
                                east: "20.0",
                                south: "34.0",
                                west: "20.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1291,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1291,
                                status: "available",
                                block: 69,
                                unitSize: 510.0,
                                north: "34.0",
                                east: "15.0",
                                south: "34.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1292,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1292,
                                status: "available",
                                block: 69,
                                unitSize: 510.0,
                                north: "34.0",
                                east: "15.0",
                                south: "34.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1293,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1293,
                                status: "available",
                                block: 69,
                                unitSize: 510.0,
                                north: "34.0",
                                east: "15.0",
                                south: "34.0",
                                west: "15.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1294,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1294,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1295,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1295,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1296,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1296,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1297,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1297,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1298,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1298,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1299,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1299,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1300,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1300,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1301,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1301,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1302,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1302,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1303,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1303,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1304,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1304,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1305,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1305,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1306,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1306,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1307,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1307,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1308,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1308,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1309,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1309,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1310,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1310,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1311,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1311,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1312,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1312,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1313,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1313,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1314,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1314,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1315,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1315,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1316,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1316,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1317,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1317,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1318,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1318,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1319,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1319,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1320,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1320,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1321,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1321,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1322,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1322,
                                status: "available",
                                block: 71,
                                unitSize: 488.75,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1323,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1323,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1324,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1324,
                                status: "available",
                                block: 71,
                                unitSize: 488.75,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1325,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1325,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1326,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1326,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1327,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1327,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1328,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1328,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1329,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1329,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1330,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1330,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1331,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1331,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1332,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1332,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1333,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1333,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1334,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1334,
                                status: "available",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1335,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1335,
                                status: "available",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1336,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1336,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1337,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1337,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1338,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1338,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1339,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1339,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1340,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1340,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1341,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1341,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1342,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1342,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1343,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1343,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1344,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1344,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1345,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1345,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1346,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1346,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1347,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1347,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1348,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1348,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1349,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1349,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1350,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1350,
                                status: "sold",
                                block: 72,
                                unitSize: 499.68,
                                north: "17.0",
                                east: "29.39",
                                south: "17.0",
                                west: "29.39",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1351,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1351,
                                status: "sold",
                                block: 72,
                                unitSize: 499.68,
                                north: "17.0",
                                east: "29.39",
                                south: "17.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1352,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1352,
                                status: "sold",
                                block: 72,
                                unitSize: 617.26,
                                north: "21.0",
                                east: "29.39",
                                south: "21.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1353,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1353,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1354,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1354,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1355,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1355,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1356,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1356,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1357,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1357,
                                status: "booked",
                                block: 73,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1358,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1358,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1359,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1359,
                                status: "booked",
                                block: 73,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1360,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1360,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1361,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1361,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1362,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1362,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1363,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1363,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1364,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1364,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1365,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1365,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1366,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1366,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1367,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1367,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1368,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1368,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1369,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1369,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1370,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1370,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1371,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1371,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1372,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1372,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1373,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1373,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1374,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1374,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1375,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1375,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1376,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1376,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1377,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1377,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1378,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1378,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1379,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1379,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1380,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1380,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1381,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1381,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1382,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1382,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1383,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1383,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1384,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1384,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1385,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1385,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1386,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1386,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1387,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1387,
                                status: "available",
                                block: 74,
                                unitSize: 674.88,
                                north: "30.0",
                                east: "22.5",
                                south: "30.0",
                                west: "22.5",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1388,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1388,
                                status: "available",
                                block: 74,
                                unitSize: 674.88,
                                north: "30.0",
                                east: "22.5",
                                south: "30.0",
                                west: "22.5",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1389,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1389,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1390,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1390,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1391,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1391,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1392,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1392,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1393,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1393,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1394,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1394,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1395,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1395,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1396,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1396,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1397,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1397,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1398,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1398,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1399,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1399,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1400,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1400,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1401,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1401,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1402,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1402,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1403,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1403,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1404,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1404,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1405,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1405,
                                status: "available",
                                block: 74,
                                unitSize: 639.97,
                                north: "30.0",
                                east: "21.0",
                                south: "30.01",
                                west: "21.66",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1406,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1406,
                                status: "available",
                                block: 74,
                                unitSize: 481.99,
                                north: "22.0",
                                east: "21.66",
                                south: "22.01",
                                west: "22.15",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1407,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1407,
                                status: "available",
                                block: 75,
                                unitSize: 629.0,
                                north: "17.0",
                                east: "37.0",
                                south: "17.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1408,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1408,
                                status: "available",
                                block: 75,
                                unitSize: 583.5,
                                north: "17.0",
                                east: "34.14",
                                south: "17.0",
                                west: "34.51",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1409,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1409,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1410,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1410,
                                status: "available",
                                block: 75,
                                unitSize: 520.17,
                                north: "15.0",
                                east: "34.51",
                                south: "15.0",
                                west: "34.84",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1411,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1411,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1412,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1412,
                                status: "available",
                                block: 75,
                                unitSize: 525.16,
                                north: "15.0",
                                east: "34.84",
                                south: "15.0",
                                west: "35.18",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1413,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1413,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1414,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1414,
                                status: "available",
                                block: 75,
                                unitSize: 530.14,
                                north: "15.0",
                                east: "35.18",
                                south: "15.0",
                                west: "35.51",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1415,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1415,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1416,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1416,
                                status: "available",
                                block: 75,
                                unitSize: 535.13,
                                north: "15.0",
                                east: "35.51",
                                south: "15.0",
                                west: "35.84",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1417,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1417,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1418,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1418,
                                status: "available",
                                block: 75,
                                unitSize: 540.12,
                                north: "15.0",
                                east: "35.84",
                                south: "15.0",
                                west: "36.17",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1419,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1419,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1420,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1420,
                                status: "available",
                                block: 75,
                                unitSize: 545.1,
                                north: "15.0",
                                east: "36.17",
                                south: "15.0",
                                west: "36.51",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1421,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1421,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1422,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1422,
                                status: "available",
                                block: 75,
                                unitSize: 550.09,
                                north: "15.0",
                                east: "36.51",
                                south: "15.0",
                                west: "36.84",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1423,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1423,
                                status: "available",
                                block: 75,
                                unitSize: 588.0,
                                north: "28.0",
                                east: "21.0",
                                south: "28.0",
                                west: "21.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1424,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1424,
                                status: "available",
                                block: 75,
                                unitSize: 448.0,
                                north: "28.0",
                                east: "16.0",
                                south: "28.0",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1425,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1425,
                                status: "available",
                                block: 75,
                                unitSize: 448.0,
                                north: "28.0",
                                east: "16.0",
                                south: "28.0",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1426,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1426,
                                status: "available",
                                block: 75,
                                unitSize: 592.17,
                                north: "28.0",
                                east: "20.84",
                                south: "28.01",
                                west: "21.46",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1427,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1427,
                                status: "available",
                                block: 76,
                                unitSize: 635.85,
                                north: "18.55",
                                east: "35.01",
                                south: "17.78",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1428,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1428,
                                status: "available",
                                block: 76,
                                unitSize: 608.7,
                                north: "17.78",
                                east: "35.01",
                                south: "17.0",
                                west: "35.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1429,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1429,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1430,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1430,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1431,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1431,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1432,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1432,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1433,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1433,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1434,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1434,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1435,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1435,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1436,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1436,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1437,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1437,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1438,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1438,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1439,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1439,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1440,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1440,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1441,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1441,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1442,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1442,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1443,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1443,
                                status: "available",
                                block: 76,
                                unitSize: 507.16,
                                north: "26.48",
                                east: "19.0",
                                south: "26.9",
                                west: "19.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1444,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1444,
                                status: "available",
                                block: 76,
                                unitSize: 433.28,
                                north: "26.9",
                                east: "16.0",
                                south: "27.26",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1445,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1445,
                                status: "available",
                                block: 76,
                                unitSize: 438.96,
                                north: "27.26",
                                east: "16.0",
                                south: "27.61",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1446,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1446,
                                status: "available",
                                block: 76,
                                unitSize: 528.63,
                                north: "27.61",
                                east: "19.0",
                                south: "28.03",
                                west: "19.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1447,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1447,
                                status: "available",
                                block: 77,
                                unitSize: 969.51,
                                north: "39.06",
                                east: "25.01",
                                south: "38.5",
                                west: "25.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1448,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1448,
                                status: "available",
                                block: 77,
                                unitSize: 2232.91,
                                north: "53.5",
                                east: "42.35",
                                south: "52.57",
                                west: "42.33",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: true,
                                st_width_1: "25",
                                st_width_2: "60",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1449,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1449,
                                status: "available",
                                block: 77,
                                unitSize: 750.0,
                                north: "30.0",
                                east: "25.0",
                                south: "30.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1450,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1450,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1451,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1451,
                                status: "available",
                                block: 77,
                                unitSize: 1269.98,
                                north: "30.0",
                                east: "42.33",
                                south: "30.0",
                                west: "42.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1452,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1452,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1453,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1453,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1454,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1454,
                                status: "available",
                                block: 77,
                                unitSize: 1269.98,
                                north: "30.0",
                                east: "42.33",
                                south: "30.0",
                                west: "42.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1455,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1455,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1456,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1456,
                                status: "available",
                                block: 77,
                                unitSize: 368.75,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1457,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1457,
                                status: "available",
                                block: 77,
                                unitSize: 1269.7,
                                north: "30.0",
                                east: "42.33",
                                south: "30.0",
                                west: "42.32",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1458,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1458,
                                status: "available",
                                block: 77,
                                unitSize: 368.75,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1459,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1459,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1460,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1460,
                                status: "available",
                                block: 77,
                                unitSize: 1269.56,
                                north: "30.0",
                                east: "42.32",
                                south: "30.0",
                                west: "42.32",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1461,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1461,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1462,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1462,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1463,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1463,
                                status: "available",
                                block: 77,
                                unitSize: 1269.43,
                                north: "30.0",
                                east: "42.32",
                                south: "30.0",
                                west: "42.31",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1464,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1464,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1465,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1465,
                                status: "available",
                                block: 77,
                                unitSize: 381.93,
                                north: "15.0",
                                east: "25.0",
                                south: "15.55",
                                west: "25.01",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1466,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1466,
                                status: "available",
                                block: 77,
                                unitSize: 1312.56,
                                north: "30.55",
                                east: "42.31",
                                south: "31.49",
                                west: "42.32",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1467,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1467,
                                status: "sold",
                                block: 78,
                                unitSize: 425.0,
                                north: "17.0",
                                east: "25.0",
                                south: "17.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1468,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1468,
                                status: "sold",
                                block: 78,
                                unitSize: 400.0,
                                north: "16.0",
                                east: "25.0",
                                south: "16.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1469,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1469,
                                status: "sold",
                                block: 78,
                                unitSize: 412.5,
                                north: "17.0",
                                east: "25.0",
                                south: "17.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1470,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1470,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1471,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1471,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1472,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1472,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1473,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1473,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1474,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1474,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1475,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1475,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1476,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1476,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1477,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1477,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1478,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1478,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1479,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1479,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1480,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1480,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1481,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1481,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1482,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1482,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1483,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1483,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1484,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1484,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1485,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1485,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1486,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1486,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1487,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1487,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1488,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1488,
                                status: "available",
                                block: 78,
                                unitSize: 977.48,
                                north: "25.0",
                                east: "38.83",
                                south: "25.01",
                                west: "39.38",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1489,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1489,
                                status: "available",
                                block: 78,
                                unitSize: 991.33,
                                north: "25.0",
                                east: "39.38",
                                south: "25.01",
                                west: "39.93",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "15+6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1490,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1490,
                                status: "booked",
                                block: 79,
                                unitSize: 649.71,
                                north: "17.0",
                                east: "38.22",
                                south: "17.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1491,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1491,
                                status: "booked",
                                block: 79,
                                unitSize: 615.28,
                                north: "17.0",
                                east: "36.38",
                                south: "17.0",
                                west: "36.38",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1492,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1492,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1493,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1493,
                                status: "booked",
                                block: 79,
                                unitSize: 548.11,
                                north: "15.0",
                                east: "36.38",
                                south: "15.0",
                                west: "36.7",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1494,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1494,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1495,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1495,
                                status: "booked",
                                block: 79,
                                unitSize: 553.0,
                                north: "15.0",
                                east: "36.7",
                                south: "15.0",
                                west: "37.03",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1496,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1496,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1497,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1497,
                                status: "booked",
                                block: 79,
                                unitSize: 557.89,
                                north: "15.0",
                                east: "37.03",
                                south: "15.0",
                                west: "37.36",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1498,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1498,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1499,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1499,
                                status: "booked",
                                block: 79,
                                unitSize: 562.78,
                                north: "15.0",
                                east: "37.36",
                                south: "15.0",
                                west: "37.68",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1500,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1500,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1501,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1501,
                                status: "booked",
                                block: 79,
                                unitSize: 567.67,
                                north: "15.0",
                                east: "37.68",
                                south: "15.0",
                                west: "38.01",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1502,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1502,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1503,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1503,
                                status: "booked",
                                block: 79,
                                unitSize: 572.56,
                                north: "15.0",
                                east: "38.01",
                                south: "15.0",
                                west: "38.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1504,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1504,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1505,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1505,
                                status: "booked",
                                block: 79,
                                unitSize: 577.45,
                                north: "15.0",
                                east: "38.33",
                                south: "15.0",
                                west: "38.66",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1506,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1506,
                                status: "booked",
                                block: 79,
                                unitSize: 687.93,
                                north: "18.0",
                                east: "38.22",
                                south: "18.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1507,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1507,
                                status: "booked",
                                block: 79,
                                unitSize: 699.4,
                                north: "18.0",
                                east: "38.66",
                                south: "18.0",
                                west: "39.05",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1508,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1508,
                                status: "available",
                                block: 80,
                                unitSize: 635.11,
                                north: "18.03",
                                east: "36.01",
                                south: "17.25",
                                west: "36.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1509,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1509,
                                status: "available",
                                block: 80,
                                unitSize: 606.94,
                                north: "17.25",
                                east: "36.01",
                                south: "16.47",
                                west: "36.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1510,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1510,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1511,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1511,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1512,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1512,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1513,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1513,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1514,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1514,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1515,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1515,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1516,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1516,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1517,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1517,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1518,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1518,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1519,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1519,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1520,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1520,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1521,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1521,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1522,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1522,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1523,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1523,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1524,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1524,
                                status: "available",
                                block: 80,
                                unitSize: 613.58,
                                north: "17.0",
                                east: "36.0",
                                south: "17.78",
                                west: "36.01",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1525,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1525,
                                status: "available",
                                block: 80,
                                unitSize: 654.25,
                                north: "17.78",
                                east: "36.0",
                                south: "18.56",
                                west: "36.01",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1526,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1526,
                                status: "available",
                                block: 81,
                                unitSize: 649.49,
                                north: "27.5",
                                east: "23.62",
                                south: "27.5",
                                west: "23.62",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1527,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1527,
                                status: "available",
                                block: 81,
                                unitSize: 649.49,
                                north: "27.5",
                                east: "23.62",
                                south: "27.5",
                                west: "23.62",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1528,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1528,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1529,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1529,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1530,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1530,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1531,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1531,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1532,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1532,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1533,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1533,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1534,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1534,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1535,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1535,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1536,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1536,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1537,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1537,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1538,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1538,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1539,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1539,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1540,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1540,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1541,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1541,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1542,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1542,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1543,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1543,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1544,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1544,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1545,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1545,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1546,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1546,
                                status: "available",
                                block: 81,
                                unitSize: 420.72,
                                north: "27.5",
                                east: "15.0",
                                south: "27.51",
                                west: "15.6",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1547,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1547,
                                status: "available",
                                block: 81,
                                unitSize: 437.15,
                                north: "27.5",
                                east: "15.6",
                                south: "27.51",
                                west: "16.2",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1548,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1548,
                                status: "available",
                                block: 82,
                                unitSize: 406.22,
                                north: "16.52",
                                east: "25.01",
                                south: "15.98",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1549,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1549,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1550,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1550,
                                status: "available",
                                block: 82,
                                unitSize: 1290.95,
                                north: "30.98",
                                east: "42.31",
                                south: "30.06",
                                west: "42.3",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1551,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1551,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1552,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1552,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1553,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1553,
                                status: "available",
                                block: 82,
                                unitSize: 1269.22,
                                north: "30.0",
                                east: "42.3",
                                south: "30.0",
                                west: "42.31",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1554,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1554,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1555,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1555,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1556,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1556,
                                status: "available",
                                block: 82,
                                unitSize: 1269.37,
                                north: "30.0",
                                east: "42.31",
                                south: "30.0",
                                west: "42.31",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1557,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1557,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1558,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1558,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1559,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1559,
                                status: "available",
                                block: 82,
                                unitSize: 1269.53,
                                north: "30.0",
                                east: "42.31",
                                south: "30.0",
                                west: "42.32",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1560,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1560,
                                status: "available",
                                block: 82,
                                unitSize: 675.0,
                                north: "27.0",
                                east: "25.0",
                                south: "27.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1561,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1561,
                                status: "available",
                                block: 82,
                                unitSize: 1269.68,
                                north: "30.0",
                                east: "42.32",
                                south: "30.0",
                                west: "42.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1562,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1562,
                                status: "available",
                                block: 82,
                                unitSize: 825.0,
                                north: "33.0",
                                east: "25.0",
                                south: "33.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1563,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1563,
                                status: "available",
                                block: 82,
                                unitSize: 1269.84,
                                north: "30.0",
                                east: "42.33",
                                south: "30.0",
                                west: "42.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1564,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1564,
                                status: "available",
                                block: 82,
                                unitSize: 945.07,
                                north: "37.53",
                                east: "25.0",
                                south: "38.07",
                                west: "25.01",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1565,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1565,
                                status: "available",
                                block: 82,
                                unitSize: 1631.31,
                                north: "38.07",
                                east: "42.33",
                                south: "38.99",
                                west: "42.35",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "60",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1566,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1566,
                                status: "available",
                                block: 83,
                                unitSize: 731.79,
                                north: "30.0",
                                east: "24.39",
                                south: "30.0",
                                west: "24.39",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1567,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1567,
                                status: "available",
                                block: 83,
                                unitSize: 975.72,
                                north: "40.0",
                                east: "24.39",
                                south: "40.0",
                                west: "24.39",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "30",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1568,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1568,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1569,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1569,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1570,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1570,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1571,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1571,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1572,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1572,
                                status: "available",
                                block: 83,
                                unitSize: 443.75,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1573,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1573,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1574,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1574,
                                status: "available",
                                block: 83,
                                unitSize: 443.75,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1575,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1575,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1576,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1576,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1577,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1577,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1578,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1578,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1579,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1579,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1580,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1580,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1581,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1581,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1582,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1582,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1583,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1583,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1584,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1584,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1585,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1585,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1586,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1586,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1587,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1587,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1588,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1588,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1589,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1589,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1590,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1590,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1591,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1591,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1592,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1592,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1593,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1593,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1594,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1594,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1595,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1595,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1596,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1596,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1597,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1597,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1598,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1598,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1599,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1599,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1600,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1600,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1601,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1601,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1602,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1602,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1603,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1603,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1604,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1604,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1605,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1605,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1606,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1606,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1607,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1607,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1608,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1608,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1609,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1609,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1610,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1610,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1611,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1611,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1612,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1612,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1613,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1613,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1614,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1614,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1615,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1615,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1616,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1616,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1617,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1617,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1618,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1618,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1619,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1619,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1620,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1620,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1621,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1621,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1622,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1622,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1623,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1623,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1624,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1624,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1625,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1625,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1626,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1626,
                                status: "booked",
                                block: 84,
                                unitSize: 1317.11,
                                north: "35.0",
                                east: "37.61",
                                south: "35.01",
                                west: "38.37",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "60",
                            },
                        },
                        {
                            Polygon: LandPlot_1_1627,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1627,
                                status: "booked",
                                block: 84,
                                unitSize: 1356.45,
                                north: "35.0",
                                east: "38.37",
                                south: "35.01",
                                west: "39.14",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "30",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_2_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_2_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_2_1,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //index 31
    {
        index: 32,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: LandPlot_31_1164,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1164,
                                status: "available",
                                block: 64,
                                unitSize: 725.0,
                                north: "29.0",
                                east: "25.0",
                                south: "29.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1165,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1165,
                                status: "available",
                                block: 64,
                                unitSize: 580.0,
                                north: "29.0",
                                east: "20.0",
                                south: "29.0",
                                west: "20.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1166,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1166,
                                status: "available",
                                block: 64,
                                unitSize: 580.0,
                                north: "29.0",
                                east: "20.0",
                                south: "29.0",
                                west: "20.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1167,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1167,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1168,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1168,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1169,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1169,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1170,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1170,
                                status: "available",
                                block: 64,
                                unitSize: 1350.0,
                                north: "45.0",
                                east: "30.0",
                                south: "45.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1171,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1171,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1172,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1172,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1173,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1173,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1174,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1174,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1175,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1175,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1176,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1176,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1177,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1177,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1178,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1178,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1179,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1179,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1180,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1180,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1181,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1181,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1182,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1182,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1183,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1183,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1184,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1184,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1185,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1185,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1186,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1186,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1187,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1187,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1188,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1188,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1189,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1189,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1190,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1190,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1191,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1191,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1192,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1192,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1193,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1193,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1194,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1194,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1195,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1195,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1196,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1196,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1197,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1197,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1198,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1198,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1199,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1199,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1200,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1200,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1201,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1201,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1202,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1202,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1203,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1203,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1204,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1204,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1205,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1205,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1206,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1206,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1207,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1207,
                                status: "booked",
                                block: 65,
                                unitSize: 440.9,
                                north: "15.0",
                                east: "29.39",
                                south: "15.0",
                                west: "29.39",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1208,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1208,
                                status: "booked",
                                block: 65,
                                unitSize: 440.9,
                                north: "15.0",
                                east: "29.39",
                                south: "15.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1209,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1209,
                                status: "booked",
                                block: 65,
                                unitSize: 440.9,
                                north: "15.0",
                                east: "29.39",
                                south: "15.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1210,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1210,
                                status: "booked",
                                block: 65,
                                unitSize: 440.9,
                                north: "15.0",
                                east: "29.39",
                                south: "15.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1211,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1211,
                                status: "available",
                                block: 66,
                                unitSize: 527.0,
                                north: "17.0",
                                east: "31.0",
                                south: "17.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1212,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1212,
                                status: "available",
                                block: 66,
                                unitSize: 527.0,
                                north: "17.0",
                                east: "31.0",
                                south: "17.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1213,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1213,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1214,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1214,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1215,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1215,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1216,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1216,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1217,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1217,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1218,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1218,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1219,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1219,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1220,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1220,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1221,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1221,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1222,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1222,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1223,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1223,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1224,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1224,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1225,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1225,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1226,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1226,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1227,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1227,
                                status: "available",
                                block: 66,
                                unitSize: 558.0,
                                north: "18.0",
                                east: "31.0",
                                south: "18.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1228,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1228,
                                status: "available",
                                block: 66,
                                unitSize: 558.0,
                                north: "18.0",
                                east: "31.0",
                                south: "18.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1229,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1229,
                                status: "available",
                                block: 67,
                                unitSize: 527.0,
                                north: "17.0",
                                east: "31.0",
                                south: "17.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1230,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1230,
                                status: "available",
                                block: 67,
                                unitSize: 550.68,
                                north: "17.0",
                                east: "32.39",
                                south: "17.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1231,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1231,
                                status: "available",
                                block: 67,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1232,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1232,
                                status: "available",
                                block: 67,
                                unitSize: 485.9,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1233,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1233,
                                status: "available",
                                block: 67,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1234,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1234,
                                status: "available",
                                block: 67,
                                unitSize: 485.9,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1235,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1235,
                                status: "available",
                                block: 67,
                                unitSize: 458.75,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1236,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1236,
                                status: "available",
                                block: 67,
                                unitSize: 485.09,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1237,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1237,
                                status: "available",
                                block: 67,
                                unitSize: 458.75,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1238,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1238,
                                status: "available",
                                block: 67,
                                unitSize: 485.09,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1239,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1239,
                                status: "available",
                                block: 67,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1240,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1240,
                                status: "available",
                                block: 67,
                                unitSize: 485.09,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1241,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1241,
                                status: "available",
                                block: 67,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1242,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1242,
                                status: "available",
                                block: 67,
                                unitSize: 485.09,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1243,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1243,
                                status: "available",
                                block: 67,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1244,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1244,
                                status: "available",
                                block: 67,
                                unitSize: 485.09,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1245,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1245,
                                status: "available",
                                block: 67,
                                unitSize: 558.0,
                                north: "18.0",
                                east: "31.0",
                                south: "18.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1246,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1246,
                                status: "available",
                                block: 67,
                                unitSize: 583.08,
                                north: "18.0",
                                east: "32.39",
                                south: "18.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1247,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1247,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1248,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1248,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1249,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1249,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1250,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1250,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1251,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1251,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1252,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1252,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1253,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1253,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1254,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1254,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1255,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1255,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1256,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1256,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1257,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1257,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1258,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1258,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1259,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1259,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1260,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1260,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1261,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1261,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1262,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1262,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1263,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1263,
                                status: "available",
                                block: 68,
                                unitSize: 499.68,
                                north: "17.0",
                                east: "29.39",
                                south: "17.0",
                                west: "29.39",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1264,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1264,
                                status: "available",
                                block: 68,
                                unitSize: 470.29,
                                north: "16.0",
                                east: "29.39",
                                south: "16.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1265,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1265,
                                status: "available",
                                block: 68,
                                unitSize: 499.68,
                                north: "17.0",
                                east: "29.39",
                                south: "17.0",
                                west: "29.39",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1266,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1266,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1267,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1267,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1268,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1268,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1269,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1269,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1270,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1270,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1271,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1271,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1272,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1272,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1273,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1273,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1274,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1274,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1275,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1275,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1276,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1276,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1277,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1277,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1278,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1278,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1279,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1279,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1280,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1280,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1281,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1281,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1282,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1282,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1283,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1283,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1284,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1284,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1285,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1285,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1286,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1286,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1287,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1287,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1288,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1288,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1289,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1289,
                                status: "available",
                                block: 69,
                                unitSize: 1350.0,
                                north: "45.0",
                                east: "30.0",
                                south: "45.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1290,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1290,
                                status: "available",
                                block: 69,
                                unitSize: 680.0,
                                north: "34.0",
                                east: "20.0",
                                south: "34.0",
                                west: "20.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1291,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1291,
                                status: "available",
                                block: 69,
                                unitSize: 510.0,
                                north: "34.0",
                                east: "15.0",
                                south: "34.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1292,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1292,
                                status: "available",
                                block: 69,
                                unitSize: 510.0,
                                north: "34.0",
                                east: "15.0",
                                south: "34.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1293,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1293,
                                status: "available",
                                block: 69,
                                unitSize: 510.0,
                                north: "34.0",
                                east: "15.0",
                                south: "34.0",
                                west: "15.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1294,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1294,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1295,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1295,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1296,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1296,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1297,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1297,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1298,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1298,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1299,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1299,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1300,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1300,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1301,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1301,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1302,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1302,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1303,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1303,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1304,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1304,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1305,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1305,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1306,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1306,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1307,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1307,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1308,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1308,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1309,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1309,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1310,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1310,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1311,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1311,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1312,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1312,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1313,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1313,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1314,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1314,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1315,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1315,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1316,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1316,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1317,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1317,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1318,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1318,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1319,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1319,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1320,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1320,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1321,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1321,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1322,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1322,
                                status: "available",
                                block: 71,
                                unitSize: 488.75,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1323,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1323,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1324,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1324,
                                status: "available",
                                block: 71,
                                unitSize: 488.75,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1325,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1325,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1326,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1326,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1327,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1327,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1328,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1328,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1329,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1329,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1330,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1330,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1331,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1331,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1332,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1332,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1333,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1333,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1334,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1334,
                                status: "available",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1335,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1335,
                                status: "available",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1336,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1336,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1337,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1337,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1338,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1338,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1339,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1339,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1340,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1340,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1341,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1341,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1342,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1342,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1343,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1343,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1344,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1344,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1345,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1345,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1346,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1346,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1347,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1347,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1348,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1348,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1349,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1349,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1350,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1350,
                                status: "sold",
                                block: 72,
                                unitSize: 499.68,
                                north: "17.0",
                                east: "29.39",
                                south: "17.0",
                                west: "29.39",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1351,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1351,
                                status: "sold",
                                block: 72,
                                unitSize: 499.68,
                                north: "17.0",
                                east: "29.39",
                                south: "17.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1352,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1352,
                                status: "sold",
                                block: 72,
                                unitSize: 617.26,
                                north: "21.0",
                                east: "29.39",
                                south: "21.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1353,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1353,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1354,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1354,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1355,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1355,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1356,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1356,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1357,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1357,
                                status: "booked",
                                block: 73,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1358,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1358,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1359,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1359,
                                status: "booked",
                                block: 73,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1360,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1360,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1361,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1361,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1362,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1362,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1363,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1363,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1364,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1364,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1365,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1365,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1366,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1366,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1367,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1367,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1368,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1368,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1369,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1369,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1370,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1370,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1371,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1371,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1372,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1372,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1373,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1373,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1374,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1374,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1375,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1375,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1376,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1376,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1377,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1377,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1378,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1378,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1379,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1379,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1380,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1380,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1381,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1381,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1382,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1382,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1383,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1383,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1384,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1384,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1385,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1385,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1386,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1386,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1387,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1387,
                                status: "available",
                                block: 74,
                                unitSize: 674.88,
                                north: "30.0",
                                east: "22.5",
                                south: "30.0",
                                west: "22.5",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1388,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1388,
                                status: "available",
                                block: 74,
                                unitSize: 674.88,
                                north: "30.0",
                                east: "22.5",
                                south: "30.0",
                                west: "22.5",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1389,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1389,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1390,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1390,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1391,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1391,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1392,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1392,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1393,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1393,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1394,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1394,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1395,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1395,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1396,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1396,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1397,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1397,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1398,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1398,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1399,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1399,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1400,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1400,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1401,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1401,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1402,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1402,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1403,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1403,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1404,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1404,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1405,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1405,
                                status: "available",
                                block: 74,
                                unitSize: 639.97,
                                north: "30.0",
                                east: "21.0",
                                south: "30.01",
                                west: "21.66",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1406,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1406,
                                status: "available",
                                block: 74,
                                unitSize: 481.99,
                                north: "22.0",
                                east: "21.66",
                                south: "22.01",
                                west: "22.15",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1407,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1407,
                                status: "available",
                                block: 75,
                                unitSize: 629.0,
                                north: "17.0",
                                east: "37.0",
                                south: "17.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1408,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1408,
                                status: "available",
                                block: 75,
                                unitSize: 583.5,
                                north: "17.0",
                                east: "34.14",
                                south: "17.0",
                                west: "34.51",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1409,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1409,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1410,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1410,
                                status: "available",
                                block: 75,
                                unitSize: 520.17,
                                north: "15.0",
                                east: "34.51",
                                south: "15.0",
                                west: "34.84",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1411,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1411,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1412,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1412,
                                status: "available",
                                block: 75,
                                unitSize: 525.16,
                                north: "15.0",
                                east: "34.84",
                                south: "15.0",
                                west: "35.18",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1413,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1413,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1414,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1414,
                                status: "available",
                                block: 75,
                                unitSize: 530.14,
                                north: "15.0",
                                east: "35.18",
                                south: "15.0",
                                west: "35.51",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1415,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1415,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1416,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1416,
                                status: "available",
                                block: 75,
                                unitSize: 535.13,
                                north: "15.0",
                                east: "35.51",
                                south: "15.0",
                                west: "35.84",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1417,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1417,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1418,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1418,
                                status: "available",
                                block: 75,
                                unitSize: 540.12,
                                north: "15.0",
                                east: "35.84",
                                south: "15.0",
                                west: "36.17",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1419,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1419,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1420,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1420,
                                status: "available",
                                block: 75,
                                unitSize: 545.1,
                                north: "15.0",
                                east: "36.17",
                                south: "15.0",
                                west: "36.51",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1421,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1421,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1422,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1422,
                                status: "available",
                                block: 75,
                                unitSize: 550.09,
                                north: "15.0",
                                east: "36.51",
                                south: "15.0",
                                west: "36.84",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1423,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1423,
                                status: "available",
                                block: 75,
                                unitSize: 588.0,
                                north: "28.0",
                                east: "21.0",
                                south: "28.0",
                                west: "21.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1424,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1424,
                                status: "available",
                                block: 75,
                                unitSize: 448.0,
                                north: "28.0",
                                east: "16.0",
                                south: "28.0",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1425,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1425,
                                status: "available",
                                block: 75,
                                unitSize: 448.0,
                                north: "28.0",
                                east: "16.0",
                                south: "28.0",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1426,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1426,
                                status: "available",
                                block: 75,
                                unitSize: 592.17,
                                north: "28.0",
                                east: "20.84",
                                south: "28.01",
                                west: "21.46",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1427,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1427,
                                status: "available",
                                block: 76,
                                unitSize: 635.85,
                                north: "18.55",
                                east: "35.01",
                                south: "17.78",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1428,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1428,
                                status: "available",
                                block: 76,
                                unitSize: 608.7,
                                north: "17.78",
                                east: "35.01",
                                south: "17.0",
                                west: "35.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1429,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1429,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1430,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1430,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1431,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1431,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1432,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1432,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1433,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1433,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1434,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1434,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1435,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1435,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1436,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1436,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1437,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1437,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1438,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1438,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1439,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1439,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1440,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1440,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1441,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1441,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1442,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1442,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1443,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1443,
                                status: "available",
                                block: 76,
                                unitSize: 507.16,
                                north: "26.48",
                                east: "19.0",
                                south: "26.9",
                                west: "19.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1444,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1444,
                                status: "available",
                                block: 76,
                                unitSize: 433.28,
                                north: "26.9",
                                east: "16.0",
                                south: "27.26",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1445,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1445,
                                status: "available",
                                block: 76,
                                unitSize: 438.96,
                                north: "27.26",
                                east: "16.0",
                                south: "27.61",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1446,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1446,
                                status: "available",
                                block: 76,
                                unitSize: 528.63,
                                north: "27.61",
                                east: "19.0",
                                south: "28.03",
                                west: "19.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1447,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1447,
                                status: "available",
                                block: 77,
                                unitSize: 969.51,
                                north: "39.06",
                                east: "25.01",
                                south: "38.5",
                                west: "25.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1448,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1448,
                                status: "available",
                                block: 77,
                                unitSize: 2232.91,
                                north: "53.5",
                                east: "42.35",
                                south: "52.57",
                                west: "42.33",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: true,
                                st_width_1: "25",
                                st_width_2: "60",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1449,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1449,
                                status: "available",
                                block: 77,
                                unitSize: 750.0,
                                north: "30.0",
                                east: "25.0",
                                south: "30.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1450,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1450,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1451,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1451,
                                status: "available",
                                block: 77,
                                unitSize: 1269.98,
                                north: "30.0",
                                east: "42.33",
                                south: "30.0",
                                west: "42.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1452,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1452,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1453,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1453,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1454,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1454,
                                status: "available",
                                block: 77,
                                unitSize: 1269.98,
                                north: "30.0",
                                east: "42.33",
                                south: "30.0",
                                west: "42.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1455,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1455,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1456,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1456,
                                status: "available",
                                block: 77,
                                unitSize: 368.75,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1457,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1457,
                                status: "available",
                                block: 77,
                                unitSize: 1269.7,
                                north: "30.0",
                                east: "42.33",
                                south: "30.0",
                                west: "42.32",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1458,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1458,
                                status: "available",
                                block: 77,
                                unitSize: 368.75,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1459,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1459,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1460,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1460,
                                status: "available",
                                block: 77,
                                unitSize: 1269.56,
                                north: "30.0",
                                east: "42.32",
                                south: "30.0",
                                west: "42.32",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1461,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1461,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1462,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1462,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1463,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1463,
                                status: "available",
                                block: 77,
                                unitSize: 1269.43,
                                north: "30.0",
                                east: "42.32",
                                south: "30.0",
                                west: "42.31",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1464,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1464,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1465,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1465,
                                status: "available",
                                block: 77,
                                unitSize: 381.93,
                                north: "15.0",
                                east: "25.0",
                                south: "15.55",
                                west: "25.01",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1466,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1466,
                                status: "available",
                                block: 77,
                                unitSize: 1312.56,
                                north: "30.55",
                                east: "42.31",
                                south: "31.49",
                                west: "42.32",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1467,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1467,
                                status: "sold",
                                block: 78,
                                unitSize: 425.0,
                                north: "17.0",
                                east: "25.0",
                                south: "17.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1468,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1468,
                                status: "sold",
                                block: 78,
                                unitSize: 400.0,
                                north: "16.0",
                                east: "25.0",
                                south: "16.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1469,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1469,
                                status: "sold",
                                block: 78,
                                unitSize: 412.5,
                                north: "17.0",
                                east: "25.0",
                                south: "17.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1470,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1470,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1471,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1471,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1472,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1472,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1473,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1473,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1474,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1474,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1475,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1475,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1476,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1476,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1477,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1477,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1478,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1478,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1479,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1479,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1480,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1480,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1481,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1481,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1482,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1482,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1483,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1483,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1484,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1484,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1485,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1485,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1486,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1486,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1487,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1487,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1488,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1488,
                                status: "available",
                                block: 78,
                                unitSize: 977.48,
                                north: "25.0",
                                east: "38.83",
                                south: "25.01",
                                west: "39.38",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1489,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1489,
                                status: "available",
                                block: 78,
                                unitSize: 991.33,
                                north: "25.0",
                                east: "39.38",
                                south: "25.01",
                                west: "39.93",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "15+6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1490,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1490,
                                status: "booked",
                                block: 79,
                                unitSize: 649.71,
                                north: "17.0",
                                east: "38.22",
                                south: "17.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1491,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1491,
                                status: "booked",
                                block: 79,
                                unitSize: 615.28,
                                north: "17.0",
                                east: "36.38",
                                south: "17.0",
                                west: "36.38",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1492,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1492,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1493,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1493,
                                status: "booked",
                                block: 79,
                                unitSize: 548.11,
                                north: "15.0",
                                east: "36.38",
                                south: "15.0",
                                west: "36.7",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1494,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1494,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1495,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1495,
                                status: "booked",
                                block: 79,
                                unitSize: 553.0,
                                north: "15.0",
                                east: "36.7",
                                south: "15.0",
                                west: "37.03",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1496,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1496,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1497,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1497,
                                status: "booked",
                                block: 79,
                                unitSize: 557.89,
                                north: "15.0",
                                east: "37.03",
                                south: "15.0",
                                west: "37.36",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1498,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1498,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1499,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1499,
                                status: "booked",
                                block: 79,
                                unitSize: 562.78,
                                north: "15.0",
                                east: "37.36",
                                south: "15.0",
                                west: "37.68",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1500,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1500,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1501,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1501,
                                status: "booked",
                                block: 79,
                                unitSize: 567.67,
                                north: "15.0",
                                east: "37.68",
                                south: "15.0",
                                west: "38.01",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1502,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1502,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1503,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1503,
                                status: "booked",
                                block: 79,
                                unitSize: 572.56,
                                north: "15.0",
                                east: "38.01",
                                south: "15.0",
                                west: "38.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1504,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1504,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1505,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1505,
                                status: "booked",
                                block: 79,
                                unitSize: 577.45,
                                north: "15.0",
                                east: "38.33",
                                south: "15.0",
                                west: "38.66",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1506,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1506,
                                status: "booked",
                                block: 79,
                                unitSize: 687.93,
                                north: "18.0",
                                east: "38.22",
                                south: "18.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1507,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1507,
                                status: "booked",
                                block: 79,
                                unitSize: 699.4,
                                north: "18.0",
                                east: "38.66",
                                south: "18.0",
                                west: "39.05",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1508,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1508,
                                status: "available",
                                block: 80,
                                unitSize: 635.11,
                                north: "18.03",
                                east: "36.01",
                                south: "17.25",
                                west: "36.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1509,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1509,
                                status: "available",
                                block: 80,
                                unitSize: 606.94,
                                north: "17.25",
                                east: "36.01",
                                south: "16.47",
                                west: "36.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1510,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1510,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1511,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1511,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1512,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1512,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1513,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1513,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1514,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1514,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1515,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1515,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1516,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1516,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1517,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1517,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1518,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1518,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1519,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1519,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1520,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1520,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1521,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1521,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1522,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1522,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1523,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1523,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1524,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1524,
                                status: "available",
                                block: 80,
                                unitSize: 613.58,
                                north: "17.0",
                                east: "36.0",
                                south: "17.78",
                                west: "36.01",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1525,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1525,
                                status: "available",
                                block: 80,
                                unitSize: 654.25,
                                north: "17.78",
                                east: "36.0",
                                south: "18.56",
                                west: "36.01",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1526,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1526,
                                status: "available",
                                block: 81,
                                unitSize: 649.49,
                                north: "27.5",
                                east: "23.62",
                                south: "27.5",
                                west: "23.62",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1527,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1527,
                                status: "available",
                                block: 81,
                                unitSize: 649.49,
                                north: "27.5",
                                east: "23.62",
                                south: "27.5",
                                west: "23.62",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1528,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1528,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1529,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1529,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1530,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1530,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1531,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1531,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1532,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1532,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1533,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1533,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1534,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1534,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1535,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1535,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1536,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1536,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1537,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1537,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1538,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1538,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1539,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1539,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1540,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1540,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1541,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1541,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1542,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1542,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1543,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1543,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1544,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1544,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1545,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1545,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1546,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1546,
                                status: "available",
                                block: 81,
                                unitSize: 420.72,
                                north: "27.5",
                                east: "15.0",
                                south: "27.51",
                                west: "15.6",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1547,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1547,
                                status: "available",
                                block: 81,
                                unitSize: 437.15,
                                north: "27.5",
                                east: "15.6",
                                south: "27.51",
                                west: "16.2",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1548,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1548,
                                status: "available",
                                block: 82,
                                unitSize: 406.22,
                                north: "16.52",
                                east: "25.01",
                                south: "15.98",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1549,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1549,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1550,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1550,
                                status: "available",
                                block: 82,
                                unitSize: 1290.95,
                                north: "30.98",
                                east: "42.31",
                                south: "30.06",
                                west: "42.3",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1551,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1551,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1552,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1552,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1553,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1553,
                                status: "available",
                                block: 82,
                                unitSize: 1269.22,
                                north: "30.0",
                                east: "42.3",
                                south: "30.0",
                                west: "42.31",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1554,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1554,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1555,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1555,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1556,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1556,
                                status: "available",
                                block: 82,
                                unitSize: 1269.37,
                                north: "30.0",
                                east: "42.31",
                                south: "30.0",
                                west: "42.31",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1557,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1557,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1558,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1558,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1559,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1559,
                                status: "available",
                                block: 82,
                                unitSize: 1269.53,
                                north: "30.0",
                                east: "42.31",
                                south: "30.0",
                                west: "42.32",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1560,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1560,
                                status: "available",
                                block: 82,
                                unitSize: 675.0,
                                north: "27.0",
                                east: "25.0",
                                south: "27.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1561,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1561,
                                status: "available",
                                block: 82,
                                unitSize: 1269.68,
                                north: "30.0",
                                east: "42.32",
                                south: "30.0",
                                west: "42.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1562,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1562,
                                status: "available",
                                block: 82,
                                unitSize: 825.0,
                                north: "33.0",
                                east: "25.0",
                                south: "33.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1563,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1563,
                                status: "available",
                                block: 82,
                                unitSize: 1269.84,
                                north: "30.0",
                                east: "42.33",
                                south: "30.0",
                                west: "42.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1564,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1564,
                                status: "available",
                                block: 82,
                                unitSize: 945.07,
                                north: "37.53",
                                east: "25.0",
                                south: "38.07",
                                west: "25.01",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1565,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1565,
                                status: "available",
                                block: 82,
                                unitSize: 1631.31,
                                north: "38.07",
                                east: "42.33",
                                south: "38.99",
                                west: "42.35",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "60",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1566,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1566,
                                status: "available",
                                block: 83,
                                unitSize: 731.79,
                                north: "30.0",
                                east: "24.39",
                                south: "30.0",
                                west: "24.39",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1567,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1567,
                                status: "available",
                                block: 83,
                                unitSize: 975.72,
                                north: "40.0",
                                east: "24.39",
                                south: "40.0",
                                west: "24.39",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "30",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1568,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1568,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1569,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1569,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1570,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1570,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1571,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1571,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1572,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1572,
                                status: "available",
                                block: 83,
                                unitSize: 443.75,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1573,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1573,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1574,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1574,
                                status: "available",
                                block: 83,
                                unitSize: 443.75,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1575,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1575,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1576,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1576,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1577,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1577,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1578,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1578,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1579,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1579,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1580,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1580,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1581,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1581,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1582,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1582,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1583,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1583,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1584,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1584,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1585,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1585,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1586,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1586,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1587,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1587,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1588,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1588,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1589,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1589,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1590,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1590,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1591,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1591,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1592,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1592,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1593,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1593,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1594,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1594,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1595,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1595,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1596,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1596,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1597,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1597,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1598,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1598,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1599,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1599,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1600,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1600,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1601,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1601,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1602,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1602,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1603,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1603,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1604,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1604,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1605,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1605,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1606,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1606,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1607,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1607,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1608,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1608,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1609,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1609,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1610,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1610,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1611,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1611,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1612,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1612,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1613,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1613,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1614,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1614,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1615,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1615,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1616,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1616,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1617,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1617,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1618,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1618,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1619,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1619,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1620,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1620,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1621,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1621,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1622,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1622,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1623,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1623,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1624,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1624,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1625,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1625,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1626,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1626,
                                status: "booked",
                                block: 84,
                                unitSize: 1317.11,
                                north: "35.0",
                                east: "37.61",
                                south: "35.01",
                                west: "38.37",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "60",
                            },
                        },
                        {
                            Polygon: LandPlot_31_1627,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1627,
                                status: "booked",
                                block: 84,
                                unitSize: 1356.45,
                                north: "35.0",
                                east: "38.37",
                                south: "35.01",
                                west: "39.14",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "30",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_33_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_33_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_33_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_33_1,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
    //////////////////////////////////////////////////////////////////////////////////////////////////
    //index 61
    {
        index: 62,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: LandPlot_61_1164,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1164,
                                status: "available",
                                block: 64,
                                unitSize: 725.0,
                                north: "29.0",
                                east: "25.0",
                                south: "29.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1165,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1165,
                                status: "available",
                                block: 64,
                                unitSize: 580.0,
                                north: "29.0",
                                east: "20.0",
                                south: "29.0",
                                west: "20.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1166,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1166,
                                status: "available",
                                block: 64,
                                unitSize: 580.0,
                                north: "29.0",
                                east: "20.0",
                                south: "29.0",
                                west: "20.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1167,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1167,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1168,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1168,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1169,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1169,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1170,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1170,
                                status: "available",
                                block: 64,
                                unitSize: 1350.0,
                                north: "45.0",
                                east: "30.0",
                                south: "45.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1171,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1171,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1172,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1172,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1173,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1173,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1174,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1174,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1175,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1175,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1176,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1176,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1177,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1177,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1178,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1178,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1179,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1179,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1180,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1180,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1181,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1181,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1182,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1182,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1183,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1183,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1184,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1184,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1185,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1185,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1186,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1186,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1187,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1187,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1188,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1188,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1189,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1189,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1190,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1190,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1191,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1191,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1192,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1192,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1193,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1193,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1194,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1194,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1195,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1195,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1196,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1196,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1197,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1197,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1198,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1198,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1199,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1199,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1200,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1200,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1201,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1201,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1202,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1202,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1203,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1203,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1204,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1204,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1205,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1205,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1206,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1206,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1207,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1207,
                                status: "booked",
                                block: 65,
                                unitSize: 440.9,
                                north: "15.0",
                                east: "29.39",
                                south: "15.0",
                                west: "29.39",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1208,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1208,
                                status: "booked",
                                block: 65,
                                unitSize: 440.9,
                                north: "15.0",
                                east: "29.39",
                                south: "15.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1209,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1209,
                                status: "booked",
                                block: 65,
                                unitSize: 440.9,
                                north: "15.0",
                                east: "29.39",
                                south: "15.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1210,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1210,
                                status: "booked",
                                block: 65,
                                unitSize: 440.9,
                                north: "15.0",
                                east: "29.39",
                                south: "15.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1211,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1211,
                                status: "available",
                                block: 66,
                                unitSize: 527.0,
                                north: "17.0",
                                east: "31.0",
                                south: "17.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1212,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1212,
                                status: "available",
                                block: 66,
                                unitSize: 527.0,
                                north: "17.0",
                                east: "31.0",
                                south: "17.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1213,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1213,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1214,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1214,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1215,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1215,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1216,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1216,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1217,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1217,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1218,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1218,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1219,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1219,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1220,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1220,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1221,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1221,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1222,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1222,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1223,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1223,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1224,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1224,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1225,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1225,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1226,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1226,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1227,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1227,
                                status: "available",
                                block: 66,
                                unitSize: 558.0,
                                north: "18.0",
                                east: "31.0",
                                south: "18.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1228,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1228,
                                status: "available",
                                block: 66,
                                unitSize: 558.0,
                                north: "18.0",
                                east: "31.0",
                                south: "18.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1229,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1229,
                                status: "available",
                                block: 67,
                                unitSize: 527.0,
                                north: "17.0",
                                east: "31.0",
                                south: "17.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1230,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1230,
                                status: "available",
                                block: 67,
                                unitSize: 550.68,
                                north: "17.0",
                                east: "32.39",
                                south: "17.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1231,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1231,
                                status: "available",
                                block: 67,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1232,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1232,
                                status: "available",
                                block: 67,
                                unitSize: 485.9,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1233,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1233,
                                status: "available",
                                block: 67,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1234,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1234,
                                status: "available",
                                block: 67,
                                unitSize: 485.9,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1235,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1235,
                                status: "available",
                                block: 67,
                                unitSize: 458.75,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1236,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1236,
                                status: "available",
                                block: 67,
                                unitSize: 485.09,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1237,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1237,
                                status: "available",
                                block: 67,
                                unitSize: 458.75,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1238,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1238,
                                status: "available",
                                block: 67,
                                unitSize: 485.09,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1239,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1239,
                                status: "available",
                                block: 67,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1240,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1240,
                                status: "available",
                                block: 67,
                                unitSize: 485.09,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1241,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1241,
                                status: "available",
                                block: 67,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1242,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1242,
                                status: "available",
                                block: 67,
                                unitSize: 485.09,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1243,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1243,
                                status: "available",
                                block: 67,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1244,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1244,
                                status: "available",
                                block: 67,
                                unitSize: 485.09,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1245,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1245,
                                status: "available",
                                block: 67,
                                unitSize: 558.0,
                                north: "18.0",
                                east: "31.0",
                                south: "18.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1246,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1246,
                                status: "available",
                                block: 67,
                                unitSize: 583.08,
                                north: "18.0",
                                east: "32.39",
                                south: "18.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1247,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1247,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1248,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1248,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1249,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1249,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1250,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1250,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1251,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1251,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1252,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1252,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1253,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1253,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1254,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1254,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1255,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1255,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1256,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1256,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1257,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1257,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1258,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1258,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1259,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1259,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1260,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1260,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1261,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1261,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1262,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1262,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1263,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1263,
                                status: "available",
                                block: 68,
                                unitSize: 499.68,
                                north: "17.0",
                                east: "29.39",
                                south: "17.0",
                                west: "29.39",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1264,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1264,
                                status: "available",
                                block: 68,
                                unitSize: 470.29,
                                north: "16.0",
                                east: "29.39",
                                south: "16.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1265,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1265,
                                status: "available",
                                block: 68,
                                unitSize: 499.68,
                                north: "17.0",
                                east: "29.39",
                                south: "17.0",
                                west: "29.39",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1266,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1266,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1267,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1267,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1268,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1268,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1269,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1269,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1270,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1270,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1271,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1271,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1272,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1272,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1273,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1273,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1274,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1274,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1275,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1275,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1276,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1276,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1277,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1277,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1278,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1278,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1279,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1279,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1280,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1280,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1281,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1281,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1282,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1282,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1283,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1283,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1284,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1284,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1285,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1285,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1286,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1286,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1287,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1287,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1288,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1288,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1289,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1289,
                                status: "available",
                                block: 69,
                                unitSize: 1350.0,
                                north: "45.0",
                                east: "30.0",
                                south: "45.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1290,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1290,
                                status: "available",
                                block: 69,
                                unitSize: 680.0,
                                north: "34.0",
                                east: "20.0",
                                south: "34.0",
                                west: "20.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1291,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1291,
                                status: "available",
                                block: 69,
                                unitSize: 510.0,
                                north: "34.0",
                                east: "15.0",
                                south: "34.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1292,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1292,
                                status: "available",
                                block: 69,
                                unitSize: 510.0,
                                north: "34.0",
                                east: "15.0",
                                south: "34.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1293,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1293,
                                status: "available",
                                block: 69,
                                unitSize: 510.0,
                                north: "34.0",
                                east: "15.0",
                                south: "34.0",
                                west: "15.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1294,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1294,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1295,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1295,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1296,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1296,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1297,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1297,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1298,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1298,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1299,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1299,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1300,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1300,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1301,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1301,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1302,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1302,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1303,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1303,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1304,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1304,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1305,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1305,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1306,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1306,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1307,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1307,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1308,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1308,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1309,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1309,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1310,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1310,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1311,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1311,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1312,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1312,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1313,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1313,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1314,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1314,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1315,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1315,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1316,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1316,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1317,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1317,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1318,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1318,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1319,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1319,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1320,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1320,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1321,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1321,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1322,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1322,
                                status: "available",
                                block: 71,
                                unitSize: 488.75,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1323,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1323,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1324,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1324,
                                status: "available",
                                block: 71,
                                unitSize: 488.75,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1325,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1325,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1326,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1326,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1327,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1327,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1328,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1328,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1329,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1329,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1330,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1330,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1331,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1331,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1332,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1332,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1333,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1333,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1334,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1334,
                                status: "available",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1335,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1335,
                                status: "available",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1336,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1336,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1337,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1337,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1338,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1338,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1339,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1339,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1340,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1340,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1341,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1341,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1342,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1342,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1343,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1343,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1344,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1344,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1345,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1345,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1346,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1346,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1347,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1347,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1348,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1348,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1349,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1349,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1350,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1350,
                                status: "sold",
                                block: 72,
                                unitSize: 499.68,
                                north: "17.0",
                                east: "29.39",
                                south: "17.0",
                                west: "29.39",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1351,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1351,
                                status: "sold",
                                block: 72,
                                unitSize: 499.68,
                                north: "17.0",
                                east: "29.39",
                                south: "17.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1352,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1352,
                                status: "sold",
                                block: 72,
                                unitSize: 617.26,
                                north: "21.0",
                                east: "29.39",
                                south: "21.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1353,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1353,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1354,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1354,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1355,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1355,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1356,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1356,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1357,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1357,
                                status: "booked",
                                block: 73,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1358,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1358,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1359,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1359,
                                status: "booked",
                                block: 73,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1360,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1360,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1361,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1361,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1362,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1362,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1363,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1363,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1364,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1364,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1365,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1365,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1366,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1366,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1367,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1367,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1368,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1368,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1369,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1369,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1370,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1370,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1371,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1371,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1372,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1372,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1373,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1373,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1374,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1374,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1375,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1375,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1376,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1376,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1377,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1377,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1378,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1378,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1379,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1379,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1380,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1380,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1381,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1381,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1382,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1382,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1383,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1383,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1384,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1384,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1385,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1385,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1386,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1386,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1387,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1387,
                                status: "available",
                                block: 74,
                                unitSize: 674.88,
                                north: "30.0",
                                east: "22.5",
                                south: "30.0",
                                west: "22.5",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1388,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1388,
                                status: "available",
                                block: 74,
                                unitSize: 674.88,
                                north: "30.0",
                                east: "22.5",
                                south: "30.0",
                                west: "22.5",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1389,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1389,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1390,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1390,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1391,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1391,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1392,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1392,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1393,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1393,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1394,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1394,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1395,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1395,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1396,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1396,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1397,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1397,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1398,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1398,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1399,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1399,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1400,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1400,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1401,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1401,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1402,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1402,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1403,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1403,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1404,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1404,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1405,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1405,
                                status: "available",
                                block: 74,
                                unitSize: 639.97,
                                north: "30.0",
                                east: "21.0",
                                south: "30.01",
                                west: "21.66",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1406,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1406,
                                status: "available",
                                block: 74,
                                unitSize: 481.99,
                                north: "22.0",
                                east: "21.66",
                                south: "22.01",
                                west: "22.15",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1407,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1407,
                                status: "available",
                                block: 75,
                                unitSize: 629.0,
                                north: "17.0",
                                east: "37.0",
                                south: "17.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1408,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1408,
                                status: "available",
                                block: 75,
                                unitSize: 583.5,
                                north: "17.0",
                                east: "34.14",
                                south: "17.0",
                                west: "34.51",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1409,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1409,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1410,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1410,
                                status: "available",
                                block: 75,
                                unitSize: 520.17,
                                north: "15.0",
                                east: "34.51",
                                south: "15.0",
                                west: "34.84",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1411,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1411,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1412,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1412,
                                status: "available",
                                block: 75,
                                unitSize: 525.16,
                                north: "15.0",
                                east: "34.84",
                                south: "15.0",
                                west: "35.18",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1413,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1413,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1414,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1414,
                                status: "available",
                                block: 75,
                                unitSize: 530.14,
                                north: "15.0",
                                east: "35.18",
                                south: "15.0",
                                west: "35.51",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1415,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1415,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1416,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1416,
                                status: "available",
                                block: 75,
                                unitSize: 535.13,
                                north: "15.0",
                                east: "35.51",
                                south: "15.0",
                                west: "35.84",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1417,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1417,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1418,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1418,
                                status: "available",
                                block: 75,
                                unitSize: 540.12,
                                north: "15.0",
                                east: "35.84",
                                south: "15.0",
                                west: "36.17",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1419,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1419,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1420,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1420,
                                status: "available",
                                block: 75,
                                unitSize: 545.1,
                                north: "15.0",
                                east: "36.17",
                                south: "15.0",
                                west: "36.51",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1421,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1421,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1422,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1422,
                                status: "available",
                                block: 75,
                                unitSize: 550.09,
                                north: "15.0",
                                east: "36.51",
                                south: "15.0",
                                west: "36.84",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1423,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1423,
                                status: "available",
                                block: 75,
                                unitSize: 588.0,
                                north: "28.0",
                                east: "21.0",
                                south: "28.0",
                                west: "21.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1424,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1424,
                                status: "available",
                                block: 75,
                                unitSize: 448.0,
                                north: "28.0",
                                east: "16.0",
                                south: "28.0",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1425,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1425,
                                status: "available",
                                block: 75,
                                unitSize: 448.0,
                                north: "28.0",
                                east: "16.0",
                                south: "28.0",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1426,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1426,
                                status: "available",
                                block: 75,
                                unitSize: 592.17,
                                north: "28.0",
                                east: "20.84",
                                south: "28.01",
                                west: "21.46",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1427,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1427,
                                status: "available",
                                block: 76,
                                unitSize: 635.85,
                                north: "18.55",
                                east: "35.01",
                                south: "17.78",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1428,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1428,
                                status: "available",
                                block: 76,
                                unitSize: 608.7,
                                north: "17.78",
                                east: "35.01",
                                south: "17.0",
                                west: "35.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1429,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1429,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1430,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1430,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1431,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1431,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1432,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1432,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1433,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1433,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1434,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1434,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1435,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1435,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1436,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1436,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1437,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1437,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1438,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1438,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1439,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1439,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1440,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1440,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1441,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1441,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1442,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1442,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1443,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1443,
                                status: "available",
                                block: 76,
                                unitSize: 507.16,
                                north: "26.48",
                                east: "19.0",
                                south: "26.9",
                                west: "19.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1444,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1444,
                                status: "available",
                                block: 76,
                                unitSize: 433.28,
                                north: "26.9",
                                east: "16.0",
                                south: "27.26",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1445,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1445,
                                status: "available",
                                block: 76,
                                unitSize: 438.96,
                                north: "27.26",
                                east: "16.0",
                                south: "27.61",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1446,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1446,
                                status: "available",
                                block: 76,
                                unitSize: 528.63,
                                north: "27.61",
                                east: "19.0",
                                south: "28.03",
                                west: "19.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1447,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1447,
                                status: "available",
                                block: 77,
                                unitSize: 969.51,
                                north: "39.06",
                                east: "25.01",
                                south: "38.5",
                                west: "25.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1448,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1448,
                                status: "available",
                                block: 77,
                                unitSize: 2232.91,
                                north: "53.5",
                                east: "42.35",
                                south: "52.57",
                                west: "42.33",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: true,
                                st_width_1: "25",
                                st_width_2: "60",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1449,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1449,
                                status: "available",
                                block: 77,
                                unitSize: 750.0,
                                north: "30.0",
                                east: "25.0",
                                south: "30.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1450,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1450,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1451,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1451,
                                status: "available",
                                block: 77,
                                unitSize: 1269.98,
                                north: "30.0",
                                east: "42.33",
                                south: "30.0",
                                west: "42.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1452,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1452,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1453,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1453,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1454,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1454,
                                status: "available",
                                block: 77,
                                unitSize: 1269.98,
                                north: "30.0",
                                east: "42.33",
                                south: "30.0",
                                west: "42.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1455,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1455,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1456,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1456,
                                status: "available",
                                block: 77,
                                unitSize: 368.75,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1457,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1457,
                                status: "available",
                                block: 77,
                                unitSize: 1269.7,
                                north: "30.0",
                                east: "42.33",
                                south: "30.0",
                                west: "42.32",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1458,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1458,
                                status: "available",
                                block: 77,
                                unitSize: 368.75,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1459,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1459,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1460,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1460,
                                status: "available",
                                block: 77,
                                unitSize: 1269.56,
                                north: "30.0",
                                east: "42.32",
                                south: "30.0",
                                west: "42.32",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1461,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1461,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1462,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1462,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1463,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1463,
                                status: "available",
                                block: 77,
                                unitSize: 1269.43,
                                north: "30.0",
                                east: "42.32",
                                south: "30.0",
                                west: "42.31",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1464,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1464,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1465,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1465,
                                status: "available",
                                block: 77,
                                unitSize: 381.93,
                                north: "15.0",
                                east: "25.0",
                                south: "15.55",
                                west: "25.01",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1466,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1466,
                                status: "available",
                                block: 77,
                                unitSize: 1312.56,
                                north: "30.55",
                                east: "42.31",
                                south: "31.49",
                                west: "42.32",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1467,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1467,
                                status: "sold",
                                block: 78,
                                unitSize: 425.0,
                                north: "17.0",
                                east: "25.0",
                                south: "17.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1468,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1468,
                                status: "sold",
                                block: 78,
                                unitSize: 400.0,
                                north: "16.0",
                                east: "25.0",
                                south: "16.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1469,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1469,
                                status: "sold",
                                block: 78,
                                unitSize: 412.5,
                                north: "17.0",
                                east: "25.0",
                                south: "17.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1470,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1470,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1471,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1471,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1472,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1472,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1473,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1473,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1474,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1474,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1475,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1475,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1476,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1476,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1477,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1477,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1478,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1478,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1479,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1479,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1480,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1480,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1481,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1481,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1482,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1482,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1483,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1483,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1484,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1484,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1485,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1485,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1486,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1486,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1487,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1487,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1488,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1488,
                                status: "available",
                                block: 78,
                                unitSize: 977.48,
                                north: "25.0",
                                east: "38.83",
                                south: "25.01",
                                west: "39.38",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1489,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1489,
                                status: "available",
                                block: 78,
                                unitSize: 991.33,
                                north: "25.0",
                                east: "39.38",
                                south: "25.01",
                                west: "39.93",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "15+6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1490,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1490,
                                status: "booked",
                                block: 79,
                                unitSize: 649.71,
                                north: "17.0",
                                east: "38.22",
                                south: "17.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1491,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1491,
                                status: "booked",
                                block: 79,
                                unitSize: 615.28,
                                north: "17.0",
                                east: "36.38",
                                south: "17.0",
                                west: "36.38",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1492,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1492,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1493,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1493,
                                status: "booked",
                                block: 79,
                                unitSize: 548.11,
                                north: "15.0",
                                east: "36.38",
                                south: "15.0",
                                west: "36.7",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1494,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1494,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1495,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1495,
                                status: "booked",
                                block: 79,
                                unitSize: 553.0,
                                north: "15.0",
                                east: "36.7",
                                south: "15.0",
                                west: "37.03",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1496,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1496,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1497,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1497,
                                status: "booked",
                                block: 79,
                                unitSize: 557.89,
                                north: "15.0",
                                east: "37.03",
                                south: "15.0",
                                west: "37.36",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1498,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1498,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1499,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1499,
                                status: "booked",
                                block: 79,
                                unitSize: 562.78,
                                north: "15.0",
                                east: "37.36",
                                south: "15.0",
                                west: "37.68",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1500,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1500,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1501,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1501,
                                status: "booked",
                                block: 79,
                                unitSize: 567.67,
                                north: "15.0",
                                east: "37.68",
                                south: "15.0",
                                west: "38.01",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1502,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1502,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1503,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1503,
                                status: "booked",
                                block: 79,
                                unitSize: 572.56,
                                north: "15.0",
                                east: "38.01",
                                south: "15.0",
                                west: "38.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1504,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1504,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1505,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1505,
                                status: "booked",
                                block: 79,
                                unitSize: 577.45,
                                north: "15.0",
                                east: "38.33",
                                south: "15.0",
                                west: "38.66",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1506,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1506,
                                status: "booked",
                                block: 79,
                                unitSize: 687.93,
                                north: "18.0",
                                east: "38.22",
                                south: "18.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1507,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1507,
                                status: "booked",
                                block: 79,
                                unitSize: 699.4,
                                north: "18.0",
                                east: "38.66",
                                south: "18.0",
                                west: "39.05",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1508,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1508,
                                status: "available",
                                block: 80,
                                unitSize: 635.11,
                                north: "18.03",
                                east: "36.01",
                                south: "17.25",
                                west: "36.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1509,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1509,
                                status: "available",
                                block: 80,
                                unitSize: 606.94,
                                north: "17.25",
                                east: "36.01",
                                south: "16.47",
                                west: "36.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1510,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1510,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1511,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1511,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1512,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1512,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1513,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1513,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1514,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1514,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1515,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1515,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1516,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1516,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1517,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1517,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1518,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1518,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1519,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1519,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1520,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1520,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1521,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1521,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1522,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1522,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1523,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1523,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1524,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1524,
                                status: "available",
                                block: 80,
                                unitSize: 613.58,
                                north: "17.0",
                                east: "36.0",
                                south: "17.78",
                                west: "36.01",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1525,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1525,
                                status: "available",
                                block: 80,
                                unitSize: 654.25,
                                north: "17.78",
                                east: "36.0",
                                south: "18.56",
                                west: "36.01",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1526,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1526,
                                status: "available",
                                block: 81,
                                unitSize: 649.49,
                                north: "27.5",
                                east: "23.62",
                                south: "27.5",
                                west: "23.62",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1527,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1527,
                                status: "available",
                                block: 81,
                                unitSize: 649.49,
                                north: "27.5",
                                east: "23.62",
                                south: "27.5",
                                west: "23.62",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1528,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1528,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1529,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1529,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1530,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1530,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1531,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1531,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1532,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1532,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1533,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1533,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1534,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1534,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1535,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1535,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1536,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1536,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1537,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1537,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1538,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1538,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1539,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1539,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1540,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1540,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1541,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1541,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1542,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1542,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1543,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1543,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1544,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1544,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1545,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1545,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1546,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1546,
                                status: "available",
                                block: 81,
                                unitSize: 420.72,
                                north: "27.5",
                                east: "15.0",
                                south: "27.51",
                                west: "15.6",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1547,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1547,
                                status: "available",
                                block: 81,
                                unitSize: 437.15,
                                north: "27.5",
                                east: "15.6",
                                south: "27.51",
                                west: "16.2",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1548,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1548,
                                status: "available",
                                block: 82,
                                unitSize: 406.22,
                                north: "16.52",
                                east: "25.01",
                                south: "15.98",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1549,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1549,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1550,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1550,
                                status: "available",
                                block: 82,
                                unitSize: 1290.95,
                                north: "30.98",
                                east: "42.31",
                                south: "30.06",
                                west: "42.3",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1551,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1551,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1552,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1552,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1553,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1553,
                                status: "available",
                                block: 82,
                                unitSize: 1269.22,
                                north: "30.0",
                                east: "42.3",
                                south: "30.0",
                                west: "42.31",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1554,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1554,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1555,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1555,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1556,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1556,
                                status: "available",
                                block: 82,
                                unitSize: 1269.37,
                                north: "30.0",
                                east: "42.31",
                                south: "30.0",
                                west: "42.31",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1557,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1557,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1558,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1558,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1559,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1559,
                                status: "available",
                                block: 82,
                                unitSize: 1269.53,
                                north: "30.0",
                                east: "42.31",
                                south: "30.0",
                                west: "42.32",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1560,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1560,
                                status: "available",
                                block: 82,
                                unitSize: 675.0,
                                north: "27.0",
                                east: "25.0",
                                south: "27.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1561,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1561,
                                status: "available",
                                block: 82,
                                unitSize: 1269.68,
                                north: "30.0",
                                east: "42.32",
                                south: "30.0",
                                west: "42.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1562,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1562,
                                status: "available",
                                block: 82,
                                unitSize: 825.0,
                                north: "33.0",
                                east: "25.0",
                                south: "33.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1563,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1563,
                                status: "available",
                                block: 82,
                                unitSize: 1269.84,
                                north: "30.0",
                                east: "42.33",
                                south: "30.0",
                                west: "42.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1564,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1564,
                                status: "available",
                                block: 82,
                                unitSize: 945.07,
                                north: "37.53",
                                east: "25.0",
                                south: "38.07",
                                west: "25.01",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1565,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1565,
                                status: "available",
                                block: 82,
                                unitSize: 1631.31,
                                north: "38.07",
                                east: "42.33",
                                south: "38.99",
                                west: "42.35",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "60",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1566,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1566,
                                status: "available",
                                block: 83,
                                unitSize: 731.79,
                                north: "30.0",
                                east: "24.39",
                                south: "30.0",
                                west: "24.39",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1567,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1567,
                                status: "available",
                                block: 83,
                                unitSize: 975.72,
                                north: "40.0",
                                east: "24.39",
                                south: "40.0",
                                west: "24.39",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "30",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1568,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1568,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1569,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1569,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1570,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1570,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1571,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1571,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1572,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1572,
                                status: "available",
                                block: 83,
                                unitSize: 443.75,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1573,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1573,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1574,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1574,
                                status: "available",
                                block: 83,
                                unitSize: 443.75,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1575,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1575,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1576,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1576,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1577,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1577,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1578,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1578,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1579,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1579,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1580,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1580,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1581,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1581,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1582,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1582,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1583,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1583,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1584,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1584,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1585,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1585,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1586,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1586,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1587,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1587,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1588,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1588,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1589,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1589,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1590,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1590,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1591,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1591,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1592,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1592,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1593,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1593,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1594,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1594,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1595,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1595,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1596,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1596,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1597,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1597,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1598,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1598,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1599,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1599,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1600,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1600,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1601,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1601,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1602,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1602,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1603,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1603,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1604,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1604,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1605,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1605,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1606,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1606,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1607,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1607,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1608,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1608,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1609,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1609,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1610,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1610,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1611,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1611,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1612,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1612,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1613,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1613,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1614,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1614,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1615,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1615,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1616,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1616,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1617,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1617,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1618,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1618,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1619,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1619,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1620,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1620,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1621,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1621,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1622,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1622,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1623,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1623,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1624,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1624,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1625,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1625,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1626,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1626,
                                status: "booked",
                                block: 84,
                                unitSize: 1317.11,
                                north: "35.0",
                                east: "37.61",
                                south: "35.01",
                                west: "38.37",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "60",
                            },
                        },
                        {
                            Polygon: LandPlot_61_1627,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1627,
                                status: "booked",
                                block: 84,
                                unitSize: 1356.45,
                                north: "35.0",
                                east: "38.37",
                                south: "35.01",
                                west: "39.14",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "30",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_61_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_61_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_61_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_61_1,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
    //////////////////////////////////////////////////////////////////////////////////////////////////
    //index 91
    {
        index: 92,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: LandPlot_91_1164,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1164,
                                status: "available",
                                block: 64,
                                unitSize: 725.0,
                                north: "29.0",
                                east: "25.0",
                                south: "29.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1165,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1165,
                                status: "available",
                                block: 64,
                                unitSize: 580.0,
                                north: "29.0",
                                east: "20.0",
                                south: "29.0",
                                west: "20.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1166,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1166,
                                status: "available",
                                block: 64,
                                unitSize: 580.0,
                                north: "29.0",
                                east: "20.0",
                                south: "29.0",
                                west: "20.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1167,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1167,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1168,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1168,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1169,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1169,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1170,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1170,
                                status: "available",
                                block: 64,
                                unitSize: 1350.0,
                                north: "45.0",
                                east: "30.0",
                                south: "45.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1171,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1171,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1172,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1172,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1173,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1173,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1174,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1174,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1175,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1175,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1176,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1176,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1177,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1177,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1178,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1178,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1179,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1179,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1180,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1180,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1181,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1181,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1182,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1182,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1183,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1183,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1184,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1184,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1185,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1185,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1186,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1186,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1187,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1187,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1188,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1188,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1189,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1189,
                                status: "available",
                                block: 64,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1190,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1190,
                                status: "available",
                                block: 64,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1191,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1191,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1192,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1192,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1193,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1193,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1194,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1194,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1195,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1195,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1196,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1196,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1197,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1197,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1198,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1198,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1199,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1199,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1200,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1200,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1201,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1201,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1202,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1202,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1203,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1203,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1204,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1204,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1205,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1205,
                                status: "booked",
                                block: 65,
                                unitSize: 480.0,
                                north: "32.0",
                                east: "15.0",
                                south: "32.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1206,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1206,
                                status: "booked",
                                block: 65,
                                unitSize: 420.0,
                                north: "28.0",
                                east: "15.0",
                                south: "28.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1207,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1207,
                                status: "booked",
                                block: 65,
                                unitSize: 440.9,
                                north: "15.0",
                                east: "29.39",
                                south: "15.0",
                                west: "29.39",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1208,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1208,
                                status: "booked",
                                block: 65,
                                unitSize: 440.9,
                                north: "15.0",
                                east: "29.39",
                                south: "15.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1209,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1209,
                                status: "booked",
                                block: 65,
                                unitSize: 440.9,
                                north: "15.0",
                                east: "29.39",
                                south: "15.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1210,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1210,
                                status: "booked",
                                block: 65,
                                unitSize: 440.9,
                                north: "15.0",
                                east: "29.39",
                                south: "15.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1211,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1211,
                                status: "available",
                                block: 66,
                                unitSize: 527.0,
                                north: "17.0",
                                east: "31.0",
                                south: "17.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1212,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1212,
                                status: "available",
                                block: 66,
                                unitSize: 527.0,
                                north: "17.0",
                                east: "31.0",
                                south: "17.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1213,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1213,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1214,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1214,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1215,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1215,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1216,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1216,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1217,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1217,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1218,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1218,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1219,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1219,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1220,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1220,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1221,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1221,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1222,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1222,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1223,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1223,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1224,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1224,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1225,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1225,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1226,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1226,
                                status: "available",
                                block: 66,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1227,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1227,
                                status: "available",
                                block: 66,
                                unitSize: 558.0,
                                north: "18.0",
                                east: "31.0",
                                south: "18.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1228,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1228,
                                status: "available",
                                block: 66,
                                unitSize: 558.0,
                                north: "18.0",
                                east: "31.0",
                                south: "18.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1229,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1229,
                                status: "available",
                                block: 67,
                                unitSize: 527.0,
                                north: "17.0",
                                east: "31.0",
                                south: "17.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1230,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1230,
                                status: "available",
                                block: 67,
                                unitSize: 550.68,
                                north: "17.0",
                                east: "32.39",
                                south: "17.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1231,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1231,
                                status: "available",
                                block: 67,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1232,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1232,
                                status: "available",
                                block: 67,
                                unitSize: 485.9,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1233,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1233,
                                status: "available",
                                block: 67,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1234,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1234,
                                status: "available",
                                block: 67,
                                unitSize: 485.9,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1235,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1235,
                                status: "available",
                                block: 67,
                                unitSize: 458.75,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1236,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1236,
                                status: "available",
                                block: 67,
                                unitSize: 485.09,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1237,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1237,
                                status: "available",
                                block: 67,
                                unitSize: 458.75,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1238,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1238,
                                status: "available",
                                block: 67,
                                unitSize: 485.09,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1239,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1239,
                                status: "available",
                                block: 67,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1240,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1240,
                                status: "available",
                                block: 67,
                                unitSize: 485.09,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1241,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1241,
                                status: "available",
                                block: 67,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1242,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1242,
                                status: "available",
                                block: 67,
                                unitSize: 485.09,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1243,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1243,
                                status: "available",
                                block: 67,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1244,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1244,
                                status: "available",
                                block: 67,
                                unitSize: 485.09,
                                north: "15.0",
                                east: "32.39",
                                south: "15.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1245,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1245,
                                status: "available",
                                block: 67,
                                unitSize: 558.0,
                                north: "18.0",
                                east: "31.0",
                                south: "18.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1246,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1246,
                                status: "available",
                                block: 67,
                                unitSize: 583.08,
                                north: "18.0",
                                east: "32.39",
                                south: "18.0",
                                west: "32.39",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1247,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1247,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1248,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1248,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1249,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1249,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1250,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1250,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1251,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1251,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1252,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1252,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1253,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1253,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1254,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1254,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1255,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1255,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1256,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1256,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1257,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1257,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1258,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1258,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1259,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1259,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1260,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1260,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1261,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1261,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1262,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1262,
                                status: "available",
                                block: 68,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1263,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1263,
                                status: "available",
                                block: 68,
                                unitSize: 499.68,
                                north: "17.0",
                                east: "29.39",
                                south: "17.0",
                                west: "29.39",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1264,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1264,
                                status: "available",
                                block: 68,
                                unitSize: 470.29,
                                north: "16.0",
                                east: "29.39",
                                south: "16.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1265,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1265,
                                status: "available",
                                block: 68,
                                unitSize: 499.68,
                                north: "17.0",
                                east: "29.39",
                                south: "17.0",
                                west: "29.39",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1266,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1266,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1267,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1267,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1268,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1268,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1269,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1269,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1270,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1270,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1271,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1271,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1272,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1272,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1273,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1273,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1274,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1274,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1275,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1275,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1276,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1276,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1277,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1277,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1278,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1278,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1279,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1279,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1280,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1280,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1281,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1281,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1282,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1282,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1283,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1283,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1284,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1284,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1285,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1285,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1286,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1286,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1287,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1287,
                                status: "available",
                                block: 69,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1288,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1288,
                                status: "available",
                                block: 69,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1289,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1289,
                                status: "available",
                                block: 69,
                                unitSize: 1350.0,
                                north: "45.0",
                                east: "30.0",
                                south: "45.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1290,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1290,
                                status: "available",
                                block: 69,
                                unitSize: 680.0,
                                north: "34.0",
                                east: "20.0",
                                south: "34.0",
                                west: "20.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1291,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1291,
                                status: "available",
                                block: 69,
                                unitSize: 510.0,
                                north: "34.0",
                                east: "15.0",
                                south: "34.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1292,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1292,
                                status: "available",
                                block: 69,
                                unitSize: 510.0,
                                north: "34.0",
                                east: "15.0",
                                south: "34.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1293,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1293,
                                status: "available",
                                block: 69,
                                unitSize: 510.0,
                                north: "34.0",
                                east: "15.0",
                                south: "34.0",
                                west: "15.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1294,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1294,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1295,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1295,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1296,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1296,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1297,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1297,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1298,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1298,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1299,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1299,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1300,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1300,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1301,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1301,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1302,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1302,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1303,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1303,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1304,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1304,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1305,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1305,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1306,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1306,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1307,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1307,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1308,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1308,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1309,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1309,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1310,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1310,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1311,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1311,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1312,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1312,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1313,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1313,
                                status: "available",
                                block: 70,
                                unitSize: 465.0,
                                north: "15.0",
                                east: "31.0",
                                south: "15.0",
                                west: "31.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1314,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1314,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1315,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1315,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1316,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1316,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1317,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1317,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1318,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1318,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1319,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1319,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1320,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1320,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1321,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1321,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1322,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1322,
                                status: "available",
                                block: 71,
                                unitSize: 488.75,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1323,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1323,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1324,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1324,
                                status: "available",
                                block: 71,
                                unitSize: 488.75,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1325,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1325,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1326,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1326,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1327,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1327,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1328,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1328,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1329,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1329,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1330,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1330,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1331,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1331,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1332,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1332,
                                status: "available",
                                block: 71,
                                unitSize: 495.0,
                                north: "15.0",
                                east: "33.0",
                                south: "15.0",
                                west: "33.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1333,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1333,
                                status: "available",
                                block: 71,
                                unitSize: 455.9,
                                north: "15.0",
                                east: "30.39",
                                south: "15.0",
                                west: "30.39",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1334,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1334,
                                status: "available",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1335,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1335,
                                status: "available",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1336,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1336,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1337,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1337,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1338,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1338,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1339,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1339,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1340,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1340,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1341,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1341,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1342,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1342,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1343,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1343,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1344,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1344,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1345,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1345,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1346,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1346,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1347,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1347,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1348,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1348,
                                status: "sold",
                                block: 72,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1349,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1349,
                                status: "sold",
                                block: 72,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1350,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1350,
                                status: "sold",
                                block: 72,
                                unitSize: 499.68,
                                north: "17.0",
                                east: "29.39",
                                south: "17.0",
                                west: "29.39",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1351,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1351,
                                status: "sold",
                                block: 72,
                                unitSize: 499.68,
                                north: "17.0",
                                east: "29.39",
                                south: "17.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1352,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1352,
                                status: "sold",
                                block: 72,
                                unitSize: 617.26,
                                north: "21.0",
                                east: "29.39",
                                south: "21.0",
                                west: "29.39",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "20",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1353,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1353,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1354,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1354,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1355,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1355,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1356,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1356,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1357,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1357,
                                status: "booked",
                                block: 73,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1358,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1358,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1359,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1359,
                                status: "booked",
                                block: 73,
                                unitSize: 443.75,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1360,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1360,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1361,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1361,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1362,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1362,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1363,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1363,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1364,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1364,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1365,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1365,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1366,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1366,
                                status: "sold",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1367,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1367,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1368,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1368,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1369,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1369,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1370,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1370,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1371,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1371,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1372,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1372,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1373,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1373,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1374,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1374,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1375,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1375,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1376,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1376,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1377,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1377,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1378,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1378,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1379,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1379,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1380,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1380,
                                status: "booked",
                                block: 73,
                                unitSize: 450.0,
                                north: "15.0",
                                east: "30.0",
                                south: "15.0",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1381,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1381,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1382,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1382,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1383,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1383,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1384,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1384,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1385,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1385,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1386,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1386,
                                status: "booked",
                                block: 73,
                                unitSize: 525.0,
                                north: "17.5",
                                east: "30.0",
                                south: "17.5",
                                west: "30.0",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1387,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1387,
                                status: "available",
                                block: 74,
                                unitSize: 674.88,
                                north: "30.0",
                                east: "22.5",
                                south: "30.0",
                                west: "22.5",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "25",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1388,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1388,
                                status: "available",
                                block: 74,
                                unitSize: 674.88,
                                north: "30.0",
                                east: "22.5",
                                south: "30.0",
                                west: "22.5",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1389,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1389,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1390,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1390,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1391,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1391,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1392,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1392,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1393,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1393,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1394,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1394,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1395,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1395,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1396,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1396,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1397,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1397,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1398,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1398,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1399,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1399,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1400,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1400,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1401,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1401,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1402,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1402,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1403,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1403,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1404,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1404,
                                status: "available",
                                block: 74,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1405,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1405,
                                status: "available",
                                block: 74,
                                unitSize: 639.97,
                                north: "30.0",
                                east: "21.0",
                                south: "30.01",
                                west: "21.66",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1406,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1406,
                                status: "available",
                                block: 74,
                                unitSize: 481.99,
                                north: "22.0",
                                east: "21.66",
                                south: "22.01",
                                west: "22.15",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1407,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1407,
                                status: "available",
                                block: 75,
                                unitSize: 629.0,
                                north: "17.0",
                                east: "37.0",
                                south: "17.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1408,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1408,
                                status: "available",
                                block: 75,
                                unitSize: 583.5,
                                north: "17.0",
                                east: "34.14",
                                south: "17.0",
                                west: "34.51",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1409,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1409,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1410,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1410,
                                status: "available",
                                block: 75,
                                unitSize: 520.17,
                                north: "15.0",
                                east: "34.51",
                                south: "15.0",
                                west: "34.84",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1411,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1411,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1412,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1412,
                                status: "available",
                                block: 75,
                                unitSize: 525.16,
                                north: "15.0",
                                east: "34.84",
                                south: "15.0",
                                west: "35.18",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1413,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1413,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1414,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1414,
                                status: "available",
                                block: 75,
                                unitSize: 530.14,
                                north: "15.0",
                                east: "35.18",
                                south: "15.0",
                                west: "35.51",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1415,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1415,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1416,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1416,
                                status: "available",
                                block: 75,
                                unitSize: 535.13,
                                north: "15.0",
                                east: "35.51",
                                south: "15.0",
                                west: "35.84",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1417,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1417,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1418,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1418,
                                status: "available",
                                block: 75,
                                unitSize: 540.12,
                                north: "15.0",
                                east: "35.84",
                                south: "15.0",
                                west: "36.17",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1419,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1419,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1420,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1420,
                                status: "available",
                                block: 75,
                                unitSize: 545.1,
                                north: "15.0",
                                east: "36.17",
                                south: "15.0",
                                west: "36.51",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1421,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1421,
                                status: "available",
                                block: 75,
                                unitSize: 555.0,
                                north: "15.0",
                                east: "37.0",
                                south: "15.0",
                                west: "37.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1422,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1422,
                                status: "available",
                                block: 75,
                                unitSize: 550.09,
                                north: "15.0",
                                east: "36.51",
                                south: "15.0",
                                west: "36.84",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1423,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1423,
                                status: "available",
                                block: 75,
                                unitSize: 588.0,
                                north: "28.0",
                                east: "21.0",
                                south: "28.0",
                                west: "21.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1424,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1424,
                                status: "available",
                                block: 75,
                                unitSize: 448.0,
                                north: "28.0",
                                east: "16.0",
                                south: "28.0",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1425,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1425,
                                status: "available",
                                block: 75,
                                unitSize: 448.0,
                                north: "28.0",
                                east: "16.0",
                                south: "28.0",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1426,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1426,
                                status: "available",
                                block: 75,
                                unitSize: 592.17,
                                north: "28.0",
                                east: "20.84",
                                south: "28.01",
                                west: "21.46",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1427,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1427,
                                status: "available",
                                block: 76,
                                unitSize: 635.85,
                                north: "18.55",
                                east: "35.01",
                                south: "17.78",
                                west: "35.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1428,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1428,
                                status: "available",
                                block: 76,
                                unitSize: 608.7,
                                north: "17.78",
                                east: "35.01",
                                south: "17.0",
                                west: "35.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1429,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1429,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1430,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1430,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1431,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1431,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1432,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1432,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1433,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1433,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1434,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1434,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1435,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1435,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1436,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1436,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1437,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1437,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1438,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1438,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1439,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1439,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1440,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1440,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1441,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1441,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1442,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1442,
                                status: "available",
                                block: 76,
                                unitSize: 525.0,
                                north: "15.0",
                                east: "35.0",
                                south: "15.0",
                                west: "35.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1443,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1443,
                                status: "available",
                                block: 76,
                                unitSize: 507.16,
                                north: "26.48",
                                east: "19.0",
                                south: "26.9",
                                west: "19.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1444,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1444,
                                status: "available",
                                block: 76,
                                unitSize: 433.28,
                                north: "26.9",
                                east: "16.0",
                                south: "27.26",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1445,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1445,
                                status: "available",
                                block: 76,
                                unitSize: 438.96,
                                north: "27.26",
                                east: "16.0",
                                south: "27.61",
                                west: "16.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1446,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1446,
                                status: "available",
                                block: 76,
                                unitSize: 528.63,
                                north: "27.61",
                                east: "19.0",
                                south: "28.03",
                                west: "19.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1447,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1447,
                                status: "available",
                                block: 77,
                                unitSize: 969.51,
                                north: "39.06",
                                east: "25.01",
                                south: "38.5",
                                west: "25.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1448,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1448,
                                status: "available",
                                block: 77,
                                unitSize: 2232.91,
                                north: "53.5",
                                east: "42.35",
                                south: "52.57",
                                west: "42.33",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: true,
                                st_width_1: "25",
                                st_width_2: "60",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1449,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1449,
                                status: "available",
                                block: 77,
                                unitSize: 750.0,
                                north: "30.0",
                                east: "25.0",
                                south: "30.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1450,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1450,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1451,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1451,
                                status: "available",
                                block: 77,
                                unitSize: 1269.98,
                                north: "30.0",
                                east: "42.33",
                                south: "30.0",
                                west: "42.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1452,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1452,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1453,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1453,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1454,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1454,
                                status: "available",
                                block: 77,
                                unitSize: 1269.98,
                                north: "30.0",
                                east: "42.33",
                                south: "30.0",
                                west: "42.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1455,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1455,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1456,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1456,
                                status: "available",
                                block: 77,
                                unitSize: 368.75,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1457,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1457,
                                status: "available",
                                block: 77,
                                unitSize: 1269.7,
                                north: "30.0",
                                east: "42.33",
                                south: "30.0",
                                west: "42.32",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1458,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1458,
                                status: "available",
                                block: 77,
                                unitSize: 368.75,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1459,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1459,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1460,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1460,
                                status: "available",
                                block: 77,
                                unitSize: 1269.56,
                                north: "30.0",
                                east: "42.32",
                                south: "30.0",
                                west: "42.32",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1461,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1461,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1462,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1462,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1463,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1463,
                                status: "available",
                                block: 77,
                                unitSize: 1269.43,
                                north: "30.0",
                                east: "42.32",
                                south: "30.0",
                                west: "42.31",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1464,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1464,
                                status: "available",
                                block: 77,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1465,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1465,
                                status: "available",
                                block: 77,
                                unitSize: 381.93,
                                north: "15.0",
                                east: "25.0",
                                south: "15.55",
                                west: "25.01",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1466,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1466,
                                status: "available",
                                block: 77,
                                unitSize: 1312.56,
                                north: "30.55",
                                east: "42.31",
                                south: "31.49",
                                west: "42.32",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1467,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1467,
                                status: "sold",
                                block: 78,
                                unitSize: 425.0,
                                north: "17.0",
                                east: "25.0",
                                south: "17.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1468,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1468,
                                status: "sold",
                                block: 78,
                                unitSize: 400.0,
                                north: "16.0",
                                east: "25.0",
                                south: "16.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1469,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1469,
                                status: "sold",
                                block: 78,
                                unitSize: 412.5,
                                north: "17.0",
                                east: "25.0",
                                south: "17.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1470,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1470,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1471,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1471,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1472,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1472,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1473,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1473,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1474,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1474,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1475,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1475,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1476,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1476,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1477,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1477,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1478,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1478,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1479,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1479,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1480,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1480,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1481,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1481,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1482,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1482,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1483,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1483,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1484,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1484,
                                status: "sold",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1485,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1485,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1486,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1486,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1487,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1487,
                                status: "available",
                                block: 78,
                                unitSize: 375.0,
                                north: "25.0",
                                east: "15.0",
                                south: "25.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1488,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1488,
                                status: "available",
                                block: 78,
                                unitSize: 977.48,
                                north: "25.0",
                                east: "38.83",
                                south: "25.01",
                                west: "39.38",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1489,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1489,
                                status: "available",
                                block: 78,
                                unitSize: 991.33,
                                north: "25.0",
                                east: "39.38",
                                south: "25.01",
                                west: "39.93",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "6",
                                st_width_2: "15+6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1490,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1490,
                                status: "booked",
                                block: 79,
                                unitSize: 649.71,
                                north: "17.0",
                                east: "38.22",
                                south: "17.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1491,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1491,
                                status: "booked",
                                block: 79,
                                unitSize: 615.28,
                                north: "17.0",
                                east: "36.38",
                                south: "17.0",
                                west: "36.38",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1492,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1492,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1493,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1493,
                                status: "booked",
                                block: 79,
                                unitSize: 548.11,
                                north: "15.0",
                                east: "36.38",
                                south: "15.0",
                                west: "36.7",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1494,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1494,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1495,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1495,
                                status: "booked",
                                block: 79,
                                unitSize: 553.0,
                                north: "15.0",
                                east: "36.7",
                                south: "15.0",
                                west: "37.03",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1496,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1496,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1497,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1497,
                                status: "booked",
                                block: 79,
                                unitSize: 557.89,
                                north: "15.0",
                                east: "37.03",
                                south: "15.0",
                                west: "37.36",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1498,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1498,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1499,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1499,
                                status: "booked",
                                block: 79,
                                unitSize: 562.78,
                                north: "15.0",
                                east: "37.36",
                                south: "15.0",
                                west: "37.68",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1500,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1500,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1501,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1501,
                                status: "booked",
                                block: 79,
                                unitSize: 567.67,
                                north: "15.0",
                                east: "37.68",
                                south: "15.0",
                                west: "38.01",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1502,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1502,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1503,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1503,
                                status: "booked",
                                block: 79,
                                unitSize: 572.56,
                                north: "15.0",
                                east: "38.01",
                                south: "15.0",
                                west: "38.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1504,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1504,
                                status: "booked",
                                block: 79,
                                unitSize: 573.28,
                                north: "15.0",
                                east: "38.22",
                                south: "15.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1505,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1505,
                                status: "booked",
                                block: 79,
                                unitSize: 577.45,
                                north: "15.0",
                                east: "38.33",
                                south: "15.0",
                                west: "38.66",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1506,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1506,
                                status: "booked",
                                block: 79,
                                unitSize: 687.93,
                                north: "18.0",
                                east: "38.22",
                                south: "18.0",
                                west: "38.22",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1507,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1507,
                                status: "booked",
                                block: 79,
                                unitSize: 699.4,
                                north: "18.0",
                                east: "38.66",
                                south: "18.0",
                                west: "39.05",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1508,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1508,
                                status: "available",
                                block: 80,
                                unitSize: 635.11,
                                north: "18.03",
                                east: "36.01",
                                south: "17.25",
                                west: "36.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1509,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1509,
                                status: "available",
                                block: 80,
                                unitSize: 606.94,
                                north: "17.25",
                                east: "36.01",
                                south: "16.47",
                                west: "36.0",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1510,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1510,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1511,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1511,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1512,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1512,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1513,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1513,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1514,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1514,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1515,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1515,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1516,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1516,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1517,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1517,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1518,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1518,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1519,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1519,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1520,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1520,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1521,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1521,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1522,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1522,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1523,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1523,
                                status: "available",
                                block: 80,
                                unitSize: 540.0,
                                north: "15.0",
                                east: "36.0",
                                south: "15.0",
                                west: "36.0",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1524,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1524,
                                status: "available",
                                block: 80,
                                unitSize: 613.58,
                                north: "17.0",
                                east: "36.0",
                                south: "17.78",
                                west: "36.01",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: true,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1525,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1525,
                                status: "available",
                                block: 80,
                                unitSize: 654.25,
                                north: "17.78",
                                east: "36.0",
                                south: "18.56",
                                west: "36.01",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1526,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1526,
                                status: "available",
                                block: 81,
                                unitSize: 649.49,
                                north: "27.5",
                                east: "23.62",
                                south: "27.5",
                                west: "23.62",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1527,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1527,
                                status: "available",
                                block: 81,
                                unitSize: 649.49,
                                north: "27.5",
                                east: "23.62",
                                south: "27.5",
                                west: "23.62",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "15",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1528,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1528,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1529,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1529,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1530,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1530,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1531,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1531,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1532,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1532,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1533,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1533,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1534,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1534,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1535,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1535,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1536,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1536,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1537,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1537,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1538,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1538,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1539,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1539,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1540,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1540,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1541,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1541,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1542,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1542,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1543,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1543,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1544,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1544,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1545,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1545,
                                status: "available",
                                block: 81,
                                unitSize: 412.5,
                                north: "27.5",
                                east: "15.0",
                                south: "27.5",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1546,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1546,
                                status: "available",
                                block: 81,
                                unitSize: 420.72,
                                north: "27.5",
                                east: "15.0",
                                south: "27.51",
                                west: "15.6",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1547,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1547,
                                status: "available",
                                block: 81,
                                unitSize: 437.15,
                                north: "27.5",
                                east: "15.6",
                                south: "27.51",
                                west: "16.2",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1548,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1548,
                                status: "available",
                                block: 82,
                                unitSize: 406.22,
                                north: "16.52",
                                east: "25.01",
                                south: "15.98",
                                west: "25.0",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1549,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1549,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1550,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1550,
                                status: "available",
                                block: 82,
                                unitSize: 1290.95,
                                north: "30.98",
                                east: "42.31",
                                south: "30.06",
                                west: "42.3",
                                front1: "southern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1551,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1551,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1552,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1552,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1553,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1553,
                                status: "available",
                                block: 82,
                                unitSize: 1269.22,
                                north: "30.0",
                                east: "42.3",
                                south: "30.0",
                                west: "42.31",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1554,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1554,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1555,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1555,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1556,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1556,
                                status: "available",
                                block: 82,
                                unitSize: 1269.37,
                                north: "30.0",
                                east: "42.31",
                                south: "30.0",
                                west: "42.31",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1557,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1557,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1558,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1558,
                                status: "available",
                                block: 82,
                                unitSize: 375.0,
                                north: "15.0",
                                east: "25.0",
                                south: "15.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1559,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1559,
                                status: "available",
                                block: 82,
                                unitSize: 1269.53,
                                north: "30.0",
                                east: "42.31",
                                south: "30.0",
                                west: "42.32",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1560,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1560,
                                status: "available",
                                block: 82,
                                unitSize: 675.0,
                                north: "27.0",
                                east: "25.0",
                                south: "27.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1561,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1561,
                                status: "available",
                                block: 82,
                                unitSize: 1269.68,
                                north: "30.0",
                                east: "42.32",
                                south: "30.0",
                                west: "42.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1562,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1562,
                                status: "available",
                                block: 82,
                                unitSize: 825.0,
                                north: "33.0",
                                east: "25.0",
                                south: "33.0",
                                west: "25.0",
                                front1: "northern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "15+6",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1563,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1563,
                                status: "available",
                                block: 82,
                                unitSize: 1269.84,
                                north: "30.0",
                                east: "42.33",
                                south: "30.0",
                                west: "42.33",
                                front1: "southern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1564,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1564,
                                status: "available",
                                block: 82,
                                unitSize: 945.07,
                                north: "37.53",
                                east: "25.0",
                                south: "38.07",
                                west: "25.01",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1565,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1565,
                                status: "available",
                                block: 82,
                                unitSize: 1631.31,
                                north: "38.07",
                                east: "42.33",
                                south: "38.99",
                                west: "42.35",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "60",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1566,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1566,
                                status: "available",
                                block: 83,
                                unitSize: 731.79,
                                north: "30.0",
                                east: "24.39",
                                south: "30.0",
                                west: "24.39",
                                front1: "northern",
                                front2: "eastern",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "20",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1567,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1567,
                                status: "available",
                                block: 83,
                                unitSize: 975.72,
                                north: "40.0",
                                east: "24.39",
                                south: "40.0",
                                west: "24.39",
                                front1: "northern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "25",
                                st_width_2: "30",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1568,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1568,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1569,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1569,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1570,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1570,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1571,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1571,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1572,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1572,
                                status: "available",
                                block: 83,
                                unitSize: 443.75,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1573,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1573,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1574,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1574,
                                status: "available",
                                block: 83,
                                unitSize: 443.75,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1575,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1575,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1576,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1576,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1577,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1577,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1578,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1578,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1579,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1579,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1580,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1580,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1581,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1581,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1582,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1582,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1583,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1583,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1584,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1584,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1585,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1585,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1586,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1586,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1587,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1587,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1588,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1588,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1589,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1589,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1590,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1590,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1591,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1591,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1592,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1592,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1593,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1593,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1594,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1594,
                                status: "available",
                                block: 83,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1595,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1595,
                                status: "available",
                                block: 83,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "southern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                            onClick: {
                                action: "open_contact_form",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1596,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1596,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1597,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1597,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "northern",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "6",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1598,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1598,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1599,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1599,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1600,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1600,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1601,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1601,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1602,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1602,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1603,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1603,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1604,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1604,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1605,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1605,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1606,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1606,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1607,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1607,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1608,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1608,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1609,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1609,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1610,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1610,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1611,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1611,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1612,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1612,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1613,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1613,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1614,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1614,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1615,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1615,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1616,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1616,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1617,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1617,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1618,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1618,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1619,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1619,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1620,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1620,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1621,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1621,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1622,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1622,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1623,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1623,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1624,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1624,
                                status: "booked",
                                block: 84,
                                unitSize: 450.0,
                                north: "30.0",
                                east: "15.0",
                                south: "30.0",
                                west: "15.0",
                                front1: "eastern",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "20",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1625,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1625,
                                status: "booked",
                                block: 84,
                                unitSize: 600.0,
                                north: "40.0",
                                east: "15.0",
                                south: "40.0",
                                west: "15.0",
                                front1: "western",
                                front2: "nan",
                                electricTransformer: false,
                                st_width_1: "30",
                                st_width_2: "0",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1626,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1626,
                                status: "booked",
                                block: 84,
                                unitSize: 1317.11,
                                north: "35.0",
                                east: "37.61",
                                south: "35.01",
                                west: "38.37",
                                front1: "eastern",
                                front2: "southern",
                                electricTransformer: true,
                                st_width_1: "20",
                                st_width_2: "60",
                            },
                        },
                        {
                            Polygon: LandPlot_91_1627,
                            className: null,
                            infobox: {
                                type: "land_info",
                                index: 1627,
                                status: "booked",
                                block: 84,
                                unitSize: 1356.45,
                                north: "35.0",
                                east: "38.37",
                                south: "35.01",
                                west: "39.14",
                                front1: "southern",
                                front2: "western",
                                electricTransformer: false,
                                st_width_1: "60",
                                st_width_2: "30",
                            },
                        },
                    ]}
                />
                <SvgCustoms
                    customs={[
                        {
                            Element: Church_91_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Church_91_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_1,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_2,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: School_91_3,
                            className: "landmark",
                            onClick: null,
                        },
                        {
                            Element: Shopping_91_1,
                            className: "landmark",
                            onClick: null,
                        },
                    ]}
                />
            </>
        ),
    },
];
